import { getLocalTimezone } from '@sparkpost/report-builder/helpers/date';
import { subMonths } from 'date-fns';
import React, { useCallback, useEffect } from 'react';
import DatePicker from 'src/components/datePicker/DatePickerV2';
import { PageLink } from 'src/components/links';
import {
  Box,
  Button,
  Column,
  Columns,
  Layout,
  ListBox,
  Panel,
  Text
} from 'src/components/matchbox';
import { TimezoneTypeahead } from 'src/components/typeahead';
import { SIGNALS_RELATIVE_DATE_OPTIONS } from 'src/constants';
import { getDeliverability } from 'src/helpers/api/metrics';
import { useSparkPostQuery } from 'src/hooks';
import { getDetailsPageApiParams, getResourceFromPageType } from '../constants/resourceDetails';
import { validateQueryParams } from '../helpers';
import useResourceDetailsContext from '../hooks/useResourceDetailsContext';

const dateTimeReducer = (state, action) => {
  switch (action.type) {
    case 'SET_RANGE':
      const ret = { ...state };
      if (action.value !== 'day') {
        ret.selectedPrecision = 'day';
        ret.selectedTimezone = 'UTC';
      }
      return { ...ret, selectedRange: action.value };
    case 'SET_TIMEZONE':
      return { ...state, selectedTimezone: action.value };
    case 'SET_PRECISION':
      return { ...state, selectedPrecision: action.value };
    default:
      throw new Error(`${action.type} is not a valid action for this reducer`);
  }
};

const initialState = {
  selectedRange: 'day',
  selectedTimezone: getLocalTimezone(),
  selectedPrecision: 'hour'
};

export default function ResourceDetails({ tabMeta, pageMeta = { sectionTitle: '' } }) {
  const SECTION_TITLE = pageMeta.sectionTitle;
  const [state, dispatch] = React.useReducer(dateTimeReducer, initialState);

  const {
    filters,
    updateFilters,
    match: { params: { id, type } = {} } = {},
    sourceCheckboxes: { checkboxes },
    hasD12yProduct,
    hasSendingProduct
  } = useResourceDetailsContext();

  const { apiParams, apiPath, messageEventsUrl } = getDetailsPageApiParams({
    type,
    id,
    filters,
    checkboxes,
    hasD12yProduct,
    hasSendingProduct
  });

  const { status: deliverabilityDataStatus, data: deliverabilityData } = useSparkPostQuery(() =>
    getDeliverability(apiParams, apiPath)
  );

  const setTimezone = useCallback(
    ({ value }) => {
      dispatch({ type: 'SET_TIMEZONE', value });
      updateFilters({ timezone: value });
    },
    [updateFilters, dispatch]
  );

  const setPrecision = useCallback(
    (e) => {
      dispatch({ type: 'SET_RANGE', value: e.currentTarget.value });
      updateFilters({ precision: e.currentTarget.value });
    },
    [updateFilters]
  );

  useEffect(() => {
    const { from, to, relativeRange: range, timezone, precision } = validateQueryParams(filters);
    updateFilters({ from, to, range, timezone, precision });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SubSectionTitle = tabMeta.subSectionTitle;
  return (
    <>
      <Layout>
        <Layout.Section annotated>
          <Layout.SectionTitle as="h2">{SECTION_TITLE}</Layout.SectionTitle>
          <Text color="gray.700" fontSize="200">
            {`General details about this ${getResourceFromPageType(type)}.`}
          </Text>
          {SubSectionTitle && <SubSectionTitle id={id} />}
        </Layout.Section>

        <Layout.Section>
          <Panel data-id="resource-details-top-panels">
            <Panel.Section>
              <Columns>
                <Column width={2 / 5}>
                  <DatePicker
                    {...validateQueryParams(filters)}
                    relativeDateOptions={SIGNALS_RELATIVE_DATE_OPTIONS}
                    onChange={(props) => {
                      const { from, to, relativeRange: range } = props;
                      updateFilters({ from, to, range });
                    }}
                    label="Date Range"
                    datePickerProps={{
                      disabledDays: { after: new Date(), before: subMonths(new Date(), 6) }
                    }}
                  />
                </Column>
                <Column width={2 / 5}>
                  <TimezoneTypeahead
                    id="alert-time-zone"
                    initialValue={state.selectedTimezone}
                    onChange={setTimezone}
                  />
                </Column>
                <Column width={1 / 5}>
                  <ListBox
                    id="alert-precision"
                    value={state.selectedPrecision}
                    label="Precision"
                    onChange={setPrecision}
                  >
                    <ListBox.Option value="hour">Hour</ListBox.Option>
                    <ListBox.Option value="day">Day</ListBox.Option>
                  </ListBox>
                </Column>
              </Columns>
              <Box mt="450">
                <Box display="flex" alignItems="flex-end">
                  {messageEventsUrl && (
                    <PageLink as={Button} variant="primary" to={messageEventsUrl}>
                      View Message Events
                    </PageLink>
                  )}
                </Box>
              </Box>
            </Panel.Section>
            <TopSections
              pageData={{
                id,
                checkboxes,
                hasD12yProduct,
                hasSendingProduct,
                deliverabilityData,
                deliverabilityDataStatus
              }}
              tabMeta={tabMeta}
            />
          </Panel>
        </Layout.Section>
      </Layout>
      <OtherLayouts
        pageData={{
          id,
          checkboxes,
          hasD12yProduct,
          hasSendingProduct,
          type,
          deliverabilityData,
          deliverabilityDataStatus
        }}
        tabMeta={tabMeta}
      />
    </>
  );
}

const OtherLayouts = ({ pageData, tabMeta }) => {
  return tabMeta.layouts.map((layout, i) => {
    const { name, component: Component, propKeys } = layout;

    const propsForComponent = {};

    propKeys.forEach((key) => {
      propsForComponent[key] = pageData[key];
    });

    return <Component key={`${name}-${i}`} {...propsForComponent} />;
  });
};

const TopSections = ({ pageData, tabMeta }) => {
  return tabMeta.topSections.map((Section, i) => {
    const { name, component: Component, propKeys } = Section;
    const propsForComponent = {};
    propKeys.forEach((key) => {
      propsForComponent[key] = pageData[key];
    });

    return <Component key={`${name}-${i}`} {...propsForComponent} />;
  });
};
