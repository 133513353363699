import React from 'react';
import { Box, Text } from 'src/components/matchbox';

type CalloutProps = {
  children: React.ReactNode;
  height: string;
  title: string;
};

const Callout = ({ children, height = '220px', title = '' }: CalloutProps): JSX.Element => (
  <Box height={height} display="flex" justifyContent="center" alignItems="center">
    <Box>
      {title && (
        <Text as="h3" mb="200" color="gray.800">
          <span>{title}</span>
        </Text>
      )}
      {children && (
        <Text as="p" color="gray.800">
          {children}
        </Text>
      )}
    </Box>
  </Box>
);

export default Callout;
