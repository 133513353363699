import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import qs from 'query-string';
import ErrorTracker from 'src/helpers/errorTracker';
import { CenteredLogo } from 'src/components';
import { PageLink } from 'src/components/links';
import { Panel, Stack } from 'src/components/matchbox';
import { PanelLoading } from 'src/components/loading';
import RegisterUserForm from './RegisterUserForm';
import {
  registerUser,
  checkInviteToken,
  updateNameOfUserWhenPendingTfaSetup,
  updateUser
} from 'src/actions/users';
import { authenticate } from 'src/actions/auth';
import { showAlert } from 'src/actions/globalAlert';
import { ENABLE_TFA_AUTH_ROUTE, DEFAULT_REDIRECT_ROUTE, AUTH_ROUTE } from 'src/constants';

export class RegisterPage extends Component {
  onSubmit = (values) => {
    const { password } = values;
    const { token, registerUser, showAlert } = this.props;

    return registerUser(token, values)
      .then(({ username }) => {
        showAlert({
          type: 'success',
          message: 'Welcome to SparkPost'
        });
        return this.authAndRedirect(username, password, values);
      })
      .catch((error) => ErrorTracker.report('register-user', error));
  };

  authAndRedirect = (username, password, values = {}) => {
    const { authenticate, history, updateNameOfUserWhenPendingTfaSetup, updateUser } = this.props;
    const { first_name, last_name } = values;

    return authenticate(username, password)
      .then((authResult) => {
        if (authResult.tfaRequired) {
          return updateNameOfUserWhenPendingTfaSetup({ first_name, last_name, username }).then(() =>
            history.push(ENABLE_TFA_AUTH_ROUTE)
          );
        }

        return updateUser(username, { first_name, last_name }).then(() =>
          history.push(DEFAULT_REDIRECT_ROUTE)
        );
      })
      .catch((error) => {
        // user was created but auth failed, redirect to /auth
        history.push(AUTH_ROUTE);
        ErrorTracker.report('sign-in', error);
      });
  };

  componentDidMount() {
    const { token } = this.props;

    if (token) {
      this.props.checkInviteToken(this.props.token);
    }
  }

  renderRegisterPanel() {
    const { invite } = this.props;

    if (invite.error) {
      return (
        <Panel.LEGACY.Section>
          <p>
            This invite has expired, please ask your account administrator to re-send your
            invitation
          </p>
        </Panel.LEGACY.Section>
      );
    }

    return (
      <Panel.LEGACY.Section>
        <Stack>
          <p>
            <small>{invite.from} invited you to join their SparkPost account.</small>
          </p>
          <RegisterUserForm onSubmit={this.onSubmit} email={invite.email} />
        </Stack>
      </Panel.LEGACY.Section>
    );
  }

  render() {
    const { token, loading } = this.props;

    if (token === undefined) {
      return <Redirect to={AUTH_ROUTE} />;
    }

    return (
      <div>
        <CenteredLogo />

        {loading ? (
          <PanelLoading />
        ) : (
          <>
            <Panel.LEGACY title="Set Password">{this.renderRegisterPanel()}</Panel.LEGACY>

            <Panel.LEGACY.Footer
              left={
                <small>
                  <PageLink to="/auth">Already signed up?</PageLink>
                </small>
              }
            />
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps({ auth, users }, props) {
  return {
    token: qs.parse(props.location.search).token,
    invite: users.invite,
    loading: users.loading || auth.loginPending
  };
}

export default withRouter(
  connect(mapStateToProps, {
    registerUser,
    updateNameOfUserWhenPendingTfaSetup,
    updateUser,
    showAlert,
    checkInviteToken,
    authenticate
  })(RegisterPage)
);
