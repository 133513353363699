/**
 * @description Return all blocklist incidents for any Monitored Resources. Incidents will be sorted with oldest incidents coming first.
 * @param {object} params - see available params in the API docs
 * @see {@link https://github.com/SparkPost/sparkpost-admin-api-documentation/blob/master/services/blocklist-monitoring-api.apib.md#retrieve-all-incidents-possible-filter-values-get-v1blocklist-monitorsincidentsfromtoblocklistsresourcesstatuslimit}
 */
export function getIncidents(params) {
  return {
    method: 'GET',
    url: '/v1/blocklist-monitors/incidents',
    params
  };
}

export function getMonitors() {
  return {
    method: 'GET',
    url: '/v1/blocklist-monitors'
  };
}

export function deleteMonitor(monitorId) {
  return {
    method: 'DELETE',
    url: `/v1/blocklist-monitors/${monitorId}`
  };
}

export function addMonitors(data) {
  return {
    method: 'POST',
    url: `/v1/blocklist-monitors`,
    data
  };
}
