import { AxiosError } from 'axios';
import { History } from 'history';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { verifyEmailToken } from 'src/actions/currentUser';
import { showAlert } from 'src/actions/globalAlert';
import { Loading } from 'src/components';
import { CurrentUser } from 'src/typescript/interfaces/CurrentUser';

interface StateProps {
  error: AxiosError;
  token: string;
  verifyingToken: null | boolean;
}

interface DispatchProps {
  showAlert: typeof showAlert;
  verifyEmailToken: typeof verifyEmailToken;
}

interface EmailVerificationProps extends StateProps, DispatchProps {
  history: History;
}

export class EmailVerification extends Component<EmailVerificationProps> {
  componentDidMount() {
    const { token, verifyEmailToken } = this.props;
    verifyEmailToken({ token });
  }

  componentDidUpdate(prevProps: EmailVerificationProps) {
    const { history, showAlert, verifyingToken, error } = this.props;

    if (!prevProps.error && error) {
      showAlert({
        type: 'error',
        message: 'Email verification failed.  The verification token is either invalid or expired.',
        details: error.message
      });

      history.push('/dashboard');
    }

    if (!error && verifyingToken === false) {
      showAlert({
        type: 'success',
        message: 'Your email address has been verified!'
      });

      history.push('/dashboard');
    }
  }

  render() {
    const { verifyingToken } = this.props;

    if (verifyingToken) {
      return <Loading />;
    }

    return null;
  }
}

type OwnProps = {
  match: {
    params: {
      token: string;
    };
  };
};

type ReduxState = {
  currentUser: CurrentUser;
};

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => {
  return {
    token: ownProps.match.params.token,
    verifyingToken: state.currentUser.verifyingToken,
    error: state.currentUser.tokenError
  };
};

export default connect(mapStateToProps, { verifyEmailToken, showAlert })(EmailVerification);
