import React, { useCallback } from 'react';
import { Box, Table } from 'src/components/matchbox';
import { TranslatableText } from '../text';

type TableHeaderProps = {
  columns: ColumnProps[];
  onSort: (sortColumn: string, direction: 'asc' | 'desc' | undefined) => void;
  sortColumn: string;
  sortDirection: 'asc' | 'desc' | undefined;
};

type ColumnProps = {
  align: 'left' | 'center' | 'right';
  label: string;
  minWidth: number;
  sortKey: string;
};

type HeaderCellProps = {
  index: number;
  item: ColumnProps | string;
  onSort: (sortColumn: string, direction: 'asc' | 'desc' | undefined) => void;
  sortColumn: string;
  sortDirection: 'asc' | 'desc' | undefined;
};

const HeaderCell = ({ item, index, sortColumn, sortDirection, onSort }: HeaderCellProps) => {
  const handleSorting = useCallback(() => {
    const { sortKey } = item as ColumnProps;
    let direction: 'asc' | 'desc';

    if (sortKey === sortColumn) {
      // change direction as same sortKey is clicked again
      direction = sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      direction = 'asc';
    }

    onSort(sortKey, direction);
  }, [item, onSort, sortColumn, sortDirection]);

  const renderSortCell = (item: { label: string; sortKey: string }) => {
    const { label, sortKey } = item;

    if (sortKey) {
      return (
        <Table.SortButton
          onClick={handleSorting}
          direction={sortKey === sortColumn && sortDirection ? sortDirection : undefined}
        >
          <TranslatableText>{label}</TranslatableText>
        </Table.SortButton>
      );
    }

    return label;
  };

  if (typeof item === 'string' || item === null) {
    return (
      <Table.HeaderCell key={`column ${index}: ${item}`} align="left">
        <TranslatableText>{item}</TranslatableText>
      </Table.HeaderCell>
    );
  }
  const { label, sortKey, minWidth, align, ...rest } = item;

  return (
    <Table.HeaderCell key={label ?? `column ${index}`} align={align} {...rest}>
      <Box minWidth={minWidth}>{renderSortCell(item)}</Box>
    </Table.HeaderCell>
  );
};

const TableHeader = (props: TableHeaderProps) => {
  const { columns, sortColumn, sortDirection } = props;

  const headerCells = columns.map((item, i) => {
    return (
      <HeaderCell
        key={`HeaderCell-${typeof item === 'object' ? item.label : item}`}
        item={item}
        index={i}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={props.onSort}
      />
    );
  });

  return (
    <thead>
      <Table.Row>{headerCells}</Table.Row>
    </thead>
  );
};

export default TableHeader;
