import React from 'react';
import PropTypes from 'prop-types';
import { FieldSet } from 'src/components';
import { Box, Columns, Column, Stack } from 'src/components/matchbox';
import { SubduedText } from 'src/components/text';

function SliderFieldSet({ children, legend, legendHidden = false }) {
  return (
    <FieldSet legend={legend} legendHidden={legendHidden}>
      <Stack>{children}</Stack>
    </FieldSet>
  );
}

function Description({ children }) {
  return <SubduedText>{children}</SubduedText>;
}

function Fields({ children }) {
  return (
    <Box maxWidth="1200">
      <Columns collapseBelow="md" space={['500', '500', '500', '700']}>
        {children}
      </Columns>
    </Box>
  );
}

function Field({ children, variant }) {
  if (variant === 'slider') {
    return (
      <Column>
        <Box paddingX={['500', '500', '500', '300']}>{children}</Box>
      </Column>
    );
  }

  return <Column width={1 / 3}>{children}</Column>;
}

SliderFieldSet.propTypes = {
  legend: PropTypes.string.isRequired,
  legendHidden: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
};

Field.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  variant: PropTypes.oneOf(['slider', 'field'])
};

Description.displayName = 'SliderFieldSet.Description';
Fields.displayName = 'SliderFieldSet.Fields';
Field.displayName = 'SliderFieldSet.Field';
SliderFieldSet.Description = Description;
SliderFieldSet.Fields = Fields;
SliderFieldSet.Field = Field;

export { SliderFieldSet };
