import qs from 'qs';
import { isEmpty } from 'lodash';

function generateUrl({ path, queryParams }) {
  let url = path;

  if (!isEmpty(queryParams)) {
    url += `?${qs.stringify(queryParams)}`;
  }

  return url;
}

export function generateAnalyticsMetricsURL({ from, to, metric, filters, groupBy }) {
  const queryParams = {
    from,
    to,
    range: 'custom',
    precision: 'hour',
    metrics: [metric],
    report: 'summary'
  };

  if (filters?.length > 0) {
    queryParams.query_filters = JSON.stringify(filters);
  }

  if (groupBy) {
    queryParams.group_by = groupBy;
  }

  return generateUrl({ queryParams, path: '/signals/analytics' });
}

export function generateHealthScoreURL({ subaccount, resourceId, resourceValue }) {
  const isBrokenDown = resourceId && resourceValue;
  const hasSubaccount = subaccount !== null && subaccount !== undefined;
  const queryParams = {};
  let path = '/signals/health-score';

  if (isBrokenDown) {
    path += `/${resourceId}/${resourceValue}`;
  } else if (hasSubaccount) {
    path += `/sid/${subaccount}`;
  }

  if (isBrokenDown && hasSubaccount) {
    queryParams.subaccount = subaccount;
  }

  return generateUrl({ path, queryParams });
}

export function generateBlocklistURL({ provider, resource }) {
  const path = '/signals/blocklist/incidents';
  const queryParams = {};

  if (provider && resource) {
    queryParams.search = `${resource} ${provider}`;
  }

  return generateUrl({ path, queryParams });
}

export function generateUsageURL() {
  return generateUrl({ path: '/usage' });
}
