import React from 'react';
import {
  ScreenReaderOnly as MatchboxScreenReaderOnly,
  ScreenReaderOnlyProps
} from '@sparkpost/matchbox';

/**
 * Matchbox ScreenReaderOnly component.
 * @see https://design.sparkpost.com/components/screenreaderonly/
 */
type Props = Omit<ScreenReaderOnlyProps, 'as'> & {
  as?: string;
};

const ScreenReaderOnly = (props: Props): JSX.Element => {
  const { as: _as, ...rest } = props;
  return <MatchboxScreenReaderOnly {...rest} as={_as as ScreenReaderOnlyProps['as']} />;
};

MatchboxScreenReaderOnly.displayName = 'HibanaScreenReaderOnly';
ScreenReaderOnly.displayName = 'ScreenReaderOnly';

export default ScreenReaderOnly;
