import React from 'react';

import { tokens } from '@sparkpost/design-tokens';
import { Button, Modal } from 'src/components/matchbox';

export default function ExitSetupModal({ isModalOpen, closeModal, handleExit }) {
  return (
    <Modal open={isModalOpen} onClose={closeModal} showCloseButton maxWidth="1300">
      <Modal.Header>Exit</Modal.Header>
      <Modal.Content>Do you really want to leave?</Modal.Content>
      <Modal.Footer>
        <Button onClick={handleExit} variant="destructive" color="blue">
          Exit
        </Button>
        <Button onClick={closeModal} variant="monochrome-secondary" color={tokens.color_gray_900}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
