import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { listUsers } from 'src/actions/users';
import { Loading, TableCollection } from 'src/components';
import { PageLink } from 'src/components/links';
import {
  Button,
  Column,
  Columns,
  LabelValue,
  Layout,
  Panel,
  Stack,
  Tag
} from 'src/components/matchbox';
import User from 'src/pages/users/components/User';
import { selectUsers } from 'src/selectors/users';
import styled from 'styled-components';

const StyledPanelContent = styled.div`
  text-align: center;
`;

const columns = [
  { label: 'Name', width: '40%', sortKey: 'label' },
  { label: 'User Role', width: '20%' },
  { label: '2FA', width: '20%' },
  { label: 'Last Login', width: '20%' }
];

function UsersTab({ loading, id: subaccountId, subaccount, users, listUsers }) {
  const showEmpty = users.length === 0;

  useEffect(() => {
    subaccountId && listUsers(subaccountId);
  }, [listUsers, subaccountId]);

  const getRowData = useCallback(
    ({ name, email, username, roleLabel, tfa_enabled, last_login }) => {
      return [
        <User name={name} email={email} username={username} />,
        roleLabel,
        tfa_enabled ? <Tag>Enabled</Tag> : <Tag>Disabled</Tag>,
        last_login ? <TimeAgo date={last_login} live={false} /> : 'Never'
      ];
    },
    []
  );

  if (loading) {
    return <Loading minHeight="300px" />;
  }

  if (showEmpty) {
    return (
      <Panel>
        <Panel.Section>
          <StyledPanelContent>
            <Stack>
              <p>
                This subaccount has no Users assigned to it. You can assign an existing one, or
                create a new one.
              </p>

              <div>
                <PageLink as={Button} variant="secondary" to="/account/users">
                  Manage Users
                </PageLink>
              </div>
            </Stack>
          </StyledPanelContent>
        </Panel.Section>
      </Panel>
    );
  }

  return (
    <>
      <Panel>
        <Panel.Section>
          <Layout>
            <Layout.Section annotated>
              <Layout.SectionTitle>Details</Layout.SectionTitle>
            </Layout.Section>
            <Layout.Section>
              <Panel>
                <Panel.Section>
                  <Columns>
                    <Column width={1 / 5}>
                      <LabelValue>
                        <LabelValue.Label>Subaccount Name</LabelValue.Label>
                        <LabelValue.Value>{subaccount.name}</LabelValue.Value>
                      </LabelValue>
                    </Column>
                    <Column width={1 / 5}>
                      <LabelValue>
                        <LabelValue.Label>Subaccount ID</LabelValue.Label>
                        <LabelValue.Value>{subaccountId}</LabelValue.Value>
                      </LabelValue>
                    </Column>
                  </Columns>
                </Panel.Section>
              </Panel>
            </Layout.Section>
          </Layout>
          <Layout>
            <Layout.Section annotated>
              <Layout.SectionTitle>Subaccount Users</Layout.SectionTitle>
              <PageLink to="/account/users/create">Invite a user to this subaccount</PageLink>
            </Layout.Section>
            <Layout.Section>
              <TableCollection
                columns={columns}
                getRowData={getRowData}
                pagination={true}
                rows={users}
                filterBox={{
                  show: true,
                  keyMap: { role: 'roleLabel' },
                  exampleModifiers: ['name', 'email', 'role'],
                  itemToStringKeys: ['username', 'name', 'email']
                }}
                defaultSortColumn="name"
              />
            </Layout.Section>
          </Layout>
        </Panel.Section>
      </Panel>
    </>
  );
}

const mapStateToProps = (state) => ({
  loading: state.apiKeys.keysLoading,
  users: selectUsers(state)
});

export default withRouter(connect(mapStateToProps, { listUsers })(UsersTab));
