import isIP from 'validator/lib/isIP';

export const toSentence = (array, conjuctionTerm = 'and') => {
  const conjunction = array.length >= 3 ? `, ${conjuctionTerm} ` : ` ${conjuctionTerm} `;
  return (
    array.slice(0, -2).join(', ') + //combines all elements except the last 2 into comma separated items.
    (array.slice(0, -2).length ? ', ' : '') + //appends comma before appending last 2 items unless there are <=2 item in the whole array
    array.slice(-2).join(conjunction)
  ); //joins the last 2 items using oxford comma for >=3 items and append to end of sentence.
};

/**
 * @name toSelectOptions
 * @description remaps passed in array to exclude object properties that are not consumable by the Matchbox <Select /> options
 * @param {Array[Object]} arr - array of objects to remap to structure expected by the `<Select />` `options` prop
 * @returns {Array[Object]} returns an array of objects, excluding data irrelevant to select options
 */
export function toSelectOptions(arr) {
  return arr.map(({ value, label }) => {
    return {
      value,
      label
    };
  });
}

/**
 *
 * @param {any} value - arbitrary value
 * @returns {array} passed-in value converted to an array
 */
export function toArray(value) {
  return Array.isArray(value) ? value : [value];
}

/**
 *
 * @param {array} rows - Array of objects with properties
 * @param {string} sortColumn - Property to sort by
 * @param {string} sortDirection - Direction to sort by {asc|desc}
 *
 * @returns {array} new sorted array
 */
export function sortMissingToEnd(rows, sortColumn, sortDirection) {
  const arraryToSort = [...rows];
  const allStrings = arraryToSort.every((i) => typeof i[sortColumn] === 'string');

  const isMissing = (i) =>
    isNaN(i[sortColumn]) || i[sortColumn] === null || i[sortColumn] === false;

  const hasMissing = arraryToSort.some(isMissing);

  // When we return 1, the function communicates to sort() that the object b takes precedence in sorting over the object a.
  // Returning -1 would do the opposite.
  return arraryToSort.sort((a, b) => {
    if (!allStrings && hasMissing && isMissing(a)) {
      return 1;
    } else if (!allStrings && hasMissing && isMissing(b)) {
      return -1;
    }

    const bothAreIp = isIP(String(a[sortColumn])) && isIP(String(b[sortColumn]));

    if (bothAreIp) {
      const aValue = a[sortColumn].split('.');
      const bValue = b[sortColumn].split('.');

      let ipSort = 0;

      aValue.forEach((ipSection, index) => {
        if (ipSort) return;

        if (Number(ipSection) < Number(bValue[index])) {
          ipSort = sortDirection === 'desc' ? 1 : -1;
        } else if (Number(ipSection) > Number(bValue[index])) {
          ipSort = sortDirection === 'desc' ? -1 : 1;
        }
      });

      return ipSort;
    }

    if (a[sortColumn] < b[sortColumn]) {
      return sortDirection === 'desc' ? 1 : -1;
    }

    if (a[sortColumn] > b[sortColumn]) {
      return sortDirection === 'desc' ? -1 : 1;
    }

    return 0;
  });
}
