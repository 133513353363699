/* eslint-disable react/jsx-no-bind */
import { tokens } from '@sparkpost/design-tokens';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, formValueSelector, getFormValues, isInvalid, reduxForm } from 'redux-form';

import { ExternalLink, SubduedLink } from 'src/components/links';
import {
  Banner,
  Box,
  Button,
  Inline,
  Layout,
  Panel,
  Stack,
  Text,
  Tooltip
} from 'src/components/matchbox';
import CheckboxWrapper from 'src/components/reduxFormWrappers/CheckboxWrapper';
import { Bold, Heading, SubduedText, TranslatableText } from 'src/components/text';
import { Form } from 'src/components/tracking';
import { selectWebhooksDocs } from 'src/selectors/eventListing';
import { getSubaccounts, hasSubaccounts } from 'src/selectors/subaccounts';
import { getSelectedEvents, selectInitialSubaccountValue } from 'src/selectors/webhooks';
import formatEditValues from '../helpers/formatEditValues';
import {
  ActiveField,
  AuthRadio,
  BasicAuthFields,
  NameField,
  OAuth2Fields,
  TargetField
} from './Fields';
import { ColumnList } from './styles';
import SubaccountSection from './SubaccountSection';

const formName = 'webhookForm';

export const EventCheckBoxes = memo(
  ({ disabled, selectedEvents = [], showCheckboxBanner, change }) => {
    const events = useSelector(selectWebhooksDocs);
    const eventsEntries = Object.entries(events);
    const selector = formValueSelector(formName);
    const checkboxes = useSelector((state) => selector(state, 'events'));

    useEffect(() => {
      selectedEvents.forEach((event) => {
        change(`events.${event}`, true);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEvents]);

    const groupCheckboxes = useMemo(() => {
      const checkboxGroups = {};

      eventsEntries.map(([eventGroupKey, eventGroup]) => {
        const areAllEventsSelected = Object.entries(eventGroup.events || {}).every(
          (event) => checkboxes[event[0]]
        );

        if (areAllEventsSelected) {
          return (checkboxGroups[eventGroupKey] = true);
        }

        const areAllEventsUnSelected = Object.entries(eventGroup.events || {}).every(
          (event) => !checkboxes[event[0]]
        );

        if (areAllEventsUnSelected) {
          return (checkboxGroups[eventGroupKey] = false);
        }

        return (checkboxGroups[eventGroupKey] = 'indeterminate');
      });

      return checkboxGroups;
    }, [eventsEntries, checkboxes]);

    const handleCheckboxGroupClick = useCallback(
      (e, events) => {
        const isChecked =
          groupCheckboxes[e.target.name] === false ||
          groupCheckboxes[e.target.name] === 'indeterminate';

        Object.entries(events).forEach((event) => {
          change(`events.${event[0]}`, isChecked);
        });
      },
      [change, groupCheckboxes]
    );

    const handleCheckboxClick = useCallback(
      (e) => {
        const isChecked = checkboxes[e.target.name.split('.')[1]];
        change(e.target.name, !isChecked);
      },
      [change, checkboxes]
    );

    return (
      <Box>
        <Stack>
          {showCheckboxBanner && (
            <Box maxWidth="260px">
              <Banner status="danger" size="small">
                Select a minimum of 1 event.
              </Banner>
            </Box>
          )}
          {eventsEntries.map(([eventGroupKey, eventGroup]) => (
            <Box key={eventGroupKey}>
              <Tooltip dark content={eventGroup.description}>
                <Field
                  label={<Bold>{eventGroup.display_name}</Bold>}
                  type="checkbox"
                  name={eventGroupKey}
                  component={CheckboxWrapper}
                  disabled={disabled}
                  data-track={true}
                  checked={groupCheckboxes[eventGroupKey]}
                  onChange={(e) => handleCheckboxGroupClick(e, eventGroup.events)}
                />
              </Tooltip>

              <ColumnList count={Math.ceil(Object.entries(eventGroup.events || {}).length / 2)}>
                {Object.entries(eventGroup.events || {}).map(
                  ([key, { display_name, description, name = `events.${key}` }]) => (
                    <Box key={key}>
                      <Tooltip dark content={description}>
                        <Field
                          label={display_name}
                          type="checkbox"
                          name={name}
                          component={CheckboxWrapper}
                          disabled={disabled}
                          data-track={true}
                          onChange={handleCheckboxClick}
                        />
                      </Tooltip>
                    </Box>
                  )
                )}
              </ColumnList>
            </Box>
          ))}
        </Stack>
      </Box>
    );
  }
);

export function AuthFields({ authType, disabled, initialValues, newWebhook }) {
  if (authType === 'basic') {
    return (
      <BasicAuthFields disabled={disabled} initialValues={initialValues} newWebhook={newWebhook} />
    );
  }

  if (authType === 'oauth2') {
    return <OAuth2Fields disabled={disabled} initialValues={initialValues} />;
  }

  return null;
}

export function WebhookForm({
  handleSubmit,
  newWebhook,
  hasSubaccounts,
  pristine,
  submitting,
  initialValues,
  selectedEvents,
  showCheckboxBanner,
  change,
  openDeleteModal,
  onSubmitClick = () => {},
  exceptionSubaccounts,
  subaccounts
}) {
  const submitText = newWebhook ? 'Create Webhook' : 'Update Webhook';

  const state = useSelector((state) => state);
  const selector = formValueSelector(formName);
  const values = getFormValues(formName)(state);
  const isFormInvalid = isInvalid(formName)(state);
  const auth = selector(state, 'auth');

  return (
    <Form onSubmit={handleSubmit} id={newWebhook ? 'create-webhook-form' : 'update-webhook-form'}>
      {!newWebhook && (
        <Layout>
          <Layout.Section annotated>
            <Stack space="300">
              <Heading as="h4">Enable Webhook</Heading>
            </Stack>
          </Layout.Section>
          <Layout.Section>
            <Panel>
              <Panel.Section>
                <Stack space="100">
                  <Box display="flex" justifyContent="space-between">
                    <Inline space="800">
                      <Bold>Webhook Enabled</Bold>
                      <ActiveField onChange={handleSubmit} />
                    </Inline>
                  </Box>

                  <TranslatableText>
                    A disabled webhook will not transmit any data.
                  </TranslatableText>
                </Stack>
              </Panel.Section>
            </Panel>
          </Layout.Section>
        </Layout>
      )}
      <Layout>
        <Layout.Section annotated>
          <Stack space="300">
            <Heading as="h4">Details</Heading>
            <SubduedText>
              Push raw events so other servers can track the progress and responses to email using
              Webhooks. Events are delivered through a POST request to the defined target URL.
            </SubduedText>
            <SubduedLink
              as={ExternalLink}
              to="https://support.sparkpost.com/docs/tech-resources/webhook-event-reference"
            >
              Webhooks Documentation
            </SubduedLink>
          </Stack>
        </Layout.Section>

        <Layout.Section>
          <Panel>
            <Panel.Section>
              <Stack>
                <NameField disabled={submitting} placeholder="e.g. Bounce Webhook" />
                <TargetField
                  disabled={submitting}
                  placeholder="e.g. https://example.com/mywebhook"
                />
              </Stack>
            </Panel.Section>
            {hasSubaccounts && subaccounts.length > 0 ? (
              <Panel.Section>
                <SubaccountSection
                  newWebhook={newWebhook}
                  formName={formName}
                  disabled={submitting}
                  subaccounts={subaccounts}
                  exceptionSubaccounts={exceptionSubaccounts}
                />
              </Panel.Section>
            ) : null}
            {!newWebhook && (
              <Panel.Section>
                <Button
                  submit
                  variant="secondary"
                  disabled={pristine}
                  loading={submitting}
                  onClick={handleSubmit}
                >
                  <TranslatableText>Update Details</TranslatableText>
                </Button>
              </Panel.Section>
            )}
          </Panel>
        </Layout.Section>
      </Layout>
      <Layout>
        <Layout.Section annotated>
          <Stack space="300">
            <Heading as="h4">Event Selection</Heading>
            <SubduedText>
              There are several types of events that can be sent to provide data concerning an
              email's life cycle. It is recommended that event selection is specific to the needs of
              each webhook.
            </SubduedText>
            <SubduedLink
              as={ExternalLink}
              to="https://support.sparkpost.com/docs/tech-resources/sparkpost-event-definitions"
            >
              Event Documentation
            </SubduedLink>
          </Stack>
        </Layout.Section>
        <Layout.Section>
          <Panel>
            <Panel.Header>Events</Panel.Header>
            <Panel.Section>
              <Stack>
                <EventCheckBoxes
                  disabled={submitting}
                  selectedEvents={selectedEvents}
                  showCheckboxBanner={showCheckboxBanner}
                  change={change}
                />
              </Stack>
            </Panel.Section>
            {!newWebhook && (
              <Panel.Section>
                <Button submit variant="secondary" disabled={pristine} loading={submitting}>
                  <TranslatableText>Update Events</TranslatableText>
                </Button>
              </Panel.Section>
            )}
          </Panel>
        </Layout.Section>
      </Layout>
      <Layout>
        <Layout.Section annotated>
          <Stack space="300">
            <Heading as="h4">Authentication</Heading>
            <SubduedText>
              Implement security measures to increase the security of webhook event data and ensure
              that the data delivered originates from SparkPost.
            </SubduedText>
            <SubduedLink
              as={ExternalLink}
              to="https://support.sparkpost.com/docs/tech-resources/webhook-authentication"
            >
              Authentication Documentation
            </SubduedLink>
          </Stack>
        </Layout.Section>
        <Layout.Section>
          <Panel>
            <Panel.Section>
              <Stack>
                <AuthRadio disabled={submitting} authType={auth} />
                <AuthFields
                  authType={auth}
                  disabled={submitting}
                  initialValues={initialValues}
                  newWebhook={newWebhook}
                />
              </Stack>
            </Panel.Section>
            {!newWebhook && (
              <Panel.Section>
                <Button submit variant="secondary" disabled={pristine} loading={submitting}>
                  <TranslatableText>Update Authentication</TranslatableText>
                </Button>
              </Panel.Section>
            )}
          </Panel>
        </Layout.Section>
      </Layout>
      {!newWebhook && (
        <Layout>
          <Layout.Section annotated>
            <Stack space="300">
              <Heading as="h4">Delete Webhook</Heading>
            </Stack>
          </Layout.Section>
          <Layout.Section>
            <Panel borderTop="4px solid #D9363E">
              <Box padding={tokens.spacing_450}>
                <Text color={tokens.color_gray_900}>
                  Deleting a webhook is permanent and cannot be undone.
                </Text>
              </Box>
            </Panel>
            <Panel>
              <Panel.Section>
                <Button color="red" variant="outline-destructive" onClick={openDeleteModal}>
                  Delete Webhook
                </Button>
              </Panel.Section>
            </Panel>
          </Layout.Section>
        </Layout>
      )}
      {newWebhook && (
        <Layout>
          <Layout.Section annotated />
          <Layout.Section>
            <Panel.Section>
              <Button
                submit
                variant="primary"
                disabled={pristine}
                loading={submitting}
                onClick={() => onSubmitClick(values, isFormInvalid)}
              >
                <TranslatableText>{submitText}</TranslatableText>
              </Button>
            </Panel.Section>
          </Layout.Section>
        </Layout>
      )}
    </Form>
  );
}

const mapStateToProps = (state, props) => {
  const { showCheckboxBanner, openDeleteModal } = props;
  const selector = formValueSelector(formName);
  const authState = selector(state, 'auth');

  const auth = props.newWebhook ? 'oauth2' : authState;

  const webhookValues = props.newWebhook ? { auth } : formatEditValues(state.webhooks.webhook);

  return {
    hasSubaccounts: hasSubaccounts(state),
    subaccounts: getSubaccounts(state),
    initialValues: {
      assignTo: 'all',
      subaccount: !props.newWebhook ? selectInitialSubaccountValue(state, props) : null,
      ...webhookValues,
      events: props.newWebhook ? {} : getSelectedEvents(state)
    },
    showCheckboxBanner,
    openDeleteModal
  };
};

const formOptions = {
  form: formName,
  enableReinitialize: true
};

export default withRouter(connect(mapStateToProps, {})(reduxForm(formOptions)(WebhookForm)));
