import React from 'react';
import { useSparkPostQuery } from 'src/hooks';
import { useParams } from 'react-router-dom';
import { ApiErrorBanner, Loading } from 'src/components';
import { Banner, Layout, Stack } from 'src/components/matchbox';
import { SubduedText } from 'src/components/text';
import { InlineSeedingSettingsPanel } from './InlineSeedingSettingsPanel';
import { EnableAutomaticSeedingPanel } from './EnableAutomaticSeedingPanel';
import { getInlineSeedsOptions } from 'src/helpers/api/seedList';

export function AccountConfig() {
  const { subaccountId } = useParams();
  const inlineSeedsOptionsQuery = useSparkPostQuery(() => getInlineSeedsOptions({ subaccountId }));

  if (inlineSeedsOptionsQuery.status === 'loading') return <Loading />;

  if (inlineSeedsOptionsQuery.status === 'error')
    return (
      <ApiErrorBanner
        message="Sorry, we seem to have had some trouble loading your inline seed list settings."
        reload={inlineSeedsOptionsQuery.refetch}
        errorDetails={inlineSeedsOptionsQuery.error?.message}
      />
    );

  return (
    <Stack>
      {inlineSeedsOptionsQuery.data?.account_enabled === false ? (
        <Banner status="warning" title="Automatic Inline Seeding Blocked">
          <p>
            Automatic Inline Seeding has been blocked across all subaccounts by the primary account.
          </p>
        </Banner>
      ) : null}

      <Layout>
        <Layout.Section annotated>
          <Layout.SectionTitle>Enable</Layout.SectionTitle>
        </Layout.Section>

        <Layout.Section>
          <EnableAutomaticSeedingPanel inlineSeedsOptionsQuery={inlineSeedsOptionsQuery} />
        </Layout.Section>
      </Layout>

      <Layout>
        <Layout.Section annotated>
          <Layout.SectionTitle>Inline Seeding Settings</Layout.SectionTitle>

          <SubduedText>
            Specify campaigns and criteria for seeding. By enabling inline seeding SparkPost will
            automatically inject seeds using the configurations set here. Choose exclude none to
            seed all Campaigns.
          </SubduedText>

          {/* TODO: Incorporate this once docs are available */}
          {/* <div>
          <SubduedLink as={ExternalLink} to="">
            Inline Seeding Documentation
          </SubduedLink>
          </div> */}
        </Layout.Section>

        <Layout.Section>
          <InlineSeedingSettingsPanel
            primaryAcctBlocked={inlineSeedsOptionsQuery.data?.account_enabled === false}
          />
        </Layout.Section>
      </Layout>
    </Stack>
  );
}
