/**
 * Generates a query string from object
 * @param  {Object} params
 * @return {String} query string
 */

export function getQueryStringFromObject(params) {
  return Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
}
