import _ from 'lodash';

/**
 * @description Remaps UI form data to array of recipients as expected by the Suppressions API
 * @param {Object} data - form data from the suppressions create/edit form
 * @param {string} data.recipient - email address of the recipient to be suppressed
 * @param {string} data.description - string description of the suppression
 * @param {Object} data.type - contains keys for each type of suppression
 * @param {boolean} data.type.transactional - whether the suppression is transactional
 * @param {boolean} data.type.non_transactional - whether the suppression is non-transactional
 * @returns array of recipients as required when making a PUT to /api/v1/suppression-list - see the [API docs](https://developers.sparkpost.com/api/suppression-list/#suppression-list-put-bulk-create-or-update-suppressions) for more
 */
export function toRecipientsArray(data) {
  const { recipient, description, type } = data;

  return _.reduce(
    type,
    (result, checked, type) => {
      if (!checked) {
        return result;
      }

      return [...result, { recipient, description, type }];
    },
    []
  );
}
