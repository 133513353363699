import React from 'react';
import { Empty } from 'src/components';
import { LabelValueLoadingSkeleton } from 'src/components/loading';
import { Columns, Column, LabelValue, Panel, Tag, UnstyledLink } from 'src/components/matchbox';
import { getAbTests } from 'src/helpers/api/abTests';
import { useSparkPostQuery } from 'src/hooks';
import useResourceDetailsContext from 'src/pages/signals/hooks/useResourceDetailsContext';
import DataSources from './DataSources';

export default function TemplateMetrics({
  hasD12yProduct,
  deliverabilityData,
  deliverabilityDataStatus
}) {
  const { match: { params: { id } = {} } = {} } = useResourceDetailsContext();

  const { status: abTestStatus, data: abTestData, error } = useSparkPostQuery(() => getAbTests());

  let abtestAmount = 0;
  if (abTestStatus === 'success') {
    let filteredTests = abTestData.filter(function (test) {
      return test.default_template.template_id === id;
    });
    abtestAmount = filteredTests.length;
  }

  const labelValues = [
    { value: 'Template', width: 1 / 6 },
    { value: 'A/B Tests', width: 1 / 6 },
    { value: 'Sent', width: 1 / 6 },
    hasD12yProduct && { width: 1 / 6, value: 'Seed Count' },
    hasD12yProduct && { width: 1 / 6, value: 'Panel Count' }
  ];

  const abTestErrored = abTestStatus === 'error' && error?.response?.status !== 404;
  const dataLoading = abTestStatus === 'loading' || deliverabilityDataStatus === 'loading';
  const dataErrored = abTestErrored || deliverabilityDataStatus === 'error';

  if (dataErrored) {
    return <Empty message="Failed to load the data. Refresh page."></Empty>;
  }

  if (dataLoading) {
    return (
      <Panel.Section>
        <LabelValueLoadingSkeleton labelValues={labelValues} />
      </Panel.Section>
    );
  }

  return (
    <div data-id="template-top-section">
      <Panel.Section>
        <Columns>
          <Column width={1 / 6}>
            <LabelValue>
              <LabelValue.Label>Template</LabelValue.Label>
              <LabelValue.Value>{id}</LabelValue.Value>
            </LabelValue>
          </Column>

          <Column width={1 / 6}>
            <LabelValue>
              <LabelValue.Label>A/B Tests</LabelValue.Label>
              <LabelValue.Value>
                {error?.response?.status === 404 || abtestAmount === 0 ? (
                  <Tag>None</Tag>
                ) : (
                  <UnstyledLink to={'/ab-testing?templates=' + id}>{abtestAmount}</UnstyledLink>
                )}
              </LabelValue.Value>
            </LabelValue>
          </Column>

          <DataSources
            dataDisplayOnly={true}
            hasD12yProduct={hasD12yProduct}
            deliverabilityData={deliverabilityData}
            deliverabilityDataStatus={deliverabilityDataStatus}
          />
        </Columns>
      </Panel.Section>
    </div>
  );
}
