import React from 'react';
import { Tag, Text } from 'src/components/matchbox';
import { snakeToFriendly } from 'src/helpers/string';
import styled from 'styled-components';

export const StyledIcon = styled.span`
  display: inline-block;
  margin-left: ${({ theme }) => theme.space[100]};
  transform: translateY(-1px);
`;

export const SeverityWrapper = styled.div``;

export const FiltersBar = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.space['400']};
  grid-template-columns: 1fr;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-gap: ${(props) => props.theme.space['500']};
    grid-template-columns: 2fr auto 280px; /* NOTE: AlignedTextButton gets max width at the same time for this grid layout to work */

    @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
      ${SeverityWrapper} {
        margin-right: 170px;
      }
    }
  }
`;

export const TableWrapper = styled.div`
  overflow-x: auto;

  & > * {
    margin-bottom: 0;
  }
`;

export const Label = ({ children }) => <Text fontWeight="medium">{children}</Text>;

export const AlertSeverityTag = ({ severity }) => {
  const SEVERITY_COLORS = {
    high: 'red',
    medium: 'yellow',
    low: 'lightGray'
  };

  return (
    <Tag color={SEVERITY_COLORS[severity] || SEVERITY_COLORS.low}>{snakeToFriendly(severity)}</Tag>
  );
};
