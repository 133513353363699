import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
// eslint-disable-next-line local/no-css-module-import
import styles from './FocusContainer.module.scss';

interface FocusContainerProps {
  children: React.ReactNode;
  className: string;
}

type FocusContainerRef = null | HTMLDivElement;

// see, https://stackoverflow.com/a/53188569
const FocusContainer = ({ className, ...props }: FocusContainerProps) => {
  const ref = useRef<FocusContainerRef>(null);

  useEffect(() => {
    if (ref.current !== null) ref.current.focus();
  }, []);

  return (
    <div
      {...props}
      className={classNames(className, styles.FocusContainer)}
      ref={ref}
      tabIndex={-1}
    />
  );
};

FocusContainer.propTypes = {
  className: PropTypes.string
};

export default FocusContainer;
