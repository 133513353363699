import React from 'react';
import { ExternalLink } from '../links';
import { Button, Text } from '../matchbox';
import { Heading } from '../text';
import { Buttons, Floating, PrivacyWrapper, Widget } from './styles';

type Props = {
  expanded?: boolean;
  floating?: boolean;
  onAccept: VoidFunction;
  onDecline: VoidFunction;
};

export const MbConsentCookieBar = ({ onAccept, onDecline, expanded, floating = false }: Props) => {
  const Wrapper = floating ? Floating : 'div';

  return (
    <Wrapper>
      <Widget>
        <PrivacyWrapper>
          {expanded && <Heading as="h6">This website uses cookies</Heading>}

          <div>
            <Text>
              By clicking “Accept”, you agree to the use of cookies on your device or browser to
              enhance site navigation, analyze site usage, and assist in our marketing efforts.
            </Text>
          </div>
          <div>
            <ExternalLink to="https://www.sparkpost.com/policies/privacy/">
              Cookie Policy
            </ExternalLink>
          </div>
        </PrivacyWrapper>

        <Buttons>
          <Button variant="primary" size="small" onClick={onAccept}>
            Accept
          </Button>

          <Button variant="monochrome-secondary" size="small" onClick={onDecline}>
            Decline
          </Button>
        </Buttons>
      </Widget>
    </Wrapper>
  );
};
