import { Box, Text } from 'src/components/matchbox';
import styled from 'styled-components';

export const SubjectText = styled(Text)`
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const TimestampText = styled(Text)`
  white-space: nowrap;
`;

export const AddFiltersButtonWrapper = styled(Box)`
  @media (min-width: ${({ theme }): string => theme.breakpoints[2]}) {
    padding-top: ${({ theme }): string => theme.space['500']};
  }
`;
