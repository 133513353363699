import React, { useCallback, useEffect, useRef } from 'react';

import { SliderFieldSet } from 'src/components';
import { Box, Slider, Stack, Text, TextField } from 'src/components/matchbox';
import { Heading } from 'src/components/text';
import { maxNumber, minNumber, required } from 'src/helpers/validation';
import styled from 'styled-components';
import { useAlertForm } from '../useAlertForm';

const SuffixText = styled.span`
  margin-left: -45px;
  color: #626f7a;
`;

export default function UsageMetric() {
  const {
    state: { accountData, conditionData },
    dispatch
  } = useAlertForm();
  const firstAccess = useRef(true);

  useEffect(() => {
    firstAccess.current = true;
  }, []);

  const { alertThreshold, precision } = conditionData;
  const setAlertThreshold = useCallback(
    (alertThreshold) => {
      if (alertThreshold < 0 || alertThreshold > 100) return;
      dispatch({
        type: 'UPDATE_CONDITION_DATA',
        data: { alertThreshold }
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (alertThreshold === 0) {
      setAlertThreshold(firstAccess.current ? 80 : 1);
      firstAccess.current = false;
    }
  }, [alertThreshold, precision, setAlertThreshold]);

  return (
    <>
      <Stack>
        <Heading as="h4">Metric and Filters</Heading>
        <Box>
          <Text as="span">Customize the alert by defining specific filters.</Text>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Text as="h5">Metric</Text>
            <Text>Account Usage</Text>
          </Box>
          <Box mr="25%">
            <Text as="h5">Subaccount Assignment</Text>
            <Text>
              {accountData.assignment === 'subaccount' && accountData.subaccount.value !== undefined
                ? accountData.subaccount.value
                : 'Primary Account'}
            </Text>
          </Box>
        </Box>
        <Box display="flex">
          <Box width="66%">
            <Text as="h5">Monthly Usage Limit Percentage</Text>
            <SliderFieldSet>
              <SliderFieldSet.Fields>
                <SliderFieldSet.Field variant="slider">
                  <Slider
                    id="seeding-threshold-slider"
                    aria-labelledby="seeding-threshold-label"
                    min={0}
                    max={100}
                    ticks={{
                      80: 'Recommended'
                    }}
                    value={alertThreshold}
                    onChange={setAlertThreshold}
                  />
                </SliderFieldSet.Field>

                <SliderFieldSet.Field variant="field">
                  <TextField
                    id="seeding-threshold-fine-grained"
                    name="threshold"
                    maxWidth={['100%', '100%', '100%', '110px']}
                    validate={[required, minNumber(1), maxNumber(100)]}
                    type="number"
                    max="100"
                    suffix={<SuffixText>%</SuffixText>}
                    data-lpignore="true"
                    data-track={true}
                    value={alertThreshold}
                    onChange={(e) => {
                      setAlertThreshold(e.target.value);
                    }}
                  />
                </SliderFieldSet.Field>
              </SliderFieldSet.Fields>
            </SliderFieldSet>
          </Box>
        </Box>
      </Stack>
    </>
  );
}
