import { tokens } from '@sparkpost/design-tokens';
import { getLineChartFormatters, getRelativeDates } from '@sparkpost/report-builder/helpers/date';
import { getMetricsFromKeys, toQueryFromOptions } from '@sparkpost/report-builder/helpers/metrics';
import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import METRICS_UNIT_CONFIG from 'src/appConfig/metrics-units';
import { ActiveFilters, Empty, Loading } from 'src/components';
import CustomTooltip from 'src/components/analyticsReport/Tooltip';
import LineChart from 'src/components/charts/LineChart';
import { Box, Inline, ListBox, Panel, Stack, Text } from 'src/components/matchbox';
import { SubduedText } from 'src/components/text';
import { TimezoneTypeahead } from 'src/components/typeahead/TimezoneTypeahead';
import { getTimeSeries } from 'src/helpers/api/metrics';
import { transformData } from 'src/helpers/metrics';
import { useSparkPostQuery } from 'src/hooks';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import styled from 'styled-components';

const dateTimeReducer = (state, action) => {
  switch (action.type) {
    case 'SET_RANGE':
      const ret = { ...state };
      if (action.value !== 'day') {
        ret.selectedPrecision = 'day';
        ret.selectedTimezone = 'UTC';
      }
      return { ...ret, selectedRange: action.value };
    case 'SET_TIMEZONE':
      return { ...state, selectedTimezone: action.value };
    case 'SET_PRECISION':
      return { ...state, selectedPrecision: action.value };
    default:
      throw new Error(`${action.type} is not a valid action for this reducer`);
  }
};
const initialState = {
  selectedRange: 'day',
  timezone: 'UTC',
  selectedPrecision: 'hour'
};

const StyledQuote = styled(Text).attrs({
  as: 'blockquote'
})`
  border-left: 1px solid #d9e0e6;
  padding-left: ${tokens.spacing_200};
  margin-left: ${tokens.spacing_200};
  margin-top: 0;
  color: ${tokens.color_gray_700};
`;

const LoadingContainer = styled(Box)`
  svg {
    margin-top: ${tokens.spacing_850};
  }
`;

export default function AlertSummary({ alert }) {
  const [state, dispatch] = React.useReducer(dateTimeReducer, initialState);
  const selectedMetric = { key: alert.alert_configuration.metric };
  const threshold = alert.alert_configuration.conditions.value;
  const operator = alert.alert_configuration.conditions.operator;
  const schedule = alert.alert_configuration.notification_schedule;
  const hasSelectedMetric = Boolean(selectedMetric.key);
  const forcePrecisionDay = state.selectedRange !== 'day';
  const isEditingAlert = useRouteMatch('/alerts/edit/:alertId');

  const formattedMetrics = useMemo(() => {
    if (!hasSelectedMetric) {
      return [];
    }
    return getMetricsFromKeys([selectedMetric], true);
  }, [selectedMetric, hasSelectedMetric]);

  const { from, to } = getRelativeDates(state.selectedRange);

  const { state: reportOptions } = useAnalyticsReportContext();

  const query = toQueryFromOptions({
    filters: reportOptions.filters,
    metrics: formattedMetrics,
    from,
    to,
    precision: state.selectedPrecision,
    timezone: state.selectedTimezone
  });

  const { status, data } = useSparkPostQuery(() => getTimeSeries(query), {
    enabled: Boolean(selectedMetric.key),
    select: (data) => {
      return transformData(data, formattedMetrics);
    }
  });

  const formattedMetric = formattedMetrics[0] || {};
  const unit = formattedMetrics[0]?.unit;
  const chartConfig = METRICS_UNIT_CONFIG[unit];
  const formatters = getLineChartFormatters(state.selectedPrecision, to);
  const line = [
    {
      key: formattedMetric.key,
      stroke: formattedMetric.stroke,
      dataKey: formattedMetric.key,
      name: formattedMetric.label
    }
  ];

  const setRange = useCallback(
    (e) => {
      dispatch({ type: 'SET_RANGE', value: e.currentTarget.value });
    },
    [dispatch]
  );

  const setTimezone = useCallback(
    ({ value }) => {
      dispatch({ type: 'SET_TIMEZONE', value });
    },
    [dispatch]
  );

  const setPrecision = useCallback(
    (e) => {
      dispatch({ type: 'SET_RANGE', value: e.currentTarget.value });
    },
    [dispatch]
  );

  return (
    <Box mb="600">
      {status === 'success' || status === 'loading' ? (
        <Panel>
          <Panel.Section>
            <Stack>
              <SubduedText>Incident History</SubduedText>
              {reportOptions.filters.length > 0 && (
                <StyledQuote>
                  <ActiveFilters filters={reportOptions.filters} />
                </StyledQuote>
              )}
            </Stack>
          </Panel.Section>
          <Panel.Section>
            <Stack>
              <Inline>
                <ListBox
                  value={state.selectedRange}
                  onChange={setRange}
                  label="Time Range"
                  id="alert-time-range"
                >
                  <ListBox.Option value="day">Last 24 hours</ListBox.Option>
                  <ListBox.Option value="7days">Last 7 days</ListBox.Option>
                  <ListBox.Option value="30days">Last 30 days</ListBox.Option>
                  <ListBox.Option value="90days">Last 90 days</ListBox.Option>
                </ListBox>
                <TimezoneTypeahead
                  id="alert-time-zone"
                  disabled={forcePrecisionDay}
                  value={state.selectedTimezone}
                  onChange={setTimezone}
                />
                <ListBox
                  id="alert-precision"
                  value={state.selectedPrecision}
                  label="Precision"
                  onChange={setPrecision}
                  disabled={forcePrecisionDay || isEditingAlert}
                >
                  <ListBox.Option value="hour">Hour</ListBox.Option>
                  <ListBox.Option value="day">Day</ListBox.Option>
                </ListBox>
              </Inline>

              {status !== 'loading' ? (
                <LineChart
                  {...formatters}
                  yTickFormatter={chartConfig.yAxisFormatter}
                  showXAxis
                  height={200}
                  unit={unit}
                  data={data || []}
                  lines={line}
                  tooltip={CustomTooltip}
                  tooltipValueFormatter={chartConfig.yAxisFormatter}
                  showTooltip
                  threshold={threshold}
                  operator={operator}
                  schedule={schedule}
                />
              ) : (
                <LoadingContainer>
                  <Loading as={Box} minHeight={tokens.spacing_900} />
                </LoadingContainer>
              )}
            </Stack>
          </Panel.Section>
        </Panel>
      ) : (
        <Empty message="No Data Available" description="Select a metric to view graph." />
      )}
    </Box>
  );
}
