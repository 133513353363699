import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { verifyAndLogin } from 'src/actions/tfa';
import { TextFieldWrapper } from 'src/components';
import { Form } from 'src/components/tracking';
import { ExternalLink } from 'src/components/links';
import { Box, Button, Error, Stack } from 'src/components/matchbox';
import { LINKS } from 'src/constants';
import { required } from 'src/helpers/validation';
import styles from './TfaForm.module.scss';

export class TfaForm extends Component {
  handleSubmit = ({ code }) => {
    const { enabled: _enabled, ...authData } = this.props.tfa;

    return this.props.verifyAndLogin({ authData, code }).catch((err) => {
      if (err.response.status === 400 || err.response.status === 403) {
        throw new SubmissionError({
          _error: 'The code is invalid. Please contact login.issues@sparkpost.com for assistance.'
        });
      }
    });
  };

  render() {
    const { tfaPending, pristine, error } = this.props;

    return (
      <Form onSubmit={this.props.handleSubmit(this.handleSubmit)} id="tfa-form">
        <Stack>
          <p>
            Enter the code generated by your two-factor auth application, or one of your{' '}
            <ExternalLink to={LINKS.TFA_BACKUP_CODES}>backup codes</ExternalLink>.
          </p>
          <Field
            name="code"
            id="code"
            label="Code"
            placeholder="123456"
            component={TextFieldWrapper}
            validate={required}
            data-sensitive={true}
            autoComplete="off"
          />
          {error && <Error wrapper="div" error={error} className={styles.TFASubmissionError} />}
          <Box>
            <Button variant="primary" submit disabled={tfaPending || pristine}>
              {tfaPending ? 'Logging In' : 'Log In'}
            </Button>
          </Box>
        </Stack>
      </Form>
    );
  }
}

function mapStateToProps({ tfa }) {
  return {
    tfa,
    tfaPending: tfa.tfaPending,
    initialValues: {
      code: tfa.code
    }
  };
}

const formOptions = {
  form: 'tfaForm'
};

export default connect(mapStateToProps, { verifyAndLogin })(reduxForm(formOptions)(TfaForm));
