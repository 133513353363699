import { Search } from '@sparkpost/matchbox-icons';
import React, { ChangeEvent } from 'react';
import { Label, Panel, ScreenReaderOnly, TextField } from 'src/components/matchbox';
import { useHibana } from 'src/context/HibanaContext';
import { RowProps } from './Collection.types';
import styles from './FilterBox.module.scss';
import { getRandomExampleSearch } from './helpers/exampleSearch';

interface CollectionFilterBoxProps {
  initialValue: number;
  label?: string;
  maxWidth?: string;
  onBlur?: (pattern: string) => void;
  onChange: (pattern: string) => void;
  placeholder?: string;
  rows: Array<RowProps>;
  wrapper?: (text: JSX.Element) => JSX.Element;
}

function CollectionFilterBox(props: CollectionFilterBoxProps): JSX.Element {
  const [state] = useHibana();
  const { isHibanaEnabled } = state;

  const {
    initialValue,
    placeholder,
    wrapper,
    onChange,
    onBlur,
    maxWidth,
    label = 'Filter By'
  } = props;

  const placeholderText = placeholder || `Filter results e.g. ${getRandomExampleSearch(props)}`;

  const handleOnChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => onChange(e.target.value),
    [onChange]
  );

  const handleOnBlur = React.useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
      onBlur && onBlur(e.target.value),
    [onBlur]
  );

  const text = (
    <>
      {!isHibanaEnabled && (
        <Label id="collection-filter-box" className={styles.FilterBoxLabel}>
          <ScreenReaderOnly>{label}</ScreenReaderOnly>
        </Label>
      )}

      <TextField
        labelHidden={!label}
        label={isHibanaEnabled ? label : undefined}
        id="collection-filter-box"
        data-id="collection-filter-box"
        name="collection-filter-box"
        prefix={<Search />}
        placeholder={placeholderText}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        defaultValue={initialValue}
        maxWidth={maxWidth}
        data-track={true}
      />
    </>
  );

  return wrapper ? (
    wrapper(text)
  ) : (
    <Panel.LEGACY className={styles.Panel} sectioned>
      {text}
    </Panel.LEGACY>
  );
}

export default CollectionFilterBox;
