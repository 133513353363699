import { tokens } from '@sparkpost/design-tokens';
import { INDUSTRY_BENCHMARK_METRICS_MAP } from '@sparkpost/report-builder/config';
import React from 'react';
import { LegendCircle } from 'src/components';
import { Box, Text } from 'src/components/matchbox';
import { DOTTED_LINE_PREFIX_KEY } from 'src/constants';

export function sanitizePayload(payload) {
  let removePrefixedKeys = payload.map((item) => {
    item.name = item.name?.replace(DOTTED_LINE_PREFIX_KEY, '');
    item.dataKey = item.dataKey?.replace(DOTTED_LINE_PREFIX_KEY, '');
    return item;
  });

  return removePrefixedKeys.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);
}

function CustomTooltip(props) {
  let { showTooltip, payload, label, labelFormatter, formatter, data, labelForDottedLine } = props;
  if (!showTooltip) {
    return null;
  }

  payload = sanitizePayload(payload);
  const industryRate = payload.find((entry) => entry.dataKey === 'industry_rate');
  const isLastDataPoint = data.length - 1 > 0 ? data[data.length - 1].ts === label : false;

  return (
    <Box borderRadius="200" padding="200" bg="gray.1000">
      {isLastDataPoint && labelForDottedLine && (
        <Box fontSize="100" color="gray.200" mb="100">
          <span>{labelForDottedLine}</span>
        </Box>
      )}
      <Box fontSize="100" color="gray.200" mb="100">
        <span>{labelFormatter(label)}</span>
      </Box>
      {payload.map((entry) => {
        if (
          entry.dataKey === 'industry_rate' ||
          entry.dataKey === 'chart_threshold' ||
          entry.dataKey === 'is_chart_threshold_muted'
        ) {
          return null;
        }

        let industryRateCommaFormatted;
        if (INDUSTRY_BENCHMARK_METRICS_MAP[entry.dataKey] && industryRate) {
          industryRateCommaFormatted = `${industryRate.value[2]}, ${industryRate.value[3]}`;
        }

        return (
          <Box key={`report_chart_${entry.name}`}>
            <Box mb="100">
              <Box justifyContent="space-between" alignItems="center" display="flex">
                <Box display="inline-flex" alignItems="center">
                  <LegendCircle mr={tokens.spacing_300} color={entry.stroke} />
                  <Text as="span" fontSize="100" color="white">
                    <span>{entry.name}</span>
                  </Text>
                </Box>
                <Box ml="800">
                  <Text fontSize="100" textAlign="right" color="white">
                    <span>{formatter(entry.value)}</span>
                  </Text>
                </Box>
              </Box>
            </Box>

            {INDUSTRY_BENCHMARK_METRICS_MAP[entry.dataKey] && industryRate && (
              <Box mb="100">
                {/* Label for the Industry and Mailbox Provider */}
                {Boolean(industryRate.value[2]) && Boolean(industryRate.value[3]) && (
                  <Box display="inline-flex" alignItems="center">
                    {/* Spacer */}
                    <Box mr={tokens.spacing_300} height="16px" width="16px" />
                    <Text as="span" fontSize="100" color="white">
                      <span>{industryRateCommaFormatted}</span>
                    </Text>
                    {/* Spacer */}
                    <Box ml="800"></Box>
                  </Box>
                )}
                {/* 25th percentile for industry benchmark */}
                {Boolean(industryRate.value[0]) && (
                  <Box justifyContent="space-between" alignItems="center" display="flex">
                    <Box display="inline-flex" alignItems="center">
                      <Box mr={tokens.spacing_300} height="16px" width="16px" />
                      <Text as="span" fontWeight="light" fontSize="100" color="white">
                        25th percentile
                      </Text>
                    </Box>
                    <Box ml="800">
                      <Text fontSize="100" fontWeight="light" textAlign="right" color="white">
                        <span>{formatter(industryRate.value[0])}</span>
                      </Text>
                    </Box>
                  </Box>
                )}
                {/* 75th percentile for industry benchmark */}
                {Boolean(industryRate.value[1]) && (
                  <Box justifyContent="space-between" alignItems="center" display="flex">
                    <Box display="inline-flex" alignItems="center">
                      <Box mr={tokens.spacing_300} height="16px" width="16px" />
                      <Text as="span" fontWeight="light" fontSize="100" color="white">
                        75th percentile
                      </Text>
                    </Box>
                    <Box ml="800">
                      <Text fontSize="100" fontWeight="light" textAlign="right" color="white">
                        <span>{formatter(industryRate.value[1])}</span>
                      </Text>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
}

export default CustomTooltip;
