import React, { useEffect } from 'react';
import CreditCardSection from 'src/components/billing/CreditCardSection';
import { Button, Stack } from 'src/components/matchbox';
import { connect } from 'react-redux';
import { updatePaymentInitialValues } from 'src/selectors/accountBillingForms';
import { isManuallyBilled } from 'src/selectors/accountBillingInfo';
import { getBillingCountries } from 'src/actions/billing';

function ValidateSection({
  credit_card,
  billingCountries,
  getBillingCountries,
  isManuallyBilled,
  submitButtonName = 'Validate',
  submitDisabled,
  formname: FORMNAME,
  handleCardToggle,
  defaultToggleState,
  isProductOnSubscription
}) {
  useEffect(() => {
    getBillingCountries();
  }, [getBillingCountries]);
  return (
    <>
      <Stack>
        {!isManuallyBilled && (
          <CreditCardSection
            creditCard={credit_card}
            handleCardToggle={handleCardToggle}
            formname={FORMNAME}
            countries={billingCountries || []}
            defaultToggleState={defaultToggleState}
          />
        )}
        {isManuallyBilled && !isProductOnSubscription ? (
          <div>
            <Button
              external
              variant="primary"
              to="https://www.sparkpost.com/recipient-validation/#recipient-validation-form"
            >
              Contact Sales
            </Button>
          </div>
        ) : (
          <div>
            <Button variant="primary" submit disabled={submitDisabled}>
              {submitButtonName}
            </Button>
          </div>
        )}
      </Stack>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    initialValues: updatePaymentInitialValues(state),
    billingCountries: state.billing.countries,
    isManuallyBilled: isManuallyBilled(state)
  };
};

export default connect(mapStateToProps, { getBillingCountries })(ValidateSection);
