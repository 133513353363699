import copy from 'copy-to-clipboard';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideNewApiKey } from 'src/actions/api-keys';
import { Banner, Button } from 'src/components/matchbox';

type ApiKeySuccessBannerProps = {
  status?: 'success' | 'default' | 'warning' | 'danger' | 'info' | 'muted';
  title: string;
};

function ApiKeySuccessBanner({ title, status = 'success' }: ApiKeySuccessBannerProps): JSX.Element {
  const [copied, setCopied] = useState(false);
  const newKey = useSelector((state: $TODOFIXME) => state.apiKeys.newKey);
  const dispatch = useDispatch();

  const onClickBanner = useCallback(() => {
    copy(newKey);
    setCopied(true);
  }, [newKey]);

  const onDismiss = useCallback(() => {
    dispatch(hideNewApiKey());
  }, [dispatch]);

  const copyText = copied ? 'Copied to clipboard' : 'Copy';

  return (
    <Banner title={title} status={status} onDismiss={onDismiss} my="300">
      <p>Make sure to copy your API key now. You won&#39;t be able to see it again!</p>
      <strong>{newKey}</strong>

      <Banner.Actions>
        <Button onClick={onClickBanner} useMatchboxVariant={false} variant="default">
          <span>{copyText}</span>
        </Button>
      </Banner.Actions>
    </Banner>
  );
}

export default ApiKeySuccessBanner;
