import React from 'react';
import { Table } from 'src/components/matchbox';

class HeaderLabel extends React.Component {
  handleSort = () => {
    this.props.onSort(this.nextOrder);
  };

  get nextOrder() {
    const { dataKey, order } = this.props;

    if (!order || order.dataKey !== dataKey) {
      // no order or order by new field
      return { ascending: true, dataKey };
    }

    if (!order.ascending) {
      return undefined; // unset
    }

    return { ascending: false, dataKey };
  }

  render() {
    const { dataKey, label, order, sortable } = this.props;
    let direction;

    if (!sortable) {
      return label;
    }

    if (order && order.dataKey === dataKey) {
      direction = order.ascending ? 'asc' : 'desc';
    }

    return (
      <Table.SortButton onClick={this.handleSort} direction={direction}>
        {label}
      </Table.SortButton>
    );
  }
}

export default HeaderLabel;
