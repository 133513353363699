import React from 'react';
import { Column as MatchboxColumn, ColumnProps } from '@sparkpost/matchbox';

/**
 * Matchbox Column component
 * @see https://design.sparkpost.com/components/columns/api#column-api
 */
const Column = (props: ColumnProps): JSX.Element => <MatchboxColumn {...props} />;

export default Column;
