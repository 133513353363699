import { formatBytes } from '@sparkpost/report-builder/helpers/units';
import { formatNumberPercent, roundToOnePlace } from 'src/helpers/units';

export function getUnitFormatter(unit) {
  switch (unit) {
    case 'number':
      return roundToOnePlace;
    case 'percent':
      return formatNumberPercent;
    case 'bytes':
      return formatBytes;
    default:
      return (value) => value;
  }
}
