import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState
} from 'react';
// TODO: Remove use of moment
// eslint-disable-next-line no-restricted-imports
import {
  INDUSTRY_BENCHMARK_METRICS_MAP,
  map as METRICS_MAP,
  NON_PREFETCHED_KEY,
  PREFETCHED_KEY,
  nonPrefetchedMetrics
} from '@sparkpost/report-builder/config';
import { getLocalTimezone, getRelativeDates } from '@sparkpost/report-builder/helpers/date';
import { formatInTimeZone } from 'date-fns-tz';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from 'src/actions/globalAlert';
import config from 'src/appConfig';
import { useMultiCheckbox } from 'src/components/MultiCheckboxDropdown';
import {
  hydrateFilters,
  parseSearch,
  replaceComparisonFilterKey,
  toFilterTypeKey
} from 'src/helpers/analyticsReport';
import {
  allMetricsAreDeliverability,
  getDeliverabilityOnlyMetrics,
  getMetricsFromKeys,
  getRollupPrecision as getPrecision,
  getValidDateRange,
  splitDeliverabilityMetric
} from 'src/helpers/metrics';
import { saveRecentReport } from 'src/helpers/recentSavedReports';
import { stringifyTypeaheadfilter } from 'src/helpers/string';
import useModal from 'src/hooks/useModal';
import { dataSourceCheckboxes } from 'src/pages/signals/constants/dataSources';
import { getValidFilters, toApiFormattedGroupings, toIterableGroupings } from '../helpers';

import { useColors } from './useColors';

const nonPrefetchedMetricsMap = nonPrefetchedMetrics.reduce(
  (accumulator, metric) => ({
    ...accumulator,
    [metric.key]: metric
  }),
  {}
);

const defaultFormat = "yyyy-MM-dd'T'HH:mm:ssxxx";

const mapPrefetchedToNonPrefetched = {};
const mapNonPrefetchedToPrefetched = {};

nonPrefetchedMetrics.forEach((metric) => {
  mapPrefetchedToNonPrefetched[metric[PREFETCHED_KEY]] = metric.key;
  mapNonPrefetchedToPrefetched[metric.key] = metric[PREFETCHED_KEY];
});

const ReportOptionsContext = createContext({});

const initialState = {
  filters: [],
  blocklistFilters: {
    blocklist_providers: [],
    blocklist_resources: []
  },
  healthScoreFilters: {},
  comparisons: [],
  healthScoreSubaccounts: {},
  groupBy: undefined,
  groupByError: false,
  industryBenchmarkMetric: null,
  industryBenchmarkFilters: { industryCategory: 'all', mailboxProvider: 'all' },
  selectedReport: null
};

const normalizePrefetchedMetrics = (metrics = [], includePrefetchedOpens = false) => {
  if (includePrefetchedOpens)
    return metrics?.map((metric) => mapNonPrefetchedToPrefetched[metric] || metric);

  return metrics?.map((metric) => mapPrefetchedToNonPrefetched[metric] || metric);
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_FILTERS': {
      const filterType = toFilterTypeKey(action.payload.type);

      return {
        ...state,
        groupBy: undefined, //Unset filters since table would only show a single value
        filters: [
          ...state.filters,
          {
            AND: {
              [filterType]: {
                eq: action.payload.hasOwnProperty('filters')
                  ? [...action.payload.filters]
                  : [action.payload]
              }
            }
          }
        ],
        metrics:
          filterType === 'subject_campaigns'
            ? getDeliverabilityOnlyMetrics(state.metrics)
            : state.metrics
      };
    }

    case 'SET_FILTERS': {
      return {
        ...state,
        groupBy: undefined, //Unset filters since table would only show a single value
        filters: [...state.filters, ...action.payload],
        metrics: state.metrics
      };
    }

    case 'SET_HEALTH_SCORE_FILTERS': {
      return {
        ...state,
        groupBy: undefined,
        healthScoreFilters: {
          ...state.healthScoreFilters,
          ...action.payload,
          errors: {
            ...state.healthScoreFilters.errors,
            ...action.payload?.errors
          }
        }
      };
    }

    case 'CLEAN_HEALTH_SCORE_FILTERS': {
      return {
        ...state,
        healthScoreFilters: {}
      };
    }

    case 'SET_BLOCKLIST_FILTERS': {
      return {
        ...state,
        groupBy: undefined, //Unset filters since table would only show a single value
        blocklistFilters: {
          ...state.blocklistFilters,
          ...action.payload,
          errors: {
            ...state.blocklistFilters.errors,
            ...action.payload?.errors
          }
        },
        metrics: state.metrics
      };
    }

    case 'SET_HEALTH_SCORE_SUBACCOUNTS': {
      return {
        ...state,
        healthScoreSubaccounts: {
          ...state.healthScoreSubaccounts,
          ...action.payload
        }
      };
    }

    case 'UPDATE_REPORT_OPTIONS': {
      const { payload, meta } = action;
      const { subaccounts, dispatchAlert } = meta;
      let update = { ...state, ...payload };

      if (!update.timezone) {
        update.timezone = getLocalTimezone();
      }

      const ALL_METRICS = { ...METRICS_MAP, ...nonPrefetchedMetricsMap };

      if (!update.metrics) {
        update.metrics = config.analyticsReport.defaultMetrics;
      } else {
        const filteredMetrics = update.metrics.filter((metric) => ALL_METRICS[metric]);

        if (filteredMetrics.length < update.metrics.length) {
          dispatchAlert({
            type: 'error',
            message: `Invalid Metric`
          });
        }
        update.metrics = filteredMetrics;

        //Removes the industry benchmark query params when the metric is removed
        if (
          update.industryBenchmarkMetric &&
          update.metrics.every((metric) => !INDUSTRY_BENCHMARK_METRICS_MAP[metric])
        ) {
          update.industryBenchmarkMetric = null;
          update.industryBenchmarkFilters = { industryCategory: 'all', mailboxProvider: 'all' };
        }
      }

      if (payload.filters) {
        const rawFilters = hydrateFilters(payload.filters, { subaccounts });
        const validatedFilters = getValidFilters(rawFilters);

        if (validatedFilters.length !== rawFilters.length) {
          dispatchAlert({
            type: 'error',
            message: `Invalid Filter`
          });
        }
        update.filters = validatedFilters;
      }
      const updatePrecision = update.precision || 'hour'; // Default to hour since it's the recommended rollup precision for 7 days

      if (!update.relativeRange) {
        update.relativeRange = '7days';
      } else {
        try {
          getValidDateRange({
            from: update.from,
            to: update.to,
            preventFuture: true,
            precision: updatePrecision,
            roundToPrecision: true
          });
        } catch (e) {
          dispatchAlert({
            type: 'error',
            message: `Invalid Date`
          });
          update.relativeRange = '7days';
        }
      }

      // TODO: Remove some of this date modification. It gets confusing with the same logic happening in the date picker
      if (update.relativeRange !== 'custom') {
        const { from, to } = getRelativeDates.useMomentInput(update.relativeRange, {
          roundToPrecision: false,
          precision: updatePrecision
        });
        //for metrics rollup, when using the relative dates, get the precision, else use the given precision
        //If precision is not in the URL, get the recommended precision.
        const precision = getPrecision({ from, to, precision: updatePrecision });
        update = { ...update, from, to, precision };
      } else {
        const precision = getPrecision({
          from: update.from,
          to: moment(update.to),
          precision: updatePrecision
        });

        update = { ...update, precision };
      }

      if (update.comparisons.length > 0) {
        //Changes the filter type from the label to the key and validates the comparison
        const validComparisons = replaceComparisonFilterKey(update.comparisons);
        const hasEnoughToCompare = validComparisons.length > 1;
        if (validComparisons.length !== update.comparisons.length || !hasEnoughToCompare) {
          dispatchAlert({
            type: 'error',
            message: `Invalid Comparison`
          });
        }
        update.comparisons = hasEnoughToCompare ? validComparisons : [];
      }

      return {
        ...update,
        isReady: true
      };
    }

    case 'REMOVE_FILTER': {
      const { payload } = action;
      const groupings = toIterableGroupings(state.filters);
      const targetFilter = groupings[payload.groupingIndex].filters[payload.filterIndex];
      // Filter out matching values based on index
      targetFilter.values = targetFilter.values.filter(
        (value) => value !== targetFilter.values[payload.valueIndex]
      );
      // Remap iterable data structure to match API structure
      const filters = toApiFormattedGroupings(groupings);

      return {
        ...state,
        filters
      };
    }

    case 'REMOVE_COMPARISON_FILTER': {
      const { payload } = action;
      const { index } = payload;
      const comparisons = [...state.comparisons];
      comparisons.splice(index, 1);

      if (comparisons.length >= 2) {
        return { ...state, comparisons };
      }
      const lastFilter = comparisons[0];
      const filterType = toFilterTypeKey(lastFilter.type);
      const filters = [{ AND: { [filterType]: { eq: [lastFilter] } } }];

      return { ...state, comparisons: [], filters: [...state.filters, ...filters] };
    }

    case 'SET_GROUP_BY': {
      const { payload } = action;
      return {
        ...state,
        groupBy: payload
      };
    }

    case 'SET_GROUP_BY_ERROR': {
      const { payload } = action;
      return {
        ...state,
        groupByError: payload
      };
    }

    case 'SET_INDUSTRY_BENCHMARK': {
      const { payload } = action;
      return { ...state, ...payload };
    }
    case 'SET_REPORT': {
      const { payload, currentUser } = action;
      //Store recently accessed report in localstorage
      if (payload?.id) saveRecentReport(payload?.id, currentUser);
      return { ...state, selectedReport: payload };
    }

    default:
      throw new Error(`${action.type} is not supported.`);
  }
};

const getSelectors = (reportOptions) => {
  const { favorite, recent, includePrefetchedOpens } = reportOptions;

  const selectDateOptions = {
    from: reportOptions.from
      ? formatInTimeZone(reportOptions.from, reportOptions.timezone, defaultFormat)
      : undefined,
    to: reportOptions.to
      ? formatInTimeZone(reportOptions.to, reportOptions.timezone, defaultFormat)
      : undefined,
    range: reportOptions.relativeRange,
    timezone: reportOptions.timezone,
    precision: reportOptions.precision
  };

  const selectTypeaheadFilters = {
    filters: (reportOptions.filters || []).map(stringifyTypeaheadfilter)
  };

  const selectCompareFilters = {
    comparisons: reportOptions.comparisons || []
  };

  const selectGroupBy = {
    group_by: reportOptions.groupBy
  };

  const selectSummaryMetrics = {
    metrics: (reportOptions.metrics || []).map((metric) =>
      typeof metric === 'string' ? metric : metric.key
    )
    //TODO RB CLEANUP: can probably remove the check if it's an object
  };

  const selectSummaryMetricsProcessed = getMetricsFromKeys(reportOptions.metrics || [], true);

  const selectIndustryBenchmark = reportOptions.industryBenchmarkMetric
    ? {
        industryBenchmarkMetric: reportOptions.industryBenchmarkMetric,
        industryBenchmarkFilters: reportOptions.industryBenchmarkFilters
      }
    : {};

  /**
   * Converts reportOptions for url sharing
   */
  const selectReportSearchOptions = { ...selectDateOptions, ...selectTypeaheadFilters };

  /**
   * Converts reportOptions for url sharing for the summary chart
   */
  const selectApiParams = {
    ...selectDateOptions,
    ...selectTypeaheadFilters,
    ...selectSummaryMetrics,
    ...selectCompareFilters,
    ...selectIndustryBenchmark,
    ...selectGroupBy,
    includePrefetched: includePrefetchedOpens
  };

  const selectUiParams = {
    ...selectDateOptions,
    ...selectTypeaheadFilters,
    ...selectCompareFilters,
    ...selectIndustryBenchmark,
    ...selectGroupBy,
    // Removes non prefetched for report saving + URL params
    metrics: (normalizePrefetchedMetrics(reportOptions.metrics, true) || []).map((metric) =>
      typeof metric === 'string' ? metric : metric.key
    ),
    includePrefetched: includePrefetchedOpens
  };

  // track params
  if (favorite && favorite > 0) {
    selectUiParams.favorite = favorite;
    selectUiParams.recent = undefined; // force undefined to remove from query string
  }

  if (recent && recent > 0) {
    selectUiParams.favorite = undefined; // force undefined to remove from query string
    selectUiParams.recent = recent;
  }

  const allMetricsAreD12y = allMetricsAreDeliverability(reportOptions.metrics);

  return {
    selectSummaryMetricsProcessed,
    selectReportSearchOptions,
    selectUiParams,
    selectApiParams,
    allMetricsAreD12y
  };
};

export const AnalyticsReportContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [includePrefetchedOpens, setIncludePrefetchedOpens] = useState(false);
  const dispatchGlobal = useDispatch();

  const currentUser = useSelector((state) => state.currentUser.username);
  const subaccounts = useSelector((state) => state.subaccounts.list);

  const dispatchAlert = useCallback((props) => dispatchGlobal(showAlert(props)), [dispatchGlobal]);
  const { closeModal, openModal, ...rest } = useModal();
  const modalActions = { openModal, closeModal };
  const modalState = { ...rest };

  const dataSourceOptions = useMultiCheckbox(dataSourceCheckboxes);

  const setReport = useCallback(
    (payload) => {
      const { includePrefetched } = parseSearch(payload?.query_string);
      setIncludePrefetchedOpens(includePrefetched || false);

      return dispatch({
        type: 'SET_REPORT',
        payload,
        currentUser
      });
    },
    [currentUser]
  );

  const refreshReportOptions = useCallback(
    (payload) => {
      return dispatch({
        type: 'UPDATE_REPORT_OPTIONS',
        payload,
        meta: { subaccounts, dispatchAlert }
      });
    },
    [dispatch, subaccounts, dispatchAlert]
  );

  const addFilters = useCallback(
    (payload) => {
      return dispatch({
        type: 'ADD_FILTERS',
        payload
      });
    },
    [dispatch]
  );

  const setFilters = useCallback(
    (payload) => {
      return dispatch({
        type: 'SET_FILTERS',
        payload
      });
    },
    [dispatch]
  );

  const setHealthScoreFilters = useCallback(
    (payload) => {
      return dispatch({
        type: 'SET_HEALTH_SCORE_FILTERS',
        payload
      });
    },
    [dispatch]
  );

  const cleanHealthScoreFilters = useCallback(() => {
    return dispatch({
      type: 'CLEAN_HEALTH_SCORE_FILTERS'
    });
  }, [dispatch]);

  const setBlocklistFilters = useCallback(
    (payload) => {
      return dispatch({
        type: 'SET_BLOCKLIST_FILTERS',
        payload
      });
    },
    [dispatch]
  );

  const setHealthScoreSubaccounts = useCallback(
    (payload) => {
      return dispatch({
        type: 'SET_HEALTH_SCORE_SUBACCOUNTS',
        payload
      });
    },
    [dispatch]
  );

  const removeFilter = useCallback(
    (payload) => {
      return dispatch({
        type: 'REMOVE_FILTER',
        payload
      });
    },
    [dispatch]
  );

  const removeComparisonFilter = useCallback(
    (payload) => {
      return dispatch({
        type: 'REMOVE_COMPARISON_FILTER',
        payload
      });
    },
    [dispatch]
  );

  const setGroupBy = useCallback(
    (payload) => {
      return dispatch({
        type: 'SET_GROUP_BY',
        payload
      });
    },
    [dispatch]
  );

  const setGroupByError = useCallback(
    (payload) => {
      return dispatch({
        type: 'SET_GROUP_BY_ERROR',
        payload
      });
    },
    [dispatch]
  );

  const setIndustryBenchmark = useCallback(
    (payload) => {
      return dispatch({
        type: 'SET_INDUSTRY_BENCHMARK',
        payload
      });
    },
    [dispatch]
  );

  const actions = {
    //Industry benchmark action
    setIndustryBenchmark,
    //Filter actions
    removeFilter,
    addFilters,
    setFilters,
    setHealthScoreFilters,
    setBlocklistFilters,
    setHealthScoreSubaccounts,
    cleanHealthScoreFilters,
    //Comparison action
    removeComparisonFilter,
    //Group by action
    setGroupBy,
    setGroupByError,
    //Overall action
    refreshReportOptions,
    setReport,
    setIncludePrefetchedOpens
  };

  const normalizedMetrics = useMemo(() => {
    return normalizePrefetchedMetrics(state.metrics, includePrefetchedOpens);
  }, [includePrefetchedOpens, state.metrics]);

  const colors = useColors(normalizedMetrics);

  const formattedMetrics = useMemo(() => {
    return getMetricsFromKeys(normalizedMetrics)
      .map((metric) => splitDeliverabilityMetric(metric, dataSourceOptions.values))
      .map((metric) => ({
        ...metric,
        stroke: colors[metric.key]
      }));
  }, [normalizedMetrics, dataSourceOptions.values, colors]);

  const stateNormalized = useMemo(() => {
    return {
      ...state,
      metrics: normalizedMetrics
    };
  }, [normalizedMetrics, state]);

  const selectors = useMemo(
    () => ({
      ...getSelectors({
        ...state,
        includePrefetchedOpens,
        metrics: normalizedMetrics
      }),
      formattedMetrics
    }),
    [state, includePrefetchedOpens, formattedMetrics, normalizedMetrics]
  );

  const validateBlocklistFilters = useCallback(() => {
    let hasErrors = false;
    if (state.blocklistFilters?.blocklist_providers.length === 0) {
      hasErrors = true;
      setBlocklistFilters({ errors: { blocklist_providers: true } });
    }
    if (state.blocklistFilters?.blocklist_resources.length === 0) {
      hasErrors = true;
      setBlocklistFilters({ errors: { blocklist_resources: true } });
    }
    return hasErrors;
  }, [
    setBlocklistFilters,
    state.blocklistFilters.blocklist_providers,
    state.blocklistFilters.blocklist_resources
  ]);

  const validateHealthScoreFilters = useCallback(() => {
    const { healthScoreFilters } = state;
    let hasErrors = false;
    if (healthScoreFilters?.facet_name === 'none') {
      hasErrors = true;
      setHealthScoreFilters({ errors: { facet_name: true } });
    }

    const facetNameFilled = !!healthScoreFilters?.facet_name;
    const facetNameIsNotNone = healthScoreFilters?.facet_name !== 'none';
    const facetValuesIsEmpty =
      !healthScoreFilters?.facet_values || healthScoreFilters.facet_values?.length <= 0;
    if (facetNameFilled && facetNameIsNotNone && facetValuesIsEmpty) {
      hasErrors = true;
      setHealthScoreFilters({ errors: { facet_values: true } });
    }

    return hasErrors;
  }, [setHealthScoreFilters, state]);

  const canSelectPrefetchedOpens = selectors?.selectSummaryMetricsProcessed?.some(
    (metric) => metric.hasOwnProperty(NON_PREFETCHED_KEY) || metric.hasOwnProperty(PREFETCHED_KEY)
  );

  return (
    <ReportOptionsContext.Provider
      value={{
        state: stateNormalized,
        actions,
        selectors,
        modalActions,
        modalState,
        dataSourceOptions,
        canSelectPrefetchedOpens,
        includePrefetchedOpens,
        validateBlocklistFilters,
        validateHealthScoreFilters
      }}
    >
      {props.children}
    </ReportOptionsContext.Provider>
  );
};

export const useAnalyticsReportContext = () => useContext(ReportOptionsContext);
