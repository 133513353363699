import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import * as suppressionActions from 'src/actions/suppressions';
import DatePicker from 'src/components/datePicker/DatePicker';
import { Box, Grid } from 'src/components/matchbox';
import MultiCheckboxDropdown, { useMultiCheckbox } from 'src/components/MultiCheckboxDropdown';
import { selectSearchInitialValues } from 'src/selectors/suppressions';
import { RELATIVE_DATE_OPTIONS, SOURCES, TYPES } from '../constants';

export function SuppressionSearch({
  refreshSuppressionDateRange,
  search,
  searchSuppressions,
  loading,
  updateSuppressionSearchOptions
}) {
  useEffect(() => {
    refreshSuppressionDateRange({
      relativeRange: search.dateOptions.relativeRange || 'day'
    });
  }, [search.dateOptions.relativeRange, refreshSuppressionDateRange]);

  useEffect(() => {
    searchSuppressions(search);
  }, [search, searchSuppressions]);

  const handleTypesSelection = useCallback(
    (types) => {
      updateSuppressionSearchOptions({ types });
    },
    [updateSuppressionSearchOptions]
  );

  const handleSourcesSelection = useCallback(
    (sources) => {
      updateSuppressionSearchOptions({ sources });
    },
    [updateSuppressionSearchOptions]
  );

  const typesCheckboxes = useMultiCheckbox({
    checkboxes: TYPES,
    allowSelectAll: false,
    onChange: (values) => {
      return handleTypesSelection(values);
    }
  });

  const sourcesCheckboxes = useMultiCheckbox({
    checkboxes: SOURCES,
    allowSelectAll: false,
    onChange: (values) => {
      return handleSourcesSelection(values);
    }
  });

  const { dateOptions } = search;

  return (
    <Grid>
      <Grid.Column xs={12} md={6}>
        <Box mb={['300', null, null, '0']}>
          {dateOptions.from && dateOptions.to && (
            <DatePicker
              {...dateOptions}
              relativeDateOptions={RELATIVE_DATE_OPTIONS}
              onChange={refreshSuppressionDateRange}
              disabled={loading}
            />
          )}
        </Box>
      </Grid.Column>
      <Grid.Column xs={6} md={3}>
        <MultiCheckboxDropdown
          label="Type"
          id="types-filter-dropdown"
          data-id="types-filter-dropdown"
          name="Type"
          formName="filterForm"
          checkboxes={typesCheckboxes.checkboxes}
          namespace="types"
          displayValue="Type"
          hideLabel
          onChange={handleTypesSelection}
        />
      </Grid.Column>

      <Grid.Column xs={6} md={3}>
        <MultiCheckboxDropdown
          label="Sources"
          id="source-filter-dropdown"
          data-id="source-filter-dropdown"
          name="Sources"
          formName="filterForm"
          checkboxes={sourcesCheckboxes.checkboxes}
          namespace="sources"
          displayValue="Sources"
          hideLabel
          onChange={handleSourcesSelection}
        />
      </Grid.Column>
    </Grid>
  );
}

const formName = 'filterForm';
const formOptions = {
  form: formName
};

const mapStateToProps = (state) => {
  return {
    search: state.suppressions.search,
    list: state.suppressions.list,
    loading: state.suppressions.listLoading,
    initialValues: selectSearchInitialValues(state)
  };
};
export default connect(
  mapStateToProps,
  suppressionActions
)(reduxForm(formOptions)(SuppressionSearch));
