import React from 'react';
import classNames from 'classnames';
import { Button } from 'src/components/matchbox';
import { PageLink } from 'src/components/links';
import { setSubaccountQuery } from 'src/helpers/subaccounts';
import { routeNamespace } from '../../constants/routes';
import useEditorContext from '../../hooks/useEditorContext';
import styles from './Actions.module.scss';

const ViewerModeActions = () => {
  const { template, isPublishedMode } = useEditorContext();

  const version = isPublishedMode ? 'draft' : 'published';

  const editDraftTo = `/${routeNamespace}/edit/${
    template.id
  }/${version}/content${setSubaccountQuery(template.subaccount_id)}`;

  return (
    <Button.Group>
      <PageLink
        to={editDraftTo}
        as={Button}
        variant="secondary"
        className={classNames(styles.Actions, styles.ActionsPrimaryLink)}
        role="button"
        data-id="button-edit-draft"
      >
        <strong>{isPublishedMode ? 'View Draft' : 'View Published'}</strong>
      </PageLink>
    </Button.Group>
  );
};

export default ViewerModeActions;
