import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';

// Actions
import { deleteWebhook, getWebhook } from 'src/actions/webhooks';
import { selectSubaccountIdFromQuery } from 'src/selectors/subaccounts';

// Components
import { showAlert } from 'src/actions/globalAlert';
import { list as getSubaccountsList } from 'src/actions/subaccounts';
import { DeleteModal, Loading } from 'src/components';
import { PageLink } from 'src/components/links';
import { Box, Page, Tabs } from 'src/components/matchbox';
import { setSubaccountQuery } from 'src/helpers/subaccounts';
import BatchTab from './components/BatchTab/BatchTab';
import EditTab from './components/EditTab';
import TestTab from './components/TestTab';

export class WebhooksDetails extends Component {
  state = {
    showDelete: false
  };

  componentDidMount() {
    const { getWebhook, match, history, subaccountId: subaccount } = this.props;

    getWebhook({ id: match.params.id, subaccount }).catch(() => {
      history.push('/webhooks/');
    });

    this.props.getSubaccountsList();
  }

  /*
    Calls deleteWebhook action then redirects to list page.
  */
  deleteWebhook = () => {
    const { deleteWebhook, showAlert, history, match, webhook } = this.props;

    return deleteWebhook({ id: match.params.id, subaccount: webhook.subaccount }).then(() => {
      history.push('/webhooks/');
      showAlert({ type: 'success', message: 'Webhook deleted' });
    });
  };

  /*
    for delete modal
  */
  toggleDelete = () => {
    this.setState({ showDelete: !this.state.showDelete });
  };

  render() {
    const { webhook, location, match, subaccountId } = this.props;
    const webhookId = match.params.id;
    const query = setSubaccountQuery(subaccountId);
    const editPath = `/webhooks/details/${webhookId}`;
    const testPath = `/webhooks/details/${webhookId}/test`;
    const batchPath = `/webhooks/details/${webhookId}/batches`;
    const tabs = [
      {
        content: 'Settings',
        as: PageLink,
        to: `${editPath}${query}`
      },
      {
        content: 'Test',
        as: PageLink,
        to: `${testPath}${query}`
      },
      {
        content: 'Batch Status',
        as: PageLink,
        to: `${batchPath}${query}`
      }
    ];

    const selectedTab = tabs.findIndex(({ to }) => location.pathname === to.split('?')[0]);

    /*
      Check .events to guard from the create page redirect,
      which sets id on the state but doesn't have the rest of the webhook
    */
    if (webhook.id !== webhookId || !webhook.events) {
      return <Loading />;
    }

    return (
      <Page
        title={webhook.name}
        breadcrumbAction={{ content: 'View All Webhooks', as: PageLink, to: '/webhooks/' }}
      >
        <Box>
          <Tabs selected={selectedTab} tabs={tabs} />

          <Box pt="600">
            <Route
              exact
              path={editPath}
              render={() => <EditTab webhook={webhook} openDeleteModal={this.toggleDelete} />}
            />
            <Route path={testPath} render={() => <TestTab webhook={webhook} />} />
            <Route path={batchPath} render={() => <BatchTab webhook={webhook} />} />
          </Box>
        </Box>

        <DeleteModal
          open={this.state.showDelete}
          title="Delete Webhook"
          content={
            <p>
              The webhook will be permanently removed and send no further events. This cannot be
              undone.
            </p>
          }
          onCancel={this.toggleDelete}
          onDelete={this.deleteWebhook}
        />
      </Page>
    );
  }
}

const mapStateToProps = (state, props) => ({
  webhook: state.webhooks.webhook,
  getLoading: state.webhooks.getLoading,
  eventDocs: state.webhooks.docs,
  subaccountId: selectSubaccountIdFromQuery(state, props)
});

const DetailsPage = withRouter(
  connect(mapStateToProps, { getWebhook, deleteWebhook, showAlert, getSubaccountsList })(
    WebhooksDetails
  )
);

export default DetailsPage;
