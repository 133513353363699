import PropTypes from 'prop-types';
import React from 'react';
import { UnstyledLink } from 'src/components/matchbox';

export default function ButtonLink(props) {
  const handleClick = (e) => {
    e.preventDefault();
    if (props.onClick) props.onClick();
  };

  return <UnstyledLink {...props} to="#" role="button" onClick={handleClick} />;
}

ButtonLink.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
    PropTypes.func
  ])
};
