import React, { Fragment } from 'react';
import { Box, Skeleton, Stack } from '../matchbox';

type SkeletonTableProps = {
  columns?: number;
  rows?: number;
};

const applyKeyToElement = (element: JSX.Element, index: number): JSX.Element => (
  <Fragment key={index}>{element}</Fragment>
);

/**
 * SkeletonTable
 *
 * Use {@link https://design.sparkpost.com/components/skeleton Skeleton} to create a loading table
 */
export const SkeletonTable = ({ columns = 3, rows = 3 }: SkeletonTableProps) => {
  const row = (
    <Box display="grid" gridTemplateColumns={`repeat(${columns}, auto)`}>
      {Array(columns)
        .fill(<Skeleton.Body lines={1} />)
        .map(applyKeyToElement)}
    </Box>
  );

  return (
    <div data-id="loading">
      <Stack>
        <Box display="grid" gridTemplateColumns={`repeat(${columns}, auto)`}>
          {Array(columns)
            .fill(
              <Box maxWidth="90%">
                <Skeleton.Header looksLike="h5" width="100%" />
              </Box>
            )
            .map(applyKeyToElement)}
        </Box>

        {Array(rows).fill(row).map(applyKeyToElement)}
      </Stack>
    </div>
  );
};
