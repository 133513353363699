import React from 'react';
import { Inline, RadioCard } from '../matchbox';

export default function RadioCardWrapper({ input, meta, options, dataTrack, ...rest }) {
  const { active, error, touched } = meta;

  return (
    <RadioCard.Group
      id={input.name}
      {...input}
      error={!active && touched && error ? error : undefined}
      {...rest}
    >
      {options.map((option) => (
        <Inline mb="100" key={`${input.name}-${option.value}`}>
          <RadioCard
            {...input}
            id={`${input.name}-${option.value}`}
            label={option.label}
            checked={option.value === input.value}
            disabled={!!option.disabled}
            value={option.value}
            helpText={option.helpText}
            data-track={dataTrack}
          >
            {option.description}
          </RadioCard>
          {option.children}
        </Inline>
      ))}
    </RadioCard.Group>
  );
}
