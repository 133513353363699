import React from 'react';
import { useHistory, useLocation, Route, Switch } from 'react-router-dom';

import { Page, Stack, Tabs } from 'src/components/matchbox';
import { PageLink } from 'src/components/links';

import { DomainsForm } from './components/AddMonitors/DomainsForm';
import { IpsForm } from './components/AddMonitors/IpsForm';

const SENDING_DOMAINS_ROUTE = '/signals/blocklist/monitors/add/sending-domain';

const IPS_ROUTE = '/signals/blocklist/monitors/add/ip';

function MonitorsAddPage() {
  const history = useHistory();
  const { pathname } = useLocation();

  const TABS = [
    {
      content: 'IPs',
      key: IPS_ROUTE,
      onClick: () => history.push(IPS_ROUTE)
    },
    {
      content: 'Domains',
      key: SENDING_DOMAINS_ROUTE,
      onClick: () => history.push(SENDING_DOMAINS_ROUTE)
    }
  ];

  const tabIndex = TABS.findIndex(({ key }) => key === pathname);

  return (
    <Page
      title="Add Resource to Monitor"
      breadcrumbAction={{
        content: 'Monitored Resources',
        to: '/signals/blocklist/monitors',
        as: PageLink
      }}
    >
      <Stack>
        <Tabs tabs={TABS} selected={tabIndex} />
        <Switch>
          <Route path={IPS_ROUTE}>
            <IpsForm />
          </Route>
          <Route path={SENDING_DOMAINS_ROUTE}>
            <DomainsForm />
          </Route>
        </Switch>
      </Stack>
    </Page>
  );
}

export default MonitorsAddPage;
