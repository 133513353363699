import { blocklist } from 'src/pages';
import App from 'src/components/layout/App';
import { hasGrants } from 'src/helpers/conditions';

const blocklistRoutes = [
  {
    path: '/signals/blocklist/incidents',
    component: blocklist.IncidentsPage,
    layout: App,
    condition: hasGrants('blocklist-monitors/manage'),
    title: 'Blocklist Incidents | Blocklist',
    supportDocSearch: 'blocklist',
    category: 'Blocklist'
  },
  {
    path: '/signals/blocklist/monitors',
    component: blocklist.MonitoredResourcesPage,
    layout: App,
    condition: hasGrants('blocklist-monitors/manage'),
    title: 'Monitored IPs and Domains | Blocklist',
    supportDocSearch: 'blocklist',
    category: 'Blocklist'
  },
  {
    path: '/signals/blocklist/monitors/add',
    redirect: '/signals/blocklist/monitors/add/ip'
  },
  {
    path: '/signals/blocklist/monitors/add/sending-domain',
    component: blocklist.MonitorsAddPage,
    condition: hasGrants('blocklist-monitors/manage'),
    layout: App,
    title: 'Add to Watch List | Blocklist',
    supportDocSearch: 'blocklist',
    category: 'Blocklist'
  },
  {
    path: '/signals/blocklist/monitors/add/ip',
    component: blocklist.MonitorsAddPage,
    condition: hasGrants('blocklist-monitors/manage'),
    layout: App,
    title: 'Add to Watch List | Blocklist',
    supportDocSearch: 'blocklist',
    category: 'Blocklist'
  },
  {
    path: '/signals/blocklist/incidents/:id',
    component: blocklist.IncidentDetailsPage,
    condition: hasGrants('blocklist-monitors/manage'),
    layout: App,
    title: 'Blocklist Incident | Blocklist',
    supportDocSearch: 'blocklist',
    category: 'Blocklist'
  },
  {
    path: '/signals/blocklist/incidents/:id',
    component: blocklist.IncidentDetailsPage,
    condition: hasGrants('blocklist-monitors/manage'),
    layout: App,
    title: 'Blocklist Incident | Blocklist',
    supportDocSearch: 'blocklist',
    category: 'Blocklist'
  },
  {
    path: '/signals/blocklist/monitors/:monitorId/incidents/:id',
    component: blocklist.IncidentDetailsPage,
    condition: hasGrants('blocklist-monitors/manage'),
    layout: App,
    title: 'Blocklist Incident | Blocklist',
    supportDocSearch: 'blocklist',
    category: 'Blocklist'
  }
];

export default blocklistRoutes;

export const hibanaBlocklistRoutes = blocklistRoutes.map((route) => ({
  ...route,
  category: 'Signals Analytics',
  subcategory: 'Blocklist'
}));
