import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { clearRedirectAfterLogin } from 'src/actions/auth';
import config from 'src/appConfig';
import { Loading } from 'src/components/loading/Loading';
import { useHibana } from 'src/context/HibanaContext';
import selectAccessConditionState from 'src/selectors/accessConditionState';
import { authRedirectAfterLoginSelector } from 'src/selectors/auth';

export function DefaultRedirect({ currentUser, ready }) {
  const [{ isHibanaEnabled }] = useHibana();
  const redirectAfterLogin = useSelector((state) => authRedirectAfterLoginSelector(state));
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const handleRedirect = () => {
      const { state: routerState = {}, ...locationWithoutState } = location;
      const allowedAccessLevels = isHibanaEnabled
        ? ['subaccount_reporting']
        : ['subaccount_reporting', 'reporting'];

      // if there is a redirect route set on state, we can
      // redirect there before access condition state is ready

      if (routerState?.redirectAfterLogin) {
        history.replace({ ...locationWithoutState, ...routerState?.redirectAfterLogin });
        return;
      } else if (redirectAfterLogin) {
        const [pathname, search] = redirectAfterLogin.split('?');
        if (pathname && pathname.length) {
          history.replace({
            ...locationWithoutState,
            pathname: pathname,
            search: search ? `?${search}` : locationWithoutState?.search
          });
        }
        dispatch(clearRedirectAfterLogin());
        return;
      }

      // if access condition state hasn't loaded, we can't
      // make a redirect decision yet
      if (!ready) {
        return;
      }

      // users are sent to the summary report depending on whether hibana is enabled and allowedAccessLevels list
      if (_.includes(allowedAccessLevels, currentUser.access_level)) {
        history.replace({ ...location, pathname: '/reports/summary' });
        return;
      }

      // everyone else is sent to the config.splashPage route
      history.replace({ ...location, pathname: config.splashPage });
    };
    handleRedirect();
  }, [
    redirectAfterLogin,
    currentUser.access_level,
    dispatch,
    history,
    isHibanaEnabled,
    location,
    ready
  ]);

  return <Loading />;
}

const mapStateToProps = (state) => selectAccessConditionState(state);

export default connect(mapStateToProps)(DefaultRedirect);
