import { Layout as MatchboxLayout } from '@sparkpost/matchbox';

/**
 * Matchbox Layout component
 * @see https://design.sparkpost.com/components/layout/
 */
const Layout = MatchboxLayout;

Layout.displayName = 'Layout';
Layout.Section.displayName = 'Layout.Section';
Layout.SectionTitle.displayName = 'Layout.SectionTitle';

export default Layout;
