import React from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import { ShowChart } from '@sparkpost/matchbox-icons';
import { Unit, Subaccount } from 'src/components';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PER_PAGE } from 'src/constants';
import { PageLink } from 'src/components/links';
import { getDetailsPageUrl } from 'src/appConfig/routes';
import { list, REPORT_BUILDER_FILTER_KEY_INVERTED_MAP } from '@sparkpost/report-builder/config';
import { Box, Button, Panel, Pagination, Table, Tag } from 'src/components/matchbox';
import { GROUP_BY_CONFIG } from 'src/pages/analyticsReport/constants';
import useResourceDetailsContext from '../../hooks/useResourceDetailsContext';

export function DeliverabilityBreakdownTable({
  tableData: { metricsData, ...additionalData },
  resourceType,
  columnsToDisplay,
  defaultColumnToSortBy
}) {
  const { filters, match: { params: { type, id } = {} } = {} } = useResourceDetailsContext();
  const filtersKey = REPORT_BUILDER_FILTER_KEY_INVERTED_MAP[GROUP_BY_CONFIG[type].filterKey];

  let queryParamsForMetrics = columnsToDisplay
    .map((x) => list.find((item) => item.label === x.columnName)?.key)
    .filter((x) => x !== undefined)
    .map((x) => `&metrics=${x}`)
    .join('');

  let viewFullReportLink = '/signals/analytics';
  if (filters && filters.to && filters.from) {
    const TO = new Date(filters.to);
    const FROM = new Date(filters.from);
    viewFullReportLink = `/signals/analytics?from=${FROM.toISOString()}&to=${TO.toISOString()}&group_by=${resourceType}${queryParamsForMetrics}&range=custom&filters=${filtersKey}:${id}`;
  }

  const tableInstanceColumns = React.useMemo(
    () => [
      ...columnsToDisplay.map((column) => {
        if (column.type === 'metric')
          return {
            Header: `${column.columnName}`,
            accessor: (row) => {
              const { key, compute, computeKeys } = list.find(
                (item) => item.label === column.columnName
              );
              return compute ? compute(row, computeKeys) : row[key];
            },
            sortType: 'basic'
          };
        if (column.type === 'resource')
          return {
            Header: `${column.columnName}`,
            accessor: (row) => {
              return row[GROUP_BY_CONFIG[resourceType].keyName];
            }
          };
        if (column.columnName === 'Sending IP Warm-up Status')
          return {
            id: 'Sending IP Warm-up Status',
            accessor: (row) => {
              return additionalData.sendingIpData.find(
                (x) => x.external_ip === row[GROUP_BY_CONFIG[resourceType].keyName]
              )?.auto_warmup_enabled;
            },
            disableSortBy: true
          };

        return {
          id: `${column.columnName}`,
          accessor: () => {
            return '';
          },
          disableSortBy: true
        };
      })
    ],
    [additionalData.sendingIpData, columnsToDisplay, resourceType]
  );
  const tableInstance = useTable(
    {
      columns: tableInstanceColumns,
      data: metricsData,
      initialState: {
        pageIndex: DEFAULT_CURRENT_PAGE - 1, // react-table takes a 0 base pageIndex
        pageSize: DEFAULT_PER_PAGE,
        sortBy: [
          {
            id: defaultColumnToSortBy,
            desc: true
          }
        ]
      },
      disableSortRemove: true
    },
    useSortBy,
    usePagination
  );

  const { prepareRow, page, pageOptions, gotoPage } = tableInstance;

  return (
    <>
      <Panel mb="0" borderBottom="0">
        <Table title="Breakdown Table">
          <thead>
            <Table.Row>
              {tableInstance.headerGroups[0].headers.map((column, index) => {
                const unit = list.find((item) => item.label === column.render('Header'))?.unit;
                return (
                  <Table.HeaderCell key={index} align={unit === 'number' ? 'right' : 'left'}>
                    {column.canSort ? (
                      <Table.SortButton
                        direction={column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : null}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        {column.render('Header')}
                      </Table.SortButton>
                    ) : (
                      column.render('Header')
                    )}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </thead>
          <tbody>
            {page?.map((row, index) => {
              prepareRow(row);

              return (
                <Table.Row key={`table-row-${index}`}>
                  {columnsToDisplay.map(({ columnName, type }, index) => {
                    const unit = list.find((item) => item.label === columnName)?.unit;
                    return (
                      <Table.Cell
                        width={index === 0 ? '25%' : 75 / columnsToDisplay.length + '%'}
                        key={index}
                        align={unit === 'number' ? 'right' : 'left'}
                      >
                        {type === 'metric' && <Unit value={row.values[columnName]} unit={unit} />}

                        {type === 'resource' && (
                          <ResourceCell
                            value={row.values[columnName]}
                            resourceType={resourceType}
                            columnName={columnName}
                            additionalData={additionalData}
                          />
                        )}
                        {type === 'tags' && row.values[columnName] && (
                          <Tag color="yellow">Warm-Up</Tag>
                        )}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
          </tbody>
        </Table>
      </Panel>
      <Panel>
        <Panel.Section padding="300">
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Pagination
              onChange={(page) => gotoPage(page)}
              pageRange={5}
              pages={pageOptions.length}
            />
            <Panel.Action as={PageLink} to={viewFullReportLink}>
              View Full Report <Button.Icon as={ShowChart} />
            </Panel.Action>
          </Box>
        </Panel.Section>
      </Panel>
    </>
  );
}

function ResourceCell({ value, columnName, resourceType, additionalData }) {
  const { filters } = useResourceDetailsContext();

  const resourceUrl = getDetailsPageUrl(resourceType, value, {
    from: filters.from.toISOString(),
    to: filters.to.toISOString()
  });

  if (columnName === 'Subaccount') {
    const subaccountName = additionalData.subaccountData.find((x) => x.id === value)?.name;
    return (
      <PageLink to={resourceUrl}>
        <Subaccount id={value} name={subaccountName} shrinkLength={12} master={value === 0} />
      </PageLink>
    );
  }

  return <PageLink to={resourceUrl}>{value}</PageLink>;
}
