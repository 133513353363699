import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { Provider } from 'react-redux';
import ErrorBoundary from 'src/components/errorBoundaries/ErrorBoundary';
import { ThemeProvider } from 'src/components/matchbox';
import { HibanaProvider } from 'src/context/HibanaContext';
import { AuthenticationProvider } from 'src/context/AuthenticationContext';
import Poll from 'src/context/Poll';
import { QueryClientProvider } from 'src/context/QueryClient';
import {
  DEFAULT_REDIRECT_ROUTE,
  DASHBOARD_ROUTE,
  ROOT_ROUTE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  REGISTER_ROUTE,
  SIGN_UP_ROUTE,
  JOIN_ROUTE,
  AUTH_ROUTE,
  LOGOUT_ROUTE,
  AUTH0_LOGOUT_ROUTE
} from 'src/constants';
import config from 'src/appConfig';
import { redirectAfterLogin } from 'src/helpers/localStorage';
import { setRedirectAfterLogin } from 'src/actions/auth';

const publicPaths = [
  DEFAULT_REDIRECT_ROUTE,
  DASHBOARD_ROUTE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  REGISTER_ROUTE,
  SIGN_UP_ROUTE,
  JOIN_ROUTE,
  AUTH_ROUTE,
  LOGOUT_ROUTE,
  AUTH0_LOGOUT_ROUTE
];

const { log } = console;
if (config?.environment === 'dev') log('STAGE: ', config.STAGE);

const reloadApp = () => {
  window.location.reload(true);
};

const currentPathDoesNotMatchPublicPath = (publicPath) => {
  return (
    window?.location?.pathname !== ROOT_ROUTE && // root "/" will always match location pathname, so check it directly
    window?.location?.pathname.match(new RegExp('^' + publicPath, 'g')) === null
  );
};

const userNeedsRedirectedAfterLogin = () => {
  return publicPaths.every(currentPathDoesNotMatchPublicPath);
};

if (userNeedsRedirectedAfterLogin()) {
  redirectAfterLogin.set(`${window?.location?.pathname}${window?.location?.search}`); // Set before react initializes and the user logs in
}

const Providers = ({ store = {}, children }) => {
  const onRedirectCallback = (appState) => {
    if (appState?.redirectAfterLogin?.length) {
      const { dispatch } = store;
      // Note: redirectAfterLogin comes through on first login callback through the imported localstorage object
      dispatch(setRedirectAfterLogin(appState?.redirectAfterLogin));
      redirectAfterLogin.remove(); // Remove after we get the value back from appState
    }
  };

  const auth0ProviderConfig = {
    domain: config?.auth0?.domain,
    clientId: config?.auth0?.clientId,
    ...(config?.auth0?.audience ? { audience: config?.auth0?.audience } : null),
    ...(config?.auth0?.connection ? { connection: config?.auth0?.connection } : null),
    ...(config?.auth0?.organization ? { organization: config?.auth0?.organization } : null),
    redirectUri: `${window?.location?.origin}${AUTH_ROUTE}`,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    onRedirectCallback
  };

  return (
    <Auth0Provider {...auth0ProviderConfig}>
      <Provider store={store}>
        <QueryClientProvider>
          <AuthenticationProvider>
            <HibanaProvider>
              <ThemeProvider target={document.querySelector('#styled-components-target')}>
                <ErrorBoundary onCtaClick={reloadApp} ctaLabel="Reload Page">
                  <Poll>{children}</Poll>
                </ErrorBoundary>
              </ThemeProvider>
            </HibanaProvider>
          </AuthenticationProvider>
        </QueryClientProvider>
      </Provider>
    </Auth0Provider>
  );
};

export default Providers;
