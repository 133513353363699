import React from 'react';
import { Header, Footer } from 'src/components/hibana';
import WindowSize from 'src/context/WindowSize';
import { BannerContext } from 'src/context/GlobalBanner';
import withContext from 'src/context/withContext';
import ScrollToTop from './components/ScrollToTop';
import { AppWrapper, AppMain, AppContainer } from './styles';
export const App = ({ children }) => {
  return (
    <WindowSize>
      <AppWrapper>
        <Header />

        <AppMain role="main" tabIndex="-1" id="main-content">
          <AppContainer>{children}</AppContainer>
        </AppMain>

        <Footer />

        <ScrollToTop />
      </AppWrapper>
    </WindowSize>
  );
};

export default withContext(BannerContext, App);
