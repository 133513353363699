import { Event } from 'src/typescript';
// eslint-disable-next-line local/no-matchbox-import
import { TagProps } from '@sparkpost/matchbox';

export const getEventColor = (eventType: Event['type']): TagProps['color'] => {
  switch (eventType) {
    case 'policy_rejection':
    case 'generation_failure':
    case 'generation_rejection':
    case 'bounce':
    case 'out_of_band':
    case 'spam_complaint':
      return 'red';

    case 'delay':
    case 'link_unsubscribe':
    case 'list_unsubscribe':
      return 'yellow';

    case 'open':
    case 'initial_open':
    case 'click':
    case 'amp_click':
    case 'amp_open':
    case 'amp_initial_open':
      return 'green';

    case 'injection':
    case 'delivery':
    default:
      return 'lightGray';
  }
};
