import React from 'react';
import { connect } from 'react-redux';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import { clear } from 'src/actions/globalAlert';
import { Portal } from 'src/components/matchbox';
import { PORTALS } from 'src/constants';
import Alert from './Alert';
import Animator from './Animator';
// eslint-disable-next-line local/no-css-module-import
import styles from './GlobalAlert.module.scss';

export const GlobalAlertWrapper = ({ alerts, clear }: $TODOFIXME) => {
  return (
    <Portal containerId={PORTALS.ALERT.ID}>
      <div className={styles.Wrapper}>
        <TransitionGroup>
          {alerts.map((alert: $TODOFIXME) => (
            <Animator key={alert.id}>
              <div className={styles.Alert}>
                <Alert onDismiss={() => clear(alert.id)} {...alert} />
              </div>
            </Animator>
          ))}
        </TransitionGroup>
      </div>
    </Portal>
  );
};

const mapStateToProps = ({ globalAlert }: $TODOFIXME) => ({ ...globalAlert });

export default connect(mapStateToProps, { clear })(GlobalAlertWrapper);
