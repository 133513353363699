import React, { useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import * as tfaActions from 'src/actions/tfa';
import { ApiErrorBanner, Loading } from 'src/components';
import { ExternalLink, SubduedLink } from 'src/components/links';
import { Box, Layout, Panel, Stack } from 'src/components/matchbox';
import { SubduedText } from 'src/components/text';
import { LINKS } from 'src/constants';
import NewTfaManagerContent from './NewTfaManagerContent';

const mapStateToProps = ({
  currentUser,
  account
}: {
  account: {
    tfa_required: boolean;
  };
  currentUser: {
    username: string;
  };
}): { required: boolean; username: string } => {
  return {
    username: currentUser?.username,
    required: account.tfa_required
  };
};

type GetTfaFactors = () => Promise<{
  mfa_factors: Array<object>;
}>;

const mapDispatchToProps = {
  getTfaFactors: tfaActions.getTfaFactors
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type NewTfaManagerProps = {
  getTfaFactors: GetTfaFactors;
  username: string;
};

type Response =
  | {
      enabled?: boolean;
      type?: string;
      value?: null | string;
    }
  | {
      [key: string]: unknown;
    };

interface LocalState {
  enabled: string | undefined;
  enabledValue: string | undefined;
  errorLoading: boolean;
  loading: boolean;
}

const initialState: LocalState = {
  enabled: undefined,
  enabledValue: undefined,
  errorLoading: false,
  loading: true
};

type payloadValue = boolean | string | null | undefined;
type Action = { payload: payloadValue; type: string };

function reducer(state: LocalState, action: Action): LocalState {
  const { type, payload } = action;
  return { ...state, [type]: payload };
}

const NewTfaManager = function (props: NewTfaManagerProps): JSX.Element {
  const { username, getTfaFactors } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const update = (key: string, value: payloadValue): void =>
    dispatch({ type: key, payload: value });
  const { enabled, enabledValue, errorLoading, loading } = state;

  const init = function () {
    update('loading', true);

    getTfaFactors()
      .then((tfaFactors) => {
        const authenticator: Response | undefined = tfaFactors?.mfa_factors.find(
          (i: { type: string }) => i.type === 'authenticator'
        );

        const sms: Response | undefined = tfaFactors?.mfa_factors.find(
          (i: { type: string }) => i.type === 'sms'
        );

        if (authenticator) {
          update('enabled', 'Authenticator Application');
          update('enabledValue', authenticator?.value);
        } else if (sms) {
          update('enabled', 'SMS');
          update('enabledValue', sms?.value);
        }

        update('loading', false);
      })
      .catch(() => {
        update('errorLoading', true);
      });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(init, []);

  useEffect(() => {
    window.addEventListener('focus', init);

    return () => {
      window.removeEventListener('focus', init);
    };
  });

  const contentReady = !loading && !errorLoading;

  return (
    <>
      <Layout>
        <Layout.Section annotated>
          <Layout.SectionTitle as="h2">Multi-Factor Authentication</Layout.SectionTitle>
          <Stack>
            <SubduedText fontSize="200">
              Enrolling in multi-factor authentication (MFA) provides an additional layer of
              security to an account.
            </SubduedText>
            <SubduedLink as={ExternalLink} to={LINKS.LEARN_MORE_TFA} fontSize="200">
              MFA Documentation
            </SubduedLink>
          </Stack>
        </Layout.Section>

        {loading && (
          <Layout.Section>
            <Panel mb="-1px">
              <Box height="900">
                <Loading />
              </Box>
            </Panel>
          </Layout.Section>
        )}

        {errorLoading && (
          <Layout.Section>
            <Box height="900">
              <ApiErrorBanner
                status="muted"
                title="Unable to load Multi-Factor Authentication"
                message="Please reload the page and try again."
                errorDetails=""
              />
            </Box>
          </Layout.Section>
        )}

        {contentReady && (
          <NewTfaManagerContent enabled={enabled} enabledValue={enabledValue} username={username} />
        )}
      </Layout>
    </>
  );
};

export default connector(NewTfaManager);
