/* eslint-disable local/require-is-first-render-empty-state-loading */
import {
  bounceTabMetrics,
  delayTabMetrics,
  linksTabMetrics,
  rejectionTabMetrics
} from '@sparkpost/report-builder/config';
import { formatInTimeZone } from 'date-fns-tz';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getSubscription } from 'src/actions/billing';
import { getReports } from 'src/actions/reports';
import { list as getSubaccountsList } from 'src/actions/subaccounts';
import {
  AggregatedMetrics,
  CompareByAggregatedMetrics,
  Empty,
  Loading,
  Tabs
} from 'src/components';
import { Page, Panel, Stack, Tooltip } from 'src/components/matchbox';
import { parseSearch } from 'src/helpers/analyticsReport';
import { shrinkToFit } from 'src/helpers/string';
import styled from 'styled-components';
import { Charts, CompareByGroupByTable, GroupByTable, ReportOptions } from './components';
import SavedReportsSection from './components/SavedReportsSection/SavedReportsSection';
import {
  BounceReasonsTab,
  DelayReasonsTab,
  LinksTab,
  RejectionReasonsTab
} from './components/tabs';
import { PRESET_REPORT_CONFIGS } from './constants';
import { useAnalyticsReportContext } from './context/AnalyticsReportContext';

import { RUDDERSTACK_EVENTS } from 'src/helpers/rudderstack';
import track from 'src/helpers/track';

const PrimaryAreaWrapper = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex-wrap: wrap;
    min-width: 320px;
  }
`;

const DEFAULT_REPORT = 'summary';

function getFormattedDateRangeForAggregateData(from, to, timezone) {
  if (!from || !to) {
    return '';
  }
  return `${formatInTimeZone(new Date(from), timezone, 'MMM do')} - ${formatInTimeZone(
    new Date(to),
    timezone,
    "MMM do',' yyyy"
  )}`;
}

export function AnalyticsReport({
  getSubscription,
  // subscription,
  reports,
  reportsStatus,
  getReports,
  getSubaccountsList,
  subaccountsReady
}) {
  const [showTable, setShowTable] = useState(true); // TODO: Incorporate in to the context reducer due to state interaction
  const { state: reportOptions, selectors, actions } = useAnalyticsReportContext();
  const location = useLocation();
  const { refreshReportOptions, setReport } = actions;
  const { selectedReport } = reportOptions;
  const {
    selectSummaryMetricsProcessed: processedMetrics,
    formattedMetrics,
    selectApiParams: summarySearchOptions = {}
  } = selectors;

  useEffect(() => {
    track(RUDDERSTACK_EVENTS.REPORT_BUILDER_VIEWED);
  }, []);

  const hasActiveComparisons = Boolean(reportOptions.comparisons.length);
  const isEmpty = useMemo(() => {
    return !Boolean(reportOptions.metrics && reportOptions.metrics.length);
  }, [reportOptions.metrics]);

  useEffect(() => {
    if (reportsStatus === 'success') {
      getSubscription();
    }
    //Updates subscription with reports to make sure product count is up to date
  }, [getSubscription, reports, reportsStatus]);

  useEffect(() => {
    getSubaccountsList();
  }, [getSubaccountsList]);

  useEffect(() => {
    getReports();
  }, [getReports]);

  // Grabs report options from the URL query params (as well as report ID)
  useEffect(() => {
    let { report: reportId, ...urlOptions } = parseSearch(location.search);

    if (!reportId && !location.search) reportId = DEFAULT_REPORT;

    // Looks for report with report ID
    const allReports = [...reports, ...PRESET_REPORT_CONFIGS];
    const report = allReports.find(({ id }) => id === reportId);
    // Waiting on reports to load (if enabled) to finish initializing
    // Waiting on subaccounts (if using comparators) to finish initializing
    if (
      (reportId && reportsStatus !== 'success') ||
      !subaccountsReady ||
      reportOptions.isReady // Already ran once
    ) {
      return;
    }

    // If report is found from ID, consolidates reportOptions from URL and report
    if (report) {
      const reportOptions = parseSearch(report.query_string);

      //If coming from scheduled report, only should have report options (rest of URL should be blank)
      //If coming from copy and pasted URL with report and overridden filters, should have report filters, and then URL filters on top
      //TODO: Don't have parseSearch return an empty array by default (so it doesn't overwrite report filters);
      if (!Boolean(urlOptions?.filters?.length)) {
        delete urlOptions.filters;
      }
      if (!Boolean(urlOptions?.comparisons?.length)) {
        delete urlOptions.comparisons;
      }

      setReport(report); // TODO: This needs to be incorporated in to the reducer since this causes state interaction
      refreshReportOptions({
        ...reportOptions,
        ...urlOptions
      });
    } else {
      // Initializes w/ just URL options
      refreshReportOptions(urlOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsStatus, reports, subaccountsReady]);

  const hasBounceMetrics = processedMetrics.some(({ key }) => {
    return bounceTabMetrics.map(({ key }) => key).includes(key);
  });
  const hasBounceTab = hasBounceMetrics && !hasActiveComparisons;
  const hasRejectionMetrics = processedMetrics.some(({ key }) => {
    return rejectionTabMetrics.map(({ key }) => key).includes(key);
  });
  const hasRejectionTab = hasRejectionMetrics && !hasActiveComparisons;
  const hasDelayMetrics = processedMetrics.some(({ key }) => {
    return delayTabMetrics.map(({ key }) => key).includes(key);
  });
  const hasDelayTab = hasDelayMetrics && !hasActiveComparisons;
  const hasLinksMetrics = processedMetrics.some(({ key }) => {
    return linksTabMetrics.map(({ key }) => key).includes(key);
  });
  const hasLinksTab = hasLinksMetrics && !hasActiveComparisons;
  const [currentTab, setCurrentTab] = useState('Report');

  const handleReportChange = (report) => {
    if (report) {
      const options = parseSearch(report.query_string);
      //Keep time range and filters when changing to preset report from another preset report.
      if (report.type === 'preset' && (!selectedReport || selectedReport.type === 'preset')) {
        const { from, to, relativeRange, timezone, precision, filters, comparisons, groupBy } =
          reportOptions;
        const mergedOptions = {
          ...options,
          from,
          to,
          relativeRange,
          timezone,
          precision,
          filters,
          comparisons,
          groupBy
        };
        refreshReportOptions(mergedOptions);
      } else {
        refreshReportOptions(options);
      }
    }
    setReport(report);
  };

  const tabs = useMemo(() => {
    /**
     * For each type of relevant metrics that could render a tab,
     * loop through active comparisons and add rendered tabs depending on the metrics selected by the end user
     */
    function getComparisonTabs() {
      let comparisonTabs = [];

      if (hasBounceMetrics) {
        reportOptions.comparisons.forEach((comparison) => {
          comparisonTabs.push({
            content: `Bounce Reason ${comparison.value}`,
            onClick: () => {
              setShowTable(false);
              setCurrentTab('Bounce Reason ' + comparison.value);
            }
          });
        });
      }

      if (hasRejectionMetrics) {
        reportOptions.comparisons.forEach((comparison) => {
          comparisonTabs.push({
            content: `Rejection Reason ${comparison.value}`,
            onClick: () => {
              setShowTable(false);
              setCurrentTab('Rejection Reason ' + comparison.value);
            }
          });
        });
      }

      if (hasLinksMetrics) {
        reportOptions.comparisons.forEach((comparison) => {
          comparisonTabs.push({
            content: `Links ${comparison.value}`,
            onClick: () => {
              setShowTable(false);
              setCurrentTab('Links ' + comparison.value);
            }
          });
        });
      }

      if (hasDelayMetrics) {
        reportOptions.comparisons.forEach((comparison) => {
          comparisonTabs.push({
            content: `Delay Reason ${comparison.value}`,
            onClick: () => {
              setShowTable(false);
              setCurrentTab('Delay Reason ' + comparison.value);
            }
          });
        });
      }

      return comparisonTabs;
    }
    return [
      {
        content: 'Report',
        onClick: () => {
          setShowTable(true);
          setCurrentTab('Report');
        }
      },
      hasBounceTab && {
        content: 'Bounce Reason',
        onClick: () => {
          setShowTable(false);
          setCurrentTab('Bounce Reason');
        }
      },
      hasRejectionTab && {
        content: 'Rejection Reason',
        onClick: () => {
          setShowTable(false);
          setCurrentTab('Rejection Reason');
        }
      },
      hasDelayTab && {
        content: 'Delay Reason',
        onClick: () => {
          setShowTable(false);
          setCurrentTab('Delay Reason');
        }
      },
      hasLinksTab && {
        content: 'Links',
        onClick: () => {
          setShowTable(false);
          setCurrentTab('Links');
        }
      },
      ...getComparisonTabs()
    ].filter(Boolean);
  }, [
    hasBounceTab,
    hasRejectionTab,
    hasDelayTab,
    hasLinksTab,
    hasBounceMetrics,
    hasRejectionMetrics,
    hasLinksMetrics,
    hasDelayMetrics,
    reportOptions.comparisons
  ]);

  useEffect(() => {
    setShowTable(true);
    setCurrentTab('Report');
  }, [tabs]);

  const { scheduledReports = [] } = useSelector((state) => state.reports);

  const { timezone } = summarySearchOptions;
  const dateValue = getFormattedDateRangeForAggregateData(
    reportOptions.from,
    reportOptions.to,
    timezone
  );
  if (!reportOptions.isReady) {
    return <Loading />;
  }

  function getPrimaryArea() {
    return (
      <PrimaryAreaWrapper>
        <SavedReportsSection
          scheduledReports={scheduledReports}
          handleReportChange={handleReportChange}
        />
      </PrimaryAreaWrapper>
    );
  }

  function getPageTitle() {
    if (selectedReport?.id) {
      if (selectedReport?.name === shrinkToFit(selectedReport?.name, 50)) {
        return selectedReport?.name;
      }
      return (
        <Tooltip content={selectedReport?.name} key="report_name" as="span" id="report_name">
          {shrinkToFit(selectedReport?.name, 50)}
        </Tooltip>
      );
    }

    return 'Analytics Report';
  }

  return (
    <Page title={getPageTitle()} primaryArea={getPrimaryArea()}>
      <Stack>
        <ReportOptions
          selectedReport={selectedReport}
          setReport={setReport}
          searchOptions={summarySearchOptions}
        />
        <Panel>
          {isEmpty ? (
            <Empty message="No Data" description="Must select at least one metric." />
          ) : (
            <div data-id="summary-chart">
              <Tabs defaultTabIndex={0} forceRender tabs={tabs}></Tabs>
              <Tabs.Item selected={currentTab === 'Report'} tabType="Report">
                <Charts />

                {hasActiveComparisons ? (
                  <CompareByAggregatedMetrics
                    formattedMetrics={formattedMetrics}
                    date={dateValue}
                    reportOptions={reportOptions}
                  />
                ) : (
                  <AggregatedMetrics
                    formattedMetrics={formattedMetrics}
                    date={dateValue}
                    reportOptions={reportOptions}
                  />
                )}
              </Tabs.Item>

              {hasBounceTab && (
                <Tabs.Item selected={currentTab === 'Bounce Reason'}>
                  <BounceReasonsTab />
                </Tabs.Item>
              )}

              {hasRejectionTab && (
                <Tabs.Item selected={currentTab === 'Rejection Reason'}>
                  <RejectionReasonsTab />
                </Tabs.Item>
              )}

              {hasDelayTab && (
                <Tabs.Item selected={currentTab === 'Delay Reason'}>
                  <DelayReasonsTab />
                </Tabs.Item>
              )}

              {hasLinksTab && (
                <Tabs.Item selected={currentTab === 'Links'}>
                  <LinksTab />
                </Tabs.Item>
              )}

              {hasBounceMetrics &&
                hasActiveComparisons &&
                reportOptions.comparisons.map((comparison, index) => {
                  return (
                    <Tabs.Item
                      key={`tab-bounce-${comparison.value}-${index}`}
                      selected={currentTab === 'Bounce Reason ' + comparison.value}
                    >
                      <BounceReasonsTab comparison={comparison} />
                    </Tabs.Item>
                  );
                })}

              {hasLinksMetrics &&
                hasActiveComparisons &&
                reportOptions.comparisons.map((comparison, index) => {
                  return (
                    <Tabs.Item
                      key={`tab-links-${comparison.value}-${index}`}
                      selected={currentTab === 'Links ' + comparison.value}
                    >
                      <LinksTab comparison={comparison} />
                    </Tabs.Item>
                  );
                })}

              {hasDelayMetrics &&
                hasActiveComparisons &&
                reportOptions.comparisons.map((comparison, index) => {
                  return (
                    <Tabs.Item
                      key={`tab-delay-${comparison.value}-${index}`}
                      selected={currentTab === 'Delay Reason ' + comparison.value}
                    >
                      <DelayReasonsTab comparison={comparison} />
                    </Tabs.Item>
                  );
                })}

              {hasRejectionMetrics &&
                hasActiveComparisons &&
                reportOptions.comparisons.map((comparison, index) => {
                  return (
                    <Tabs.Item
                      key={`tab-rejection-${comparison.value}-${index}`}
                      selected={currentTab === 'Rejection Reason ' + comparison.value}
                    >
                      <RejectionReasonsTab comparison={comparison} />
                    </Tabs.Item>
                  );
                })}
            </div>
          )}
        </Panel>
        {showTable && (
          <div>{hasActiveComparisons ? <CompareByGroupByTable /> : <GroupByTable />}</div>
        )}
      </Stack>
    </Page>
  );
}

// Redux
const mapStateToProps = (state) => ({
  reports: state.reports.list,
  reportsStatus: state.reports.status,
  subaccountsReady: state.subaccounts.ready,
  subscription: state.billing.subscription
});

const mapDispatchToProps = {
  getSubscription,
  getReports,
  getSubaccountsList
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsReport);
