import _ from 'lodash';
import React, { ChangeEvent, MouseEventHandler, useCallback, useState } from 'react';
import { LoadingSVG } from 'src/components';
import { Button, TextField } from 'src/components/matchbox';

type PromoCodeNewProps = {
  disabled: boolean;
  handlePromoCode: {
    applyPromoCode: (promoCode: string) => void;
    clearPromoCode: MouseEventHandler<HTMLButtonElement>;
  };
  promoCodeObj: {
    promoError: {
      message: string;
    };
    promoPending: boolean;
    selectedPromo: {
      promoCode: string;
    };
  };
};

const PromoCodeNew = ({ promoCodeObj, handlePromoCode, disabled }: PromoCodeNewProps) => {
  const { applyPromoCode, clearPromoCode } = handlePromoCode;
  const { promoError, promoPending, selectedPromo } = promoCodeObj;
  const [promoCode, setPromoCode] = useState(selectedPromo.promoCode || '');

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPromoCode(event.target.value);
  }, []);

  const handleClick = useCallback(() => {
    applyPromoCode(promoCode);
  }, [applyPromoCode, promoCode]);

  const renderActionButton = (condition: string) => {
    if (condition) {
      return (
        <Button variant="connected" onClick={clearPromoCode} disabled={disabled}>
          Remove
        </Button>
      );
    }
    return (
      <Button variant="connected" onClick={handleClick} disabled={promoPending || disabled}>
        Apply
      </Button>
    );
  };

  const renderLoading = (condition: string | boolean) =>
    condition ? <LoadingSVG size="XSmall" /> : null;
  const isDisabled = () => promoPending || _.has(selectedPromo, 'promoCode') || disabled;
  const displayErrorMessage = () => (promoError ? promoError.message : '');

  return (
    <TextField
      id="promo-code"
      name="promo"
      label="Promo Code"
      disabled={isDisabled()}
      data-track={true}
      connectRight={renderActionButton(selectedPromo.promoCode)}
      onChange={handleChange}
      suffix={renderLoading(promoPending)}
      error={displayErrorMessage()}
      defaultValue={selectedPromo.promoCode || ''}
    />
  );
};

export default PromoCodeNew;
