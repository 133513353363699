const productionConfig = {
  sentry: {
    dsn: 'https://c0c34ae6836f477b97695ec9a48e4ef6@o113111.ingest.sentry.io/4504046130167808',
    publicKey: 'c0c34ae6836f477b97695ec9a48e4ef6'
  },
  vwo: {
    enabled: true
  },
  rudderstack: {
    enabled: true,
    publicKey: '2A5AmcNdnGyb5kBeZJrij0iIPwI'
  }
};

module.exports = productionConfig;
