import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAnalyticsReportContext } from '../context/AnalyticsReportContext';
import { list as METRICS_LIST } from '@sparkpost/report-builder/config';
import { hasProductOnBillingSubscription } from 'src/helpers/conditions/account';

export default function useAllowSubjectCampaignFilter() {
  const {
    state: { metrics }
  } = useAnalyticsReportContext();
  const hasD12yProduct = useSelector((state) =>
    hasProductOnBillingSubscription('deliverability')(state)
  );

  const d12yMetricsMap = useMemo(
    () =>
      METRICS_LIST.reduce((accumulator, metric) => {
        if (metric.product === 'deliverability') {
          return accumulator.set(metric.key, true);
        }
        return accumulator;
      }, new Map()),
    //Creates a hashmap with the d12y metrics as keys.
    []
  );

  //Only allow subject campaign filter when every metric is d12y and the user has the d12y product
  const allowSubjectCampaignFilter = useMemo(() => {
    const hasAtLeastOneD12yMetric = metrics?.some((metric) => d12yMetricsMap.has(metric));
    return hasAtLeastOneD12yMetric && hasD12yProduct;
  }, [metrics, hasD12yProduct, d12yMetricsMap]);

  return allowSubjectCampaignFilter;
}
