import { kebabCase, memoize, uniqueId } from 'lodash';
import React, { useCallback, useState } from 'react';

// COMPONENTS
import ActionPopover from 'src/components/actionPopover';
import { Box, Expandable, Skeleton, Tooltip } from 'src/components/matchbox';
import { TranslatableText } from '../text';

// HELPERS
import { shrinkToFit } from 'src/helpers/string';

// STYLES
import { ActionIcon, Item, ItemActionButton, MinimalExpandableWrapper, SubTitle } from './styles';

// TYPES
import { SidebarExpandableItemProps } from 'src/typescript';

const getUniqueId = memoize((prefix) => uniqueId(`${kebabCase(prefix)}-`));

export const SidebarExpandableItem = ({
  id,
  title,
  onToggle,
  items,
  loading,
  open
}: SidebarExpandableItemProps): JSX.Element => {
  const defaultOpened = open || false;
  const [isOpen, setIsOpen] = useState(defaultOpened);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
    onToggle && onToggle();
  }, [isOpen, onToggle]);

  const wrapIcon = (icon: React.ReactNode): null | JSX.Element => {
    if (!icon) return null;
    return <Box mr={400}>{icon}</Box>;
  };

  const getTitle = (title: string): string | JSX.Element => {
    const shortenedTitle =
      title.substring(0, 18) + shrinkToFit(title.substring(18, title.length), 11);

    if (title === shortenedTitle) {
      return title;
    }

    return (
      <Tooltip content={title} as="span" id={`tooltip-${id}`}>
        {shortenedTitle}
      </Tooltip>
    );
  };

  return (
    <MinimalExpandableWrapper>
      <Expandable
        id={id}
        subtitle={<SubTitle>{title}</SubTitle>}
        title=""
        variant="borderless"
        onToggle={handleToggle}
        open={isOpen}
      >
        <>
          {items.map(({ title, onClick, icon, actions, active }, index) => {
            const hasActions = actions && actions.length;
            const hasClick = onClick && typeof onClick === 'function';

            if (loading)
              return (
                <Skeleton.Box
                  data-id="table-skeleton-row"
                  height="600"
                  width="100%"
                  // eslint-disable-next-line react/no-array-index-key
                  key={`skeleton-${index}`}
                />
              );
            else
              return (
                <Item
                  display="grid"
                  role="listitem button"
                  gridTemplateColumns={hasActions ? '1fr 50px' : '1fr'}
                  minHeight="600"
                  active={active}
                  // eslint-disable-next-line react/jsx-no-bind
                  onKeyDown={(e): void => {
                    if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
                      hasClick && onClick();
                    }
                  }}
                  key={getUniqueId(title)}
                  aria-pressed={active ? true : false}
                  aria-current={active ? 'page' : false}
                  tabIndex={0}
                  hasClick={hasClick}
                  onClick={onClick}
                >
                  <Box display="flex" alignItems="center" fontSize={200}>
                    {wrapIcon(icon)}
                    <TranslatableText>{getTitle(title)}</TranslatableText>
                  </Box>

                  {hasActions && (
                    <ItemActionButton aria-labelledby={`${title} Menu`}>
                      <ActionPopover
                        buttonId={`actions-${id}`}
                        direction="right"
                        display="inline"
                        textAlign="left"
                        actions={actions}
                        buttonProps={{
                          size: 'small'
                        }}
                        popoverProps={{
                          position: 'topRight'
                        }}
                        customIcon={ActionIcon}
                      />
                    </ItemActionButton>
                  )}
                </Item>
              );
          })}
        </>
      </Expandable>
    </MinimalExpandableWrapper>
  );
};

export function SidebarExpandable(props: React.ComponentProps<typeof Expandable>): JSX.Element {
  return (
    <MinimalExpandableWrapper>
      <Expandable
        {...props}
        variant="borderless"
        subtitle={<SubTitle>{props.title}</SubTitle>}
        title=""
      />
    </MinimalExpandableWrapper>
  );
}
