import React from 'react';
import BackupCodesList from './BackupCodesList';
import CopyCodes from './CopyCodes'; //todo use CopyToClipboard component
import { Stack } from 'src/components/matchbox';

const BackupCodes = ({ codes }) => (
  <Stack>
    <p>
      <strong>Recovery Codes</strong>
    </p>

    <BackupCodesList codes={codes} />

    <CopyCodes codes={codes} />
  </Stack>
);

export default BackupCodes;
