import copy from 'copy-to-clipboard';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { Button, Tooltip } from 'src/components/matchbox';

// TYPES
import { ButtonProps } from '../matchbox/Button.types';

type CopyToClipboardProps = ButtonProps &
  PropsWithChildren<{
    value: string;
  }>;

/**
 * Reusable Copy to Clipboard button
 */
const CopyToClipboard = ({
  children = 'Copy',
  value,
  ...buttonProps
}: CopyToClipboardProps): JSX.Element => {
  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef<undefined | number>();

  useEffect(() => {
    return (): void => {
      setCopied(false);
      window.clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleCopy = (): void => {
    copy(value);
    setCopied(true);
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => setCopied(false), 3000);
  };

  const content = copied ? 'Copied to Clipboard' : 'Copy to Clipboard';

  return (
    <Tooltip dark content={content} id="copy-to-clipboard-button">
      <Button {...buttonProps} name="copy-field-button" onClick={handleCopy}>
        {children}
      </Button>
    </Tooltip>
  );
};

export default CopyToClipboard;
