import React from 'react';
import { Box, Button } from 'src/components/matchbox';
import { DEFAULT_PER_PAGE_BUTTONS } from 'src/constants';

/**
 * Use src/components/collection/Pagination.js instead (also update this when a deprecation plan is in place)
 * @deprecated
 */
function PerPageControl({ onChange, perPage, totalCount }) {
  if (!totalCount || totalCount < DEFAULT_PER_PAGE_BUTTONS[0]) {
    return null;
  }

  return (
    <Box p="200">
      <Button.Group>
        <Box as="span">Per Page</Box>

        {DEFAULT_PER_PAGE_BUTTONS.map((size) => {
          const isActive = perPage === size;

          return (
            <Button
              variant={isActive ? 'primary' : 'tertiary'}
              aria-selected={isActive ? 'true' : 'false'}
              key={size}
              onClick={() => onChange(size)}
              ml="200"
              size="small"
              marginX="100"
              width={[0]}
            >
              {size}
            </Button>
          );
        })}
      </Button.Group>
    </Box>
  );
}

export default PerPageControl;
