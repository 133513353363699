import { FIELD_IDENTIFIER_BLOCKLIST } from './constants';
/**
 * @name getFormData
 * @description Converts the form elements to JSON data with name-value pairs for each field along with a any validation error encountered during submission
 */
export function getFormData(formElements, formName) {
  const errors = [];
  let formData = {};

  for (const el of formElements) {
    let name = getElName(el, formName);
    const value = getFieldValue(el);

    if (isError(el) && name) {
      errors.push(name);
    }

    if (!isFieldToBeIgnored(el) && name) {
      formData = { ...formData, [name]: value };
    }
  }
  formData = { ...formData, errors: errors, hasError: errors.length > 0 };

  return formData;
}

/**
 * @name getFieldValue
 * @description Accepts a passed-in field element to retrieve its value. The value is masked if field is part of the blocklist.
 * @param {HTMLElement} fieldEl
 */
export function getFieldValue(fieldEl) {
  const { type, 'data-track': dataTrack } = getElAttributes(fieldEl);
  const checkableTypes = ['checkbox', 'radio'];

  if (!shouldBeFiltered(fieldEl) && dataTrack)
    return checkableTypes.includes(type) ? fieldEl.checked : fieldEl.value;

  return '<filtered>';
}

export function shouldBeFiltered(fieldEl) {
  const { type, 'data-sensitive': dataSensitive, id, name } = getElAttributes(fieldEl);

  return (
    Boolean(dataSensitive) ||
    FIELD_IDENTIFIER_BLOCKLIST.includes(id) ||
    FIELD_IDENTIFIER_BLOCKLIST.includes(name) ||
    type === 'password'
  );
}

/**
 * @name isFieldToBeIgnored
 * @description returns true if the field element is to be ignored from being added to form data.
 */
export function isFieldToBeIgnored(el) {
  const value = getFieldValue(el);
  const { type } = getElAttributes(el);
  const node = el.nodeName;
  return (
    type === 'button' ||
    node === 'BUTTON' ||
    type === 'submit' ||
    type === 'file' ||
    node === 'FIELDSET' ||
    (type === 'radio' && !value) ||
    (type === 'checkbox' && !value)
  );
}

/**
 * @name isError
 * @description Returns true if there is an validation error on the field
 * @param {HTMLElement} fieldEl
 */
export function isError(fieldEl) {
  const { 'aria-invalid': ariaInvalid, type, name } = getElAttributes(fieldEl);

  //aria-invalid is not being set for input fields of type file
  if (type === 'file') {
    const fileErrorEl = document.querySelector(`[id=${name}-error-message]`);

    if (fileErrorEl) return true;

    return false;
  }

  return ariaInvalid === 'true';
}

/**
 * @name getElName
 * @description  Accepts a passed-in field element to retrieve its name
 * @param {HTMLElement} el
 */
export function getElName(el, formName) {
  const { id, name, type } = getElAttributes(el);

  const checkableTypes = ['checkbox', 'radio'];

  if (checkableTypes.includes(type) && id) return id + `(${formName})`;

  if (name) return name + `(${formName})`;

  if (id) return id + `(${formName})`;

  return undefined;
}

/**
 * @name getElAttributes
 * @description Returns all attributes associated with HTML element.
 * @param {HTMLElement} el
 */
function getElAttributes(el) {
  let attributes = {};

  for (const attr of el.attributes) {
    attributes = { ...attributes, [attr.name]: attr.value };
  }

  return attributes;
}
