export const FIELD_IDENTIFIER_BLOCKLIST = [
  'creditCard',
  'credit-card',
  'card',
  'Passcode*',
  'new-password',
  'current-password',
  'Password*',
  'pw',
  'Email Address',
  'Email address',
  'Credit Card',
  'Social Security',
  'Social Security Number',
  'Username',
  'social-security',
  'social-security-number',
  'ssn',
  'socialSecurityNumber',
  'email-address',
  'emailAddress',
  'passcode',

  'recipients', //ScheduleEditFormWrapper
  'to-address', //ScheduleEditFormWrapper
  'recipient', // AddForm
  'email-address-textfield', // AddForm
  'username', //LoginForm, RegisterUserForm, SsoLoginForm
  'password', //LoginForm, RegisterUserForm, JoinForm
  'newPassword', // ResetPasswordPage, PasswordForm
  'confirmNewPassword', // ResetPasswordPage
  'tfa-form-password', // DisableTfaModal
  'tfa-backup-codes-generate-password', // BackupCodesModal
  'Password', // BackupCodesModal
  'firstName', // NameForm
  'lastName', // NameForm
  'currentPassword', //PasswordForm
  'tfa-setup-passcode', //EnableTfaForm
  'Passcode', //EnableTfaForm
  'target', // WebhookForm
  'basicUser', // WebhookForm
  'basicPass', // WebhookForm
  'clientId', // WebhookForm
  'clientSecret', // WebhookForm
  'tokenURL', // WebhookForm
  'first_name', // JoinForm
  'last_name', // JoinForm
  'company_name', // JoinForm
  'email', // JoinForm, SingleRecipientTab
  'emailTo',
  'user', // ForgotPasswordPage
  'billingAddress.firstName', // BillingAddressForm
  'billingAddress.lastName', // BillingAddressForm
  'billingAddress.country', // BillingAddressForm
  'billingAddress.state', // BillingAddressForm
  'billingAddress.zip', // BillingAddressForm
  'card.number', // PaymentForm
  'card.name', // PaymentForm
  'card.expCombined', // PaymentForm
  'card.securityCode', // PaymentForm
  'code', // TfaForm
  'content.from.email', // SettingsForm
  'content.from.name' // SettingsForm
];
