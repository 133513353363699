import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetch as fetchAccount, renewAccount } from 'src/actions/account';
import { Action, AlertType, showAlert } from 'src/actions/globalAlert';
import { selectTargetBanner } from 'src/selectors/globalBanner';
import HeaderAccountBanner from './HeaderAccountBanner';

export interface IPendingCancelBanner {
  account: $TODOFIXME;
  fetchAccount: $TODOFIXME;
  location: $TODOFIXME;
  renewAccount: () => Promise<string>;
  showAlert: <T extends AlertType>(alert: T) => Action<string, T>;
}

export interface HeaderAccountBannerProps extends IPendingCancelBanner {
  targetBanner: 'pending-cancellation' | undefined;
}

const mapStateToProps = (state: $TODOFIXME, props: $TODOFIXME) => {
  return {
    location: state.location,
    account: state.account,
    targetBanner: selectTargetBanner(state, props)
  };
};

const mapDispatchToProps = {
  fetchAccount,
  renewAccount,
  showAlert
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderAccountBanner));
