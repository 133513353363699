import { getLineChartFormatters } from '@sparkpost/report-builder/helpers/date';
import { addMonths, differenceInSeconds, isAfter } from 'date-fns';
import React, { useMemo } from 'react';
import { ApiErrorBanner } from 'src/components';
import LineChart from 'src/components/charts/LineChart';
import Loading from 'src/components/loading/PanelLoading';
import { Box, Panel, Stack } from 'src/components/matchbox';
import { Heading } from 'src/components/text';
import { PRECISION_TO_SECONDS } from 'src/constants';
import { ChartScreenReaderOnlyTable } from './ChartScreenReaderOnlyTable';
import CustomTooltip from './Tooltip';
import { useAnalyticsReportCharts } from './useAnalyticsReportCharts';

export const LABEL_FOR_INCOMPLETE_DATA = {
  hour: 'Incomplete Hour',
  day: 'Incomplete Day',
  week: 'Incomplete Week',
  month: 'Incomplete Month',
  '15min': 'Incomplete 15 Mins',
  '5min': 'Incomplete 5 Mins',
  '1min': 'Incomplete Minute'
};
// Handles charts data
export function AnalyticsReportCharts({ reportOptions, formattedMetrics, small }) {
  const { comparisons } = reportOptions;
  const hasComparisons = comparisons.length > 0;

  const { queries, charts, activeChart, setActiveChart } = useAnalyticsReportCharts(
    reportOptions,
    formattedMetrics
  );

  return (
    <>
      {queries.map((query, index) => {
        return (
          <Panel.Section key={`chart_group_${index}`}>
            {hasComparisons ? (
              <Stack>
                <Box>
                  <Heading looksLike="h5" as="h3">
                    {comparisons[index].value}
                  </Heading>
                </Box>
                <Box>
                  <ChartGroup
                    charts={charts}
                    refetchChart={query.refetch}
                    chartStatus={query.status}
                    data={query.data}
                    id={`chart_group_${index}`}
                    tableCaption={`${comparisons[index].value} Analytics Data Over Time`}
                    activeChart={activeChart}
                    setActiveChart={setActiveChart}
                    reportOptions={reportOptions}
                    formattedMetrics={formattedMetrics}
                    small={small}
                  />
                </Box>
              </Stack>
            ) : (
              <ChartGroup
                charts={charts}
                refetchChart={query.refetch}
                chartStatus={query.status}
                data={query.data}
                id={`chart_group_${index}`}
                activeChart={activeChart}
                setActiveChart={setActiveChart}
                reportOptions={reportOptions}
                formattedMetrics={formattedMetrics}
                small={small}
              />
            )}
          </Panel.Section>
        );
      })}
    </>
  );
}

export function ChartGroup(props) {
  const {
    reportOptions,
    activeChart,
    setActiveChart,
    id,
    small,
    tableCaption,
    chartStatus,
    data: chartData = [],
    refetchChart,
    charts
  } = props;
  const { comparisons, precision, to, timezone: timeZone } = reportOptions;
  const formatters = getLineChartFormatters(precision, to, { includeTimezone: true, timeZone });

  let height = 150;

  switch (charts.length * (comparisons.length || 1)) {
    case 1:
      height = 400;
      if (small) height = 200;
      break;
    case 2:
      height = 200;
      break;
    default:
      break;
  }

  const showAllTooltips = useMemo(() => {
    return charts.every(({ metrics }) => metrics.length <= 4);
  }, [charts]);

  if (chartStatus === 'loading' || chartStatus === 'idle') {
    return <Loading as={Box} minHeight="200px" />;
  }

  if (chartStatus === 'error') {
    return (
      <ApiErrorBanner
        reload={refetchChart}
        status="muted"
        title="Unable to load report"
        message="Please try again"
      />
    );
  }

  const isLastDataPointnToTSWithinPrecision = (chartData) => {
    if (chartData.length > 1) {
      const lastDataPointTS = new Date(chartData[chartData.length - 1].ts);
      const currentTS = new Date();

      if (precision === 'month') {
        const hasMonthInLastDataPointTSEnded = isAfter(currentTS, addMonths(lastDataPointTS, 1));

        return hasMonthInLastDataPointTSEnded ? false : true;
      }

      const lastDataPointnToTSDiff = Math.abs(differenceInSeconds(lastDataPointTS, currentTS));
      return lastDataPointnToTSDiff <= PRECISION_TO_SECONDS[precision];
    }
    return false;
  };

  return (
    <Box>
      <Stack>
        {charts.map((chart, index) => {
          const showLastLineAsDottedLine = isLastDataPointnToTSWithinPrecision(chartData);
          return (
            <Box
              key={`chart-${index}`}
              onMouseOver={() =>
                setActiveChart(showAllTooltips ? `${id}_chart` : `${id}_chart_${index}`)
              }
              data-id="chart-box"
            >
              <LineChart
                yDomain={[chart.min, chart.max]}
                height={height}
                syncId="summaryChart"
                showLastLineAsDottedLine={showLastLineAsDottedLine}
                labelForDottedLine={LABEL_FOR_INCOMPLETE_DATA[precision]}
                data={chartData}
                precision={precision}
                showTooltip={
                  activeChart === (showAllTooltips ? `${id}_chart` : `${id}_chart_${index}`)
                }
                lines={chart.metrics.map(({ name, label, stroke }) => ({
                  key: name,
                  dataKey: name,
                  name: label,
                  stroke
                }))}
                {...formatters}
                yTickFormatter={chart.yAxisFormatter}
                yLabel={chart.label}
                tooltipValueFormatter={chart.yAxisFormatter}
                showXAxis={index === charts.length - 1}
                tooltip={CustomTooltip}
                unit={chart.unit}
              />

              <ChartScreenReaderOnlyTable
                caption={tableCaption}
                captionUnit={chart.label}
                metrics={chart.metrics}
                data={chartData}
              />
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
}
