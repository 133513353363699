import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Panel, Layout, LabelValue, Tag, Stack } from 'src/components/matchbox';
import { PanelLoading } from 'src/components';
import { ExternalLink, SubduedLink } from 'src/components/links';
import * as tfaActions from 'src/actions/tfa';
import BackupCodesModal from './BackupCodesModal';
import EnableTfaModal from './EnableTfaModal';
import DisableTfaModal from './DisableTfaModal';
import { LINKS } from 'src/constants';
import { logout } from 'src/actions/auth';
import { showAlert } from 'src/actions/globalAlert';
import { SubduedText } from 'src/components/text';
export class TfaManager extends Component {
  state = {
    openModal: null
  };

  componentDidMount() {
    this.props.getTfaBackupStatus();

    if (this.props.statusUnknown) {
      this.props.getTfaStatus();
    }
  }

  closeModals = () => this.setState({ openModal: null });

  closeBackupModal = () => {
    this.closeModals();
  };

  onEnable = () => {
    this.closeModals();
    this.props.getTfaBackupStatus();
  };

  disable = (password) => {
    const { showAlert, required, toggleTfa, logout } = this.props;
    return toggleTfa({ enabled: false, password }).then(() => {
      if (required) {
        logout();
      }
      showAlert({ type: 'success', message: 'Profile Updated.' });
    });
  };

  generateBackupCodes = (password) =>
    this.props.generateBackupCodes(password).then(this.props.getTfaBackupStatus);

  render() {
    const { statusUnknown, enabled, required, backupCodes } = this.props;

    if (statusUnknown) {
      return <PanelLoading minHeight="100px" />;
    }

    return (
      <Layout>
        <Layout.Section annotated>
          <Layout.SectionTitle as="h2">Multi-factor Authentication</Layout.SectionTitle>
          <Stack>
            <SubduedText>
              Enrolling in multi-factor authentication (MFA) provides an additional layer of
              security to an account.
            </SubduedText>
            <SubduedLink as={ExternalLink} to={LINKS.LEARN_MORE_TFA}>
              MFA Documentation
            </SubduedLink>
          </Stack>
        </Layout.Section>
        <Layout.Section>
          <Panel mb="-1px">
            <Panel.Header>
              <LabelValue>
                <LabelValue.Label>Status</LabelValue.Label>
                <LabelValue.Value>
                  <Tag color={enabled ? 'green' : 'lightGray'}>
                    <span>{enabled ? 'Enabled' : 'Disabled'}</span>
                  </Tag>
                </LabelValue.Value>
              </LabelValue>
              {enabled && (
                <Panel.Action onClick={() => this.setState({ openModal: 'backupCodes' })}>
                  Generate New Backup Codes
                </Panel.Action>
              )}
              {enabled && (
                <Panel.Action onClick={() => this.setState({ openModal: 'disable' })}>
                  {required ? <>Reset MFA</> : <> Disable MFA</>}
                </Panel.Action>
              )}
              {!enabled && (
                <Panel.Action onClick={() => this.setState({ openModal: 'enable' })}>
                  Enable MFA
                </Panel.Action>
              )}
            </Panel.Header>
            <Panel.Section>
              <LabelValue>
                <LabelValue.Label>Active Codes</LabelValue.Label>
                <LabelValue.Value>
                  {backupCodes.activeCount}
                  {backupCodes.activeCount === 0 && (
                    <small>
                      &nbsp;(We recommend saving a set of backup codes in case you lose or misplace
                      your current device.)
                    </small>
                  )}
                </LabelValue.Value>
              </LabelValue>
            </Panel.Section>

            <BackupCodesModal
              open={this.state.openModal === 'backupCodes'}
              onClose={this.closeBackupModal}
              {...this.props.backupCodes}
              generate={this.generateBackupCodes}
              clearCodes={this.props.clearBackupCodes}
            />
            <EnableTfaModal
              open={this.state.openModal === 'enable'}
              onClose={this.closeModals}
              enabled={enabled}
              onEnable={this.onEnable}
            />
            <DisableTfaModal
              open={this.state.openModal === 'disable'}
              onClose={this.closeModals}
              disable={this.disable}
              togglePending={this.props.togglePending}
              toggleError={this.props.toggleError}
              enabled={enabled}
              tfaRequired={this.props.required}
            />
          </Panel>
        </Layout.Section>
      </Layout>
    );
  }
}

const mapStateToProps = ({ account, tfa, tfaBackupCodes }) => ({
  ...tfa,
  statusUnknown: tfa.enabled === null,
  enabled: tfa.enabled === true,
  required: account.tfa_required,
  backupCodes: tfaBackupCodes
});

export default connect(mapStateToProps, { logout, showAlert, ...tfaActions })(TfaManager);
