/* eslint-disable @typescript-eslint/no-unsafe-return */
import { tokens } from '@sparkpost/design-tokens';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import {
  Area,
  AxisDomain,
  Bar,
  BarProps,
  ComposedChart,
  ItemSorter,
  LabelFormatter,
  Line,
  LineProps,
  RechartsFunction,
  Rectangle,
  ResponsiveContainer,
  ScaleType,
  Scatter,
  TickFormatterFunction,
  Tooltip,
  TooltipFormatter,
  TooltipPayload,
  XAxis,
  YAxis
} from 'recharts';
import { DOTTED_LINE_PREFIX_KEY } from 'src/constants';
import { weekDayDictionary } from 'src/pages/alerts/NewDetailsPage/constants';
import AlertIcon from '../icons/AlertIcon';
import MuteIcon from '../icons/MuteIcon';
import Cursor from './Cursor';
import styles from './LineChart.module.scss';

const identity = (a: {}) => a;

const orderDesc: ItemSorter<TooltipPayload> = (a: $TODOFIXME, b: $TODOFIXME) => {
  return b.value - a.value;
};

interface SPLineProps extends LineProps {
  dataKey: number;
}

type SPLineChartProps = {
  data: $TODOFIXME[];
  height: number;
  labelForDottedLine: $TODOFIXME;
  lines: SPLineProps[] | Readonly<SPLineProps[]>;
  operator: string;
  precision: 'day' | '1min' | '15min' | 'month';
  schedule: {
    days: string[];
    timeframe: {
      end: string;
      start: string;
      time_zone: string;
    };
  };
  showLastLineAsDottedLine: boolean;
  showTooltip: boolean;
  showXAxis: boolean;
  syncId: number;
  threshold: number;
  tooltip: React.ElementType;
  tooltipLabelFormatter: LabelFormatter | undefined;
  tooltipValueFormatter: TooltipFormatter | undefined;
  unit: string;
  xAxisKey: string;
  xTickFormatter: TickFormatterFunction | undefined;
  yDomain: readonly [AxisDomain, AxisDomain] | undefined;
  yLabel: string;
  yScale: RechartsFunction | ScaleType | undefined;
  yTickFormatter: TickFormatterFunction | undefined;
};

export default function SpLineChart(props: SPLineChartProps) {
  const formatChartData = (data: $TODOFIXME[]) => {
    const { showLastLineAsDottedLine, lines } = props;
    if (showLastLineAsDottedLine && data.length > 2) {
      lines.forEach((x) => {
        data[data.length - 2][DOTTED_LINE_PREFIX_KEY + x.dataKey] =
          data[data.length - 2][x.dataKey];

        data[data.length - 1][DOTTED_LINE_PREFIX_KEY + x.dataKey] =
          data[data.length - 1][x.dataKey];
        delete data[data.length - 1][x.dataKey];
      });
    }
    return data;
  };

  const renderLines = () => {
    const { lines = [], showLastLineAsDottedLine } = props;

    return lines.map((line: $TODOFIXME) => {
      const lineProps = {
        strokeWidth: 2,
        animationDuration: 400,
        activeDot: false,
        dot: false,
        type: 'linear',
        connectNulls: true,
        ...line
      };
      if (showLastLineAsDottedLine) {
        return [
          <Line {...lineProps} />,
          <Line
            {...lineProps}
            dataKey={DOTTED_LINE_PREFIX_KEY + lineProps.dataKey}
            key={DOTTED_LINE_PREFIX_KEY + lineProps.dataKey}
            strokeDasharray="3 3"
          />
        ];
      }
      return <Line {...lineProps} />;
    });
  };

  // Manually generates X axis ticks
  const getXTicks = () => {
    const { data, precision } = props;
    let ticks;

    // Shows ticks every Sunday
    if (precision === 'day' && data.length > 15) {
      ticks = data.reduce((acc, { ts }) => {
        if (moment(ts).isoWeekday() === 7) {
          acc.push(ts);
        }
        return acc;
      }, []);
    }

    // Show ticks every 15 minutes
    if (precision === '1min') {
      ticks = data.reduce((acc, { ts }) => {
        if (moment(ts).minutes() % 15 === 0) {
          acc.push(ts);
        }
        return acc;
      }, []);
    }

    // Show ticks every 30 minutes
    if (precision === '15min') {
      ticks = data.reduce((acc, { ts }) => {
        if (moment(ts).minutes() % 30 === 0) {
          acc.push(ts);
        }
        return acc;
      }, []);
    }

    return ticks;
  };

  const {
    data,
    height,
    syncId,
    showTooltip,
    xTickFormatter = identity,
    yTickFormatter = identity,
    yScale = 'linear',
    yDomain = ['dataMin', 'dataMin'],
    tooltipLabelFormatter = identity,
    tooltipValueFormatter = identity,
    showXAxis,
    xAxisKey = 'ts',
    yLabel,
    tooltip: CustomTooltip,
    unit,
    labelForDottedLine,
    showLastLineAsDottedLine,
    lines,
    threshold,
    operator,
    schedule
  } = props;

  const xAxisHeight = 30;
  const formattedChartData = formatChartData(data);

  const dataKey = lines[0].dataKey;
  const highestValue = Math.max(...formattedChartData.map((value) => value[dataKey]));

  const chartData = useMemo(() => {
    if (!threshold) return formattedChartData;

    return formattedChartData.map((chartData) => {
      let operation;

      if (operator === 'gt') {
        if (unit === 'percent') {
          operation = chartData[dataKey] > Number(threshold) / 100;
        } else {
          operation = chartData[dataKey] > Number(threshold);
        }
      }

      if (operator === 'lt') {
        if (unit === 'percent') {
          operation = chartData[dataKey] < Number(threshold) / 100;
        } else {
          operation = chartData[dataKey] < Number(threshold);
        }
      }

      if (operation) {
        const alertDate = new Date(chartData.ts);

        const alertTime = `${alertDate.getHours()}:${alertDate.getMinutes()}`;
        const alertDay = alertDate.getDay();

        const isUnmuted =
          !schedule ||
          !schedule.timeframe.end ||
          !schedule.timeframe.start ||
          (alertTime > schedule.timeframe.start && alertTime < schedule.timeframe.end) ||
          schedule.days.includes(weekDayDictionary[alertDay]);

        return {
          ...chartData,
          chart_threshold: highestValue,
          is_chart_threshold_muted: !isUnmuted
        };
      }

      return chartData;
    });
  }, [threshold, formattedChartData, unit, operator, dataKey, schedule, highestValue]);

  const setBarBackground = useCallback(
    (props: BarProps) => {
      const greaterThanThreshold =
        threshold && operator
          ? unit === 'percent'
            ? props[dataKey] * 100 >= threshold
            : props[dataKey] >= threshold
          : false;

      const newProps = {
        ...props,
        fill: greaterThanThreshold ? tokens.color_red_500 : tokens.color_gray_200,
        opacity: greaterThanThreshold ? 0.15 : 1
      };
      return <Rectangle {...newProps} />;
    },
    [dataKey, operator, threshold, unit]
  );

  return (
    <div className={styles.ChartWrapper}>
      <ResponsiveContainer width="99%" height={showXAxis ? height + xAxisHeight : height}>
        <ComposedChart syncId={syncId} barCategoryGap="3%" data={chartData}>
          <Bar key="noKey" dataKey="noKey" background={setBarBackground} />
          <XAxis
            axisLine={false}
            dataKey={xAxisKey}
            height={xAxisHeight}
            hide={!showXAxis}
            interval="preserveStartEnd"
            tickFormatter={xTickFormatter}
            tickLine={false}
            ticks={getXTicks()}
          />
          <YAxis
            axisLine={false}
            domain={yDomain}
            interval="preserveStartEnd"
            padding={{ top: 8, bottom: 8 }}
            scale={yScale}
            tickFormatter={yTickFormatter}
            tickLine={false}
            width={60}
          />
          <Tooltip
            cursor={<Cursor data={chartData} />}
            content={
              CustomTooltip ? (
                <CustomTooltip
                  data={chartData}
                  showTooltip={showTooltip}
                  labelForDottedLine={showLastLineAsDottedLine ? labelForDottedLine : undefined}
                />
              ) : undefined
            }
            wrapperStyle={{ zIndex: tokens.zIndex_overlay }}
            isAnimationActive={false}
            itemSorter={orderDesc}
            labelFormatter={tooltipLabelFormatter}
            formatter={tooltipValueFormatter}
          />
          {/* eslint-disable-next-line local/restrict-translatable-text */}
          {renderLines()}
          {unit === 'percent' && (
            <Area
              dataKey="industry_rate"
              stroke={tokens.color_blue_800}
              fill={tokens.color_blue_800}
              opacity={0.15}
            />
          )}
          <Scatter
            data={chartData}
            dataKey="chart_threshold"
            shape={(props) => {
              if (!props.payload.chart_threshold) return <></>;

              if (props.payload.is_chart_threshold_muted) {
                return <MuteIcon {...props} />;
              }

              return <AlertIcon {...props} />;
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <span className="sp-linechart-yLabel">{yLabel}</span>
    </div>
  );
}
