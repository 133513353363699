import { useEffect } from 'react';
import { AUTH_ROUTE } from 'src/constants';
import { useAuthentication } from 'src/context/AuthenticationContext';
import useDebouncedEffect from 'src/helpers/useDebouncedEffect';

export default function LogoutPage({ history }) {
  const {
    context: {
      logout,
      auth: { loggedIn }
    }
  } = useAuthentication();

  useEffect(() => {
    if (loggedIn) {
      logout();
    }
  }, [loggedIn, logout]);

  useDebouncedEffect({
    effect: () => {
      if (!loggedIn) history.push(AUTH_ROUTE);
    },
    deps: [history, loggedIn, logout],
    delay: 2500
  });

  return null;
}
