import { tokens } from '@sparkpost/design-tokens';
import React from 'react';
import { Rectangle } from 'recharts';
import { ChartPointProp } from './types/charts.types';

type CursorProps = {
  data: $TODOFIXME[];
  height?: number;
  points?: ChartPointProp[];
  width?: number;
};

const Cursor = ({ data, height, points, width: chartWidth }: CursorProps): JSX.Element => {
  const [{ x, y }] = points ? points : [{ x: 0, y: 0 }];
  const sectionWidth = (chartWidth ? chartWidth : 0) / data.length;
  const gap = sectionWidth * 0.03;
  const width = sectionWidth - gap * 2;

  return (
    <Rectangle x={x - width / 2} y={y} height={height} width={width} fill={tokens.color_gray_400} />
  );
};

export default React.memo(Cursor);
