import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import { Page } from 'src/components/matchbox';

import {
  deleteRecipientList,
  getRecipientList,
  updateRecipientList
} from 'src/actions/recipientLists';

import { showAlert } from 'src/actions/globalAlert';
import { DeleteModal, Loading } from 'src/components';
import { PageLink } from 'src/components/links';

import RecipientListForm from './components/RecipientListForm';

export class EditPage extends Component {
  state = {
    showDelete: false
  };

  toggleDelete = () => this.setState({ showDelete: !this.state.showDelete });

  secondaryActions = [
    {
      content: 'Delete',
      onClick: this.toggleDelete
    }
  ];

  deleteRecipientList = () => {
    const { current, deleteRecipientList, showAlert, history } = this.props;

    return deleteRecipientList(encodeURIComponent(current.id)).then(() => {
      showAlert({
        type: 'success',
        message: 'Deleted recipient list'
      });
      history.push('/lists/recipient-lists');
    });
  };

  updateRecipientList = (values) => {
    const { updateRecipientList, showAlert, history } = this.props;

    return updateRecipientList(values).then(() => {
      showAlert({
        type: 'success',
        message: 'Updated recipient list'
      });
      history.push('/lists/recipient-lists');
    });
  };

  componentDidMount() {
    const {
      match: {
        params: { id }
      },
      getRecipientList,
      history
    } = this.props;

    return getRecipientList(encodeURIComponent(id)).catch(() => {
      history.push('/lists/recipient-lists');
    });
  }

  render() {
    const { loading } = this.props;

    if (loading) {
      return <Loading />;
    }

    return (
      <Page
        title="Update Recipient List"
        secondaryActions={this.secondaryActions}
        breadcrumbAction={{
          content: 'Recipient Lists',
          Component: PageLink,
          to: '/lists/recipient-lists'
        }}
      >
        <RecipientListForm editMode={true} onSubmit={this.updateRecipientList} />

        <DeleteModal
          open={this.state.showDelete}
          title="Are you sure you want to delete this recipient list?"
          content={
            <p>
              The recipient list will be immediately and permanently removed. This cannot be undone.
            </p>
          }
          onCancel={this.toggleDelete}
          onDelete={this.deleteRecipientList}
        />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  current: state.recipientLists.current,
  loading: state.recipientLists.currentLoading,
  list: state.recipientLists.list,
  error: state.recipientLists.error
});

const mapDispatchToProps = {
  getRecipientList,
  updateRecipientList,
  deleteRecipientList,
  showAlert
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditPage));
