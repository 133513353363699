import styled from 'styled-components';

export const Widget = styled.div`
  width: 100%;
  max-width: 1200px;
  border: 1px solid rgba(236, 239, 248, 0);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  background-color: white;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Floating = styled.div`
  position: fixed;
  display: flex;
  align-items: end;
  justify-content: start;
  bottom: 20px;
  left: 20px;
  right: 20px;
  z-index: 999;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
`;

export const PrivacyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 810px;
`;
