import { seedList } from 'src/pages';
import App from 'src/components/layout/App';
import { hasGrants, not } from 'src/helpers/conditions';

const DEFAULTS = {
  layout: App,
  supportDocSearch: 'seedlist',
  category: 'Configuration',
  subcategory: 'Seed List'
};

export default [
  {
    path: '/inbox-placement/seedlist', // A redirect from the old path for the feature
    redirect: '/seedlist/manual'
  },
  {
    path: '/seedlist',
    component: seedList.SeedListPage,
    title: 'Seed List Settings',
    ...DEFAULTS
  },
  {
    path: '/seedlist/manual',
    component: seedList.SeedListPage,
    title: 'Manual Seeding | Seed List Settings',
    ...DEFAULTS
  },
  {
    path: '/seedlist/promo',
    condition: not(hasGrants('seeds/view')),
    component: seedList.SeedListPromoPage,
    title: 'Seed List',
    ...DEFAULTS
  },
  {
    path: '/seedlist/automatic',
    component: seedList.SeedListPage,
    condition: hasGrants('seeds/view', 'inline-seeds/read-write'),

    title: 'Automatic Seeding | Seed List Settings',
    ...DEFAULTS
  },
  {
    path: '/seedlist/automatic/config',
    component: seedList.SeedListPage,
    condition: hasGrants('seeds/view', 'inline-seeds/read-write'),

    title: 'Automatic Seeding | Seed List Settings',
    ...DEFAULTS
  },
  {
    path: '/seedlist/automatic/config/list',
    component: seedList.SeedListPage,
    condition: hasGrants('seeds/view', 'inline-seeds/read-write'),
    title: 'Subaccounts Configuration | Automatic Seeding | Seed List Settings',
    ...DEFAULTS
  },
  {
    path: '/seedlist/automatic/config/:subaccountId',
    component: seedList.SeedListPage,
    condition: hasGrants('seeds/view', 'inline-seeds/read-write'),

    title: 'Configuration | Automatic Seeding | Seed List Settings',
    ...DEFAULTS
  },
  {
    path: '/seedlist/automatic/activity/:subaccountId?',
    component: seedList.SeedingActivityPage,
    condition: hasGrants('seeds/view', 'inline-seeds/read-write'),

    title: 'Seeding Activity',
    ...DEFAULTS
  }
];
