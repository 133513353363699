import React from 'react';
import PropTypes from 'prop-types';
import { Columns, Column, Skeleton, Stack } from 'src/components/matchbox';

/**
 * @description Returns columns and rows of skeleton loaders for rendering <table /> loading states
 */
export function TableSkeleton({
  numberOfColumns = 4,
  numberOfRows = 5
}: {
  numberOfColumns: number;
  numberOfRows: number;
}) {
  const iterableColumns = Array.from(new Array(numberOfColumns));
  const iterableRows = Array.from(new Array(numberOfRows));

  return (
    <Stack space="400">
      {numberOfColumns > 0 ? (
        <Columns>
          {iterableColumns.map((_column, index) => {
            return (
              <Column key={`table-skeleton-column-${index}`}>
                <Skeleton.Box data-id="table-skeleton-column" height="600" width="100%" />
              </Column>
            );
          })}
        </Columns>
      ) : null}

      {iterableRows.map((_row, index) => {
        return (
          <Skeleton.Box
            key={`table-skeleton-row-${index}`}
            data-id="table-skeleton-row"
            height="600"
            width="100%"
          />
        );
      })}
    </Stack>
  );
}

TableSkeleton.propTypes = {
  numberOfColumns: PropTypes.number,
  numberOfRows: PropTypes.number
};
