import React from 'react';
import { HeaderGroup, UseSortByColumnProps } from 'react-table';
import styled from 'styled-components';

// COMPONENTS
import { ApiErrorBanner, Empty } from 'src/components';
import { ScreenReaderExclude } from 'src/components/a11y';
import { Box, ScreenReaderOnly, Table as MatchboxTable } from 'src/components/matchbox';
import { SkeletonTable } from 'src/components/SkeletonTable/SkeletonTable';

// TYPES
import { MessageEvents } from 'src/typescript';
import { AcessibleCol, Data, ReactTableInstanceWithHooks } from '../types';

const TableWrapper = styled(Box)`
  overflow: auto;
`;

type TableProps = { error?: MessageEvents['error']; loading: boolean; reload: () => void } & Pick<
  ReactTableInstanceWithHooks,
  'getTableProps' | 'page' | 'getTableBodyProps' | 'headerGroups' | 'prepareRow'
>;

export const Table = ({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  page,
  prepareRow,
  loading,
  error,
  reload
}: TableProps): JSX.Element => {
  if (error) {
    return (
      <Box px="300">
        <ApiErrorBanner
          message="Sorry, we seem to have had some trouble loading your message events."
          errorDetails={error.message}
          reload={reload}
        />
      </Box>
    );
  }

  if (loading)
    return (
      <Box p="300">
        <SkeletonTable columns={5} rows={3} />
      </Box>
    );
  return (
    <TableWrapper>
      <MatchboxTable {...getTableProps()}>
        <ScreenReaderOnly as="thead">
          {headerGroups.map((headerGroup) => {
            const { key: headerRowKey, ...headerRowProps } = headerGroup.getHeaderGroupProps();
            type HeaderCol = AcessibleCol<HeaderGroup<Data>> & UseSortByColumnProps<Data>;
            const headers = headerGroup.headers as HeaderCol[];

            return (
              <MatchboxTable.Row {...headerRowProps} key={headerRowKey}>
                {headers
                  .filter((header) => header.readerScreenExclude === undefined)
                  .map((column) => {
                    const { key: colKey, ...colProps } = column.getHeaderProps();

                    return (
                      <MatchboxTable.HeaderCell {...colProps} key={colKey}>
                        {column.canSort || column.isSorted ? (
                          <MatchboxTable.SortButton
                            direction={
                              column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : undefined
                            }
                            {...column.getSortByToggleProps()}
                          >
                            {column.render('Header')}
                          </MatchboxTable.SortButton>
                        ) : (
                          column.render('Header')
                        )}
                      </MatchboxTable.HeaderCell>
                    );
                  })}
              </MatchboxTable.Row>
            );
          })}
        </ScreenReaderOnly>

        <ScreenReaderExclude as="thead">
          {headerGroups.map((headerGroup) => {
            const { key: headerRowKey, ...headerRowProps } = headerGroup.getHeaderGroupProps();
            type HeaderCol = AcessibleCol<HeaderGroup<Data>> & UseSortByColumnProps<Data>;
            const headers = headerGroup.headers as HeaderCol[];

            return (
              <MatchboxTable.Row {...headerRowProps} key={headerRowKey}>
                {headers
                  .filter((header) => !header.readerScreenIncludeOnly)
                  .map((column) => {
                    const { key: colKey, ...colProps } = column.getHeaderProps();

                    return (
                      <MatchboxTable.HeaderCell {...colProps} key={colKey}>
                        {column.canSort || column.isSorted ? (
                          <MatchboxTable.SortButton
                            direction={
                              column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : undefined
                            }
                            {...column.getSortByToggleProps()}
                          >
                            {column.render('Header')}
                          </MatchboxTable.SortButton>
                        ) : (
                          column.render('Header')
                        )}
                      </MatchboxTable.HeaderCell>
                    );
                  })}
              </MatchboxTable.Row>
            );
          })}
        </ScreenReaderExclude>

        <tbody {...getTableBodyProps()}>
          {page.length === 0 && (
            <MatchboxTable.Row>
              <MatchboxTable.Cell colSpan={5} p="0">
                <Empty message="There are no message events for your current query" />
              </MatchboxTable.Cell>
            </MatchboxTable.Row>
          )}

          {page.map((row) => {
            prepareRow(row);
            const { key: rowKey, ...rowProps } = row.getRowProps();

            return (
              <MatchboxTable.Row {...rowProps} key={rowKey}>
                {row.cells.map((cell) => {
                  const { key: cellKey, ...cellProps } = cell.getCellProps();

                  return (
                    <MatchboxTable.Cell {...cellProps} key={cellKey}>
                      {cell.render('Cell')}
                    </MatchboxTable.Cell>
                  );
                })}
              </MatchboxTable.Row>
            );
          })}
        </tbody>
      </MatchboxTable>
    </TableWrapper>
  );
};
