import { MoreHoriz } from '@sparkpost/matchbox-icons';
import React, { useRef, useState } from 'react';
import { ActionList, Box, Button, Popover, ScreenReaderOnly } from 'src/components/matchbox';
import { PORTALS } from 'src/constants';
import useUniqueId from 'src/hooks/useUniqueId';

type ActionPopoverProps = {
  actions: $TODOFIXME[];
  buttonId: string;
  buttonProps: $TODOFIXME;
  customIcon: $TODOFIXME;
  direction: string;
  display: string;
  popoverProps: $TODOFIXME;
  textAlign:
    | 'center'
    | 'end'
    | 'justify'
    | 'left'
    | 'match-parent'
    | 'right'
    | 'start'
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset';
};

const ActionPopover = ({
  actions,
  display = 'block',
  textAlign = 'right',
  direction = 'left',
  popoverProps,
  buttonProps,
  customIcon: CustomIcon = MoreHoriz,
  buttonId
}: ActionPopoverProps): JSX.Element => {
  const uniqueId = useUniqueId('action-popover');

  /** Made the Popover a controlled component,
   * because of the issue of uncontrolled popover not closing when a Modal is opened
   * TODO - Remove this logic when we have a matchbox prop to handle this - https://github.com/SparkPost/matchbox/issues/920
   * */
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);

  return (
    <Box textAlign={textAlign} display={display}>
      <Popover
        closeOnInsideClick
        as="span"
        portalId={PORTALS.POPOVER.ID}
        id={uniqueId}
        left={direction === 'left'}
        right={direction === 'right'}
        trigger={
          <Button
            aria-controls={uniqueId}
            variant="minimal"
            id={buttonId}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={(event: React.KeyboardEvent<HTMLButtonElement>): void => {
              event.stopPropagation();
              setIsOpen(true);
            }}
            // eslint-disable-next-line react/jsx-no-bind
            onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>): void => {
              if (event.key === 'Enter') {
                event.stopPropagation();
                setIsOpen(true);
              }
            }}
            {...buttonProps}
          >
            <CustomIcon />
            <ScreenReaderOnly>Open Menu</ScreenReaderOnly>
          </Button>
        }
        open={isOpen}
        {...popoverProps}
      >
        <div ref={node}>
          <ActionList>
            {actions.map(({ ...action }) => {
              const hasOnClick = action?.onClick;
              const onClick = function (
                event:
                  | React.MouseEvent<HTMLAnchorElement, MouseEvent>
                  | React.KeyboardEvent<HTMLAnchorElement>
              ): void {
                event.stopPropagation();

                setIsOpen(false);
                if (hasOnClick) {
                  action.onClick();
                }
              };

              const isLink = action.to;
              if (isLink) {
                return (
                  <ActionList.Action
                    key={`action-item-${action.content.toLowerCase()}-${action.id}`}
                    component={action.component}
                    to={action.to}
                    as={action.as}
                    disabled={action.disabled}
                  >
                    {action.content}
                  </ActionList.Action>
                );
              } else {
                return (
                  <ActionList.Action
                    key={`action-item-${action.content.toLowerCase()}`}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={onClick}
                    // eslint-disable-next-line react/jsx-no-bind
                    onKeyDown={(event: React.KeyboardEvent<HTMLAnchorElement>): void => {
                      if (event.key === 'Enter') {
                        onClick(event);
                      }
                    }}
                    component={action.component}
                    disabled={action.disabled}
                  >
                    {action.content}
                  </ActionList.Action>
                );
              }
            })}
          </ActionList>
        </div>
      </Popover>
    </Box>
  );
};

export default ActionPopover;
