export const splitTime = (time: string) => {
  const [hour, minute] = String(time)?.split(':');
  return { hour: Number(hour), minute: minute ?? '00' };
};

export const convertTo24HourFormat = (time: string, period: 'AM' | 'PM') => {
  const { hour, minute } = splitTime(time);
  if (period === 'PM') {
    const hour24Format = hour >= 12 ? time : `${hour + 12}:${minute}`;
    return hour24Format;
  }
  return time;
};

export const convertToAmPmFormat = (time: string) => {
  const { hour, minute } = splitTime(time);
  if (hour >= 12) {
    return [`${hour - 12}:${minute}`, 'PM'];
  }
  return [time, 'AM'];
};

const daysOfWeek = new Map([
  ['Sunday', 0],
  ['Monday', 1],
  ['Tuesday', 2],
  ['Wednesday', 3],
  ['Thursday', 4],
  ['Friday', 5],
  ['Saturday', 6]
]);

export const convertDaysToNumbers = (dayStrings: string[]) =>
  dayStrings.map((day) => daysOfWeek.get(day));

export const convertNumbersToDays = (numbersArray: number[]) => {
  return numbersArray.map((number) => {
    for (const [day, value] of daysOfWeek) {
      if (value === number) {
        return day;
      }
    }
    return 0;
  });
};
