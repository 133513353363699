import React, { useCallback, useEffect, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { subMonths, format } from 'date-fns';
import { toDate, formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import {
  getLocalTimezone,
  getRelativeDates,
  getRelativeDateOptions
} from '@sparkpost/report-builder/helpers/date';
import { getRollupPrecision as getPrecision } from 'src/helpers/metrics';
import {
  ActionList,
  Box,
  Button,
  DatePicker as DateSelector,
  Error,
  Popover,
  TextField,
  WindowEvent
} from 'src/components/matchbox';
import ButtonWrapper from 'src/components/buttonWrapper';
import ManualEntryForm from './ManualEntryFormV2';
import { FORMATS } from 'src/constants';
import styled from 'styled-components';
import { tokens } from '@sparkpost/design-tokens';
import OGDatePicker from './DatePicker';
import { useHibana } from 'src/context/HibanaContext';
import { CalendarToday } from '@sparkpost/matchbox-icons';
import { TranslatableText } from 'src/components/text';
import { Form } from '../tracking';

const pattern = 'yyyy-MM-dd HH:mm:ss.SSS';
const isoDateFormat = 'yyyy-MM-dd';

export function changeDateTimezone(date, timeZone = getLocalTimezone()) {
  const castedDate = new Date(date);

  //Converts date to string agnostic of timezone
  const dateString = format(castedDate, pattern);
  //Adds new timezone to the agnostic date string
  const retDate = toDate(dateString, { timeZone });

  return retDate;
}

const ActionListWrapper = styled.div`
  width: 150px;
  border-right-color: ${tokens.color_gray_400};
  border-right-style: solid;
  border-right-width: ${tokens.borderWidth_100};
`;

const DateSelectorWrapper = styled.div`
  width: max-content;
  max-width: 38.5rem;
`;

const StyledError = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-left: ${tokens.spacing_200};
  line-height: ${tokens.lineHeight_200};
`;

const DATE_FORMAT = FORMATS.DATE_FNS.LONG_DATETIME;

// Date Range Formatter
function formatDateRange({ from, to, dateFormat = DATE_FORMAT, timezone }) {
  if (!from || !to) {
    return 'Invalid Date Range';
  }

  const fromDate = formatInTimeZone(from, timezone, dateFormat);
  const toDate = formatInTimeZone(to, timezone, dateFormat);
  return `${fromDate} – ${toDate}`;
}

const initialState = {
  isDatePickerOpen: false,
  isSelecting: false,
  relativeRange: 'custom',
  selected: {},
  validationError: null,
  selectedPrecision: undefined
};

const actionTypes = {
  open: 'OPEN',
  close: 'CLOSE',
  error: 'SET_ERROR',
  sync: 'SYNC',
  dayClick: 'DAY_CLICK',
  dayHover: 'DAY_HOVER',
  selectRange: 'SELECT_RANGE',
  setFormDates: 'SET_FORM_DATES'
};

const datePickerReducer = (state, { type, payload }) => {
  switch (type) {
    case actionTypes.open: {
      return { ...state, isDatePickerOpen: true };
    }
    case actionTypes.close: {
      return { ...state, isDatePickerOpen: false, isSelecting: false, validationError: null };
    }
    case actionTypes.error: {
      const { validationError } = payload;
      return { ...state, validationError };
    }
    case actionTypes.sync:
      return { ...state, ...payload, isSelecting: false };
    case actionTypes.setFormDates:
      return { ...state, ...payload, relativeRange: 'custom' };
    case actionTypes.selectRange:
      return {
        ...state,
        ...payload,
        isSelecting: payload.relativeRange === 'custom' ? state.isSelecting : false
      };
    case actionTypes.dayClick:
    case actionTypes.dayHover:
      return { ...state, ...payload };
    default: {
      return state;
    }
  }
};

/**
 * @name DatePicker
 * @description Date picker component. Handles optional time ranges and timezones
 * @param {Boolean} shouldChangePrecision -
 * @param {Function} updateShownPrecision -
 * @param
 */

export function DatePicker(props) {
  // TODO: Add consistency on using deconstructed props vs accessing props object directly
  const {
    shouldChangePrecision,
    updateShownPrecision,
    validate,
    now = new Date(),
    relativeDateOptions = [],
    disabled,
    datePickerProps = {},
    textFieldProps = {},
    dateFieldFormat = DATE_FORMAT,
    roundToPrecision,
    preventFuture,
    error,
    left,
    hideManualEntry,
    precision,
    id,
    label,
    timezone = getLocalTimezone(),
    onBlur,
    onChange
  } = props;

  const [state, dispatch] = useReducer(datePickerReducer, initialState);
  const { isSelecting, selected, isDatePickerOpen } = state;

  const syncTimeToState = useCallback(
    ({ from, to, precision, relativeRange }) => {
      if (from && to) {
        const selectedPrecision = shouldChangePrecision && precision;
        dispatch({
          type: actionTypes.sync,
          payload: {
            selected: { from, to },
            selectedPrecision,
            relativeRange
          }
        });
      }
    },
    [shouldChangePrecision]
  );

  useEffect(() => {
    syncTimeToState({
      to: props.to,
      from: props.from,
      precision: props.precision,
      relativeRange: props.relativeRange
    });
  }, [props.to, props.from, props.precision, props.relativeRange, syncTimeToState]);

  // Closes popover on escape, submits on enter
  const handleKeyDown = (e) => {
    if (!isDatePickerOpen) {
      return;
    }
    if (!isSelecting && e.key === 'Enter') {
      handleSubmit();
    }
  };

  // Temporarily update precision for when the date picker is open. Resets after closed
  useEffect(() => {
    if (updateShownPrecision) {
      if (isDatePickerOpen && props.precision !== state.selectedPrecision) {
        return updateShownPrecision(state.selectedPrecision);
      }
      return updateShownPrecision('');
    }
  }, [isDatePickerOpen, updateShownPrecision, state.selectedPrecision, props.precision]);

  const cancelDatePicker = () => {
    syncTimeToState(props);
    dispatch({ type: actionTypes.close });
  };

  // Day picker function
  // clicked is date, 12pm, local time
  function handleDayClick(clicked) {
    const dates = isSelecting
      ? selected //Second day clicked
      : {
          //First Day clicked
          from: fromFormatter(clicked),
          to: toFormatter(clicked)
        };

    const validationError = validate && validate(dates);

    if (isSelecting && validationError) {
      dispatch({ type: actionTypes.error, payload: { validationError } });
      return;
    }

    dispatch({
      type: actionTypes.dayClick,
      payload: {
        relativeRange: 'custom',
        selected: dates,
        beforeSelected: dates,
        isSelecting: !isSelecting,
        validationError: null,
        selectedPrecision:
          shouldChangePrecision &&
          getPrecision({ from: dates.from, to: dates.to, precision: props.precision })
      }
    });
  }

  // Day picker function
  // clicked is date, 12pm, local time
  function handleDayHover(hovered) {
    if (!isSelecting) {
      return;
    }

    const newDateCorrectTimezone = changeDateTimezone(hovered, timezone);

    //True dates (before dates get hovered)
    let { from, to } = state.beforeSelected;

    // Determines if hovered date is temporary new to/from
    if (from.getTime() <= newDateCorrectTimezone.getTime()) {
      //Hovered time is after first selected time (forwards)
      to = toFormatter(hovered);
    } else {
      //Hovered time is before first selected time (backwards)
      from = fromFormatter(hovered);
    }

    const newPrecision = getPrecision({ from, to });
    dispatch({
      type: actionTypes.dayHover,
      payload: { selected: { from, to }, selectedPrecision: newPrecision }
    });
  }

  // Handles selecting preselected date ranges
  const handleSelectRelativeRange = (value) => {
    if (value !== 'custom') {
      const { from, to } = getRelativeDates.useMomentInput(value, { roundToPrecision: false, now });

      const newPrecision = getPrecision({ from, to });

      dispatch({
        type: actionTypes.selectRange,
        payload: {
          relativeRange: value,
          selectedPrecision: newPrecision,
          selected: { from, to }
        }
      });
    } else {
      dispatch({
        type: actionTypes.selectRange,
        payload: {
          relativeRange: value
        }
      });
    }
  };

  const handleFormDates = ({ from, to, precision }, callback) => {
    const selectedPrecision = props.shouldChangePrecision ? precision : undefined;
    dispatch({
      type: actionTypes.setFormDates,
      payload: { selected: { from, to }, selectedPrecision }
    });
    callback();
  };

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();

      const selectedDates = state.selected;
      const validationError = validate && validate(selectedDates);
      if (validationError) {
        dispatch({ type: actionTypes.error, payload: { validationError } });
        return;
      }

      dispatch({ type: actionTypes.close });
      onChange({
        ...selectedDates,
        relativeRange: state.relativeRange,
        precision:
          state.selectedPrecision ||
          getPrecision({ from: moment(selectedDates.from), to: moment(selectedDates.to) })
      });
    },
    [dispatch, state, validate, onChange]
  );

  // Gets start of day, respective to timezone
  // Used by datepicker
  const fromFormatter = (fromDate) => {
    const dateString = format(fromDate, isoDateFormat);

    return toDate(`${dateString}T00:00:00`, { timeZone: timezone });
  };

  // Gets end of day, respective to timezone
  // Needs to preserve date (12/11/2021 local = 12/11/2021 UTC+13) for example
  // Used by datepicker
  const toFormatter = (endDate) => {
    // need to get end of day relative to timezone
    const dateString = format(endDate, isoDateFormat);
    const endOfDay = toDate(`${dateString}T23:59:59.999`, { timeZone: timezone });

    //If future
    if (endOfDay.getTime() > now.getTime()) {
      return now;
    }
    return endOfDay;
  };

  const {
    selected: { from, to },
    validationError,
    selectedPrecision
  } = state;

  // Used only by modifiers to extract the date without the time for calculations
  const plainDateFormat = 'MM dd yyyy';

  const rangeOptions = getRelativeDateOptions(relativeDateOptions).map(({ label, value }) => {
    return {
      children: label,
      highlighted: state.relativeRange === value,
      is: 'button',
      onClick: () => handleSelectRelativeRange(value)
    };
  });

  /**
   * @description Used for date highlighting in react-day-picker
   */
  const modifiers = useMemo(
    () => ({
      firstSelected: (day) => {
        const dateString = format(day, plainDateFormat);
        const newDateString = formatInTimeZone(from, timezone, plainDateFormat);

        // Replaces isSameDay since it breaks with timezones at certain times
        return dateString === newDateString;
      },
      lastSelected: (day) => {
        const dateString = format(day, plainDateFormat);
        const newDateString = formatInTimeZone(to, timezone, plainDateFormat);

        // Replaces isSameDay since it breaks with timezones at certain times
        return dateString === newDateString;
      },
      inBetween: (day) => {
        const DATE_FORMAT = 'yyyyMMdd';
        const localizedFrom = Number(formatInTimeZone(from, timezone, DATE_FORMAT));
        const localizedDay = Number(format(day, DATE_FORMAT));
        const localizedTo = Number(formatInTimeZone(to, timezone, DATE_FORMAT));

        const condition = localizedDay > localizedFrom && localizedDay < localizedTo;

        return condition;
      }
    }),
    [from, to, timezone]
  );

  return (
    <Popover
      id={`popover-${id}`}
      as="div"
      trigger={
        <TextField
          data-id={`date-field-${id}`}
          label={label}
          id={`date-field-${id}`}
          onClick={() => dispatch({ type: actionTypes.open })}
          prefix={<CalendarToday />}
          value={formatDateRange({ from, to, dateFormat: dateFieldFormat, timezone })}
          readOnly
          onBlur={onBlur}
          error={error}
          disabled={disabled}
          type="text"
          {...textFieldProps}
        />
      }
      onClose={cancelDatePicker}
      open={isDatePickerOpen}
      left={left}
    >
      <Form onSubmit={handleSubmit} id={id}>
        <Box display="flex">
          <ActionListWrapper>
            <ActionList>
              {rangeOptions.map(({ children, ...option }, i) => {
                return (
                  <ActionList.Action key={`action-item-${i}`} {...option}>
                    <TranslatableText>{children}</TranslatableText>
                  </ActionList.Action>
                );
              })}
            </ActionList>
          </ActionListWrapper>
          <Box as={DateSelectorWrapper} padding="400">
            <DateSelector
              data-id="date-selector"
              modifiers={modifiers}
              fixedWeeks
              initialMonth={subMonths(utcToZonedTime(now, timezone), 1)}
              toMonth={utcToZonedTime(now, timezone)}
              disabledDays={{ after: utcToZonedTime(now, timezone) }}
              onDayClick={handleDayClick}
              onDayMouseEnter={handleDayHover}
              onDayFocus={handleDayHover}
              onKeyDown={handleKeyDown}
              onDayKeyDown={handleKeyDown}
              // TODO: Prop is unavailable until the datepicker is updated. Edge case of date being different in different timezone
              // today={utcToZonedTime(now, timezone)}
              {...datePickerProps}
            />
            {!hideManualEntry && (
              <ManualEntryForm
                data-id="manual-entry-form"
                selectDates={handleFormDates}
                onEnter={handleKeyDown}
                to={to}
                from={from}
                timezone={timezone}
                roundToPrecision={roundToPrecision}
                preventFuture={preventFuture}
                selectedPrecision={selectedPrecision}
                defaultPrecision={shouldChangePrecision && precision}
              />
            )}
          </Box>
        </Box>
        <Box padding="400" borderTop="400">
          <ButtonWrapper>
            <Button variant="primary" type="submit" data-id="date-picker-custom-apply">
              Apply
            </Button>

            <Button variant="secondary" onClick={cancelDatePicker}>
              Cancel
            </Button>
          </ButtonWrapper>
        </Box>
        {validationError && (
          <StyledError>
            <Error wrapper="span" error={validationError}></Error>
          </StyledError>
        )}
        <WindowEvent event="keydown" handler={handleKeyDown} />
      </Form>
    </Popover>
  );
}

export default (props) => {
  const [state] = useHibana();
  const { isHibanaEnabled } = state;
  return isHibanaEnabled ? <DatePicker {...props} /> : <OGDatePicker {...props} />;
};

DatePicker.propTypes = {
  now: PropTypes.instanceOf(Date),
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  relativeRange: PropTypes.string,
  relativeDateOptions: PropTypes.arrayOf(PropTypes.string),
  roundToPrecision: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  datePickerProps: PropTypes.object,
  dateFieldFormat: PropTypes.string,
  disabled: PropTypes.bool,
  hideManualEntry: PropTypes.bool,
  selectPrecision: PropTypes.bool,
  precision: PropTypes.string,
  id: PropTypes.string,
  shouldChangePrecision: PropTypes.bool,
  updateShownPrecision: PropTypes.func,
  validate: PropTypes.func,
  textFieldProps: PropTypes.object,
  preventFuture: PropTypes.bool,
  error: PropTypes.string,
  left: PropTypes.any,
  label: PropTypes.string,
  timezone: PropTypes.string,
  onBlur: PropTypes.func
};

DatePicker.defaultProps = {
  preventFuture: true,
  roundToPrecision: false,
  id: 'date-picker'
};
