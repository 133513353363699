import React, { useCallback } from 'react';

import { Box, Select, Stack, Text, TextField } from 'src/components/matchbox';
import { Heading } from 'src/components/text';
import { useAlertForm } from '../useAlertForm';

export default function Details() {
  const {
    state: { accountData, alertData },
    dispatch
  } = useAlertForm();

  const handleAlertNameChange = useCallback(
    (e) => {
      dispatch({ type: 'UPDATE_ALERT_DATA', data: { name: e.target.value } });
    },
    [dispatch]
  );

  const handleSeverityChange = useCallback(
    (e) => {
      dispatch({ type: 'UPDATE_ALERT_DATA', data: { severity: e.target.value } });
    },
    [dispatch]
  );

  return (
    <Stack>
      <Heading as="h4">Details</Heading>
      <Box display="flex">
        <Box mr="20px" width="60%" maxWidth="400px">
          <TextField
            id="name"
            label="Alert Name"
            placeholder="e.g. Block Rate > 20%"
            required
            value={alertData.name}
            onChange={handleAlertNameChange}
            error={alertData.errors.name && 'Required'}
          />
        </Box>
        <Box>
          <Select
            label="Severity Level"
            id="severity"
            options={[
              {
                value: undefined,
                label: 'Select',
                disabled: !!alertData.severity
              },
              {
                value: 'high',
                label: 'High'
              },
              {
                value: 'medium',
                label: 'Medium'
              },
              {
                value: 'low',
                label: 'Low'
              }
            ]}
            value={alertData.severity}
            onChange={handleSeverityChange}
            error={alertData.errors.severity && 'Required'}
          />
        </Box>
      </Box>
      <Box>
        <Text as="h5">Subaccount Assignment</Text>
        <Text>
          {accountData.assignment === 'subaccount' && accountData.subaccount.value !== undefined
            ? accountData.subaccount.value
            : 'Primary Account'}
        </Text>
      </Box>
    </Stack>
  );
}
