import React from 'react';
import { Tag as MatchboxTag, TagProps } from '@sparkpost/matchbox';

/**
 * Matchbox Tag component.
 * @see https://design.sparkpost.com/components/tag/
 */
export default function Tag(props: TagProps): JSX.Element {
  return <MatchboxTag {...props} />;
}
