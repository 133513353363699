import React, { useCallback, useState, useMemo } from 'react';
import _ from 'lodash';
import { Panel, Table } from 'src/components/matchbox';
import Collection from './Collection';
import TableHeader from './TableHeader';
import styles from './TableCollection.module.scss';

import useHibanaToggle from 'src/hooks/useHibanaToggle';

const OGTableWrapper = (props) => (
  <Panel.LEGACY mb="500">
    <div className={styles.TableWrapper}>
      <Table>{props.children}</Table>
    </div>
  </Panel.LEGACY>
);

const HibanaTableWrapper = ({ children, hasBorder = true }) => {
  return (
    <Panel.LEGACY mb="500" {...(!hasBorder ? { border: '0' } : {})}>
      <div>
        <Table>{children}</Table>
      </div>
    </Panel.LEGACY>
  );
};

const TableWrapper = (props) => {
  return useHibanaToggle(OGTableWrapper, HibanaTableWrapper)(props);
};

const TableBody = (props) => <tbody>{props.children}</tbody>;

/**
 *
 * @deprecated use react-table instead.
 * @see https://github.com/SparkPost/2web2ui/blob/bf84dcae570f01ab2f9298d3869829fca6186d7a/src/pages/webhooks/ListPage.js
 */
const TableCollection = (props) => {
  const { defaultSortColumn = null, defaultSortDirection = 'asc' } = props;

  const [state, setState] = useState({
    sortColumn: defaultSortColumn,
    sortDirection: defaultSortDirection
  });

  const handleSortChange = useCallback(
    (sortColumn, sortDirection) => {
      setState({
        sortColumn,
        sortDirection
      });
    },
    [setState]
  );

  const {
    rowComponent,
    wrapperComponent,
    headerComponent,
    columns,
    getRowData,
    rows,
    title,
    sortFn = _.orderBy
  } = props;

  const WrapperComponent = wrapperComponent ? wrapperComponent : TableWrapper;

  const HeaderComponent = useMemo(() => {
    return headerComponent
      ? headerComponent
      : () => (
          <TableHeader
            columns={columns}
            onSort={handleSortChange}
            sortColumn={state.sortColumn}
            sortDirection={state.sortDirection}
          />
        );
  }, [columns, handleSortChange, headerComponent, state]);

  const TableRow = useMemo(() => {
    return rowComponent ? rowComponent : (props) => <Table.Row rowData={getRowData(props)} />;
  }, [getRowData, rowComponent]);

  const sortedRows = useMemo(() => {
    return state.sortColumn ? sortFn(rows, state.sortColumn, state.sortDirection) : rows;
  }, [rows, state, sortFn]);

  return (
    <Collection
      outerWrapper={WrapperComponent}
      headerComponent={HeaderComponent}
      bodyWrapper={TableBody}
      rowComponent={TableRow}
      {...props}
      rows={sortedRows}
      sortColumn={state.sortColumn}
      sortDirection={state.sortDirection}
      title={title}
    />
  );
};

export default TableCollection;
