import { formatBytes } from '@sparkpost/report-builder/helpers/units';
import React from 'react';
import { TranslatableText } from '../text';

// Formats bytes into a readable size value
const Size = ({ value }: { value: number }): JSX.Element => (
  <TranslatableText>{formatBytes(value)}</TranslatableText>
);
Size.displayName = 'Size';

export { Size };
