/**
 * @description Returns "friendly" formatted number, e.g. 1,000,000 in the US or 1.000.000 in Europe
 * @param {number} number - the passed-in number
 * @returns {string} friendly-formatted string, e.g. 1000 => '1,000'
 */
export function formatToLocaleString(number) {
  if (typeof number !== 'number')
    throw new Error(
      `'formatToLocaleString' only accepts numbers. Received '${typeof number}' instead.`
    );

  return new Intl.NumberFormat().format(number);
}

/**
 * @description Parse a localized number to a float.
 * @link Borrowed from https://stackoverflow.com/questions/29255843/is-there-a-way-to-reverse-the-formatting-by-intl-numberformat-in-javascript
 * @param {string} stringNumber - the localized number
 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
 * @returns {number}
 */
export function parseLocaleString(stringNumber, locale) {
  if (typeof stringNumber !== 'string')
    throw new Error(
      `'parseLocaleString' only accepts strings. Received '${typeof stringNumber}' instead.`
    );

  const thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, '');
  const decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, '');

  return parseFloat(
    stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.')
  );
}

export function getNumbers(arr) {
  return arr.map((num) => typeof num === 'number' && !isNaN(num) && num).filter(Boolean);
}

export function sumNumbers(arr) {
  return getNumbers(arr).reduce((acc, item) => (acc += item), 0);
}

export function sumTwoNumbers(one, two) {
  return sumNumbers([one, two]);
}
