import { useMemo } from 'react';
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';

import { useSparkPostQuery, useSubaccounts } from 'src/hooks';
import { useIpPools } from '../../../hooks';
import { getMonitors } from 'src/helpers/api/blocklist';

import { COLUMN_HEADERS, DEFAULT_SORT_BY } from '../constants';

export function useMonitorsCollection() {
  const query = useSparkPostQuery(getMonitors);

  const { getIPPool } = useIpPools();
  const { getSubaccountName } = useSubaccounts();

  const data = useMemo(() => {
    if (!Boolean(query.data)) {
      return [];
    }
    return query.data.map((row) => {
      const { resource, subaccount_id } = row;
      let assignmentValue;
      let assignmentLabel;
      const ipPoolAssignment = getIPPool(resource);
      const subaccountAssignment = getSubaccountName(subaccount_id);

      if (ipPoolAssignment) {
        assignmentLabel = 'IP Pool';
        assignmentValue = ipPoolAssignment;
      } else if (subaccountAssignment !== null) {
        assignmentLabel = 'Subaccount';
        assignmentValue = subaccountAssignment;
      } else {
        return row;
      }

      const assignment = `${assignmentLabel}: ${assignmentValue}`;
      return { ...row, assignment };
    });
  }, [query.data, getIPPool, getSubaccountName]);

  const table = useTable(
    {
      columns: COLUMN_HEADERS,
      data,
      initialState: {
        pageSize: 10,
        pageIndex: 0,
        sortBy: DEFAULT_SORT_BY
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  return { query, table };
}
