import React from 'react';
import { useSparkPostQuery } from 'src/hooks';
import { useSelector } from 'react-redux';
import { LINKS } from 'src/constants';
import { PageLink, SubduedLink, ExternalLink } from 'src/components/links';
import { Stack } from 'src/components/matchbox';
import { Settings } from '@sparkpost/matchbox-icons';
import { hasGrants } from 'src/helpers/conditions';
import { getIpPool } from 'src/helpers/api/ipPools';

export default function ({ id }) {
  // Note: Identical grants as in src/appConfig/routes/index for canManageIpPools
  const canManageIpPools = useSelector((state) => hasGrants('ip_pools/manage')(state));

  const { status } = useSparkPostQuery(() => getIpPool(id), {
    retry: false
  });

  return (
    <>
      <Stack space="200">
        {status !== 'error' && canManageIpPools && (
          <PageLink to={`/account/ip-pools/edit/${id}`} fontSize="200">
            <span>IP Pool Settings&nbsp;</span>
            <Settings />
          </PageLink>
        )}
        <SubduedLink as={ExternalLink} to={LINKS.IP_POOL_DOCS} fontSize="200">
          Learn more about IP Pools
        </SubduedLink>
      </Stack>
    </>
  );
}
