import React from 'react';
import { Box } from 'src/components/matchbox';
import { ExternalLink } from 'src/components/links';
import { Rocket } from '@sparkpost/matchbox-icons';
import useResourceDetailsContext from '../../hooks/useResourceDetailsContext';

export default function CampaignIdSubTitle() {
  const { match: { params: { type } = {} } = {}, hasD12yProduct } = useResourceDetailsContext();
  if (!hasD12yProduct && type === 'campaign')
    return (
      <ExternalLink to="https://www.sparkpost.com/features/email-deliverability/" showIcon={false}>
        Upgrade for Deliverability Insights
        <Box color="brand.orange">
          <Rocket />
        </Box>
      </ExternalLink>
    );

  return null;
}
