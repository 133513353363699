import { tokens } from '@sparkpost/design-tokens';
import _ from 'lodash';
import React, { useState } from 'react';
import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  Text,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis
} from 'recharts';
import { ScreenReaderExclude, ScreenReaderOnlyTable } from 'src/components/a11y';
import { WindowEvent } from 'src/components/matchbox';
import { TranslatableText } from 'src/components/text';
import styled from 'styled-components';
import YAxisTooltip from './HorizontalBarTooltip';

const YAxisLeftText = styled(Text)`
  fill: #626f7a;
`;

const CustomTooltip = ({ active, payload }) => {
  const tooltip = payload[0]?.payload?.tooltip;

  if (active && tooltip && tooltip.length) {
    return <YAxisTooltip>{tooltip}</YAxisTooltip>;
  }

  return null;
};

const YAxisLeftTick = ({ y, payload: { value } }) => {
  // {/* TODO: Fix scaleToFit error: <text> attribute transform: Expected number, "scale(NaN)". */}
  return (
    <YAxisLeftText x={0} y={y} textAnchor="start" verticalAnchor="middle" scaleToFit>
      {value}
    </YAxisLeftText>
  );
};

const YAxisRightLabel = (props) => {
  const { x, y, width, height, value } = props;

  if (!value && value !== 0) {
    return;
  }

  const fireOffset = value > 90;
  const offset = fireOffset ? 5 : -65;
  const formattedValue = `${value}%`;

  return (
    <Text
      x={x + width - offset}
      y={y + height / 2}
      textAnchor="end"
      verticalAnchor="middle"
      fill={fireOffset ? tokens.color_white : tokens.color_gray_1000}
    >
      {formattedValue}
    </Text>
  );
};

const YAxisRightLabelSmall = (props) => {
  const { x, y, width, height, value } = props;

  if (!value && value !== 0) {
    return;
  }

  const fireOffset = value <= 50;
  const offset = fireOffset ? -65 : 5;
  const formattedValue = `${value}%`;

  return (
    <Text
      x={x + width - offset}
      y={y + height / 2}
      textAnchor="end"
      verticalAnchor="middle"
      fill={fireOffset ? tokens.color_gray_1000 : tokens.color_white}
    >
      <TranslatableText>{formattedValue}</TranslatableText>
    </Text>
  );
};

const HorizontalBar = ({ data, xKey, yKey, labelListKey, barSize = 25, caption }) => {
  const [isWindowSizeSmall, setWindowSizeSmall] = useState(window.innerWidth <= 720);
  const updateSize = () => {
    setWindowSizeSmall(window.innerWidth < 720);
  };

  const debouncedUpdateSize = _.debounce(updateSize, 300);

  return (
    <>
      <WindowEvent event="resize" handler={debouncedUpdateSize} />
      <ScreenReaderExclude>
        <ResponsiveContainer height={data.length * barSize * 2} width="99%">
          <BarChart data={data} layout="vertical">
            <XAxis type="number" hide domain={[0, 1]} />
            <YAxis
              type="category"
              tickLine={false}
              axisLine={false}
              dataKey={yKey}
              width={200}
              tick={YAxisLeftTick}
              orientation="left"
            />
            <RechartsTooltip width={200} content={<CustomTooltip />} />
            <Bar
              background
              dataKey={xKey}
              fill="#1273e6"
              barSize={barSize}
              isAnimationActive={false}
            >
              {isWindowSizeSmall ? (
                <LabelList dataKey={labelListKey} content={YAxisRightLabelSmall} />
              ) : (
                <LabelList dataKey={labelListKey} content={YAxisRightLabel} />
              )}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </ScreenReaderExclude>
      <BarChartScreenReaderOnlyTable data={data} xKey={xKey} yKey={yKey} caption={caption} />
    </>
  );
};

export function BarChartScreenReaderOnlyTable({ caption = 'Bar Chart Data', data, xKey, yKey }) {
  const getHeaders = () => {
    return data.map((x) => x[yKey]);
  };
  return (
    <ScreenReaderOnlyTable caption={caption}>
      <ScreenReaderOnlyTable.Head headers={getHeaders()} />
      <tbody>
        <tr>
          {data.map((x, index) => {
            return (
              <td key={index}>
                <TranslatableText>
                  {x[yKey]} is {x[xKey]}
                </TranslatableText>
              </td>
            );
          })}
        </tr>
      </tbody>
    </ScreenReaderOnlyTable>
  );
}

export default HorizontalBar;
