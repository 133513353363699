import { Search } from '@sparkpost/matchbox-icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CollectionPagination from 'src/components/collection/Pagination';
import { Box, Column, Columns, Panel, Table, TextField } from 'src/components/matchbox';
import MultiCheckboxDropdown, { useMultiCheckbox } from 'src/components/MultiCheckboxDropdown';
import TableHeaderCell from './TableHeaderCell';

const WEBHOOK_STATUS_CHECKBOXES = [
  {
    label: 'All',
    name: 'selectAll'
  },
  {
    label: 'Success',
    name: 'Success'
  },
  {
    label: 'Failure',
    name: 'Failure'
  },
  {
    label: 'Disabled',
    name: 'Disabled'
  }
];

export default function Collection({
  headerGroups,
  getTableProps,
  getTableBodyProps,
  page,
  prepareRow,
  setFilter,
  rows,
  state,
  gotoPage,
  setPageSize
}) {
  const [webhookQuery, setWebhookQuery] = useState('');

  const originalRows = useMemo(() => rows.map((row) => row.original), [rows]);

  const multiCheckbox = useMultiCheckbox({
    checkboxes: WEBHOOK_STATUS_CHECKBOXES,
    allowSelectAll: false,
    onChange: (values) => {
      if (values.length === WEBHOOK_STATUS_CHECKBOXES.length) {
        return setFilter('last_status', undefined);
      }

      setFilter('last_status', values);
    }
  });

  useEffect(() => {
    setFilter('name', webhookQuery);
  }, [webhookQuery, setFilter]);

  const handleSearch = useCallback((e) => {
    setWebhookQuery(e.target.value);
  }, []);

  return (
    <>
      <Panel>
        <Panel.Section>
          <Columns>
            <Column width={0.55}>
              <TextField
                id="filter-alert"
                maxWidth="inherit"
                label="Filter By"
                prefix={<Search />}
                onChange={handleSearch}
                value={webhookQuery}
                placeholder="e.g. Webhook_001"
              />
            </Column>
            <Column width={0.2}>
              <MultiCheckboxDropdown
                allowEmpty={false}
                checkboxes={multiCheckbox.checkboxes}
                id="group-by-dropdown"
                label="Webhook Status"
                screenReaderDirections="Filter the table by the selected checkboxes"
              />
            </Column>
          </Columns>
        </Panel.Section>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                <Table.Row header {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <TableHeaderCell
                        column={column}
                        key={`headerGroup-headers-column-${column.id}`}
                      />
                    );
                  })}
                </Table.Row>
              );
            })}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Table.Row {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Table.Cell width={`${100 / row.cells.length}%`} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
          </tbody>
        </Table>
      </Panel>
      <Box mt="500">
        <CollectionPagination
          data={originalRows}
          perPage={state.pageSize}
          currentPage={state.pageIndex + 1}
          onPageChange={gotoPage}
          onPerPageChange={setPageSize}
        />
      </Box>
    </>
  );
}
