import React, { useCallback } from 'react';
import { Control, RegisterOptions, useController } from 'react-hook-form';
import { Typeahead } from 'src/components/typeahead';

type TypeaheadTypes = {
  helpText?: string;
  id: string;
  itemToString: (item: $TODOFIXME) => string;
  label?: string;
  renderItem: (item: $TODOFIXME) => $TODOFIXME;
};

type TypeaheadControllerProps = TypeaheadTypes & {
  control: Control<$TODOFIXME, $TODOFIXME>;
  defaultValue?: string;
  disabled?: boolean;
  name: string;
  onChange?: (value: $TODOFIXME) => void;
  results: $TODOFIXME[];
  rules?: Omit<
    RegisterOptions<$TODOFIXME, $TODOFIXME>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
};

export function TypeaheadController({
  control,
  name,
  rules = {},
  defaultValue = '',
  onChange,
  ...typeaheadProps
}: TypeaheadControllerProps) {
  const {
    field: { ref, value, ...inputProps }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  const onChangeWrapper = useCallback(
    (...params: [$TODOFIXME]) => {
      onChange && onChange(...params);

      return inputProps.onChange(...params);
    },
    [inputProps, onChange]
  );

  return (
    <Typeahead
      {...inputProps}
      {...typeaheadProps}
      selectedItem={value}
      ref={ref}
      data-track={true}
      onChange={onChangeWrapper}
    />
  );
}
