import React from 'react';
import ReactDOM from 'react-dom';
// Note: styles must be imported before the App and Providers
// eslint-disable-next-line local/no-css-module-import
import 'src/index.scss';
import { createGlobalStyle } from 'styled-components';
import App from './App';
import config from './appConfig';
import ErrorTracker from './helpers/errorTracker';
import Providers from './Providers';
import configureStore from './store';

// https://github.com/webpack/webpack-dev-server/issues/2969
const GlobalStyle = createGlobalStyle`
iframe {
  z-index: -1 !important;
}
`;

declare global {
  interface Window {
    SPARKPOST_LOADED: boolean;
    rudderanalytics: rudderanalyticsProps;
  }
}

export const defaultStore = configureStore();

const renderApp = () => {
  ReactDOM.render(
    <Providers store={defaultStore}>
      {process.env.NODE_ENV === 'development' && <GlobalStyle />}
      <App />
    </Providers>,
    document.getElementById('root')
  );
};

ErrorTracker.install(config, defaultStore);
try {
  renderApp();
} catch (e) {
  //report error
  ErrorTracker.report('render-app', e);
}

window.SPARKPOST_LOADED = true; // Indicates the app bundle has loaded successfully

// Hot module replacement
if (process.env.NODE_ENV !== 'production') {
  import('@axe-core/react')
    .then((axe) => {
      const numberOfMillisecondsToDelayBetweenScans = 60_000;
      return axe.default(React, ReactDOM, numberOfMillisecondsToDelayBetweenScans);
    })
    .catch(() => {
      // shh
    });

  if (module.hot) {
    module.hot.accept('./App', () => {
      renderApp();
    });
  }
}
