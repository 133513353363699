import React from 'react';
import { Rocket } from '@sparkpost/matchbox-icons';
import { Box, Inline, Checkbox, UnstyledLink } from 'src/components/matchbox';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: flex;
`;

const Left = styled(Box)`
  flex: 1;
`;

const Right = styled(Inline)`
  flex: 0;
`;

/**
 * Each individual checkbox row
 */
const CheckboxWithUpgradeLink = ({ showLink, link, disabled, external, checkboxProps }) => {
  return (
    <CheckboxContainer>
      <Left>
        <Checkbox {...checkboxProps} disabled={disabled} />
      </Left>
      {showLink && (
        <Right space="100">
          <UnstyledLink title="Upgrade" to={link} external={external}>
            Upgrade
          </UnstyledLink>
          <Box color="brand.orange">
            <Rocket />
          </Box>
        </Right>
      )}
    </CheckboxContainer>
  );
};

export default CheckboxWithUpgradeLink;
