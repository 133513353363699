export const dataSourceCheckboxes = {
  checkboxes: [
    { name: 'panel', label: 'Panel' },
    { name: 'seed', label: 'Seed List' }
  ],
  allowSelectAll: false,
  allowEmpty: false
};

export const dataSourceCheckboxesForSubjectCampaign = {
  checkboxes: [
    { name: 'panel', label: 'Panel' },
    { name: 'seed', label: 'Seed List' }
  ],
  allowSelectAll: false,
  allowEmpty: false
};
