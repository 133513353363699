import React, { useCallback } from 'react';

import { Box, RadioCard, Stack, Text } from 'src/components/matchbox';
import { Heading } from 'src/components/text';
import { useAlertForm } from '../useAlertForm';
import GroupByOption from 'src/pages/analyticsReport/components/GroupByTable/GroupByOption';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import { METRICS_BREAKDOWN_TYPES } from '../constants';

export default function Data() {
  const {
    state: {
      measurementData: { measurementType }
    },
    dispatch
  } = useAlertForm();
  const {
    state: { groupBy, groupByError },
    actions: { setGroupBy }
  } = useAnalyticsReportContext();

  const handleMeasurementTypeChange = useCallback(
    (e) => {
      dispatch({ type: 'UPDATE_MEASUREMENT_DATA', data: { measurementType: e.target.id } });
    },
    [dispatch]
  );

  return (
    <Stack>
      <Heading as="h4">Data Measurement</Heading>
      <Box>
        <Text as="span">
          Monitor a metric by its total value or by a specific property. Properties include
          Recipient Domain, Sending IP, IP Pool, Campaign (ID), Mailbox Provider, Mailbox Provider
          Region, Template, Sending Domain, and Subaccount.
        </Text>
      </Box>

      <Box>
        <RadioCard.Group orientation="horizontal" label="Breakdown">
          <RadioCard
            label="No Breakdown"
            id={METRICS_BREAKDOWN_TYPES.byValue}
            checked={measurementType === METRICS_BREAKDOWN_TYPES.byValue}
            name="type"
            onChange={handleMeasurementTypeChange}
          >
            The alert is triggered if the total value of the metric meets the threshold.
          </RadioCard>
          <RadioCard
            label="Breakdown by Property"
            id={METRICS_BREAKDOWN_TYPES.breakdownByProperty}
            checked={measurementType === METRICS_BREAKDOWN_TYPES.breakdownByProperty}
            name="type"
            onChange={handleMeasurementTypeChange}
          >
            The alert is triggered if any one item in a specified property (i.e. subaccount, mailbox
            provider, campaign_id, etc.) meets the threshold.
          </RadioCard>
        </RadioCard.Group>
      </Box>
      {measurementType === METRICS_BREAKDOWN_TYPES.breakdownByProperty && (
        <Box>
          <GroupByOption
            groupBy={groupBy}
            hasSubaccounts
            onChange={setGroupBy}
            error={groupByError && 'Required'}
          />
        </Box>
      )}
    </Stack>
  );
}
