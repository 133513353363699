import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Panel, Spinner } from 'src/components/matchbox';

const StyledPanelSection = styled.div<{ minHeight: string }>`
  min-height: ${(props) => props.minHeight};
  position: 'relative';
`;

const PanelSectionLoading = ({ minHeight = '400px' }: { minHeight: string }) => {
  return (
    <StyledPanelSection
      as={Panel.LEGACY.Section}
      data-id="panel-section-loading"
      minHeight={minHeight}
    >
      <Box minHeight={minHeight} display="flex" alignItems="center" justifyContent="center">
        <Spinner label="panel-section-loading" />
      </Box>
    </StyledPanelSection>
  );
};

PanelSectionLoading.propTypes = {
  minHeight: PropTypes.string
};

export default PanelSectionLoading;
