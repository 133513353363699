import { Progress as MatchboxProgress } from '@sparkpost/matchbox';

/**
 * Matchbox Progress component
 * @see https://design.sparkpost.com/components/progress/
 */
const Progress = MatchboxProgress;

/**
 * Matchbox Progress component
 * @see https://design.sparkpost.com/components/progress/
 */
const Step = MatchboxProgress.Step;

Progress.displayName = 'Progress';
Step.displayName = 'Progress.Step';

Progress.Step = Step;

export default Progress;
