import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'src/components/matchbox';
import styles from './ActiveLabel.module.scss';

function ActiveLabel({ name, value }) {
  return (
    <Box className={styles.ActiveLabel}>
      <Text as="div" mb="200" fontSize="400" color="gray.700">
        {name}
      </Text>

      <Text as="div" fontSize="400" color="gray.900" fontWeight="semibold">
        {value}
      </Text>
    </Box>
  );
}

ActiveLabel.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ActiveLabel.displayName = 'PieChart.ActiveLabel';

export default ActiveLabel;
