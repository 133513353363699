import React from 'react';
import { Empty } from 'src/components';
import { LabelValueLoadingSkeleton } from 'src/components/loading';
import { Column, Columns, LabelValue, Panel } from 'src/components/matchbox';
import { getIpPool } from 'src/helpers/api/ipPools';
import { useSparkPostQuery } from 'src/hooks';
import DataSources from './DataSources';

export default function IpPool({
  hasD12yProduct,
  id,
  deliverabilityData,
  deliverabilityDataStatus
} = {}) {
  const loadingLabelValues = [
    { width: 1 / 6, value: 'IP Pool' },
    { width: 1 / 6, value: 'Sent' },
    hasD12yProduct && { width: 1 / 6, value: 'Seed Count' },
    hasD12yProduct && { width: 1 / 6, value: 'Panel Count' }
  ];

  const { status, data } = useSparkPostQuery(() => getIpPool(id), {
    retry: false
  });

  if (deliverabilityDataStatus === 'loading' || status === 'loading') {
    return (
      <Panel.Section>
        <LabelValueLoadingSkeleton labelValues={loadingLabelValues} />
      </Panel.Section>
    );
  }

  if (deliverabilityDataStatus === 'error' || status === 'error') {
    return <Empty message="Failed to load the data. Refresh page."></Empty>;
  }

  return (
    <Panel.Section>
      <Columns>
        <Column width={1 / 6}>
          <LabelValue>
            <LabelValue.Label>IP Pool ID</LabelValue.Label>
            <LabelValue.Value>{data.id}</LabelValue.Value>
          </LabelValue>
        </Column>
        <Column width={1 / 6}>
          <LabelValue>
            <LabelValue.Label>IP Pool Name</LabelValue.Label>
            <LabelValue.Value>{data.name}</LabelValue.Value>
          </LabelValue>
        </Column>

        <DataSources
          dataDisplayOnly={true}
          hasD12yProduct={hasD12yProduct}
          deliverabilityData={deliverabilityData}
          deliverabilityDataStatus={deliverabilityDataStatus}
        />
      </Columns>
    </Panel.Section>
  );
}
