import React, { useLayoutEffect, useRef, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { formatDate } from '@sparkpost/report-builder/helpers/date';
import { getBoundingClientRect } from 'src/helpers/geometry';
import styles from './Tooltip.module.scss';
import './Tooltip.scss';

interface TooltipProps {
  active?: boolean;
  animationDuration?: number;
  animationEasing?: string;
  children: (content: JSX.Element) => JSX.Element;
  contentStyle?: {};
  coordinate: { x: number; y: number };
  cursor?: boolean;
  cursorStyle?: {};
  filterNull?: boolean;
  isAnimationActive?: boolean;
  itemSorter?: () => void;
  itemStyle?: {};
  label?: string;
  labelStyle?: {};
  offset: number;
  payload: [];
  position?: { x: number; y: number };
  separator?: string;
  useTranslate3d?: boolean;
  viewBox?: { bottom: number; brushBottom: number; left: number; right: number; top: number };
  width: string;
  wrapperStyle?: {};
}

function Tooltip({
  coordinate,
  children,
  offset,
  width,
  ...otherProps
}: TooltipProps): JSX.Element {
  const content = _.get(otherProps, 'payload[0]', {});
  const date = _.get(content, 'payload.date');
  const wrapper = useRef(null);
  const [positionedOnRight, setPositionedOnRight] = useState(true);
  const [position, setPosition] = useState({ left: 0, top: 0 });

  // Calculates Tooltip position
  useLayoutEffect(() => {
    const rect = getBoundingClientRect(wrapper);

    // Find a consistent point to evaluate against window width
    const xTarget = positionedOnRight ? rect.left - offset : rect.left + rect.width + offset;
    const newPositionedOnRight = xTarget + rect.width + offset < window.innerWidth;

    const coords = {
      top: coordinate.y - rect.height / 2,
      left: newPositionedOnRight ? coordinate.x + offset : coordinate.x - offset - rect.width
    };

    setPosition(coords);
    setPositionedOnRight(newPositionedOnRight);
  }, [coordinate.x, coordinate.y, offset, positionedOnRight, wrapper]);

  // TODO: Replace use of `style` with `styled-components` and props
  /* eslint-disable react/forbid-dom-props */
  return (
    <div className={styles.TooltipWrapper} ref={wrapper} style={{ width, ...position }}>
      {date && <div className={styles.TooltipDate}>{formatDate.useMomentInput(date)}</div>}
      <div className={styles.TooltipContent}>{children(content)}</div>
    </div>
  );
  /* eslint-enable react/forbid-dom-props */
}

const defaultChildren = ({ value }: $TODOFIXME) => value;

Tooltip.propTypes = {
  children: PropTypes.func,
  width: PropTypes.string,
  offset: PropTypes.number
};

Tooltip.defaultProps = {
  children: defaultChildren,
  width: '200px',
  offset: 25
};

export default Tooltip;
