import React, { useMemo } from 'react';
import { Box, Column, Columns, Pagination } from 'src/components/matchbox';
import { DEFAULT_PAGE_RANGE, DEFAULT_PER_PAGE_BUTTONS } from 'src/constants';
import PerPageButtons from './PerPageButtons';
import SaveCSVButton from './SaveCSVButton';

type CollectionPaginationProps = {
  currentPage: number | undefined;
  data: $TODOFIXME[];
  onPageChange?: ((currentPage: number) => void) | (() => void);
  onPerPageChange: (buttonAmount: number) => void;
  pageRange: number;
  perPage: number;
  perPageButtons: number[];
  saveCsv: boolean;
};

const CollectionPagination = ({
  currentPage,
  data,
  onPageChange,
  onPerPageChange,
  pageRange,
  perPage,
  perPageButtons,
  saveCsv
}: CollectionPaginationProps): JSX.Element | null => {
  const pagination = useMemo(() => {
    if (!data || data.length <= perPage) {
      return null;
    }

    return (
      <Column data-id="pagination-pages">
        <Pagination
          pages={Math.ceil(data.length / perPage)}
          pageRange={pageRange}
          currentPage={currentPage}
          onChange={onPageChange}
        />
      </Column>
    );
  }, [currentPage, data, onPageChange, pageRange, perPage]);

  if (!currentPage) {
    return null;
  }

  return (
    <Columns collapseBelow="xs" align="center">
      {pagination}

      <Box
        as={Column}
        data-id="pagination-per-page"
        display="flex"
        alignItems="center"
        justifyContent={['flex-start', 'flex-start', 'flex-end']}
      >
        <PerPageButtons
          totalCount={data.length}
          perPage={perPage}
          perPageButtons={perPageButtons}
          onPerPageChange={onPerPageChange}
        />

        <SaveCSVButton data={data} saveCsv={saveCsv} filename={null} />
      </Box>
    </Columns>
  );
};

CollectionPagination.defaultProps = {
  pageRange: DEFAULT_PAGE_RANGE,
  perPageButtons: DEFAULT_PER_PAGE_BUTTONS,
  saveCsv: true
};

export default CollectionPagination;
