import { Location } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { HeaderAccountBanner } from 'src/components/headerAccountBanner';
import { selectHibanaNavItems } from 'src/selectors/navItems';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';

type HeaderProps = {
  className?: string;
  location: Location;
  navItems: $TODOFIXME[];
};

function Header(props: HeaderProps) {
  const { className, location, navItems } = props;

  return (
    <header className={className}>
      <HeaderAccountBanner />

      <DesktopNavigation navItems={navItems} location={location} />

      <MobileNavigation navItems={navItems} location={location} />
    </header>
  );
}

export default withRouter(
  connect((state) => ({
    navItems: selectHibanaNavItems(state)
  }))(Header)
);
