import React, { FormEvent } from 'react';

// COMPONENTS
import { ScreenReaderExclude } from 'src/components/a11y';
import { Box, RadioCard, Stack } from 'src/components/matchbox';

// HOOKS
import { useAnalyticsReportContext } from '../../../../pages/analyticsReport/context/AnalyticsReportContext';

// STYLES
import { SectionTitle } from '../../sidebarStyles';

// TYPES
import { UseAnalyticsReportContext } from 'src/typescript';

type ActionType = {
  setIncludePrefetchedOpens: (value: boolean) => void;
};

const AlternativePrefetched = (): JSX.Element => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    actions,
    includePrefetchedOpens
  } = useAnalyticsReportContext() as UseAnalyticsReportContext;

  const allActions = actions as ActionType;

  const handleOnChange = React.useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      return allActions.setIncludePrefetchedOpens(event.currentTarget.value === 'include');
    },
    [allActions]
  );

  return (
    <Box data-id="include-prefetched-opens" position="relative">
      <Stack space="200">
        <ScreenReaderExclude>
          <SectionTitle>Prefetched Opens</SectionTitle>
        </ScreenReaderExclude>

        <RadioCard.Group space="compact" label="Prefetched Opens" labelHidden>
          <RadioCard
            size="small"
            label="Exclude Prefetched Opens"
            name="group"
            id="exclude"
            value="exclude"
            onChange={handleOnChange}
            checked={!includePrefetchedOpens}
          />
          <RadioCard
            size="small"
            label="Include Prefetched Opens"
            name="group"
            id="include"
            value="include"
            onChange={handleOnChange}
            checked={includePrefetchedOpens}
          />
        </RadioCard.Group>
      </Stack>
    </Box>
  );
};

export default AlternativePrefetched;
