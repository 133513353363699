export const formatDataForRecencyByCohort = (apiData, facetType) => {
  const { history, ...rest } = apiData;

  const dataWithKeysRenamed = Object.fromEntries(
    Object.entries(rest).map(([key, value]) => [`${key.replace('current_', '')}`, value])
  );

  Object.keys(dataWithKeysRenamed).forEach((key) => {
    if (key !== 'c_total' && key !== facetType)
      dataWithKeysRenamed[key] = dataWithKeysRenamed[key] / dataWithKeysRenamed['c_total'];
  });

  return dataWithKeysRenamed;
};

export function formatIdFromUrl(type, id) {
  switch (type) {
    case 'campaign':
      return id.toLowerCase();
    case 'ip-pool':
      return id;
    case 'mailbox-provider':
      return id;
    case 'sending-domain':
      return id;
    case 'subaccount':
      return parseInt(id);
    default:
      return '';
  }
}

export function allCurrentPropertiesNull(obj) {
  if (!obj) return true;

  return Object.keys(obj)
    .filter((key) => key.startsWith('current_'))
    .every((key) => obj[key] === null);
}
