import _ from 'lodash';

const MAX_RECENT_REPORTS = 5;

export function getRecentReports(username) {
  var recentReports = localStorage.getItem(`recent-reports-${username}`);
  return JSON.parse(recentReports) || [];
}

export function saveRecentReport(newReport, username) {
  const recentReports = getRecentReports(username).filter((x) => x !== newReport);

  if (recentReports.length === MAX_RECENT_REPORTS) {
    recentReports.shift();
  }
  recentReports.push(newReport);
  localStorage.setItem(`recent-reports-${username}`, JSON.stringify(recentReports));
}

export function deleteRecentReport(report, username) {
  const updatedReports = getRecentReports(username).filter((x) => x !== report);
  localStorage.setItem(`recent-reports-${username}`, JSON.stringify(updatedReports));
}

export function sanitizeRecentReports(deletedReports, username) {
  deletedReports.forEach((id) => {
    deleteRecentReport(id, username);
  });
}
