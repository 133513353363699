import React, { Component } from 'react';
import { ButtonLink } from 'src/components/links';
import { Snackbar } from 'src/components/matchbox';
// eslint-disable-next-line local/no-css-module-import
import styles from './Alert.module.scss';

type AlertProps = {
  action: {
    content: string;
    onClick: $TODOFIXME;
  };
  autoDismiss: boolean;
  details: string;
  maxWidth: number;
  message: string;
  onDismiss: $TODOFIXME;
  timeoutInterval: number;
  type: $TODOFIXME;
};

type AlertState = {
  showDetails: boolean;
  timeout: null | NodeJS.Timeout;
};

class Alert extends Component<AlertProps> {
  state: AlertState = {
    showDetails: false,
    timeout: null
  };

  static defaultProps = {
    autoDismiss: true,
    type: 'default',
    showDetails: false,
    timeoutInterval: 15000,
    timeout: null
  };

  componentDidMount() {
    if (this.props.autoDismiss) {
      this.refreshTimeout();
    }
  }

  handleDismiss = () => {
    this.props.onDismiss();
  };

  refreshTimeout = () => {
    if (this.state.timeout !== null) {
      clearTimeout(this.state.timeout);
    }

    this.setState({ timeout: setTimeout(this.handleDismiss, this.props.timeoutInterval) });
  };

  handleDetails = () => {
    if (this.props.autoDismiss) {
      this.refreshTimeout();
    }
    this.setState({ showDetails: true });
  };

  renderMessage() {
    const { message, details, action } = this.props;
    const { showDetails } = this.state;
    const showDetailsLink = details && !showDetails;
    let ActionsMarkup = () => {
      return <></>;
    };

    const DetailsLink = () => (
      <ButtonLink className={styles.Details} onClick={this.handleDetails}>
        View Details
      </ButtonLink>
    );

    if (action) {
      const { content: actionContent, onClick, ...actionProps } = action;

      ActionsMarkup = () => (
        <ButtonLink className={styles.Details} onClick={onClick} {...actionProps}>
          <span>{actionContent}</span>
        </ButtonLink>
      );
    }

    const markup = showDetails ? (
      <div>{details}</div>
    ) : (
      <div>
        <span>{message}</span>&nbsp;{showDetailsLink ? <DetailsLink /> : <ActionsMarkup />}
      </div>
    );

    return <div>{markup}</div>;
  }

  render() {
    const { type, maxWidth } = this.props;

    return (
      <Snackbar status={type} onDismiss={this.handleDismiss} maxWidth={maxWidth}>
        {this.renderMessage()}
      </Snackbar>
    );
  }
}

export default Alert;
