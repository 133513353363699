import React, { useCallback } from 'react';
import { Control, RegisterOptions, useController } from 'react-hook-form';
import { SubaccountTypeahead } from 'src/components/typeahead';

type SubaccountTypeaheadControllerProps = {
  control: Control<$TODOFIXME, $TODOFIXME>;
  defaultValue?: string;
  disabled?: boolean;
  helpText?: string;
  id: string;
  name: string;
  onChange?: (value: $TODOFIXME) => void;
  rules?: Omit<
    RegisterOptions<$TODOFIXME, $TODOFIXME>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
};

export function SubaccountTypeaheadController({
  control,
  name,
  rules = {},
  defaultValue = '',
  helpText,
  disabled,
  id,
  onChange
}: SubaccountTypeaheadControllerProps) {
  const {
    field: { ref, value, ...inputProps }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  const onChangeWrapper = useCallback(
    (...params: [$TODOFIXME]) => {
      onChange && onChange(...params);

      return inputProps.onChange(...params);
    },
    [inputProps, onChange]
  );

  return (
    <SubaccountTypeahead
      {...inputProps}
      id={id}
      helpText={helpText}
      disabled={disabled}
      selectedItem={value}
      ref={ref}
      data-track={true}
      onChange={onChangeWrapper}
    />
  );
}
