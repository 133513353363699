import React from 'react';

// HOOKS
import { useUniqueId } from 'src/hooks';

// COMPONENTS
import { ListBox } from 'src/components/matchbox';
// eslint-disable-next-line local/no-matchbox-import
import { ListBoxProps } from '@sparkpost/matchbox';

type SortSelectProps = {
  defaultValue?: string;
  disabled?: boolean;
  label?: string;
  onChange: ListBoxProps['onChange'];
  options: Array<{ label: string; value: string }>;
};

export const SortSelect: React.VFC<SortSelectProps> = ({
  options,
  onChange,
  disabled,
  defaultValue,
  label
}) => {
  const uniqueId = useUniqueId('sort-select');

  return (
    <div>
      {/* @ts-ignore: ListBox needs type */}
      <ListBox
        id={uniqueId}
        label={label || 'Sort By'}
        onChange={onChange}
        disabled={disabled}
        defaultValue={defaultValue || options[0].value}
      >
        {options.map((option, i) => {
          return (
            /* @ts-ignore: ListBox needs type */
            <ListBox.Option key={i} value={option.value}>
              {option.label}
              {/* @ts-ignore: ListBox needs type */}
            </ListBox.Option>
          );
        })}
      </ListBox>
    </div>
  );
};
