import moment from 'moment';
import _ from 'lodash';
import {
  list as METRICS_LIST,
  deliverabilityList as DELIVERABILITY_METRICS_LIST
} from '@sparkpost/report-builder/config';
import config from 'src/appConfig';
import { getRelativeDates, roundBoundaries } from '@sparkpost/report-builder/helpers/date';
import { toFilterTypeKey } from 'src/helpers/analyticsReport';
import { divide } from '@sparkpost/report-builder/helpers/math';
import {
  getComputeKeysOrMainKeyFromMetrics,
  getMetricsFromKeys as nonMemoizedGetMetricsFromKeys
} from '@sparkpost/report-builder/helpers/metrics';
import { differenceInMinutes, formatISO, isBefore, isEqual, isValid } from 'date-fns';

const { metricsRollupPrecisionMap: rollupPrecisionMap } = config;

/**
 * @name getRollupPrecision
 * @description Calculates the precision value for metrics Rollup. If the precision given
 * is still within range, do not change precision. If the possible precision options
 * do not include the current precision, get the recommended precision.
 *
 * @param params
 * @param {Date} params.from The beginning of the user's desired date range for a report.
 * @param {Date} params.to The end of the user's desired date range for a report.
 * @param {string} params.precision The precision of returned timeseries data selected by the user, e.g. '7days', 'custom',
 *
 * @returns {string} the precision of timeseries data to be returned - see the [API docs for examples](https://developers.sparkpost.com/api/metrics/#metrics-get-time-series-metrics)
 */
export function getRollupPrecision({ from, to = new Date(), precision }) {
  if (!precision) {
    return getRecommendedRollupPrecision(from, to);
  }
  const precisionOptions = getPrecisionOptions(moment(from), moment(to));
  const precisionOptionsValues = precisionOptions.map(({ value }) => value);
  if (precisionOptionsValues.includes(precision)) {
    return precision;
  }
  return getRecommendedRollupPrecision(from, to);
}

export function getRecommendedRollupPrecision(from, to = moment()) {
  const diff = moment(to).diff(moment(from), 'minutes');
  return rollupPrecisionMap.find(({ recommended }) => diff <= recommended).value;
}

/**
 * @name getPrecisionOptions
 * @description Creates an array of possible precision options for a time span
 *
 * @param {Date} from The beginning of the user's desired date range for a report.
 * @param {Date} to The end of the user's desired date range for a report.
 *
 * @returns {string} the precision of timeseries data to be returned - see the [API docs for examples](https://developers.sparkpost.com/api/metrics/#metrics-get-time-series-metrics)
 */
export function getPrecisionOptions(from, to = new Date()) {
  const fromDate = moment.isMoment(from) ? from.toDate() : from;
  const toDate = moment.isMoment(to) ? to.toDate() : to;

  const diff = differenceInMinutes(toDate, fromDate);
  return _getPrecisionOptions(diff);
}

const _getPrecisionOptions = _.memoize((diff) => {
  const result = rollupPrecisionMap
    .filter(({ min, max }) => diff >= min && diff <= max)
    .map(({ value }) => ({ value, label: _.startCase(_.words(value).join(' ')) }));
  return result;
});

export function getMomentPrecisionByDate(from, to = moment()) {
  const diff = moment(to).diff(moment(from), 'minutes');
  if (diff <= 60 * 24) {
    return 'minutes';
  }
  return diff <= 60 * 24 * 2 ? 'hours' : 'days';
}

export function getMomentPrecisionByPrecision(precision) {
  if (precision.includes('min')) {
    return 'minutes';
  }
  if (['day', 'week', 'month'].includes(precision)) {
    return 'day';
  }
  return 'hour';
}

export function getPrecisionType(precision) {
  switch (precision) {
    case '1min':
    case '5min':
    case '15min':
      return 'hour';
    default:
      return precision;
  }
}

// We are forced to use UTC for any precision greater or equal to 'day'
const FORCED_UTC_ROLLUP_PRECISIONS = ['day', 'week', 'month'];

/**
 * @name isForcedUTCRollupPrecision
 * @description determines whether the passed in precision means a UTC date must be used as the timezone for metrics API requests
 *
 * @param {string} precision - the user's selected date precision
 *
 * @returns {boolean}
 */
export function isForcedUTCRollupPrecision(precision) {
  return FORCED_UTC_ROLLUP_PRECISIONS.includes(precision);
}

/**
 * @name getValidDateRange
 * @description returns verified from and to dates; throws an error if date range is invalid - catch this to reset to last state
 *
 * @param {Date} from The beginning of the user's desired date range for a report.
 * @param {Date} to The end of the user's desired date range for a report.
 * @param {Date} now the current date
 * @param {boolean} roundToPrecision whether to round the returned from and to dates according to the passed in precision
 * @param {boolean} preventFuture whether or not to prevent future dates from returning
 * @param {string} precision The precision of returned timeseries data selected by the user, e.g. '7days', 'custom',
 *
 * @returns {from, to} returns a valid from and to date range
 */
export function getValidDateRange({
  from,
  to,
  now = new Date(),
  roundToPrecision,
  preventFuture,
  precision
}) {
  // If we're not rounding, check to see if we want to prevent future dates. if not, we're good.
  const nonRoundCondition = roundToPrecision || precision || !preventFuture || isBefore(to, now);
  const validDates = _.every(_.map([from, to, now], (date) => isValid(date)));

  if (validDates && isBefore(from, to) && nonRoundCondition) {
    if (!roundToPrecision) {
      return { from, to };
    }

    // Use the user's rounded 'to' input if it's less than or equal to 'now' rounded up to the nearest precision,
    // otherwise use the valid range and precision of floor(from) to ceil(now).
    // This is necessary because the precision could change between the user's invalid range, and a valid range.
    const roundedBoundariesNow = roundBoundaries({
      from: formatISO(from),
      to: formatISO(now),
      precision
    });

    const roundedFromNow = roundedBoundariesNow.from;
    const roundedNow = roundedBoundariesNow.to;

    const roundedBoundaries = roundBoundaries({
      from: formatISO(from),
      to: formatISO(to),
      precision
    });

    const roundedFrom = roundedBoundaries.from;
    const roundedTo = roundedBoundaries.to;

    if (isBefore(roundedTo, roundedNow) || isEqual(roundedTo, roundedNow)) {
      from = roundedFrom;
      to = roundedTo;
    } else {
      from = roundedFromNow;
      to = roundedNow;
    }

    return { from, to };
  }

  throw new Error('Invalid date range selected');
}

/**
 * Retrieve metrics configuration information based on the passed in key
 * @param {string} metricKey relevant key based on available metrics
 */
export function getMetricFromKey(metricKey) {
  return METRICS_LIST.find((metric) => metric.key === metricKey);
}

/**
 * @name getMetricFromKey
 * @description Retrieves an array of metrics objects from the passed in array of metric string keys
 *
 * @param keys array of metric string
 *
 * @returns {Array[metric]} returns an array of metrics objects derived from metrics configuration, formatted for use in the UI
 */
export const getMetricsFromKeys = _.memoize(
  nonMemoizedGetMetricsFromKeys,
  (keys = []) => `${keys.join('')}`
);

/**
 * @name allMetricsAreDeliverability
 * @description given an array of metrics, return true if they are all in the DELIVERABILITY_METRICS_LIST
 *
 * @param metrics {Array[string]} Array of string values representing metric.key values
 *
 * @return {Boolean}
 * @note Only checks metric.key, not metric.computeKeys
 */
export const allMetricsAreDeliverability = (metrics) => {
  return (
    metrics &&
    metrics.length &&
    metrics.every(
      (metric) =>
        DELIVERABILITY_METRICS_LIST.findIndex(
          (deliverabilityMetric) => deliverabilityMetric.key === metric
        ) >= 0
    )
  );
};

/**
 * @name getDeliverabilityOnlyMetrics
 * @description given an array of metrics, return the ones that are in the DELIVERABILITY_METRICS_LIST
 *
 * @param metrics {Array[string]} Array of string values representing metric.key values
 *
 * @return metrics {Array[metrics]}
 * @note Only checks metric.key, not metric.computeKeys
 */
export const getDeliverabilityOnlyMetrics = (metrics) => {
  return (
    metrics &&
    metrics.length &&
    metrics.filter(
      (metric) =>
        DELIVERABILITY_METRICS_LIST.findIndex(
          (deliverabilityMetric) => deliverabilityMetric.key === metric
        ) >= 0
    )
  );
};

export const deliverabilityComputeKeys = getComputeKeysOrMainKeyFromMetrics(
  DELIVERABILITY_METRICS_LIST
);

export function computeKeysForItem(metrics = []) {
  return (item) =>
    metrics.reduce((acc, metric) => {
      if (metric.compute) {
        acc[metric.key] = metric.compute(acc, metric.computeKeys);
      }
      return acc;
    }, item);
}

/**
 * @name transformData
 * @description Transforms API result into chart-ready data in 2 steps:
 * 1. computes any necessary computed metrics
 * 2. arranges metrics into groups sorted by unit/measure
 *
 * @param {Array} metrics list of currently selected metrics objects from config
 * @param {Array} data results from the metrics API
 *
 * @return {Array} returns data in a format consumable by charts UI
 */
export function transformData(data = [], metrics = []) {
  return data.map(computeKeysForItem(metrics));
}

// Extract from, to, filters (campaign, template, ...) and any other included update fields
// into a set of common options for metrics queries.
export function buildCommonOptions(reportOptions, updates = {}) {
  return {
    ...reportOptions,
    ...updates,
    ...getRelativeDates.useMomentInput(reportOptions.relativeRange),
    ...getRelativeDates.useMomentInput(updates.relativeRange)
  };
}

/**
 * @name toFilterFromComparison
 * @description prepares request options/params based on the current state of the page and the passed in comparison object.
 *
 * @param {Object} comparison - passed in comparison object when the user selects comparisons via "compare by"
 *
 * @returns {Object} returns an [advanced filter object](https://developers.sparkpost.com/api/metrics/#header-advanced-filters]) derived from the user's selected comparison
 */
export function toFilterFromComparison(comparison) {
  const filterId = toFilterTypeKey(comparison.type);

  if (!filterId)
    throw new Error(
      `Invalid comparison ${comparison} - please supply a valid comparison to "useComparisonArguments".`
    );

  // Returns according to the metrics advanced filters:
  // https://developers.sparkpost.com/api/metrics/#header-advanced-filters
  return {
    AND: {
      [filterId]: {
        eq: [comparison] // The `dehydrateFilters` helper expects this structure
      }
    }
  };
}

/**
 * @name splitDeliverabilityMetric
 * @description Splits metric to either source from exclusively seed or panel data for deliverability metrics
 *
 * @param {Object} metric metrics object to split
 * @param {Array[String]} dataSource array of values included for data source
 *
 * @returns {Object} returns metrics object with the relevant `_seed` or `_panel` key - see list of [metrics keys in the API docs](https://developers.sparkpost.com/api/metrics/#metrics-get-time-series-metrics)
 */
export function splitDeliverabilityMetric(metric, dataSource) {
  if (metric.product !== 'deliverability') {
    return metric;
  }

  const hasPanel = dataSource.includes('panel');
  const hasSeed = dataSource.includes('seed');
  const hasBoth = hasSeed && hasPanel;
  const targetString = hasPanel ? 'panel' : 'seed';

  if (!hasPanel && !hasSeed) {
    return metric;
  }

  if (hasBoth) {
    return metric;
  }

  switch (metric.key) {
    case 'count_inbox':
      return {
        ...metric,
        computeKeys: [`count_inbox_${targetString}`],
        compute: (data) => data[`count_inbox_${targetString}`]
      };
    case 'count_spam':
      return {
        ...metric,
        computeKeys: [`count_spam_${targetString}`],
        compute: (data) => data[`count_spam_${targetString}`]
      };
    case 'inbox_folder_rate':
      return {
        ...metric,
        computeKeys: [`count_spam_${targetString}`, `count_inbox_${targetString}`],
        compute: (data) =>
          divide(
            data[`count_inbox_${targetString}`],
            data[`count_inbox_${targetString}`] + data[`count_spam_${targetString}`]
          )
      };
    case 'spam_folder_rate':
      return {
        ...metric,
        computeKeys: [`count_spam_${targetString}`, `count_inbox_${targetString}`],
        compute: (data) =>
          divide(
            data[`count_spam_${targetString}`],
            data[`count_inbox_${targetString}`] + data[`count_spam_${targetString}`]
          )
      };
    case 'count_moved_to_inbox':
    case 'count_moved_to_spam':
    case 'moved_to_inbox_rate':
    case 'moved_to_spam_rate':
      if (targetString !== 'panel') {
        //Only works with panel data
        return {
          ...metric,
          computeKeys: [],
          compute: undefined
        };
      } else {
        return metric;
      }
    default:
      return metric; //Other deliverability metrics have no changes
  }
}

/**
 * Sorts an array's objects by object keys
 * @param {Array<Object>} array - Array of objects
 * @param {Array} keys - An array of keys in the desired order
 * @returns {Array} The sorted Array of objects
 */
export function sortDataByKeys(array, keys) {
  if (!keys || !array) {
    return [];
  }

  return array.map((row) => {
    let sortedRow = {};
    for (const key of keys) {
      sortedRow = { ...sortedRow, [key]: row[key] };
    }
    return sortedRow;
  });
}
