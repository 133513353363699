import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { getSubaccount } from 'src/actions/subaccounts';
import { selectSubaccount } from 'src/selectors/subaccounts';
import { useAlertForm } from '../useAlertForm';

import { list } from '@sparkpost/report-builder/config';
import { hydrateFilters } from 'src/helpers/analyticsReport';
import { selectSubaccounts } from 'src/helpers/api/selectors/metrics';
import { getSubaccounts } from 'src/helpers/api/subaccounts';
import { convertNumbersToDays, convertToAmPmFormat } from 'src/helpers/hour';
import { useSparkPostQuery } from 'src/hooks';
import { QueryFN } from 'src/hooks/api/types';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import { ALERT_TYPES } from '../../constants/alertTypes';
import { getAlertName } from '../../helpers/alertTypes';
import { METRICS_BREAKDOWN_TYPES } from '../constants';

type UseFillAlertForm = {
  handleFillAlertForm: (alert: Alert, isDuplicatingAlert: boolean) => void;
};

interface Alert {
  alert_configuration: AlertConfiguration;
  alert_type: number;
  created: Date;
  customer: number;
  description: null;
  id: number;
  last_triggered: null;
  name: string;
  severity: string;
  status: null;
  subaccount: number;
  targets: Target[];
  tenant: string;
  updated: Date;
}

interface AlertConfiguration {
  any_subaccount?: boolean;
  conditions: Conditions;
  filters?: Filters;
  group_by?: string;
  metric?: string;
  notification_schedule: NotificationSchedule;
  subaccounts?: [];
}

interface NotificationSchedule {
  days: number[];
  timeframe: Timeframe;
}

interface Timeframe {
  end: string;
  start: string;
  time_zone: string;
}

interface Conditions {
  operator: string;
  precision?: string;
  source?: string;
  value: string;
}

interface Target {
  alert_id: number;
  id: number;
  target: string;
  target_type: string;
}

export interface Filters {
  groupings: unknown[];
}

interface Subaccount {
  id: number;
  name: string;
}

function getSelectedMetric(alertTypeId: number, metric?: string) {
  const foundMetric = list.find(({ key }) => key === metric);
  if (foundMetric) {
    return foundMetric;
  }
  const newMetric = {
    key: getAlertName(alertTypeId),
    label: getAlertName(alertTypeId).split('_').join(' ')
  };
  return newMetric;
}

function getTimeRange(notificationSchedule: NotificationSchedule) {
  const isAnytime =
    _.isEqual(notificationSchedule.days, [1, 2, 3, 4, 5, 6, 7]) &&
    notificationSchedule.timeframe.start === '00:00:00' &&
    notificationSchedule.timeframe.end === '23:59:59';
  return isAnytime ? 'anytime' : 'defined';
}

function shouldUpdateConditionData(alert_type: number) {
  const validAlertTypes = [
    ALERT_TYPES.metrics,
    ALERT_TYPES.sending_limit_alert,
    ALERT_TYPES.health_score
  ];
  return validAlertTypes.includes(alert_type);
}

export function useFillAlertForm(): UseFillAlertForm {
  const { dispatch } = useAlertForm();
  const reduxDispatch = useDispatch();
  const {
    actions: {
      setFilters,
      setBlocklistFilters,
      setHealthScoreFilters,
      setHealthScoreSubaccounts,
      setGroupBy
    }
  } = useAnalyticsReportContext() as $TODOFIXME;
  const [shouldDispatchSubaccountInfo, setShouldDispatchSubaccountInfo] = useState(false);

  // TODO-TS: remove and and type correctly
  const subaccount = useSelector<$TODOFIXME, $TODOFIXME>(selectSubaccount);
  const { data: subaccountsData } = useSparkPostQuery(getSubaccounts as QueryFN, {
    select: selectSubaccounts
  }) as { data: Subaccount[] };

  const handleFillAlertForm = useCallback<UseFillAlertForm['handleFillAlertForm']>(
    (alert: Alert, isDuplicatingAlert: boolean) => {
      const selectedMetric = getSelectedMetric(alert.alert_type, alert.alert_configuration.metric);
      const accountData = {
        assignment: 'primary',
        subaccount: {}
      };
      const { notification_schedule } = alert.alert_configuration;

      if (alert.subaccount > 0) {
        // request subaccount info
        setShouldDispatchSubaccountInfo(true);
        reduxDispatch(getSubaccount(alert.subaccount));
      }
      if (alert.alert_configuration?.filters?.groupings) {
        setFilters(hydrateFilters(alert.alert_configuration?.filters?.groupings));
      }
      if (alert.alert_type === ALERT_TYPES.blocklistings) {
        setBlocklistFilters(alert.alert_configuration.filters);
      }
      if (alert.alert_type === ALERT_TYPES.health_score) {
        setHealthScoreFilters(alert.alert_configuration.filters);
        const healthScoreSubaccountIds: number[] = alert.alert_configuration?.subaccounts || [];
        setHealthScoreSubaccounts({
          any_subaccount: alert.alert_configuration?.any_subaccount,
          subaccounts: subaccountsData?.filter(({ id }) => healthScoreSubaccountIds.includes(id))
        });
      }

      dispatch({ type: 'UPDATE_TYPE', data: getAlertName(alert.alert_type) });
      dispatch({ type: 'UPDATE_METRIC_DATA', data: { selectedMetric } });
      dispatch({ type: 'UPDATE_ACCOUNT_DATA', data: accountData });
      const alertName = isDuplicatingAlert ? `Copy of ${alert.name}` : alert.name;
      dispatch({
        type: 'UPDATE_ALERT_DATA',
        data: { name: alertName, severity: alert.severity, status: alert?.status || 'disabled' }
      });

      const [startTime, startAmPm] = convertToAmPmFormat(notification_schedule.timeframe.start);
      const [endTime, endAmPm] = convertToAmPmFormat(notification_schedule.timeframe.end);

      dispatch({
        type: 'UPDATE_NOTIFICATION_TIME',
        data: {
          range: getTimeRange(notification_schedule),
          startTime: startTime,
          startPeriod: startAmPm,
          endTime: endTime,
          endPeriod: endAmPm,
          days: convertNumbersToDays(notification_schedule.days),
          timeZone: notification_schedule.timeframe.time_zone
        }
      });
      dispatch({
        type: 'UPDATE_MEASUREMENT_DATA',
        data: {
          measurementType: alert.alert_configuration?.group_by
            ? METRICS_BREAKDOWN_TYPES.breakdownByProperty
            : METRICS_BREAKDOWN_TYPES.byValue
        }
      });
      setGroupBy(alert.alert_configuration?.group_by);

      if (shouldUpdateConditionData(alert.alert_type)) {
        switch (alert.alert_type) {
          case ALERT_TYPES.health_score:
            dispatch({
              type: 'UPDATE_CONDITION_DATA',
              data: {
                condition: alert.alert_configuration.conditions.operator,
                alertThreshold: alert.alert_configuration.conditions.value,
                source: alert.alert_configuration.conditions.source
              }
            });
            break;
          default:
            dispatch({
              type: 'UPDATE_CONDITION_DATA',
              data: {
                condition: alert.alert_configuration.conditions.operator,
                alertThreshold: alert.alert_configuration.conditions.value,
                precision: alert.alert_configuration.conditions.precision
              }
            });
        }
      }

      const parseTargetsToNotificationData = (targetList: Target[]) => {
        const targetObject: any = { emails: [] };
        targetList.forEach((target) => {
          switch (target.target_type) {
            case 'email':
              targetObject['isEmailChecked'] = true;
              targetObject.emails = [...targetObject.emails, target.target];
              break;
            case 'slack':
              targetObject['isSlackChecked'] = true;
              targetObject['slack'] = target.target;
              break;
            case 'webhook':
              targetObject['isWebhookChecked'] = true;
              targetObject['webhook'] = target.target;
              break;
          }
        });
        return targetObject;
      };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch({
        type: 'UPDATE_NOTIFICATION_DATA',
        data: parseTargetsToNotificationData(alert.targets)
      });
    },
    [
      dispatch,
      reduxDispatch,
      setBlocklistFilters,
      setFilters,
      setGroupBy,
      setHealthScoreFilters,
      setHealthScoreSubaccounts,
      subaccountsData
    ]
  );

  // receive subaccount info and dispatch to the form
  useEffect(() => {
    if (shouldDispatchSubaccountInfo && subaccount.id) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch({
        type: 'UPDATE_ACCOUNT_DATA',
        data: {
          assignment: 'subaccount',
          subaccount: {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            id: subaccount.id,
            type: 'subaccounts',
            value: `${subaccount.name} (ID ${subaccount.id})`
          }
        }
      });
    }
  }, [shouldDispatchSubaccountInfo, subaccount, dispatch]);

  return {
    handleFillAlertForm
  };
}
