import React, { useCallback, useState, useMemo } from 'react';
import { ComboBoxTypeahead } from 'src/components/typeahead/AsyncComboBoxTypeahead';
import { METRICS_API_LIMIT } from 'src/constants';
import sortMatch from 'src/helpers/sortMatch';
import { useSparkPostQuery } from 'src/hooks';

const METRICS_API_SMALL_LIMIT = 20;

const limitedFilterTypes = ['subaccounts'];

function getLimit(filterType) {
  return limitedFilterTypes.includes(filterType) ? METRICS_API_SMALL_LIMIT : METRICS_API_LIMIT;
}

function Typeahead(props) {
  const {
    id,
    setFilterValues,
    index,
    lookaheadRequest,
    lookaheadOptions = {},
    selector,
    value,
    groupingIndex,
    filterIndex,
    filterType,
    maxItems,
    ...rest
  } = props;
  const [inputValue, setInputValue] = useState('');
  let allowCustom = true;

  const { data, status } = useSparkPostQuery(
    () =>
      lookaheadRequest({
        ...lookaheadOptions,
        match: inputValue,
        limit: getLimit(filterType),
        staleTime: 1000 * 30 // 30 seconds
      }),
    { enabled: Boolean(inputValue && inputValue.length >= 3) }
  );

  const results = selector(data);

  const onFilterChange = useCallback(
    (newValues) => {
      setFilterValues({ groupingIndex, filterIndex, values: newValues });
    },
    [groupingIndex, filterIndex, setFilterValues]
  );

  const filteredResults = useMemo(() => {
    return sortMatch(results, inputValue, (a) => a.value);
  }, [inputValue, results]);

  if (filterType === 'subaccounts') {
    allowCustom = false;
  }

  return (
    <ComboBoxTypeahead
      id={id}
      onChange={onFilterChange}
      onInputChange={setInputValue}
      value={value}
      results={filteredResults}
      loading={status === 'loading'}
      customItemHelpText="Add Filter"
      allowCustom={allowCustom}
      maxItems={maxItems}
      {...rest}
    />
  );
}

export default Typeahead;
