import PropTypes, { ReactElementLike, ReactNodeArray } from 'prop-types';
import React from 'react';
import { Text } from 'src/components/matchbox';
import { Emphasized, Uppercase } from 'src/components/text';

type ComparisonProps = {
  as?: $TODOFIXME;
  children: string | number | boolean | Record<string, never> | ReactElementLike | ReactNodeArray;
};

export default function Comparison(props: ComparisonProps) {
  const { as = 'span', children } = props;

  return (
    <Text as={as} fontWeight="500" data-id={props['data-id']}>
      <Uppercase>
        <Emphasized>{children}</Emphasized>
      </Uppercase>
    </Text>
  );
}

Comparison.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]).isRequired,
  'data-id': PropTypes.string
};
