import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { AlertType, showAlert } from 'src/actions/globalAlert';

type RedirectAndAlertProps = {
  alert: AlertType;
  showAlert: typeof showAlert;
  to: string;
};

export class RedirectAndAlert extends Component<RedirectAndAlertProps> {
  componentDidMount() {
    this.props.showAlert(this.props.alert);
  }

  render() {
    return <Redirect to={this.props.to} />;
  }
}

export default connect(undefined, { showAlert })(RedirectAndAlert);
