import React from 'react';
import { Empty, Subaccount, Unit } from 'src/components';
import { PageLink } from 'src/components/links';
import { LabelValueLoadingSkeleton } from 'src/components/loading';
import { Column, Columns, LabelValue, Panel } from 'src/components/matchbox';
import { getIncidents } from 'src/helpers/api/blocklist';
import { getSendingDomain } from 'src/helpers/api/sendingDomains';
import { getSubaccount } from 'src/helpers/api/subaccounts';
import { useSparkPostQuery } from 'src/hooks';
import useResourceDetailsContext from '../../hooks/useResourceDetailsContext';
import DataSources from './DataSources';
import { WarningIcon } from './utils/styles';

export default function SendingDomain({
  hasD12yProduct,
  deliverabilityDataStatus,
  deliverabilityData
}) {
  const {
    match: { params: { id } = {} } = {},
    isBlocklistEnabled,
    filters
  } = useResourceDetailsContext();

  const loadingLabelValues = [
    { width: 1 / 5, value: 'Sending Domain' },
    { width: 1 / 5, value: 'Subaccount Assignment' },
    { width: 1 / 5, value: 'Sent' },
    hasD12yProduct && { width: 1 / 6, value: 'Seed Count' },
    hasD12yProduct && { width: 1 / 6, value: 'Panel Count' },
    { width: 1 / 5, value: 'Blocklists' }
  ];

  /**
   * Sending Domain Info (for subaccount assignment)
   */
  const { status: sendingDomainAssignmentStatus, data: sendingDomainAssignment = [] } =
    useSparkPostQuery(() => getSendingDomain(id));

  /**
   * Subaccount info for when the domain is associated with a subaccount
   */
  const { status: subaccountDataStatus, data: subaccountData = [] } = useSparkPostQuery(
    () => getSubaccount({ id: sendingDomainAssignment?.subaccount_id }),
    {
      enabled: sendingDomainAssignment?.subaccount_id >= 0
    }
  );

  /**
   * Blocklist incidents
   */
  const { status: blocklistDataStatus, data: blocklistData = [] } = useSparkPostQuery(
    () => getIncidents({ from: filters.from, to: filters.to, resources: [id].join(',') }),
    {
      enabled: isBlocklistEnabled
    }
  );

  if (
    blocklistDataStatus === 'loading' ||
    subaccountDataStatus === 'loading' ||
    sendingDomainAssignmentStatus === 'loading' ||
    deliverabilityDataStatus === 'loading'
  ) {
    return (
      <Panel.Section>
        <LabelValueLoadingSkeleton labelValues={loadingLabelValues}></LabelValueLoadingSkeleton>
      </Panel.Section>
    );
  }

  if (sendingDomainAssignmentStatus === 'error' || deliverabilityDataStatus === 'error') {
    return <Empty message="Failed to load the data. Refresh page."></Empty>;
  }

  let subaccountAssociationLink;
  if (sendingDomainAssignment?.shared_with_subaccounts === true) {
    subaccountAssociationLink = '/account/subaccounts';
  } else if (
    !sendingDomainAssignment?.shared_with_subaccounts &&
    sendingDomainAssignment?.subaccount_id >= 0
  ) {
    subaccountAssociationLink = `/account/subaccounts/${sendingDomainAssignment?.subaccount_id}/sending-domains`;
  }
  const isPrimaryAccountDomain =
    !sendingDomainAssignment?.subaccount_id && !sendingDomainAssignment?.shared_with_subaccounts;

  const domainsIsSharedWithAll =
    !sendingDomainAssignment?.subaccount_id &&
    sendingDomainAssignment?.shared_with_subaccounts === true;

  let blocklistLink = `/signals/blocklist/incidents?search=${id}`;
  return (
    <Panel.Section>
      <Columns>
        <Column width={1 / 6}>
          <LabelValue>
            <LabelValue.Label>Sending Domain</LabelValue.Label>
            <LabelValue.Value>{id}</LabelValue.Value>
          </LabelValue>
        </Column>
        <Column width={1 / 6}>
          <LabelValue>
            <LabelValue.Label>Subaccount Assignment</LabelValue.Label>
            <LabelValue.Value>
              <PageLink to={subaccountAssociationLink}>
                <Subaccount
                  name={!isPrimaryAccountDomain && !domainsIsSharedWithAll && subaccountData?.name}
                  all={domainsIsSharedWithAll}
                  allText="All Subaccounts"
                  master={isPrimaryAccountDomain}
                  shrinkLength={24}
                />
              </PageLink>
            </LabelValue.Value>
          </LabelValue>
        </Column>

        <DataSources
          dataDisplayOnly={true}
          hasD12yProduct={hasD12yProduct}
          deliverabilityData={deliverabilityData}
          deliverabilityDataStatus={deliverabilityDataStatus}
        />

        {isBlocklistEnabled && (
          <Column width={1 / 6}>
            <LabelValue>
              <LabelValue.Label>
                {blocklistData?.length > 0 && <WarningIcon />}
                Blocklists
              </LabelValue.Label>
              <LabelValue.Value>
                {blocklistData?.length > 0 && (
                  <div>
                    <PageLink to={blocklistLink}>
                      <Unit value={blocklistData?.length} unit="number"></Unit>
                    </PageLink>
                  </div>
                )}
                {blocklistData?.length === 0 && <div>None</div>}
              </LabelValue.Value>
            </LabelValue>
          </Column>
        )}
      </Columns>
    </Panel.Section>
  );
}
