import qs from 'qs';
import _ from 'lodash';
import {
  list as METRICS_LIST,
  REPORT_BUILDER_FILTER_KEY_MAP,
  REPORT_BUILDER_FILTER_KEY_INVERTED_MAP
} from '@sparkpost/report-builder/config';
import { getRelativeDates, relativeDateOptions } from '@sparkpost/report-builder/helpers/date';
import { mapFiltersToComparators } from '@sparkpost/report-builder/helpers/metrics';

/**
 * @name toFilterTypeKey
 * @description Returns the filter key for REPORT_BUILDER_FILTER_KEY_MAP object based on the passed in value
 * This is needed for backwards compatibility
 * @param {string} key - either the key or label value for the filters map
 * @returns {string} returns human-readable filter type from the passed in string key, e.g. 'domains' returns 'Recipient Domain'
 */
export function toFilterTypeKey(key) {
  if (REPORT_BUILDER_FILTER_KEY_INVERTED_MAP.hasOwnProperty(key)) {
    return key;
  }

  return REPORT_BUILDER_FILTER_KEY_MAP[key];
}

/**
 * @name hydrateFilters
 * @description Converts API data structure in to a more human-readable version of filters for later rendering
 * @param {Array} groupings grouped [advanced filters](https://developers.sparkpost.com/api/metrics/#header-advanced-filters) retrieved from the API
 * @param {Object} options
 *   @param {Array} options.subaccounts array of subaccounts, usually retrieved from the global data store
 * @returns UI-consumable filters for rendering the currently active filters within a component
 */
export function hydrateFilters(groupings, { subaccounts } = {}) {
  return groupings.map((grouping) => {
    const groupingKeys = Object.keys(grouping);
    const groupingType = groupingKeys[0];
    const filters = Object.keys(grouping[groupingType]);

    return {
      [groupingType]: filters.reduce((ret, filter) => {
        const filterObj = grouping[groupingType][filter];
        const comparisons = Object.keys(filterObj);
        ret[filter] = comparisons.reduce((filterRet, comparison) => {
          switch (comparison) {
            case 'eq':
            case 'notEq': {
              if (filter === 'subaccounts') {
                filterRet[comparison] = filterObj[comparison].map((rawValue) => {
                  // Depending on the filters being parsed (old vs. newer grouped comparator filters vs custom),
                  // the filters may be a an object instead of a string.
                  const id = typeof rawValue === 'object' ? rawValue.id : rawValue;
                  const subaccount = subaccounts.find(
                    (subaccount) => subaccount.id === Number.parseInt(id)
                  );

                  return {
                    value: subaccount ? `${subaccount.name} (ID ${id})` : id,
                    id,
                    type: toFilterTypeKey(filter)
                  };
                });
              } else {
                filterRet[comparison] = filterObj[comparison].map((rawValue) => {
                  // Depending on the filters being parsed (old vs. newer grouped comparator filters),
                  // the filters may be a an object instead of a string.
                  const value = typeof rawValue === 'object' ? rawValue.value : rawValue;

                  return { value, type: toFilterTypeKey(filter) };
                });
              }

              return filterRet;
            }
            case 'like':
            case 'notLike':
            default:
              filterRet[comparison] = filterObj[comparison];
              return filterRet;
          }
        }, {});

        return ret;
      }, {})
    };
  });
}

/**
 * Parses search string
 * @param  {string} search - location.search
 * @returns {Object}
 * {
 *   filters: array of advanced filters
 *   comparisons: array of filters that are being compared
 *   range: specific range for dates, used in place of specific dates
 *   from: javascript date representing beginning of range
 *   to: javascript date representing beginning of range
 *   timezone: string representing timezone
 *   precision: string representing grouping of data based on a time intervals (minute, hour, day, etc.)
 *  report: string representing ID of an active report
 * }
 */
export function parseSearch(search) {
  if (!search) {
    return {};
  }

  const {
    from,
    to,
    range,
    metrics,
    timezone,
    precision,
    filters = [],
    query_filters,
    comparisons = [],
    report,
    industryBenchmarkMetric,
    industryBenchmarkFilters,
    group_by,
    includePrefetched
  } = qs.parse(search, { ignoreQueryPrefix: true, arrayLimit: METRICS_LIST.length });
  /**
   * Note: Is this the way we want to reset values? The reason we run into stickyness
   * issues is that this object isn't initialized and we add to it (and we don't really
   * add falsy values) which would unset this
   *  */

  let ret = {
    groupBy: undefined
  };

  if (['true', 'false'].includes(includePrefetched)) {
    ret.includePrefetched = JSON.parse(includePrefetched);
  }

  if (report) {
    ret.report = report;
  }

  if (group_by) {
    ret.groupBy = group_by;
  }

  if (query_filters) {
    try {
      ret.filters = JSON.parse(decodeURI(query_filters));
    } catch {
      ret.filters = [];
    }
  } else {
    const filtersList = (typeof filters === 'string' ? [filters] : filters).map((filter) => {
      const parts = filter.split(':');
      const type = parts.shift();
      let value;
      let id;

      // Subaccount filters include 3 parts
      // 'Subaccount:1234 (ID 554):554' -> { type: 'Subaccount', value: '1234 (ID 554)', id: '554' }
      if (type === 'Subaccount') {
        id = parts.pop();
        value = parts.join(':');
      } else {
        value = parts.join(':');
      }
      return { value, type, id };
    });

    ret.filters = mapFiltersToComparators(filtersList);
  }

  if (comparisons) {
    ret.comparisons = comparisons;
  }

  if (metrics) {
    ret.metrics = typeof metrics === 'string' ? [metrics] : metrics;

    // Maps approx to non approx version of the metric
    ret.metrics = ret.metrics.map((metric) => {
      return metric.replace(/_approx$/, '');
    });
  }

  const fromTime = new Date(from);
  const toTime = new Date(to);

  if (from && !isNaN(fromTime)) {
    ret.from = fromTime;
  }

  if (to && !isNaN(toTime)) {
    ret.to = toTime;
  }

  if (range) {
    const invalidRange = !_.find(relativeDateOptions, ['value', range]);
    const effectiveRange = invalidRange ? 'day' : range;
    ret = { ...ret, ...getRelativeDates.useMomentInput(effectiveRange) };
  }

  if (precision) {
    ret.precision = precision;
  }

  if (timezone) {
    ret.timezone = timezone;
  }

  if (industryBenchmarkMetric) {
    ret.industryBenchmarkMetric = industryBenchmarkMetric;
    if (industryBenchmarkFilters) {
      try {
        ret.industryBenchmarkFilters = JSON.parse(decodeURI(industryBenchmarkFilters));
      } catch {
        ret.industryBenchmarkFilters = { industryCategory: 'all', mailboxProvider: 'all' };
      }
    }
  }

  // filters are used in pages to dispatch updates to Redux store
  return ret;
}

/**
 * @name replaceComparisonFilterKey
 * @description replaces the key of comparisons with the key value rather than label value
 * @param {Array[Object]} comparisons array of comparison objects derived from the UI "Add Comparison" form
 * @returns {Array[Object]} returns re-formatted array of comparisons
 */
export function replaceComparisonFilterKey(comparisons) {
  return comparisons
    .map((comparison) => {
      return { ...comparison, type: toFilterTypeKey(comparison.type) };
    })
    .filter(({ type }) => Boolean(type));
}
