import React from 'react';
import { Users } from 'src/components/images';
import { Page } from 'src/components/matchbox';
import { ApiErrorBanner, Loading } from 'src/components';
import { PageLink } from 'src/components/links';
import { getMonitors } from 'src/helpers/api/blocklist';
import { useSparkPostQuery } from 'src/hooks';
import { BlocklistIncidents } from './components';

export default function IncidentsPage() {
  const {
    data: hasMonitors,
    status,
    error,
    refetch
  } = useSparkPostQuery(getMonitors, {
    select: (monitors) => monitors?.length > 0
  });

  // Typically I would wrap this in a `<Page />`, however,
  // because the `title` prop is dynamic according to the results, I wanted
  // to avoid rendering it until that data has made its return trip.
  if (status === 'loading') {
    return <Loading />;
  }

  if (status === 'error') {
    return (
      <Page title="Blocklist Incidents">
        <ApiErrorBanner
          message="Sorry, we seem to have had some trouble loading your blocklist monitors."
          errorDetails={error.message}
          reload={refetch}
        />
      </Page>
    );
  }

  return (
    <Page
      empty={{
        show: !hasMonitors,
        title: 'Blocklist Reports',
        image: Users,
        content: (
          <p>
            Keep an eye on your Domains and IPs and maintain a healthy sender reputation and improve
            your deliverability
          </p>
        )
      }}
      title="Blocklist Incidents"
      primaryAction={{
        content: hasMonitors ? 'View Monitored List' : 'Add to Monitored List',
        to: hasMonitors ? '/signals/blocklist/monitors' : '/signals/blocklist/monitors/add',
        as: PageLink
      }}
    >
      <BlocklistIncidents />
    </Page>
  );
}
