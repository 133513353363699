import _ from 'lodash';
import sparkpostApiRequest from './helpers/sparkpostApiRequest';
import { formatApiTimestamp } from '@sparkpost/report-builder/helpers/date';
import { subDays } from 'date-fns';

export const getIngestBatchEvents = ({
  batchIds = [],
  cursor,
  from = subDays(new Date(), 10),
  perPage,
  statuses = [],
  to = new Date()
} = {}) => {
  const errorTypes = statuses.filter((status) => status !== 'success');

  return sparkpostApiRequest({
    type: 'GET_INGEST_BATCH_EVENTS',
    meta: {
      method: 'GET',
      url: 'v1/events/ingest',
      showErrorAlert: false,
      params: {
        batch_ids: batchIds.length ? batchIds.join(',') : undefined,
        cursor,
        events: statuses.includes('success') ? 'success' : undefined,
        error_types: errorTypes.length ? errorTypes.join(',') : undefined,
        from: formatApiTimestamp(from),
        per_page: perPage,
        to: formatApiTimestamp(to)
      }
    }
  });
};
