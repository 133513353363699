import { tokens } from '@sparkpost/design-tokens';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, Button, ScreenReaderOnly, styles } from 'src/components/matchbox';
import { onPlan } from 'src/helpers/conditions/account';
import { selectCondition } from 'src/selectors/accessConditionState';
import styled from 'styled-components';

const UpgradeButtonStyled = styled(Button)`
  ${styles.buttonReset}
  ${styles.focusOutline({ radius: tokens.borderRadius_pill })}

  height:${({ theme }) => theme.sizes[650]};
  font-size: ${({ theme }) => theme.fontSizes[200]};
  border: 1px solid ${({ theme }) => theme.colors.blue[700]};
  border-radius: ${({ theme }) => theme.radii.pill};
  padding: ${({ theme }) => theme.space[200]} ${({ theme }) => theme.space[300]};
  cursor: pointer;
  transition: background ${({ theme }) => theme.motion.duration.fast},
    color ${({ theme }) => theme.motion.duration.fast};
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blue[700]};
  align-self: center;
  justify-self: end;
  margin-right: 12px;

  &:hover {
    background: ${({ theme }) => theme.colors.blue[700]};
    color: ${({ theme }) => theme.colors.white};
  }
`;

type UpgradeButtonProps = {
  account: $TODOFIXME;
  state: $TODOFIXME;
};

function UpgradeButton(props: UpgradeButtonProps) {
  const { account, state } = props;
  const { pending_cancellation } = account;

  const showUpgradeButton =
    !pending_cancellation &&
    (selectCondition(onPlan('free500-0419'))(state) ||
      selectCondition(onPlan('free500-SPCEU-0419'))(state));

  if (showUpgradeButton)
    return (
      <UpgradeButtonStyled
        aria-controls="desktop-navigation-upgrade-button"
        data-id="desktop-navigation-upgrade-button"
        to="/account/billing/plan"
        variant="default"
      >
        <Box
          display="inline-flex"
          alignItems="center"
          maxWidth={['900', null, null, null, '1000']}
          aria-hidden="true"
        >
          <Box truncate>Upgrade</Box>
        </Box>
        <ScreenReaderOnly>Upgrade button</ScreenReaderOnly>
      </UpgradeButtonStyled>
    );

  return null;
}

const mapStateToProps = (state: $TODOFIXME) => {
  return {
    account: state.account,
    state: state
  };
};
export default withRouter(connect(mapStateToProps)(UpgradeButton));
