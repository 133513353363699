import cx from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApiErrorBanner, Empty, PanelLoading, TableCollection, Unit } from 'src/components';
import EmptyCell from 'src/components/collection/EmptyCell';
import { PageLink } from 'src/components/links';
import { Box, Checkbox, Column, Columns, Panel, Table } from 'src/components/matchbox';
import MultiCheckboxDropdown from 'src/components/MultiCheckboxDropdown';
import { hasSubaccounts as hasSubaccountsSelector } from 'src/selectors/subaccounts';
import styled from 'styled-components';
import { GROUP_BY_CONFIG } from '../../constants';
import { useAnalyticsReportContext } from '../../context/AnalyticsReportContext';
import CheckboxWithLink from '../CheckboxWithLink';
import GroupByOption from './GroupByOption';
// eslint-disable-next-line local/no-css-module-import
import { getDetailsPageUrl } from 'src/appConfig/routes';
import { TranslatableText } from 'src/components/text';
import { sortMissingToEnd } from 'src/helpers/array';
import { shrinkToFit } from 'src/helpers/string';
import { checkSendingMetricsRemovalFilters } from './helpers/checkSendingMetricsRemovalFilters';
import styles from './ReportTable.module.scss';
import { SendingMetricsRemovalModal } from './SendingMetricsRemovalModal';
import { useGroupByTable } from './useGroupByTable';
import { useGroupByTableCheckboxes } from './useGroupByTableCheckboxes';

const PrimaryColumnText = styled(Box)`
  white-space: nowrap;
`;

const tableWrapper = (props) => {
  return (
    <Panel mb="500">
      <Table freezeFirstColumn>{props.children}</Table>
    </Panel>
  );
};

export const GroupByTable = () => {
  const {
    data,
    status,
    setGroupBy,
    groupBy,
    refetch,
    checkboxes,
    resetCheckboxes,
    apiMetrics,
    hasSendingMetrics,
    hasInboxTrackingMetrics,
    hasSendingProduct,
    hasD12yProduct
  } = useGroupByTable();

  const {
    selectors: { selectSummaryMetricsProcessed: displayMetrics },
    actions: { addFilters },
    state: reportOptions
  } = useAnalyticsReportContext();

  const [currentGroupPage, setCurrentGroupPage] = useState(1);

  const hasSubaccounts = useSelector(hasSubaccountsSelector);
  const subaccounts = useSelector((state) => state.subaccounts.list);
  const group = GROUP_BY_CONFIG[groupBy];

  const { metricsToRemove, modal, ...checkboxItemsController } = useGroupByTableCheckboxes();
  const { isModalOpen, meta, closeModal } = modal;

  const getSubaccountFilter = useCallback(
    (subaccountId) => {
      if (subaccountId === 0) {
        return { type: 'Subaccount', value: 'Primary Account (ID 0)', id: 0 };
      } else if (subaccountId === -1) {
        return { type: 'Subaccount', value: 'Unattributed (External Sends)', id: -1 };
      }

      const subaccount = subaccounts.find(({ id }) => {
        return id === subaccountId;
      });

      const value = subaccount
        ? `${subaccount?.name} (ID ${subaccount?.id})`
        : `Subaccount ${subaccountId}`;
      return { type: 'Subaccount', value, id: subaccountId };
    },
    [subaccounts]
  );

  useEffect(() => {
    setCurrentGroupPage(1);
    if (groupBy === 'subject-campaign') resetCheckboxes();
    checkboxItemsController.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupBy]); // only need to react to groupByChanges

  const getColumnHeaders = useCallback(() => {
    const primaryCol = {
      key: 'group-by',
      label: group.label,
      className: cx(styles.HeaderCell, styles.FirstColumnHeader),
      sortKey: group.keyName
    };

    const metricCols = displayMetrics.map(({ label, key }) => ({
      key,
      label: <Box textAlign="right">{label}</Box>,
      className: cx(styles.HeaderCell, styles.NumericalHeader),
      align: 'right',
      sortKey: key
    }));

    return [primaryCol, ...metricCols];
  }, [group, displayMetrics]);

  const checkboxComponent = useMemo(() => {
    return CheckboxWithLink({
      hasSendingProduct,
      hasD12yProduct,
      hasSendingMetrics,
      hasInboxTrackingMetrics,
      isCampaignSubjectGroupBy: groupBy === 'subject-campaign'
    });
  }, [groupBy, hasSendingMetrics, hasSendingProduct, hasD12yProduct, hasInboxTrackingMetrics]);

  const onPageChange = useCallback(
    (page) => {
      setCurrentGroupPage(page);
    },
    [setCurrentGroupPage]
  );

  return (
    <>
      <Panel marginBottom="-1px">
        <Panel.Section>
          <Columns collapseBelow="sm">
            <GroupByOption
              disabled={status === 'loading' || displayMetrics.length === 0}
              groupBy={groupBy}
              hasSubaccounts={hasSubaccounts}
              onChange={setGroupBy}
            />

            {groupBy && (
              <Column>
                <MultiCheckboxDropdown
                  allowEmpty={false}
                  checkboxes={checkboxes}
                  id="group-by-dropdown"
                  label="Data Sources"
                  screenReaderDirections="Filter the table by the selected checkboxes"
                  checkboxComponent={checkboxComponent}
                />
              </Column>
            )}
          </Columns>
        </Panel.Section>

        {groupBy && <checkboxItemsController.Header />}
      </Panel>
      <div data-id="breakdown-by-table">
        <GroupByTableContent
          group={group}
          displayMetrics={displayMetrics}
          status={status}
          refetch={refetch}
          data={data}
          apiMetrics={apiMetrics}
          getSubaccountFilter={getSubaccountFilter}
          reportOptions={reportOptions}
          checkboxItemsController={checkboxItemsController}
          groupBy={groupBy}
          getColumnHeaders={getColumnHeaders}
          currentPage={currentGroupPage}
          onPageChange={onPageChange}
          isModalOpen={isModalOpen}
          addFilters={addFilters}
          meta={meta}
          closeModal={closeModal}
          metricsToRemove={metricsToRemove}
        />
      </div>
    </>
  );
};

const GroupByTableContent = ({
  group,
  displayMetrics,
  status,
  refetch,
  data,
  apiMetrics,
  getSubaccountFilter,
  reportOptions,
  checkboxItemsController,
  groupBy,
  getColumnHeaders,
  currentPage,
  onPageChange,
  isModalOpen,
  addFilters,
  meta,
  closeModal,
  metricsToRemove
}) => {
  const getRowData = useCallback(
    (row) => {
      const primaryColumnKey = row[group.keyName];
      const isUnattributed = primaryColumnKey === -1;
      const newFilter =
        group.label === 'Subaccount'
          ? getSubaccountFilter(primaryColumnKey)
          : { type: group.label, value: primaryColumnKey };

      const Link = ({ children }) => {
        if (!isUnattributed) {
          const params = {
            from: reportOptions.from.toISOString(),
            to: reportOptions.to.toISOString(),
            precision: reportOptions.precision,
            timezone: reportOptions.timezone
          };
          return (
            <PageLink
              to={getDetailsPageUrl(group.keyName, primaryColumnKey, params)}
              aria-label={`${group.label} ${children}`}
            >
              <TranslatableText>{children}</TranslatableText>
            </PageLink>
          );
        }

        return <TranslatableText>{children}</TranslatableText>;
      };

      const checkBoxId = `checkbox-${newFilter.value}-${groupBy}`;

      const onCheckboxChange = () => {
        checkboxItemsController.toggle({
          id: checkBoxId,
          filter: newFilter
        });
      };

      const primaryCol = (
        <Box width="100%" maxWidth="350px" display="flex">
          <Box alignItems="center" display="flex">
            <Checkbox
              onClick={onCheckboxChange}
              defaultChecked={checkboxItemsController.hasChecked(checkBoxId)}
              id={checkBoxId}
              key={checkBoxId}
            />
          </Box>
          <SendingMetricsRemovalModal
            isModalOpen={isModalOpen && checkSendingMetricsRemovalFilters(meta, newFilter.value)}
            addFilter={addFilters}
            filter={meta}
            onClose={closeModal}
            metricsToRemove={metricsToRemove}
          />
          <Box display="flex" ml="400" alignItems="center">
            <PrimaryColumnText title={newFilter.value}>
              <Link>{shrinkToFit(newFilter.value, 35)}</Link>
            </PrimaryColumnText>
          </Box>
        </Box>
      );

      const metricCols = displayMetrics.map(({ key, unit }) => (
        <Box textAlign="right" key={key}>
          {row[key] !== undefined && row[key] !== null ? (
            <Unit value={row[key]} unit={unit} />
          ) : (
            <EmptyCell />
          )}
        </Box>
      ));

      return [primaryCol, ...metricCols];
    },
    [
      addFilters,
      checkboxItemsController,
      closeModal,
      displayMetrics,
      getSubaccountFilter,
      group,
      groupBy,
      isModalOpen,
      meta,
      metricsToRemove,
      reportOptions
    ]
  );

  if (!group || displayMetrics.length === 0) {
    return null;
  }

  if (status === 'error') {
    return (
      <Panel>
        <Panel.Section>
          <ApiErrorBanner
            reload={refetch}
            status="muted"
            title="Unable to load report"
            message="Please try again"
          />
        </Panel.Section>
      </Panel>
    );
  }

  if (status === 'loading') {
    return <PanelLoading minHeight="250px" />;
  }

  if (!Boolean(data.length) || !Boolean(apiMetrics.length)) {
    return (
      <Panel.LEGACY>
        <Empty message="No results found." />
      </Panel.LEGACY>
    );
  }

  return (
    <TableCollection
      rowKeyName={group.keyName}
      columns={getColumnHeaders()}
      getRowData={getRowData}
      pagination
      defaultPerPage={10}
      rows={data}
      defaultSortColumn={displayMetrics[0].key}
      defaultSortDirection="desc"
      wrapperComponent={tableWrapper}
      currentPage={currentPage}
      onPageChange={onPageChange}
      sortFn={sortMissingToEnd}
    />
  );
};

export default GroupByTable;
