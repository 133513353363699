import { ListBox as MatchboxListBox, ListBoxProps } from '@sparkpost/matchbox';
import React, { forwardRef } from 'react';
import { Box } from 'src/components/matchbox';
import { FIELD_MAX_WIDTH } from 'src/constants';

/**
 * Matchbox ListBox component
 * @see https://design.sparkpost.com/components/listbox/
 */

const ListBox = forwardRef<HTMLInputElement, ListBoxProps>(function ListBox(props, ref) {
  const { maxWidth, ...rest } = props;

  return (
    <Box maxWidth={maxWidth ? maxWidth : FIELD_MAX_WIDTH}>
      <MatchboxListBox ref={ref} {...rest} />
    </Box>
  );
}) as typeof MatchboxListBox & {
  Option: typeof MatchboxListBox.Option;
};

ListBox.displayName = 'ListBox';
ListBox.Option = MatchboxListBox.Option;

export default ListBox;
