import { Page as MatchboxPage, PageProps as MatchboxPageProps } from '@sparkpost/matchbox';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Loading from 'src/components/loading';
import { RUDDERSTACK_EVENTS } from 'src/helpers/rudderstack';
import track from 'src/helpers/track';

/**
 * Matchbox Page component.
 * @see https://design.sparkpost.com/components/page/
 */

type PageProps = MatchboxPageProps & {
  empty?: {
    show: boolean;
    trackingOnly: boolean;
  };
  emptyStateComponent?: React.ElementType;
  loading?: boolean;
};
export default function Page({
  emptyStateComponent: EmptyStateComponent,
  ...props
}: PageProps): JSX.Element {
  const location = useLocation();
  const showEmptyState = EmptyStateComponent && props.empty?.show;
  const emptyStateTrackingOnly = props.empty?.trackingOnly; // don't look at show or EmptyStateComponent, just track with RudderStack

  React.useEffect(() => {
    if ((showEmptyState || emptyStateTrackingOnly) && !props.loading) {
      track(RUDDERSTACK_EVENTS.EMPTY_STATE_LOADED, {
        location: location
      });
    }
  }, [emptyStateTrackingOnly, location, props.loading, showEmptyState]);

  if (props.loading) return <Loading />;

  if (showEmptyState && !emptyStateTrackingOnly) {
    return <EmptyStateComponent />;
  }

  return <MatchboxPage {...props} />;
}

Page.displayName = 'Page';
