import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RUDDERSTACK_EVENTS } from 'src/helpers/rudderstack';
import track from 'src/helpers/track';
import { usePrevious } from 'src/hooks';

type TrackDrawerProps = PropsWithChildren<{ open: boolean; title: string }>;

export const TrackDrawer = ({ open, title, children }: TrackDrawerProps): JSX.Element => {
  const wasOpen = usePrevious(open);
  const location = useLocation();

  useEffect(() => {
    if (!wasOpen && open) {
      track(RUDDERSTACK_EVENTS.DRAWER_OPENED, {
        title: title,
        location: location.pathname
      });
    }

    if (wasOpen && !open) {
      track(RUDDERSTACK_EVENTS.DRAWER_CLOSED, {
        title: title,
        location: location.pathname
      });
    }
  }, [location.pathname, open, title, wasOpen]);

  return <>{children}</>;
};
