import { tokens } from '@sparkpost/design-tokens';
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Footer, Header } from 'src/components/hibana';
import { Drawer, ScreenReaderOnly } from 'src/components/matchbox';
import { MEDIA_QUERY_TOKENS } from 'src/constants';
import { useSidebarContext } from 'src/context/SidebarContext';
import WindowSize, { WindowSizeContext } from 'src/context/WindowSize';
import findRouteByPath from 'src/helpers/findRouteByPath';
import ScrollToTop from './components/ScrollToTop';
import {
  ContainerForRest,
  DesktopSidebarContainer,
  DrawerWrapper,
  MainWithSidebar as Main,
  MaxWidthContainer,
  Wrapper
} from './styles.js';

const noopContainer = ({ children }) => children;

export function AppWithSidebar({ children }) {
  const { pathname } = useLocation();
  const { sidebar, container } = findRouteByPath(pathname);
  const { isSidebarOpen } = useSidebarContext();

  const Container = container || noopContainer;
  useEffect(() => {
    document.body.setAttribute('style', 'overflow-y: hidden');

    return () => document.body.setAttribute('style', 'overflow-y: initial');
  }, []);

  return (
    <WindowSize>
      <Container>
        <Wrapper>
          <Header />
          <Main role="main" tabIndex="-1" id="main-content">
            <SidebarContainer sidebar={sidebar} />
            <ContainerForRest open={isSidebarOpen}>
              <MaxWidthContainer>{children}</MaxWidthContainer>
              <Footer />
            </ContainerForRest>
          </Main>
          <ScrollToTop />
        </Wrapper>
      </Container>
    </WindowSize>
  );
}

function SidebarContainer({ sidebar: Sidebar }) {
  const { isSidebarOpen, getDrawerProps } = useSidebarContext();
  const { screenWidth } = useContext(WindowSizeContext);

  return (
    <>
      <DesktopSidebarContainer open={isSidebarOpen} aria-expanded={isSidebarOpen} data-id="sidebar">
        <Sidebar />
      </DesktopSidebarContainer>

      {screenWidth < MEDIA_QUERY_TOKENS[tokens.mediaQuery_md] && (
        <Drawer {...getDrawerProps()} position="left">
          <DrawerWrapper>
            <Drawer.Header showCloseButton>
              <ScreenReaderOnly>Sidebar</ScreenReaderOnly>
            </Drawer.Header>

            <Sidebar />
          </DrawerWrapper>
        </Drawer>
      )}
    </>
  );
}

export default AppWithSidebar;
