import { Skeleton } from '@sparkpost/matchbox';

/**
 * Skeleton loader primitive
 * @see https://matchbox-libby.netlify.app/?path=Skeleton__box&source=false
 *
 *
 * Skeleton loader for body copy
 * @see https://matchbox-libby.netlify.app/?path=Skeleton__body&source=false
 *
 *
 * Skeleton loader for headings
 * @see https://matchbox-libby.netlify.app/?path=Skeleton__heading&source=false
 */
const MatchboxSkeleton = Skeleton;

export default MatchboxSkeleton;
