import React from 'react';

function MuteIcon({ cx, cy, r }: { cx?: number; cy?: number; r: number }) {
  return (
    <path
      fill="#39444D"
      r={r}
      transform={`translate(${cx ? cx - 6 : ''}, ${cy ? cy : ''})`}
      d="M9 6a3 3 0 00-1.667-2.687v1.474L8.967 6.42C8.987 6.287 9 6.147 9 6zm1.667 0c0 .627-.134 1.213-.36 1.76l1.006 1.007C11.753 7.94 12 7 12 6A5.998 5.998 0 007.333.153v1.374A4.67 4.67 0 0110.667 6zM.847 0L0 .847 3.153 4H0v4h2.667L6 11.333V6.847L8.833 9.68c-.446.347-.946.62-1.5.787v1.373a5.993 5.993 0 002.46-1.207L11.153 12l.847-.847-6-6L.847 0zM6 .667L4.607 2.06 6 3.453V.667z"
    ></path>
  );
}

export default MuteIcon;
