import React, { useCallback } from 'react';
import { Box, Button, ScreenReaderOnly, Text } from 'src/components/matchbox';
import { DEFAULT_PER_PAGE_BUTTONS } from 'src/constants';
import { TranslatableText } from '../text';

type PerPageButtonsProps = {
  onPerPageChange: (buttonAmount: number) => void;
  perPage: number;
  perPageButtons: number[];
  totalCount: number;
};

type PerPageButtonProps = {
  buttonAmount: number;
  onPerPageChange: (buttonAmount: number) => void;
  perPage: number;
};

const PageButton = ({
  buttonAmount,
  perPage,
  onPerPageChange
}: PerPageButtonProps): JSX.Element => {
  const PerPageChange = useCallback(() => {
    return onPerPageChange(buttonAmount);
  }, [buttonAmount, onPerPageChange]);

  return (
    <Button
      color={perPage === buttonAmount ? 'blue' : undefined}
      variant={perPage !== buttonAmount ? 'minimal' : 'primary'}
      useMatchboxVariant={false}
      key={buttonAmount}
      name="collection-per-page-button"
      data-id={`collection-per-page-button-${buttonAmount}`}
      onClick={PerPageChange}
      size="small"
      marginX="100"
      width={[0]}
    >
      <TranslatableText>{buttonAmount}</TranslatableText>
      <ScreenReaderOnly>per page</ScreenReaderOnly>
    </Button>
  );
};

const PerPageButtons = ({
  perPage,
  perPageButtons = DEFAULT_PER_PAGE_BUTTONS,
  onPerPageChange,
  totalCount
}: PerPageButtonsProps) => {
  if (totalCount <= Math.min(...perPageButtons)) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center">
      <Text as="span" fontSize="200" marginRight="200">
        Per Page
      </Text>
      <Box marginRight="200">
        <Button.Group>
          {perPageButtons.map((buttonAmount) => (
            <PageButton
              key={`PageButton-${buttonAmount}`}
              buttonAmount={buttonAmount}
              perPage={perPage}
              onPerPageChange={onPerPageChange}
            />
          ))}
        </Button.Group>
      </Box>
    </Box>
  );
};

export default PerPageButtons;
