import React from 'react';

import { DoNotDisturbOn } from '@sparkpost/matchbox-icons';
import { Table, ScreenReaderOnly, Stack, LabelValue, Button, Box } from 'src/components/matchbox';
import { ScreenReaderExclude } from 'src/components/a11y';
import { DisplayDate, Empty } from 'src/components';
import { PageLink } from 'src/components/links';
import { formatDateTime } from '@sparkpost/report-builder/helpers/date';
import { TranslatableText } from 'src/components/text';

export function MonitorsTable(props) {
  const { table, handleDelete } = props;
  const { headerGroups, page, prepareRow, rows } = table;

  if (rows.length === 0) {
    return <Empty message="No results found." />;
  }
  return (
    <Table>
      <ScreenReaderOnly as="thead">
        {headerGroups.map((headerGroup) => {
          return (
            <Table.Row header {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <Table.HeaderCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          );
        })}
      </ScreenReaderOnly>
      <tbody>
        {page.map((row, index) => {
          prepareRow(row);
          const {
            resource,
            active_listing_count,
            total_listing_count,
            last_listed_at,
            id,
            assignment
          } = row.original;
          const formattedDate = last_listed_at ? formatDateTime.useMomentInput(last_listed_at) : '';

          return (
            <Table.Row key={`monitored-list-table-row-${index}`}>
              {/* Main Resource details */}
              <Table.Cell>
                <Stack space="100">
                  <Box>
                    <PageLink to={`/signals/blocklist/incidents?search=${resource}`}>
                      <TranslatableText>{resource}</TranslatableText>
                    </PageLink>
                  </Box>
                  {assignment && (
                    <ScreenReaderExclude>
                      <Box fontSize="200">{assignment}</Box>
                    </ScreenReaderExclude>
                  )}

                  <ScreenReaderExclude>
                    <Box fontSize="200">
                      {last_listed_at ? (
                        <div>
                          <DisplayDate timestamp={last_listed_at} formattedDate={formattedDate} />
                        </div>
                      ) : (
                        <div>No Incidents</div>
                      )}
                    </Box>
                  </ScreenReaderExclude>
                </Stack>
              </Table.Cell>

              <ScreenReaderOnly as={Table.Cell}>{assignment}</ScreenReaderOnly>

              <ScreenReaderOnly as={Table.Cell}>
                {last_listed_at ? (
                  <div>
                    <DisplayDate timestamp={last_listed_at} formattedDate={formattedDate} />
                  </div>
                ) : (
                  <div>No Incidents</div>
                )}
              </ScreenReaderOnly>

              <Table.Cell>
                <ScreenReaderExclude as="div">
                  <LabelValue>
                    <LabelValue.Label>
                      <Box textAlign="right">Active Listing Count</Box>
                    </LabelValue.Label>
                    <LabelValue.Value>
                      <Box textAlign="right">{active_listing_count}</Box>
                    </LabelValue.Value>
                  </LabelValue>
                </ScreenReaderExclude>
                <ScreenReaderOnly>{active_listing_count}</ScreenReaderOnly>
              </Table.Cell>

              <Table.Cell>
                <ScreenReaderExclude as="div">
                  <LabelValue>
                    <LabelValue.Label>
                      <Box textAlign="right">Total Listing Count</Box>
                    </LabelValue.Label>
                    <LabelValue.Value>
                      <Box textAlign="right">{total_listing_count}</Box>
                    </LabelValue.Value>
                  </LabelValue>
                </ScreenReaderExclude>
                <ScreenReaderOnly>{total_listing_count}</ScreenReaderOnly>
              </Table.Cell>

              {/* Button to disable resource */}
              <Table.Cell>
                <Box textAlign="right">
                  <Button
                    variant="minimal"
                    onClick={() => {
                      handleDelete({ resource, id });
                    }}
                  >
                    <span>Stop Monitoring&nbsp;</span>
                    <Button.Icon as={DoNotDisturbOn} />
                  </Button>
                </Box>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </tbody>
    </Table>
  );
}
