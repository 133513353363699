import React, { Component } from 'react';
import Recaptcha from 'react-recaptcha';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';

import config from 'src/appConfig';
import { ExternalLink } from 'src/components/links';
import { Box, Button, Checkbox, Stack } from 'src/components/matchbox';
import { CheckboxWrapper, TextFieldWrapper } from 'src/components/reduxFormWrappers';
import { TranslatableText } from 'src/components/text';
import { Form } from 'src/components/tracking';
import { FORMS, LINKS } from 'src/constants';
import { email, endsWithWhitespace, minLength, required } from 'src/helpers/validation';
const { recaptcha } = config;

/** Recaptcha flow
 *
 * UX:
 * Disable form until recaptcha is ready
 * Challenge with recaptcha when Create Account button is clicked
 * Submits form data to api upon successful completetion of recaptcha challenge


 * Code
 * Recaptcha script is loaded in JoinPage
 * When script is loaded, Recaptcha component will initialize it and bind to different callbacks
 * ref: reference to recaptcha instance
 * onloadCallback: it's triggered upon Recaptcha's dependencies (global) is completely loaded. we enable form at that point
 * executeRecaptcha: this is triggered, when user clicks Create Account button. captcha challenge is presented to user and submitted to Google for validation
 * verifyCallback: this is triggered upon user completes recaptcha challenge successfuly. we pass the data we've received
 */

export class JoinForm extends Component {
  state = {
    reCaptchaReady: false,
    reCaptchaInstance: null,
    recaptcha_response: null,
    recaptchaPass: false,
    showPassword: false
  };

  onSubmit = (formValues) => {
    this.props.onSubmit({
      ...formValues,
      recaptcha_response: this.state.recaptcha_response,
      recaptcha_type: 'checkbox',
      recaptcha_version: 'enterprise'
    });
  };

  reCaptchaLoaded = () => {
    this.setState({ reCaptchaReady: true });
  };

  recaptchaVerifyCallback = (response) => {
    this.setState({ recaptcha_response: response, recaptchaPass: true });
  };

  recaptchaExpiredCallback = (response) => {
    this.setState({ recaptcha_response: response, recaptchaPass: false });
  };

  render() {
    const { loading, pristine, invalid, submitting } = this.props;

    const { reCaptchaReady, showPassword, recaptchaPass } = this.state;

    const pending = loading || submitting || !reCaptchaReady;
    const hideShowText = showPassword ? 'Hide' : 'Show';
    const loadingCreateText = loading ? 'Loading' : 'Create Account';

    const IS_CYPRESS_RUNTIME = Boolean(
      window?.Cypress?.cy?.id && window?.Cypress?.env().RECAPTCHA_KEY
    );

    const shouldDisableFromRecaptcha = Boolean(!IS_CYPRESS_RUNTIME && !recaptchaPass);

    const recaptchaKey = IS_CYPRESS_RUNTIME ? window?.Cypress?.env().RECAPTCHA_KEY : recaptcha.key;

    return (
      <Form id="sign-up-form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Stack>
          <Field
            name="first_name"
            component={TextFieldWrapper}
            label="First Name"
            autoComplete="given-name"
            validate={required}
            disabled={pending}
            data-sensitive={true}
          />
          <Field
            name="last_name"
            component={TextFieldWrapper}
            label="Last Name"
            autoComplete="family-name"
            validate={required}
            disabled={pending}
            data-sensitive={true}
          />
          <Field
            validate={required}
            name="company_name"
            component={TextFieldWrapper}
            label="Company"
            disabled={pending}
            autoComplete="organization"
            data-sensitive={true}
          />
          <Field
            name="email"
            component={TextFieldWrapper}
            label="Email"
            validate={[required, email]}
            disabled={pending}
            autoComplete="username email"
            data-sensitive={true}
          />
          <Field
            name="password"
            component={TextFieldWrapper}
            label="Password"
            validate={[required, minLength(12), endsWithWhitespace]}
            disabled={!reCaptchaReady || loading}
            type={showPassword ? 'text' : 'password'}
            autoComplete="new-password"
            connectRight={
              <Button
                variant="connected"
                data-id="show-password-button"
                onClick={() => this.setState({ showPassword: !showPassword })}
              >
                <span>{hideShowText}</span>
              </Button>
            }
            data-sensitive={true}
          />

          <Checkbox.Group>
            <Field
              name="email_opt_in"
              id="email_opt_in"
              component={CheckboxWrapper}
              disabled={pending}
              data-track={true}
              type="checkbox"
              label={
                <span>Sign up for product updates, customer event invitations, and more!</span>
              }
            />

            <Field
              name="tou_accepted"
              id="tou_accepted"
              component={CheckboxWrapper}
              type="checkbox"
              disabled={pending}
              validate={required}
              data-track={true}
              label={
                <TranslatableText>
                  I agree to the <ExternalLink to={LINKS.TOU}>Terms of Use</ExternalLink> and the{' '}
                  <ExternalLink to={LINKS.MICROENTERPRISE_WAIVER}>
                    Microenterprise Waiver
                  </ExternalLink>
                </TranslatableText>
              }
            />
          </Checkbox.Group>
          <Box>
            <Recaptcha
              sitekey={recaptchaKey}
              render="explicit"
              onloadCallback={this.reCaptchaLoaded}
              verifyCallback={this.recaptchaVerifyCallback}
              expiredCallback={this.recaptchaExpiredCallback}
            />
          </Box>
          <Box>
            <Button
              id="submit"
              type="submit"
              variant="primary"
              disabled={pending || pristine || invalid || shouldDisableFromRecaptcha}
            >
              <span>{loadingCreateText}</span>
            </Button>
          </Box>
        </Stack>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  initialValues: {
    tou_accepted: false,
    email_opt_in: false
  },
  loading: state.account.createLoading || state.auth.loginPending,
  formValues: getFormValues(FORMS.JOIN)(state)
});

const RegisterAccountForm = reduxForm({ form: FORMS.JOIN })(JoinForm);
export default connect(mapStateToProps, {})(RegisterAccountForm);
