import { tokens } from '@sparkpost/design-tokens';
import { Telegram } from '@sparkpost/matchbox-icons';
import React, { forwardRef, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { showAlert } from 'src/actions/globalAlert';
import { testScheduledReport } from 'src/actions/reports';
import { AsyncActionModal, ButtonWrapper, RadioButtonGroup } from 'src/components';
import {
  Box,
  Button,
  Inline,
  LabelValue,
  Layout,
  Panel,
  Radio,
  Select,
  Stack,
  TextField
} from 'src/components/matchbox';
import { ComboBoxTypeaheadController } from 'src/components/reactHookFormControllers';
import { Uppercase } from 'src/components/text';
import { ComboBoxTypeahead } from 'src/components/typeahead/ComboBoxTypeaheadV2';
import { TimezoneTypeahead } from 'src/components/typeahead/TimezoneTypeahead';
import useModal from 'src/hooks/useModal';
import { DAY_OF_WEEK_OPTIONS, WEEK_OPTIONS } from '../constants/scheduledReports';
import { hasAtLeastOneRecipient, recipientUserToString } from '../helpers/scheduledReports';

export const ScheduledReportDetailsForm = ({
  formControls,
  isUpdatingScheduledReport,
  disabled,
  report,
  users
}) => {
  const history = useHistory();
  const [testSendRecipients, setTestSendRecipients] = useState([]);
  const { testScheduledReportStatus } = useSelector(({ reports }) => reports);

  const { closeModal, isModalOpen, openModal } = useModal();
  const dispatch = useDispatch();

  const { control, formState, register, setValue, setError } = formControls;
  const { errors } = formState;
  const formValues = useWatch({ control, name: ['name', 'subject'] });

  const onSendTest = () => {
    const [name, subject] = formValues;
    if (!name || !subject) {
      if (!name) {
        setError('name', { message: 'Required' });
      }
      if (!subject) {
        setError('subject', { message: 'Required' });
      }
      closeModal();
      return dispatch(
        showAlert({
          type: 'error',
          message: `Please fill out "Scheduled Report Name" and "Email Subject" before sending test`
        })
      );
    }
    dispatch(
      testScheduledReport({
        reportId: report.id,
        name: name,
        recipients: testSendRecipients.map(({ username }) => username),
        subject: subject
      })
    ).then(() => {
      dispatch(
        showAlert({
          type: 'success',
          message: `Successfully sent test report`
        })
      );
      closeModal();
    });
  };

  const Typeahead = forwardRef((props) => (
    <ComboBoxTypeaheadController
      disabled={disabled}
      error={errors.recipients && 'At least 1 recipient must be selected'}
      id="to-address"
      itemToString={recipientUserToString}
      label="Send To"
      name="recipients"
      results={users}
      setValue={setValue}
      data-track={true}
      {...props}
    />
  ));

  return (
    <>
      <Layout>
        <Layout.Section annotated>
          <Layout.SectionTitle>Details</Layout.SectionTitle>
        </Layout.Section>
        <Layout.Section>
          <Panel>
            <Panel.Section>
              <TextField
                disabled={disabled}
                data-track={true}
                label="Scheduled Report Name"
                name="name"
                helpText="Title for the scheduling of this report"
                id="scheduled-report-name"
                error={errors.name?.message}
                {...register('name', { required: 'Required' })}
              />
            </Panel.Section>
            <Panel.Section>
              <Inline space="800">
                <div>
                  <LabelValue>
                    <LabelValue.Label>Report</LabelValue.Label>
                    <LabelValue.Value>{report.name}</LabelValue.Value>
                  </LabelValue>
                </div>
                <div>
                  <LabelValue>
                    <LabelValue.Label>From Address</LabelValue.Label>
                    <LabelValue.Value>reports@sparkpost.com</LabelValue.Value>
                  </LabelValue>
                </div>
              </Inline>
            </Panel.Section>
            <Panel.Section>
              <TextField
                disabled={disabled}
                data-track={true}
                label="Email Subject"
                name="subject"
                helpText="Text which will appear as subject line in report email"
                id="email-subject"
                error={errors.subject?.message}
                {...register('subject', { required: 'Required' })}
              />
            </Panel.Section>
            <Panel.Section>
              <Panel.Action onClick={openModal} disabled={disabled}>
                Send Test
                <Button.Icon as={Telegram} />
              </Panel.Action>
              <Controller
                control={control}
                render={({ field }) => <Typeahead {...field} />}
                name="recipients"
                rules={{ validate: hasAtLeastOneRecipient }}
              />
            </Panel.Section>
            {isUpdatingScheduledReport && (
              <Panel.Section>
                <ButtonWrapper>
                  <Button type="submit" variant="primary" disabled={!formState.isDirty || disabled}>
                    Update Details
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => history.push(`/signals/analytics?report=${report.id}`)}
                    disabled={disabled}
                  >
                    Cancel
                  </Button>
                </ButtonWrapper>
              </Panel.Section>
            )}
          </Panel>
        </Layout.Section>
      </Layout>
      <AsyncActionModal
        disabled={testSendRecipients.length < 1}
        open={isModalOpen}
        actionVerb="Send Test"
        isPending={testScheduledReportStatus === 'loading'}
        onAction={onSendTest}
        onCancel={closeModal}
        title="Send Test Report Email"
      >
        <Box height="160px">
          <ComboBoxTypeahead
            id="test-send"
            itemToString={recipientUserToString}
            label="Send To"
            onChange={setTestSendRecipients}
            results={users}
            value={testSendRecipients}
            maxNumberOfResults={10}
          />
        </Box>
      </AsyncActionModal>
    </>
  );
};

export const ScheduledReportTimingForm = ({
  formControls,
  isUpdatingScheduledReport,
  disabled,
  report
}) => {
  const history = useHistory();

  const { control, formState, register, setValue } = formControls;
  const { errors } = formState;
  const [periodFormValue, timezoneFormValue, timingFormValue] = useWatch({
    control,
    name: ['period', 'timezone', 'timing']
  });

  return (
    <Layout>
      <Layout.Section annotated>
        <Layout.SectionTitle>Send Timing</Layout.SectionTitle>
      </Layout.Section>
      <Layout.Section>
        <Stack>
          <Panel>
            <Panel.Section>
              <Radio.Group label="Send Report">
                <Radio
                  id="daily"
                  disabled={disabled}
                  {...register('timing')}
                  label="Daily"
                  value="daily"
                  name="timing"
                  data-track={true}
                />
                <Radio
                  id="weekly"
                  disabled={disabled}
                  {...register('timing')}
                  label="Weekly"
                  value="weekly"
                  name="timing"
                  data-track={true}
                />
                <Radio
                  id="monthly"
                  disabled={disabled}
                  {...register('timing')}
                  label="Monthly"
                  value="monthly"
                  name="timing"
                  data-track={true}
                />
              </Radio.Group>
            </Panel.Section>
            <Panel.Section>
              <Inline alignY="top">
                <Select
                  id="week"
                  {...register('week')}
                  label="Week"
                  name="week"
                  options={WEEK_OPTIONS}
                  disabled={timingFormValue === 'weekly' || timingFormValue === 'daily' || disabled}
                  data-track={true}
                />
                <Select
                  id="day"
                  {...register('day')}
                  label="Day"
                  name="day"
                  options={DAY_OF_WEEK_OPTIONS}
                  disabled={timingFormValue === 'daily' || disabled}
                  data-track={true}
                />
                <TextField
                  disabled={disabled}
                  label="Time"
                  name="time"
                  id="time"
                  data-track={true}
                  error={errors.time?.message}
                  maxWidth="12rem"
                  placeholder="hh:mm"
                  connectRight={
                    <RadioButtonGroup id="period" label="Grouping Type">
                      <RadioButtonGroup.Button
                        {...register('period')}
                        id="am"
                        disabled={disabled}
                        name="period"
                        checked={periodFormValue === 'AM'}
                        onChange={() => setValue('period', 'AM', { shouldDirty: true })}
                        value="AM"
                        data-track={true}
                      >
                        <Uppercase>AM</Uppercase>
                      </RadioButtonGroup.Button>
                      <RadioButtonGroup.Button
                        {...register('period')}
                        id="pm"
                        disabled={disabled}
                        name="period"
                        checked={periodFormValue === 'PM'}
                        onChange={() => setValue('period', 'PM', { shouldDirty: true })}
                        value="PM"
                        data-track={true}
                      >
                        <Uppercase>PM</Uppercase>
                      </RadioButtonGroup.Button>
                    </RadioButtonGroup>
                  }
                  {...register('time', {
                    required: 'Required',
                    pattern: {
                      value: /^(1[0-2]|0?[1-9]):[0-5][0-9]$/,
                      message: 'Invalid time format, should be hh:mm 12 hour format'
                    }
                  })}
                />
                <Box minWidth={tokens.sizing_1000}>
                  <input type="hidden" {...register('timezone')} name="timezone" />
                  <TimezoneTypeahead
                    disabled={disabled}
                    initialValue={timezoneFormValue}
                    data-track={true}
                    onChange={({ value }) => setValue('timezone', value, { shouldDirty: true })}
                  />
                </Box>
              </Inline>
            </Panel.Section>
            {isUpdatingScheduledReport && (
              <Panel.Section>
                <ButtonWrapper>
                  <Button type="submit" variant="primary" disabled={!formState.isDirty || disabled}>
                    Update Timing
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => history.push(`/signals/analytics?report=${report.id}`)}
                    disabled={disabled}
                  >
                    Cancel
                  </Button>
                </ButtonWrapper>
              </Panel.Section>
            )}
          </Panel>
          {!isUpdatingScheduledReport && (
            <ButtonWrapper>
              <Button type="submit" variant="primary" disabled={!formState.isValid || disabled}>
                Schedule Report
              </Button>
              <Button
                variant="secondary"
                onClick={() => history.push(`/signals/analytics?report=${report.id}`)}
                disabled={disabled}
              >
                Cancel
              </Button>
            </ButtonWrapper>
          )}
        </Stack>
      </Layout.Section>
    </Layout>
  );
};
