import React from 'react';
import { Button, Drawer } from 'src/components/matchbox';
import { FirstPage, FolderOpen } from '@sparkpost/matchbox-icons';
import { TranslatableText } from 'src/components/text';
import _ from 'lodash';
import { SidebarFooter, FooterButton } from '../../sidebarStyles';

const FooterSidebar = ({ isMobileVersion, handleOpenAllReports, closeSidebar }) => {
  if (isMobileVersion)
    return (
      <Drawer.Footer>
        <FooterButton onClick={handleOpenAllReports} variant="minimal" first={true} color="blue">
          <TranslatableText>View All Reports&nbsp;</TranslatableText>
          <Button.Icon as={FolderOpen} size={22} />
        </FooterButton>

        <FooterButton onClick={closeSidebar} variant="minimal" color="blue">
          <TranslatableText>Collapse&nbsp;</TranslatableText>
          <Button.Icon as={FirstPage} size={22} />
        </FooterButton>
      </Drawer.Footer>
    );
  return (
    <SidebarFooter paddingY={400}>
      <FooterButton
        onClick={handleOpenAllReports}
        variant="minimal"
        first={true}
        color="blue"
        paddingLeft="400"
      >
        <TranslatableText> View All Reports&nbsp;</TranslatableText>
        <Button.Icon as={FolderOpen} size={22} />
      </FooterButton>

      <FooterButton onClick={closeSidebar} variant="minimal" color="blue" paddingLeft="400">
        <TranslatableText>Collapse&nbsp;</TranslatableText>
        <Button.Icon as={FirstPage} size={22} />
      </FooterButton>
    </SidebarFooter>
  );
};
export default FooterSidebar;
