import _ from 'lodash';
import config from 'src/appConfig';
import { createSelector } from 'reselect';
import { selectDefaultTemplateOptions } from 'src/selectors/account';
import { getDomains, isVerified } from 'src/selectors/sendingDomains';
import { hasSubaccounts, selectSubaccountIdFromProps } from 'src/selectors/subaccounts';
import { filterTemplatesBySubaccount } from 'src/helpers/templates';
import { getSubaccountsIndexedById, getSubaccountName } from './subaccounts';

export const getDraftTemplateById = (state, id) => _.get(state, ['templates', 'byId', id, 'draft']);
export const getPublishedTemplateById = (state, id) =>
  _.get(state, ['templates', 'byId', id, 'published']);

export const getTemplates = (state) => state.templates.list;
export const selectTemplates = createSelector(
  [getTemplates, getSubaccountsIndexedById],
  (templates, subaccounts) =>
    templates.map((template) => ({
      ...template,
      subaccount_name: getSubaccountName(subaccounts, template['subaccount_id'])
    }))
);
export const selectPublishedTemplates = (state) =>
  // eslint-disable-next-line lodash/prop-shorthand
  _.filter(state.templates.list, (template) => template.has_published);

const createTemplateSelector = (getter) =>
  createSelector([getter, selectDefaultTemplateOptions], (template, defaultOptions) => {
    if (!template) {
      return;
    }

    const templateContent = _.isEmpty(template.content)
      ? undefined
      : // Remove falsy values from the content object
        // See: https://stackoverflow.com/questions/30812765/how-to-remove-undefined-and-null-values-from-an-object-using-lodash#answer-31209300
        { content: _.omitBy(template.content, _.isNil) };

    return {
      ...template,
      ...templateContent,
      options: { ...defaultOptions, ...template.options }
    };
  });

export const selectDraftTemplateById = createTemplateSelector(getDraftTemplateById);
export const selectPublishedTemplateById = createTemplateSelector(getPublishedTemplateById);

export const selectDraftTemplatePreview = (state, id, defaultValue) =>
  state.templates.contentPreview.draft[id] || defaultValue;
export const selectPublishedTemplatePreview = (state, id, defaultValue) =>
  state.templates.contentPreview.published[id] || defaultValue;
export const selectPreviewLineErrors = (state) =>
  _.get(state, 'templates.contentPreview.error.response.data.errors', []);

export const selectDefaultTestData = () => JSON.stringify(config.templates.testData, null, 2);
export const selectTemplateTestData = (state) =>
  JSON.stringify(state.templates.testData || config.templates.testData, null, 2);

export const selectAndCloneDraftById = createSelector([selectDraftTemplateById], (draft) => {
  if (!draft) {
    return;
  }
  return { ...draft, name: `${draft.name} Copy`, id: `${draft.id}-copy` };
});

// Selects sending domains for From Email typeahead
export const selectDomainsBySubaccount = createSelector(
  [getDomains, selectSubaccountIdFromProps],
  (domains, subaccountId) =>
    _.filter(domains, (domain) => {
      if (!isVerified(domain)) {
        return false;
      }

      return subaccountId
        ? domain.shared_with_subaccounts || domain.subaccount_id === Number(subaccountId)
        : !domain.subaccount_id;
    })
);

/**
 * Returns domains by subaccount, but if no verified domain exists, return sparkpost default domain
 */
export const selectDomainsBySubaccountWithDefault = createSelector(
  [selectDomainsBySubaccount],
  (domains) => {
    if (!domains || !domains.length) {
      return [{ domain: config.sandbox.domain }];
    } else {
      return domains;
    }
  }
);

/**
 * Selects subaccountId from the selector's second arguement, in place of props
 */
export const selectSubaccountId = (state, subaccountId) => subaccountId;

/**
 * Selects published templates, filtered by provided subaccount
 * @param state  redux state
 * @param subaccountId
 */
export const selectPublishedTemplatesBySubaccount = createSelector(
  [selectPublishedTemplates, selectSubaccountId, hasSubaccounts],
  (templates, subaccountId, subaccountsExist) =>
    filterTemplatesBySubaccount({ templates, subaccountId, hasSubaccounts: subaccountsExist })
);

/**
 * Prepare templates collection for listing with published and draft as separate item.
 * @return array
 */
export const selectTemplatesForListTable = createSelector([selectTemplates], (templates) => {
  return templates.map((template) => {
    const isPublished = template.published;
    const hasPublished = template.has_published;
    const hasDraft = template.has_draft;

    if (hasPublished) {
      return {
        ...template,
        list_name: template.name,
        list_status: hasDraft && !isPublished ? 'published_with_draft' : 'published'
      };
    } else {
      return {
        ...template,
        list_name: template.name,
        list_status: 'draft'
      };
    }
  });
});
