import { tokens } from '@sparkpost/design-tokens';
import { InfoOutline } from '@sparkpost/matchbox-icons';
import { formatDate } from '@sparkpost/report-builder/helpers/date';
import React, { Fragment } from 'react';
import { ActiveFilters, LabelValueLoadingSkeleton } from 'src/components';
import {
  Box,
  Column,
  Columns,
  LabelValue,
  Layout,
  Panel,
  Skeleton,
  Tag,
  Tooltip
} from 'src/components/matchbox';
import { TranslatableText } from 'src/components/text';
import { snakeToFriendly } from 'src/helpers/string';
import { GROUP_BY_CONFIG } from 'src/pages/analyticsReport/constants';
import { SubaccountFilters } from '../NewCreatePage/AlertSummary/HealthScoreAlertSummary';
import { HEALTHSCORE_EVALUTATION_LABELS, metricsAndUnits } from '../NewCreatePage/constants';
import { AlertSeverityTag } from '../components/RecentIncidents/styles';
import { ALERT_TYPES } from '../constants/alertTypes';
import { operatorDictionary } from './constants';
import { StyledQuote } from './styles';

const MetricsAlertDetails = ({ groupBy, metric, conditions, displayPlaceholder, filters }) => (
  <>
    <Box>
      <TranslatableText>The alert is triggered when </TranslatableText>
      <Tag color="blue">{snakeToFriendly(metric)}</Tag>
      <TranslatableText> is </TranslatableText>
      <Tag color="blue">{operatorDictionary[conditions.operator]}</Tag>{' '}
      <Tag color="blue">{`${conditions.value} ${displayPlaceholder}`}</Tag>
      <TranslatableText> at the end of a </TranslatableText>
      <Tag color="blue">1 {conditions.precision}</Tag> period.
    </Box>
    {filters?.groupings && (
      <Box>
        <StyledQuote>{filters && <ActiveFilters filters={filters.groupings} />}</StyledQuote>
      </Box>
    )}
    {groupBy && (
      <Box>
        <TranslatableText>And is broken down by </TranslatableText>
        <Tag color="blue">{GROUP_BY_CONFIG[groupBy].label}</Tag>
      </Box>
    )}
  </>
);

const AccountUsageDetails = ({ conditions, filters }) => (
  <>
    <Box>
      <TranslatableText>The alert is triggered when </TranslatableText>
      <Tag color="blue">Usage</Tag>
      <TranslatableText> is </TranslatableText>
      <Tag color="blue">{conditions.value}%</Tag>
      <TranslatableText> of the montlhy limit.</TranslatableText>
    </Box>
    <Box mt="300">
      <StyledQuote>{filters && <ActiveFilters filters={filters.groupings} />}</StyledQuote>
    </Box>
  </>
);

const BlocklistsDetails = ({ blocklistFilters }) => {
  return (
    <>
      <Box>
        <TranslatableText>The alert is triggered when a </TranslatableText>
        <Tag color="blue">Blocklist Incident</Tag>
        <TranslatableText> is found in </TranslatableText>
        {blocklistFilters.blocklist_providers.map((blocklist, index) => (
          <Fragment key={`alert-blocklist-${blocklist}`}>
            <Tag color="blue">{blocklist}</Tag>
            {index + 1 < blocklistFilters.blocklist_providers.length && (
              <TranslatableText>, </TranslatableText>
            )}
          </Fragment>
        ))}
        {blocklistFilters.blocklist_resources && (
          <>
            <TranslatableText> for </TranslatableText>
            {blocklistFilters.blocklist_resources.map((resource, index) => (
              <Fragment key={`alert-resource-${resource}`}>
                <Tag color="blue">{resource}</Tag>
                {index + 1 < blocklistFilters.blocklist_resources.length && (
                  <TranslatableText>, </TranslatableText>
                )}
              </Fragment>
            ))}
          </>
        )}
      </Box>
    </>
  );
};

const HealthScoreDetails = ({ conditions, filters, any_subaccount, subaccounts }) => {
  const SubaccountBreakdown = () => {
    const facetValues = filters?.facet_values || [];
    return (
      <Box mt="-12px">
        {filters?.facet_name && (
          <TranslatableText>
            and is broken down by <Tag color="blue">{filters.facet_name}</Tag>
            {' equals to '}
            {facetValues.map((facet) => (
              <Tag color="gray">{facet}</Tag>
            ))}
          </TranslatableText>
        )}
      </Box>
    );
  };

  return (
    <>
      <Box>
        <TranslatableText>The alert is triggered when </TranslatableText>
        <Tag color="blue">Health Score</Tag>
        <TranslatableText> has a </TranslatableText>
        <Tag color="blue">{HEALTHSCORE_EVALUTATION_LABELS[conditions.source]}</Tag>{' '}
        <Tag color="blue">{operatorDictionary[conditions.operator]}</Tag>{' '}
        <Tag color="blue">{`${conditions.value} `}</Tag>
      </Box>
      <SubaccountFilters subaccounts={subaccounts} any_subaccount={any_subaccount} />
      <SubaccountBreakdown />
    </>
  );
};

export default function AlertDetails({ alert, loading, showDetailsSection }) {
  const columnsLength = showDetailsSection ? 4 : 5;

  if (loading) {
    return (
      <Layout.Section>
        <Panel>
          {!showDetailsSection && <Panel.SubHeader>Details</Panel.SubHeader>}
          <Panel.Section>
            <LabelValueLoadingSkeleton
              labelValues={[
                { value: 'Alert Name', width: 1 / 6 },
                { value: 'Date Created', width: 1 / 6 },
                { value: 'Last Incident', width: 1 / 6 },
                {
                  value: (
                    <Tooltip
                      content="Severity Level is a user-designated label to categorize the urgency for which an alert should be prioritized. It does not impact how the alert performs."
                      id="severity-level-tooltip"
                    >
                      Severity Level
                    </Tooltip>
                  ),
                  width: 1 / 6
                },
                { value: 'Notifications', width: 1 / 6 }
              ]}
            />
          </Panel.Section>
          <Panel.SubHeader>Alert Summary</Panel.SubHeader>
          <Panel.Section>
            <Skeleton.Body />
          </Panel.Section>
        </Panel>
      </Layout.Section>
    );
  }

  const {
    name,
    created,
    subaccount,
    last_triggered: lastTriggered,
    severity,
    status,
    alert_configuration: alertConfiguration
  } = alert;

  const { metric, conditions, filters, any_subaccount, subaccounts, group_by } = alertConfiguration;
  const displayPlaceholder = metricsAndUnits.find((el) => el.key === metric)?.placeholder;
  const subAccountValue = subaccount === 0 ? 'Primary Account' : 'Subaccount';

  const AlertDetailsBlock = () => {
    switch (alert.alert_type) {
      case ALERT_TYPES.blocklistings:
        return <BlocklistsDetails blocklistFilters={filters} />;
      case ALERT_TYPES.sending_limit_alert:
        return <AccountUsageDetails conditions={conditions} filters={filters} />;
      case ALERT_TYPES.health_score:
        return (
          <HealthScoreDetails
            conditions={conditions}
            filters={filters}
            any_subaccount={any_subaccount}
            subaccounts={subaccounts}
          />
        );
      default:
        return (
          <MetricsAlertDetails
            metric={metric}
            conditions={conditions}
            displayPlaceholder={displayPlaceholder}
            filters={filters}
            groupBy={group_by}
          />
        );
    }
  };

  return (
    <Layout.Section>
      <Panel>
        {!showDetailsSection && <Panel.SubHeader>Details</Panel.SubHeader>}
        <Panel.Section>
          <Columns>
            <Column width={1 / columnsLength}>
              <LabelValue>
                <LabelValue.Label>Alert Name</LabelValue.Label>
                <LabelValue.Value>{name}</LabelValue.Value>
              </LabelValue>
            </Column>
            <Column width={1 / columnsLength}>
              <LabelValue.Label>Date Created</LabelValue.Label>
              <LabelValue.Value>{formatDate(created)}</LabelValue.Value>
            </Column>
            {showDetailsSection && (
              <Column width={1 / columnsLength}>
                <LabelValue.Label>Subaccount Assignment</LabelValue.Label>
                <LabelValue.Value>
                  <TranslatableText>{subAccountValue}</TranslatableText>
                </LabelValue.Value>
              </Column>
            )}
            {!showDetailsSection && (
              <Column width={1 / columnsLength}>
                <LabelValue.Label>Last Incident</LabelValue.Label>
                <LabelValue.Value>{lastTriggered && formatDate(lastTriggered)}</LabelValue.Value>
              </Column>
            )}
            <Column width={1 / columnsLength}>
              <Tooltip
                content="Severity Level is a user-designated label to categorize the urgency for which an alert should be prioritized. It does not impact how the alert performs."
                id="severity-level-tooltip"
              >
                <LabelValue.Label>
                  Severity Level <InfoOutline fill={tokens.color_blue_700} />
                </LabelValue.Label>
              </Tooltip>
              <LabelValue.Value>
                <AlertSeverityTag severity={severity} />
              </LabelValue.Value>
            </Column>
            {!showDetailsSection && (
              <Column width={1 / columnsLength}>
                <LabelValue.Label>Notifications</LabelValue.Label>
                <Box>{status && <Tag>{snakeToFriendly(status)}</Tag>}</Box>
              </Column>
            )}
          </Columns>
          {showDetailsSection && (
            <Box mt="500">
              <LabelValue.Label>Alert Summary</LabelValue.Label>
              <LabelValue.Value>
                <AlertDetailsBlock />
              </LabelValue.Value>
            </Box>
          )}
        </Panel.Section>
        {!showDetailsSection && (
          <Panel.Section>
            <AlertDetailsBlock />
          </Panel.Section>
        )}
      </Panel>
    </Layout.Section>
  );
}
