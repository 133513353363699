import React from 'react';

// COMPONENTS
import { Box, Button, Panel, Stack, Text } from 'src/components/matchbox';
import { TranslatableText } from 'src/components/text';

// ICONS
import { Refresh } from '@sparkpost/matchbox-icons';

export const BatchTabLayout = ({
  batchesLoading,
  table,
  pagination,
  lastUpdateText,
  onRefreshBatches
}) => {
  const buttonText = batchesLoading ? 'Refreshing...' : 'Refresh Batches';

  return (
    <Stack>
      <Panel>
        <Panel.Section>
          <Box justifyContent="flex-end" display="flex" alignItems="center" gridGap="12px">
            <Text color="gray.700" pb="100">
              Data last updated <TranslatableText>{lastUpdateText}</TranslatableText>
            </Text>

            <Button variant="text" disabled={batchesLoading} onClick={onRefreshBatches}>
              {buttonText}
              <Button.Icon as={Refresh} />
            </Button>
          </Box>
        </Panel.Section>

        {table}
      </Panel>

      {pagination}
    </Stack>
  );
};
