import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isSubaccountUser as isSubaccountUserSelector } from 'src/helpers/conditions/user';
import { AUTOMATIC_SEEDING_ROUTE } from '../../constants';

export function AutomaticSeedingRouteHandler() {
  const isSubaccountUser = useSelector(isSubaccountUserSelector);

  if (isSubaccountUser) {
    return <Redirect to={`${AUTOMATIC_SEEDING_ROUTE}/config`} />;
  }

  return <Redirect to={`${AUTOMATIC_SEEDING_ROUTE}/config/list`} />;
}
