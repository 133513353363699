/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getDocumentation } from 'src/actions/messageEvents';
import { Tabs } from 'src/components';
import { Box, Button, Checkbox, Drawer, ScreenReaderOnly, Tooltip } from 'src/components/matchbox';
import { Bold } from 'src/components/text';
import { Form } from 'src/components/tracking';
import { selectMessageEventListingv2 } from 'src/selectors/eventListing';
import { ColumnList } from '../styles';
import FiltersForm from './FiltersForm';

type FormValues = {
  [key: string]: string;
};

type FiltersDrawerProps = {
  closeDrawer: () => void;
  getDrawerProps: () => $TODOFIXME;
  onChange: (value: $TODOFIXME) => void;
};

const drawerTabs = [{ content: 'Event Filters' }, { content: 'Message Filters' }];

const eventGroups = {
  Injection: ['generation_failure', 'generation_rejection', 'injection', 'policy_rejection'],
  Delivery: ['bounce', 'delay', 'delivery', 'out_of_band', 'sms_status'],
  Engagement: [
    'amp_click',
    'amp_initial_open',
    'amp_open',
    'click',
    'initial_open',
    'link_unsubscribe',
    'list_unsubscribe',
    'open',
    'spam_complaint'
  ]
};

type FiltersDrawerPayload = {
  events: object;
  filters: $TODOFIXME[];
};

function FiltersDrawer({ onChange, getDrawerProps, closeDrawer }: FiltersDrawerProps): JSX.Element {
  const selectedEvents = useSelector((state: $TODOFIXME) => state.messageEvents.search.events);

  const initialPayload = (): FiltersDrawerPayload => ({
    filters: [],
    events: {}
  });

  const payloadRef = useRef<FiltersDrawerPayload>(initialPayload());
  const payloadCapturedCount = useRef(0);

  const metricFormRef = useRef<HTMLFormElement>(null);
  const filterFormRef = useRef<HTMLFormElement>(null);

  const events = useSelector(selectMessageEventListingv2);

  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm<FormValues>();

  useEffect(() => {
    dispatch(getDocumentation());
  }, [dispatch]);

  const capturePayload = useCallback((type: keyof FiltersDrawerPayload) => {
    return (payload: Partial<FiltersDrawerPayload>): void => {
      if (payload[type]) {
        payloadRef.current = {
          ...payloadRef.current,
          [type]: payload[type]
        };
      }
    };
  }, []);

  const handleFormsSubmit = useCallback(() => {
    metricFormRef.current?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    filterFormRef.current?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    // run right after both forms above capture their payloads
    setTimeout(() => {
      onChange(payloadRef.current);
      payloadRef.current = initialPayload();
    }, 10);
    closeDrawer();
  }, [closeDrawer, onChange]);

  return (
    <Drawer {...getDrawerProps()}>
      <Drawer.Header showCloseButton>
        <ScreenReaderOnly>Report Options</ScreenReaderOnly>
      </Drawer.Header>

      <Drawer.Content p="0">
        <Tabs defaultTabIndex={0} forceRender fitted tabs={drawerTabs}>
          <Tabs.Item>
            <Form
              id="message-events-filter-drawer"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={handleSubmit(capturePayload('events'))}
              ref={metricFormRef}
            >
              <Box p="500">
                {Object.keys(eventGroups).map((eventGroupKey) => (
                  <Box key={eventGroupKey} pt="450">
                    <Bold>{eventGroupKey}</Bold>

                    <ColumnList count={Math.ceil(eventGroups[eventGroupKey].length / 2)}>
                      {eventGroups[eventGroupKey].map((eventKey: string) => {
                        const event = events.find(({ type }) => type === eventKey);
                        return (
                          <Box key={eventKey}>
                            <Tooltip id={eventKey} dark content={event?.description}>
                              <Checkbox
                                {...register(`events.${eventKey}`, {
                                  shouldUnregister: true
                                })}
                                id={eventKey}
                                label={event?.displayName}
                                type="checkbox"
                                name={`events.${eventKey}`}
                                defaultChecked={Boolean(selectedEvents.includes(eventKey))}
                                data-track={true}
                              />
                            </Tooltip>
                          </Box>
                        );
                      })}
                    </ColumnList>
                  </Box>
                ))}
              </Box>
            </Form>
          </Tabs.Item>
          <Tabs.Item>
            <FiltersForm handleSubmit={capturePayload('filters')} ref={filterFormRef} />
          </Tabs.Item>
        </Tabs>
      </Drawer.Content>
      <Drawer.Footer>
        <Box display="flex">
          <Box pr="100" flex="1">
            <Button
              useMatchboxVariant={false}
              width="100%"
              variant="primary"
              onClick={handleFormsSubmit}
            >
              Apply Filters
            </Button>
          </Box>
          <Box pl="100" flex="1">
            <Button
              useMatchboxVariant={false}
              width="100%"
              variant="secondary"
              onClick={closeDrawer}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Drawer.Footer>
    </Drawer>
  );
}

const mapStateToProps = (state: $TODOFIXME): $TODOFIXME => {
  return {
    eventListing: selectMessageEventListingv2(state)
  };
};

export default connect(mapStateToProps)(FiltersDrawer);
