import { Auth } from 'src/typescript/interfaces/Auth';
import { CurrentUser } from 'src/typescript/interfaces/CurrentUser';
import { QueryFN, QueryKey } from '../types';

interface DeriveQueryKey {
  auth: Auth;
  currentUser: CurrentUser;
  queryFn: QueryFN;
}

export function deriveQueryKey({ queryFn, auth, currentUser }: DeriveQueryKey): QueryKey {
  const { url, method, params, headers, data } = queryFn();

  let headersWithSubaccount;
  if (currentUser?.selectedSubaccount) {
    headersWithSubaccount = {
      'x-msys-subaccount': currentUser?.selectedSubaccount,
      ...headers
    };
  }

  return [url, { method, params, headers: headersWithSubaccount || headers, auth, data }];
}
