import { useCallback, useMemo } from 'react';
import { useSparkPostQuery } from 'src/hooks';
import { PRIMARY_SUBACCOUNT, PRIMARY_AND_ALL_SUBACCOUNTS } from 'src/constants';
import { getSubaccounts } from 'src/helpers/api/subaccounts';
import { useSelector } from 'react-redux';
import { hasSubaccounts as hasSubaccountsSelector } from 'src/selectors/subaccounts';

export default function useSubaccounts({ withD12yOption = false } = {}) {
  const hasSubaccounts = useSelector(hasSubaccountsSelector);
  const subaccountsQuery = useSparkPostQuery(
    () => getSubaccounts(withD12yOption ? { params: { option: 'deliverability' } } : {}),
    {
      staleTime: 1000 * 30,
      enabled: hasSubaccounts
    }
  );

  const subaccountsById = useMemo(() => {
    if (subaccountsQuery.status !== 'success') {
      return;
    }
    return subaccountsQuery.data.reduce((acc, subaccount) => {
      acc[subaccount.id] = subaccount;
      return acc;
    }, {});
  }, [subaccountsQuery.status, subaccountsQuery.data]);

  const getSubaccountById = useCallback(
    (id) => {
      if (subaccountsQuery.status !== 'success') {
        return {};
      }
      if (id === 0 || id === '0') return PRIMARY_SUBACCOUNT;

      return subaccountsById[id];
    },
    [subaccountsById, subaccountsQuery.status]
  );

  const getSubaccountName = useCallback(
    (id) => {
      if (Number.parseInt(id) === PRIMARY_SUBACCOUNT.id) {
        return PRIMARY_SUBACCOUNT.name;
      } else if (Number.parseInt(id) === PRIMARY_AND_ALL_SUBACCOUNTS.id) {
        return PRIMARY_AND_ALL_SUBACCOUNTS.name;
      } else if (id === null || id === undefined) {
        return;
      }

      if (subaccountsQuery.status !== 'success') {
        return `Subaccount ${id}`;
      }

      return subaccountsById[id]?.name || `Subaccount ${id}`;
    },
    [subaccountsById, subaccountsQuery.status]
  );

  return {
    subaccountsQuery,
    getSubaccountName,
    getSubaccountById,
    hasSubaccounts,
    subaccountsById
  };
}
