import React, { useCallback } from 'react';
import { FirstPage } from '@sparkpost/matchbox-icons';
import { Box, Button, Pager, ScreenReaderOnly, Text } from 'src/components/matchbox';

interface CursorPagingProps {
  currentPage: number;
  handleFirstPage: () => void;
  handlePageChange: (page: number) => void;
  hideText?: boolean;
  nextDisabled: boolean;
  perPage: number;
  previousDisabled: boolean;
  totalCount: number;
}

const CursorPaging = ({
  currentPage,
  handlePageChange,
  previousDisabled,
  nextDisabled,
  handleFirstPage,
  perPage,
  totalCount,
  hideText
}: CursorPagingProps): JSX.Element => {
  const handlePreviousClick = useCallback(
    () => handlePageChange(currentPage - 1),
    [handlePageChange, currentPage]
  );

  const handleNextClick = useCallback(
    () => handlePageChange(currentPage + 1),
    [handlePageChange, currentPage]
  );

  return (
    <Box display="flex" alignItems="center">
      <Button
        flat
        disabled={currentPage === 1}
        key="rewind"
        onClick={handleFirstPage}
        variant="default"
      >
        <FirstPage />
        <ScreenReaderOnly>Return to First Page</ScreenReaderOnly>
      </Button>
      <Pager>
        <Pager.Previous flat onClick={handlePreviousClick} disabled={previousDisabled} />
        <Pager.Next flat onClick={handleNextClick} disabled={nextDisabled} />
      </Pager>
      {!hideText && (
        <Text marginLeft="200" as="span" fontSize="200">
          Page:&nbsp;
          <strong data-id="pagination-current-page">{currentPage}</strong>
          &nbsp;of {Math.ceil(totalCount / perPage)}
        </Text>
      )}
    </Box>
  );
};

export default CursorPaging;
