import { ArrowBack, ArrowForward } from '@sparkpost/matchbox-icons';
import cx from 'classnames';
import React, { useCallback } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import { ScreenReaderOnly } from 'src/components/matchbox';
import styles from './DateSelector.module.scss';

type DateSelectorProps = {
  selectedDays: {
    from: Date;
    to: Date;
  };
};

type NavbarProps = {
  onNextClick: () => void;
  onPreviousClick: () => void;
  showNextButton: boolean;
  showPreviousButton: boolean;
};

export function Navbar({
  onPreviousClick,
  onNextClick,
  showNextButton,
  showPreviousButton
}: NavbarProps) {
  const handlePreviousClick = useCallback(() => onPreviousClick(), [onPreviousClick]);
  const handleNextClick = useCallback(() => onNextClick(), [onNextClick]);

  return (
    <div className={styles.Navbar}>
      {/*
        Conditionally rendering the buttons causes `react-day-picker` to behave unpredictably.
        Instead, always rendering the elements in the DOM but conditionally applying classes
        helps address the problem. Debugging further, it looks like the library unmounts the button
        before Matchbox checks as to whether the clicked button came from outside the popover node.
        As a result, the click registers as coming from outside the node as the popover no longer
        contains said element.
      */}
      <button
        className={cx(
          styles.NavbarButton,
          styles.NavbarButtonPrev,
          !showPreviousButton && styles.isHidden
        )}
        onClick={handlePreviousClick}
        data-id="date-selector-previous-month"
        type="button"
      >
        <ArrowBack size={21} className={styles.Prev} />

        <ScreenReaderOnly>Previous Month</ScreenReaderOnly>
      </button>

      <button
        className={cx(
          styles.NavbarButton,
          styles.NavbarButtonNext,
          !showNextButton && styles.isHidden
        )}
        onClick={handleNextClick}
        data-id="date-selector-next-month"
        type="button"
      >
        <ArrowForward size={21} className={styles.Next} />

        <ScreenReaderOnly>Next Month</ScreenReaderOnly>
      </button>
    </div>
  );
}

function DateSelector({ selectedDays, ...props }: DateSelectorProps): JSX.Element {
  const modifiers = selectedDays
    ? {
        [styles.firstSelected]: (day: Date) => DateUtils.isSameDay(day, selectedDays.from),
        [styles.lastSelected]: (day: Date) => DateUtils.isSameDay(day, selectedDays.to),
        [styles.inBetween]: (day: Date) =>
          DateUtils.isDayBetween(day, selectedDays.from, selectedDays.to)
      }
    : {};

  return <DayPicker modifiers={modifiers} classNames={styles} navbarElement={Navbar} {...props} />;
}

export default DateSelector;
