const { memoize } = require('lodash');

module.exports = memoize(function getConfigFromWindow() {
  const tenantConfigs = typeof TENANT_CONFIGS !== 'undefined' ? TENANT_CONFIGS : {}; // eslint-disable-line no-undef
  const auth0Configs = typeof AUTH0_CONFIGS !== 'undefined' ? AUTH0_CONFIGS : {}; // eslint-disable-line no-undef
  const hasWindow = typeof window !== 'undefined';
  const w = hasWindow ? window : {};

  const hostname = (w && w.location && w.location.hostname) || '';
  const config = tenantConfigs[hostname] || {};

  return {
    tenantConfig: config,
    auth0Configs,
    hasWindow,
    hostname
  };
});
