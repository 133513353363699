import styled, { css } from 'styled-components';

const BREAKPOINT_1200 = css`
  ${({ theme }) => theme.breakpoints[3]}
`;
const BREAKPOINT_960 = css`
  ${({ theme }) => theme.breakpoints[2]}
`;
const BREAKPOINT_720 = css`
  ${({ theme }) => theme.breakpoints[1]}
`;

export const Wrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;
`;

export const MainWithSidebar = styled.main`
  position: relative;
  display: flex;
  width: 100%;
  overflow-y: hidden;
  overscroll-behavior: contain;
`;

export const DesktopSidebarContainer = styled.div`
  ${() => `height:calc(100vh - 140px);`}
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;
  flex-grow: 0;
  max-width: 330px;
  ${({ open }) => open && `flex-grow: 1;`}
  ${({ open, theme }) => !open && `width: ${theme.sizes['800']}};`}
  transition: all .8s ease-out;
  display: none;

  @media (min-width: ${BREAKPOINT_960}) {
    display: flex;
  }
`;

export const ContainerForRest = styled.div`
  overflow-y: auto;
  overscroll-behavior: contain;
  flex: 4;

  padding-bottom: ${({ theme }) => theme.sizes['900']};
  ${({ open }) => !open && `margin: 0 auto;`}

  @media (max-width: ${BREAKPOINT_960}) {
    margin: 0 auto;
    padding: ${({ theme }) => theme.space['800']} ${({ theme }) => theme.space['400']};
    padding-bottom: ${({ theme }) => theme.sizes['900']};
  }

  @media (min-width: ${BREAKPOINT_720}) {
    padding-left: ${({ theme }) => theme.space['500']};
    padding-right: ${({ theme }) => theme.space['500']};
  }
`;

export const MaxWidthContainer = styled.div`
  margin: 0 auto;
  max-width: ${BREAKPOINT_1200};
`;

export const ContentContainer = styled.div`
  overflow-y: auto;
  overscroll-behavior: contain;
  flex: 4;
  padding: ${({ theme }) => theme.space['800']} ${({ theme }) => theme.space['400']};
  padding-bottom: ${({ theme }) => theme.sizes['900']};
  margin-left: ${({ theme }) => theme.space['800']};
  margin-right: ${({ theme }) => theme.space['800']};
  max-width: ${BREAKPOINT_1200};
  ${({ open }) => !open && `margin: 0 auto;`}

  @media (max-width: ${BREAKPOINT_960}) {
    margin: 0 auto;
  }
`;

export const AppWrapper = styled.div`
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

export const AppMain = styled.main`
  position: relative;
  outline: none; /* Disables focus styles - not in the tab order - only focused via skip link */
  flex: 1 0 0;
  padding: ${({ theme }) => theme.space['800']} ${({ theme }) => theme.space['400']};
  padding-bottom: ${({ theme }) => theme.sizes['900']};
  @media (min-width: ${BREAKPOINT_960}) {
    padding: 0 ${({ theme }) => theme.space['500']} ${({ theme }) => theme.space['400']}
      ${({ theme }) => theme.space['500']};
    padding-bottom: ${({ theme }) => theme.sizes['900']};
  }
`;

export const AppContainer = styled.div`
  position: relative;
  overflow: initial;
  min-height: 100%;
  max-width: ${BREAKPOINT_1200};
  margin: 0 auto;
`;

export const DrawerWrapper = styled.div`
  [data-id='drawer-content'] {
    padding: 0;
  }
`;
