import React from 'react';
import PropTypes from 'prop-types';
import { subaccountToString } from 'src/helpers/subaccounts';
import { TypeSelect } from './index';

/**
 * @description Typeahead UI for subaccount selection that does *not* include baked-in API requests, or a clear button.
 */
export function SubaccountTypeSelect({
  id,
  label,
  subaccounts,
  helpText,
  onChange,
  value,
  disabled,
  'data-track': dataTrack,
  'data-id': dataId
}) {
  return (
    <TypeSelect
      id={id}
      label={label}
      placeholder="e.g. Primary Account or subaccount_001"
      helpText={helpText}
      disabled={disabled}
      renderItem={(subaccount) => {
        return (
          <TypeSelect.Item
            label={subaccount.name}
            itemToString={(subaccount) => subaccount.id}
            meta={subaccount.id === 0 ? undefined : subaccount.id.toString()}
          />
        );
      }}
      itemToString={(item) => subaccountToString(item)}
      results={subaccounts}
      onChange={onChange}
      selectedItem={value}
      data-track={dataTrack}
      data-id={dataId}
    />
  );
}

SubaccountTypeSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subaccounts: PropTypes.array,
  helpText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
  disabled: PropTypes.bool,
  'data-track': PropTypes.bool,
  'data-id': PropTypes.string
};

SubaccountTypeSelect.defaultProps = {
  id: 'subaccount-type-select',
  label: 'Account Assignment',
  'data-track': false
};
