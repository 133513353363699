import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ActionList, Popover } from 'src/components/matchbox';
import { TranslatableText } from 'src/components/text';
import { selectHibanaAccountNavItems } from 'src/selectors/navItems';
import styles from './AccountPopover.module.scss';
import DesktopAccountButtonV2 from './DesktopAccountButtonV2';

function AccountPopover(props) {
  const { accountItems, currentUser, dispatch } = props;
  const [isOpen, setIsOpen] = useState(false);

  const togglePopover = () => setIsOpen(!isOpen);

  const DesktopButton = DesktopAccountButtonV2;

  const personalInfo =
    currentUser.first_name && currentUser.last_name
      ? `${currentUser.first_name} ${currentUser.last_name}`
      : currentUser.email;

  return (
    <div className={styles.AccountPopover} data-id="desktop-navigation-account-popover">
      <Popover
        id="desktop-navigation-account-popover-contents"
        trigger={
          <DesktopButton isOpen={isOpen} currentUser={currentUser} onClick={togglePopover} />
        }
        left
        open={isOpen}
        onClose={togglePopover}
      >
        {currentUser && (
          <div className={styles.AccountPopoverHeader}>
            <div className={styles.AccountPopoverUserInfo}>{personalInfo}</div>

            {/* Render the customer ID where available, otherwise their role */}
            {(currentUser.customer || currentUser.access_level) && (
              <div className={styles.AccountPopoverSupplemental}>
                {currentUser.customer ? currentUser.customer : currentUser.access_level}
              </div>
            )}
          </div>
        )}
        <ActionList data-id="desktop-navigation-account-actionlist" onClick={togglePopover}>
          {accountItems.map((item, i) => {
            return (
              <ActionList.Action
                key={`action-item-${i}`}
                external={item.external}
                onClick={item.action && (() => dispatch(item.action()))}
                component={!item.external && item.to ? Link : undefined}
                to={item.to}
                section={item.section}
              >
                <PopoverItem item={item} />
              </ActionList.Action>
            );
          })}
        </ActionList>
      </Popover>
    </div>
  );
}

function PopoverItem({ item }) {
  const { label, icon: Icon, secondaryLabel } = item;

  return (
    <div className={styles.PopoverItem}>
      {/* Extra `div` needed to appropriately handle flexbox properties */}
      <div>
        <TranslatableText>{label}</TranslatableText>

        {secondaryLabel && (
          <span>
            <span role="presentation"> / </span>

            <span className={styles.PopoverItemSecondaryLabel}>{secondaryLabel}</span>
          </span>
        )}
      </div>

      {Icon && <Icon size={18} />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  accountItems: selectHibanaAccountNavItems(state)
});

export default connect(mapStateToProps)(AccountPopover);
