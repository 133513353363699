export const weekDayDictionary: { [key: number]: string } = {
  1: 'Sunday',
  2: 'Monday',
  3: 'Tuesday',
  4: 'Wednesday',
  5: 'Thursday',
  6: 'Friday',
  7: 'Saturday'
};

export const operatorDictionary = {
  gt: 'above',
  lt: 'below',
  eq: 'equal'
};
