import { XhrRequestMeta } from 'src/typescript/interfaces/XhrRequestMeta';

const headers = {
  'X-MSYS-ALERTSV2': 1
};

export function getAlerts(params: object): XhrRequestMeta {
  return {
    method: 'GET',
    url: `/v1/alerts/`,
    params,
    headers
  };
}

export function getAlert({ id }: { id: string }): XhrRequestMeta {
  return {
    method: 'GET',
    url: `/v1/alerts/${id}`,
    headers
  };
}

export function getIncident({ id }: { id: string }): XhrRequestMeta {
  return {
    method: 'GET',
    url: `/v1/alerts/incidents?alert_id=${id}`,
    headers
  };
}

export function createAlert(data: object): XhrRequestMeta {
  return {
    method: 'POST',
    url: `/v1/alerts/`,
    data,
    headers
  };
}

export function updateAlert(id: string, data: object): XhrRequestMeta {
  return {
    method: 'PUT',
    url: `/v1/alerts/${id}`,
    data,
    headers
  };
}

export function deleteAlert(id: string): XhrRequestMeta {
  return {
    method: 'DELETE',
    url: `/v1/alerts/${id}`,
    headers
  };
}

export function sendNotificationTest(id: string): XhrRequestMeta {
  return {
    method: 'POST',
    url: `/v1/alerts/${id}/test`,
    headers
  };
}
