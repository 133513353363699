/* eslint-disable local/restrict-translatable-text */
/* eslint-disable react/jsx-no-bind */
import { tokens } from '@sparkpost/design-tokens';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Button, Modal, Stack, Text } from 'src/components/matchbox';
import { useSparkPostMutation } from 'src/hooks';
import { sendNotificationTest } from 'src/helpers/api/alerts';
import { showAlert } from 'src/actions/globalAlert';

export default function SendAlertTestModal({ open, onClose, email, slack, webhook }) {
  const dispatchRedux = useDispatch();
  const { id } = useParams();

  const alertNotificationTestMutation = useSparkPostMutation(
    () => {
      return sendNotificationTest(id);
    },
    {
      onSuccess: () => {
        dispatchRedux(
          showAlert({ type: 'success', message: `Notification test sent successfully` })
        );
        onClose();
      }
    }
  );
  const handleSendAlertNotificationTest = () => {
    alertNotificationTestMutation.mutate({});
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header showCloseButton>Send Test Alert</Modal.Header>
      <Modal.Content>
        <Stack>
          <Box>
            Send an alert test notification to multiple channels set up in Notification
            Configuration.
          </Box>
          <Box>
            <Text as="b">Email</Text>
            {!email.length && <Text color={tokens.color_gray_900}>None</Text>}
            {email.map((email) => (
              <Text key={`alert-test-email-${email}`} color={tokens.color_gray_900}>
                {email}
              </Text>
            ))}
          </Box>
          <Box>
            <Text as="b">Slack</Text>
            {!slack.length && <Text color={tokens.color_gray_900}>None</Text>}
            {slack.map((slack) => (
              <Text key={`alert-test-slack-${slack}`} color={tokens.color_gray_900}>
                {slack}
              </Text>
            ))}
          </Box>
          <Box>
            <Text as="b">Webhook</Text>
            {!webhook.length && <Text color={tokens.color_gray_900}>None</Text>}
            {webhook.map((webhook) => (
              <Text key={`alert-test-webhook-${webhook}`} color={tokens.color_gray_900}>
                {webhook}
              </Text>
            ))}
          </Box>
        </Stack>
      </Modal.Content>
      <Modal.Footer>
        <Button variant="destructive" color="blue" onClick={handleSendAlertNotificationTest}>
          Send Test Notification
        </Button>
        <Button onClick={onClose} variant="monochrome-secondary">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
