import React, { useEffect } from 'react';
import ErrorWebp from '@sparkpost/matchbox-media/images/Error.webp';
import { EmptyState } from 'src/components/matchbox';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import ErrorTracker from 'src/helpers/errorTracker';

export default function AuthConfigErrorPage() {
  const location = useLocation();
  const search = qs.parse(location?.search);

  useEffect(() => {
    const error = new Error(
      `Auth0 Configuration Error - ${search.error_description} - ${search.error}`
    );
    if (search.error)
      ErrorTracker.report('auth0-configuration-error', error, {
        level: 'error',
        ...search
      });
  }, [search]);

  return (
    <EmptyState>
      <EmptyState.Header>Sorry, something went wrong</EmptyState.Header>
      <EmptyState.Image src={ErrorWebp} />
      <EmptyState.Content>
        We're having some technical issues. Our engineers have been notified and are working on
        getting this fixed.
      </EmptyState.Content>
    </EmptyState>
  );
}
