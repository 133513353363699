import React, { useCallback } from 'react';
import { Box, Button, Select, Stack } from 'src/components/matchbox';
import { INDUSTRY_BENCHMARK_INDUSTRIES, INDUSTRY_BENCHMARK_MAILBOX_PROVIDERS } from 'src/constants';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';

const INDUSTRY_BENCHMARK_FORM = 'industry-benchmark-fields';

export const AlternativeIndustryBenchmarkFields = () => {
  const {
    state: reportOptions,
    actions: { setIndustryBenchmark }
  } = useAnalyticsReportContext();
  const { industryBenchmarkMetric, industryBenchmarkFilters } = reportOptions;

  const handleEnable = useCallback(() => {
    setIndustryBenchmark({
      industryBenchmarkMetric: 'inbox_folder_rate'
    });
  }, [setIndustryBenchmark]);

  const handleDisable = useCallback(() => {
    setIndustryBenchmark({
      industryBenchmarkMetric: null
    });
  }, [setIndustryBenchmark]);

  const handleCategoryChange = useCallback(
    ({ target: { value } }) => {
      setIndustryBenchmark({
        industryBenchmarkFilters: {
          ...industryBenchmarkFilters,
          industryCategory: value
        },
        industryBenchmarkMetric
      });
    },
    [industryBenchmarkFilters, industryBenchmarkMetric, setIndustryBenchmark]
  );

  const handleProviderChange = useCallback(
    ({ target: { value } }) => {
      setIndustryBenchmark({
        industryBenchmarkFilters: {
          ...industryBenchmarkFilters,
          mailboxProvider: value
        },
        industryBenchmarkMetric
      });
    },
    [industryBenchmarkFilters, industryBenchmarkMetric, setIndustryBenchmark]
  );

  return (
    <Box>
      <Box>
        <Box id={INDUSTRY_BENCHMARK_FORM}>
          <Stack space="200">
            <Select
              maxWidth="800px"
              id="industry"
              value={industryBenchmarkFilters.industryCategory}
              options={INDUSTRY_BENCHMARK_INDUSTRIES}
              data-track={true}
              onChange={handleCategoryChange}
            />
            <Select
              id="mailbox-provider"
              value={industryBenchmarkFilters.mailboxProvider}
              options={INDUSTRY_BENCHMARK_MAILBOX_PROVIDERS}
              data-track={true}
              onChange={handleProviderChange}
            />
            <Box>
              {!industryBenchmarkMetric && (
                <Button
                  size="small"
                  variant="mutedOutline"
                  color="blue"
                  useMatchboxVariant={true}
                  onClick={handleEnable}
                >
                  Enable
                </Button>
              )}
              {industryBenchmarkMetric && (
                <Button
                  variant="secondary"
                  size="small"
                  color="blue"
                  useMatchboxVariant={true}
                  onClick={handleDisable}
                >
                  Disable
                </Button>
              )}
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
