import React from 'react';
import { Tag } from 'src/components/matchbox';

interface ReadyFor {
  bounce: boolean;
  bounceDefault: boolean;
  dkim: boolean;
  sending: boolean;
  subaccount: number;
}

const ReadyFor = ({ bounce, dkim, sending, bounceDefault, subaccount }: ReadyFor) => {
  let bounceMarkup = null;

  if (!bounce && !dkim && !sending) {
    return null;
  }

  if (bounce) {
    bounceMarkup = bounceDefault ? (
      <Tag color="orange">{`Bounce (${subaccount ? 'Subaccount ' : ''}Default`})</Tag>
    ) : (
      <Tag>Bounce</Tag>
    );
  }

  const sendingMarkup = sending ? <Tag>Sending</Tag> : null;

  const dkimMarkup = dkim ? <Tag>DKIM Signing</Tag> : null;

  return (
    <span>
      <small>Ready For:</small>
      <span>{sendingMarkup}</span>
      <span>{bounceMarkup}</span>
      <span>{dkimMarkup}</span>
    </span>
  );
};

export default ReadyFor;
