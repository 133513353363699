import React from 'react';
import { ShowChart } from '@sparkpost/matchbox-icons';
import { sub, isToday, isBefore } from 'date-fns';
import { useSparkPostQuery } from 'src/hooks';
import { Tooltip } from 'src/components/matchbox';
import { Empty, Unit } from 'src/components';
import { tokens } from '@sparkpost/design-tokens';
import { Box, Button, Panel, Layout, LabelValue, Stack, Text } from 'src/components/matchbox';
import { LabelValueLoadingSkeleton } from 'src/components/loading';
import { getEngagementRecency } from 'src/helpers/api/signals';
import { formatDate } from '@sparkpost/report-builder/helpers/date';
import useResourceDetailsContext from '../../hooks/useResourceDetailsContext';
import { PageLink } from 'src/components/links';
import { formatDataForRecencyByCohort, allCurrentPropertiesNull, formatIdFromUrl } from './helpers';
import { ENGAGEMENT_RECENCY_COHORTS } from 'src/pages/signals/constants/info';
import { TranslatableText } from 'src/components/text';

const FACET = {
  campaign: 'campaign_id',
  'ip-pool': 'ip_pool',
  'mailbox-provider': 'mb_provider',
  'sending-domain': 'sending_domain',
  subaccount: 'sid'
};

export default function CohortPercentages() {
  const { filters, match: { params: { type, id } = {} } = {} } = useResourceDetailsContext();
  const idFormattedPerType = formatIdFromUrl(type, id);

  let toParam;
  let fromParam;
  let viewFullReportLink = `/signals/engagement/cohorts/${FACET[type]}/${encodeURIComponent(
    idFormattedPerType
  )}`;
  if (filters && filters.to && filters.from) {
    const YESTERDAY = new Date(sub(new Date(), { days: 1 }));
    const TWO_DAYS_AGO = new Date(sub(new Date(), { days: 2 }));
    const TO = new Date(filters.to);
    const FROM = new Date(filters.from);

    if (isToday(TO)) {
      toParam = YESTERDAY;
      if (isBefore(toParam, FROM)) fromParam = TWO_DAYS_AGO;
      else fromParam = FROM;
    } else {
      toParam = TO;
      fromParam = FROM;
    }

    // https://sparkpost.atlassian.net/browse/SAA-273
    // viewFullReportLink += `?from=${FROM.toISOString()}&to=${toParam.toISOString()}&range=custom`;
  }

  const apiParam = {
    from: fromParam,
    to: toParam,
    facet: FACET[type],
    filter: idFormattedPerType
  };

  const { status: engagementRecencyStatus, data: engagementRecencyData = [] } = useSparkPostQuery(
    () => getEngagementRecency(apiParam),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const cohortPercentages =
    engagementRecencyData &&
    engagementRecencyData.data &&
    engagementRecencyData.data.length &&
    engagementRecencyData.data.find((apiData) => {
      return apiData[FACET[type]] === idFormattedPerType;
    });

  const formattedPercentages = formatDataForRecencyByCohort(cohortPercentages || {}, FACET[type]);
  const noDataFound =
    allCurrentPropertiesNull(cohortPercentages) || Object.keys(formattedPercentages).length === 0;

  const formattedDate = formatDate.useMomentInput(toParam);

  return (
    <div data-id="cohort-percentages">
      <Layout>
        <Layout.Section annotated>
          <Layout.SectionTitle>Cohort Percentages</Layout.SectionTitle>
          <Stack space="100">
            <Text color="gray.700" fontSize="300" font-weight="600">
              <TranslatableText>{formattedDate}</TranslatableText>
            </Text>
          </Stack>
        </Layout.Section>
        <Layout.Section>
          <Panel>
            {engagementRecencyStatus === 'loading' && (
              <Panel.Section>
                <LabelValueLoadingSkeleton
                  labelValues={[
                    { value: 'New', width: 1 / 6 },
                    { value: 'Never Engaged', width: 1 / 6 },
                    { value: 'Not Recently Engaged', width: 1 / 6 },
                    { value: 'Semi Recently Engaged', width: 1 / 6 },
                    { value: 'Recently Engaged', width: 1 / 6 }
                  ]}
                />
              </Panel.Section>
            )}

            {engagementRecencyStatus === 'error' && (
              <Empty message="Failed to load the data. Refresh page." />
            )}

            {engagementRecencyStatus === 'success' && noDataFound && (
              <Empty message="No results found." />
            )}

            {engagementRecencyStatus === 'success' && !noDataFound && (
              <Panel.Section>
                <Panel.Action as={PageLink} to={viewFullReportLink}>
                  View Full Report <Button.Icon as={ShowChart} />
                </Panel.Action>

                <Box display="flex">
                  <LabelValue mr={tokens.spacing_500}>
                    <LabelValue.Label>
                      <Tooltip id="new" dark content={ENGAGEMENT_RECENCY_COHORTS['New']}>
                        New
                      </Tooltip>
                    </LabelValue.Label>
                    <LabelValue.Value>
                      <Unit value={formattedPercentages?.c_new} unit="percent" roundTo={1} />
                    </LabelValue.Value>
                  </LabelValue>

                  <LabelValue mr={tokens.spacing_500}>
                    <LabelValue.Label>
                      <Tooltip
                        id="never-engaged"
                        dark
                        content={ENGAGEMENT_RECENCY_COHORTS['Never Engaged']}
                      >
                        Never Engaged
                      </Tooltip>
                    </LabelValue.Label>
                    <LabelValue.Value>
                      <Unit value={formattedPercentages?.c_uneng} unit="percent" roundTo={1} />
                    </LabelValue.Value>
                  </LabelValue>

                  <LabelValue mr={tokens.spacing_500}>
                    <LabelValue.Label>
                      <Tooltip
                        id="not-recently-engaged"
                        dark
                        content={ENGAGEMENT_RECENCY_COHORTS['Not Recently Engaged']}
                      >
                        Not Recently Engaged
                      </Tooltip>
                    </LabelValue.Label>
                    <LabelValue.Value>
                      <Unit value={formattedPercentages?.c_365d} unit="percent" roundTo={1} />
                    </LabelValue.Value>
                  </LabelValue>

                  <LabelValue mr={tokens.spacing_500}>
                    <LabelValue.Label>
                      <Tooltip
                        id="semi-recently-engaged"
                        dark
                        content={ENGAGEMENT_RECENCY_COHORTS['Semi Recently Engaged']}
                      >
                        Semi Recently Engaged
                      </Tooltip>
                    </LabelValue.Label>
                    <LabelValue.Value>
                      <Unit value={formattedPercentages?.c_90d} unit="percent" roundTo={1} />
                    </LabelValue.Value>
                  </LabelValue>

                  <LabelValue mr={tokens.spacing_500}>
                    <LabelValue.Label>
                      <Tooltip
                        id="recently-engaged"
                        dark
                        content={ENGAGEMENT_RECENCY_COHORTS['Recently Engaged']}
                      >
                        Recently Engaged
                      </Tooltip>
                    </LabelValue.Label>
                    <LabelValue.Value>
                      <Unit value={formattedPercentages?.c_14d} unit="percent" roundTo={1} />
                    </LabelValue.Value>
                  </LabelValue>
                </Box>
              </Panel.Section>
            )}
          </Panel>
        </Layout.Section>
      </Layout>
    </div>
  );
}
