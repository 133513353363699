// https://messagebird.atlassian.net/wiki/spaces/~641589493/pages/9747464193/Instrumentation

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/ban-types */
import { noop } from 'lodash';
import React, {
  ComponentType,
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { createStore } from './store';
import { ITrack } from './types';

const API_URL = 'https://dotcom.messagebird.com/events';

interface Store {
  country: 'unknown' | string;
  enabled: boolean;
  firstPageLoadedAt: ReturnType<typeof Date.now>;
  loading: boolean;
  pageId: undefined | string;
  sessionId: string;
  visitorId: 'unknown' | string;
}

const initialStore: Store = {
  country: 'unknown',
  visitorId: 'unknown',
  sessionId: Math.random().toString(36).slice(2, 14),
  pageId: undefined,
  firstPageLoadedAt: Date.now(),
  enabled: true,
  loading: true
};

export const useStore = createStore<Store>(initialStore);

const currentPageId = () => {
  // In case this page is getting rendered with SSR
  if (typeof window !== 'object') {
    return '#';
  }

  return document.location.pathname + document.location.search;
};

type InstrumentationContextType = {
  country: string;
  sessionId: string;
  track: ITrack;
  visitorId: string;
};

const InstrumentationContext = createContext<InstrumentationContextType>({
  country: initialStore.country,
  sessionId: initialStore.visitorId,
  track: noop,
  visitorId: initialStore.visitorId
});

export function withInstrumentationContext(Component: any): ComponentType {
  return (props) => {
    const [store, setStore] = useStore();
    const [queued, setQueued] = useState<{ action: string; event: any }[]>([]);

    const track = useCallback(
      (action: string, event: any) => {
        if (store.visitorId === 'unknown' && action !== 'formSubmit') {
          // Still loading fingerprint or not enabled (ie not given consent) etc. Queue events for it to be the flushed
          // when the HOC is ready.
          queued.push({ action, event });
          return;
        }

        fetch(API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({
            call: 'track',
            data: {
              visitorId: store.visitorId,
              sessionId: store.sessionId,
              pageId: store.pageId,
              activity: action,
              payload: event
            }
          })
        }).then((res) => res.text());
      },
      [store.visitorId, store.sessionId, store.pageId, queued]
    );

    const hasAcceptedPrivacy = useCallback((): boolean => {
      const previousConsent = window.localStorage.getItem('consentDialog');
      const consentSettings = previousConsent ? JSON.parse(previousConsent) : {};

      return (
        consentSettings.essential &&
        consentSettings.functional &&
        consentSettings.marketing &&
        consentSettings.performance &&
        consentSettings.responded
      );
    }, []);

    // Flush queued
    useEffect(() => {
      if (queued.length && store.visitorId !== 'unknown') {
        queued.forEach((item) => {
          track(item.action, item.event);
        });
        setQueued([]);
      }
    }, [queued, store.visitorId, track]);

    useEffect(() => {
      if (typeof window !== 'undefined') {
        const shouldLoadScripts = hasAcceptedPrivacy();

        if (shouldLoadScripts || store.enabled) {
          SCRIPTS.forEach((script) =>
            insertScript({
              id: script.id,
              type: script.type,
              src: script.src,
              content: script.content,
              location: script.location
            })
          );
        }

        if (!store.enabled) {
          if (!hasAcceptedPrivacy()) {
            SCRIPTS.forEach((script) => removeElement(script.id));
            removeElement('mb-assets');
            setStore(initialStore);
          }
        }
      }
    }, [hasAcceptedPrivacy, setStore, store.enabled]);

    return (
      <Fingerprint>
        <InstrumentationContext.Provider
          value={{
            country: store.country,
            visitorId: store.visitorId,
            sessionId: store.sessionId,
            track
          }}
        >
          <BaseInstrumentation>
            <Component {...props} />
          </BaseInstrumentation>
        </InstrumentationContext.Provider>
      </Fingerprint>
    );
  };
}

function loadScript(url: string, id?: string) {
  return new Promise((resolve, reject) => {
    const foundEl = (id && document.getElementById(id)) || null;

    if (foundEl) {
      return resolve(foundEl);
    }

    const el = document.createElement('script');
    el.onload = resolve;
    el.onerror = reject;
    el.src = url;
    if (id) el.id = id;
    document.documentElement.appendChild(el);
  });
}

function insertScript({
  id,
  type,
  location,
  src = '',
  content = ''
}: {
  content?: string;
  id: string;
  location: 'head' | 'body';
  src?: string;
  type: 'async' | 'inline' | 'noscript';
}) {
  const isScriptOnPage = document.querySelector(`#${id}`);
  if (isScriptOnPage) return;

  let newScriptElement;
  let iframeElement;

  switch (type) {
    case 'async':
      newScriptElement = document.createElement('script');
      newScriptElement.async = true;
      newScriptElement.src = src;
      break;

    case 'inline':
      newScriptElement = document.createElement('script');
      newScriptElement.append(content);
      break;

    case 'noscript':
      newScriptElement = document.createElement('noscript');
      iframeElement = document.createElement('iframe');

      iframeElement.src = src;
      iframeElement.height = '0';
      iframeElement.width = '0';
      iframeElement.setAttribute('style', 'display:none;visibility:hidden');

      newScriptElement.appendChild(iframeElement);
      break;

    default:
      throw new Error('invalid script type provided');
  }

  newScriptElement.id = id;

  const locationEl = document.querySelector(location);
  if (locationEl) locationEl.appendChild(newScriptElement);
}

function removeElement(id: string) {
  const element = document.querySelector(`#${id}`);
  if (element) element.remove();
}

const SCRIPTS: any[] = [];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const FingerprintJS: any;

const Fingerprint: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [store, setStore] = useStore();

  const hasAcceptedPrivacy = useCallback((): boolean => {
    const previousConsent = window.localStorage.getItem('consentDialog');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const consentSettings = previousConsent ? JSON.parse(previousConsent) : {};

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
      consentSettings.essential &&
      consentSettings.functional &&
      consentSettings.marketing &&
      consentSettings.performance &&
      consentSettings.responded
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.enabled]);

  useMemo(() => {
    const initScript = async () => {
      if (!store.loading || !store.enabled || !document) {
        return;
      }

      if (hasAcceptedPrivacy() === false) {
        return;
      }

      const fpjs = await loadScript('https://dotcom.messagebird.com/assets/fp.min.js', 'mb-assets');

      try {
        const loaded = await FingerprintJS.load(fpjs);
        const result = await loaded.get();

        setStore({
          visitorId: result.visitorId,
          country: result.country,
          loading: false
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Failed', e);
        setStore({
          loading: false
        });
      }
    };

    return initScript();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.loading, store.enabled]);

  return <>{children}</>;
};

const BaseInstrumentation: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [store, setStore] = useStore();
  const [utmTags, setUtmTags] = useState({});
  const instrumentation = useContext(InstrumentationContext);

  const handleUnload = useCallback(() => {
    const timeSpent = Date.now() - store.firstPageLoadedAt;
    instrumentation.track('pageUnload', { timeSpent });
  }, [instrumentation, store.firstPageLoadedAt]);

  // Detect UTM tags
  useEffect(() => {
    if (!window) {
      return;
    }

    const url = window.location.search;
    const urlParts = url.split('?');
    if (urlParts.length < 2) {
      return;
    }

    const params = urlParts[1].split('&');
    const utm = {};
    for (let i = 0; i < params.length; i++) {
      const keyValue = params[i].split('=');
      const key = keyValue[0];
      const value = keyValue[1];
      if (key.startsWith('utm_')) {
        const camelCaseKey = key
          .substring(4)
          .replace(/-/g, '_')
          .replace(/\b[a-z]/g, function (letter) {
            return letter.toUpperCase();
          });
        utm[`utm${camelCaseKey}`] = value;
      }
    }

    setUtmTags(utm);
  }, []);

  const [pageId, setPageId] = useState<string>(currentPageId());

  useEffect(() => {
    if (!utmTags || !Object.keys(utmTags).length) {
      return;
    }

    // Track campaign
    instrumentation.track('campaign', utmTags);
  }, [instrumentation, utmTags]);

  useEffect(() => {
    const from = store.pageId;
    const to = pageId;

    if (!store.pageId) {
      instrumentation.track('pageLoad', {
        to,
        ...utmTags
      });
    } else {
      instrumentation.track('pageChange', {
        from,
        to
      });
    }

    setStore({
      pageId
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, utmTags]);

  useEffect(() => {
    if (!window) {
      return;
    }

    window.addEventListener('beforeunload', handleUnload);
    return () => window.removeEventListener('beforeunload', handleUnload);
  }, [handleUnload]);

  return <>{children}</>;
};

export function withInstrumentation(Component: any): ComponentType {
  return (props) => {
    const [store, setStore] = useStore();
    const context = useContext(InstrumentationContext);
    if (!context) {
      // eslint-disable-next-line no-console
      console.error('InstrumentationContext was used outside of its Provider');
    }

    // disable this on sparkpost
    // if (store.loading) {
    //   return <></>;
    // }

    return (
      <Component
        {...props}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        track={(context && context.track) || function () {}}
        country={(context && context.country) || 'unknown'}
        visitorId={(context && context.visitorId) || 'unknown'}
      />
    );
  };
}
