import React from 'react';
import { Box, Columns, Column, Label, Skeleton } from 'src/components/matchbox';

/**
 * @param {Object} props
 * @param {Array<Object>} props.labelValue - Array of label value example - [ { name: 'ABC' , width: 1/2 }]
 * @param {Object} props.boxProps
 *  @param {Object} props.labelProps
 * @param {Object} props.skeletonProps
 */
type LabelValueLoadingSkeletonProps = {
  boxProps: object;
  labelProps: object;
  labelValues: { name: string; value: string; width: number }[];
  skeletonProps: object;
};
export function LabelValueLoadingSkeleton({
  labelValues = [],
  boxProps,
  labelProps,
  skeletonProps
}: LabelValueLoadingSkeletonProps) {
  if (labelValues.length === 0) return null;
  return (
    <Columns>
      {labelValues.map((labelValue, key) => (
        <Column width={labelValue.width || 1 / labelValues.length} key={key}>
          <Box {...boxProps}>
            <Label fontWeight="semibold" {...labelProps}>
              {labelValue.value}
            </Label>
            <Skeleton.Header data-id="skeleton-header" {...skeletonProps} />
          </Box>
        </Column>
      ))}
    </Columns>
  );
}
