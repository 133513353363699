import React from 'react';
import { Panel } from 'src/components/matchbox';
import { TableSkeleton } from 'src/components/loading';
import { Empty } from 'src/components';
import { DeliverabilityBreakdownTable } from './DeliverabilityBreakdownTable';

export function DeliverabilityBreakdownTableContainer({
  tableDataAndStatus,
  resourceType,
  columnsToDisplay,
  defaultColumnToSortBy
}) {
  const { tableDataStatus, ...data } = tableDataAndStatus;
  if (tableDataStatus === 'loading') {
    return (
      <Panel>
        <Panel.Section>
          <TableSkeleton numberOfColumns={5} numberOfRows={4} />
        </Panel.Section>
      </Panel>
    );
  }
  if (tableDataStatus === 'error') {
    return (
      <Panel>
        <Empty message="Failed to load the data. Refresh page."></Empty>
      </Panel>
    );
  }

  if (data.metricsData.length === 0)
    return (
      <Panel>
        <Empty message="No results found." />
      </Panel>
    );

  return (
    <DeliverabilityBreakdownTable
      tableData={data}
      resourceType={resourceType}
      columnsToDisplay={columnsToDisplay}
      defaultColumnToSortBy={defaultColumnToSortBy}
    />
  );
}
