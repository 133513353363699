import React from 'react';
import { ComboBoxMenu as MatchboxComboBoxMenu, ComboBoxMenuProps } from '@sparkpost/matchbox';

/**
 * Matchbox ComboboxMenu component
 * @see https://design.sparkpost.com/components/combobox/
 */
function ComboBoxMenu(props: ComboBoxMenuProps): JSX.Element {
  return <MatchboxComboBoxMenu {...props} />;
}

ComboBoxMenu.displayName = 'ComboBoxMenu';

export default ComboBoxMenu;
