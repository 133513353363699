import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { deriveQueryKey } from './helpers/deriveQueryKey';
import { handleError } from './helpers/handleError';
import { usePrepareQuery } from './helpers/usePrepareQuery';
import { QueryFN } from './types';

/**
 * Wrapper hook for react-query `useQuery` hook that handles consistent auth/error behavior
 * See: https://react-query.tanstack.com/reference/useQuery
 */

interface SparkPostQueryOptions extends UseQueryOptions {
  onError?: (error: AxiosError) => unknown;
}

export function useSparkPostQuery(
  queryFn: QueryFN,
  options: SparkPostQueryOptions = {
    onError: () => undefined
  }
) {
  const { queryClient, currentUser, auth, dispatch } = usePrepareQuery();
  const { onError, ...rest } = options;
  const { method } = queryFn();

  return useQuery({
    queryKey: deriveQueryKey({ queryFn, auth, currentUser }),
    // Pass in a custom handler for handling errors
    onError: (error: AxiosError) => {
      if (onError) onError(error); // Invoke a passed-in `onError` handler before the standard error handling
      return void handleError({ currentUser, error, method, queryClient, auth, dispatch });
    },
    // Allow config overriding on a case-by-case basis, for any value not manually updated, `react-query` defaults are used.
    ...rest
  });
}
