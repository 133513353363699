export const STATUS_CHECKBOXES = [
  {
    label: 'Enabled',
    name: 'enabled'
  },
  {
    label: 'Disabled',
    name: 'disabled'
  }
];

export const ENABLED = 'enabled';
export const DISABLED = 'disabled';
