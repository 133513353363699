import React from 'react';
import { useParams } from 'react-router-dom';
import { Panel } from 'src/components/matchbox';
import { useSparkPostMutation, useSubaccounts } from 'src/hooks';
import { PanelLoading } from 'src/components/loading';
import { LabelValue } from 'src/components/matchbox';
import { updateInlineSeedsOptions } from 'src/helpers/api/seedList';
import { subaccountToString } from 'src/helpers/subaccounts';
import { ApiErrorBanner, ToggleBlock } from 'src/components';
import { LabelValueLoadingSkeleton } from 'src/components/loading';

export function EnableAutomaticSeedingPanel({ inlineSeedsOptionsQuery }) {
  const { subaccountId } = useParams();
  const { subaccountsQuery, hasSubaccounts, getSubaccountById } = useSubaccounts();
  const { status, data, error, refetch } = inlineSeedsOptionsQuery;

  const mutation = useSparkPostMutation(
    () => updateInlineSeedsOptions({ enabled: !data.enabled, subaccountId }),
    { onSuccess: refetch }
  );

  if (status === 'loading') return <PanelLoading minHeight="150px" />;

  if (status === 'error')
    return (
      <ApiErrorBanner
        message="Sorry, we seem to have had some trouble loading your inline seed list settings."
        reload={refetch}
        errorDetails={error.message}
      />
    );

  return (
    <Panel>
      {hasSubaccounts ? (
        <Panel.Section>
          <SubaccountLabelValue
            status={subaccountsQuery.status}
            subaccount={getSubaccountById(subaccountId)}
          />
        </Panel.Section>
      ) : null}

      <Panel.Section>
        <ToggleBlock
          label="Enable Inline Seeding"
          helpText="Campaigns selected that meet the criteria will be automatically seeded."
          onChange={mutation.mutate}
          disabled={mutation.status === 'loading'}
          input={{
            checked: data.enabled,
            id: 'enable-inline-seeding',
            name: 'enableInlineSeeding'
          }}
        />
      </Panel.Section>
    </Panel>
  );
}

function SubaccountLabelValue({ status, subaccount }) {
  if (status === 'loading') {
    return <LabelValueLoadingSkeleton labelValues={[{ value: 'Subaccount' }]} />;
  }

  if (status === 'error') {
    return (
      <LabelValue>
        <LabelValue.Label>Subaccount</LabelValue.Label>
        <LabelValue.Value>Subaccount Name Unavailable</LabelValue.Value>
      </LabelValue>
    );
  }

  return (
    <LabelValue>
      <LabelValue.Label>Subaccount</LabelValue.Label>
      <LabelValue.Value>{subaccountToString(subaccount)}</LabelValue.Value>
    </LabelValue>
  );
}
