import { getSubaccountHeader } from 'src/helpers/api/headers';
import { sparkpost as sparkpostRequest } from 'src/helpers/axiosInstances';
import ErrorTracker from 'src/helpers/errorTracker';
import { setSubaccountHeader } from 'src/helpers/subaccounts';
import { showAlert } from './globalAlert';
import sparkpostApiRequest from './helpers/sparkpostApiRequest';

export function inviteUser(email, access_level, subaccount) {
  const action = {
    type: 'INVITE_USER',
    meta: {
      data: { email, access_level },
      method: 'POST',
      url: '/v1/users/invite',
      headers: setSubaccountHeader(subaccount)
    }
  };

  return sparkpostApiRequest(action);
}

export function deleteUser(username) {
  const action = {
    type: 'DELETE_USER',
    meta: {
      data: { username }, // need in reducer, no user reference in response
      method: 'DELETE',
      url: `/v1/users/${username}`
    }
  };

  return (dispatch) =>
    dispatch(sparkpostApiRequest(action)).then(() =>
      dispatch(
        showAlert({
          type: 'success',
          message: `Deleted ${username}`
        })
      )
    );
}

export function listUsers(subaccountId) {
  return sparkpostApiRequest({
    type: 'LIST_USERS',
    meta: {
      method: 'GET',
      url: '/v1/users',
      showErrorAlert: false,
      headers: getSubaccountHeader(subaccountId)
    }
  });
}

export function updateUser(username, data) {
  const action = {
    type: 'UPDATE_USER',
    meta: {
      method: 'PUT',
      url: `/v1/users/${username}`,
      data: {
        ...data,
        username // need in reducer, no user reference in response
      }
    }
  };

  return (dispatch) =>
    dispatch(sparkpostApiRequest(action)).then(({ message }) =>
      dispatch(showAlert({ type: 'success', message }))
    );
}

export function checkInviteToken(token) {
  const action = {
    type: 'CHECK_INVITE_TOKEN',
    meta: {
      method: 'GET',
      url: `/v1/users/invite/${token}`
    }
  };

  // returns 404 when not found
  return (dispatch) =>
    dispatch(sparkpostApiRequest(action)).catch((error) => {
      ErrorTracker.report('check-invite', error);
    });
}

export function registerUser(token, data) {
  return sparkpostApiRequest({
    type: 'REGISTER_USER',
    meta: {
      method: 'POST',
      url: `/v1/users/register/${token}`,
      data: { ...data, tou_accepted: true }
    }
  });
}

// note, this is a hack needed for registering users for accounts that require TFA, the registration form asks
//  users for first and last name and /users/register doesn't support these fields, an update user request
//  is needed to save these values, however updateUser action uses sparkpostApiRequest that assumed the user
//  is authenticated, for users invited to join an account that requires TFA, will not be considered authenticated
//  until they complete the TFA setup, this is a workaround to use the temporary TFA access token to submit the
//  user update request
export function updateNameOfUserWhenPendingTfaSetup({ first_name, last_name, username }) {
  return (dispatch, getState) => {
    const { tfa } = getState();

    return sparkpostRequest({
      method: 'PUT',
      url: `/v1/users/${username}`,
      headers: {
        Authorization: tfa.token
      },
      data: {
        first_name,
        last_name
      }
    });
  };
}
