import React from 'react';
import { ShowChart } from '@sparkpost/matchbox-icons';
import { sub, isToday, isBefore } from 'date-fns';
import { formatDate } from '@sparkpost/report-builder/helpers/date';
import { useSparkPostQuery } from 'src/hooks';
import { Empty } from 'src/components';
import {
  Button,
  Columns,
  Column,
  Select,
  Layout,
  Panel,
  Stack,
  Text
} from 'src/components/matchbox';
import useUniqueId from 'src/hooks/useUniqueId';
import { getEngagementRecency } from 'src/helpers/api/signals';
import HorizontalBar from '../charts/horizontalbar/HorizontalBar';
import useEngagementRateByCohort from '../../hooks/useEngagementRateByCohort';
import useResourceDetailsContext from '../../hooks/useResourceDetailsContext';
import { roundToPlaces } from '@sparkpost/report-builder/helpers/units';
import { HorizontalBarLoading } from 'src/components/loading';
import UnsubscribeRateByCohortActions from '../actionContent/UnsubscribeRateByCohortActions';
import EngagementRateByCohortActions from '../actionContent/EngagementRateByCohortActions';
import ComplaintsByCohortActions from '../actionContent/ComplaintsByCohortActions';
import { SubduedLink, ExternalLink } from 'src/components/links';
import { PageLink } from 'src/components/links';
import { LINKS } from 'src/constants';
import { formatDataForRecencyByCohort, formatIdFromUrl } from './helpers';
import { TranslatableText } from 'src/components/text';
import { ENGAGEMENT_RECENCY_COHORTS } from 'src/pages/signals/constants/info';

const FACET = {
  campaign: 'campaign_id',
  'ip-pool': 'ip_pool',
  'mailbox-provider': 'mb_provider',
  'sending-domain': 'sending_domain',
  subaccount: 'sid'
};

const COHORT_KEY = {
  New: 'new',
  'Never Engaged': 'uneng',
  'Not Recently Engaged': '365d',
  'Semi Recently Engaged': '90d',
  'Recently Engaged': '14d'
};

const ACTION = {
  ENGAGEMENT_RATE_SELECTED: 'cohort-engagement',
  UNSUBSCRIBE_RATE_SELECTED: 'unsub-cohort',
  COMPLAINT_RATE_SELECTED: 'fbl-cohort'
};

const getEngagementUrlValue = (selectedType) => {
  switch (selectedType) {
    case 'cohort-engagement':
      return 'engagement-rate';
    case 'unsub-cohort':
      return 'unsubscribes';
    case 'fbl-cohort':
      return 'complaints';
    default:
      return '';
  }
};

const getEngagementSuffix = (selectedType) => {
  switch (selectedType) {
    case 'cohort-engagement':
      return '_eng';
    case 'unsub-cohort':
      return '_unsub';
    case 'fbl-cohort':
      return '_fbl';
    default:
      return '';
  }
};

export const formatBreakdownByDataForActions = (apiData) => {
  const { history, ...rest } = apiData;

  return Object.fromEntries(
    Object.entries(rest).map(([key, value]) => [`${key.replace('current_', '')}`, value])
  );
};

export default function EngagementRateByCohortLayout() {
  const selectId = useUniqueId('engagement-by');
  const selectOptions = [
    { label: 'Engagement Rate', value: 'cohort-engagement' },
    { label: 'Unsubscribe Rate', value: 'unsub-cohort' },
    { label: 'Complaint Rate', value: 'fbl-cohort' }
  ];
  const { filters, match: { params: { type, id } = {} } = {} } = useResourceDetailsContext();
  const idFormattedPerType = formatIdFromUrl(type, id);

  const { dispatch, selectedType, get } = useEngagementRateByCohort();

  let toParam;
  let fromParam;
  let engagementUrlValue = getEngagementUrlValue(selectedType);
  let viewFullReportLink = `/signals/engagement/${engagementUrlValue}/${
    FACET[type]
  }/${encodeURIComponent(idFormattedPerType)}`;
  if (filters && filters.to && filters.from) {
    const THREE_DAYS_AGO = new Date(sub(new Date(), { days: 3 }));
    const FOUR_DAYS_AGO = new Date(sub(new Date(), { days: 4 }));
    const TO = new Date(filters.to);
    const FROM = new Date(filters.from);

    if (isToday(TO)) {
      toParam = THREE_DAYS_AGO;
      if (isBefore(toParam, FROM)) fromParam = FOUR_DAYS_AGO;
      else fromParam = FROM;
    } else {
      toParam = TO;
      fromParam = FROM;
    }

    // TODO: https://sparkpost.atlassian.net/browse/SAA-273 -> send date info over
    // viewFullReportLink += `?from=${FROM.toISOString()}&to=${toParam.toISOString()}&range=custom`;
  }

  const apiParam = {
    from: fromParam,
    to: toParam,
    facet: FACET[type],
    filter: idFormattedPerType
  };

  const { status: breakDownDataStatus, data: breakDownData = {} } = useSparkPostQuery(
    () => get(apiParam),
    {
      enabled: Boolean(selectedType)
    }
  );

  const breakdownPercentages =
    breakDownData &&
    breakDownData.data &&
    breakDownData.data.length &&
    breakDownData.data.find((apiData) => {
      return apiData[FACET[type]] === idFormattedPerType;
    });

  const { status: engagementRecencyStatus, data: engagementRecencyData = [] } = useSparkPostQuery(
    () => getEngagementRecency(apiParam)
  );

  const cohortPercentages =
    engagementRecencyData &&
    engagementRecencyData.data &&
    engagementRecencyData.data.length &&
    engagementRecencyData.data.find((apiData) => {
      return apiData[FACET[type]] === idFormattedPerType;
    });

  const getData = () => {
    if (!breakdownPercentages) return [];

    const barNames = [
      'New',
      'Never Engaged',
      'Not Recently Engaged',
      'Semi Recently Engaged',
      'Recently Engaged'
    ];

    const horizontalBarData = barNames.map((name) => {
      const roundLength =
        selectedType === ACTION.UNSUBSCRIBE_RATE_SELECTED ||
        selectedType === ACTION.COMPLAINT_RATE_SELECTED
          ? 3
          : 1;
      const currentCohortKey = `current_p_${COHORT_KEY[name]}${getEngagementSuffix(selectedType)}`;
      return {
        tooltip: ENGAGEMENT_RECENCY_COHORTS[name],
        name,
        value: breakdownPercentages[currentCohortKey],
        percent: breakdownPercentages[currentCohortKey]
          ? roundToPlaces(100 * breakdownPercentages[currentCohortKey], roundLength)
          : 0
      };
    });

    return horizontalBarData.map((x) => x.value).every((element) => element === null)
      ? []
      : horizontalBarData;
  };

  const recencyData = formatDataForRecencyByCohort(cohortPercentages || {}, FACET[type]);
  const actionsData = formatBreakdownByDataForActions(breakdownPercentages || {});

  const formattedDate = formatDate.useMomentInput(toParam);

  return (
    <Layout data-id="engagement-rate-by-cohort">
      <Layout.Section annotated>
        <Layout.SectionTitle>Engagement By Cohort</Layout.SectionTitle>
        <Stack space="100">
          <Text color="gray.700" fontSize="300" font-weight="600">
            <TranslatableText>{formattedDate}</TranslatableText>
          </Text>
          <SubduedLink as={ExternalLink} to={LINKS.ENGAGEMENT_RECENCY_DOCS}>
            Learn more about engagement recency.
          </SubduedLink>
          {engagementRecencyStatus === 'success' &&
            breakDownDataStatus === 'success' &&
            getData().length !== 0 && (
              <>
                {selectedType === ACTION.UNSUBSCRIBE_RATE_SELECTED && (
                  <UnsubscribeRateByCohortActions
                    unsubscribeByCohort={actionsData}
                    recencyByCohort={recencyData}
                    inSubtitle={true}
                  />
                )}
                {selectedType === ACTION.ENGAGEMENT_RATE_SELECTED && (
                  <EngagementRateByCohortActions
                    engagementByCohort={actionsData}
                    recencyByCohort={recencyData}
                    date={toParam}
                    inSubtitle={true}
                    facet={FACET[type]}
                    facetId={id}
                  />
                )}
                {selectedType === ACTION.COMPLAINT_RATE_SELECTED && (
                  <ComplaintsByCohortActions
                    complaintsByCohort={actionsData}
                    recencyByCohort={recencyData}
                    date={toParam}
                    inSubtitle={true}
                  />
                )}
              </>
            )}
        </Stack>
      </Layout.Section>
      <Layout.Section>
        <Panel>
          <Panel.Section>
            <Panel.Action as={PageLink} to={viewFullReportLink}>
              View Full Report <Button.Icon as={ShowChart} />
            </Panel.Action>
            <Columns>
              <Column width={1 / 2}>
                <Select
                  label="Type"
                  id={selectId}
                  value={selectedType}
                  disabled={breakDownDataStatus === 'loading'}
                  options={selectOptions}
                  onChange={(event) => dispatch({ type: event.target.value })}
                  placeholder="Select Resource"
                  placeholderValue="placeholder"
                />
              </Column>
            </Columns>
          </Panel.Section>

          {breakDownDataStatus === 'loading' && (
            <Panel.Section>
              <HorizontalBarLoading
                iterableRows={[
                  { label: 'New' },
                  { label: 'Never Engaged' },
                  { label: 'Not Recently Engaged' },
                  { label: 'Semi Recently Engaged' },
                  { label: 'Recently Engaged' }
                ]}
              />
            </Panel.Section>
          )}

          {breakDownDataStatus === 'success' && getData().length !== 0 && (
            <Panel.Section>
              <HorizontalBar data={getData()} xKey="value" yKey="name" labelListKey="percent" />
            </Panel.Section>
          )}

          {breakDownDataStatus === 'success' && getData().length === 0 && (
            <Empty message="No results found." />
          )}

          {breakDownDataStatus === 'error' && (
            <Empty message="Failed to load the data. Refresh page." />
          )}
        </Panel>
      </Layout.Section>
    </Layout>
  );
}
