import React from 'react';
import styled from 'styled-components';
import { Search } from '@sparkpost/matchbox-icons';
import { FORMATS, SIGNALS_RELATIVE_DATE_OPTIONS } from 'src/constants';
import { ListBox, TextField } from 'src/components/matchbox';
import MultiCheckboxDropdown from 'src/components/MultiCheckboxDropdown';
import DatePicker from 'src/components/datePicker/DatePickerV2';
import { SORT_BY_OPTIONS } from './constants';

const FilterFields = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.space['400']};
  grid-template-columns: 1fr;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    grid-gap: ${(props) => props.theme.space['500']};
    grid-template-columns: 1fr 1.5fr 1fr 1fr; /* Static column width to prevent re-sizing when the user updates their selection */
  }
`;

export function BlocklistIncidentsFilters({
  disabled,
  from,
  to,
  relativeRange,
  search,
  onDateChange,
  onSearchChange,
  onSortByChange,
  statusCheckboxes = []
}) {
  return (
    <FilterFields>
      <DatePicker
        from={new Date(from)}
        to={new Date(to)}
        relativeRange={relativeRange}
        label="Date Range"
        onChange={onDateChange}
        dateFieldFormat={FORMATS.DATE_FNS.DATE}
        relativeDateOptions={SIGNALS_RELATIVE_DATE_OPTIONS}
        hideManualEntry
        disabled={disabled}
      />

      <TextField
        key={disabled ? 'disabled-search' : 'not-disabled-search'} // See: https://stackoverflow.com/a/41717743/3330688 and https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
        label="Search Blocklist or Resource"
        id="incident-search-blocklist-or-resource"
        placeholder="e.g. Spamhaus, 00.000.00"
        prefix={<Search />}
        onChange={onSearchChange}
        disabled={disabled}
        defaultValue={search} // The value is *not* always controlled - only initially stemming from the URL query string params
      />

      {/* No change handler is needed here - the `useMultiCheckbox` hook handles state changes and values */}
      <MultiCheckboxDropdown
        label="Status"
        id="incident-status"
        screenReaderDirections="Checkboxes filter the table. When checked, table rows without the selected value are hidden."
        checkboxes={statusCheckboxes}
        disabled={disabled}
        allowEmpty={false}
      />

      <ListBox
        key={disabled ? 'disabled-sort-by' : 'not-disabled-sort-by'} // See: https://stackoverflow.com/a/41717743/3330688 and https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
        label="Sort By"
        id="incident-sort-by"
        onChange={onSortByChange}
        defaultValue={SORT_BY_OPTIONS[0].value}
        disabled={disabled}
      >
        {SORT_BY_OPTIONS.map((option, index) => {
          return (
            <ListBox.Option key={`${option.value}-${index}`} value={option.value}>
              {option.label}
            </ListBox.Option>
          );
        })}
      </ListBox>
    </FilterFields>
  );
}
