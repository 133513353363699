import React from 'react';
import { Banner, Stack } from 'src/components/matchbox';

export function SubjectCampaignsWarning(props) {
  const { type } = props;

  return (
    <>
      {/* alert role used to inform screen reader users of the presence of the error since it renders before interactive content in the DOM */}
      {/* negative tabIndex used to allow programmatic focus handling */}
      <div role="alert" tabIndex="-1" data-id={props['data-id']}>
        <Banner size="small" status="danger">
          <Stack space="200">
            <p>
              Current sending metrics will automatically be removed if a Campaign (Subject){' '}
              <span>{type}</span> is applied.
            </p>
          </Stack>
        </Banner>
      </div>
    </>
  );
}
