import { Box, Text } from 'src/components/matchbox';
import styled from 'styled-components';

export const SectionTitle = styled(Text)`
  color: ${({ theme }): string => theme.colors['gray']['700']};
  margin-bottom: ${({ theme }): string => theme.space[400]};
  font-size: ${({ theme }): string => theme.fontSizes['300']};
`;

export const EventContentWrapper = styled(Box)`
  display: grid;
  margin-bottom: ${({ theme }): string => theme.space['500']};

  @media (min-width: ${({ theme }): string => theme.breakpoints[1]}) {
    grid-template-columns: 50% 50%;
  }
`;

export const LayoutWrapper = styled.div`
  @media (max-width: ${({ theme }): string => theme.breakpoints[2]}) {
    > div > div {
      flex-wrap: wrap-reverse;
      gap: ${({ theme }): string => theme.space['400']};
    }
  }
`;
