import { useState, useEffect } from 'react';
import _ from 'lodash';
import { HIBANA_METRICS_COLORS } from 'src/constants';

const colorArray = [...HIBANA_METRICS_COLORS] as const;

type Color = typeof colorArray[number];

interface ColorObject {
  [key: string]: Color;
}

export function useColors(keys: string[] = []): ColorObject {
  const [colors, setColors] = useState<ColorObject>({});
  const [colorIndex, setColorIndex] = useState(0);

  useEffect(() => {
    const assignedKeys = Object.keys(colors);
    const removedKeys = _.without(assignedKeys, ...keys);
    const newKeys = _.without(keys, ...assignedKeys);

    if (newKeys.length === 0 && removedKeys.length === 0) {
      return;
    }

    const newColors = {
      ...colors
    };

    removedKeys.forEach((key) => {
      delete newColors[key];
    });

    newKeys.forEach((key, index) => {
      newColors[key] = HIBANA_METRICS_COLORS[(colorIndex + index) % HIBANA_METRICS_COLORS.length];
    });

    setColorIndex((colorIndex + newKeys.length) % HIBANA_METRICS_COLORS.length);
    setColors(newColors);
  }, [keys, colors, setColors, colorIndex, setColorIndex]);

  return colors;
}
