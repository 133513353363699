import qs from 'qs';

/**
 * qsParseWithPlusSignEncoded
 *
 * A helper function to parser an URL query string with a plus sign (`+`) (and not a space)
 *
 * @param { String } str - URL query string
 * @return { { [key: string]: string } } query string object parsed (with `+` encoded right)
 */
export function qsParseWithPlusSignEncoded(str) {
  const safeStr = str.replace(/\+/g, '%2B');
  return qs.parse(safeStr);
}
