import React from 'react';
import { useSelector } from 'react-redux';
import { PageLink } from 'src/components/links';
import { Stack } from 'src/components/matchbox';
import { Settings } from '@sparkpost/matchbox-icons';
import { hasGrants } from 'src/helpers/conditions';

const SendingDomainSubTitle = function ({ id }) {
  const canManageSendingDomains = useSelector((state) =>
    hasGrants('sending_domains/manage')(state)
  );

  return (
    <>
      <Stack space="200">
        {canManageSendingDomains && (
          <PageLink to={`/domains/details/sending-bounce/${id}`} fontSize="200">
            <span>Sending Domain Settings&nbsp;</span>
            <Settings />
          </PageLink>
        )}
      </Stack>
    </>
  );
};

export default SendingDomainSubTitle;
