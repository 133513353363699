import React from 'react';
import { Drawer as MatchboxDrawer, DrawerProps, useDrawer } from '@sparkpost/matchbox';
import { PORTALS } from 'src/constants';
import { TrackDrawer } from 'src/components/tracking/TrackDrawer';

type DrawerComponent = (props: DrawerProps) => JSX.Element;

type Drawer = Pick<typeof MatchboxDrawer, 'Content' | 'Footer' | 'Header' | 'displayName'> & {
  useDrawer: typeof useDrawer;
} & DrawerComponent;

const isDrawerHeader = (node: React.ReactNode): boolean => {
  const child = node as JSX.Element;
  return child?.type && child.type.displayName === 'Drawer.Header';
};

/**
 * Matchbox Drawer component
 * @see https://design.sparkpost.com/components/drawer/
 */
const Drawer: Drawer = (props) => {
  let drawerTitle = '';

  React.Children.map(props.children, function (node) {
    if (isDrawerHeader(node)) {
      const child = node as JSX.Element;
      if (React.isValidElement(child.props.children)) {
        React.Children.map(child.props.children, function (c) {
          if (c?.type && c.type.displayName === 'ScreenReaderOnly')
            drawerTitle = c.props.children as string;
        });
      } else {
        drawerTitle = child.props.children as string;
      }
    }
  });

  if (!drawerTitle && props.open) {
    // eslint-disable-next-line
    console.warn(
      'This drawer will not be tracked with a correct name. Please use `Drawer.Header` along with `ScreenReaderOnly` to avoid this problem'
    );
  }

  return (
    <TrackDrawer open={props.open} title={drawerTitle}>
      <MatchboxDrawer portalId={PORTALS.DRAWER.ID} {...props} />
    </TrackDrawer>
  );
};

Drawer.displayName = 'Drawer';
Drawer.Footer = MatchboxDrawer.Footer;
Drawer.Content = MatchboxDrawer.Content;
Drawer.Header = MatchboxDrawer.Header;
Drawer.useDrawer = useDrawer;

Drawer.Footer.displayName = 'Drawer.Footer';
Drawer.Content.displayName = 'Drawer.Content';
Drawer.Header.displayName = 'Drawer.Header';

export default Drawer;
