import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showAlert } from 'src/actions/globalAlert';
import { list as getSubaccountsList } from 'src/actions/subaccounts';
import { createWebhook, getEventDocs } from 'src/actions/webhooks';
import { Loading } from 'src/components';
import { PageLink } from 'src/components/links';
import { Page } from 'src/components/matchbox';
import { setSubaccountQuery } from 'src/helpers/subaccounts';
import { selectWebhookEventListing } from 'src/selectors/eventListing';
import WebhookForm from './components/WebhookForm';

export class WebhooksCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCheckboxBanner: false
    };
  }

  componentDidMount() {
    this.props.getEventDocs();
    this.props.getSubaccountsList();
  }

  onSubmitClick = (values, isInvalid) => {
    const { showAlert } = this.props;

    const noEventSelected = Object.entries(values.events || {}).every(
      (event) => event[1] === false
    );

    if (isInvalid) {
      showAlert({
        type: 'error',
        message: 'Something went wrong.'
      });
    }

    if (noEventSelected) {
      return this.setState({ showCheckboxBanner: true });
    } else {
      return this.setState({ showCheckboxBanner: false });
    }
  };

  /*
    Makes a webhook object from form values and calls the createWebhook action
    with it. Invoked in the form's onSubmit func
  */
  create = (values) => {
    const { createWebhook, showAlert } = this.props;
    const { name, target, subaccount, auth, assignTo } = values;
    const webhook = { name, target };

    webhook.events = Object.keys(values.events || {}).filter((key) => values.events[key]);
    webhook.exception_subaccounts = Object.keys(values.exception_subaccounts || {})
      .filter((key) => values.exception_subaccounts[key])
      .map((subaccountId) => Number(subaccountId));

    if (assignTo !== 'all' || webhook.exception_subaccounts.length === 0) {
      webhook.exception_subaccounts = undefined;
    }

    // builds the webhooks auth details from the form values
    switch (auth) {
      case 'basic':
        webhook.auth_type = 'basic';
        webhook.auth_credentials = {
          username: values.basicUser,
          password: values.basicPass
        };
        break;
      case 'oauth2':
        webhook.auth_type = 'oauth2';
        webhook.auth_request_details = {
          url: values.tokenURL,
          body: {
            client_id: values.clientId,
            client_secret: values.clientSecret
          }
        };
        break;
      default:
        // none
        break;
    }

    let subaccountId;

    // Value from the radio group
    if (assignTo === 'master') {
      subaccountId = 0;
    }

    // 'values.subaccount' only available if assignTo = 'subaccount'
    if (assignTo === 'subaccount') {
      subaccountId = subaccount.id;
    }

    return createWebhook({ webhook, subaccount: subaccountId }).then(() =>
      showAlert({
        type: 'success',
        message: (
          <>
            Webhook created!
            <br /> A test batch has been sent to Target URL.
          </>
        )
      })
    );
  };

  componentDidUpdate(prevProps) {
    const { webhook, history } = this.props;

    // Handles the history push after create
    if (_.isEmpty(prevProps.webhook) && !_.isEmpty(webhook)) {
      history.push(`/webhooks/details/${webhook.id}${setSubaccountQuery(webhook.subaccount)}`);
    }
  }

  render() {
    const { eventListing, eventsLoading } = this.props; // Form doesn't load until we have events

    if (eventListing.length === 0 && eventsLoading) {
      return <Loading />;
    }

    return (
      <Page
        title="Create Webhook"
        breadcrumbAction={{ content: 'Webhooks', Component: PageLink, to: '/webhooks' }}
      >
        <WebhookForm
          newWebhook={true}
          onSubmit={this.create}
          showCheckboxBanner={this.state.showCheckboxBanner}
          onSubmitClick={this.onSubmitClick}
        />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  webhook: state.webhooks.webhook,
  eventsLoading: state.webhooks.docsLoading,
  eventListing: selectWebhookEventListing(state)
});

const CreatePage = withRouter(
  connect(mapStateToProps, { createWebhook, getEventDocs, showAlert, getSubaccountsList })(
    WebhooksCreate
  )
);

export default CreatePage;
