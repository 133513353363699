import { Refresh } from '@sparkpost/matchbox-icons';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserUIOptions } from 'src/actions/currentUser';
import { ConfirmationModal } from 'src/components';
import {
  Banner,
  Box,
  Button,
  Inline,
  LabelValue,
  Layout,
  Panel,
  Stack,
  Text
} from 'src/components/matchbox';
import { SubduedText } from 'src/components/text';
import {
  generateBackupCodes,
  generateTwoFactorInvite,
  resetTwoFactorConfig
} from 'src/helpers/api/users';
import { useAlert, useModal, useSparkPostMutation } from 'src/hooks';
import BackupCodeAuth0Modal from './BackupCodeAuth0Modal';

// TODO: Make "multi-factor authentication (MFA)" an external link like in the mocks: https://sparkpost.atlassian.net/browse/AC-2874
const ResetContent = function () {
  return (
    <>
      <Text mb="100">
        Various multi-factor authentication (MFA) methods can be used for verification, including:
      </Text>
      <ul>
        <li>Authenticator application (e.g. Google Authenticator, Duo, Authy, etc)</li>
        <li>SMS</li>
      </ul>
      <Text mt="500">
        The Reset and Change MFA Method button will open a new window. By default, the authenticator
        application method setup is shown. To set up MFA for a different method, click the Use
        Another Method or the Recovery Code button.
      </Text>
    </>
  );
};

interface NewTfaManagerContentProps {
  enabled: string | undefined;
  enabledValue: string | undefined;
  username: string;
}

const NewTfaManagerContent = function (props: NewTfaManagerContentProps): JSX.Element {
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const { enabled, enabledValue, username } = props;
  const {
    closeModal: closeConfirmModal,
    openModal: openConfirmModal,
    isModalOpen: isConfirmModalOpen
  } = useModal();
  const {
    closeModal: closeBackupModal,
    openModal: openBackupModal,
    isModalOpen: isBackupModalOpen
  } = useModal();
  const [backupCode, setBackupCode] = useState<Array<object>>([]);

  const handleInviteUserSuccess = (data: { ticket_url: string }): void => {
    dispatch(
      updateUserUIOptions({
        has_recovery_code: true
      })
    );

    if (data?.ticket_url) {
      // TODO: Investigate a way to keep track of this window being open/closed (taking into account the react lifecycle and re-renders)
      window.open(data?.ticket_url, 'mfa', 'width=800,height=900,toolbar=0,menubar=0,location=0');
    }
  };

  const handleInviteUserError = (err: AxiosError): void => {
    showAlert({ type: 'error', message: err?.message });
  };

  const inviteUserMutation = useSparkPostMutation(() => generateTwoFactorInvite(username), {
    onSuccess: handleInviteUserSuccess,
    onError: handleInviteUserError
  });

  const resetMutation = useSparkPostMutation(() => resetTwoFactorConfig(username), {
    onSuccess: () => {
      inviteUserMutation.mutate({});
    },
    onError: (err: AxiosError) => {
      showAlert({ type: 'error', message: err?.message });
    }
  });

  const handleGenerateBackupCodeSuccess = (resp: { codes: Array<object> }): void => {
    setBackupCode(resp.codes);
    openBackupModal();
  };

  const handleGenerateBackupCodeError = (err: AxiosError): void => {
    showAlert({ type: 'error', message: 'Something went wrong.', details: err.message });
    closeBackupModal();
  };

  const generateBackupCodeMutation = useSparkPostMutation(() => generateBackupCodes({ username }), {
    onSuccess: handleGenerateBackupCodeSuccess,
    onError: handleGenerateBackupCodeError
  });

  if (enabled)
    return (
      <>
        <Layout.Section>
          <Panel mb="-1px">
            <Panel.Section>
              {enabled && (
                <Panel.Action
                  onClick={openConfirmModal}
                  disabled={resetMutation.status === 'loading'}
                >
                  Change MFA Method <Refresh />
                </Panel.Action>
              )}

              <Box mb="500">
                <SubduedText fontWeight="400">Multi-Factor Authentication (MFA) Method</SubduedText>
              </Box>

              <Stack>
                <Inline space="500">
                  <LabelValue>
                    <LabelValue.Label>Authentication Method</LabelValue.Label>
                    <LabelValue.Value>{enabled}</LabelValue.Value>
                  </LabelValue>
                  {enabled === 'SMS' && (
                    <LabelValue>
                      <LabelValue.Label>Phone Number</LabelValue.Label>
                      <LabelValue.Value>{enabledValue}</LabelValue.Value>
                    </LabelValue>
                  )}
                </Inline>
              </Stack>
            </Panel.Section>

            <Panel.Section>
              <Panel.Action
                onClick={generateBackupCodeMutation.mutate}
                disabled={generateBackupCodeMutation.status === 'loading'}
              >
                Regenerate Recovery Code <Refresh />
              </Panel.Action>
              <Stack>
                <SubduedText fontWeight="400">Recovery Code</SubduedText>

                <Text>
                  A recovery code was generated during MFA enrollment to enable login. If the
                  recovery code is lost or inaccessible, generate a new one.
                </Text>
              </Stack>
            </Panel.Section>
          </Panel>
        </Layout.Section>

        <BackupCodeAuth0Modal
          open={isBackupModalOpen}
          onClose={closeBackupModal}
          codes={backupCode}
        />

        <ConfirmationModal
          open={isConfirmModalOpen}
          title="Change the Multi-Factor Authentication (MFA) Method"
          content={
            <>
              <Banner mb="300" size="small" status="info">
                <Text>
                  The Reset and Change MFA Method button will open a new browser window. Close out
                  of that window once the method has been updated.
                </Text>
              </Banner>
              <ResetContent />
            </>
          }
          onConfirm={resetMutation.mutate}
          onCancel={closeConfirmModal}
          confirmVerb="Reset and Change MFA Method"
        />
      </>
    );

  return (
    <Layout.Section>
      <Panel>
        <Panel.Section>
          <Banner mb="500" size="small" status="warning">
            <Text>Once Multi-Factor Authentication has been enabled. It cannot be disabled.</Text>
          </Banner>

          <ResetContent />
        </Panel.Section>
        <Panel.Section>
          <Button
            type="submit"
            variant="secondary"
            useMatchboxVariant={false}
            onClick={inviteUserMutation.mutate}
          >
            Enable Multi-Factor Authentication
          </Button>
        </Panel.Section>
      </Panel>
    </Layout.Section>
  );
};

export default NewTfaManagerContent;
