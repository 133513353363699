import { Close } from '@sparkpost/matchbox-icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ButtonLink } from 'src/components/links';
import { Button, ScreenReaderOnly } from 'src/components/matchbox';
// eslint-disable-next-line local/no-css-module-import
import styles from './FullBanner.module.scss';

interface FullBannerProps {
  children: React.ReactElement;
  className: string;
}

function FullBanner(props: FullBannerProps) {
  const { children, className } = props;

  return (
    <div className={classNames(styles.FullBanner, className)} role="alert">
      <div className={styles.FullBannerContainer}>{children}</div>
    </div>
  );
}

function Icon({ as }: $TODOFIXME) {
  const Component = as;
  return <Component className={styles.Icon} />;
}

function CloseButton({ onClick, variant }: $TODOFIXME) {
  return (
    <Button variant={variant} className={styles.CloseButton} flat onClick={onClick}>
      <Close />
      <ScreenReaderOnly>Close</ScreenReaderOnly>
    </Button>
  );
}

function Link({ as, ...rest }: $TODOFIXME) {
  const Component = as;
  return <Component {...rest} className={styles.Link} />;
}

FullBanner.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

Link.defaultProps = {
  as: ButtonLink
};

FullBanner.CloseButton = CloseButton;
FullBanner.Icon = Icon;
FullBanner.Link = Link;

export default FullBanner;
