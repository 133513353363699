import { tokens } from '@sparkpost/design-tokens';
import styled from 'styled-components';

function getColumnSize(count: number): number {
  return count < 2 ? 2 : count;
}

export const ColumnList = styled.div`
  padding: inherit;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: ${(props: { count: number }): string =>
    `repeat(${getColumnSize(props.count)}, auto)`};
  grid-auto-flow: column;
  word-wrap: break-word;
  margin-left: ${tokens.spacing_500};
`;
