import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Page } from 'src/components/matchbox';
import { PageDescription } from 'src/components/text';
import { PageLink } from 'src/components/links';
import { SeedingActivity } from './components';

export function SeedingActivityPage() {
  const { subaccountId } = useParams();
  const { state } = useLocation();
  const getSeedListSettingUrl = () => {
    if (subaccountId === undefined) {
      if (state) {
        if (state.fromAutomatic) {
          return '/seedlist/automatic';
        } else {
          return '/seedlist/manual';
        }
      }
    }
    return '/seedlist';
  };
  return (
    <Page
      title="Automatic Seeding Activity"
      breadcrumbAction={{
        content: 'Seed List Settings',
        to: getSeedListSettingUrl(),
        as: PageLink
      }}
    >
      <PageDescription>
        Once a selected campaign is eligible for or actively being seeded, it will be added here.
      </PageDescription>

      <SeedingActivity />
    </Page>
  );
}
