import React, { forwardRef } from 'react';
import { TextField as MatchboxTextField } from '@sparkpost/matchbox';
import { FIELD_MAX_WIDTH } from 'src/constants';
import { Box } from 'src/components/matchbox';

/**
 * Matchbox TextField component.
 * @see https://design.sparkpost.com/components/textfield/
 */
const TextField: typeof MatchboxTextField = forwardRef(
  ({ maxWidth, ...rest }, ref): JSX.Element => (
    <Box maxWidth={maxWidth ? maxWidth : FIELD_MAX_WIDTH}>
      <MatchboxTextField ref={ref} {...rest} />
    </Box>
  )
);

TextField.displayName = 'TextField';

export default TextField;
