import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { CookieConsent, GlobalAlertWrapper, SiftScience } from 'src/components';
import AppRoutes from 'src/components/appRoutes';
import { AuthenticationGate, SuspensionAlerts } from 'src/components/auth';
import GoogleTagManager from 'src/components/googleTagManager/GoogleTagManager';
import Layout from 'src/components/layout/Layout';
import Support from 'src/components/support/Support';
import GlobalBanner from 'src/context/GlobalBanner';
import SidebarProvider from 'src/context/SidebarContext';
import RudderStack from './components/rudderstack/RudderStack';
import VisualWebsiteOptimizer from './components/vwo/VisualWebsiteOptimizer';

import isbot from 'isbot';
import { BrowserRouter } from 'react-router-dom';
import config from 'src/appConfig';

const App = ({ RouterComponent = BrowserRouter }) => (
  <>
    <RouterComponent>
      <div>
        {config.siftScience && <SiftScience config={config.siftScience} />}
        {config.gtmId && <GoogleTagManager id={config.gtmId} />}
        <VisualWebsiteOptimizer />
        {!isbot(window.navigator.userAgent) && config?.rudderstack?.enabled && <RudderStack />}

        <AuthenticationGate />
        <SuspensionAlerts />
        <CookieConsent />
        <GlobalBanner>
          <SidebarProvider>
            <Layout>
              <AppRoutes />
            </Layout>
          </SidebarProvider>
        </GlobalBanner>
        <Support />
        <GlobalAlertWrapper />
      </div>
    </RouterComponent>
    <ReactQueryDevtools initialIsOpen={false} />
  </>
);

export default App;
