import React from 'react';
import { useSparkPostQuery } from 'src/hooks';
import { PageLink } from 'src/components/links';
import { Stack } from 'src/components/matchbox';
import { Settings } from '@sparkpost/matchbox-icons';
import { getSendingIp } from 'src/helpers/api/sendingIps';
import useResourceDetailsContext from '../../hooks/useResourceDetailsContext';

function SendingIpSubTitle() {
  const { match: { params: { id } = {} } = {} } = useResourceDetailsContext();
  const { status: sendingIpDataStatus, data: sendingIpData } = useSparkPostQuery(() =>
    getSendingIp(id)
  );
  if (sendingIpDataStatus === 'loading' || sendingIpDataStatus === 'error') return null;
  return (
    <Stack space="200">
      <PageLink to={`/account/ip-pools/edit/${sendingIpData?.ip_pool}/${id}`} fontSize="200">
        <span>Sending IP Settings&nbsp;</span>
        <Settings />
      </PageLink>
    </Stack>
  );
}

export default SendingIpSubTitle;
