import React from 'react';
import { RecipientValidationPriceTable } from 'src/components';
import { Panel, Modal } from 'src/components/matchbox';
import styles from './RVPriceModal.module.scss';

export default function RVPriceModal({ isOpen, handleOpen }) {
  return (
    <Modal.LEGACY
      open={isOpen}
      onClose={() => handleOpen(false)}
      showCloseButton
      title="Recipient Validation Price Modal"
    >
      <Panel.LEGACY title="How was this calculated?">
        <Panel.LEGACY.Section>
          <RecipientValidationPriceTable
            cellProps={{
              className: styles.rvModalCell
            }}
          />
        </Panel.LEGACY.Section>
      </Panel.LEGACY>
    </Modal.LEGACY>
  );
}
