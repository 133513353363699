import styled from 'styled-components';

function getColumnSize(count: number): number {
  return count < 2 ? 2 : count;
}

export const ColumnList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: ${(props: { count: number }): string =>
    `repeat(${getColumnSize(props.count)}, auto)`};
  grid-auto-flow: column;
  word-wrap: break-word;
  margin-top: ${({ theme }): string => theme.space['200']};
`;
