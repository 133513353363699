import _ from 'lodash';

export const rekey = (obj, nextKey) =>
  Object.keys(obj).reduce(
    (acc, key) => ({ ...acc, [obj[key][nextKey]]: { ...obj[key], key } }),
    {}
  );

export const toCollection = (obj) =>
  Object.keys(obj).reduce((acc, key) => [...acc, { ...obj[key], key }], []);

/**
 * @description Determines whether all object keys are empty
 * @param {object} obj - Any arbitrary object
 * @returns {boolean}
 */
export const areAllKeysEmpty = (obj) => {
  for (let key in obj) {
    if (!_.isEmpty(obj[key])) {
      return false;
    }
  }

  return true;
};

export const removeEmpty = (obj) => {
  // eslint-disable-next-line
  return Object.fromEntries(Object.entries(obj).filter(([_key, value]) => value != null));
};
