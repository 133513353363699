import React from 'react';
import { LINKS } from 'src/constants';
import { Box, EmptyState, Page, Stack, Text } from 'src/components/matchbox';
import { TranslatableText, Heading, Bold } from 'src/components/text';
import ConfigurationWebp from '@sparkpost/matchbox-media/images/Configuration.webp';
import Configuration from '@sparkpost/matchbox-media/images/Configuration@medium.jpg';

export function SeedListPromoPage() {
  return (
    <Page>
      <EmptyState>
        <EmptyState.Header>Seed List</EmptyState.Header>
        <EmptyState.Content>
          <Stack>
            <Text>
              <TranslatableText>
                Add seeds to your sending and get the most out of your analytics with deliverability
                metrics only available through SparkPost's&nbsp;
              </TranslatableText>
              <Bold>Signals Deliverability.</Bold>
            </Text>
            <Box>
              <Heading as="h2" looksLike="h3">
                Deliverability Metrics
              </Heading>
              <Text>
                Gain insights directly from the inbox with SparkPost's Deliverability Metrics.
                Uncover exactly when spam issues are detected via near real-time feedback from real
                inboxes.
              </Text>
            </Box>

            <Box>
              <Heading mb="300" as="h2" looksLike="h3">
                Automatic Seeding
              </Heading>

              <Text>
                Send with SparkPost and leave the seeding to us. Automatic Seeding ensures that
                every message you send has the potential to power effective analytics.
              </Text>
            </Box>
          </Stack>
        </EmptyState.Content>

        <EmptyState.Image src={Configuration} seeThrough>
          <source srcSet={ConfigurationWebp} type="image/webp" />
        </EmptyState.Image>

        <EmptyState.Action external to={LINKS.DELIVERABILITY_ANALYTICS_DEMO}>
          Add Deliverability Plan
        </EmptyState.Action>

        <EmptyState.Action variant="outline" external to={LINKS.DELIVERABILITY_ANALYTICS}>
          Learn More
        </EmptyState.Action>
      </EmptyState>
    </Page>
  );
}
