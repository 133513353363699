import _ from 'lodash';
import { fetch as fetchAccount } from 'src/actions/account';
import { refresh } from 'src/actions/auth';
import { showAlert } from 'src/actions/globalAlert';
import requestHelperFactory from 'src/actions/helpers/requestHelperFactory';
import { sparkpost as sparkpostAxios } from 'src/helpers/axiosInstances';
import ErrorTracker from 'src/helpers/errorTracker';
import { getRefreshToken } from 'src/helpers/http';
import { resolveOnCondition } from 'src/helpers/promise';
import sessionEmitter from 'src/helpers/sessionEmitter';

const maxRefreshRetries = 3;

export const refreshTokensUsed = new Set();

export let refreshing = false;

// Re-dispatches a given action after we finish refreshing the auth token
function redispatchAfterRefresh(action, dispatch) {
  return resolveOnCondition(() => !refreshing).then(() => dispatch(sparkpostRequest(action)));
}

const sparkpostRequest = requestHelperFactory({
  request: sparkpostAxios,
  transformHttpOptions: (options, getState) => {
    const { auth, currentUser } = getState();
    const transformed = { ...options };
    if (auth.loggedIn) {
      _.set(transformed, 'headers.Authorization', auth.token);
    }

    if (currentUser.selectedSubaccount) {
      _.set(transformed, 'headers.x-msys-subaccount', currentUser.selectedSubaccount);
    }
    return transformed;
  },
  onSuccess: ({ response, dispatch, types, meta }) => {
    const results = _.get(response, 'data.results', response.data);
    const links = _.get(response, 'data.links', {});
    const total_count = _.get(response, 'data.total_count');
    dispatch({
      type: types.SUCCESS,
      meta,
      payload: results,
      extra: { links, total_count }
    });

    return meta.onSuccess ? dispatch(meta.onSuccess({ results })) : results;
  },
  onFail: ({ types, err: error, dispatch, meta, action, getState }) => {
    const { message, response = {} } = error;
    const { currentUser, auth } = getState();
    const { retries = 0, showErrorAlert = true } = meta;
    const isAuth0User = currentUser?.auth_migrated;

    if (isAuth0User && response.status === 401) {
      sessionEmitter.emitRefresh({
        redispatchAfterRefresh: () => redispatchAfterRefresh(action, dispatch)
      });
      return;
    }

    // NOTE: if this is a 401 and we have a refresh token, we need to do a
    // refresh to get a new auth token and then re-dispatch this action
    if (response.status === 401 && auth.refreshToken && retries < maxRefreshRetries) {
      action.meta.retries = retries + 1;

      // If we are currently refreshing the token OR if this refresh token
      // has already been used to refresh, we should re-dispatch after refresh is complete
      if (refreshing || refreshTokensUsed.has(auth.refreshToken)) {
        return redispatchAfterRefresh(action, dispatch);
      }

      refreshing = true;
      refreshTokensUsed.add(auth.refreshToken);

      // call API for a new token
      return (
        getRefreshToken(auth.refreshToken) // eslint-disable-line react-hooks/rules-of-hooks
          // dispatch a refresh action to save new token results in cookie and store
          .then(({ data } = {}) => dispatch(refresh(data.access_token, auth.refreshToken)))

          // dispatch the original action again, now that we have a new token ...
          // if anything in this refresh flow blew up, log out
          .then(
            // refresh token request succeeded
            () => {
              refreshing = false;
              return dispatch(sparkpostRequest(action));
            },
            // refresh token request failed
            (err) => {
              refreshing = false;
              sessionEmitter.emitLogout();
              throw err;
            }
          )
      );
    }

    // Re-fetch the account to see if suspended or terminated (handled in AuthenticationGate)
    if (response.status === 403) {
      dispatch(fetchAccount());
    }

    if (response.status === 401) {
      sessionEmitter.emitLogout();
    }

    // any other API error should automatically fail, to be handled in the reducers/components
    dispatch({
      type: types.FAIL,
      payload: { error, message, response },
      meta
    });

    // Don't show alerts for 404s on GET. Otherwise show alerts on request.
    const get404 = response.status === 404 && action.meta.method === 'GET';

    if (!get404 && showErrorAlert) {
      dispatch(
        showAlert({
          type: 'error',
          message: 'Something went wrong.',
          details:
            response.status === 413
              ? 'The size of the CSV file converted into JSON exceeds the limit of 10 MB of the API.'
              : message
        })
      );
    }

    // TODO: Remove this once we unchain all actions
    ErrorTracker.addRequestContextAndThrow(types.FAIL, response, error);
  }
});

export default (action) => (dispatch) => {
  // check for refreshing and exit early, otherwise call factory-made function
  if (refreshing) {
    return redispatchAfterRefresh(action, dispatch);
  }

  return dispatch(sparkpostRequest(action));
};
