/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

// COMPONENTS
import { TranslatableText } from 'src/components/text';
import { Tag } from 'src/components/matchbox';

export const TableWrapper = styled.div`
  overflow-x: auto;

  & > * {
    margin-bottom: 0;
  }
`;

export const LastStatus: React.VFC<{ status: 'Success' | 'Failure' }> = ({ status }) => {
  const isSuccess = status === 'Success';

  return (
    <Tag color={isSuccess ? 'green' : 'red'}>
      <TranslatableText>{status}</TranslatableText>
    </Tag>
  );
};
