import React from 'react';

import ResourceDetails from '../components/ResourceDetails';

import { EVENTS_ROUTE } from 'src/constants';

import { DELIVERABILITY_LINKS_METRIC_KEYS } from '@sparkpost/report-builder/config';
import { apiParams as API_PARAMS } from '../constants/resourceDetails';

import CohortPercentages from '../components/ResourceDetailsLayouts/CohortPercentages';
import DeliverabilityBreakdown from '../components/ResourceDetailsLayouts/DeliverabilityBreakdown';
import DeliverabilityDetails from '../components/ResourceDetailsLayouts/DeliverabilityDetails';
import EngagementRateByCohortLayout from '../components/ResourceDetailsLayouts/EngagementRateByCohortLayout';
import HealthScore from '../components/ResourceDetailsLayouts/HealthScore';
import LinkEngagement from '../components/ResourceDetailsLayouts/LinkEngagement';
import CampaignIdSubTitle from '../components/ResourceDetailsSubTitles/CampaignIdSubTitle';
import IpPoolSubSectionTitle from '../components/ResourceDetailsSubTitles/IpPool';
import MailboxProviderSubTitle from '../components/ResourceDetailsSubTitles/MailboxProviderSubTitle';
import RecipientDomainSubTitle from '../components/ResourceDetailsSubTitles/RecipientDomainSubTitle';
import SendingDomainSubTitle from '../components/ResourceDetailsSubTitles/SendingDomainSubTitle';
import SendingIpSubTitle from '../components/ResourceDetailsSubTitles/SendingIpSubTitle';
import SubaccountSubSectionTitle from '../components/ResourceDetailsSubTitles/Subaccount';
import TemplateSubTitle from '../components/ResourceDetailsSubTitles/TemplateSubTitle';
import CampaignIdTopSection from '../components/ResourceDetailsTopSections/CampaignIdTopSection';
import DataSources from '../components/ResourceDetailsTopSections/DataSources';
import IpPoolTopSection from '../components/ResourceDetailsTopSections/IpPoolTopSection';
import MailboxProviderRegionTopSection from '../components/ResourceDetailsTopSections/MailboxProviderRegionTopSection';
import MailboxProviderTopSection from '../components/ResourceDetailsTopSections/MailboxProviderTopSection';
import RecipientDomainTopSection from '../components/ResourceDetailsTopSections/RecipientDomainTopSection';
import SendingDomainTopSection from '../components/ResourceDetailsTopSections/SendingDomainTopSection';
import SendingIpTopSection from '../components/ResourceDetailsTopSections/SendingIpTopSection';
import SubaccountTopSection from '../components/ResourceDetailsTopSections/SubaccountTopSection';
import SubjectInfoTopSection from '../components/ResourceDetailsTopSections/SubjectInfoTopSection';
import TemplateMetricsTopSection from '../components/ResourceDetailsTopSections/TemplateMetricsTopSection';

import { getMetrics } from '../helpers';

import { isAfter, sub } from 'date-fns';

const TEN_DAYS_AGO = sub(new Date(), { days: 10 });

export const DETAILS_PAGES = {
  subaccount: {
    title: 'Subaccount Details',
    sectionTitle: 'Subaccount',
    tabs: [
      {
        tabName: 'details',
        component: ResourceDetails,
        propKeys: ['pageMeta', 'tabMeta'],
        topSections: [
          {
            name: 'subaccount',
            component: SubaccountTopSection,
            propKeys: [
              'hasD12yProduct',
              'id',
              'deliverabilityData',
              'deliverabilityDataStatus',
              'status'
            ]
          }
        ],
        subSectionTitle: SubaccountSubSectionTitle,
        layouts: [
          {
            name: 'deliverability-details',
            component: DeliverabilityDetails,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          },
          {
            name: 'deliverability-breakdown',
            component: DeliverabilityBreakdown,
            propKeys: ['hasD12yProduct']
          },
          {
            name: 'health-score',
            component: HealthScore,
            propKeys: []
          },
          {
            name: 'cohort-percentages',
            component: CohortPercentages,
            propKeys: []
          },
          {
            name: 'engagement-by-cohort',
            component: EngagementRateByCohortLayout,
            propKeys: []
          },
          {
            name: 'link-engagement',
            component: LinkEngagement,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          }
        ]
      }
    ]
  },
  domain: {
    title: 'Recipient Domain Details',
    sectionTitle: 'Recipient Domain',
    tabs: [
      {
        tabName: 'details',
        component: ResourceDetails,
        propKeys: ['pageMeta', 'tabMeta'],
        topSections: [
          {
            name: 'domain',
            component: RecipientDomainTopSection,
            propKeys: ['hasD12yProduct', 'deliverabilityData', 'deliverabilityDataStatus']
          }
        ],
        subSectionTitle: RecipientDomainSubTitle,
        layouts: [
          {
            name: 'deliverability-details',
            component: DeliverabilityDetails,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          },
          {
            name: 'deliverability-breakdown',
            component: DeliverabilityBreakdown,
            propKeys: ['hasD12yProduct']
          },
          {
            name: 'link-engagement',
            component: LinkEngagement,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          }
        ]
      }
    ]
  },
  'sending-domain': {
    title: 'Sending Domain Details',
    sectionTitle: 'Sending Domain',
    tabs: [
      {
        tabName: 'details',
        component: ResourceDetails,
        propKeys: ['pageMeta', 'tabMeta'],
        topSections: [
          {
            name: 'sending-domain',
            component: SendingDomainTopSection,
            propKeys: ['hasD12yProduct', 'deliverabilityData', 'deliverabilityDataStatus']
          }
        ],
        subSectionTitle: SendingDomainSubTitle,
        layouts: [
          {
            name: 'deliverability-details',
            component: DeliverabilityDetails,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          },
          {
            name: 'deliverability-breakdown',
            component: DeliverabilityBreakdown,
            propKeys: ['hasD12yProduct']
          },
          {
            name: 'cohort-percentages',
            component: CohortPercentages,
            propKeys: []
          },
          {
            name: 'engagement-by-cohort',
            component: EngagementRateByCohortLayout,
            propKeys: []
          },
          {
            name: 'link-engagement',
            component: LinkEngagement,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          }
        ]
      }
    ]
  },
  campaign: {
    title: 'Campaign ID Details',
    sectionTitle: 'Campaign',
    tabs: [
      {
        tabName: 'details',
        component: ResourceDetails,
        propKeys: ['pageMeta', 'tabMeta'],
        topSections: [
          {
            name: 'campaign-id',
            component: CampaignIdTopSection,
            propKeys: [
              'hasD12yProduct',
              'checkboxes',
              'deliverabilityData',
              'deliverabilityDataStatus'
            ]
          }
        ],
        subSectionTitle: CampaignIdSubTitle,
        layouts: [
          {
            name: 'deliverability-details',
            component: DeliverabilityDetails,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          },
          {
            name: 'deliverability-breakdown',
            component: DeliverabilityBreakdown,
            propKeys: ['hasD12yProduct']
          },
          {
            name: 'cohort-percentages',
            component: CohortPercentages,
            propKeys: []
          },
          {
            name: 'engagement-by-cohort',
            component: EngagementRateByCohortLayout,
            propKeys: []
          },
          {
            name: 'link-engagement',
            component: LinkEngagement,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          }
        ]
      },
      {
        tabName: 'content',
        component: () => {
          return <>placeholder</>;
        },
        propKeys: ['pageMeta', 'tabMeta']
      }
    ]
  },
  'subject-campaign': {
    title: 'Campaign Subject Details',
    sectionTitle: 'Campaign',
    tabs: [
      {
        tabName: 'details',
        component: ResourceDetails,
        propKeys: ['pageMeta', 'tabMeta'],
        topSections: [
          {
            name: 'data-sources',
            component: DataSources,
            propKeys: ['hasD12yProduct', 'deliverabilityData', 'deliverabilityDataStatus']
          },
          {
            name: 'subject-info',
            component: SubjectInfoTopSection,
            propKeys: ['id']
          }
        ],
        layouts: [
          {
            name: 'deliverability-details',
            component: DeliverabilityDetails,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          },
          {
            name: 'deliverability-breakdown',
            component: DeliverabilityBreakdown,
            propKeys: ['hasD12yProduct']
          }
        ]
      },
      {
        tabName: 'content',
        component: () => {
          return <>placeholder</>;
        }
      }
    ]
  },
  template: {
    title: 'Template Details',
    sectionTitle: 'Template',
    tabs: [
      {
        tabName: 'details',
        component: ResourceDetails,
        propKeys: ['pageMeta', 'tabMeta'],
        topSections: [
          {
            name: 'template-metrics',
            component: TemplateMetricsTopSection,
            propKeys: ['hasD12yProduct', 'deliverabilityData', 'deliverabilityDataStatus']
          }
        ],
        subSectionTitle: TemplateSubTitle,
        layouts: [
          {
            name: 'deliverability-details',
            component: DeliverabilityDetails,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          },
          {
            name: 'deliverability-breakdown',
            component: DeliverabilityBreakdown,
            propKeys: ['hasD12yProduct']
          },
          {
            name: 'link-engagement',
            component: LinkEngagement,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          }
        ]
      }
    ]
  },
  'sending-ip': {
    title: 'Sending IP Details',
    sectionTitle: 'Sending IP',
    tabs: [
      {
        tabName: 'details',
        component: ResourceDetails,
        propKeys: ['pageMeta', 'tabMeta'],
        topSections: [
          {
            name: 'sending-ip',
            component: SendingIpTopSection,
            propKeys: ['hasD12yProduct', 'deliverabilityData', 'deliverabilityDataStatus']
          }
        ],
        subSectionTitle: SendingIpSubTitle,
        layouts: [
          {
            name: 'deliverability-details',
            component: DeliverabilityDetails,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          },
          {
            name: 'deliverability-breakdown',
            component: DeliverabilityBreakdown,
            propKeys: ['hasD12yProduct']
          },
          {
            name: 'link-engagement',
            component: LinkEngagement,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          }
        ]
      }
    ]
  },
  'ip-pool': {
    title: 'IP Pool Details',
    sectionTitle: 'IP Pool',
    tabs: [
      {
        tabName: 'details',
        component: ResourceDetails,
        propKeys: ['pageMeta', 'tabMeta'],
        topSections: [
          {
            name: 'ippool',
            component: IpPoolTopSection,
            propKeys: [
              'hasD12yProduct',
              'id',
              'data',
              'status',
              'deliverabilityData',
              'deliverabilityDataStatus'
            ]
          }
        ],
        subSectionTitle: IpPoolSubSectionTitle,
        layouts: [
          {
            name: 'deliverability-details',
            component: DeliverabilityDetails,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          },
          {
            name: 'deliverability-breakdown',
            component: DeliverabilityBreakdown,
            propKeys: ['hasD12yProduct']
          },
          {
            name: 'cohort-percentages',
            component: CohortPercentages,
            propKeys: []
          },
          {
            name: 'engagement-by-cohort',
            component: EngagementRateByCohortLayout,
            propKeys: []
          },
          {
            name: 'link-engagement',
            component: LinkEngagement,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          }
        ]
      }
    ]
  },
  'mailbox-provider': {
    title: 'Mailbox Provider Details',
    sectionTitle: 'Mailbox Provider',
    tabs: [
      {
        tabName: 'details',
        component: ResourceDetails,
        propKeys: ['pageMeta', 'tabMeta'],
        topSections: [
          {
            name: 'mailbox-provider',
            component: MailboxProviderTopSection,
            propKeys: ['hasD12yProduct', 'deliverabilityData', 'deliverabilityDataStatus']
          }
        ],
        subSectionTitle: MailboxProviderSubTitle,
        layouts: [
          {
            name: 'deliverability-details',
            component: DeliverabilityDetails,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          },
          {
            name: 'deliverability-breakdown',
            component: DeliverabilityBreakdown,
            propKeys: ['hasD12yProduct']
          },
          {
            name: 'cohort-percentages',
            component: CohortPercentages,
            propKeys: []
          },
          {
            name: 'engagement-by-cohort',
            component: EngagementRateByCohortLayout,
            propKeys: []
          },
          {
            name: 'link-engagement',
            component: LinkEngagement,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          }
        ]
      }
    ]
  },
  'mailbox-provider-region': {
    title: 'Region Details',
    sectionTitle: 'Region Provider',
    tabs: [
      {
        tabName: 'details',
        component: ResourceDetails,
        propKeys: ['pageMeta', 'tabMeta'],
        topSections: [
          {
            name: 'mailbox-provider-region',
            component: MailboxProviderRegionTopSection,
            propKeys: ['hasD12yProduct', 'deliverabilityData', 'deliverabilityDataStatus']
          }
        ],
        layouts: [
          {
            name: 'deliverability-details',
            component: DeliverabilityDetails,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          },
          {
            name: 'deliverability-breakdown',
            component: DeliverabilityBreakdown,
            propKeys: ['hasD12yProduct']
          },
          {
            name: 'link-engagement',
            component: LinkEngagement,
            propKeys: ['deliverabilityData', 'deliverabilityDataStatus']
          }
        ]
      }
    ]
  }
};

export const apiParams = {
  campaign: 'campaigns',
  'subject-campaign': 'subject_campaigns',
  subaccount: 'subaccounts',
  'ip-pool': 'ip_pools',
  'sending-ip': 'sending_ips',
  'mailbox-provider-region': 'mailbox_provider_regions',
  'mailbox-provider': 'mailbox_providers',
  domain: 'domains',
  'sending-domain': 'sending_domains',
  template: 'templates'
};

export const VALID_BREAKDOWNTYPES = [
  'campaign',
  'subject-campaign',
  'subaccount',
  'ip-pool',
  'sending-ip',
  'mailbox-provider-region',
  'mailbox-provider',
  'domain',
  'watched-domain',
  'sending-domain',
  'template'
];

/**
 * @param {object} newTab The tab configuration object with the ".tabName" property
 * @param {string} currentUrl The current main url path
 * @returns the same url with the last /value replaced with the new tab value
 */
export function getNewTabUrl(newTab, currentUrl) {
  if (!newTab?.tabName || !currentUrl) {
    return;
  }

  return currentUrl.replace(/(.*)\/.*$/, `$1/${newTab.tabName}`);
}

/**
 * @param {object} map
 * @param {object} map.tabMeta The tab configuration object with the ".tabName" property
 * @param {object} map.propData The current main url path
 * @returns An object with properties picked from the configuration propKeys list.
 */
export function getComponentProps(map) {
  if (!map || typeof map !== 'object') return;
  const { tabMeta = {}, propData = {} } = map;

  if (!tabMeta || !tabMeta?.propKeys) {
    return;
  }

  const propsToSpread = {};

  for (let index = 0; index < tabMeta.propKeys.length; index++) {
    const propKey = tabMeta.propKeys[index];
    propsToSpread[propKey] = propData[propKey];
  }

  return propsToSpread;
}

export function getResourceFromPageType(type) {
  switch (type) {
    case 'sending-domain':
      return 'sending domain';
    case 'mailbox-provider-region':
      return 'region';
    case 'mailbox-provider':
      return 'mailbox provider';
    case 'ip-pool':
      return 'IP pool';
    case 'sending-ip':
      return 'IP';
    case 'subaccount':
      return 'subaccount';
    case 'template':
      return 'template';
    case 'subject-campaign':
    case 'campaign':
      return 'campaign';
    case 'domain':
      return 'recipient domain';
    default:
      return '';
  }
}

export function getDetailsPageApiParams({
  type,
  id,
  filters,
  checkboxes,
  hasD12yProduct,
  hasSendingProduct
}) {
  const deliverabilityMetrics = DELIVERABILITY_LINKS_METRIC_KEYS;

  // set metric keys for api calls
  const deliverabilityDetailsMetricKeys = [
    ...new Set([
      ...getMetrics({ checkboxes, hasD12yProduct, hasSendingProduct }),
      ...deliverabilityMetrics
    ])
  ].join(',');

  // set base api param object
  let apiParams = {
      from: filters.from,
      to: filters.to,
      precision: filters.precision,
      timezone: filters.timezone,
      [API_PARAMS[type]]: [id].join(',')
    },
    apiPath,
    messageEventPreFilter = '';

  // SET API PARAMS PER TYPE
  switch (type) {
    case 'campaign':
      messageEventPreFilter = 'campaigns';
      apiParams = {
        ...apiParams,
        metrics: deliverabilityDetailsMetricKeys
      };
      apiPath = 'campaign';
      break;
    case 'subject-campaign':
      messageEventPreFilter = 'subjects';
      apiParams = {
        ...apiParams,
        metrics: getMetrics({ checkboxes, hasD12yProduct, hasSendingProduct, type }).join(',')
      };
      apiPath = 'subject-campaign';
      break;
    case 'subaccount':
      messageEventPreFilter = 'subaccounts';
      apiParams = {
        ...apiParams,
        metrics: deliverabilityDetailsMetricKeys
      };
      apiPath = 'subaccount';
      break;
    case 'ip-pool':
      messageEventPreFilter = 'ip_pools';
      apiParams = {
        ...apiParams,
        metrics: deliverabilityDetailsMetricKeys
      };
      apiPath = 'ip-pool';
      break;
    case 'sending-ip':
      messageEventPreFilter = 'sending_ips';
      apiParams = {
        ...apiParams,
        metrics: deliverabilityDetailsMetricKeys
      };
      apiPath = 'sending-ip';
      break;
    case 'mailbox-provider-region':
      messageEventPreFilter = 'mailbox_provider_regions';
      apiParams = {
        ...apiParams,
        metrics: deliverabilityDetailsMetricKeys
      };
      apiPath = 'mailbox-provider-region';
      break;
    case 'mailbox-provider':
      messageEventPreFilter = 'mailbox_providers';
      apiParams = {
        ...apiParams,
        metrics: deliverabilityDetailsMetricKeys
      };
      apiPath = 'mailbox-provider';
      break;
    case 'domain':
      messageEventPreFilter = 'recipient_domains';
      apiParams = {
        ...apiParams,
        domains: [id].join(','),
        metrics: deliverabilityDetailsMetricKeys
      };
      apiPath = 'domain';
      break;
    case 'sending-domain':
      messageEventPreFilter = 'sending_domains';
      apiParams = {
        ...apiParams,
        metrics: deliverabilityDetailsMetricKeys
      };
      apiPath = 'sending-domain';
      break;
    case 'template':
      messageEventPreFilter = 'templates';
      apiParams = {
        ...apiParams,
        metrics: deliverabilityDetailsMetricKeys
      };
      apiPath = 'template';
      break;
    default:
      messageEventPreFilter = '';
      apiParams = {
        ...apiParams
      };
      apiPath = '';
      break;
  }

  // SET MESSAGE EVENT URL
  let messageEventsUrl;
  let DATE_RANGE_IS_WITHIN_LAST_TEN_DAYS;
  let TO;
  let FROM;
  if (filters && filters.to && filters.from) {
    FROM = new Date(filters.from);
    TO = new Date(filters.to);
    DATE_RANGE_IS_WITHIN_LAST_TEN_DAYS = isAfter(TO, TEN_DAYS_AGO) && isAfter(FROM, TEN_DAYS_AGO);
  }

  if (messageEventPreFilter && messageEventPreFilter.length && DATE_RANGE_IS_WITHIN_LAST_TEN_DAYS) {
    const FROM_ISO = FROM.toISOString();
    const TO_ISO = TO.toISOString();
    messageEventsUrl = `${EVENTS_ROUTE}?${messageEventPreFilter}=${id}&to=${TO_ISO}&from=${FROM_ISO}&range=custom`;
  } else if (messageEventPreFilter && messageEventPreFilter.length) {
    messageEventsUrl = `${EVENTS_ROUTE}?${messageEventPreFilter}=${id}&range=10days`;
  }

  return { apiParams, apiPath, messageEventsUrl };
}
