export const SEEDING_STATUS_CHECKBOXES = [
  {
    label: 'Active',
    name: 'active'
  },
  {
    label: 'Pending',
    name: 'pending'
  }
];

export const SORT_BY_OPTIONS = [
  {
    value: 'campaignId.asc',
    label: 'Campaign ID (A-Z)'
  },
  {
    value: 'campaignId.desc',
    label: 'Campaign ID (Z-A)'
  },
  // TODO: Unclear where subaccount data is coming from
  // {
  //   value: 'subaccountAsc',
  //   label: 'Subaccount (A-Z)',
  // },
  // {
  //   value: 'subaccountDesc',
  //   label: 'Subaccount (Z-A)',
  // },
  {
    value: 'thresholdPercentage.desc',
    label: 'Threshold (High-Low)'
  },
  {
    value: 'thresholdPercentage.asc',
    label: 'Threshold (Low-High)'
  },
  {
    value: 'timeRemaining.desc',
    label: 'Time Remaining (High-Low)'
  },
  {
    value: 'timeRemaining.asc',
    label: 'Time Remaining (Low-High)'
  }
];

export const DEFAULT_SORT_BY = [
  {
    id: 'campaignId',
    desc: false
  }
];
