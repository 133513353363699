import React, { useState } from 'react';
import { PageLink } from 'src/components/links';
import { Page } from 'src/components/matchbox';

import { MonitorsCollection } from './components/MonitorsCollection';
import RemoveFromWatchlistModal from './components/RemoveFromWatchlistModal';
// import CongratsBanner from './components/CongratsBanner';

export default function MonitoredResourcesPage() {
  // const [showCongrats, setShowCongrats] = useState(true);
  const [monitorToDelete, setMonitorToDelete] = useState(null);

  const closeModal = () => setMonitorToDelete(null);

  return (
    <Page
      title="Monitored IPs and Domains"
      primaryAction={{
        content: 'Add IP or Domain to Monitor',
        to: '/signals/blocklist/monitors/add/ip',

        as: PageLink
      }}
      breadcrumbAction={{
        content: 'Blocklisting Incidents',
        to: '/signals/blocklist/incidents',
        as: PageLink
      }}
    >
      {/* TODO: Figure out actual condition */}
      {/* {showCongrats && !Boolean(hasIncidents) && (
        <CongratsBanner onDismiss={() => setShowCongrats(false)} />
      )} */}
      <div data-id="monitors-table">
        <MonitorsCollection handleDelete={setMonitorToDelete} />
      </div>
      <RemoveFromWatchlistModal monitorToDelete={monitorToDelete} closeModal={closeModal} />
    </Page>
  );
}
