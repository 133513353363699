const SENDING_IPS_BASE_URL = `/v1/sending-ips`;

//Documentation URL - https://developers.sparkpost.com/api/sending-ips/#sending-ips-get-retrieve-a-sending-ip
export function getSendingIp(sendingIp) {
  return {
    method: 'GET',
    url: `${SENDING_IPS_BASE_URL}/${sendingIp}`
  };
}

//Documentation URL - https://developers.sparkpost.com/api/sending-ips/#sending-ips-get-list-all-sending-ips
export function getAllSendingIps() {
  return {
    method: 'GET',
    url: `${SENDING_IPS_BASE_URL}`
  };
}
