import styled, { css, DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';

// COMPONENTS
import { Box } from 'src/components/matchbox';
import { Bold } from '../text';

// ICONS
import { MoreHoriz } from '@sparkpost/matchbox-icons';
// eslint-disable-next-line local/no-matchbox-import
import { BoxProps } from '@sparkpost/matchbox';

const itemActiveColor = css`
  background-color: ${({ theme }): string => theme.colors.gray['300']};
`;

type IStyledBox = (
  props: BoxProps & {
    active?: boolean;
    hasClick?: boolean;
    role?: string;
  } & React.HTMLAttributes<HTMLDivElement>
) => JSX.Element;

const StyledBox = Box as IStyledBox;

export const Item = styled<IStyledBox>(StyledBox)`
  transition: background-color ${({ theme }): string => theme.motion.duration.fast} linear;
  padding-left: ${({ theme }): string => theme.space['400']};
  padding-right: ${({ theme }): string => theme.space['200']};
  cursor: ${({ hasClick }): string => (hasClick ? 'pointer' : 'default')};

  &:hover {
    ${itemActiveColor}
  }

  ${({ active }): false | undefined | FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    active && itemActiveColor}
`;

export const ItemActionButton = styled(Box)``;

export const MinimalExpandableWrapper = styled.div`
  [data-id='expandable-toggle'] {
    padding-left: ${({ theme }): string => theme.space['400']};
    padding-right: ${({ theme }): string => theme.space['450']};
  }
  [data-id='expandable-content'] {
    padding: 0;
  }
`;

export const SubTitle = styled(Bold)`
  font-size: ${({ theme }): string => theme.fontSizes['100']};
  font-weight: ${({ theme }): string => theme.fontWeights.semibold};
`;

export const ActionIcon = styled(MoreHoriz)`
  color: ${({ theme }): string => theme.colors.gray['700']};
`;
