import React from 'react';
import { Tabs, UnstyledLink } from 'src/components/matchbox';
import { ButtonLink } from 'src/components/links';
import styles from './SignUpTabs.module.scss';

const SignUpTabs = ({ location, brand, isSPCEU }) => {
  const href = `${brand.url}${location.pathname}${location.search}`;
  const noop = undefined;

  return (
    <Tabs
      fitted
      tabs={[
        {
          Component: isSPCEU ? UnstyledLink : ButtonLink,
          content: <span className={styles.TabContent}>SparkPost</span>,
          to: isSPCEU ? href : noop
        },
        {
          Component: !isSPCEU ? UnstyledLink : ButtonLink,
          content: <span className={styles.TabContent}>SparkPost EU</span>,
          to: !isSPCEU ? href : noop
        }
      ]}
      selected={isSPCEU ? 1 : 0}
    />
  );
};

export default SignUpTabs;
