import { Portal as MatchboxPortal } from '@sparkpost/matchbox';

/**
 * Matchbox Portal component.
 * @see https://design.sparkpost.com/components/portal/
 */
const Portal = MatchboxPortal;

Portal.displayName = 'Portal';
export default Portal;
