export interface AnalyticsMetricsEvaluation {
  groupBy?: string;
  resource?: string;
}
export interface BlocklistEvaluation {
  provider: string;
  resource: string;
}

export interface HealthScoreEvaluation {
  resourceId?: string;
  resourceValue?: string;
  subaccount?: string;
}

export function parseAnalyticsMetricsEvaluation(resourceStr: string): AnalyticsMetricsEvaluation {
  const [groupBy, resource] = resourceStr.split(';');
  return groupBy && resource ? { groupBy, resource } : {};
}

export function parseBlocklistEvaluation(resourceStr: string): BlocklistEvaluation {
  const [resource, provider] = resourceStr.split(';');
  return { resource, provider };
}

export function parseHealthScoreEvaluation(resourceStr: string): HealthScoreEvaluation {
  const [resourceId, resourceValue, subaccount] = resourceStr.split(';');
  return {
    resourceId: resourceId === '##all##' ? undefined : resourceId,
    resourceValue: resourceValue === '##all##' ? undefined : resourceValue,
    subaccount: subaccount === '-1' ? undefined : subaccount
  };
}
