import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ScreenReaderOnly } from 'src/components/matchbox';
import findRouteByPath from 'src/helpers/findRouteByPath';
import { selectRoutes } from 'src/selectors/routes';
import Form from './Form';

/**
 * Returns layout component from routes config
 */
const noop = () => false;
export default function Layout({ children }) {
  const { pathname } = useLocation();
  const routes = useSelector((state) => selectRoutes(state));
  const route = findRouteByPath(pathname, undefined, routes);

  const evaluatedLayoutCondition = useSelector((state) =>
    route.layoutCondition ? route.layoutCondition(state) : noop()
  );

  const LayoutComponent = route.layoutCondition
    ? route.layout.find((x) => x.condition === evaluatedLayoutCondition).component
    : route.layout || Form;

  return (
    <LayoutComponent>
      {route.title && (
        <>
          {/* `defer` solves issue with <title> not updating when a new tab is opened: https://github.com/nfl/react-helmet/issues/315 */}
          <Helmet defer={false}>
            <title>{route.title} | SparkPost</title>
          </Helmet>

          {/*
            Screen reader only ARIA live region to help inform users of a route change.
            See: https://www.gatsbyjs.org/blog/2019-07-11-user-testing-accessible-client-routing/
            and
            https://www.gatsbyjs.org/blog/2020-02-10-accessible-client-side-routing-improvements/
          */}
          <ScreenReaderOnly>
            <div aria-live="assertive" aria-atomic="true">
              {route.title}
            </div>
          </ScreenReaderOnly>
        </>
      )}
      {children}
    </LayoutComponent>
  );
}
