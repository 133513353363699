import React from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { Panel } from 'src/components/matchbox';
import { useSparkPostQuery } from 'src/hooks';
import { ApiErrorBanner } from 'src/components';
import { PanelLoading } from 'src/components/loading';
import { getInlineSeedsConfig } from 'src/helpers/api/seedList';
import { InlineSeedingSettingsForm } from './InlineSeedingSettingsForm';
import { toFormFriendlyFormat } from './helpers';

export function InlineSeedingSettingsPanel({ primaryAcctBlocked }) {
  const { subaccountId } = useParams();
  const {
    data: inlineSeedsConfigData,
    status: inlineSeedsConfigStatus,
    error: inlineSeedsConfigError,
    refetch: inlineSeedsConfigRefetch
  } = useSparkPostQuery(() => getInlineSeedsConfig({ subaccountId }), {
    refetchOnWindowFocus: false,
    cacheTime: 0,
    select: ({ configs }) => toFormFriendlyFormat(configs)
  });

  if (inlineSeedsConfigStatus === 'loading') {
    return <PanelLoading />;
  }

  if (inlineSeedsConfigStatus === 'error') {
    return (
      <ApiErrorBanner
        message="Sorry, we seem to have had some trouble loading your inline seed list settings."
        errorDetails={inlineSeedsConfigError.message}
        reload={inlineSeedsConfigRefetch}
      />
    );
  }

  return (
    <Panel>
      <InlineSeedingSettingsForm
        initialFormData={inlineSeedsConfigData}
        primaryAcctBlocked={primaryAcctBlocked}
      />
    </Panel>
  );
}
