import { formatMilliseconds } from '@sparkpost/report-builder/helpers/units';
import React from 'react';
import { TranslatableText } from '../text';

// Formats milliseconds into a readable duration value
const Duration = ({ value }: { value: number }): JSX.Element => (
  <TranslatableText>{formatMilliseconds(value)}</TranslatableText>
);
Duration.displayName = 'Duration';

export { Duration };
