import { Tabs as MatchboxTabs } from '@sparkpost/matchbox';

/**
 * Matchbox Tabs component.
 * @see https://design.sparkpost.com/components/tabs/
 */
const Tabs = MatchboxTabs;

Tabs.displayName = 'Tabs';

export default Tabs;
