import _ from 'lodash';
import qs from 'query-string';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Empty } from 'src/components';
import { Table } from 'src/components/matchbox';
import { objectSortMatch } from 'src/helpers/sortMatch';
import { CollectionProps, RowProps, StateProps } from './Collection.types';
import FilterBox from './FilterBox';
import calculateVisibleRows from './helpers/calculateVisibleRows';
import Pagination from './Pagination';

const PassThroughWrapper = (props: { children: React.ReactNode }): $TODOFIXME => props.children;
const NullComponent = (): null => null;
const objectValuesToString =
  (keys: Array<string>) =>
  (item: string): string =>
    (keys || Object.keys(item)).map((key) => item[key]).join(' ');

export class Collection extends React.Component<CollectionProps, StateProps> {
  state: StateProps = {
    perPage: 25,
    currentPage: 0
  };

  static defaultProps = {
    defaultPerPage: 25,
    filterBox: {
      show: false
    }
  };

  componentDidMount(): void {
    const { defaultPerPage, filterBox, location, currentPage } = this.props as CollectionProps;

    this.setState({
      perPage: defaultPerPage,
      currentPage: Number(qs.parse(location.search).page) || currentPage || 1
    });

    if (filterBox.show && filterBox.initialValue) {
      this.handleFilterChange(String(filterBox.initialValue));
    }
  }

  componentDidUpdate(prevProps: { currentPage: number; rows: Array<RowProps> }): void {
    const { rows, currentPage } = this.props;

    // re-calculate filtered results if the incoming
    // row data has changed
    if (rows !== prevProps.rows) {
      this.handleFilterChange(this.state.pattern as string);
    }

    if (currentPage !== prevProps.currentPage) {
      this.handlePageChange(currentPage - 1);
    }
  }

  handlePageChange = (index: number): void => {
    const currentPage = index + 1;
    this.props.onPageChange && this.props.onPageChange(currentPage);

    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.setState({ currentPage }, this.maybeUpdateQueryString);
  };

  handlePerPageChange = (perPage: number): void => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.setState({ perPage, currentPage: 1 }, this.maybeUpdateQueryString);
  };

  handleFilterChange = (pattern: string): void => {
    const { rows, filterBox, sortColumn, sortDirection, currentPage } = this
      .props as CollectionProps;
    const { keyMap, itemToStringKeys, matchThreshold, onChange } = filterBox;
    const update: { currentPage: number; filteredRows: $TODOFIXME; pattern: string } = {
      currentPage: currentPage || 1,
      filteredRows: null,
      pattern
    };
    if (pattern) {
      const filteredRows = objectSortMatch({
        items: rows,
        pattern,
        getter: objectValuesToString(itemToStringKeys),
        keyMap,
        matchThreshold
      } as $TODOFIXME);
      // Ultimately respect the sort column, if present
      if (sortColumn) {
        update.filteredRows = _.orderBy(filteredRows, sortColumn, sortDirection || 'asc');
      } else {
        update.filteredRows = filteredRows;
      }
    }

    this.setState(update);
    if (onChange) onChange(pattern);
  };

  debouncedHandleFilterChange = _.debounce(this.handleFilterChange, 300);

  maybeUpdateQueryString(): void {
    const { history, location, pagination, updateQueryString } = this.props as CollectionProps;
    if (!pagination || updateQueryString === false) {
      return;
    }
    const { currentPage, perPage } = this.state;
    const { search, pathname } = location;
    const parsed = qs.parse(search);
    if (parsed.page || updateQueryString) {
      const updated = Object.assign(parsed, { page: currentPage, perPage });
      history.push(`${pathname}?${qs.stringify(updated)}`);
    }
  }

  getVisibleRows(): Array<RowProps> | null {
    const { perPage, currentPage, filteredRows } = this.state;
    const { rows = [], pagination } = this.props as CollectionProps;

    if (!pagination) {
      return filteredRows || rows;
    }

    const currentIndex = currentPage && perPage && (currentPage - 1) * perPage;

    if (typeof currentIndex === 'number' && typeof perPage === 'number')
      return calculateVisibleRows({ filteredRows, rows, currentIndex, perPage });
    return null;
  }

  renderFilterBox(): JSX.Element | null {
    const { filterBox, rows } = this.props as CollectionProps;

    if (filterBox.show) {
      return <FilterBox {...filterBox} rows={rows} onChange={this.debouncedHandleFilterChange} />;
    }

    return null;
  }

  renderPagination(): JSX.Element | null {
    const {
      rows,
      perPageButtons,
      pagination,
      saveCsv = true,
      paginationWrapper,
      pageRange
    } = this.props as CollectionProps;
    const { currentPage, perPage, filteredRows } = this.state;

    const PaginationWrapper = paginationWrapper ? paginationWrapper : React.Fragment;

    if (!pagination || !currentPage) {
      return null;
    }

    return (
      <PaginationWrapper>
        <Pagination
          data={filteredRows || rows}
          perPage={perPage}
          currentPage={currentPage}
          perPageButtons={perPageButtons}
          pageRange={pageRange}
          onPageChange={this.handlePageChange}
          onPerPageChange={this.handlePerPageChange}
          saveCsv={saveCsv}
        />
      </PaginationWrapper>
    );
  }

  render(): JSX.Element {
    const {
      rowComponent: RowComponent,
      rowKeyName = 'id',
      headerComponent: HeaderComponent = NullComponent,
      outerWrapper: OuterWrapper = PassThroughWrapper,
      bodyWrapper: BodyWrapper = PassThroughWrapper,
      children,
      title,
      hasBorder
    } = this.props as CollectionProps;

    const filterBox = this.renderFilterBox();
    const visibleRows = this.getVisibleRows();

    const collection = (
      <OuterWrapper hasBorder={hasBorder}>
        <HeaderComponent />
        <BodyWrapper>
          {visibleRows?.length === 0 ? (
            <Table.Row>
              <Table.Cell colSpan={7} p="0">
                <Empty message="No results found." />
              </Table.Cell>
            </Table.Row>
          ) : (
            visibleRows?.map((row, i) => (
              <RowComponent
                // eslint-disable-next-line react/no-array-index-key
                key={`${row[rowKeyName] || 'row'}-${i}`}
                {...row}
                index={i}
                isLast={visibleRows.length === i + 1} // Temporary. Would like to update the hook to include pagination
              />
            ))
          )}
        </BodyWrapper>
      </OuterWrapper>
    );
    const pagination = this.renderPagination();
    const heading = title ? <h3>{title}</h3> : null;

    return typeof children === 'function' ? (
      children({ filterBox, collection, heading, pagination })
    ) : (
      <div>
        {heading}
        {filterBox}
        {collection}
        {pagination}
      </div>
    );
  }
}

export default withRouter(Collection as $TODOFIXME);
