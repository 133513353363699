import React, { useCallback, useMemo, useState } from 'react';

import { tokens } from '@sparkpost/design-tokens';
import { Box, Button, Layout, Panel, Progress, Stack, Text } from 'src/components/matchbox';
import { Form } from 'src/components/tracking';
import AlertSummary from '../AlertSummary';
import {
  TAB_CONDITION_CONFIGURATION,
  TAB_DATA_MEASUREMENT,
  TAB_DETAILS_AND_NOTIFICATIONS,
  TAB_METRIC_AND_FILTERING,
  METRICS_BREAKDOWN_TYPES
} from '../constants';
import Footer from '../Footer';
import Condition from '../Forms/Condition';
import Data from '../Forms/Data';
import Details from '../Forms/Details';
import Metric from '../Forms/Metric';
import Notifications from '../Forms/Notifications';
import { useAlertForm } from '../useAlertForm';
import { useModal } from 'src/hooks';
import ExitSetupModal from './ExitSetupModal';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';

const tabsLabels = [
  'Metric and Filtering',
  'Data Measurement',
  'Condition Configuration',
  'Details and Notifications'
];

export default function AnalyticsSetup({ goToTypeSelection, isEditingAlert, alertMutation }) {
  const [tab, setTab] = useState(TAB_METRIC_AND_FILTERING);

  const {
    state: {
      measurementData: { measurementType }
    },
    dispatch,
    validatePageFields
  } = useAlertForm();
  const { closeModal, openModal: openExitModal, isModalOpen } = useModal();
  const {
    state: { groupBy },
    actions: { setGroupByError }
  } = useAnalyticsReportContext();

  const handleExit = useCallback(() => {
    closeModal();
    dispatch({ type: 'CLEAN_UP' });
    goToTypeSelection();
  }, [closeModal, dispatch, goToTypeSelection]);

  const validateGroupBy = useCallback(() => {
    let hasError = false;
    if (measurementType === METRICS_BREAKDOWN_TYPES.breakdownByProperty) {
      hasError = !groupBy;
    }
    setGroupByError(hasError);
    return hasError;
  }, [groupBy, measurementType, setGroupByError]);

  const pageHasErrors = useCallback(() => {
    const hasMetricErrors = validatePageFields(tab);
    const groupByHasError = validateGroupBy();
    return hasMetricErrors || groupByHasError;
  }, [tab, validateGroupBy, validatePageFields]);

  const handleCreateAlert = useCallback(() => {
    if (pageHasErrors()) return;

    alertMutation.mutate({});
  }, [alertMutation, pageHasErrors]);

  const handleSkip = useCallback(() => {
    setTab((previousTab) => previousTab + 1);
  }, []);

  const FormBody = useMemo(() => {
    switch (tab) {
      case TAB_METRIC_AND_FILTERING:
        return Metric;

      case TAB_DATA_MEASUREMENT:
        return Data;

      case TAB_CONDITION_CONFIGURATION:
        return Condition;

      case TAB_DETAILS_AND_NOTIFICATIONS:
        return Details;

      default:
        return Metric;
    }
  }, [tab]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
  }, []);

  const goToMetricTab = useCallback(() => {
    setTab(TAB_METRIC_AND_FILTERING);
  }, []);

  const goToDataMeasurementTab = useCallback(() => {
    if (pageHasErrors()) return;

    setTab(TAB_DATA_MEASUREMENT);
  }, [pageHasErrors]);

  const goToConditionTab = useCallback(() => {
    if (pageHasErrors()) return;

    setTab(TAB_CONDITION_CONFIGURATION);
  }, [pageHasErrors]);

  const goToDetailsAndNotificationsTab = useCallback(() => {
    if (pageHasErrors()) return;

    setTab(TAB_DETAILS_AND_NOTIFICATIONS);
  }, [pageHasErrors]);

  const handleContinue = useCallback(() => {
    switch (tab) {
      case TAB_METRIC_AND_FILTERING:
        goToDataMeasurementTab();
        break;

      case TAB_DATA_MEASUREMENT:
        goToConditionTab();
        break;

      case TAB_CONDITION_CONFIGURATION:
        goToDetailsAndNotificationsTab();
        break;

      default:
        break;
    }
  }, [tab, goToDataMeasurementTab, goToDetailsAndNotificationsTab, goToConditionTab]);

  return (
    <Form onSubmit={handleSubmit} id="new-alert-create-form">
      <ExitSetupModal isModalOpen={isModalOpen} closeModal={closeModal} handleExit={handleExit} />
      <Layout>
        <Layout.Section annotated>
          <Progress>
            <Progress.Step
              completed={tab > TAB_METRIC_AND_FILTERING}
              selected={tab === TAB_METRIC_AND_FILTERING}
              onClick={goToMetricTab}
            >
              {tabsLabels[0]}
            </Progress.Step>
            <Progress.Step
              completed={tab > TAB_DATA_MEASUREMENT}
              selected={tab === TAB_DATA_MEASUREMENT}
              onClick={goToDataMeasurementTab}
            >
              {tabsLabels[1]}
            </Progress.Step>
            <Progress.Step
              completed={tab > TAB_CONDITION_CONFIGURATION}
              selected={tab === TAB_CONDITION_CONFIGURATION}
              onClick={goToConditionTab}
            >
              {tabsLabels[2]}
            </Progress.Step>
            <Progress.Step
              completed={tab > TAB_DETAILS_AND_NOTIFICATIONS}
              selected={tab === TAB_DETAILS_AND_NOTIFICATIONS}
              onClick={goToDetailsAndNotificationsTab}
            >
              {tabsLabels[3]}
            </Progress.Step>
          </Progress>
        </Layout.Section>
        <Layout.Section>
          <Box mb={tokens.spacing_900} maxWidth="786px">
            <Stack space={tokens.spacing_500}>
              <Panel>
                <Panel.Section>
                  <FormBody />
                </Panel.Section>
              </Panel>

              {tab === TAB_DETAILS_AND_NOTIFICATIONS && (
                <Panel>
                  <Panel.Section>
                    <Notifications />
                  </Panel.Section>
                </Panel>
              )}
              <AlertSummary />
            </Stack>
          </Box>
        </Layout.Section>
      </Layout>
      <Footer label={tabsLabels[tab - 1]}>
        {isEditingAlert && tab !== TAB_DETAILS_AND_NOTIFICATIONS && (
          <Button
            variant="tertiary"
            mr="8px"
            onClick={handleSkip}
            loading={alertMutation.status === 'loading'}
          >
            <Text>Skip</Text>
          </Button>
        )}
        {(tab !== TAB_DETAILS_AND_NOTIFICATIONS || !isEditingAlert) && (
          <Button
            variant="secondary"
            mr="8px"
            onClick={isEditingAlert ? handleCreateAlert : openExitModal}
            loading={alertMutation.status === 'loading'}
          >
            <Text>{isEditingAlert ? 'Save and Exit' : 'Exit'}</Text>
          </Button>
        )}
        {tab === TAB_DETAILS_AND_NOTIFICATIONS ? (
          <Button
            variant="primary"
            onClick={handleCreateAlert}
            loading={alertMutation.status === 'loading'}
          >
            <Text>{isEditingAlert ? 'Save and Exit' : 'Create Alert'}</Text>
          </Button>
        ) : (
          <Button
            variant="primary"
            type="submit"
            onClick={handleContinue}
            loading={alertMutation.status === 'loading'}
          >
            <Text>{isEditingAlert ? 'Save and Continue' : 'Continue'}</Text>
          </Button>
        )}
      </Footer>
    </Form>
  );
}
