export const INCIDENT_IMPACTS_CONFIG = [
  // This first instance of Unknown tag exist for when resource_impact returned by api is null
  {
    id: 'unknown',
    friendlyName: 'Unknown',
    tagColor: 'darkGray',
    sortingScore: 0,
    description: 'There is insufficient data to know the impact of this blocklist on your sending.'
  },
  // This second instance of Unknown tag exist for when resource_impact returned by api is insufficient data
  {
    id: 'insufficient data',
    friendlyName: 'Unknown',
    tagColor: 'darkGray',
    sortingScore: 1,
    description: 'There is insufficient data to know the impact of this blocklist on your sending.'
  },
  {
    id: 'low',
    friendlyName: 'Low Impact',
    tagColor: 'darkGray',
    sortingScore: 2,
    description:
      'The impact of this blocklist is low as it is expected to affect less than 5% of your sending.'
  },
  {
    id: 'medium',
    friendlyName: 'Medium Impact',
    tagColor: 'yellow',
    sortingScore: 3,
    description:
      'The impact of this blocklist is medium as it is expected to affect between 5-10% of your sending.'
  },
  {
    id: 'high',
    friendlyName: 'High Impact',
    tagColor: 'red',
    sortingScore: 4,
    description:
      'The impact of this blocklist is high as it is expected to affect more than 10% of your sending.'
  }
];
