import { formatDate } from '@sparkpost/report-builder/helpers/date';
import React, { useCallback } from 'react';
import { FullBanner } from 'src/components/fullBanner';
import { Text } from 'src/components/matchbox';
import { HeaderAccountBannerProps, IPendingCancelBanner } from './HeaderAccountBanner.container';
// eslint-disable-next-line local/no-css-module-import
import styles from './HeaderAccountBanner.module.scss';

const PendingCancelBanner = (props: IPendingCancelBanner): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { account, renewAccount, showAlert, fetchAccount } = props;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const cancellationDate = account.pending_cancellation.effective_date;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const formattedDate = formatDate.useMomentInput(cancellationDate);

  const handleLinkClick = useCallback(() => {
    return renewAccount().then(() => {
      showAlert({ type: 'success', message: 'Your account will not be cancelled.' });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      return fetchAccount();
    });
  }, [fetchAccount, renewAccount, showAlert]);

  return (
    <FullBanner className={styles.HeaderAccountBanner}>
      <>
        <Text>
          {`Your account will be cancelled on ${formattedDate} and you will no longer be able to send
        email or login. Changed your mind?`}
        </Text>

        <FullBanner.Link as="button" onClick={handleLinkClick}>
          Don&rsquo;t Cancel
        </FullBanner.Link>
      </>
    </FullBanner>
  );
};

const HeaderAccountBanner = (props: HeaderAccountBannerProps): JSX.Element | null => {
  const { targetBanner } = props;

  if (targetBanner === 'pending-cancellation') {
    return <PendingCancelBanner {...props} />;
  }

  return null;
};

export default HeaderAccountBanner;
