import React, { useCallback, useEffect } from 'react';

import { SliderFieldSet } from 'src/components';
import { Box, Select, Slider, Stack, Text, TextField } from 'src/components/matchbox';
import { Heading } from 'src/components/text';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import styled from 'styled-components';
import {
  HEALTHSCORE_EVALUTATION_LABELS,
  HEALTHSCORE_EVALUTATION_TYPES,
  metricsAndUnits
} from '../constants';
import { useAlertForm } from '../useAlertForm';

const SuffixText = styled.span`
  margin-left: -45px;
  color: #626f7a;
`;

export default function HealthScoreCondition() {
  const {
    state: {
      conditionData: { condition, alertThreshold, source, errors }
    },
    dispatch
  } = useAlertForm();

  const { state: reportOptions, actions } = useAnalyticsReportContext();

  const selectedMetric = reportOptions.metrics[0];
  const displayPlaceholder = metricsAndUnits.find((el) => el.key === selectedMetric)?.placeholder;

  const { refreshReportOptions } = actions;

  const handleConditionChange = useCallback(
    (e) => {
      dispatch({
        type: 'UPDATE_CONDITION_DATA',
        data: { condition: e.target.value !== 'Select' && e.target.value }
      });
    },
    [dispatch]
  );

  const setAlertThresholdChange = useCallback(
    (alertThreshold) => {
      if (alertThreshold < 0 || alertThreshold > 100) return;
      const parsedAlertThreshold = parseInt(alertThreshold) || undefined;
      dispatch({
        type: 'UPDATE_CONDITION_DATA',
        data: { alertThreshold: parsedAlertThreshold }
      });
    },
    [dispatch]
  );
  const handleAlertThresholdChange = useCallback(
    (e) => {
      setAlertThresholdChange(e.target.value);
    },
    [setAlertThresholdChange]
  );

  const handleSourceChange = useCallback(
    (e) => {
      const source = e.target.value;
      refreshReportOptions({ ...reportOptions, source: e.target.value.toLowerCase() });
      dispatch({
        type: 'UPDATE_CONDITION_DATA',
        data: {
          source,
          condition: source === HEALTHSCORE_EVALUTATION_TYPES.raw ? undefined : 'gt'
        }
      });
    },
    [dispatch, refreshReportOptions, reportOptions]
  );

  useEffect(() => {
    if (!source) {
      dispatch({
        type: 'UPDATE_CONDITION_DATA',
        data: { source: HEALTHSCORE_EVALUTATION_TYPES.raw }
      });
    }
  }, [source, dispatch]);

  return (
    <Stack>
      <Heading as="h4">Condition Configurations</Heading>
      <Box width="600px">
        <Text fontSize="18px">Define the alert conditions.</Text>
      </Box>
      <Box display="flex">
        <Box display="flex">
          <Box mr="20px">
            <Select
              id="evaluated"
              label="Evaluated"
              onChange={handleSourceChange}
              value={source}
              options={[
                {
                  value: HEALTHSCORE_EVALUTATION_TYPES.raw,
                  label: HEALTHSCORE_EVALUTATION_LABELS.raw
                },
                {
                  value: HEALTHSCORE_EVALUTATION_TYPES.day_over_day,
                  label: HEALTHSCORE_EVALUTATION_LABELS.day_over_day
                },
                {
                  value: HEALTHSCORE_EVALUTATION_TYPES.week_over_week,
                  label: HEALTHSCORE_EVALUTATION_LABELS.week_over_week
                }
              ]}
            />
          </Box>
          {source === HEALTHSCORE_EVALUTATION_TYPES.raw ? (
            <>
              <Box mr="20px" minWidth="140px">
                <Select
                  id="comparison"
                  label="Comparison"
                  required
                  onChange={handleConditionChange}
                  value={condition}
                  error={errors.condition && 'Required'}
                  options={[
                    'Select',
                    {
                      value: 'gt',
                      label: 'Above'
                    },
                    {
                      value: 'lt',
                      label: 'Below'
                    }
                  ]}
                />
              </Box>
              <Box>
                <TextField
                  id="threshold"
                  type="number"
                  label="Alert Threshold"
                  placeholder={displayPlaceholder}
                  value={alertThreshold}
                  onChange={handleAlertThresholdChange}
                  name="threshold"
                  error={errors.alertThreshold && 'Required'}
                />
              </Box>
            </>
          ) : (
            <Box width="500px">
              <SliderFieldSet legend="Alert Threshold">
                <SliderFieldSet.Fields>
                  <SliderFieldSet.Field variant="slider">
                    <Slider
                      id="seeding-threshold-slider"
                      aria-labelledby="seeding-threshold-label"
                      min={0}
                      max={100}
                      ticks={{
                        80: 'Recommended'
                      }}
                      value={alertThreshold}
                      onChange={setAlertThresholdChange}
                    />
                  </SliderFieldSet.Field>

                  <SliderFieldSet.Field variant="field">
                    <TextField
                      id="seeding-threshold-fine-grained"
                      name="threshold"
                      maxWidth={['100%', '100%', '100%', '110px']}
                      type="number"
                      min="0"
                      max="100"
                      suffix={<SuffixText>%</SuffixText>}
                      data-lpignore="true"
                      data-track={true}
                      value={alertThreshold}
                      onChange={handleAlertThresholdChange}
                    />
                  </SliderFieldSet.Field>
                </SliderFieldSet.Fields>
              </SliderFieldSet>
            </Box>
          )}
        </Box>
      </Box>
    </Stack>
  );
}
