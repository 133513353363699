export function getIpPool(id) {
  return {
    method: 'GET',
    url: `/v1/ip-pools/${id}`
  };
}

export function getIpPools() {
  return {
    method: 'GET',
    url: `/v1/ip-pools`
  };
}
