import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import SelectWrapper from 'src/components/reduxFormWrappers/SelectWrapper';

export default function IpPoolSelect(props) {
  const { disabled, placeholder, options } = props;
  const required = useCallback(
    (value) => (value === placeholder ? 'Required' : undefined),
    [placeholder]
  );

  const formattedOptions = options.map(({ id, name }) => ({
    label: `${name} (id: ${id})`,
    value: id
  }));

  return (
    <Field
      name="ipPool"
      component={SelectWrapper}
      label="IP Pool"
      placeholder={placeholder}
      options={formattedOptions}
      disabled={disabled}
      validate={required}
      data-track={true}
    />
  );
}
