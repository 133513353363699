import { AxiosError } from 'axios';
import { useQueries } from 'react-query';
import { deriveQueryKey } from './helpers/deriveQueryKey';
import { handleError } from './helpers/handleError';
import { usePrepareQuery } from './helpers/usePrepareQuery';
import { QueryFN } from './types';

/**
 * Wrapper hook for react-query `useQueries` hook that handles consistent auth/error behavior for multiple, simultaneous requests
 * See: https://react-query.tanstack.com/reference/useQueries
 */
export function useSparkPostQueries(queryFns: Array<QueryFN>, options = {}) {
  const { queryClient, currentUser, auth, dispatch } = usePrepareQuery();

  // Generate array of query keys based on each passed in query function
  const derivedQueryFns = queryFns.map((queryFn) => {
    const { method } = queryFn();

    return {
      queryKey: deriveQueryKey({ queryFn, auth, currentUser }),
      onError: (error: AxiosError) =>
        handleError({ currentUser, error, method, queryClient, auth, dispatch }),
      ...options
    };
  });

  return useQueries(derivedQueryFns);
}
