import _ from 'lodash';
import React, { useMemo } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';

// HELPERS
import { formatDateTime } from '@sparkpost/report-builder/helpers/date';

// SaveCSVButton
import { Empty, Pagination } from 'src/components';
import SaveCSVButton from 'src/components/collection/SaveCSVButton';
import { Box, Column, Columns, Panel, Stack, Table, Text } from 'src/components/matchbox';
import { SkeletonTable } from 'src/components/SkeletonTable/SkeletonTable';
import { BatchTabLayout } from './BatchTabLayout';

// STYLES
import { LastStatus, TableWrapper } from './styles';

const columns = [
  { label: 'Delivery Time', sortKey: 'ts' }, //This is timestamp
  { label: 'Batch ID', sortKey: 'batch_id', sortType: 'basic' },
  { label: 'Last Status', sortKey: 'status' },
  { label: 'Attempt Count', sortKey: 'attempts' },
  { label: 'Response Code', sortKey: 'response_code' }
];

const Cell = ({ cell }) => {
  const batch = cell.row.original;

  if (cell.column.sortKey === 'ts')
    return <time dateTime={batch.ts}>{formatDateTime(batch.ts)}</time>;

  if (cell.column.sortKey === 'status') return <LastStatus status={batch.status} />;

  if (['attempts', 'response_code'].includes(cell.column.sortKey))
    return (
      <Box textAlign="right" pr="750">
        <span>{cell.value || ''}</span>
      </Box>
    );

  return cell.value || '';
};

export const BatchTabContent = ({ batches, batchesLoading, onRefreshBatches, lastUpdateText }) => {
  const columnsMemoized = useMemo(
    () =>
      columns.map((col) => ({
        ...col,
        accessor: col.sortKey,
        Cell
      })),
    []
  );

  const {
    headerGroups,
    getTableProps,
    getTableBodyProps,
    rows,
    page,
    prepareRow,
    setPageSize,
    state,
    gotoPage
  } = useTable(
    {
      columns: columnsMemoized,
      data: batches,
      defaultCanSort: true,
      disableMultiSort: true,
      initialState: {
        sortBy: [
          {
            id: 'ts',
            desc: true
          }
        ]
      }
    },
    useSortBy,
    usePagination
  );

  const originalRows = useMemo(() => rows.map((row) => row.original), [rows]);

  const table = () => {
    if (batchesLoading) {
      return (
        // <PanelSectionLoading />
        <Panel.Section>
          <SkeletonTable columns={3} rows={3} />
        </Panel.Section>
      );
    }

    if (_.isEmpty(batches)) {
      return <Empty message="There are no batches for your webhook" />;
    }

    return (
      <Stack>
        <Panel.Section p="0">
          <TableWrapper>
            <Table {...getTableProps()} title="Alerts: Recent Incidents">
              <thead>
                {headerGroups.map((headerGroup) => {
                  return (
                    <Table.Row header {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                        return (
                          <Table.HeaderCell
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                          >
                            <Table.SortButton
                              onClick={() => column.toggleSortBy()}
                              direction={
                                column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : undefined
                              }
                            >
                              {column.render('label')}
                            </Table.SortButton>
                          </Table.HeaderCell>
                        );
                      })}
                    </Table.Row>
                  );
                })}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <Table.Row {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                        );
                      })}
                    </Table.Row>
                  );
                })}
              </tbody>
            </Table>
          </TableWrapper>
        </Panel.Section>
      </Stack>
    );
  };

  const pagination = useMemo(() => {
    const pagesNumber = Math.ceil(originalRows.length / state.pageSize);

    const helperText = (withMargin = false) => (
      <Text color="gray.700" mt={withMargin ? 300 : 0}>
        Webhook batches will only be shown for the past 24 hours.
      </Text>
    );

    if (!batches.length) return helperText();

    if (pagesNumber < 2)
      return (
        <Columns>
          <Column>{helperText()}</Column>

          <Column width="content">
            <SaveCSVButton size="small" outline data={originalRows} saveCsv />
          </Column>
        </Columns>
      );

    return (
      <>
        <Pagination
          data={originalRows}
          perPage={state.pageSize}
          currentPage={state.pageIndex + 1}
          onPageChange={gotoPage}
          onPerPageChange={setPageSize}
        />
        <span>{helperText(true)}</span>
      </>
    );
  }, [batches.length, gotoPage, originalRows, setPageSize, state.pageIndex, state.pageSize]);

  return (
    <BatchTabLayout
      lastUpdateText={lastUpdateText}
      batchesLoading={batchesLoading}
      onRefreshBatches={onRefreshBatches}
      table={table()}
      pagination={pagination}
    />
  );
};
