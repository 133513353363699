import React from 'react';
import { Tooltip } from 'src/components/matchbox';
import styles from './Legend.module.scss';

const Item = ({ label, hibanaFill, tooltipContent }) => {
  const fill = hibanaFill;

  const content = (
    <div className={styles.Item}>
      {/* eslint-disable-next-line */}
      <span className={styles.Fill} style={{ background: fill }} />
      <span className={styles.Label}>{label}</span>
    </div>
  );

  if (tooltipContent) {
    return (
      <Tooltip content={tooltipContent(label)} dark id={`legend-${label}`}>
        {content}
      </Tooltip>
    );
  }

  return content;
};

const Legend = ({ items, tooltipContent }) => (
  <div>
    {items.map((item, i) => (
      <Item key={i} tooltipContent={tooltipContent} {...item} />
    ))}
  </div>
);

export default Legend;
