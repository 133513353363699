import React, { useCallback } from 'react';

// COMPONENTS
import { ExternalLink, SubduedLink } from 'src/components/links';
import { Box, Stack, Tag, Text, TimelineTabs } from 'src/components/matchbox';

// HELPERS
import { formatDateTime } from '@sparkpost/report-builder/helpers/date';
import { getEventColor } from '../helpers/getEventColor';
import { getEventName } from '../helpers/getEventName';

// TYPES
import { Event } from 'src/typescript';

type EventTimelineProps = {
  history?: Event[];
  onClick?: (eventId: Event['event_id']) => void;
  selectedId?: Event['event_id'];
};

export const EventTimeline = ({
  history,
  onClick,
  selectedId
}: EventTimelineProps): JSX.Element => {
  const currentSelectedIdIndex = history?.findIndex((event) => event.event_id === selectedId) || 0;

  const handleClick = useCallback(
    (eventId: Event['event_id']): void => {
      const isEventSelected = eventId === selectedId;

      if (onClick && !isEventSelected) {
        onClick(eventId);
      }
    },
    [onClick, selectedId]
  );

  return (
    <Stack space={300}>
      <Text looksLike="h3" as="h3" color="gray.900" fontWeight="600" fontSize="400">
        Events
      </Text>

      <Text color="gray.700">Select an event for more information.</Text>

      <SubduedLink
        target="_blank"
        as={ExternalLink}
        href="https://support.sparkpost.com/docs/tech-resources/sparkpost-event-definitions"
      >
        Event Definitions
      </SubduedLink>

      <TimelineTabs key={currentSelectedIdIndex} initialIndex={currentSelectedIdIndex} mt="300">
        {history?.map((event) => (
          <TimelineTabs.Tab key={event.event_id} onClick={(): void => handleClick(event.event_id)}>
            <Stack space={100}>
              <Box role="button">
                <Tag color={getEventColor(event.type)}>{getEventName(event.type)}</Tag>
              </Box>
              <Text as="time" dateTime={event.timestamp} color="gray.700">
                {/* eslint-disable-next-line local/restrict-translatable-text */}
                {formatDateTime(event.timestamp)}
              </Text>
            </Stack>
          </TimelineTabs.Tab>
        ))}
      </TimelineTabs>
    </Stack>
  );
};
