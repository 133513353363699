export interface Action<T, P> {
  payload?: P;
  type: T;
}

export type AlertType = {
  id?: string;
  message?: string;
  type?: 'success' | 'warning' | 'error';
};

export function showAlert<T extends AlertType>(alert: T): Action<string, T> {
  return {
    type: 'SHOW_GLOBAL_ALERT',
    payload: alert
  };
}

export function clear(id: string): Action<string, { id: string }> {
  return {
    type: 'CLEAR_GLOBAL_ALERT',
    payload: { id }
  };
}

export function hideGlobalBanner(): Action<string, void> {
  return {
    type: 'CLEAR_GLOBAL_BANNER'
  };
}
