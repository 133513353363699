import React, { forwardRef } from 'react';
import { Badge as MatchboxBadge } from '@sparkpost/matchbox';

/**
 * Matchbox Badge component
 * @see https://design.sparkpost.com/components/badge/
 */
const Badge: typeof MatchboxBadge = forwardRef(
  (props, ref): JSX.Element => <MatchboxBadge ref={ref} {...props} />
);

Badge.displayName = 'Badge';

export default Badge;
