import { subMonths } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import config from 'src/appConfig';
import DatePicker from 'src/components/datePicker/DatePickerV2';
import { Grid, Select, Tooltip } from 'src/components/matchbox';
import { TimezoneTypeahead } from 'src/components/typeahead/TimezoneTypeahead';
import { isForcedUTCRollupPrecision } from 'src/helpers/metrics';
import { getFilteredTimezoneOptions } from '../../helpers/index';
import styles from '../ReportOptions.module.scss';
import PrecisionSelector from './PrecisionSelector';

const { metricsRollupPrecisionMap } = config;
const RELATIVE_DATE_OPTIONS = ['hour', 'day', '7days', '30days', '90days', 'custom'];
const PRECISION_OPTIONS = metricsRollupPrecisionMap.map(({ value }) => ({
  value,
  label: _.startCase(_.words(value).join(' '))
}));

export const DateTimeSection = ({
  reportOptions,
  reportLoading,
  handleTimezoneSelect,
  refreshReportOptions
}) => {
  const timezoneOptions = getFilteredTimezoneOptions();
  const [shownPrecision, setShownPrecision] = useState('');
  const updateShownPrecision = useCallback(
    (shownPrecision) => {
      setShownPrecision(shownPrecision);
    },
    [setShownPrecision]
  );

  const isForcedUTC =
    reportOptions.precision && isForcedUTCRollupPrecision(reportOptions.precision);

  const isShownForcedUTC = shownPrecision && isForcedUTCRollupPrecision(shownPrecision);

  const timezoneDisabled = reportLoading || (isForcedUTC && shownPrecision === '');

  return (
    <Grid>
      <Grid.Column xs={12} md={6}>
        <DatePicker
          {...reportOptions}
          relativeDateOptions={RELATIVE_DATE_OPTIONS}
          disabled={reportLoading}
          onChange={refreshReportOptions}
          shouldChangePrecision
          label="Date Range"
          updateShownPrecision={updateShownPrecision}
          datePickerProps={{
            // The API team only keeps data going back 6 months for most users, though *some* users have 12 months of data available.
            // Having some limit in place prevents massive amounts of empty data from rendering in the chart, so keeping it still has value.
            // See Metrics API data retention table:
            // https://sparkpost.atlassian.net/wiki/spaces/ENG/pages/169148743/Metrics+API#MetricsAPI-DataRetention
            disabledDays: {
              before: subMonths(new Date(), 12),
              after: utcToZonedTime(new Date(), reportOptions.timezone)
            }
          }}
        />
      </Grid.Column>
      <Grid.Column xs={6} md={4}>
        <div className={styles.TimezoneTooltipWrapper}>
          <Tooltip
            id="timezone-typahead-tooltip"
            disabled={!isShownForcedUTC && !timezoneDisabled}
            content="Day, week, and month precision only support UTC."
          >
            <TimezoneTypeahead
              options={timezoneOptions}
              initialValue={reportOptions.timezone}
              onChange={handleTimezoneSelect}
              isForcedUTC={isForcedUTC}
              disabledAndUTCOnly={!!isShownForcedUTC}
              disabled={timezoneDisabled}
            />
          </Tooltip>
        </div>
      </Grid.Column>
      <Grid.Column xs={6} md={2}>
        {
          //We will show a fake selector that shows the temporary precision when the user
          //is selecting dates using the datepicker but has not confirmed the selection
          !shownPrecision ? (
            <PrecisionSelector
              from={reportOptions.from}
              to={reportOptions.to}
              selectedPrecision={reportOptions.precision}
              changeTime={refreshReportOptions}
              ready={reportOptions.isReady}
              disabled={reportLoading}
            />
          ) : (
            <Select
              label="Precision"
              id="precision-select"
              options={PRECISION_OPTIONS}
              value={shownPrecision}
              disabled={reportLoading}
              readOnly
            />
          )
        }
      </Grid.Column>
    </Grid>
  );
};

export default DateTimeSection;
