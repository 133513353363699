import { Location } from 'history';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { rudderstackPage } from 'src/helpers/rudderstack';

import findRouteByPath from 'src/helpers/findRouteByPath';

interface FindRouteByPathProps {
  path: string;
}

const RudderStackPage = (): null => {
  const history = useHistory();
  const prevPathname = useRef(history.location.pathname);
  const prevSearchParams = useRef(history.location.search);

  // On URL path changes, inform RudderStack via "PAGE" event
  useEffect(() => {
    rudderstackPage(prevPathname.current, {
      genericPath: (findRouteByPath(prevPathname.current) as FindRouteByPathProps).path
    }); //app load
    const unlisten = history.listen((location: Location) => {
      if (
        prevPathname.current !== location.pathname ||
        prevSearchParams.current !== location.search
      ) {
        rudderstackPage(location.pathname + location.search, {
          genericPath: (findRouteByPath(location.pathname) as FindRouteByPathProps).path
        });
        prevPathname.current = location.pathname;
        prevSearchParams.current = location.search;
      }
    });

    return (): void => unlisten();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default RudderStackPage;
