/* eslint-disable @typescript-eslint/no-unsafe-call */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import bowser from 'bowser';
import _ from 'lodash';
import set from 'set-value';

// TYPES
import { Extras, Primitive } from '@sentry/types';
import { AnyAction } from 'redux';

declare global {
  interface Window {
    Cypress?: {
      cy: {
        id: $TODOFIXME;
      };
      env: $TODOFIXME;
    };
  }
}

const IS_CYPRESS_RUNTIME = Boolean(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  window?.Cypress?.cy?.id && window?.Cypress?.env().CY_IGNORE_AUTH
);

/**
 * List of breadcrumbs to ignore to reduce noise
 * @note Sentry has a 100 breadcrumb per event/error maximum
 */
const IGNORED_ACTIONS = new Set([
  '@@redux/INIT',
  '@@redux-form/BLUR',
  '@@redux-form/CHANGE',
  '@@redux-form/DESTROY',
  '@@redux-form/FOCUS',
  '@@redux-form/REGISTER_FIELD',
  '@@redux-form/TOUCH',
  '@@redux-form/UNREGISTER_FIELD',
  '@@redux-form/UPDATE_SYNC_ERRORS',
  '@@redux-form/INITIALIZE',
  '@@redux-form/STOP_SUBMIT',
  '@@redux-form/SET_SUBMIT_FAILED'
]);

/**
 * Remove sensitive tokens from URLs
 * @param {String} url
 */
function filterURL(url: string): string {
  const filters = [
    { re: /\/reset-password\/.*$/, replacement: '/reset-password/[FILTERED]' },
    {
      re: /\/account\/email-verification\/.*$/,
      replacement: '/account/email-verification/[FILTERED]'
    }
  ];

  if (!url) {
    return url;
  }

  // url.replace(filters[0].re, filters[0].replacement)
  //    .replace(filters[1].re, filters[1].replacement) ...
  return filters.reduce((filtered, { re, replacement }) => filtered.replace(re, replacement), url);
}

export function getGenericApiUrl(url: string): string {
  const filters = [
    {
      re: /\/webhooks\/.*\/batch-status/,
      replacement: '/users/[WEBHOOKID]/batch-status'
    },
    {
      re: /\/webhooks\/.*\/validate/,
      replacement: '/users/[WEBHOOKID]/validate'
    },
    {
      re: /\/webhooks\/.*$/,
      replacement: '/webhooks/[WEBHOOKID]'
    },
    {
      re: /\/tracking-domains\/.*\/verify/,
      replacement: '/tracking-domains/[TRACKING_DOMAIN]/verify'
    },
    {
      re: /\/tracking-domains\/.*$/,
      replacement: '/tracking-domains/[TRACKING_DOMAIN]'
    },
    {
      re: /\/templates\/.*$/,
      replacement: '/templates/[TEMPLATEID]'
    },
    {
      re: /\/suppression-list\/.*$/,
      replacement: '/suppression-list/[ID]'
    },
    {
      re: /\/subaccounts\/.*$/,
      replacement: '/subaccounts/[ID]'
    },
    {
      re: /\/sending-ips\/.*$/,
      replacement: '/sending-ips/[ID]'
    },
    {
      re: /\/sending-domains\/.*\/verify/,
      replacement: '/sending-domains/[SENDING_DOMAIN]/verify'
    },
    {
      re: /\/sending-domains\/.*$/,
      replacement: '/sending-domains/[SENDING_DOMAIN]'
    },
    {
      re: /\/api-keys\/.*$/,
      replacement: '/api-keys/[ID]'
    },
    {
      re: /\/alerts\/.*\/incidents/,
      replacement: '/alerts/[ID]/incidents'
    },
    {
      re: /\/alerts\/.*$/,
      replacement: '/alerts/[ID]'
    },
    {
      re: /\/ip-pools\/.*$/,
      replacement: '/ip-pools/[ID]'
    },
    {
      re: /\/recipient-lists\/.*$/,
      replacement: '/recipient-lists/[ID]'
    },
    {
      re: /\/labs\/snippets\/.*$/,
      replacement: '/labs/snippets/[ID]'
    },
    {
      re: /\/users\/invite\/.*$/,
      replacement: '/users/invite/[USERNAME]'
    },
    {
      re: /\/users\/register\/.*$/,
      replacement: '/users/register/[TOKEN]'
    },
    {
      re: /\/users\/.*\/saml/,
      replacement: '/users/[USERNAME]/saml'
    },
    {
      re: /\/users\/.*\/two-factor\/backup/,
      replacement: '/users/[USERNAME]/two-factor/backup'
    },
    {
      re: /\/users\/.*\/two-factor/,
      replacement: '/users/[USERNAME]/two-factor'
    },
    {
      re: /\/users\/.*\/verify/,
      replacement: '/users/[USERNAME]/verify'
    },
    {
      re: /\/users\/.*$/,
      replacement: '/users/[USERNAME]'
    },
    {
      re: /\/billing\/invoices\/.*$/,
      replacement: '/billing/invoices/[INVOICE_ID]'
    },
    {
      re: /\/billing\/subscription\/promo-codes\/.*$/,
      replacement: '/billing/subscription/promo-codes/[PROMOCODE]'
    },
    {
      re: /\/recipient-validation\/trigger\/.*$/,
      replacement: '/recipient-validation/trigger/[ID]'
    },
    {
      re: /\/recipient-validation\/single\/.*$/,
      replacement: '/recipient-validation/single/[ID]'
    },
    {
      re: /\/recipient-validation\/job\/.*$/,
      replacement: '/recipient-validation/single/[ID]'
    },
    {
      re: /\/blocklist-monitors\/incidents\/.*$/,
      replacement: '/blocklist-monitors/incidents/[ID]'
    },
    {
      re: /\/blocklist-monitors\/.*$/,
      replacement: '/blocklist-monitors/[ID]'
    }
  ];
  if (!url) {
    return url;
  }
  const index = filters
    .map(({ re }) => {
      return re.test(url);
    })
    .findIndex((x) => x === true);

  if (index === -1) return url;

  return url.replace(filters[index].re, filters[index].replacement);
}

/**
 * Sanitise any URL in a given field of an object.
 * @param {Object} obj - containing key
 * @param {String} key - key to sanitise
 */
function filterUrlFromKeyIn(
  obj: Record<string, $TODOFIXME> | undefined,
  key: string
): Record<string, $TODOFIXME> | undefined {
  if (!obj) {
    return obj;
  }

  return {
    ...obj,
    [key]: filterURL(obj[key])
  };
}

/**
 * Sanitise the browser request URL
 * @param {Object} request - Sentry request object
 */
function filterRequest(request: Sentry.Request): Sentry.Request {
  if (!request) {
    return request;
  }

  const urlFiltered = filterUrlFromKeyIn(request, 'url');
  const headers = filterUrlFromKeyIn(request.headers, 'Referer');

  return {
    ...urlFiltered,
    headers
  };
}

// Closure to safely enrich events with data from Redux store
export function getEnricherOrDieTryin(store: $TODOFIXME, currentWindow: Window) {
  return function enrich(data: $TODOFIXME): Sentry.Event {
    const { currentUser } = store.getState();
    const user = _.pick(currentUser, ['access_level', 'customer', 'username']);
    const fromOurBundle = isErrorFromOurBundle(data);
    const apiError = isApiError(data);
    const chunkFailure = isChunkFailure(data);
    const request = filterRequest(data.request);
    const userAgent = _.get(currentWindow, 'navigator.userAgent');

    /*global SUPPORTED_BROWSERS*/
    // refer to docs/browser-support-sentry-issue.md for more info
    const isSupportedBrowser = Boolean(bowser.getParser(userAgent).satisfies(SUPPORTED_BROWSERS));

    return {
      ...data,
      extra: {
        // extra data that doesn't need to be searchable
        ...data.extra,
        isApiError: apiError,
        isChunkFailure: chunkFailure,
        isSupportedBrowser
      },
      request,
      level:
        !fromOurBundle || apiError || chunkFailure || !isSupportedBrowser ? 'warning' : 'error',
      tags: {
        // all tags can be easily searched and sent in Slack notifications
        ...data.tags,
        customer: _.get(user, 'customer'),
        // This <html> property should be set by us and updated when page is translated
        documentLanguage: _.get(currentWindow, 'document.documentElement.lang', 'unknown'),
        navigatorLanguage: _.get(currentWindow, 'navigator.language', 'unknown'),
        source: fromOurBundle ? '2web2ui' : 'unknown'
      },
      user
    };
  };
}

// Check if error event was thrown from our bundle or from something else (i.e. browser extension)
export function isErrorFromOurBundle(data: $TODOFIXME): boolean {
  // The local environment match is looser to allow for hot module replacement (i.e. http://app.sparkpost.test/4.a0803f8355f692de1382.hot-update.js)
  const looksLikeOurBundle = /sparkpost\.test|sparkpost\.com\/static\/js\//;
  // There should never be multiple exception values
  let frames = _.get(data, 'exception.values[0].stacktrace.frames', []);
  const firstFunction = _.get(frames, '[0].function');

  // A Sentry function may be included in the stacktrace and needs to be ignored (i.e. HTMLDocument.wrapped or wrapped)
  // eslint-disable-next-line @typescript-eslint/prefer-includes
  if (/wrapped/.test(firstFunction)) {
    frames = frames.slice(1); // safely reassign frames without Sentry function
  }

  // if any frame is from our bundle
  return Boolean(frames.find(({ filename }: $TODOFIXME) => looksLikeOurBundle.test(filename)));
}

export function isApiError(data: $TODOFIXME): boolean {
  const looksLikeApiErrorType = /SparkpostApiError|ZuoraApiError/;
  const type = _.get(data, 'exception.values[0].type');

  return looksLikeApiErrorType.test(type);
}

export function isChunkFailure(data: $TODOFIXME): boolean {
  const looksLikeChunkFailure = /Loading chunk/;
  const value = _.get(data, 'exception.values[0].value');

  // eslint-disable-next-line @typescript-eslint/prefer-includes
  return looksLikeChunkFailure.test(value as string);
}

// The purpose of this helper is to provide a common interface for reporting errors
// with the expectation that the current service will change in the future.
class ErrorTracker {
  /**
   * The service must be configured before it can be used
   * @param {object} store - the Redux store for additional context
   * @param {object}
   */
  install(config: $TODOFIXME, store: $TODOFIXME): void {
    const { release, sentry, tenantId } = config;
    const windowBrowser = window as SPWindow;

    // ignore cypress too
    // Silently ignore installation if Sentry configuration is not provided
    if (IS_CYPRESS_RUNTIME || !sentry) {
      return;
    }

    const dsn = sentry.dsn;

    Sentry.init({
      dsn,
      integrations: [new Integrations.BrowserTracing()],
      release,
      initialScope: (scope) => scope.setTag('tenantId', tenantId),
      tracesSampleRate: 1.0,
      beforeSend: getEnricherOrDieTryin(store, window),
      beforeBreadcrumb(crumb, hint) {
        if (IGNORED_ACTIONS.has(crumb.message || '')) {
          return null;
        }

        if (crumb.category === 'ui.click') {
          const target = hint?.event?.target as HTMLElement | undefined;
          const text = target?.textContent || '';
          crumb.message = text ? `${crumb.message} "${text}"` : crumb.message;
        }

        return crumb;
      }
    });

    if (Sentry?.captureMessage && !windowBrowser?.SP?.productionConfig?.auth0?.domain) {
      Sentry.captureMessage(
        `Missing Auth0 Configuration for tenantId "${windowBrowser?.SP?.productionConfig?.tenantId}"`,
        'fatal'
      );
    }
  }

  // Record redux actions as breadcrumbs
  get sentryReduxEnhancer(): $TODOFIXME {
    return Sentry.createReduxEnhancer({
      actionTransformer: (action) => {
        const withFilteredPayload = (action: AnyAction, arrOfTypes: string[]): AnyAction => {
          if (arrOfTypes.includes(action.type)) {
            const filteredAction = {
              ...action,
              payload: '[FILTERED]'
            };

            return filteredAction;
          }

          return action;
        };

        if (IGNORED_ACTIONS.has(action.type)) {
          return null;
        }

        return withFilteredPayload(action, []);
      },
      stateTransformer: (state) => {
        const withFilteredData = (
          state: $TODOFIXME,
          arrOfObjectPaths: string[]
        ): Record<string, $TODOFIXME> => {
          // this is a deep clone of the state to avoid mutating the original state
          const decoupledState = JSON.parse(JSON.stringify(state));

          arrOfObjectPaths.forEach((path: string) => {
            set(decoupledState, path, '[FILTERED]');
          });

          return decoupledState;
        };

        return withFilteredData(state, [
          'currentUser.email',
          'currentUser.email_verified',
          'currentUser.first_name',
          'currentUser.last_name'
        ]);
      }
    });
  }

  /**
   * Report an error
   *
   * @param {string} logger - simple description of where the error was caught
   * @param {Error} error
   * @example
   *   try {
   *     throw new Error('Oh no, save me!');
   *   } catch(error) {
   *     ErrorTracker.report('where-am-i', error);
   *   }
   * @returns generated id event
   */
  report(
    logger: string,
    error: Error,
    extra: Extras = {},
    tags: Record<string, Primitive> = {}
  ): string | null {
    // // Silently ignore if Sentry is not setup

    if (!Sentry.getCurrentHub().getClient()) {
      return null;
    }

    return Sentry.captureException(error, {
      extra,
      tags: {
        logger,
        ...tags
      }
    });
  }

  addRequestContextAndThrow(
    reduxActionType: string,
    response: $TODOFIXME = {},
    error: $TODOFIXME
  ): void {
    const zuoraErrorCodes = _.get(response, 'data.reasons', [])
      .map((reason: $TODOFIXME) => reason.code)
      .join();

    Sentry.captureException(error, {
      tags: {
        reduxActionType,
        httpResponseStatus: response.status || 0,
        zuoraErrorCodes: zuoraErrorCodes
      }
    });
    throw error;
  }
}

// Export a constructed instance to avoid the need to call `new` everywhere
export default new ErrorTracker();
