export function getAccount(params) {
  return {
    method: 'GET',
    url: '/v1/account',
    params
  };
}

export function getUsage() {
  return {
    method: 'GET',
    url: '/v1/usage'
  };
}

export function getUsageHistory() {
  return {
    method: 'GET',
    url: '/v1/usage/history'
  };
}

/**
 * Sets `options` on the user's account. This does *not* set UI options (`options.ui`)
 * @param {object} args
 * @param {string} args.option - account option string, e.g. "custom_reports"
 * @param {boolean} args.value - whether the account option is enabled
 */
export function setAccountOption({ option, value }) {
  return {
    method: 'PUT',
    url: '/v1/account',
    data: {
      options: {
        [option]: value
      }
    }
  };
}
