import React, { useCallback } from 'react';
import _ from 'lodash';

import {
  Button,
  Box,
  Layout,
  Panel,
  Checkbox,
  Stack,
  ListBox,
  Radio,
  Banner,
  Tooltip
} from 'src/components/matchbox';
import { Empty } from 'src/components';
import { Search } from '@sparkpost/matchbox-icons';
import { Form } from 'src/components/tracking';
import { SubduedText } from 'src/components/text';
import { ComboBoxTextField } from 'src/components/ComboBox/ComboBox';

import { useIpsForm } from './hooks/useIpsForm';
import { TypeSelect } from 'src/components/typeahead';
import { ComboBoxTypeahead } from 'src/components/typeahead/ComboBoxTypeaheadV2';
import { subaccountToString } from 'src/helpers/subaccounts';

export function IpsForm() {
  const { mutation, select, dispatch, state, subaccountsQuery, handleSubmit, allIps, multiEntry } =
    useIpsForm();

  const submitting = mutation.status === 'loading';

  const hasSubaccounts = subaccountsQuery.status === 'success' && subaccountsQuery.data.length > 0;

  function onSubaccountRadioClick(e) {
    return dispatch({
      type: 'SELECT_SUBACCOUNT_OPTION',
      subaccountOption: e.currentTarget.value
    });
  }

  const onTypeaheadChange = useCallback(
    (selectedIps) => {
      dispatch({ type: 'TYPEAHEAD_CHANGE', selectedIps });
    },
    [dispatch]
  );

  return (
    <Form id="blocklist-watchlist-add-ip" onSubmit={handleSubmit}>
      <Layout>
        <Layout.Section annotated>
          <Layout.SectionTitle>Monitoring Assingment</Layout.SectionTitle>
          <SubduedText>Select a subaccount to be assigned to monitor resources.</SubduedText>
        </Layout.Section>
        <Layout.Section>
          <Panel>
            <Panel.Section>
              {hasSubaccounts && (
                <Box>
                  <Radio.Group label="Account Assignment for Monitoring">
                    <Radio
                      id="all-subaccounts"
                      value="all-subaccounts"
                      onChange={onSubaccountRadioClick}
                      checked={state.subaccountOption === 'all-subaccounts'}
                      label="Assign to Primary and all Subaccounts"
                    />
                    <Radio
                      id="primary-subaccount"
                      value="primary-subaccount"
                      onChange={onSubaccountRadioClick}
                      checked={state.subaccountOption === 'primary-subaccount'}
                      label="Assign to Primary Account only"
                    />
                    <Radio
                      id="single-subaccount"
                      value="single-subaccount"
                      onChange={onSubaccountRadioClick}
                      checked={state.subaccountOption === 'single-subaccount'}
                      label="Assign to a Single Subaccount"
                    />
                    {state.subaccountOption === 'single-subaccount' && (
                      <Box ml="500" mt="200">
                        <TypeSelect
                          label="Subaccount"
                          required
                          name="subaccount"
                          id="subaccount-typeahead"
                          placeholder="e.g. samplesubaccount"
                          disabled={submitting}
                          renderItem={(subaccount) => {
                            return (
                              <TypeSelect.Item
                                label={subaccount.name}
                                itemToString={(subaccount) => subaccount.id}
                                meta={subaccount.id === 0 ? undefined : subaccount.id.toString()}
                              />
                            );
                          }}
                          itemToString={(item) => subaccountToString(item)}
                          results={subaccountsQuery.data.map((subaccount, index) => ({
                            ...subaccount,
                            key: `subaccount_item_${index}`
                          }))}
                          onChange={(subaccount) =>
                            dispatch({ type: 'SELECT_SUBACCOUNT', subaccount })
                          }
                          selectedItem={state.subaccount}
                        />
                      </Box>
                    )}
                  </Radio.Group>
                </Box>
              )}
            </Panel.Section>
          </Panel>
        </Layout.Section>
      </Layout>

      <Layout>
        <Layout.Section annotated>
          <Layout.SectionTitle>SparkPost IPs</Layout.SectionTitle>
          <Stack>
            <SubduedText>SparkPost IPs can be monitored.</SubduedText>
            <SubduedText>
              Any new IPs added after this selection will need to be added to the monitor list.
            </SubduedText>
          </Stack>
        </Layout.Section>
        <Layout.Section>
          {Boolean(state.subaccount?.ip_pool && state.subaccountOption === 'single-subaccount') ? (
            <Panel>
              {select.ipsFromSelectedPool.length > 0 ? (
                <Panel.Section>
                  <Stack space="200">
                    {_.every(select.ipsFromSelectedPool, 'disabled') ? (
                      <Banner status="success" size="small">
                        All IPs are currently being monitored
                      </Banner>
                    ) : null}
                    <Checkbox.Group label={`Current IPs in "${select.selectedIpPool.name}"`}>
                      {select.ipsFromSelectedPool.map((checkbox, index) => (
                        <Checkbox {...checkbox} key={`checkbox-${index}`} />
                      ))}
                    </Checkbox.Group>

                    {state.errors['no-ips-banner'] && (
                      <Banner status="danger" size="small">
                        {state.errors['no-ips-banner']}
                      </Banner>
                    )}
                  </Stack>
                </Panel.Section>
              ) : (
                <Empty
                  message={`There are currently no IPs assigned to "${select.selectedIpPool.name}"`}
                />
              )}
            </Panel>
          ) : (
            <Stack>
              <Panel>
                <Panel.Section>
                  <Checkbox
                    checked={state.allIpsCheckbox}
                    onChange={() => dispatch({ type: 'TOGGLE_ALL_IPS' })}
                    id="all-sparkpost-ips"
                    label="All SparkPost IPs"
                    helpText={`Includes ${allIps.length} unmonitored SparkPost IPs`}
                  />
                </Panel.Section>
                <Panel.Section>
                  <Stack space="200">
                    <Stack>
                      <Stack>
                        <ListBox
                          disabled={state.allIpsCheckbox}
                          placeholder="Select IP Pool"
                          defaultValue=""
                          id="ip-pool-listbox"
                          label="By IP Pool Name"
                          value={state.selectedIpPool}
                          onChange={(e) =>
                            dispatch({ type: 'SELECT_IP_POOL', value: e.currentTarget.value })
                          }
                        >
                          {select.availableIpPools.map((ipPool, index) => (
                            <ListBox.Option key={`ip_pool_${index}`} value={ipPool.id}>
                              {ipPool.name}
                            </ListBox.Option>
                          ))}
                        </ListBox>
                        {select.selectedIpPool &&
                          (select.ipsFromSelectedPool.length > 0 ? (
                            <Box>
                              <Checkbox.Group
                                label={`Current IPs in "${select.selectedIpPool.name}"`}
                              >
                                {select.ipsFromSelectedPool.map((checkbox, index) => {
                                  if (checkbox.disabled) {
                                    return (
                                      <Box key={`checkbox-${index}`}>
                                        <Tooltip
                                          content="IP is already being monitored"
                                          id={`ip-tooltip-${index}`}
                                        >
                                          <Checkbox {...checkbox} />
                                        </Tooltip>
                                      </Box>
                                    );
                                  } else {
                                    return (
                                      <Box key={`checkbox-${index}`}>
                                        <Checkbox {...checkbox} key={`checkbox-${index}`} />
                                      </Box>
                                    );
                                  }
                                })}
                              </Checkbox.Group>
                            </Box>
                          ) : (
                            <Box>No IPs available in "{select.selectedIpPool.name}"</Box>
                          ))}
                      </Stack>
                    </Stack>
                    {state.errors['no-ips-banner'] && (
                      <Banner status="danger" size="small">
                        {state.errors['no-ips-banner']}
                      </Banner>
                    )}
                  </Stack>
                </Panel.Section>

                <Panel.Section>
                  <ComboBoxTypeahead
                    disabled={state.allIpsCheckbox}
                    placeholder="e.g. 00.000.000.00"
                    label="By IP Address"
                    prefix={<Search />}
                    value={state.selectedIps}
                    results={allIps}
                    onChange={onTypeaheadChange}
                  />
                </Panel.Section>
              </Panel>
            </Stack>
          )}
        </Layout.Section>
      </Layout>

      <Layout>
        <Layout.Section annotated>
          <Layout.SectionTitle>External IPs</Layout.SectionTitle>
          <Stack>
            <SubduedText>External IPs can be monitored.</SubduedText>
          </Stack>
        </Layout.Section>
        <Layout.Section>
          <Stack>
            <Panel>
              <Panel.Section>
                <Stack space="200">
                  <Stack space="300">
                    <Box>
                      <ComboBoxTextField
                        id="external-ips-combobox"
                        placeholder="e.g. 00.000.000.00"
                        label="IP Address"
                        error={multiEntry.error}
                        selectedItems={multiEntry.valueList}
                        itemToString={(a) => a}
                        value={multiEntry.value}
                        onKeyDown={multiEntry.handleKeyDown}
                        onBlur={multiEntry.handleBlur}
                        onChange={multiEntry.handleChange}
                        removeItem={multiEntry.handleRemove}
                      />
                    </Box>
                  </Stack>
                  {state.errors['no-ips-banner'] && (
                    <Banner status="danger" size="small">
                      {state.errors['no-ips-banner']}
                    </Banner>
                  )}
                </Stack>
              </Panel.Section>
            </Panel>
            <Box>
              <Button loading={submitting} variant="primary" type="submit">
                Add IPs to Monitor
              </Button>
            </Box>
          </Stack>
        </Layout.Section>
      </Layout>
    </Form>
  );
}
