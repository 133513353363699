import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Button, Panel, Stack, TextField } from 'src/components/matchbox';
import { Form } from 'src/components/tracking';
import { useAuthentication } from 'src/context/AuthenticationContext';

function PasswordForm({ currentUser, isAuth0User, onSubmit }) {
  const { changePassword } = useAuthentication();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({});
  const password = useRef({});
  password.current = watch('currentPassword') || '';

  const handleAuth0PasswordReset = () => {
    changePassword({ email: currentUser.email, authConnection: currentUser.auth_connection });
  };

  if (isAuth0User) {
    return (
      <>
        <Panel.Section>
          A password reset will send an instructional email to the email address associated with
          this account.
        </Panel.Section>
        <Panel.Section>
          <Button variant="secondary" onClick={handleAuth0PasswordReset}>
            Reset Password
          </Button>
        </Panel.Section>
      </>
    );
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="update-password-form">
      <Panel.Section>
        <Stack>
          <TextField
            id="currentPassword"
            name="currentPassword"
            label="Current Password"
            type="password"
            error={errors.currentPassword ? errors.currentPassword.message : null}
            {...register('currentPassword', {
              required: 'Required'
            })}
          />

          <TextField
            id="newPassword"
            name="newPassword"
            label="New Password"
            type="password"
            error={errors.newPassword ? errors.newPassword.message : null}
            {...register('newPassword', {
              required: 'Required',
              minLength: {
                value: 12,
                message: 'Password must have at least 12 characters'
              },
              validate: (value) =>
                value !== password.current || 'New Password is same as Current Password'
            })}
          />
        </Stack>
      </Panel.Section>

      <Panel.Section>
        <Button type="submit" variant="secondary">
          Update Password
        </Button>
      </Panel.Section>
    </Form>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps)(PasswordForm);
