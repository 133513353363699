import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { Box, Button, Error, Panel, Stack } from 'src/components/matchbox';
import { ButtonWrapper } from 'src/components';
import { Form } from 'src/components/tracking';
import { getIpPools } from 'src/selectors/ipPools';
import { hasProductOnBillingSubscription } from 'src/helpers/conditions/account';
import { DeliverabilityToggleBlock, NameField, StatusSelect } from './formFields';
import IpPoolSelect from './IpPoolSelect';
import RestrictToIpPoolCheckbox from './RestrictToIpPoolCheckbox';

const IP_POOL_PLACEHOLDER = 'Select IP Pool';
const MISSING_IP_POOL_ERROR =
  'The IP pool previously assigned to this subaccount has been deleted. Re-assign its mail deliveries to an existing IP pool or remove its restriction to a single IP pool.';

export class SubaccountEditForm extends Component {
  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      hasD12ySection,
      ipPools,
      ipPoolError,
      compliance,
      reset,
      restrictedToIpPool
    } = this.props;

    return (
      <Form onSubmit={handleSubmit} id="subaccount-edit-form">
        <Panel.LEGACY.Section>
          <Stack>
            <NameField disabled={submitting || compliance} />

            <StatusSelect disabled={submitting || compliance} compliance={compliance} />

            {Boolean(ipPools.length) && (
              <Stack>
                <RestrictToIpPoolCheckbox disabled={submitting || compliance} />

                {restrictedToIpPool ? (
                  <Stack space="200">
                    <IpPoolSelect
                      disabled={submitting || compliance}
                      options={ipPools}
                      placeholder={IP_POOL_PLACEHOLDER}
                    />

                    {ipPoolError ? (
                      <Box maxWidth="1200">
                        <Error error={ipPoolError} />
                      </Box>
                    ) : null}
                  </Stack>
                ) : null}
              </Stack>
            )}
          </Stack>
        </Panel.LEGACY.Section>

        {hasD12ySection ? (
          <Panel.LEGACY.Section>
            <DeliverabilityToggleBlock disabled={submitting} />
          </Panel.LEGACY.Section>
        ) : null}

        <Panel.LEGACY.Section>
          <ButtonWrapper>
            <Button variant="primary" submit disabled={pristine || submitting || compliance}>
              {submitting ? 'Updating...' : 'Update Subaccount'}
            </Button>

            <Button variant="secondary" disabled={pristine || submitting} onClick={reset}>
              Cancel
            </Button>
          </ButtonWrapper>
        </Panel.LEGACY.Section>
      </Form>
    );
  }
}

const formName = 'SubaccountEditForm';
const valueSelector = formValueSelector(formName);

const mapStateToProps = (state, { subaccount }) => {
  const { compliance, ip_pool, name, status, options } = subaccount;
  const ipPools = getIpPools(state);
  const restrictedToIpPool = Boolean(ip_pool);
  const assignedIpPoolExists = ipPools.some((ipPool) => ipPool.id === ip_pool);
  const hasMissingIpPoolError = restrictedToIpPool && !assignedIpPoolExists;

  return {
    compliance,
    ipPools,
    restrictedToIpPool: valueSelector(state, 'restrictedToIpPool'),
    ipPoolError: hasMissingIpPoolError ? MISSING_IP_POOL_ERROR : undefined,
    hasD12ySection: hasProductOnBillingSubscription('deliverability')(state),
    initialValues: {
      ipPool: assignedIpPoolExists ? ip_pool : IP_POOL_PLACEHOLDER,
      name,
      restrictedToIpPool,
      status: compliance ? `${status} by SparkPost` : status,
      options: {
        // TODO: This isn't the best way to do this - there is an existing problem with the loading state of the page which should be corrected when removing Redux from the feature in the future
        deliverability: Boolean(options?.deliverability)
      }
    }
  };
};

const SubaccountEditReduxForm = reduxForm({ form: formName })(SubaccountEditForm);
export default connect(mapStateToProps, {})(SubaccountEditReduxForm);
