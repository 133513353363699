export function getAuthToken(token) {
  const headers = {};
  const data = {};
  headers['Authorization'] = `Bearer ${token}`;
  return {
    method: 'POST',
    url: '/v1/account/integrations/auth0/token',
    headers,
    data
  };
}
