import React, { useRef } from 'react';
import { Empty } from 'src/components';
import { Box, Grid, Label, Panel, Skeleton } from 'src/components/matchbox';
import { getMessageEvents } from 'src/helpers/api/messageEvents';
import { getRelativeDates } from '@sparkpost/report-builder/helpers/date';
import { useSparkPostQuery } from 'src/hooks';
import useResourceDetailsContext from '../../hooks/useResourceDetailsContext';
import DataSources from './DataSources';

export default function CampaignIdTopSection({
  hasD12yProduct,
  deliverabilityData = [],
  deliverabilityDataStatus
}) {
  const { match: { params: { id } = {} } = {} } = useResourceDetailsContext();
  const messageEventsDateRange = useRef(
    getRelativeDates.useMomentInput('10days', { roundToPrecision: false })
  );
  const messageEventsApiParams = {
    campaigns: id,
    ...messageEventsDateRange.current,
    per_page: 25
  };
  const { status: messageEventsDataStatus, data: messageEventsData = [] } = useSparkPostQuery(() =>
    getMessageEvents(messageEventsApiParams)
  );
  const campaign_id = id;
  const { friendly_from } = messageEventsData[0] || {};

  if (messageEventsDataStatus === 'error' && deliverabilityDataStatus === 'error')
    return <Empty message="Failed to load the data. Refresh page."></Empty>;

  return (
    <>
      <DataSources
        hasD12yProduct={hasD12yProduct}
        deliverabilityData={deliverabilityData}
        deliverabilityDataStatus={deliverabilityDataStatus}
      />
      <FromNCampaignSection
        campaign_id={campaign_id}
        friendly_from={friendly_from}
        messageEventsDataStatus={messageEventsDataStatus}
      />
    </>
  );
}

const FromNCampaignSection = ({ campaign_id, friendly_from, messageEventsDataStatus }) => {
  if (messageEventsDataStatus === 'error')
    return <Empty message="Failed to load the data. Refresh page."></Empty>;
  if (messageEventsDataStatus === 'loading') {
    return (
      <Panel.Section>
        <Grid>
          <Grid.Column xs={3}>
            <Box>
              <Label mb="0" fontWeight="semibold">
                Campaign
              </Label>
              <Skeleton.Header />
            </Box>
          </Grid.Column>
          <Grid.Column xs={3}>
            <Box>
              <Label mb="0" fontWeight="semibold">
                Sample From
              </Label>
              <Skeleton.Header />
            </Box>
          </Grid.Column>
        </Grid>
      </Panel.Section>
    );
  }
  if (!campaign_id && !friendly_from) return null;
  return (
    <Panel.Section>
      <Grid>
        {campaign_id && (
          <Grid.Column xs={3}>
            <Box>
              <Label mb="0" fontWeight="semibold">
                Campaign
              </Label>

              <Box>{campaign_id}</Box>
            </Box>
          </Grid.Column>
        )}
        {friendly_from && (
          <Grid.Column xs={3}>
            <Box>
              <Label mb="0" fontWeight="semibold">
                Sample From
              </Label>

              <Box>{friendly_from}</Box>
            </Box>
          </Grid.Column>
        )}
      </Grid>
    </Panel.Section>
  );
};
