import React from 'react';
import { useParams } from 'react-router-dom';
import { useEffectReducer } from 'use-effect-reducer';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ButtonWrapper, FieldSet, SliderFieldSet } from 'src/components';
import { Form } from 'src/components/tracking';
import {
  Button,
  Column,
  Columns,
  Panel,
  ScreenReaderOnly,
  Select,
  Slider,
  TextField
} from 'src/components/matchbox';
import { ScreenReaderExclude } from 'src/components/a11y';
import { SubduedText, TranslatableText } from 'src/components/text';
import { useSparkPostMutation, useAlert, useModal } from 'src/hooks';
import { formatToLocaleString, parseLocaleString } from 'src/helpers/number';
import { createInlineSeedsConfig, updateInlineSeedsConfig } from 'src/helpers/api/seedList';
import { clamp } from 'src/helpers/math';
import { MultiEntryField } from './MultiEntryField';
import { CONFIG } from './constants';
import { toApiFriendlyFormat } from './helpers';
import AutomaticSeedingSettingsModal from '../AutomaticSeedingSettingsModal';

//TODO - need to add a Externallink for word wildcards - TR-3298
const CAMPAIGN_IDS_HELP_TEXT = {
  include:
    'A maximum of 5 campaigns and/or wildcards can be included for seeding. Note that campaign IDs are case-sensitive',
  exclude:
    'A maximum of 5 campaigns and/or wildcards can be excluded for seeding. Note that campaign IDs are case-sensitive'
};

export function InlineSeedingSettingsForm({ initialFormData, primaryAcctBlocked }) {
  const { subaccountId } = useParams();
  const { showAlert } = useAlert();
  const [state, dispatch] = useInlineSeedingSettings(initialFormData);
  const { closeModal, openModal, isModalOpen } = useModal();
  const mutationFn =
    state.formData.mutationType === 'create' ? createInlineSeedsConfig : updateInlineSeedsConfig;
  const configMutation = useSparkPostMutation(
    (nextConfig = {}) => mutationFn({ subaccountId, ...nextConfig }),
    {
      onSuccess: () => handleMutationSuccess(state.formData.mutationType),
      onError: () => handleMutationError(state.formData.mutationType)
    }
  );

  function handleSubmit(event) {
    event.preventDefault();

    if (primaryAcctBlocked) openModal();

    if (!primaryAcctBlocked)
      return dispatch({ type: 'FORM_SUBMIT', mutationFn: configMutation.mutate });
  }

  /**
   * @param {string} mutationType - Type of mutation request. 'create' | 'update'
   */
  function handleMutationSuccess(mutationType) {
    const verb = mutationType === 'create' ? 'created' : 'updated';

    closeModal();

    return showAlert({ type: 'success', message: `Inline seeding configuration ${verb}.` });
  }

  /**
   * @param {string} mutationType - Type of mutation request. 'create' | 'update'
   */
  function handleMutationError(mutationType) {
    const verb = mutationType === 'create' ? 'creation' : 'update';

    closeModal();

    return showAlert({ type: 'error', message: `Inline seeding configuration ${verb} failed.` });
  }

  function handleCampaignsToSeedChange(event) {
    const { value } = event.currentTarget;

    return dispatch({ type: 'CAMPAIGNS_TO_SEED_CHANGE', value });
  }

  function handleCampaignIdsChange(event) {
    const { value } = event.target;

    return dispatch({ type: 'CAMPAIGN_IDS_CHANGE', value });
  }

  function handleCampaignIdRemoval(value) {
    return dispatch({ type: 'CAMPAIGN_IDS_REMOVE', value });
  }

  function handleCampaignIdsBlur(event) {
    const { value } = event.target;

    return dispatch({ type: 'CAMPAIGN_IDS_BLUR', value });
  }

  function handleCampaignIdsKeydown(event) {
    const { value } = event.target;

    // The user presses the spacebar or enter key
    if ((event.keyCode === 13 || event.keyCode === 32) && value.length > 0) {
      event.preventDefault();

      return dispatch({ type: 'CAMPAIGN_IDS_ADD', value });
    }
  }

  /**
   * @param {object} options
   * @param {Object | Number} options.event
   * @param {string} options.control - 'slider' | 'field'
   */
  function handleThresholdSliderChange(event) {
    return dispatch({ type: 'THRESHOLD_SLIDER_CHANGE', value: event });
  }

  function handleThresholdFieldChange(event) {
    const { value } = event.target;

    return dispatch({ type: 'THRESHOLD_FIELD_CHANGE', value });
  }

  function handleThresholdBlur(event) {
    const { value } = event.target;

    return dispatch({ type: 'THRESHOLD_FIELD_BLUR', value });
  }

  function handleDurationChange(event) {
    // The `<Slider />` returns the raw value, whereas the `<TextField />` returns the event object
    const value = typeof event === 'object' ? event.target.value : event;

    return dispatch({ type: 'DURATION_CHANGE', value });
  }

  function handleDurationBlur(event) {
    const { value } = event.target;

    return dispatch({ type: 'DURATION_FIELD_BLUR', value });
  }

  function handleResetChange(event) {
    // The `<Slider />` returns the raw value, whereas the `<TextField />` returns the event object
    const value = typeof event === 'object' ? event.target.value : event;

    return dispatch({ type: 'RESET_CHANGE', value });
  }

  function handleResetBlur(event) {
    const { value } = event.target;

    return dispatch({ type: 'RESET_FIELD_BLUR', value });
  }

  return (
    <>
      <Form id="inline-seeding-settings" onSubmit={handleSubmit}>
        <Panel.Section>
          <FieldSet legend="Campaigns" legendHidden>
            <Columns collapseBelow="md">
              <Column width={1 / 4}>
                <Select
                  label="Campaigns to Seed"
                  id="campaigns-to-seed"
                  onChange={handleCampaignsToSeedChange}
                  value={state.formData.campaignsToSeed}
                  disabled={configMutation.status === 'loading'}
                  data-track={true}
                  options={[
                    {
                      label: 'Include',
                      value: 'include'
                    },
                    {
                      label: 'Exclude',
                      value: 'exclude'
                    }
                  ]}
                />
              </Column>

              <Column>
                {/* TODO: This could probably be replaced with a more generic, global component */}
                <MultiEntryField
                  label="Campaign ID"
                  id="campaign-id"
                  data-id="campaign-ids-field"
                  selectedItems={state.formData.campaignIds}
                  onRemoveSelectedItem={handleCampaignIdRemoval}
                  value={state.formData.campaignIdsInput}
                  onChange={handleCampaignIdsChange}
                  onBlur={handleCampaignIdsBlur}
                  onKeyDown={handleCampaignIdsKeydown}
                  error={state.errors.campaignIds}
                  helpText={CAMPAIGN_IDS_HELP_TEXT[state.formData.campaignsToSeed]}
                  placeholder="e.g. new_year_campaign, marketing-*"
                  data-track={true}
                  disabled={
                    configMutation.status === 'loading' ||
                    state.formData.campaignIds.length === CONFIG.CAMPAIGN_IDS.MAX
                  }
                />
              </Column>
            </Columns>
          </FieldSet>
        </Panel.Section>

        <Panel.Section>
          <SliderFieldSet legend="Seeding Threshold">
            <SliderFieldSet.Description>
              Minimum number of messages that must be sent within 24 hours before seeding will
              begin.
            </SliderFieldSet.Description>

            <SliderFieldSet.Fields>
              <SliderFieldSet.Field variant="slider">
                <ScreenReaderOnly as="label" id="seeding-threshold-label">
                  Number of Messages
                </ScreenReaderOnly>

                <Slider
                  id="seeding-threshold-slider"
                  aria-labelledby="seeding-threshold-label"
                  min={CONFIG.THRESHOLD.MIN}
                  max={CONFIG.THRESHOLD.MAX}
                  ticks={{
                    [CONFIG.THRESHOLD.MIN]: formatToLocaleString(CONFIG.THRESHOLD.MIN),
                    [CONFIG.THRESHOLD.MAX]: formatToLocaleString(CONFIG.THRESHOLD.MAX)
                  }}
                  onChange={handleThresholdSliderChange}
                  value={state.formData.threshold}
                  disabled={configMutation.status === 'loading'}
                />
              </SliderFieldSet.Field>

              <SliderFieldSet.Field variant="field">
                <TextField
                  label="Fine-Grained Number of Messages"
                  labelHidden
                  id="seeding-threshold-fine-grained"
                  name="threshold"
                  maxWidth={['100%', '100%', '100%', '190px']}
                  data-lpignore="true"
                  suffix={<SuffixText>messages</SuffixText>}
                  onChange={handleThresholdFieldChange}
                  onBlur={handleThresholdBlur}
                  value={formatToLocaleString(state.formData.threshold)}
                  disabled={configMutation.status === 'loading'}
                  data-track={true}
                />
              </SliderFieldSet.Field>
            </SliderFieldSet.Fields>
          </SliderFieldSet>
        </Panel.Section>

        <Panel.Section>
          <SliderFieldSet legend="Seeding Duration">
            <SliderFieldSet.Description>
              Seeds will be distributed evenly during this time.
            </SliderFieldSet.Description>

            <SliderFieldSet.Fields>
              <SliderFieldSet.Field variant="slider">
                <ScreenReaderOnly as="label" id="seeding-duration-label">
                  Duration in Minutes
                </ScreenReaderOnly>

                <Slider
                  id="seeding-duration-slider"
                  aria-labelledby="seeding-duration-label"
                  min={CONFIG.DURATION.MIN}
                  max={CONFIG.DURATION.MAX}
                  ticks={{
                    [CONFIG.DURATION.MIN]: `20 min`,
                    [CONFIG.DURATION.MAX]: '12 hrs'
                  }}
                  onChange={handleDurationChange}
                  value={state.formData.duration}
                  disabled={configMutation.status === 'loading'}
                />
              </SliderFieldSet.Field>

              <SliderFieldSet.Field variant="field">
                <TextField
                  label="Fine-Grained Duration in Minutes"
                  labelHidden
                  data-lpignore="true"
                  maxWidth={['100%', '100%', '100%', '120px']}
                  suffix={<SuffixText>minutes</SuffixText>}
                  id="fine-grained-seeding-duration"
                  name="duration"
                  onChange={handleDurationChange}
                  onBlur={handleDurationBlur}
                  value={state.formData.duration}
                  disabled={configMutation.status === 'loading'}
                  data-track={true}
                />
              </SliderFieldSet.Field>
            </SliderFieldSet.Fields>
          </SliderFieldSet>
        </Panel.Section>

        <Panel.Section>
          <SliderFieldSet legend="Seeding Reset Time">
            <SliderFieldSet.Description>
              Hours until a campaign is eligible to be seeded again
            </SliderFieldSet.Description>

            <SliderFieldSet.Fields>
              <SliderFieldSet.Field variant="slider">
                <ScreenReaderOnly as="label" id="seeding-reset-time-label">
                  Reset Time in Hours
                </ScreenReaderOnly>

                <Slider
                  id="seeding-reset-time-slider"
                  aria-labelledby="seeding-reset-time-label"
                  min={CONFIG.RESET.MIN}
                  max={CONFIG.RESET.MAX}
                  ticks={{
                    [CONFIG.RESET.MIN]: '22 hours',
                    [24 * 3]: '3 days',
                    [24 * 5]: '5 days',
                    [CONFIG.RESET.MAX]: '7 days'
                  }}
                  onChange={handleResetChange}
                  value={state.formData.reset}
                  disabled={configMutation.status === 'loading'}
                />
              </SliderFieldSet.Field>

              <SliderFieldSet.Field variant="field">
                <TextField
                  label="Fine-Grained Reset Time in Hours"
                  labelHidden
                  data-lpignore="true"
                  id="fine-grained-reset-time"
                  name="reset"
                  maxWidth={['100%', '100%', '100%', '100px']}
                  suffix={<SuffixText>hours</SuffixText>}
                  onChange={handleResetChange}
                  onBlur={handleResetBlur}
                  value={state.formData.reset}
                  disabled={configMutation.status === 'loading'}
                  data-track={true}
                />
              </SliderFieldSet.Field>
            </SliderFieldSet.Fields>
          </SliderFieldSet>
        </Panel.Section>

        <Panel.Section>
          <ButtonWrapper>
            <Button
              type="submit"
              variant="primary"
              loading={configMutation.status === 'loading' && !primaryAcctBlocked}
            >
              Save Settings
            </Button>

            <Button
              variant="secondary"
              onClick={() => dispatch({ type: 'RESET_FORM' })}
              disabled={configMutation.status === 'loading'}
            >
              Cancel
            </Button>
          </ButtonWrapper>
        </Panel.Section>
      </Form>
      <AutomaticSeedingSettingsModal
        open={isModalOpen}
        onClose={closeModal}
        handleClick={() =>
          dispatch({
            type: 'FORM_SUBMIT',
            mutationFn: configMutation.mutate,
            closeModal: closeModal
          })
        }
        isLoading={configMutation.status === 'loading'}
      />
    </>
  );
}

InlineSeedingSettingsForm.propTypes = {
  initialFormData: PropTypes.shape({
    threshold: PropTypes.number,
    duration: PropTypes.number,
    reset: PropTypes.number,
    campaignIds: PropTypes.array,
    campaignIdsInput: PropTypes.string,
    mutationType: PropTypes.oneOf(['create', 'update'])
  })
};

function SuffixText({ children }) {
  return (
    <ScreenReaderExclude as={SubduedText} marginTop="200">
      <TranslatableText>{children}</TranslatableText>
    </ScreenReaderExclude>
  );
}

/**
 * @description Re-formatted form data derived from `/v1/inline-seeds/config`
 * @typedef InlineSeedFormData
 * @type {object}
 * @property {number} threshold - Number of messages seen for a campaign before seed sending starts.
 * @property {number} duration - Number of minutes after threshold is met over which to spread out seed messages.
 * @property {number} reset - Number of hours after threshold is met until seed sending can happen again.
 * @property {string} mutationType - 'create' | 'update' - uses relevant POST or PUT upon submitting the form
 * @property {string} campaignsToSeed - 'include' | 'exclude' - maps to `match` and `exclude` in the API
 * @property {Array} campaignIds - Array of campaign IDs to be passed to `match` or `exclude`
 * @property {string} campaignIdsInput - Raw user entry before adding tags to the multi entry field
 */

/**
 * @param {InlineSeedFormData} initialFormData
 * @returns {Array} - returns [send, dispatch] methods from `useReducer`
 */
function useInlineSeedingSettings(initialFormData) {
  const initialState = {
    errors: {},
    formData: initialFormData
  };

  function reducer(state, action, exec) {
    switch (action.type) {
      case 'CAMPAIGNS_TO_SEED_CHANGE': {
        const nextFormData = {
          ...state.formData,
          campaignsToSeed: action.value,
          campaignIdsInput: '', // Clears any in-progress user entry in the `TextField`
          campaignIds: [] // Clears any existing campaign IDs
        };

        return {
          ...state,
          formData: nextFormData
        };
      }

      case 'CAMPAIGN_IDS_CHANGE': {
        const nextFormData = {
          ...state.formData,
          campaignIdsInput: action.value
        };

        // Clear the error if resolved
        if (state.errors.campaignIds) {
          delete state.errors.campaignIds;
        }

        return {
          ...state,
          formData: nextFormData
        };
      }

      case 'CAMPAIGN_IDS_ADD': {
        const prevCampaignIds = state.formData.campaignIds;

        const nextFormData = {
          ...state.formData,
          campaignIdsInput: '', // Clear the freeform entry
          campaignIds: [...prevCampaignIds, action.value]
        };

        return {
          ...state,
          formData: nextFormData
        };
      }

      case 'CAMPAIGN_IDS_BLUR': {
        const prevCampaignIds = state.formData.campaignIds;
        const nextCampaignIds = action.value.split(' '); // If several strings are pasted in to the field with an empty space, split them in to an array of values

        // Show an error if the user has not supplied at least one campaign ID or provided any entry in to the text field
        if (_.isEmpty(state.formData.campaignIds) && _.isEmpty(state.formData.campaignIdsInput)) {
          const nextErrors = {
            ...state.errors,
            campaignIds: 'At least one Campaign ID is required.'
          };

          return {
            ...state,
            errors: nextErrors
          };
        }

        // Add the user's freeform entry to the campaign IDs array if present and no error was encountered
        if (!_.isEmpty(state.formData.campaignIdsInput)) {
          const nextFormData = {
            ...state.formData,
            campaignIdsInput: '', // Clear the freeform entry
            campaignIds: [...prevCampaignIds, ...nextCampaignIds]
          };
          // Clear the the campaign IDs error
          const nextErrors = {
            ...state.errors,
            campaignIds: undefined
          };

          return {
            ...state,
            errors: nextErrors,
            formData: nextFormData
          };
        }

        // Otherwise return the current state
        return state;
      }

      case 'CAMPAIGN_IDS_REMOVE': {
        const nextFormData = {
          ...state.formData,
          // Only leave campaign IDs that do not match the passed-in index
          campaignIds: state.formData.campaignIds.filter(
            (_value, index) => index !== action.value.index
          )
        };
        // Clear any errors when removing
        const nextErrors = {
          ...state.errors,
          campaignIds: undefined
        };

        return {
          ...state,
          formData: nextFormData,
          errors: nextErrors
        };
      }

      case 'THRESHOLD_SLIDER_CHANGE': {
        const nextFormData = {
          ...state.formData,
          threshold: action.value
        };

        return {
          ...state,
          formData: nextFormData
        };
      }

      case 'THRESHOLD_FIELD_CHANGE': {
        const value = parseLocaleString(action.value);
        const threshold = isNaN(value) ? 0 : value;
        const nextFormData = {
          ...state.formData,
          threshold
        };

        return {
          ...state,
          formData: nextFormData
        };
      }

      case 'THRESHOLD_FIELD_BLUR': {
        const value = parseLocaleString(action.value);
        const threshold = clamp(value, CONFIG.THRESHOLD.MIN, CONFIG.THRESHOLD.MAX);
        const nextFormData = {
          ...state.formData,
          threshold
        };

        return {
          ...state,
          formData: nextFormData
        };
      }

      case 'DURATION_CHANGE': {
        const nextFormData = {
          ...state.formData,
          duration: action.value
        };

        return {
          ...state,
          formData: nextFormData
        };
      }

      case 'DURATION_FIELD_BLUR': {
        // If the user enters a non-number, revert the field to the previous value
        const value = isNaN(action.value) ? 0 : action.value;
        const duration = clamp(value, CONFIG.DURATION.MIN, CONFIG.DURATION.MAX);
        const nextFormData = {
          ...state.formData,
          duration
        };

        return {
          ...state,
          formData: nextFormData
        };
      }

      case 'RESET_CHANGE': {
        const nextFormData = {
          ...state.formData,
          reset: action.value
        };

        return {
          ...state,
          formData: nextFormData
        };
      }

      case 'RESET_FIELD_BLUR': {
        // If the user enters a non-number, revert the field to the previous value
        const value = isNaN(action.value) ? 0 : action.value;
        const reset = clamp(value, CONFIG.RESET.MIN, CONFIG.RESET.MAX);
        const nextFormData = {
          ...state.formData,
          reset
        };

        return {
          ...state,
          formData: nextFormData
        };
      }

      case 'RESET_FORM': {
        return initialState;
      }

      case 'FORM_SUBMIT': {
        if (_.isEmpty(state.formData.campaignIds)) {
          exec(() => {
            const inputEl = document.querySelector('[data-id="campaign-ids-field"]');

            // This could have been accomplished with refs, but refs are a huge pain for such a simple interaction
            inputEl.focus();
          });
          const nextErrors = {
            ...state.errors,
            campaignIds: 'At least one Campaign ID is required.'
          };

          if (action.closeModal) action.closeModal();

          return {
            ...state,
            errors: nextErrors
          };
        }

        // When no errors are present, make the relevant request to the API
        exec(() => {
          const nextConfig = toApiFriendlyFormat(state.formData);

          return action.mutationFn(nextConfig);
        });

        return state;
      }

      default: {
        // eslint-disable-next-line no-console
        console.warn(`${action.type} is not supported by 'useSettingsForm'`);

        return state;
      }
    }
  }

  const [state, dispatch] = useEffectReducer(reducer, initialState);

  return [state, dispatch];
}
