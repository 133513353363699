import React from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { ApiErrorBanner } from 'src/components';
import { TableSkeleton } from 'src/components/loading';
import { Panel } from 'src/components/matchbox';
import { useSparkPostQuery } from 'src/hooks';
import { getInlineSeedsStatus } from 'src/helpers/api/seedList';
import { SeedingActivityPanel } from './SeedingActivityPanel';
import { toTableFriendly } from './helpers';

export function SeedingActivity() {
  const { subaccountId } = useParams();
  const { status, data, error, refetch } = useSparkPostQuery(
    () => getInlineSeedsStatus({ subaccountId }),
    {
      refetchOnWindowFocus: false,
      select: (data) => toTableFriendly(data)
    }
  );

  if (status === 'loading') {
    return (
      <Panel>
        <Panel.Section>
          <TableSkeleton numberOfColumns={3} />
        </Panel.Section>
      </Panel>
    );
  }

  if (status === 'error') {
    return (
      <ApiErrorBanner
        message="Sorry, we seem to have had some trouble loading your seeding activity."
        errorDetails={error.message}
        reload={refetch}
      />
    );
  }

  return <SeedingActivityPanel data={data} />;
}
