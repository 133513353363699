import React, { Component } from 'react';
import { connect } from 'react-redux';

// REDUX
import { getEventSamples, testWebhook } from 'src/actions/webhooks';
import { showAlert } from 'src/actions/globalAlert';

// COMPONENTS
import Loading from 'src/components/loading';
import { Button, CodeBlock, Panel, Stack, Layout, Text, Box, Label } from 'src/components/matchbox';
import { TranslatableText } from 'src/components/text';
import { CopyToClipboard } from 'src/components';

export class TestTab extends Component {
  state = {
    testSent: false,
    sampleEvent: null
  };

  componentWillReceiveProps(nextProps) {
    if (!this.state.sampleEvent && nextProps.samples) {
      this.setState((_state, props) => ({
        sampleEvent: this.buildTestRequest(props.webhook, nextProps.samples)
      }));
    }
  }

  componentDidMount() {
    const { webhook, samples } = this.props;

    if (!samples) {
      this.props.getEventSamples(['delivery']);
    }

    if (!this.state.sampleEvent && samples) {
      this.setState({ sampleEvent: this.buildTestRequest(webhook, samples) });
    }
  }

  buildTestRequest(webhook, payload) {
    const parser = document.createElement('a');
    parser.href = webhook.target;

    const requestLines = [
      `POST ${parser.pathname} HTTP/1.1`,
      `Host: ${parser.hostname}`,
      'Content-Type: application/json',
      'X-MessageSystems-Batch-ID: 77c2b630-d712-11e4-9642-efc2723b99c1' // hardcoded value in the API
    ];

    if (webhook.auth_token) {
      requestLines.push(`X-MessageSystems-Webhook-Token: ${webhook.auth_token}`);
    }
    if (webhook.auth_type === 'oauth2') {
      requestLines.push('Authorization: Bearer <OAUTH2 ACCESS TOKEN>');
    } else if (webhook.auth_type === 'basic') {
      requestLines.push('Authorization: Basic <BASE64 ENCODED CREDENTIALS>');
    }

    requestLines.push('Connection: close');
    requestLines.push('');
    requestLines.push(JSON.stringify(payload, null, 2));

    return requestLines.join('\n');
  }

  testWebhook = () => {
    const { testWebhook, webhook, samples, showAlert } = this.props;

    return testWebhook({ id: webhook.id, subaccount: webhook.subaccount, message: samples }).then(
      () => {
        showAlert({ type: 'success', message: 'The test was successful!' });
        this.setState({ testSent: true });
      }
    );
  };

  render() {
    if (this.props.samplesLoading) {
      return <Loading />;
    }

    const { webhook, testResponse, testLoading } = this.props;
    const { testSent } = this.state;

    const buttonText = testSent
      ? testLoading
        ? 'Sending...'
        : 'Re-send batch'
      : 'Send Test Batch';

    const isTestSuccessful = !testLoading && testSent && testResponse && testResponse.status <= 299;

    return (
      <Layout>
        <Layout.Section annotated>
          <Box px="400">
            <Layout.SectionTitle>Test</Layout.SectionTitle>
            <Text color="gray.700" fontSize="200">
              Testing a webhook will send an example message batch to the target URL. Sending a test
              can help to troubleshoot the webhook.
            </Text>
          </Box>
        </Layout.Section>

        <Layout.Section>
          <Panel>
            <Panel.Section>
              <Stack>
                <Box>
                  <Label label="Target URL" />

                  <p>{webhook.target}</p>
                </Box>

                <CodeBlock code={this.state.sampleEvent || 'generating...'} height={300} />
              </Stack>
            </Panel.Section>

            <Panel.Section>
              <Button variant="primary" disabled={testLoading} onClick={this.testWebhook}>
                <TranslatableText>{buttonText}</TranslatableText>
              </Button>
            </Panel.Section>

            <Panel.Section>
              <Stack>
                {!testSent && (
                  <>
                    <p>A test must be sent to view the server response.</p>
                    <CodeBlock code="" height={300} />
                  </>
                )}

                {isTestSuccessful && (
                  <>
                    <p>The server responded like this:</p>
                    <CodeBlock code={JSON.stringify(testResponse, null, '  ')} height={300} />
                  </>
                )}
              </Stack>
            </Panel.Section>

            {isTestSuccessful && (
              <Panel.Section>
                <CopyToClipboard
                  value={JSON.stringify(testResponse, null, '  ')}
                  variant="secondary"
                >
                  Copy JSON
                </CopyToClipboard>
              </Panel.Section>
            )}
          </Panel>
        </Layout.Section>
      </Layout>
    );
  }
}

const mapStateToProps = ({ webhooks }) => ({
  samples: webhooks.samples,
  samplesLoading: webhooks.samplesLoading,
  testLoading: webhooks.testLoading,
  testResponse: webhooks.testResponse
});

export default connect(mapStateToProps, { getEventSamples, testWebhook, showAlert })(TestTab);
