import React, { useCallback } from 'react';

import { tokens } from '@sparkpost/design-tokens';
import { Box, Panel, Stack, Tag, Text } from 'src/components/matchbox';
import { Emphasized, Heading, TranslatableText } from 'src/components/text';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import styled from 'styled-components';
import { HEALTHSCORE_EVALUTATION_LABELS, HEALTHSCORE_EVALUTATION_TYPES } from '../constants';
import { useAlertForm } from '../useAlertForm';

const StyledQuote = styled(Text).attrs({
  as: 'blockquote'
})`
  border-left: 1px solid #d9e0e6;
  padding-left: ${tokens.spacing_200};
  margin-left: ${tokens.spacing_200};
  margin-top: 0;
  color: ${tokens.color_gray_700};
`;

const CONDITION_LABELS = {
  gt: 'above',
  lt: 'below'
};

export function SubaccountFilters({ subaccounts, any_subaccount }) {
  const getHealthScoreValue = () => {
    const parsedSubaccounts = subaccounts || [];
    if (any_subaccount) return 'Primary and all subaccounts';
    if (parsedSubaccounts.length === 1 && parsedSubaccounts[0] === 0) return 'Primary';

    const accountsString = parsedSubaccounts.map((subaccount) => subaccount.value).join(', ');
    return accountsString.substring(accountsString.lenght - 1);
  };
  const subaccountValue = getHealthScoreValue();
  return (
    <StyledQuote>
      <Text>
        Subaccount
        <Text fontWeight="500" as="span" fontSize="200">
          <Emphasized> is equal to</Emphasized>
        </Text>
        <TranslatableText>{` ${subaccountValue}`}</TranslatableText>
      </Text>
    </StyledQuote>
  );
}

export default function AlertSummary() {
  const {
    state: { metricData, conditionData }
  } = useAlertForm();
  const {
    state: { healthScoreFilters, healthScoreSubaccounts }
  } = useAnalyticsReportContext();
  const placeholder = conditionData.source === HEALTHSCORE_EVALUTATION_TYPES.raw ? '' : '%';
  const SubaccountBreakdown = useCallback(() => {
    const facetValues = healthScoreFilters?.facet_values || [];
    return (
      <Box mt="-24px" mb="-12px">
        {healthScoreFilters?.facet_name && (
          <TranslatableText>
            and is broken down by <Tag color="blue">{healthScoreFilters.facet_name}</Tag>
            {' equals to '}
            {facetValues.map((facet) => (
              <Tag color="gray">{facet}</Tag>
            ))}
          </TranslatableText>
        )}
      </Box>
    );
  }, [healthScoreFilters.facet_name, healthScoreFilters.facet_values]);

  const getConditionLabel = useCallback(
    (rawCondition) => {
      if (conditionData.source === HEALTHSCORE_EVALUTATION_TYPES.raw) {
        return CONDITION_LABELS[rawCondition];
      }
      return 'greater than';
    },
    [conditionData.source]
  );

  return (
    <Box height="296px">
      <Panel>
        <Panel.Section>
          <Stack>
            <Heading as="h4">Alert Summary</Heading>
            {!metricData.selectedMetric.key && (
              <Text>
                The alert summary will auto-populate when a metric and configurations are selected.
              </Text>
            )}
            {metricData.selectedMetric.key && (
              <Box>
                <TranslatableText>The alert is triggered when </TranslatableText>
                <Tag color="blue">{metricData.selectedMetric.label}</Tag>
                <TranslatableText> has a </TranslatableText>
                {(!conditionData.alertThreshold || !conditionData.condition) && (
                  <TranslatableText>...</TranslatableText>
                )}

                {conditionData.source && (
                  <Tag color="blue">{HEALTHSCORE_EVALUTATION_LABELS[conditionData.source]}</Tag>
                )}

                {conditionData.condition && (
                  <Tag color="blue">{`${getConditionLabel(conditionData.condition)}`}</Tag>
                )}

                {conditionData.alertThreshold && (
                  <Tag color="blue">{`${conditionData.alertThreshold} ${placeholder}`}</Tag>
                )}
              </Box>
            )}
            <SubaccountFilters
              subaccounts={healthScoreSubaccounts?.subaccounts}
              any_subaccount={healthScoreSubaccounts?.any_subaccount}
            />
            <SubaccountBreakdown />
          </Stack>
        </Panel.Section>
      </Panel>
    </Box>
  );
}
