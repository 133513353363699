import React from 'react';
import { Button, ScreenReaderOnly, Table, Tag, Text } from 'src/components/matchbox';
import { PageLink } from 'src/components/links';
import { formatMinuteString } from 'src/helpers/units';
import { ScreenReaderExclude } from 'src/components/a11y';
import { Bold } from 'src/components/text';
import { Percent } from 'src/components/formatters';
import { formatTimeRemaining } from './helpers';
import { ChevronRight } from '@sparkpost/matchbox-icons';

export function SeedingActivityTable({ tableInstance }) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = tableInstance;

  return (
    <Table title="Seeding Activity" {...getTableProps()}>
      <ScreenReaderOnly as="thead">
        {headerGroups.map((headerGroup) => {
          return (
            <Table.Row header {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <Table.HeaderCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          );
        })}
      </ScreenReaderOnly>

      <tbody {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row); // `react-table` requires this to be invoked first
          const { values } = row;
          const timeRemaining = formatMinuteString(values.timeRemaining);
          const queryFilters = [
            {
              AND: {
                campaigns: { eq: [values.campaignId] }
              }
            }
          ];
          //TODO Add reusable function for handling nested objects for use in urls
          const viewResultsTo = {
            pathname: '/signals/analytics',
            search: `?query_filters=${JSON.stringify(
              queryFilters
            )}&metrics=spam_folder_rate&metrics=inbox_folder_rate&metrics=count_spam&metrics=count_inbox&precision=day&group_by=mailbox-provider&industryBenchmarkMetric=inbox_folder_rate&industryBenchmarkFilters={\"industryCategory\":\"all\",\"mailboxProvider\":\"all\"}`
          };

          return (
            <Table.Row key={`seeding-activity-row-${index}`} {...row.getRowProps()}>
              <Table.Cell>{values.campaignId}</Table.Cell>

              <Table.Cell>
                {values.status === 'active' ? <Tag color="green">Active</Tag> : null}

                {values.status === 'pending' ? <Tag>Pending</Tag> : null}
              </Table.Cell>

              <Table.Cell>
                {/* These pseudo headings repeat the column headers to screen reader users, so we hide from screen reader only users as a result */}
                <ScreenReaderExclude as="div">
                  <Text as={Bold} fontSize="200">
                    Threshold
                  </Text>
                </ScreenReaderExclude>
                <Percent value={values.thresholdPercentage} />
              </Table.Cell>

              <Table.Cell>
                <ScreenReaderExclude as="div">
                  <Text as={Bold} fontSize="200">
                    Time Remaining
                  </Text>
                </ScreenReaderExclude>

                {/* TODO: Reformat according to mocks */}
                {timeRemaining > 0 ? (
                  <div>{formatTimeRemaining(timeRemaining)}</div>
                ) : (
                  <div>
                    <ScreenReaderExclude>---</ScreenReaderExclude>

                    <ScreenReaderOnly>No Time Remaining</ScreenReaderOnly>
                  </div>
                )}
              </Table.Cell>

              <Table.Cell align="right">
                <PageLink as={Button} to={viewResultsTo} variant="minimal" size="small">
                  View Results <Button.Icon as={ChevronRight} />
                </PageLink>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </tbody>
    </Table>
  );
}
