import { dehydrateFilters } from '@sparkpost/report-builder/helpers/metrics';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getScheduledReports } from 'src/actions/reports';
import { Loading } from 'src/components';
import { Panel, Stack } from 'src/components/matchbox';
import { parseSearch } from 'src/helpers/analyticsReport';
import { usePageFilters } from 'src/hooks';
import { useAnalyticsReportContext } from '../context/AnalyticsReportContext';
import DateTimeSection from './DateTimeSection';

import { nonPrefetchedMetrics, PREFETCHED_KEY } from '@sparkpost/report-builder/config';
import { AnalyticsReportsModals } from './AnalyticsReportModals';

const initFilters = {
  from: {},
  to: {},
  range: {},
  timezone: {},
  precision: {},
  filters: {},
  metrics: {},
  query_filters: {},
  comparisons: {},
  report: {},
  industryBenchmarkMetric: {},
  industryBenchmarkFilters: {},
  group_by: {},
  includePrefetched: {},

  // Url params to track usage
  favorite: {},
  recent: {}
};

const mapNonPrefetchedMetricKeyToPrefetched = {};

nonPrefetchedMetrics.forEach((metric) => {
  mapNonPrefetchedMetricKeyToPrefetched[metric.key] = metric[PREFETCHED_KEY];
});

const normalizeMetricsKeys = (metricKeyArr) =>
  metricKeyArr.map((metricKey) => mapNonPrefetchedMetricKeyToPrefetched[metricKey] || metricKey);

export function ReportOptions(props) {
  const dispatch = useDispatch();
  const { reportLoading } = props;
  const {
    state: reportOptions,
    actions,
    selectors,
    includePrefetchedOpens
  } = useAnalyticsReportContext();
  const { refreshReportOptions } = actions;
  const { selectedReport } = reportOptions;

  const { selectUiParams } = selectors;
  const { updateFilters, filters: pageFilters } = usePageFilters(initFilters, {
    isReplaced: true
  });

  useEffect(() => {
    const parseString = (str) => {
      try {
        return JSON.parse(str);
      } catch (e) {
        return str;
      }
    };

    if (pageFilters.includePrefetched !== undefined)
      actions.setIncludePrefetchedOpens(parseString(pageFilters.includePrefetched));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedReport?.id) dispatch(getScheduledReports(selectedReport?.id));
  }, [dispatch, selectedReport]);

  // Updates the query params with incoming search option changes
  useEffect(() => {
    if (reportOptions.isReady) {
      const { filters: selectedFilters, ...update } = selectUiParams;
      const { filters } = reportOptions;

      update.query_filters = Boolean(filters.length)
        ? JSON.stringify(dehydrateFilters(filters))
        : null; //Explicitly unsetting filters

      if (update.range !== 'custom') {
        update.to = null;
        update.from = null;
      }

      if (update.industryBenchmarkMetric && update.industryBenchmarkFilters) {
        update.industryBenchmarkFilters = JSON.stringify(update.industryBenchmarkFilters);
      } else {
        update.industryBenchmarkMetric = null;
        update.industryBenchmarkFilters = null;
      }

      updateFilters(
        {
          ...update,
          metrics: normalizeMetricsKeys(update.metrics),
          includePrefetched: includePrefetchedOpens,
          report: selectedReport?.id
        },
        { arrayFormat: 'indices' }
      );
    }
  }, [
    selectUiParams,
    updateFilters,
    reportOptions.isReady,
    selectedReport,
    reportOptions,
    includePrefetchedOpens
  ]);

  const handleReportChange = (report) => {
    if (report) {
      const options = parseSearch(report.query_string);
      //Keep time range and filters when changing to preset report from another preset report.
      if (report.type === 'preset' && (!selectedReport || selectedReport.type === 'preset')) {
        const { from, to, relativeRange, timezone, precision, filters, comparisons, groupBy } =
          reportOptions;
        const mergedOptions = {
          ...options,
          from,
          to,
          relativeRange,
          timezone,
          precision,
          filters,
          comparisons,
          groupBy
        };
        refreshReportOptions(mergedOptions);
      } else {
        refreshReportOptions(options);
      }
    }
    actions.setReport(report);
  };

  const handleTimezoneSelect = useCallback(
    (timezone) => {
      refreshReportOptions({ timezone: timezone.value });
    },
    [refreshReportOptions]
  );

  if (!reportOptions.isReady) {
    return <Loading />;
  }

  return (
    <Stack>
      <Panel>
        <div data-id="report-options">
          <Panel.Section>
            <DateTimeSection
              reportOptions={reportOptions}
              handleTimezoneSelect={handleTimezoneSelect}
              reportLoading={reportLoading}
              refreshReportOptions={refreshReportOptions}
            />
          </Panel.Section>
          <AnalyticsReportsModals handleReportChange={handleReportChange} />
        </div>
      </Panel>
    </Stack>
  );
}

export default ReportOptions;
