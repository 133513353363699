import { useCallback } from 'react';
import { useSparkPostQuery } from 'src/hooks';
import { getIpPools } from 'src/helpers/api/ipPools';
export function useIpPools() {
  const { data, status } = useSparkPostQuery(getIpPools, {
    staleTime: 1000 * 30,
    select: (data) => {
      return data.reduce((acc, { ips, name }) => {
        ips.forEach(({ external_ip }) => {
          acc[external_ip] = name;
        });
        return acc;
      }, {});
    }
  });

  const getIPPool = useCallback(
    (ip) => {
      if (status !== 'success') {
        return undefined;
      }
      return data[ip];
    },
    [data, status]
  );

  return {
    getIPPool
  };
}
