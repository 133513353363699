import React from 'react';
import { Helmet } from 'react-helmet';
import getConfig from 'src/helpers/getConfig';
import RudderStackIdentify from './RudderStackIdentify';
import RudderStackPage from './RudderStackPage';

const getScript = (
  key: string
): string => `!function(){var e=window.rudderanalytics=window.rudderanalytics||[];e.methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId"],e.factory=function(t){return function(){var r=Array.prototype.slice.call(arguments);return r.unshift(t),e.push(r),e}};for(var t=0;t<e.methods.length;t++){var r=e.methods[t];e[r]=e.factory(r)}e.loadJS=function(e,t){var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://rs-cdn.analytics.sparkpost.com/v1.1/rudder-analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)},e.loadJS(),
e.load('${key}', 'https://rs-dataplane.analytics.sparkpost.com'),
e.page()}();`;

export const RudderStack = (): JSX.Element | null => {
  const enabled = getConfig('rudderstack.enabled') || false;

  if (enabled) {
    const key = getConfig('rudderstack.publicKey');

    if (key) {
      return (
        <>
          <Helmet>
            <script type="text/javascript">{getScript(key)}</script>
          </Helmet>
          <RudderStackIdentify />
          <RudderStackPage />
        </>
      );
    }
  }

  return null;
};

export default RudderStack;
