import React, { LegacyRef, MutableRefObject, Ref } from 'react';
import { Box, ComboBoxMenu, Inline, Label, Tag, TextField } from 'src/components/matchbox';
import { TranslatableText } from 'src/components/text';

type ItemProps = {
  children: React.ReactNode;
  onRemove: () => void;
};

const Item = React.forwardRef(({ onRemove, children, ...rest }: ItemProps, _ref) => {
  return (
    <Tag onRemove={onRemove} {...rest}>
      <TranslatableText>{children}</TranslatableText>
    </Tag>
  );
});

type ItemsProps = {
  children: React.ReactNode;
};

function Items({ children }: ItemsProps) {
  if (!children) {
    return null;
  }
  return (
    <Box position="relative" mt="200">
      <Inline space="200">{children}</Inline>
    </Box>
  );
}

const Menu = React.forwardRef((props, ref: MutableRefObject<HTMLDivElement>) => {
  return <ComboBoxMenu menuRef={ref} {...props} />;
});

const Input = React.forwardRef(({ children, ...props }, ref: Ref<HTMLInputElement>) => {
  return (
    <Box>
      <TextField id="combobox-input" ref={ref} {...props} />
      {children}
    </Box>
  );
});

// eslint-disable-next-line react/display-name
const ComboBox = React.forwardRef(({ children, ...rest }, ref: LegacyRef<HTMLDivElement>) => {
  return (
    <Box as="div" {...rest} ref={ref} position="relative">
      {children}
    </Box>
  );
}) as typeof Box & {
  Input: typeof Input;
  Item: typeof Item;
  Items: typeof Items;
  Label: typeof Label;
  Menu: typeof Menu;
};

type ComboBoxProps = {
  children?: React.ReactNode;
  itemToString: (value: string) => string;
  removeItem: (value: string) => void;
  selectedItems: string[];
};

/**
 * @name ComboBox
 * @description Would essentially take the place of the existing component ComboBoxTextField
 */
export function ComboBoxTextField(props: ComboBoxProps) {
  const { children, removeItem, selectedItems, itemToString, ...rest } = props;

  return (
    <ComboBox>
      <Input {...rest} />
      <Items>
        {selectedItems.map((item, index) => {
          return (
            <Item
              key={`combobox-item-${index}`}
              onRemove={() => {
                removeItem(item);
              }}
            >
              <TranslatableText>{itemToString(item)}</TranslatableText>
            </Item>
          );
        })}
      </Items>
    </ComboBox>
  );
}

Item.displayName = 'ComboBoxItem';
Menu.displayName = 'ComboBoxMenu';
Input.displayName = 'ComboBoxInput';
ComboBox.displayName = 'ComboBox';

ComboBox.Input = Input;
ComboBox.Label = Label;
ComboBox.Menu = Menu;
ComboBox.Items = Items;
ComboBox.Item = Item;

export default ComboBox;
