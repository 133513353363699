import React from 'react';
import { Box, Panel } from 'src/components/matchbox';
import PlanPrice from 'src/components/billing/PlanPrice';
import { PLAN_TIERS } from 'src/constants';
import { Warning } from '@sparkpost/matchbox-icons';
import styles from './CurrentPlanSection.module.scss';

const CurrentPlanSection = ({ currentPlan, isPlanSelected }) => {
  return (
    <Panel.LEGACY title="Current Plan">
      <Panel.LEGACY.Section className={styles.currentPlan}>
        <div className={styles.Title}>{PLAN_TIERS[currentPlan.tier]}</div>
        <div className={styles.Plan}>
          <Box marginLeft="400" marginTop="400">
            <PlanPrice showOverage showIp showCsm plan={currentPlan} />
          </Box>
          <input
            type="hidden"
            label="currentPlan"
            id="currentPlan"
            name="currentPlan"
            data-track={true}
            disabled
            value={currentPlan.code}
          />
        </div>
      </Panel.LEGACY.Section>
      {isPlanSelected && currentPlan.status === 'deprecated' && (
        <Panel.LEGACY.Section>
          <div className={styles.DeprecatedWarning}>
            <Warning size={28} />
            <div className={styles.content}>
              <span>Your current plan is no longer available. Once you switch back, </span>
              <strong>you won't be able to change back.</strong>
            </div>
          </div>
        </Panel.LEGACY.Section>
      )}
    </Panel.LEGACY>
  );
};

export default CurrentPlanSection;
