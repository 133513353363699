import { Warning } from '@sparkpost/matchbox-icons';
import React from 'react';
import { Empty, Unit } from 'src/components';
import { PageLink } from 'src/components/links';
import { LabelValueLoadingSkeleton } from 'src/components/loading';
import { Box, Column, Columns, LabelValue, Panel, Tag } from 'src/components/matchbox';
import { getIncidents } from 'src/helpers/api/blocklist';
import { getSendingIp } from 'src/helpers/api/sendingIps';
import { useSparkPostQuery } from 'src/hooks';
import useResourceDetailsContext from '../../hooks/useResourceDetailsContext';
import DataSources from './DataSources';
import { BlockListCountContainer } from './utils/styles';
import { WrappedText } from 'src/components/text';

export default function SendingIpTopSection({
  hasD12yProduct,
  deliverabilityDataStatus,
  deliverabilityData
}) {
  const {
    match: { params: { id } = {} } = {},
    isBlocklistEnabled,
    filters
  } = useResourceDetailsContext();
  const labelValues = isBlocklistEnabled
    ? [
        { width: 1 / 6, value: 'IP' },
        { width: 1 / 6, value: 'Hostname' },
        { width: 1 / 6, value: 'Warm-up Status' },
        { width: 1 / 6, value: 'Sent' },
        hasD12yProduct && { width: 1 / 6, value: 'Seed Count' },
        hasD12yProduct && { width: 1 / 6, value: 'Panel Count' },
        { width: 1 / 6, value: 'Blocklists' }
      ]
    : [{ value: 'IP' }, { value: 'Hostname' }, { value: 'Warm-up Status' }, { value: 'Sent' }];
  const { status: sendingIpDataStatus, data: sendingIpData = [] } = useSparkPostQuery(() =>
    getSendingIp(id)
  );
  const { status: blocklistDataStatus, data: blocklistData = [] } = useSparkPostQuery(
    () => getIncidents({ from: filters.from, to: filters.to, resources: [id].join(',') }),
    {
      enabled: isBlocklistEnabled
    }
  );

  const { external_ip, hostname, auto_warmup_enabled } = sendingIpData;
  if (
    deliverabilityDataStatus === 'error' ||
    sendingIpDataStatus === 'error' ||
    blocklistDataStatus === 'error'
  )
    return <Empty message="Failed to load the data. Refresh page."></Empty>;

  if (
    deliverabilityDataStatus === 'loading' ||
    sendingIpDataStatus === 'loading' ||
    blocklistDataStatus === 'loading'
  ) {
    return (
      <Panel.Section>
        <LabelValueLoadingSkeleton labelValues={labelValues} />
      </Panel.Section>
    );
  }

  let blocklistLink = `/signals/blocklist/incidents?search=${id}`;
  return (
    <Panel.Section>
      <Columns>
        {external_ip && (
          <Column width={1 / 6}>
            <LabelValue>
              <LabelValue.Label>IP</LabelValue.Label>
              <LabelValue.Value>{external_ip}</LabelValue.Value>
            </LabelValue>
          </Column>
        )}
        {hostname && (
          <Column width={1 / 6}>
            <LabelValue>
              <LabelValue.Label>Hostname</LabelValue.Label>
              <LabelValue.Value>
                <WrappedText>{hostname}</WrappedText>
              </LabelValue.Value>
            </LabelValue>
          </Column>
        )}

        <Column width={1 / 6}>
          <LabelValue>
            <LabelValue.Label>Warm-up Status</LabelValue.Label>
            <LabelValue.Value>
              {auto_warmup_enabled && <Tag color="green">Active</Tag>}
              {!auto_warmup_enabled && <Tag color="lightGray">Inactive</Tag>}
            </LabelValue.Value>
          </LabelValue>
        </Column>

        <DataSources
          dataDisplayOnly={true}
          hasD12yProduct={hasD12yProduct}
          deliverabilityData={deliverabilityData}
          deliverabilityDataStatus={deliverabilityDataStatus}
        />

        {isBlocklistEnabled && (
          <Column width={1 / 6}>
            <LabelValue>
              <LabelValue.Label>
                {blocklistData?.length > 0 && <Box as={Warning} color="red.700" />}
                Blocklists
              </LabelValue.Label>
              <LabelValue.Value>
                {blocklistData?.length > 0 && (
                  <BlockListCountContainer>
                    <PageLink to={blocklistLink}>
                      <Unit value={blocklistData?.length} unit="number"></Unit>
                    </PageLink>
                  </BlockListCountContainer>
                )}
                {blocklistData?.length === 0 && (
                  <BlockListCountContainer>None</BlockListCountContainer>
                )}
              </LabelValue.Value>
            </LabelValue>
          </Column>
        )}
      </Columns>
    </Panel.Section>
  );
}
