import { createSelector } from 'reselect';

export const selectWebhooksDocs = (state) => state.webhooks.docs || {};
export const selectMessageEventsDocs = (state) => state.messageEvents.documentation || {};

export const selectWebhookEventListing = createSelector([selectWebhooksDocs], (docs) => {
  const list = Object.keys(docs).reduce((accumulated, groupName) => {
    const group = docs[groupName];
    const listForGroup = Object.keys(group.events).map((key) => ({
      key,
      ...group.events[key]
    }));
    return [...accumulated, ...listForGroup];
  }, []);

  list.sort(({ display_name: a = '' }, { display_name: b = '' }) =>
    a.toLowerCase() < b.toLowerCase() ? -1 : 1
  );

  return list;
});

export const selectMessageEventListing = createSelector([selectMessageEventsDocs], (docs) =>
  Object.keys(docs)
    .sort()
    .map((type) => {
      const evt = docs[type];
      return {
        type,
        displayName: evt.displayName,
        description: evt.description
      };
    })
);

export const selectMessageEventListingv2 = createSelector([selectMessageEventsDocs], (docs) => {
  const updatedEventNames = { ...docs };
  Object.values(updatedEventNames).reduce((acc, cur) => {
    if (acc.displayName === 'AMP Initial Open') acc.displayName = 'AMP Open (Top Pixel)';
    if (acc.displayName === 'AMP Open') acc.displayName = 'AMP Open (Bottom Pixel)';
    if (acc.displayName === 'Initial Open') acc.displayName = 'Opens (Top Pixel)';
    if (acc.displayName === 'Open') acc.displayName = 'Opens (Bottom Pixel)';
    return cur;
  }, {});

  return Object.keys(updatedEventNames)
    .sort()
    .map((type) => {
      const evt = updatedEventNames[type];
      return {
        type,
        displayName: evt.displayName,
        description: evt.description
      };
    });
});
