import React, { FormEvent, forwardRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Column,
  Columns,
  ComboBoxTextField,
  Drawer,
  Panel,
  ScreenReaderOnly,
  Stack,
  Text
} from 'src/components/matchbox';
import { Comparison, Uppercase } from 'src/components/text';
import { Form } from 'src/components/tracking';
import { EVENTS_SEARCH_FILTERS } from 'src/constants';
import { allMetricsAreDeliverability } from 'src/helpers/metrics';
import {
  AddButton,
  DuplicateErrorBanner,
  MultiEntryController,
  RemoveButton,
  TypeSelect
} from 'src/pages/analyticsReport/components/FiltersForm/components';
import useFiltersForm from 'src/pages/analyticsReport/components/FiltersForm/useFiltersForm';
import { SubjectCampaignsWarning } from 'src/pages/analyticsReport/components/index';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import {
  filterTypeExistsInGroupings,
  toApiFormattedGroupings,
  toGroupingFields
} from 'src/pages/analyticsReport/helpers';
import { Grouping, MessageEvents, UseAnalyticsReportContext } from 'src/typescript';
import { getMessageFilterLabel } from '../helpers/getMessageFilterLabel';
import { getFiltersAsArray } from '../helpers/transformData';

const filterTypeOptions = getFiltersAsArray(EVENTS_SEARCH_FILTERS) as {
  label: string;
  value: string;
}[];

type FiltersFormProps = {
  handleSubmit: (value: $TODOFIXME) => void;
};

const populateGroupings = (searchObj: MessageEvents['search']): Grouping[] => {
  const arr: Grouping[] = [];

  const { events, ...rest } = searchObj;

  Object.keys(rest).map((type) => {
    const values = rest[type];

    if (values.length && EVENTS_SEARCH_FILTERS[type])
      arr.push({
        type: 'AND',
        filters: [
          {
            label: EVENTS_SEARCH_FILTERS[type]?.label || '',
            type,
            compareBy: 'eq',
            values,
            hasCompareBySelect: true,
            hasCompareByLikeOptions: true,
            valueField: 'typeahead',
            hasGroupingTypeRadioGroup: true,
            hasAndButton: true,
            hasOrButton: false,
            hasComparisonBetweenFilters: false,
            hasRemoveButton: false
          }
        ],
        hasAndBetweenGroups: false,
        hasAndButton: true,
        hasDuplicateFilters: false
      });
  });

  return arr;
};

const getMultiEntryKeycodesByType = (type: string): undefined | number[] => {
  const COMMA = 188;
  const ENTER = 13;

  if (
    [
      'subjects',
      'reasons',
      'campaigns',
      'templates',
      'ab_tests',
      'mailbox_providers',
      'mailbox_provider_regions'
    ].includes(type)
  )
    return [COMMA, ENTER];

  return []; // default (both above and space)
};

const FiltersForm = forwardRef<HTMLFormElement, FiltersFormProps>(function FiltersForm(
  { handleSubmit },
  ref
) {
  const { state, actions } = useFiltersForm();
  const { status } = state;
  const {
    setFilterType,
    setFilterCompareBy,
    setFilterValues,
    addGrouping,
    removeFilter,
    clearFilters,
    setFilters,
    submit,
    setGrouping
  } = actions;
  const groupings: Grouping[] = toGroupingFields(state.groupings);
  const { state: reportOptions } = useAnalyticsReportContext() as UseAnalyticsReportContext;
  const { metrics, filters, to, from } = reportOptions;
  const eventsState = useSelector<$TODOFIXME, $TODOFIXME>((state) => state.messageEvents);

  function handleFormSubmit(e: FormEvent): void {
    e.preventDefault(); // Prevents page refresh
    submit();
  }

  useEffect(() => {
    if (status === 'error') {
      // Using refs to manage focus is a *huge* pain with multiple elements - this isn't pretty but gets the job done and eliminates a *lot* of complexity
      // that comes with using a ref callback to store refs in an array.
      const errorBanners: NodeListOf<HTMLElement> = document.querySelectorAll(
        '[data-id="error-banner"]'
      );

      errorBanners[0].focus();
    }

    // If the status changes to "success" (only possible via form submission) then call the passed in submit handler
    if (status === 'success') {
      handleSubmit({ filters: toApiFormattedGroupings(groupings) });
    }

    // For this effect, only care about the status changing, so not using an exhaustive dependency check here
    // eslint-disable-next-line
  }, [status]);

  useEffect(() => {
    setFilters(filters);
  }, [setFilters, filters]);

  // first load
  useEffect(() => {
    const { dateOptions, ...search } = eventsState.search;

    const loadedGroupings = populateGroupings(search);

    if (loadedGroupings.length) setGrouping(loadedGroupings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MAX_FILTERS_ITEMS_TO_COMPARE = 10;

  const shouldShowSubjectCampaignWarning =
    !allMetricsAreDeliverability(metrics) &&
    filterTypeExistsInGroupings(groupings, 'subject_campaigns');

  const helpTextByFilter = (filterType: string) => {
    const baseHelpText = (entity: string) =>
      `A maximum of ${MAX_FILTERS_ITEMS_TO_COMPARE} ${entity} can be added.`;

    const helpTextSpecific = EVENTS_SEARCH_FILTERS[filterType]?.label;

    if (helpTextSpecific) return baseHelpText(helpTextSpecific);

    return undefined;
  };

  return (
    <Form onSubmit={handleFormSubmit} id="analyticsreport-filter-form" ref={ref}>
      <Box padding="500" paddingBottom="8rem">
        {shouldShowSubjectCampaignWarning && (
          <Box marginY="400">
            <SubjectCampaignsWarning type="filter" />
          </Box>
        )}

        <Stack>
          {groupings.map((grouping, groupingIndex) => {
            return (
              <div key={`grouping-${groupingIndex}`}>
                <Stack>
                  <Panel data-id="grouping">
                    {grouping.filters.map((filter, filterIndex) => {
                      const filterLabel = EVENTS_SEARCH_FILTERS[filter.type]?.label;
                      const filterPlaceholder = EVENTS_SEARCH_FILTERS[filter.type]?.placeholder;
                      const filterValue = filter?.type;

                      return (
                        <Box
                          as={Panel.Section}
                          position="relative"
                          key={`filters-${groupingIndex}-${filterIndex}`}
                        >
                          {status === 'error' &&
                          filterIndex === 0 &&
                          grouping.hasDuplicateFilters ? (
                            <Box marginY="400">
                              <DuplicateErrorBanner data-id="error-banner" />
                            </Box>
                          ) : null}

                          <Box as="fieldset" border="0" padding="0">
                            <ScreenReaderOnly as={'legend' as $TODOFIXME}>
                              Filter By
                            </ScreenReaderOnly>
                            <Stack space="400">
                              <Columns alignY="center">
                                <Column>
                                  <TypeSelect
                                    onChange={(e: $TODOFIXME) => {
                                      setFilterType({
                                        filterType: e.target.value,
                                        groupingIndex,
                                        filterIndex
                                      });

                                      setFilterCompareBy({
                                        compareBy: 'eq',
                                        groupingIndex,
                                        filterIndex
                                      });
                                    }}
                                    id={`filter-by-${groupingIndex}-${filterIndex}`}
                                    value={filterValue || undefined}
                                    options={filterTypeOptions}
                                  />
                                </Column>

                                <Column>
                                  {filter.hasCompareBySelect && (
                                    <Text pt="500">{getMessageFilterLabel(filterValue)}</Text>
                                  )}
                                </Column>
                              </Columns>

                              {filter.hasCompareBySelect && (
                                <MultiEntryController
                                  id={`multi-entry-${groupingIndex}-${filterIndex}`}
                                  initialValueList={filter.values}
                                  setFilterValues={setFilterValues}
                                  filterIndex={filterIndex}
                                  groupingIndex={groupingIndex}
                                  minLength={1}
                                  keycodes={getMultiEntryKeycodesByType(filterValue)}
                                  render={(props: $TODOFIXME) => {
                                    return (
                                      <ComboBoxTextField
                                        label={filterLabel}
                                        id={props.id}
                                        removeItem={props.handleRemove}
                                        onBlur={props.handleBlur}
                                        onKeyDown={props.handleKeyDown}
                                        onChange={props.handleChange}
                                        value={props.value}
                                        error={props.error}
                                        selectedItems={props.valueList}
                                        itemToString={(item) => (item ? item : '')}
                                        placeholder={filterPlaceholder}
                                        data-track={true}
                                        maxItems={MAX_FILTERS_ITEMS_TO_COMPARE}
                                        helpText={helpTextByFilter(filter.type)}
                                      />
                                    );
                                  }}
                                />
                              )}

                              {filter.hasComparisonBetweenFilters ? (
                                <Comparison>{grouping.type || ''}</Comparison>
                              ) : null}
                            </Stack>

                            {filter.hasRemoveButton ? (
                              <RemoveButton
                                onClick={() => removeFilter({ filterIndex, groupingIndex })}
                              />
                            ) : null}
                          </Box>
                        </Box>
                      );
                    })}
                  </Panel>
                </Stack>

                {grouping.hasAndButton ? (
                  <Box marginBottom="800" marginTop="500">
                    <AddButton onClick={() => addGrouping()}>
                      <Uppercase>Add Filter</Uppercase>
                    </AddButton>
                  </Box>
                ) : null}
              </div>
            );
          })}
        </Stack>

        <Drawer.Footer>
          <Box display="flex">
            <Box pr="100" flex="1">
              <Button useMatchboxVariant={false} width="100%" variant="primary" type="submit">
                Apply Filters
              </Button>
            </Box>
            <Box pl="100" flex="1">
              <Button
                useMatchboxVariant={false}
                width="100%"
                variant="secondary"
                onClick={clearFilters}
              >
                Clear Filters
              </Button>
            </Box>
          </Box>
        </Drawer.Footer>
      </Box>
    </Form>
  );
});

export default FiltersForm;
