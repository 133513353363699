import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from 'src/actions/auth';
import { Route } from 'react-router-dom';
import AccessControl from './AccessControl';
import { AUTH_ROUTE } from 'src/constants';
import RouteFocusHandler from './RouteFocusHandler';
import sessionEmitter from 'src/helpers/sessionEmitter';

export class PublicRoute extends Component {
  componentDidMount() {
    const { forceLogout } = this.props;

    if (forceLogout) {
      // NOTE: Forces reset of cookie and redux (happens when forceLogout: true is on the route definition)
      // NOTE: You cannot test this by manually landing on the page, only by having the routing send the user to a component with this flag
      sessionEmitter.emitLogout();
    }
  }

  render() {
    const {
      component: Component,
      condition,
      forceLogout,
      loggedIn,
      logout,
      ...routeProps
    } = this.props;

    if (forceLogout && loggedIn) {
      return (
        <>
          You are already logged in. <a href={window.location.origin}>Click here</a> to return to
          the dashboard.
        </>
      );
    }

    return (
      <Route
        {...routeProps}
        // eslint-disable-next-line react/jsx-no-bind
        render={(reactRouterProps) => (
          <AccessControl condition={condition} redirect={AUTH_ROUTE} wait={false}>
            <RouteFocusHandler />
            <Component {...routeProps} {...reactRouterProps} />
          </AccessControl>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn
});

export default connect(mapStateToProps, { logout })(PublicRoute);
