import React, { Component } from 'react';
import { Grid, Button, Panel, Stack, TextField, Modal } from 'src/components/matchbox';
import { Form } from 'src/components/tracking';
import { ButtonWrapper } from 'src/components';
import { TranslatableText } from 'src/components/text';

export default class DisableTfaModal extends Component {
  state = {
    password: '',
    showErrors: false
  };

  componentDidUpdate(oldProps) {
    if (!this.props.enabled && this.props.open) {
      this.props.onClose();
    }
    if (oldProps.open && !this.props.open) {
      this.setState({
        password: '',
        showErrors: false
      });
    }
    if (!oldProps.toggleError && this.props.toggleError) {
      this.setState({
        showErrors: true
      });
    }
  }

  handleInputChange = ({ target }) => {
    this.setState({ password: target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.disable(this.state.password);
  };

  render() {
    const { open, onClose, togglePending, toggleError, tfaRequired } = this.props;

    return (
      <Modal.LEGACY
        open={open}
        onClose={onClose}
        title={
          tfaRequired ? 'Reset Two-Factor Authentication' : 'Disable Two-Factor Authentication'
        }
      >
        <Panel.LEGACY
          title={
            tfaRequired ? 'Reset Two-Factor Authentication' : 'Disable Two-Factor Authentication'
          }
          accent
        >
          <Form
            onSubmit={this.handleSubmit}
            id={tfaRequired ? 'reset-tfa-form' : 'disable-tfa-form'}
          >
            <Panel.LEGACY.Section>
              <Stack>
                <p>
                  Enter your SparkPost password to{' '}
                  <TranslatableText>{tfaRequired ? 'reset' : 'disable'}</TranslatableText>
                  &nbsp;two-factor authentication.
                </p>
                <p>
                  If two-factor authentication is required on this account, you will be logged out
                  after disabling it. You can re-enable when you next log in.
                </p>
                <Grid>
                  <Grid.Column xs={12} md={6}>
                    <TextField
                      id="tfa-form-password"
                      name="Password"
                      label="Password"
                      type="password"
                      error={this.state.showErrors && toggleError ? 'Incorrect password' : ''}
                      placeholder="Enter your password"
                      onChange={this.handleInputChange}
                      value={this.state.password}
                      data-sensitive={true}
                    />
                  </Grid.Column>
                </Grid>
              </Stack>
            </Panel.LEGACY.Section>
            <Panel.LEGACY.Section>
              <ButtonWrapper>
                <Button type="submit" variant="primary" disabled={togglePending}>
                  {tfaRequired
                    ? togglePending
                      ? 'Reseting...'
                      : 'Reset MFA'
                    : togglePending
                    ? 'Disabling...'
                    : 'Disable MFA'}
                </Button>

                <Button variant="seconary" disabled={togglePending} onClick={onClose}>
                  Cancel
                </Button>
              </ButtonWrapper>
            </Panel.LEGACY.Section>
          </Form>
        </Panel.LEGACY>
      </Modal.LEGACY>
    );
  }
}
