/* eslint-disable local/no-css-module-import */
import { CheckCircleOutline, Warning } from '@sparkpost/matchbox-icons';
import { formatDate } from '@sparkpost/report-builder/helpers/date';
import PropTypes from 'prop-types';
import React from 'react';
import { Empty } from 'src/components';
import { ExternalLink, PageLink } from 'src/components/links';
import { Box, Panel, Stack, Text } from 'src/components/matchbox';
import styles from './Actions.module.scss';
import ChartHeader from './ChartHeader';

const Action = ({ content, link, type = 'bad', internal = false, linkText = 'Learn More' }) => {
  let iconMarkup;

  const linkMarkup =
    link &&
    (internal ? (
      <PageLink to={link} className={styles.Link}>
        {linkText}
      </PageLink>
    ) : (
      <ExternalLink to={link} className={styles.Link}>
        {linkText}
      </ExternalLink>
    ));

  if (type === 'bad') {
    iconMarkup = (
      <div className={styles.IconBad}>
        <Warning size={25} />
      </div>
    );
  }

  if (type === 'good') {
    iconMarkup = (
      <div className={styles.IconGood}>
        <CheckCircleOutline size={25} />
      </div>
    );
  }

  if (type === 'warning') {
    iconMarkup = (
      <div className={styles.IconWarning}>
        <Warning size={25} />
      </div>
    );
  }

  return (
    <div className={styles.ActionWrapper}>
      <div className={styles.Action}>
        {iconMarkup}
        <p>
          {content} {linkMarkup}
        </p>
      </div>
      <hr className={styles.Dash} role="presentation" />
    </div>
  );
};

const Actions = ({ actions, date, empty }) => {
  const title = date ? `Recommendations – ${formatDate.useMomentInput(date)}` : `Recommendations`;

  return (
    <>
      <ChartHeader title={title} />
      {!empty && (
        <Panel.LEGACY.Section>
          {actions.map((props, i) => (
            <Action key={i} {...props} />
          ))}
        </Panel.LEGACY.Section>
      )}
      {empty && <Empty minHeight="100px" message="No actions to display at this time." />}
    </>
  );
};

Actions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['good', 'bad', 'warning']),
      content: PropTypes.node,
      link: PropTypes.string
    })
  ),
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
};

export default Actions;

const ResourceDetailsAction = ({
  content,
  link,
  type = 'bad',
  internal = false,
  linkText = 'Learn More'
}) => {
  let iconMarkup;

  const linkMarkup =
    link &&
    (internal ? (
      <PageLink to={link}>{linkText}</PageLink>
    ) : (
      <ExternalLink to={link}>{linkText}</ExternalLink>
    ));

  if (type === 'bad') {
    iconMarkup = (
      <Box color="red.700" flex="0 0" mr="100">
        <Warning />
      </Box>
    );
  }

  if (type === 'good') {
    iconMarkup = (
      <Box color="green.700" flex="0 0" mr="100">
        <CheckCircleOutline />
      </Box>
    );
  }

  if (type === 'warning') {
    iconMarkup = (
      <Box color="yellow.400" flex="0 0" mr="100">
        <Warning />
      </Box>
    );
  }

  return (
    <Box display="flex">
      {iconMarkup}
      <Text as="p" looksLike="h6" color="gray.700" mt="100">
        {content} &nbsp;{linkMarkup}
      </Text>
    </Box>
  );
};

export const ResourceDetailsActions = ({ actions, empty }) => {
  if (empty) return null;
  return (
    <Stack>
      {actions.map((props, i) => (
        <ResourceDetailsAction key={i} {...props} />
      ))}
    </Stack>
  );
};

ResourceDetailsActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['good', 'bad', 'warning']),
      content: PropTypes.node,
      link: PropTypes.string
    })
  )
};
