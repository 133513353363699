import { PRIMARY_SUBACCOUNT } from 'src/constants';

/**
 * @description Select subaccounts from the API for use with the typeahead
 * @param {array} subaccounts - Array of subaccounts
 * @param {object} options
 * @param {boolean} options.hasPrimaryAccountOption - whether to include the primary account in the list of subaccount options
 * @returns {array} array of subaccounts with a unique `key` property as well as the primary account
 */
export function selectSubaccountsForTypeahead(
  subaccounts,
  { hasPrimaryAccountOption = false } = {}
) {
  return [hasPrimaryAccountOption ? PRIMARY_SUBACCOUNT : undefined, ...subaccounts]
    .map((subaccount) => {
      if (!subaccount) return undefined;

      return {
        ...subaccount,
        key: `subaccount-option-${subaccount.id}`
      };
    })
    .filter(Boolean); // Remove undefined values
}

/**
 * @description Select a subaccount from a list of subaccounts by its `id`
 * @param {array} subaccounts - Array of subaccounts
 * @param {number|string} subaccountId - Subaccount ID
 * @returns {object} subaccount with at least `name` and `id` properties
 */
export function selectSubaccountById(subaccounts, subaccountId) {
  if (subaccountId === 0 || subaccountId === '0') return PRIMARY_SUBACCOUNT;

  return subaccounts.find((subaccount) => subaccount.id.toString() === subaccountId.toString());
}
