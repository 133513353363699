import { useAuth0 } from '@auth0/auth0-react'; // should be the only import for this
import auth0js from 'auth0-js';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout, refreshAuth0 } from 'src/actions/auth';
import config from 'src/appConfig';
import { AUTH0_LOGOUT_ROUTE } from 'src/constants';
import { authQueryFn } from 'src/helpers/api';
import { getAuthToken } from 'src/helpers/api/auth0';
import authCookie from 'src/helpers/authCookie';
import sessionEmitter from 'src/helpers/sessionEmitter';
import useDebouncedEffect from 'src/helpers/useDebouncedEffect';
import { useAlert, useSparkPostMutation } from 'src/hooks';

let redispatchQueue = [];
let timeout;

export const AuthenticationStateContext = createContext();

/**
 * Authentication Provider
 **/
function Provider(props) {
  const { children, ...rest } = props;

  return (
    <AuthenticationStateContext.Provider value={rest}>
      <>{children}</>
    </AuthenticationStateContext.Provider>
  );
}

function mapStateToProps(state) {
  const { auth, currentUser } = state;

  return {
    authCookie,
    auth,
    isAuth0User: currentUser?.auth_migrated,
    currentUser
  };
}

const mapDispatchToProps = {
  logout
};

export const AuthenticationProvider = connect(mapStateToProps, mapDispatchToProps)(Provider);

/**
 * useAuthentication Hook
 **/
export function useAuthentication() {
  const [refetch, setRefetch] = useState();
  const history = useHistory();
  const context = useContext(AuthenticationStateContext);
  const dispatch = useDispatch();
  const auth0 = useAuth0();
  const { getAccessTokenSilently } = auth0;
  const auth0JS = new auth0js.WebAuth({
    clientID: config?.auth0?.clientId,
    domain: config?.auth0?.domain
  });
  const { showAlert } = useAlert();

  const changePassword = ({ email, authConnection }) => {
    const userEmail = email;
    try {
      auth0JS.changePassword(
        {
          connection: authConnection,
          email: userEmail
        },
        function (err, resp) {
          if (err) {
            showAlert({ type: 'error', message: err.message });
          } else {
            showAlert({ type: 'success', message: resp });
          }
        }
      );
    } catch (err) {
      showAlert({ type: 'error', message: err.message });
    }
  };

  if (context === undefined)
    throw new Error('useAuthentication must be used within an AuthenticationProvider');

  const handleSuccess = useCallback(
    (data) => {
      dispatch(refreshAuth0(data?.token));
      setRefetch(true);
    },
    [dispatch]
  );

  const handleErrors = useCallback(() => {
    sessionEmitter.emitLogout();
  }, []);

  const getAuthTokenMutation = useSparkPostMutation(
    ({ exchangeToken } = {}) => {
      return getAuthToken(exchangeToken);
    },
    {
      queryFn: authQueryFn,
      onError: handleErrors,
      onSuccess: handleSuccess,
      retry: false
    }
  );
  const { isIdle, mutate } = getAuthTokenMutation;

  const { auth: loggedIn } = context;
  const onSessionRefresh = useCallback(
    async ({ redispatchAfterRefresh }) => {
      if (loggedIn) {
        if (redispatchAfterRefresh) redispatchQueue.push(redispatchAfterRefresh);

        clearTimeout(timeout);
        timeout = setTimeout(async () => {
          if (isIdle) {
            try {
              // Note: getAccessTokenSilently needs to have "allow offline access" turned on with useRefreshTokens={true} prop on the provider.
              const silentToken = await getAccessTokenSilently({
                // https://auth0.github.io/auth0-react/interfaces/index.gettokensilentlyoptions.html#timeoutinseconds
                timeoutInSeconds: 15
              });
              if (silentToken) mutate({ exchangeToken: silentToken });
            } catch (e) {
              history.push(AUTH0_LOGOUT_ROUTE);
            }
          }
        }, 1000);
      }
    },
    [loggedIn, isIdle, getAccessTokenSilently, mutate, history]
  );

  useDebouncedEffect({
    effect: () => {
      if (refetch) {
        getAuthTokenMutation.reset();
        while (redispatchQueue.length > 0) {
          const refetch = redispatchQueue.shift();
          refetch();
        }
        setTimeout(() => {
          setRefetch(false);
        }, 1000);
      }
    },
    deps: [refetch],
    delay: 750
  });

  useEffect(() => {
    if (sessionEmitter.countRefresh() < 1) sessionEmitter.onRefresh(onSessionRefresh);
    return () => sessionEmitter.offRefresh(onSessionRefresh);
  }, [onSessionRefresh]);

  // Export the auth0 hook from here as the source of truth next to the app state context
  return {
    context,
    auth0,
    previousRoute: localStorage?.getItem(`app.sp.previous-route`),
    changePassword
  };
}
