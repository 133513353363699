import { AxiosError, Method } from 'axios';
import { Dispatch } from 'react';
import { QueryClient } from 'react-query';
import { fetch as fetchAccount } from 'src/actions/account';
import { refresh } from 'src/actions/auth';
import { showAlert } from 'src/actions/globalAlert';
import { getRefreshToken } from 'src/helpers/http';
import sessionEmitter from 'src/helpers/sessionEmitter';
import { Auth } from 'src/typescript/interfaces/Auth';
import { CurrentUser } from 'src/typescript/interfaces/CurrentUser';

interface HandleError {
  auth: Auth;
  currentUser: CurrentUser;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>;
  error: AxiosError;
  method: Method;
  queryClient: QueryClient;
}

export function handleError({
  currentUser,
  error,
  method,
  queryClient,
  auth,
  dispatch
}: HandleError) {
  const { response = { status: undefined } } = error;
  const isAuth0User = currentUser?.auth_migrated;

  if (isAuth0User && response.status === 401) {
    void queryClient.invalidateQueries({ refetchActive: false, refetchInactive: false });

    sessionEmitter.emitRefresh({
      redispatchAfterRefresh: async () => await queryClient.refetchQueries()
    });

    return;
  }

  if (response.status === 401 && auth.refreshToken) {
    // Invalidate any in-progress queries
    void queryClient.invalidateQueries({ refetchActive: false, refetchInactive: false });

    return (
      getRefreshToken(auth.refreshToken)
        // dispatch a refresh action to save new token results in cookie and store
        .then(({ data }: { data: { access_token: string } }) =>
          dispatch(refresh(data.access_token, auth.refreshToken))
        )

        // dispatch the original action again, now that we have a new token ...
        // if anything in this refresh flow blew up, log out
        .then(
          // refresh token request succeeded
          async () => {
            // Refetch queries when the refresh token is successful
            // See: https://react-query.tanstack.com/docs/api/#queryClientrefetchqueries
            return await queryClient.refetchQueries();
          },
          // refresh token request failed
          (err) => {
            sessionEmitter.emitLogout();
            throw err;
          }
        )
    );
  }

  if (response.status === 401) {
    sessionEmitter.emitLogout();
  }

  if (response.status === 403) {
    return dispatch(fetchAccount());
  }

  // No messaging necessary for 404 errors for "GET" requests
  if (response.status === 404 && method === 'GET') {
    return;
  }

  // This error handling could be handled on the server, however, the effort required to do so was significantly larger than handling it here
  if (response.status === 413) {
    return dispatch(
      showAlert({
        type: 'error',
        message: 'Something went wrong.',
        details:
          'The size of the CSV file converted into JSON exceeds the limit of 10 MB of the API.'
      })
    );
  }

  return dispatch(
    showAlert({
      type: 'error',
      message: 'Something went wrong.',
      details: error.message
    })
  );
}
