import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import * as supportActions from 'src/actions/support';
import config from 'src/appConfig';
import { ButtonWrapper, SelectWrapper, TextFieldWrapper } from 'src/components';
import { PageLink } from 'src/components/links';
import { Button, Panel, Stack } from 'src/components/matchbox';
import FileFieldWrapper from 'src/components/reduxFormWrappers/FileFieldWrapper';
import { Heading } from 'src/components/text';
import { Form } from 'src/components/tracking';
import { isAws } from 'src/helpers/conditions/account';
import { maxFileSize, required } from 'src/helpers/validation';
import { hasOnlineSupport } from 'src/selectors/accountBillingInfo';
import {
  notAuthorizedToSubmitSupportTickets,
  selectSupportIssue,
  selectSupportIssues
} from 'src/selectors/support';
import styles from '../Support.module.scss';
import NoIssues from './NoIssues';
import * as mime from 'mime-types';

const PRESIGNED_UPLOAD_URL = 'https://dotcom.bird.com/support/cases/presigned-upload';

export class SupportForm extends Component {
  onSubmit = async ({ attachment, issueId, message }) => {
    const issue = _.find(this.props.issues, { id: issueId });
    let ticket = {
      issueType: issue.type,
      message,
      subject: issue.label,
      username: this.props.username,
      accountId: this.props.accountId,
      account: this.props.account,
      email: this.props.userEmail
    };

    if (attachment) {
      try {
        // Performing presigned upload
        const contentType = mime.contentType(attachment.name);
        const presignedResult = await fetch(PRESIGNED_UPLOAD_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authority: 'dotcom.messagebird.com',
            'sec-fetch-mode': 'cors',
            'sec-fetch-site': 'cross-site'
          },
          body: JSON.stringify({
            contentType
          })
        });

        const { mediaUrl, uploadUrl, uploadMethod, uploadFormData } = await presignedResult.json();

        // Uploading file
        const formData = new FormData();

        for (const [key, value] of Object.entries(uploadFormData)) {
          formData.append(key, value);
        }

        formData.append('file', attachment, attachment.name);

        await fetch(uploadUrl, {
          method: uploadMethod,
          body: formData
        });

        ticket = {
          ...ticket,
          attachment: {
            filename: attachment.name,
            url: mediaUrl,
            contentType
          }
        };
      } catch (e) {
        // Attachment upload failed, proceeding with ticket creation
      }
    }

    this.props.onClose();
    this.props.createTicket(ticket);
  };

  renderSuccess() {
    const { onClose, ticketId } = this.props;

    return (
      <div className={styles.SupportContainer}>
        <Stack>
          <Heading as="h6">Your Ticket Has Been Submitted</Heading>

          <p>{`Ticket #${ticketId}`}</p>

          <p>Please check your email for updates on your support ticket.</p>

          <div>
            <Button variant="primary" onClick={onClose}>
              Continue
            </Button>
          </div>
        </Stack>
      </div>
    );
  }

  reset(parentReset) {
    this.props.reset(formName);
    return parentReset();
  }

  renderForm() {
    const {
      handleSubmit,
      invalid,
      issues,
      needsOnlineSupport,
      onClose,
      pristine,
      selectedIssue,
      submitting
    } = this.props;
    const submitText = submitting ? 'Submitting' : 'Submit Ticket';

    return (
      <Form onSubmit={handleSubmit(this.onSubmit)} id="support-form">
        <Panel.LEGACY.Section>
          <Stack>
            <Field
              name="issueId"
              label="I need help with..."
              helpText={
                needsOnlineSupport && (
                  <Fragment>
                    Additional technical support is available on paid plans.{' '}
                    <PageLink onClick={onClose} to="/account/billing/plan">
                      Upgrade now
                    </PageLink>
                    .
                  </Fragment>
                )
              }
              errorInLabel
              disabled={submitting}
              data-track={true}
              component={SelectWrapper}
              options={[
                { disabled: true, label: 'Select an option', value: '' },
                ...issues.map(({ id, label }) => ({ label, value: id }))
              ]}
              validate={required}
            />
            <Field
              name="message"
              label={selectedIssue ? selectedIssue.messageLabel : 'Tell us more about your issue'}
              errorInLabel
              multiline
              resize="none"
              rows={10}
              disabled={submitting}
              component={TextFieldWrapper}
              data-track={true}
              validate={required}
            />
            <Field
              type="file"
              name="attachment"
              label="Attach a file"
              disabled={submitting}
              component={FileFieldWrapper}
              validate={maxFileSize(config.support.maxAttachmentSizeBytes)}
            />
          </Stack>
        </Panel.LEGACY.Section>
        <Panel.LEGACY.Section>
          <ButtonWrapper>
            <Button variant="primary" submit disabled={pristine || invalid || submitting}>
              <span>{submitText}</span>
            </Button>

            <Button variant="secondary" disabled={submitting} onClick={() => onClose()}>
              Cancel
            </Button>
          </ButtonWrapper>
        </Panel.LEGACY.Section>
      </Form>
    );
  }

  render() {
    const { notAuthorizedToSubmitSupportTickets, openSupportPanel, submitSucceeded } = this.props;

    if (notAuthorizedToSubmitSupportTickets) {
      return <NoIssues onCancel={openSupportPanel} />;
    }

    if (submitSucceeded) {
      return this.renderSuccess();
    }

    return this.renderForm();
  }
}

export const formName = 'support-form';
const selector = formValueSelector(formName);
const mapStateToProps = (state) => ({
  issues: selectSupportIssues(state),
  needsOnlineSupport: !hasOnlineSupport(state) && !isAws(state), //AWS users can no longer use billing page. Remove on deprecating AWS accounts
  notAuthorizedToSubmitSupportTickets: notAuthorizedToSubmitSupportTickets(state),
  selectedIssue: selectSupportIssue(state, selector(state, 'issueId')),
  ticketId: state.support.ticketId,
  accountId: state.currentUser.customer,
  username: state.currentUser.username,
  account: state.account.company_name,
  userEmail: state.currentUser.email
});

const ReduxSupportForm = reduxForm({ form: formName })(SupportForm);
export default connect(mapStateToProps, supportActions)(ReduxSupportForm);
