import React, { useEffect } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { ApiErrorBanner, Empty, PanelLoading, TableCollection, Unit } from 'src/components';
import EmptyCell from 'src/components/collection/EmptyCell';
import { Box, Column, Columns, Panel, Table, Tag, Checkbox } from 'src/components/matchbox';
import MultiCheckboxDropdown from 'src/components/MultiCheckboxDropdown';
import { toFilterTypeLabel } from 'src/pages/analyticsReport/helpers';
import { hasSubaccounts as hasSubaccountsSelector } from 'src/selectors/subaccounts';
import { GROUP_BY_CONFIG } from '../../constants';
import { useAnalyticsReportContext } from '../../context/AnalyticsReportContext';
import { PageLink } from 'src/components/links';
import CheckboxWithLink from '../CheckboxWithLink';
import GroupByOption from './GroupByOption';
// eslint-disable-next-line local/no-css-module-import
import styles from './ReportTable.module.scss';
import { useCompareByGroupByTable } from './useGroupByTable';
import { SendingMetricsRemovalModal } from './SendingMetricsRemovalModal';
import { shrinkToFit } from 'src/helpers/string';
import { getDetailsPageUrl } from 'src/appConfig/routes';
import { TranslatableText } from 'src/components/text';
import { sortMissingToEnd } from 'src/helpers/array';
import { useGroupByTableCheckboxes } from './useGroupByTableCheckboxes';
import { checkSendingMetricsRemovalFilters } from './helpers/checkSendingMetricsRemovalFilters';

const PrimaryColumnText = styled(Box)`
  white-space: nowrap;
`;

const tableWrapper = (props) => {
  return (
    <Panel mb="500">
      <Table freezeFirstColumn>{props.children}</Table>
    </Panel>
  );
};

export const CompareByTable = () => {
  const {
    data,
    statuses = [],
    setGroupBy,
    groupBy,
    comparisonType,
    refetchAll,
    checkboxes,
    resetCheckboxes,
    apiMetrics,
    hasSendingMetrics,
    hasInboxTrackingMetrics,
    hasSendingProduct,
    hasD12yProduct
  } = useCompareByGroupByTable();
  const {
    state: reportOptions,
    selectors: { selectSummaryMetricsProcessed: metrics },
    actions: { addFilters }
  } = useAnalyticsReportContext();

  const { onFilterClick, metricsToRemove, modal, ...checkboxItemsController } =
    useGroupByTableCheckboxes({
      hideCompareButton: true
    });
  const { isModalOpen, meta, closeModal } = modal;

  const hasSubaccounts = useSelector(hasSubaccountsSelector);
  const subaccounts = useSelector((state) => state.subaccounts.list);
  const group = GROUP_BY_CONFIG[groupBy];

  useEffect(() => {
    if (groupBy === 'subject-campaign') resetCheckboxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupBy]); // only need to react to groupByChanges

  const tableData = _.flatten(data);

  const getColumnHeaders = () => {
    const primaryCol = {
      key: 'group-by',
      label: group.label,
      className: cx(styles.HeaderCell, styles.FirstColumnHeader),
      sortKey: group.keyName
    };

    const comparisonCol = {
      key: comparisonType,
      label: toFilterTypeLabel(comparisonType),
      className: cx(styles.HeaderCell, styles.FirstColumnHeader),
      sortKey: comparisonType
    };

    const metricCols = metrics.map(({ label, key }) => ({
      key,
      label: <Box textAlign="right">{label}</Box>,
      className: cx(styles.HeaderCell, styles.NumericalHeader),
      align: 'right',
      sortKey: key
    }));

    return [primaryCol, comparisonCol, ...metricCols];
  };

  const getSubaccountFilter = (subaccountId) => {
    if (subaccountId === 0) {
      return { type: 'Subaccount', value: 'Primary Account (ID 0)', id: 0 };
    } else if (subaccountId === -1) {
      return { type: 'Subaccount', value: 'Unattributed (External Sends)', id: -1 };
    }

    const subaccount = subaccounts.find(({ id }) => {
      return id === subaccountId;
    });

    const value = subaccount
      ? `${subaccount?.name} (ID ${subaccount?.id})`
      : `Subaccount ${subaccountId}`;
    return { type: 'Subaccount', value, id: subaccountId };
  };

  const getRowData = (row) => {
    const primaryColumnKey = row[group.keyName];
    const isUnattributed = primaryColumnKey === -1;
    const newFilter =
      group.label === 'Subaccount'
        ? getSubaccountFilter(primaryColumnKey)
        : { type: group.label, value: primaryColumnKey };

    const Link = ({ children }) => {
      if (!isUnattributed) {
        const params = {
          from: reportOptions.from.toISOString(),
          to: reportOptions.to.toISOString()
        };
        return (
          <PageLink
            to={getDetailsPageUrl(group.keyName, primaryColumnKey, params)}
            aria-label={`${group.label} ${children}`}
          >
            <TranslatableText>{children}</TranslatableText>
          </PageLink>
        );
      }

      return <TranslatableText>{children}</TranslatableText>;
    };

    const checkBoxId = `checkbox-${newFilter.value}-${groupBy}`;

    const onCheckboxChange = () => {
      checkboxItemsController.toggle({
        id: checkBoxId,
        filter: newFilter
      });
    };

    const primaryCol = (
      <Box width="100%" maxWidth="350px" display="flex">
        <Checkbox
          onClick={onCheckboxChange}
          defaultChecked={checkboxItemsController.hasChecked(checkBoxId)}
          id={checkBoxId}
          key={checkBoxId}
        />

        <SendingMetricsRemovalModal
          isModalOpen={isModalOpen && checkSendingMetricsRemovalFilters(meta, newFilter.value)}
          addFilter={addFilters}
          filter={meta}
          onClose={closeModal}
          metricsToRemove={metricsToRemove}
        />
        <Box display="flex" ml="400" alignItems="center">
          <PrimaryColumnText title={newFilter.value}>
            <Link>{shrinkToFit(newFilter.value, 35)}</Link>
          </PrimaryColumnText>
        </Box>
      </Box>
    );

    const comparisonCol = <Tag>{row[comparisonType]}</Tag>;
    const metricCols = metrics.map(({ key, unit }) => (
      <Box textAlign="right" key={key}>
        {row[key] !== undefined && row[key] !== null ? (
          <Unit value={row[key]} unit={unit} />
        ) : (
          <EmptyCell />
        )}
      </Box>
    ));

    return [primaryCol, comparisonCol, ...metricCols];
  };

  const checkboxComponent = React.useMemo(
    () =>
      CheckboxWithLink({
        hasSendingProduct,
        hasD12yProduct,
        hasSendingMetrics,
        hasInboxTrackingMetrics,
        isCampaignSubjectGroupBy: groupBy === 'subject-campaign'
      }),
    [groupBy, hasSendingMetrics, hasSendingProduct, hasD12yProduct, hasInboxTrackingMetrics]
  );

  return (
    <>
      <Panel marginBottom="-1px">
        <Panel.Section>
          <Columns collapseBelow="sm">
            <GroupByOption
              disabled={statuses.includes('loading') || metrics.length === 0}
              groupBy={groupBy}
              hasSubaccounts={hasSubaccounts}
              onChange={setGroupBy}
            />

            {groupBy && (
              <Column>
                <MultiCheckboxDropdown
                  allowEmpty={false}
                  checkboxes={checkboxes}
                  id="group-by-dropdown"
                  label="Data Sources"
                  screenReaderDirections="Filter the table by the selected checkboxes"
                  checkboxComponent={checkboxComponent}
                />
              </Column>
            )}
          </Columns>
        </Panel.Section>

        <checkboxItemsController.Header />
      </Panel>

      <div data-id="breakdown-by-table-with-comparisons">
        <TableContent
          group={group}
          metrics={metrics}
          statuses={statuses}
          refetchAll={refetchAll}
          tableData={tableData}
          apiMetrics={apiMetrics}
          getColumnHeaders={getColumnHeaders}
          getRowData={getRowData}
        />
      </div>
    </>
  );
};

const TableContent = ({
  group,
  metrics,
  statuses,
  refetchAll,
  tableData,
  apiMetrics,
  getColumnHeaders,
  getRowData
}) => {
  if (!group || metrics.length === 0) {
    return null;
  }

  if (statuses.includes('error')) {
    return (
      <Panel>
        <Panel.Section>
          <ApiErrorBanner
            reload={refetchAll}
            status="muted"
            title="Unable to load report"
            message="Please try again"
          />
        </Panel.Section>
      </Panel>
    );
  }

  if (statuses.includes('loading')) {
    return <PanelLoading minHeight="250px" />;
  }

  if (!Boolean(tableData.length) || !Boolean(apiMetrics.length)) {
    return (
      <Panel>
        <Empty message="No results found." />
      </Panel>
    );
  }

  return (
    <TableCollection
      rowKeyName={group.keyName}
      columns={getColumnHeaders()}
      // eslint-disable-next-line react/jsx-no-bind
      getRowData={getRowData}
      pagination
      defaultPerPage={10}
      rows={tableData}
      defaultSortColumn="group-by"
      defaultSortDirection="desc"
      wrapperComponent={tableWrapper}
      sortFn={sortMissingToEnd}
    />
  );
};

export default CompareByTable;
