import { Text as MatchboxText } from '@sparkpost/matchbox';

/**
 * Matchbox Text component.
 * @see https://design.sparkpost.com/components/text/
 */
const Text = MatchboxText;

Text.displayName = 'Text';

export default Text;
