import React from 'react';
import { ContentCopy } from '@sparkpost/matchbox-icons';
import { ButtonLink } from 'src/components/links';

const DuplicateTemplate = (props) => {
  const { className, onClick } = props;

  return (
    <div className={className}>
      <ButtonLink onClick={onClick} data-id="action-duplicate">
        <ContentCopy />

        <span>Duplicate</span>
      </ButtonLink>
    </div>
  );
};

export default DuplicateTemplate;
