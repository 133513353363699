import React from 'react';
import { Button, Modal } from 'src/components/matchbox';
import { setAccountOption } from 'src/helpers/api/account';
import { useAlert, useSparkPostMutation } from 'src/hooks';

export default function BlockAutomaticInlineSeedingModal({ open, onClose, onSuccess }) {
  const { showAlert } = useAlert();
  const mutation = useSparkPostMutation(
    () => {
      return setAccountOption({ option: 'inline_seeds', value: false });
    },
    { onSuccess: handleSuccess }
  );

  function handleSuccess() {
    onClose();
    onSuccess();
    showAlert({ type: 'success', message: 'Account Updated' });
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header showCloseButton>
        Block Automatic Inline Seeding Across All Accounts
      </Modal.Header>

      <Modal.Content>
        Block Automatic Inline Seeding for Primary and all Subaccounts. This will block all pending
        and future Automatic Seeding from occurring until permitted.
      </Modal.Content>

      <Modal.Footer>
        <Button
          color="red"
          useMatchboxVariant
          loading={mutation.status === 'loading'}
          onClick={mutation.mutate}
        >
          Block Seeding
        </Button>

        <Button
          color="gray"
          outlineBorder
          useMatchboxVariant
          disabled={mutation.status === 'loading'}
          onClick={onClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
