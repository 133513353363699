import { Search } from '@sparkpost/matchbox-icons';
import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from '@tanstack/react-table';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Banner, Box, Button, Panel, Table, TextField } from 'src/components/matchbox';
import { Form } from 'src/components/tracking';
import { onEnter } from 'src/helpers/keyEvents';

import { Redirect, useLocation } from 'react-router-dom';
import { setSubaccount } from 'src/actions/accessControl';
import styled from 'styled-components';
import Loading from '../loading';

const CustomRow = styled(Box)<{ selected: boolean }>`
  background: ${(props) =>
    props.selected ? props.theme.colors.blue['300'] : 'initial'} !important;
  &:hover {
    background: ${(props) =>
      props.selected ? props.theme.colors.blue['300'] : props.theme.colors.blue['200']} !important;
  }
  cursor: pointer;
`;

const CustomPanelContainer = styled(Panel.Section)`
  height: 300px;
  overflow-y: auto;
`;

type Subaccount = {
  access_level: string;
  status: string;
  subaccount_id: number;
  subaccount_name: string;
};

export function SubaccountSelection(): React.ReactNode {
  const { status, subaccounts, showSubaccountSelect } = useSelector(
    (state: $TODOFIXME) => state.currentUser
  ) as { showSubaccountSelect: boolean; status: string; subaccounts: Subaccount[] };

  const nonTerminatedSubaccounts = subaccounts.filter(({ status }) => status !== 'terminated');

  const columns = React.useMemo<ColumnDef<Subaccount>[]>(
    () => [
      {
        accessorKey: 'subaccount_name',
        header: 'Name'
      },
      {
        accessorKey: 'subaccount_id',
        header: 'ID'
      }
    ],
    []
  );

  //TODO: Get rid of this once we bump the version of react-table.
  // https://github.com/TanStack/table/issues/4210
  const globalFilterFn: FilterFn<$TODOFIXME> = (row, columnId, filter) => {
    const val = row.getValue(columnId);
    return String(val).toLowerCase().includes(filter.toLowerCase());
  };

  const dispatch = useDispatch();
  const { state } = useLocation();

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [error, setError] = React.useState('');

  const { getHeaderGroups, getRowModel } = useReactTable({
    data: nonTerminatedSubaccounts,
    columns,
    state: {
      sorting,
      globalFilter
    },
    globalFilterFn,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  });

  const [selectedSub, setSelectedSub] = React.useState('');

  const handleSubmit = (e: $TODOFIXME) => {
    e.preventDefault();
    if (!selectedSub) {
      setError('Please select a subaccount');
    } else {
      const { access_level, subaccount_id } =
        subaccounts.find(({ subaccount_id }) => selectedSub === String(subaccount_id)) || {};
      localStorage.setItem('currentSubId', selectedSub);
      dispatch(setSubaccount(subaccount_id, access_level));
    }
  };

  if (status !== 'success') {
    return <Loading />;
  }

  if (!showSubaccountSelect) {
    return <Redirect to="/landing" />;
  }

  const rows = getRowModel().rows;

  if (nonTerminatedSubaccounts.length === 0) {
    return (
      <Banner status="danger" title="All Subaccounts Terminated">
        All accessible subaccounts are terminated. Please contact your account administrator.
      </Banner>
    );
  }

  return (
    <Box>
      <Form onSubmit={handleSubmit} id="subaccount-selection">
        <Panel>
          <Panel.Header>Select Subaccount</Panel.Header>
          <Panel.Section>
            <TextField
              id="subaccount-table-search"
              prefix={<Search />}
              label="Search Subaccount"
              value={globalFilter ?? ''}
              onChange={(e: $TODOFIXME) => setGlobalFilter(e.currentTarget.value)}
              placeholder="e.g. Subaccount XYZ"
            />
          </Panel.Section>
          <CustomPanelContainer p="0">
            <Table>
              <thead>
                {getHeaderGroups().map((headerGroup) => {
                  return (
                    <Table.Row key={headerGroup.id}>
                      {headerGroup.headers.map((column) => {
                        return (
                          <Table.HeaderCell key={column.id}>
                            <Table.SortButton
                              direction={column.column.getIsSorted() || undefined}
                              onClick={column.column.getToggleSortingHandler()}
                            >
                              {/* eslint-disable-next-line local/restrict-translatable-text */}
                              {flexRender(column.column.columnDef.header, column.getContext())}
                            </Table.SortButton>
                          </Table.HeaderCell>
                        );
                      })}
                    </Table.Row>
                  );
                })}
              </thead>
              {rows.length > 0 && (
                <tbody>
                  {getRowModel().rows.map((row) => {
                    return (
                      <CustomRow
                        as="tr"
                        tabIndex={0}
                        role="menuitem"
                        key={row.id}
                        onClick={() => {
                          setSelectedSub(String(row.getValue('subaccount_id')));
                        }}
                        onKeyPress={onEnter(() => {
                          setSelectedSub(String(row.getValue('subaccount_id')));
                        })}
                        selected={String(row.getValue('subaccount_id')) === String(selectedSub)}
                      >
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <Table.Cell key={cell.id} py="200">
                              {/* eslint-disable-next-line local/restrict-translatable-text */}
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </Table.Cell>
                          );
                        })}
                      </CustomRow>
                    );
                  })}
                </tbody>
              )}
            </Table>
            {rows.length <= 0 && (
              <Box backgroundColor="gray.200" height="233px">
                <Box
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  paddingX="800"
                  paddingY="500"
                  fontSize="400"
                  size="100%"
                  fontWeight="medium"
                >
                  No Results Found
                </Box>
              </Box>
            )}
          </CustomPanelContainer>
          <Panel.Section>
            <Button variant="primary" useMatchboxVariant={false} type="submit">
              Open Subaccount
            </Button>
          </Panel.Section>
        </Panel>
      </Form>
    </Box>
  );
}
