import React, { useCallback, useState } from 'react';

import { tokens } from '@sparkpost/design-tokens';
import { RadioButtonGroup } from 'src/components';
import {
  Banner,
  Box,
  Checkbox,
  Inline,
  Radio,
  Stack,
  Tag,
  Text,
  TextField
} from 'src/components/matchbox';
import { Heading, TranslatableText, Uppercase } from 'src/components/text';
import { TimezoneTypeahead } from 'src/components/typeahead';
import { isEmailAddress } from 'src/helpers/email';
import { DAY_OF_WEEK_OPTIONS } from 'src/pages/analyticsReport/constants/scheduledReports';
import { useAlertForm } from '../useAlertForm';

export default function Notifications() {
  const [currentEmail, setCurrentEmail] = useState('');
  const {
    state: {
      notificationData: { time, ...notificationData }
    },
    dispatch
  } = useAlertForm();

  const handleChange = useCallback(
    (e) => {
      const type = e.target.id;
      const checked = e.target.checked;

      switch (type) {
        case 'email':
          return dispatch({ type: 'UPDATE_NOTIFICATION_DATA', data: { isEmailChecked: checked } });

        case 'slack':
          return dispatch({ type: 'UPDATE_NOTIFICATION_DATA', data: { isSlackChecked: checked } });

        case 'webhook':
          return dispatch({
            type: 'UPDATE_NOTIFICATION_DATA',
            data: { isWebhookChecked: checked }
          });

        default:
          break;
      }
    },
    [dispatch]
  );

  const emailAlreadyExists = useCallback(
    (newEmail) => {
      return notificationData.emails?.some((existentEmail) => existentEmail === newEmail);
    },
    [notificationData.emails]
  );

  const validateAndUpdateEmailNotificationData = useCallback(() => {
    if (!isEmailAddress(currentEmail)) return;
    if (emailAlreadyExists(currentEmail)) return;

    dispatch({
      type: 'UPDATE_NOTIFICATION_DATA',
      data: { emails: [...notificationData.emails, currentEmail] }
    });
    setCurrentEmail('');
  }, [currentEmail, dispatch, emailAlreadyExists, notificationData.emails]);

  const handleAddEmail = useCallback(
    (e) => {
      const isComma = e.target.value.slice(-1) === ',';

      if (!isComma) {
        return setCurrentEmail(e.target.value);
      }
      validateAndUpdateEmailNotificationData();
    },
    [validateAndUpdateEmailNotificationData]
  );

  const handleOnEnterIsPressedInEmailField = useCallback(
    (e) => {
      if (e.key !== 'Enter') return;
      validateAndUpdateEmailNotificationData();
    },
    [validateAndUpdateEmailNotificationData]
  );

  const handleRemoveEmail = useCallback(
    (emailToRemove) => {
      dispatch({
        type: 'UPDATE_NOTIFICATION_DATA',
        data: { emails: notificationData.emails.filter((email) => emailToRemove !== email) }
      });
    },
    [dispatch, notificationData.emails]
  );

  const handleChangeSlack = useCallback(
    (e) => {
      dispatch({ type: 'UPDATE_NOTIFICATION_DATA', data: { slack: e.target.value } });
    },
    [dispatch]
  );

  const handleChangeWebhook = useCallback(
    (e) => {
      dispatch({ type: 'UPDATE_NOTIFICATION_DATA', data: { webhook: e.target.value } });
    },
    [dispatch]
  );

  const handleChangeTime = useCallback(
    (e) => {
      dispatch({ type: 'UPDATE_NOTIFICATION_TIME', data: { range: e.target.id } });
    },
    [dispatch]
  );

  const handleChangeDays = useCallback(
    (e) => {
      const { checked, id } = e.target;

      if (checked) {
        return dispatch({ type: 'UPDATE_NOTIFICATION_TIME', data: { days: [...time.days, id] } });
      }

      return dispatch({
        type: 'UPDATE_NOTIFICATION_TIME',
        data: { days: time.days.filter((day) => day !== id) }
      });
    },
    [dispatch, time.days]
  );

  const handleChangeTimeFrom = useCallback(
    (e) => {
      dispatch({ type: 'UPDATE_NOTIFICATION_TIME', data: { startTime: e.target.value } });
    },
    [dispatch]
  );

  const handleChangePeriodFrom = useCallback(
    (value) => {
      dispatch({ type: 'UPDATE_NOTIFICATION_TIME', data: { startPeriod: value } });
    },
    [dispatch]
  );

  const handleChangeTimeTo = useCallback(
    (e) => {
      dispatch({ type: 'UPDATE_NOTIFICATION_TIME', data: { endTime: e.target.value } });
    },
    [dispatch]
  );

  const handleChangePeriodTo = useCallback(
    (value) => {
      dispatch({ type: 'UPDATE_NOTIFICATION_TIME', data: { endPeriod: value } });
    },
    [dispatch]
  );

  const handleChangeTimezone = useCallback(
    (timezone) => {
      dispatch({ type: 'UPDATE_NOTIFICATION_TIME', data: { timeZone: timezone.value } });
    },
    [dispatch]
  );

  return (
    <Stack>
      <Heading as="h4">Notifications</Heading>
      <Box>
        <Checkbox.Group
          label="Events"
          description="A minimum of 1 notification channel must be selected."
        >
          {notificationData.errors.targets && (
            <Box maxWidth="370px">
              <Banner status="danger">
                <TranslatableText>Select a minimum of 1 notification channel.</TranslatableText>
              </Banner>
            </Box>
          )}
          <Checkbox
            id="email"
            label="Email"
            onChange={handleChange}
            defaultChecked={notificationData.isEmailChecked}
          />
          {notificationData.isEmailChecked && (
            <Box ml="450" mt="200" mb="200" maxWidth="566px">
              <TextField
                id="email-input"
                label="Email Address(es)"
                placeholder="e.g. johndoe@example.com"
                required
                onChange={handleAddEmail}
                onKeyDown={handleOnEnterIsPressedInEmailField}
                helpText="Use commas to separate multiple email addresses. A maximum of 10 email addresses can
                  be added."
                value={currentEmail}
                error={notificationData.errors.emails && 'Required'}
              />
              <Box mt="200">
                {notificationData.emails?.map((email, index) => (
                  <Tag
                    key={`tag-email-${email}-${index}`}
                    onRemove={() => handleRemoveEmail(email)}
                  >
                    <TranslatableText>{email}</TranslatableText>
                  </Tag>
                ))}
              </Box>
            </Box>
          )}
          <Checkbox
            id="slack"
            label="Slack"
            onChange={handleChange}
            defaultChecked={notificationData.isSlackChecked}
          />
          {notificationData.isSlackChecked && (
            <Box ml="450" mt="200" mb="200" maxWidth="566px">
              <TextField
                label="Slack Channel Webhook URL"
                placeholder="e.g. http://hooks.slack.com/services/T00/B00XX"
                required
                value={notificationData.slack}
                onChange={handleChangeSlack}
                helpText="Only 1 Slack channel can be added."
                error={notificationData.errors.slack && 'Required'}
              />
            </Box>
          )}
          <Checkbox
            id="webhook"
            label="Webhook"
            onChange={handleChange}
            defaultChecked={notificationData.isWebhookChecked}
          />
          {notificationData.isWebhookChecked && (
            <Box ml="450" mt="200" mb="200" maxWidth="566px">
              <TextField
                label="Webhook URL"
                placeholder="e.g. http://example.com/webhook-target"
                required
                value={notificationData.webhook}
                onChange={handleChangeWebhook}
                helpText="Only 1 webhook can be added."
                error={notificationData.errors.webhook && 'Required'}
              />
            </Box>
          )}
        </Checkbox.Group>
      </Box>
      <Box>
        <Radio.Group label="Notification Time Range">
          <Radio
            id="anytime"
            label="The alert is triggered if the conditions are met at any day and time."
            name="group"
            defaultChecked={time.range === 'anytime'}
            onChange={handleChangeTime}
          />
          <Radio
            id="defined"
            label="The alert is triggered if the conditions are met during a defined time range."
            name="group"
            defaultChecked={time.range === 'defined'}
            onChange={handleChangeTime}
          />
        </Radio.Group>
        {time.range === 'defined' && (
          <Box mt="300">
            <Radio.Group label="Day(s)">
              <Inline>
                {DAY_OF_WEEK_OPTIONS.map((day) => (
                  <Checkbox
                    id={day.label}
                    label={day.label}
                    key={`day-${day.value}`}
                    onChange={handleChangeDays}
                    defaultChecked={time.days.includes(day.label)}
                  />
                ))}
              </Inline>
            </Radio.Group>
            <Box mt="300">
              <Inline>
                <TextField
                  label="Timeframe"
                  name="from"
                  id="from"
                  data-track={true}
                  maxWidth="12rem"
                  placeholder="hh:mm"
                  error={time.errors.startTime && 'Required'}
                  onChange={handleChangeTimeFrom}
                  value={time.startTime}
                  connectRight={
                    <RadioButtonGroup id="period" label="Grouping Type">
                      <RadioButtonGroup.Button
                        id="am"
                        name="period"
                        checked={time.startPeriod === 'AM'}
                        onChange={handleChangePeriodFrom}
                        value="AM"
                        data-track={true}
                      >
                        <Uppercase>AM</Uppercase>
                      </RadioButtonGroup.Button>
                      <RadioButtonGroup.Button
                        id="pm"
                        name="period"
                        checked={time.startPeriod === 'PM'}
                        onChange={handleChangePeriodFrom}
                        value="PM"
                        data-track={true}
                      >
                        <Uppercase>PM</Uppercase>
                      </RadioButtonGroup.Button>
                    </RadioButtonGroup>
                  }
                />
                <Box mt="450">
                  <Text>to</Text>
                </Box>
                <TextField
                  label=" "
                  name="to"
                  id="to"
                  data-track={true}
                  maxWidth="12rem"
                  placeholder="hh:mm"
                  error={time.errors.endTime && 'Required'}
                  onChange={handleChangeTimeTo}
                  value={time.endTime}
                  connectRight={
                    <RadioButtonGroup id="period" label="Grouping Type">
                      <RadioButtonGroup.Button
                        id="am"
                        name="period"
                        checked={time.endPeriod === 'AM'}
                        onChange={handleChangePeriodTo}
                        value="AM"
                        data-track={true}
                      >
                        <Uppercase>AM</Uppercase>
                      </RadioButtonGroup.Button>
                      <RadioButtonGroup.Button
                        id="pm"
                        name="period"
                        checked={time.endPeriod === 'PM'}
                        onChange={handleChangePeriodTo}
                        value="PM"
                        data-track={true}
                      >
                        <Uppercase>PM</Uppercase>
                      </RadioButtonGroup.Button>
                    </RadioButtonGroup>
                  }
                />
                <Box width={tokens.sizing_1000} maxWidth="297px">
                  <TimezoneTypeahead
                    initialValue={time.timeZone}
                    data-track={true}
                    onChange={handleChangeTimezone}
                    error={time.errors.timeZone && 'Required'}
                  />
                </Box>
              </Inline>
            </Box>
          </Box>
        )}
      </Box>
    </Stack>
  );
}
