/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Box, QueryCard, Stack, Text } from 'src/components/matchbox';
import { toActiveFilterTagGroups, toIterableGroupings } from 'src/pages/analyticsReport/helpers';

type ActiveFiltersProps = {
  filters: $TODOFIXME[];
  handleFilterRemove?: (filter: $TODOFIXME) => void;
};

const NEW_FILTERS_COMPARE_LABELS = {
  'is equal to': 'equals'
};

export default function ActiveFilters({
  filters,
  handleFilterRemove
}: ActiveFiltersProps): JSX.Element {
  const iterableGroupings = toIterableGroupings(filters);
  const groupings = toActiveFilterTagGroups(iterableGroupings);

  return (
    <div data-id="active-filter-tags">
      {groupings.map((grouping, groupingIndex) => {
        return (
          <Box data-id="active-filter-group" key={`active-filter-tag-${groupingIndex}`}>
            <Stack space="200">
              {groupingIndex > 0 && (
                <Box my="200">
                  <Text fontSize="200">And</Text>
                </Box>
              )}
              <QueryCard.Group>
                {grouping.filters.map((filter, filterIndex) => {
                  return (
                    <Box key={`filter-${groupingIndex}-${filterIndex}`}>
                      {filter.values &&
                        filter.values.map((rawValue, valueIndex) => {
                          // Some values are objects when returned from the typeahead, others are just strings
                          const value = typeof rawValue === 'object' ? rawValue.value : rawValue;
                          // The remove method is not always present - conditionally rendering the remove button on the relevant `<Tag />`
                          const onRemoveFn = handleFilterRemove
                            ? (): void =>
                                handleFilterRemove({ groupingIndex, filterIndex, valueIndex })
                            : undefined;

                          return (
                            <Box
                              data-id={`filter-${groupingIndex}-${filterIndex}-${valueIndex}`}
                              key={`tag-${groupingIndex}-${filterIndex}-${valueIndex}`}
                            >
                              <QueryCard onClose={onRemoveFn}>
                                <QueryCard.Title>{filter.label}</QueryCard.Title>
                                <QueryCard.Operator>
                                  {NEW_FILTERS_COMPARE_LABELS[filter.compareBy] || filter.compareBy}
                                </QueryCard.Operator>
                                <QueryCard.Subtitle>{value}</QueryCard.Subtitle>
                              </QueryCard>
                            </Box>
                          );
                        })}
                    </Box>
                  );
                })}
              </QueryCard.Group>
            </Stack>
          </Box>
        );
      })}
    </div>
  );
}
