import React from 'react';
import { Error, Radio, Box, Columns, Column } from 'src/components/matchbox';

export default function RadioGroup({
  label,
  input,
  options,
  meta,
  bottomError,
  grid = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
  'data-track': dataTrack,
  display = 'grid'
}) {
  const { error, touched } = meta;

  const Wrapper = display === 'grid' ? Columns : Box;
  const ColumnWrapper = display === 'grid' ? Column : Box;

  return (
    <Radio.Group label={label}>
      {!bottomError && touched && error && <Error error={error} />}
      <Wrapper>
        {options.map((option) => (
          <Box as={ColumnWrapper} mb="100" {...grid} key={`${input.name}-${option.value}`}>
            <Radio
              {...input}
              id={`${input.name}-${option.value}`}
              label={option.label}
              checked={option.value === input.value}
              disabled={!!option.disabled}
              value={option.value}
              helpText={option.helpText}
              data-track={dataTrack}
            />
            {option.children}
          </Box>
        ))}
      </Wrapper>
      {bottomError && touched && error && <Error error={error} />}
    </Radio.Group>
  );
}
