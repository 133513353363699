import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'src/components/matchbox';

type ComparisonProps = {
  as?: $TODOFIXME;
  children: React.ReactNode;
};

export default function Emphasized(props: ComparisonProps) {
  const { children, as = 'span' } = props;

  return (
    <Text as={as} fontStyle="italic" data-id={props['data-id']}>
      {children}
    </Text>
  );
}

Emphasized.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]).isRequired,
  'data-id': PropTypes.string
};
