export const INCIDENT_COLUMNS = [
  {
    Header: 'Resource',
    accessor: 'resource'
  },
  {
    Header: 'Blocklist',
    accessor: 'blocklistName'
  },
  {
    Header: 'Assignment',
    accessor: 'assignment'
  },
  {
    Header: 'Date Listed',
    accessor: 'dateListed',
    sortType: 'datetime'
  },
  {
    Header: 'Date Resolved',
    accessor: 'dateResolved',
    sortType: 'datetime'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'Impact',
    accessor: 'impactScore',
    sortType: 'number'
  },
  {
    Header: 'Actions',
    accessor: 'id'
  }
];

export const INCIDENT_STATUSES = {
  flagged: 'Active',
  resolved: 'Resolved'
};

export const INCIDENT_STATUS_CHECKBOXES = [
  {
    label: 'Resolved',
    name: 'resolved'
  },
  {
    label: 'Active',
    name: 'active'
  }
];

export const SORT_BY_OPTIONS = [
  {
    value: 'dateListed.desc',
    label: 'Date Listed (Newest-Oldest)'
  },
  {
    value: 'dateListed.asc',
    label: 'Date Listed (Oldest-Newest)'
  },
  {
    value: 'dateResolved.desc',
    label: 'Date Resolved (Newest-Oldest)'
  },
  {
    value: 'dateResolved.asc',
    label: 'Date Resolved (Oldest-Newest)'
  },
  {
    value: 'blocklistName.asc',
    label: 'Blocklist Name (A-Z)'
  },
  {
    value: 'blocklistName.desc',
    label: 'Blocklist Name (Z-A)'
  },
  {
    value: 'impactScore.desc',
    label: 'Impact (High-Low)'
  },
  {
    value: 'impactScore.asc',
    label: 'Impact (Low-High)'
  }
];

export const DEFAULT_SORT_BY = [
  {
    id: 'dateResolved',
    desc: false
  }
];
