/* eslint-disable local/restrict-translatable-text */
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

// ICONS
import { ChevronRight, Search } from '@sparkpost/matchbox-icons';

// COMPONENTS
import { Empty } from 'src/components';
import { ScreenReaderExclude } from 'src/components/a11y';
import Pagination from 'src/components/collection/Pagination';
import { PageLink } from 'src/components/links';
import {
  Box,
  Button,
  Panel,
  ScreenReaderOnly,
  Stack,
  Table,
  Text,
  TextField
} from 'src/components/matchbox';
import { TranslatableText } from 'src/components/text';

// HOOKS
import { usePageFilters } from 'src/hooks';

// REDUCERS
import TableFilters, {
  reducer as tableFiltersReducer
} from 'src/pages/domains/components/TableFilters';

// STYLES
import {
  AlertSeverityTag,
  FiltersBar,
  Label,
  SeverityWrapper,
  StyledIcon,
  TableWrapper
} from './styles';

// HELPERS
import { formatLongDateTime } from '@sparkpost/report-builder/helpers/date';
import { getMetricsFromKeys } from '@sparkpost/report-builder/helpers/metrics';
import {
  generateAnalyticsMetricsURL,
  generateBlocklistURL,
  generateHealthScoreURL,
  generateUsageURL
} from '../../helpers/urlGenerator';
import { parseBlocklistEvaluation, parseHealthScoreEvaluation } from '../../helpers/evaluation';
import { getUnitFormatter } from '../../helpers/unit';

const filterOptions = [
  {
    label: 'Date Triggered (Newest - Oldest)',
    value: 'last_triggered_desc',
    id: 'last_triggered',
    desc: true
  },
  {
    label: 'Date Triggered (Oldest - Newest)',
    value: 'last_triggered_asc',
    id: 'last_triggered',
    desc: false
  },
  {
    label: 'Alert Name (A - Z)',
    value: 'name_asc',
    id: 'name',
    desc: false
  },
  {
    label: 'Alert Name (Z - A)',
    value: 'name_desc',
    id: 'name',
    desc: true
  }
];

function getColumns() {
  return [
    {
      label: 'Alert Name',
      sortKey: 'name',
      width: '35%',
      sortType: 'basic',
      filter: 'fuzzyText',
      screenReaderOnly: false
    },
    {
      label: 'Subaccount',
      sortKey: 'subaccount',
      screenReaderOnly: true
    },
    {
      label: 'Metric',
      sortKey: 'metric',
      screenReaderOnly: true
    },
    { label: 'Threshold Value', sortKey: 'threshold', sortType: 'basic', screenReaderOnly: false },
    {
      label: 'Severity',
      sortKey: 'severity',
      screenReaderOnly: false,
      filter(rows, columnIds, filterValue) {
        if (filterValue === '') return rows;

        const severityFilter = filterValue.split(',');

        return rows.filter((row) => severityFilter.includes(row.values.severity));
      }
    },
    {
      label: 'Last Triggered',
      sortKey: 'last_triggered',
      screenReaderOnly: false
    },
    {
      label: 'View in',
      sortKey: 'viewIn',
      disableSortBy: true,
      screenReaderOnly: false
    }
  ];
}

const pageFilters = {
  alert: {},
  severity: {},
  sortBy: {},
  tab: {}
};

const getCellData = (alert_type, incident) => {
  switch (alert_type) {
    case 'metrics_alert':
      const { metric } = incident.alert_configuration;
      const { label: metricLabel, unit: metricUnit } = getMetricsFromKeys([metric])[0];
      const formatMetricValue = getUnitFormatter(metricUnit);
      const metricConditionValue = formatMetricValue(incident.alert_configuration.conditions.value);
      const reportLink = generateAnalyticsMetricsURL({
        from: incident.beginning,
        to: incident.resolution,
        metric,
        filters: incident.filters,
        groupBy: incident.alert_configuration.group_by
      });
      return {
        label: metricLabel,
        conditionValue: metricConditionValue,
        linkLabel: 'View in Analytics',
        link: reportLink
      };
    case 'blocklist_alert':
      const { resource, provider } = parseBlocklistEvaluation(incident.evaluation.resource);
      const blockListIncidentsLink = generateBlocklistURL({ resource, provider });
      return {
        label: 'Blocklist',
        conditionValue: provider,
        linkLabel: 'View in Blocklist',
        link: blockListIncidentsLink
      };
    case 'sending_limit_alert':
      const usageLink = generateUsageURL();
      return {
        label: 'Account Usage',
        conditionValue: incident.alert_configuration.conditions.value,
        linkLabel: 'View in Usage',
        link: usageLink
      };
    case 'health_score_alert':
      const { resourceId, resourceValue, subaccount } = parseHealthScoreEvaluation(
        incident.evaluation.resource
      );
      const healthScoreLink = generateHealthScoreURL({ resourceId, resourceValue, subaccount });
      return {
        label: 'Health Score',
        conditionValue: incident.alert_configuration.conditions.value,
        linkLabel: 'View in Health Score',
        link: healthScoreLink
      };
    default:
      return { label: '', conditionValue: '', linkLabel: '', link: '' };
  }
};

const Cell = ({ cell }) => {
  const incident = cell.row.original;
  const subaccount = incident.subaccount;
  const alert_type = incident.alert_type;
  const { label, conditionValue, linkLabel, link } = getCellData(alert_type, incident);

  if (cell.column.sortKey === 'name')
    return (
      <Box minWidth="140px">
        <Stack space="0">
          <PageLink to={`/alerts/details/${incident.alert_id}`}>{incident.name}</PageLink>
          <ScreenReaderExclude>
            <Text>
              Metric: <TranslatableText>{label}</TranslatableText>
            </Text>
            <Text>Subaccount: {subaccount}</Text>
          </ScreenReaderExclude>
        </Stack>
      </Box>
    );

  if (cell.column.sortKey === 'threshold')
    return (
      <Box minWidth="100px">
        <Stack space="100" align="right">
          <Label>{label}</Label>
          <Text>{conditionValue}</Text>
        </Stack>
      </Box>
    );

  if (cell.column.sortKey === 'severity') return <AlertSeverityTag severity={incident.severity} />;

  if (cell.column.sortKey === 'last_triggered')
    return (
      <Box minWidth="150px">
        <Stack space="100">
          <Label>Date Triggered</Label>
          <Text>
            {incident.last_triggered ? (
              <time dateTime={incident.last_triggered}>
                {formatLongDateTime(incident.last_triggered)}
              </time>
            ) : (
              'Never Triggered'
            )}
          </Text>
        </Stack>
      </Box>
    );

  if (cell.column.sortKey === 'viewIn')
    return (
      !_.isNull(link) && (
        <Button variant="minimal" to={link}>
          <TranslatableText>{linkLabel}</TranslatableText>

          <StyledIcon>
            <ChevronRight />
          </StyledIcon>
        </Button>
      )
    );

  // screen reader only
  if (cell.column.sortKey === 'subaccount')
    return <ScreenReaderOnly>Subaccount: {subaccount}</ScreenReaderOnly>;

  if (cell.column.sortKey === 'metric') return <ScreenReaderOnly>Metric: {label}</ScreenReaderOnly>;

  return cell.value || '';
};

export default function RecentIncidents({ incidents }) {
  const filterPlaceholder = useMemo(() => _.sample(incidents)?.name || 'Alert name', [incidents]);
  const { filters, updateFilters } = usePageFilters(pageFilters);
  const [alertQuery, setAlertQuery] = useState('');
  const [selectSortBy, setSelectSortBy] = useState(filters.sortBy || 'last_triggered_desc');

  const [severityState, dispatchSeverity] = useReducer(tableFiltersReducer, {
    checkboxes: [
      {
        label: 'Select All',
        name: 'selectAll',
        isChecked: false
      },
      {
        label: 'High',
        name: 'high',
        isChecked: false
      },
      {
        label: 'Medium',
        name: 'medium',
        isChecked: false
      },
      {
        label: 'Low',
        name: 'low',
        isChecked: false
      }
    ]
  });

  const columnsMemoized = useMemo(
    () =>
      getColumns().map((col) => ({
        ...col,
        accessor: col.sortKey,
        Cell
      })),
    []
  );

  const {
    headerGroups,
    getTableProps,
    getTableBodyProps,
    rows,
    page,
    prepareRow,
    setPageSize,
    state,
    gotoPage,
    setSortBy,
    setGlobalFilter,
    setFilter
  } = useTable(
    {
      columns: columnsMemoized,
      data: incidents,
      initialState: {
        sortBy: [
          {
            id: 'last_triggered',
            desc: true
          }
        ]
      }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const handleSearch = useCallback((e) => setAlertQuery(e.target.value), []);

  useEffect(() => {
    setGlobalFilter(alertQuery);
    updateFilters({ ...filters, alert: alertQuery });

    // Note: we only run this effect when alertQuery changes
    // exec the search and update the url
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertQuery]);

  const handleSeverityChange = useCallback((e) => {
    const { target } = e;
    dispatchSeverity({ type: 'TOGGLE', name: target.name }); // NOTE: updates checkbox state
  }, []);

  useEffect(() => {
    const severity = [];

    severityState.checkboxes
      .filter((severity) => severity.name !== 'selectAll' && severity.isChecked)
      .forEach((severityItem) => severity.push(severityItem.name));

    setFilter('severity', severity.join(','));
    updateFilters({ ...filters, severity });

    // Note: we only run this effect when severityState changes
    // exec the severity filter and update the url
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [severityState]);

  const handleSortSelectChange = useCallback((e) => {
    const { currentTarget } = e;
    setSelectSortBy(currentTarget.value);
  }, []);

  useEffect(() => {
    const filter = filterOptions.find((option) => option.value === selectSortBy);

    if (!filter) return;

    setSortBy([
      {
        id: filter.id,
        desc: filter.desc
      }
    ]);

    updateFilters({ ...filters, sortBy: selectSortBy });

    // Note: we only run this effect when selectSortBy changes
    // exec the sort and update the url
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectSortBy]);

  // initialize filters from url
  useEffect(() => {
    if (filters.alert) setAlertQuery(filters.alert);
    if (filters.severity && filters.severity.length) {
      const severity = typeof filters.severity === 'string' ? [filters.severity] : filters.severity;
      severity.forEach((severity) => {
        dispatchSeverity({ type: 'TOGGLE', name: severity });
      });
    }

    // Note: we only run this effect once (on mount)
    // update the state from the url
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const originalRows = useMemo(() => rows.map((row) => row.original), [rows]);

  const table = (
    <Panel mb="500">
      <Panel.Section p="0">
        <TableWrapper>
          <Table {...getTableProps()} title="Alerts: Recent Incidents">
            <ScreenReaderOnly as="thead">
              {headerGroups.map((headerGroup) => {
                return (
                  <Table.Row header {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      return (
                        <Table.HeaderCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render('label')}
                        </Table.HeaderCell>
                      );
                    })}
                  </Table.Row>
                );
              })}
            </ScreenReaderOnly>

            <tbody {...getTableBodyProps()}>
              {page.length === 0 && (
                <Table.Row>
                  <Table.Cell colSpan="100%" p="0">
                    <Empty message="No results found." />
                  </Table.Cell>
                </Table.Row>
              )}

              {page.map((row) => {
                prepareRow(row);
                return (
                  <Table.Row {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <Table.Cell
                          {...cell.getCellProps()}
                          align={cell.column.sortKey === 'viewIn' ? 'right' : undefined}
                        >
                          {cell.render('Cell')}
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                );
              })}
            </tbody>
          </Table>
        </TableWrapper>
      </Panel.Section>
    </Panel>
  );

  return (
    <>
      <Panel>
        <Panel.Section>
          <FiltersBar>
            <TextField
              id="filter-alert"
              maxWidth="inherit"
              label="Filter Alerts"
              prefix={<Search />}
              onChange={handleSearch}
              value={alertQuery}
              placeholder={`e.g. ${filterPlaceholder}`}
            />

            <SeverityWrapper>
              <TableFilters.StatusPopover
                label="Severity Level"
                placeholder="Select"
                checkboxes={severityState.checkboxes}
                onCheckboxChange={handleSeverityChange}
              />
            </SeverityWrapper>

            <TableFilters.SortSelect
              defaultValue={selectSortBy}
              options={filterOptions}
              onChange={handleSortSelectChange}
            />
          </FiltersBar>
        </Panel.Section>
      </Panel>

      {table}

      <Pagination
        data={originalRows}
        perPage={state.pageSize}
        currentPage={state.pageIndex + 1}
        onPageChange={gotoPage}
        onPerPageChange={setPageSize}
      />
    </>
  );
}
