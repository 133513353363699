import React from 'react';
import { Inline } from 'src/components/matchbox';

type ButtonWrapperProps = {
  children: React.ReactNode;
};

export default function ButtonWrapper({ children }: ButtonWrapperProps): JSX.Element {
  return <Inline>{children}</Inline>;
}
