export const TYPES = [
  {
    label: 'Transactional',
    name: 'transactional'
  },
  {
    label: 'Non-Transactional',
    name: 'non_transactional'
  }
];

export const SOURCES = [
  {
    label: 'Spam Complaint',
    name: 'Spam Complaint'
  },
  {
    label: 'List Unsubscribe',
    name: 'List Unsubscribe'
  },
  {
    label: 'Bounce Rule',
    name: 'Bounce Rule'
  },
  {
    label: 'Unsubscribe Link',
    name: 'Unsubscribe Link'
  },
  {
    label: 'Manually Added',
    name: 'Manually Added'
  },
  {
    label: 'Compliance',
    name: 'Compliance'
  }
];

export const RELATIVE_DATE_OPTIONS = ['hour', 'day', '7days', '30days', '90days', 'custom'];
