import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { Button, Modal, Tabs } from 'src/components/matchbox';
import { Form } from 'src/components/tracking';
import { updateUserUIOptions } from 'src/actions/currentUser';
import { showAlert } from 'src/actions/globalAlert';
import {
  MyReportsTabWithSelectableRows,
  AllReportsTabWithSelectableRows
} from './ChangeReportModalTabs';
import { usePrevious } from 'src/hooks';
export function ChangeReportModal({ reports, open, onClose, currentUser }) {
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);

  const {
    control,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful }
  } = useForm();

  const [submittedData, setSubmittedData] = useState({});
  const prevClose = usePrevious(!open);
  //Ref - https://codesandbox.io/s/react-hook-form-handlesubmit-with-reset-xrh0t
  useEffect(() => {
    // this prevents the Modal to clear the radio button state when form is submitted
    if (isSubmitSuccessful) {
      reset({ ...submittedData });
    }
  }, [isSubmitSuccessful, submittedData, reset]);

  useEffect(() => {
    // this clears the previous state of radio buttons when the Modal is reopened
    if (prevClose && open) reset({});
  }, [open, prevClose, reset]);

  const handleTabChange = (index) => {
    setTabIndex(index);
    setValue('reportId', null);
  };

  const closeModal = () => {
    setValue('reportId', null);
    onClose();
  };

  const onSubmit = (val) => {
    setSubmittedData(val);

    if (val.reportId) {
      dispatch(updateUserUIOptions({ pinned_report_id: val.reportId })).then(() => {
        dispatch(
          showAlert({
            type: 'success',
            message: 'Pinned Report updated'
          })
        );
      });

      closeModal();
    }
  };

  const TABS = [
    <MyReportsTabWithSelectableRows
      reports={reports}
      currentUser={currentUser}
      onSubmit={onSubmit}
      register={register}
    />,
    <AllReportsTabWithSelectableRows reports={reports} onSubmit={onSubmit} register={register} />
  ];

  return (
    <Modal open={open} onClose={closeModal} showCloseButton maxWidth="1300">
      <Modal.Header>Change Report</Modal.Header>
      <Modal.Content p="0">
        <Form onSubmit={handleSubmit(onSubmit)} id="dashboard-changereport-modal-form">
          <Tabs
            tabs={[
              { content: 'My Reports', onClick: () => handleTabChange(0) },
              { content: 'All Reports', onClick: () => handleTabChange(1) }
            ]}
            fitted
            selected={tabIndex}
          />

          {TABS[tabIndex]}
        </Form>
      </Modal.Content>
      <ModalFooter onClose={closeModal} control={control} />
    </Modal>
  );
}

function ModalFooter({ onClose, control }) {
  const submitDisbaled = useWatch({
    control,
    name: 'reportId'
  });
  return (
    <Modal.Footer>
      <Button
        variant="primary"
        loadingLabel="Loading"
        type="submit"
        form="dashboard-changereport-modal-form"
        disabled={!submitDisbaled}
      >
        Change Report
      </Button>
      <Button variant="secondary" onClick={onClose}>
        Cancel
      </Button>
    </Modal.Footer>
  );
}
ModalFooter.displayName = 'Modal.Footer';

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.username
  };
};
export default connect(mapStateToProps)(ChangeReportModal);
