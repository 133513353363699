import { Add, RemoveCircle } from '@sparkpost/matchbox-icons';
import React from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isAccountUiOptionSet } from 'src/helpers/conditions/account';

import { ExternalLink, PageLink, SubduedLink } from 'src/components/links';
import {
  Box,
  Button,
  Column,
  Columns,
  Layout,
  ListBox,
  Page,
  Panel,
  Radio,
  RadioCard,
  ScreenReaderOnly,
  Stack,
  TextField
} from 'src/components/matchbox';
import { SubduedText, TranslatableText } from 'src/components/text';
import { Form } from 'src/components/tracking';
import { useAlert, useSparkPostMutation, useSubaccounts } from 'src/hooks';

import { TypeaheadController } from 'src/components/reactHookFormControllers/TypeaheadController';
import { TypeaheadItem } from 'src/components/typeahead';
import { ROLES } from 'src/constants';
import { createSubaccountUser, createUser } from 'src/helpers/api/users';
import { hasSubaccounts as hasSubaccountsSelector } from 'src/selectors/subaccounts';

const content = [
  {
    label: 'Admin',
    value: ROLES.ADMIN,
    helpText:
      'This role is a full access user. Admins have all permissions available and are the only users that can manage users, security, and billing settings.'
  },
  {
    label: 'Developer',
    value: ROLES.DEVELOPER,
    helpText:
      'This role is a powerful setup and development user. Developer users are able to create and edit API Keys and have access to all email related account settings.'
  },
  {
    label: 'Templates',
    value: ROLES.TEMPLATES,
    helpText:
      'This role is a content and campaign management user. These users can manage Templates, AB Tests, Recipient Lists, Suppressions. They also have view only access to Domains, Subaccounts, IP Pools, and all reporting and analytics features.'
  },
  {
    label: 'Reporting',
    value: ROLES.REPORTING,
    helpText:
      "This role is a data analytics user. They have access to all reporting features and View Only access to Domains, Subaccounts, and IP Pools. Reporting users can't edit any account or feature settings"
  }
];

type Subaccount = {
  id: number;
};

type SubaccountRole = {
  role: string;
  subaccount: Subaccount | null;
};

type FormValues = {
  email: string;
  subaccount_assignment: string;
  subaccounts: SubaccountRole[];
  user_role: string;
};

export function CreatePage({ isSubAccountReportingLive }: { isSubAccountReportingLive: boolean }) {
  const { handleSubmit, register, watch, control, formState } = useForm<FormValues>({
    defaultValues: {
      subaccount_assignment: 'primary',
      user_role: ROLES.ADMIN,
      subaccounts: [{ subaccount: null, role: ROLES.SUBACCOUNT_DEVELOPER }]
    }
  });

  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({ control, name: 'subaccounts' });

  const subAssignmentRadio = watch('subaccount_assignment');
  const email = watch('email');
  const subValues = watch('subaccounts').map(({ subaccount }) => subaccount?.id ?? null);

  const { showAlert } = useAlert();
  const history = useHistory();

  const { subaccountsQuery } = useSubaccounts();
  const { data: subaccounts } = subaccountsQuery as { data: { id: number }[] };
  const hasSubaccounts = useSelector((state: $TODOFIXME) => hasSubaccountsSelector(state));

  const subaccountOptions = subaccounts?.filter(({ id }) => !subValues.includes(id)) ?? [];

  const createRequest = useSparkPostMutation(
    (
      {
        email,
        role,
        subaccounts
      }: {
        email: string;
        role: string;
        subaccounts: { access_level: string; subaccount_id: string | number }[];
      } = { email: '', role: 'admin', subaccounts: [] }
    ) =>
      subAssignmentRadio === 'primary'
        ? createUser(email, role)
        : createSubaccountUser(email, subaccounts),
    {
      onSuccess: () => {
        showAlert({
          type: 'success',
          message: `Invitation sent to ${email}`
        });
        history.push('/account/users');
      }
    }
  );

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    if (values.subaccount_assignment === 'primary') {
      const { email, user_role } = values;
      createRequest.mutate({ email, role: user_role });
    } else {
      const { email, subaccounts } = values;
      const mappedSubaccounts = subaccounts.map(({ subaccount, role }) => ({
        access_level: role,
        subaccount_id: subaccount?.id
      }));
      createRequest.mutate({ email, subaccounts: mappedSubaccounts });
    }
  };

  return (
    <Page
      title="Invite User"
      breadcrumbAction={{ content: 'Users', Component: PageLink, to: '/account/users' }}
    >
      <Layout>
        <Layout.Section annotated>
          <Layout.SectionTitle>Details</Layout.SectionTitle>
          <SubduedText>
            Invite and designate team member roles within a SparkPost account. Each role defines the
            feature to which the user has access.
          </SubduedText>
          <SubduedLink
            as={ExternalLink}
            to="https://support.sparkpost.com/docs/user-guide/managing-users"
          >
            User Management Documentation
          </SubduedLink>
        </Layout.Section>
        <Layout.Section>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Form id="users-create-form" onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              <Panel>
                <Panel.Section>
                  <TextField
                    {...register('email', { required: 'Required' })}
                    error={errors['email']?.message}
                    disabled={createRequest.status === 'loading'}
                    id="email"
                    helpText="An invitation will be sent to the email address."
                    placeholder="e.g. example@email.com"
                    label="Email"
                    type="email"
                  />
                </Panel.Section>
                <Panel.Section>
                  <Stack>
                    {isSubAccountReportingLive && (
                      <Radio.Group label="Subaccount Assignment">
                        <Radio
                          {...register('subaccount_assignment')}
                          disabled={createRequest.status === 'loading'}
                          value="primary"
                          id="primary"
                          label="Assign to Primary Account"
                        />
                        <Radio
                          {...register('subaccount_assignment')}
                          disabled={createRequest.status === 'loading' || !hasSubaccounts}
                          id="subaccount"
                          helpText="Users assigned to a subaccount can only be assinged to the Developer or Reporting Role"
                          value="subaccount"
                          label="Assign to Subaccount"
                        />
                      </Radio.Group>
                    )}
                    {subAssignmentRadio === 'primary' ? (
                      <RadioCard.Group label="User Role" orientation="grid">
                        {content.map(({ label, helpText, value }) => (
                          <RadioCard
                            {...register('user_role')}
                            disabled={createRequest.status === 'loading'}
                            value={value}
                            key={`role-radio-${value}`}
                            id={`role-radio-${value}`}
                            label={label}
                          >
                            <TranslatableText>{helpText}</TranslatableText>
                          </RadioCard>
                        ))}
                      </RadioCard.Group>
                    ) : (
                      <Box pl="500">
                        <Stack>
                          {fields.map((field, index) => {
                            return (
                              <Columns key={field.id}>
                                <Column width={1 / 2}>
                                  <TypeaheadController
                                    disabled={createRequest.status === 'loading'}
                                    control={control}
                                    id={`${field.id}-subaccount`}
                                    label="Subaccount"
                                    helpText=""
                                    name={`subaccounts.${index}.subaccount`}
                                    results={subaccountOptions}
                                    renderItem={(item: $TODOFIXME) => (
                                      <TypeaheadItem label={item.name} id={item.id} />
                                    )}
                                    itemToString={(item: $TODOFIXME) =>
                                      item ? `${item.name} (${item.id})` : ''
                                    }
                                  />
                                </Column>
                                <Column>
                                  <Controller
                                    control={control}
                                    //eslint-disable-next-line react/jsx-no-bind
                                    render={({ field: { onChange, ...fieldProps } }) => (
                                      <ListBox
                                        disabled={createRequest.status === 'loading'}
                                        id={`${field.id}-role`}
                                        label="Role"
                                        onChange={(e) => {
                                          onChange({ target: e.currentTarget, ...e });
                                        }}
                                        {...fieldProps}
                                      >
                                        <ListBox.Option
                                          value={ROLES.SUBACCOUNT_DEVELOPER}
                                          label="Developer"
                                        >
                                          <Box> Developer </Box>
                                          <Box color="gray.700">
                                            Setup and development user. Full access to API keys and
                                            all other email related setup, sending, and reporting
                                            features
                                          </Box>
                                        </ListBox.Option>
                                        <ListBox.Option
                                          value={ROLES.SUBACCOUNT_REPORTING}
                                          label="Reporting"
                                        >
                                          <Box> Reporting </Box>
                                          <Box color="gray.700">
                                            Data analytics user. Has access to all reporting
                                            features and can view templates. No access to any
                                            account or feature settings.
                                          </Box>
                                        </ListBox.Option>
                                      </ListBox>
                                    )}
                                    name={`subaccounts.${index}.role`}
                                  ></Controller>
                                </Column>
                                <Column width={1 / 12}>
                                  <Box mt="500">
                                    {fields.length > 1 && (
                                      <Button
                                        useMatchboxVariant={true}
                                        variant="text"
                                        color="red"
                                        onClick={() => remove(index)}
                                      >
                                        <ScreenReaderOnly>Remove Subaccount</ScreenReaderOnly>
                                        <Button.Icon as={RemoveCircle} />
                                      </Button>
                                    )}
                                  </Box>
                                </Column>
                              </Columns>
                            );
                          })}
                          {fields.length < 25 && (
                            <Box>
                              <Button
                                useMatchboxVariant={false}
                                loading={createRequest.status === 'loading'}
                                variant="tertiary"
                                onClick={() =>
                                  append({ subaccount: null, role: ROLES.SUBACCOUNT_DEVELOPER })
                                }
                              >
                                <TranslatableText>Add Subaccount</TranslatableText>
                                <Button.Icon as={Add} />
                              </Button>
                            </Box>
                          )}
                        </Stack>
                      </Box>
                    )}
                  </Stack>
                </Panel.Section>
              </Panel>
              <Box>
                <Button useMatchboxVariant={false} variant="primary" type="submit">
                  Invite User
                </Button>
              </Box>
            </Stack>
          </Form>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

const mapStateToProps = (state: $TODOFIXME) => ({
  isSubAccountReportingLive: isAccountUiOptionSet('subaccount_reporting')(state)
});

export default connect(mapStateToProps)(CreatePage);
