import { tokens } from '@sparkpost/design-tokens';
import { list as METRICS_LIST } from '@sparkpost/report-builder/config';
import React from 'react';
import { Empty, Percent } from 'src/components';
import { LabelValueLoadingSkeleton } from 'src/components/loading';
import { Box, LabelValue, Layout, Panel, Text } from 'src/components/matchbox';
import { getResourceFromPageType } from '../../constants/resourceDetails';
import useResourceDetailsContext from '../../hooks/useResourceDetailsContext';

/**
 * This component is used for the message lifecycle and deliverability details
 */

const METRICS_BY_MAIN_KEY = [
  'open_rate',
  'click_through_rate',
  'spam_complaint_rate',
  'unsubscribe_rate',
  'accepted_rate',
  'block_bounce_rate',
  'inbox_folder_rate',
  'spam_folder_rate',
  'prefetched_open_rate'
];

const METRIC_META = {};
METRICS_LIST.forEach(({ key, label, compute, computeKeys }) => {
  const metricToFilterOn = METRICS_BY_MAIN_KEY.indexOf(key) > -1;
  if (metricToFilterOn) {
    METRIC_META[key] = {
      label,
      compute,
      computeKeys
    };
  }
});

export default function ({ deliverabilityData = [], deliverabilityDataStatus }) {
  const { hasD12yProduct, match: { params: { type } = {} } = {} } = useResourceDetailsContext();

  // dd = deliverability details
  // ml = message lifecycle
  const mode = type === 'subject-campaign' ? 'dd' : 'ml';
  const sectionTitle = mode === 'dd' ? 'Deliverability Details' : 'Message Lifecycle';
  const LOADING = deliverabilityDataStatus === 'loading';
  const ERROR = deliverabilityDataStatus === 'error';
  const SUCCESS = deliverabilityDataStatus === 'success';

  const data = deliverabilityData && deliverabilityData.length && deliverabilityData[0];

  if (mode === 'dd' && !hasD12yProduct) return null;

  const loadingLabelValues = [{ value: '' }, { value: '' }, { value: '' }, { value: '' }];

  return (
    <Layout data-id="deliverability-details">
      <Layout.Section annotated>
        <Layout.SectionTitle>{sectionTitle}</Layout.SectionTitle>
        {mode === 'ml' && (
          <Text color="gray.700" fontSize="200">
            {`General information about this ${getResourceFromPageType(type)}'s performance.`}
          </Text>
        )}
      </Layout.Section>
      <Layout.Section>
        <Panel mb="0">
          {LOADING && (
            <Panel.Section>
              <LabelValueLoadingSkeleton labelValues={loadingLabelValues} />
            </Panel.Section>
          )}
          {!LOADING && ERROR && <Empty message="Failed to load the data. Refresh page."></Empty>}
          {!LOADING && SUCCESS && (
            <>
              <Panel.Section>
                <Box display="flex">
                  {mode === 'ml' && (
                    <LabelValue mr={tokens.spacing_600}>
                      <LabelValue.Label>{METRIC_META['accepted_rate'].label}</LabelValue.Label>
                      <LabelValue.Value>
                        <Percent
                          value={METRIC_META['accepted_rate'].compute(
                            data,
                            METRIC_META['accepted_rate'].computeKeys
                          )}
                        />
                      </LabelValue.Value>
                    </LabelValue>
                  )}
                  {mode === 'ml' && (
                    <LabelValue mr={tokens.spacing_600}>
                      <LabelValue.Label>{METRIC_META['block_bounce_rate'].label}</LabelValue.Label>
                      <LabelValue.Value>
                        <Percent
                          value={METRIC_META['block_bounce_rate'].compute(
                            data,
                            METRIC_META['block_bounce_rate'].computeKeys
                          )}
                        />
                      </LabelValue.Value>
                    </LabelValue>
                  )}
                  {hasD12yProduct && (mode === 'ml' || mode === 'dd') && (
                    <LabelValue mr={tokens.spacing_600}>
                      <LabelValue.Label>{METRIC_META['inbox_folder_rate'].label}</LabelValue.Label>
                      <LabelValue.Value>
                        <Percent value={METRIC_META['inbox_folder_rate'].compute(data)} />
                      </LabelValue.Value>
                    </LabelValue>
                  )}
                  {hasD12yProduct && (mode === 'ml' || mode === 'dd') && (
                    <LabelValue mr={tokens.spacing_600}>
                      <LabelValue.Label>{METRIC_META['spam_folder_rate'].label}</LabelValue.Label>
                      <LabelValue.Value>
                        <Percent value={METRIC_META['spam_folder_rate'].compute(data)} />
                      </LabelValue.Value>
                    </LabelValue>
                  )}
                </Box>
              </Panel.Section>
              {mode === 'ml' && (
                <Panel.Section>
                  <Box display="flex">
                    <LabelValue mr={tokens.spacing_600}>
                      <LabelValue.Label>
                        {METRIC_META['prefetched_open_rate'].label} (Includes Prefetched)
                      </LabelValue.Label>
                      <LabelValue.Value>
                        <Percent
                          value={METRIC_META['open_rate'].compute(
                            data,
                            METRIC_META['open_rate'].computeKeys
                          )}
                        />
                      </LabelValue.Value>
                    </LabelValue>

                    <LabelValue mr={tokens.spacing_600}>
                      <LabelValue.Label>
                        {METRIC_META['open_rate'].label} (Excludes Prefetched)
                      </LabelValue.Label>
                      <LabelValue.Value>
                        <Percent
                          value={METRIC_META['prefetched_open_rate'].compute(
                            data,
                            METRIC_META['prefetched_open_rate'].computeKeys
                          )}
                        />
                      </LabelValue.Value>
                    </LabelValue>

                    <LabelValue mr={tokens.spacing_600}>
                      <LabelValue.Label>{METRIC_META['unsubscribe_rate'].label}</LabelValue.Label>
                      <LabelValue.Value>
                        <Percent
                          value={METRIC_META['unsubscribe_rate'].compute(
                            data,
                            METRIC_META['unsubscribe_rate'].computeKeys
                          )}
                        />
                      </LabelValue.Value>
                    </LabelValue>
                    <LabelValue mr={tokens.spacing_600}>
                      <LabelValue.Label>
                        {METRIC_META['spam_complaint_rate'].label}
                      </LabelValue.Label>
                      <LabelValue.Value>
                        <Percent
                          value={METRIC_META['spam_complaint_rate'].compute(
                            data,
                            METRIC_META['spam_complaint_rate'].computeKeys
                          )}
                        />
                      </LabelValue.Value>
                    </LabelValue>
                    <LabelValue>
                      <LabelValue.Label>{METRIC_META['click_through_rate'].label}</LabelValue.Label>
                      <LabelValue.Value>
                        <Percent
                          value={METRIC_META['click_through_rate'].compute(
                            data,
                            METRIC_META['click_through_rate'].computeKeys
                          )}
                        />
                      </LabelValue.Value>
                    </LabelValue>
                  </Box>
                </Panel.Section>
              )}
            </>
          )}
        </Panel>
      </Layout.Section>
    </Layout>
  );
}
