import { FilterAlt } from '@sparkpost/matchbox-icons';
import React from 'react';
import { Unit } from 'src/components';
import { Box, Button, Column, Columns, LabelValue, Stack } from 'src/components/matchbox';
import styled from 'styled-components';
import CompareByAggregatedRow from './CompareByAggregatedRow';

const ViewFilterButton = styled(Button)`
  float: right;
  color: ${(props) => props.theme.colors.gray['600']};
`;

export default function CompareByAggregatedMetrics({
  date,
  reportOptions,
  //TODO: Add better accessor for formatted metrics: FE-1716
  formattedMetrics,
  showFiltersButton,
  handleViewFiltersClick
}) {
  const { comparisons } = reportOptions;

  const RenderDate = () => {
    return (
      <Column width={showFiltersButton ? 2 / 5 : 1 / 5}>
        <Box mb={showFiltersButton && '500'} data-id="aggregate-metrics-date-range">
          <LabelValue appearance="inverted">
            <LabelValue.Label>Date</LabelValue.Label>
            <LabelValue.Value>
              <Unit value={date} />
            </LabelValue.Value>
          </LabelValue>
        </Box>
      </Column>
    );
  };

  const RenderAggregateData = () => {
    return (
      <Column>
        <Stack space="300">
          {comparisons?.map((comparison, comparisonIndex) => {
            return (
              <CompareByAggregatedRow
                key={`comparison-${comparisonIndex}`}
                comparison={comparison}
                reportOptions={reportOptions}
                formattedMetrics={formattedMetrics}
                hasDivider={comparisonIndex < comparisons.length - 1}
              />
            );
          })}
        </Stack>
      </Column>
    );
  };

  if (showFiltersButton)
    return (
      <Box padding="400" backgroundColor="gray.1000" data-id="compare-by-aggregated-metrics">
        <Columns>
          <RenderDate />
          <Column width={4 / 5}>
            <ViewFilterButton variant="minimal" onClick={handleViewFiltersClick}>
              View Filters <FilterAlt size={20} />
            </ViewFilterButton>
          </Column>
        </Columns>
        <Columns>
          <RenderAggregateData />
        </Columns>
      </Box>
    );

  return (
    <Box padding="400" backgroundColor="gray.1000" data-id="compare-by-aggregated-metrics">
      <Columns>
        <RenderDate />
        <RenderAggregateData />
      </Columns>
    </Box>
  );
}
