export const COLUMN_HEADERS = [
  { Header: 'Resource', accessor: 'resource' },
  { Header: 'Assignment', accessor: 'assignment' },
  { Header: 'Date Listed', accessor: 'last_listed_at' },
  { Header: 'Current Listings', accessor: 'active_listing_count' },
  { Header: 'Historic Listing', accessor: 'total_listing_count' },
  { Header: 'Action', accessor: () => null }
];

export const SORT_BY_OPTIONS = [
  { value: 'last_listed_at.desc', label: 'Last Incident (Newest - Oldest)' },
  { value: 'last_listed_at.asc', label: 'Last Incident (Oldest - Newest)' },
  { value: 'resource.asc', label: 'Resource Name (A - Z)' },
  { value: 'resource.desc', label: 'Resource Name (Z - A)' }
];

export const DEFAULT_SORT_BY = [{ id: 'last_listed_at', desc: true }];
