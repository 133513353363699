export default (state = false, { type }) => {
  if (type === 'ACCESS_CONTROL_READY') {
    return true;
  }

  if (type === 'ACCESS_CONTROL_RESET') {
    return false;
  }

  return state;
};
