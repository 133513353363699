import { UnstyledLink } from 'src/components/matchbox';
import styled from 'styled-components';

const SubduedLink = styled(UnstyledLink)`
  color: ${(props) => props.theme.colors.gray['700']};

  &:visited {
    color: ${(props) => props.theme.colors.gray['700']};
  }
`;

export default SubduedLink;
