import { useAnalyticsReportContext } from '../context/AnalyticsReportContext';

export default function useAnalyticsReportModal() {
  const {
    modalActions: { openModal }
  } = useAnalyticsReportContext();
  const openDeleteModal = (reportToDelete) => {
    return openModal({ type: 'delete', focusedReport: reportToDelete });
  };

  const openEditReportModal = (reportToEdit) => {
    return openModal({ type: 'edit', focusedReport: reportToEdit });
  };
  const openPinToDashboardModal = (reportToPin, oldReportThatWasPinned) => {
    return openModal({
      type: 'confirm-pin',
      focusedReport: reportToPin,
      previouslyPinnedReport: oldReportThatWasPinned
    });
  };
  const openSaveReportModal = (selectedReport) =>
    openModal({ type: 'save', focusedReport: selectedReport });

  const openSaveNewReportModal = (selectedReport) =>
    openModal({ type: 'create', focusedReport: selectedReport });

  const openScheduledReportModal = (selectedReport) =>
    openModal({ type: 'scheduled', focusedReport: selectedReport });

  const openAllReportModal = () => openModal({ type: 'view' });

  return {
    openDeleteModal,
    openEditReportModal,
    openPinToDashboardModal,
    openSaveReportModal,
    openSaveNewReportModal,
    openScheduledReportModal,
    openAllReportModal
  };
}
