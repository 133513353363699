import React from 'react';
import { usePrepareAnalyticsReportQuery, useSparkPostQuery } from 'src/hooks';
import { DELIVERABILITY_DELAY_METRIC_KEYS } from '@sparkpost/report-builder/config';
import { getDelayReasonByDomain, getDeliverability } from 'src/helpers/api/metrics';
import { ApiErrorBanner, Loading } from 'src/components';
import { Panel } from 'src/components/matchbox';
import { getMetricsFromKeys, toFilterFromComparison } from 'src/helpers/metrics';
import { useAnalyticsReportContext } from '../../context/AnalyticsReportContext';
import { TAB_LOADING_HEIGHT } from '../../constants';
import { DelayReasonTable } from '../tables';

export default function DelayReasonsTab({ comparison }) {
  const { aggregatesQuery, delayReasonsQuery, isPending, isError } =
    useQueriesWithComparison(comparison);

  function handleReload() {
    aggregatesQuery.refetch();
    delayReasonsQuery.refetch();
  }

  if (isPending) {
    return <Loading minHeight={TAB_LOADING_HEIGHT} />;
  }

  if (isError) {
    return (
      <Panel.Section>
        <ApiErrorBanner reload={handleReload} status="muted" />
      </Panel.Section>
    );
  }

  const reasons = delayReasonsQuery.data;
  const totalAccepted = aggregatesQuery.data ? aggregatesQuery.data.count_accepted : 1;
  return <DelayReasonTable reasons={reasons} totalAccepted={totalAccepted} loading={false} />;
}

/**
 * Prepares request parameters using common hooks, then leverages helper functions to determine which `metrics` are passed as arguments to each request.
 *
 * @param {Object} comparison - passed in comparison set by the user via the "Compare By" feature
 *
 */
function useQueriesWithComparison(comparison) {
  const { state: reportOptions } = useAnalyticsReportContext();
  const deliverabilityMetrics = getMetricsFromKeys(DELIVERABILITY_DELAY_METRIC_KEYS);
  const existingFilters = reportOptions.filters ? reportOptions.filters : [];
  const comparisonFilter = comparison ? [toFilterFromComparison(comparison)] : [];
  const aggregatesParams = usePrepareAnalyticsReportQuery({
    ...reportOptions,
    filters: [...existingFilters, ...comparisonFilter],
    metrics: deliverabilityMetrics
  });
  const delayReasonsParams = usePrepareAnalyticsReportQuery({
    ...reportOptions,
    filters: [...existingFilters, ...comparisonFilter],
    metrics: reportOptions.metrics
  });
  const delayReasonsQuery = useSparkPostQuery(() => getDelayReasonByDomain(delayReasonsParams), {
    refetchOnWindowFocus: true
  });
  const aggregatesQuery = useSparkPostQuery(() => getDeliverability(aggregatesParams), {
    refetchOnWindowFocus: true,
    select: (data) => data[0]
  });

  return {
    aggregatesQuery,
    delayReasonsQuery,
    isPending: aggregatesQuery.status === 'loading' || delayReasonsQuery.status === 'loading',
    isError: aggregatesQuery.status === 'error' || delayReasonsQuery.status === 'error'
  };
}
