import React from 'react';
import { useAnalyticsReportContext } from '../context/AnalyticsReportContext';
import { AnalyticsReportCharts } from 'src/components/analyticsReport';

export default function ChartContainer() {
  const { state: reportOptions, selectors } = useAnalyticsReportContext();
  return (
    <AnalyticsReportCharts
      reportOptions={reportOptions}
      formattedMetrics={selectors.formattedMetrics}
      showIndustryBenchmarkButton={true}
    />
  );
}
