import React, { useState } from 'react';
import { CheckCircleOutline, CloseCircleOutline } from '@sparkpost/matchbox-icons';
import { Box, Button, Panel, Stack, Text } from 'src/components/matchbox';
import { TranslatableText } from 'src/components/text';
import { ExternalLink } from 'src/components/links';
import PermitAutomaticInlineSeedingModal from './PermitAutomaticInlineSeedingModal';
import BlockAutomaticInlineSeedingModal from './BlockAutomaticInlineSeedingModal';

export function SubaccountAccessPanel({ primaryAcctEnabled, refetch }) {
  const [enableAccessModalOpen, setEnableAccessModalOpen] = useState(false);
  const [disableAccessModalOpen, setDisableAccessModalOpen] = useState(false);

  const message = primaryAcctEnabled
    ? 'Automatic Inline Seeding is permitted on Primary and all subaccounts using settings saved per account. '
    : 'Automatic Inline Seeding is blocked across Primary and all subaccounts. ';

  return (
    <>
      <Panel data-id="subaccount-access-panel">
        <Panel.Header>
          {primaryAcctEnabled ? (
            <TranslatableText>Automatic Seeding Permitted On All Subaccounts</TranslatableText>
          ) : (
            <TranslatableText>Automatic Seeding Blocked On All Subaccounts</TranslatableText>
          )}

          {primaryAcctEnabled ? (
            <Panel.Action color="red" onClick={() => setDisableAccessModalOpen(true)}>
              <TranslatableText>Block Automatic Seeding</TranslatableText>

              <Button.Icon as={CloseCircleOutline} />
            </Panel.Action>
          ) : (
            <Panel.Action onClick={() => setEnableAccessModalOpen(true)}>
              <TranslatableText>Permit Automatic Seeding</TranslatableText>

              <Button.Icon as={CheckCircleOutline} />
            </Panel.Action>
          )}
        </Panel.Header>

        <Panel.Section>
          <Stack>
            <Text>
              <Box maxWidth="1150">
                <TranslatableText>{message}</TranslatableText>
                Read more about{' '}
                <ExternalLink to="https://www.sparkpost.com/docs/user-guide/automatic-inline-seeds/">
                  Automatic Inline Seeding
                </ExternalLink>
                .
              </Box>
            </Text>
          </Stack>
        </Panel.Section>
      </Panel>

      <PermitAutomaticInlineSeedingModal
        open={enableAccessModalOpen}
        onClose={() => setEnableAccessModalOpen(false)}
        onSuccess={refetch}
      />

      <BlockAutomaticInlineSeedingModal
        open={disableAccessModalOpen}
        onClose={() => setDisableAccessModalOpen(false)}
        onSuccess={refetch}
      />
    </>
  );
}
