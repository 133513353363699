import React, { DOMAttributes, PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';

// COMPONENTS
import { UnstyledLink } from 'src/components/matchbox';

// TYPES
import { PolymorphicComponentProps } from 'src/typescript';

type PageLinkProps<E extends React.ElementType> = PolymorphicComponentProps<
  E,
  PropsWithChildren<{
    /** @deprecated Invalid prop `onClick` - avoid attaching click handlers to links. Use the `ButtonLink` component instead.  */
    onClick?: DOMAttributes<E>['onClick'];
    to: LinkProps['to'];
  }>
>;

// Link to a page in our application
const PageLink = <E extends React.ElementType = typeof UnstyledLink>(
  props: PageLinkProps<E>
): JSX.Element => {
  const { as: asComponent = UnstyledLink, children, to, ...otherProps } = props;

  const Component = asComponent;

  return (
    <Component to={to} as={Link as PageLinkProps<E>['as']} {...otherProps}>
      {children}
    </Component>
  );
};

export default PageLink;
