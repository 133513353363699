import React from 'react';
import { InfoOutline, ChevronRight } from '@sparkpost/matchbox-icons';
import styled from 'styled-components';
import {
  Box,
  Panel,
  Table,
  Text,
  Button,
  Tooltip,
  ScreenReaderOnly
} from 'src/components/matchbox';
import { TableCollection } from 'src/components';
import { formatPercent } from 'src/helpers/units';
import { PageLink } from 'src/components/links';

const PER_PAGE = 10;

const StyledIcon = styled(InfoOutline)`
  vertical-align: top;
  transform: translateY(-15%);
`;

const StyledWrappedText = styled(Text)`
  word-break: break-word;
`;

const TableWrapper = (props) => (
  <Panel.Section p="0">
    <Table>{props.children}</Table>
  </Panel.Section>
);

const PaginationWrapper = (props) => <Panel.Section p="100">{props.children}</Panel.Section>;

export function MailboxProviderImpacts({
  mailboxProviders = [],
  isEstimatedImpact,
  occurredAt,
  resolvedAt
}) {
  mailboxProviders =
    mailboxProviders?.map(({ distribution_perc, ...rest }) => ({
      distribution_perc: distribution_perc / 100, //Percent is returned as 0 - 1 value
      ...rest
    })) || [];

  const columns = [
    { label: 'Mailbox Provider', sortKey: 'mailbox_provider' },
    { label: 'Percentage of Recipients', sortKey: 'distribution_perc' },
    {
      label: isEstimatedImpact ? 'Expected Impact' : 'Observed Impact',
      sortKey: 'provider_action'
    },
    { label: <ScreenReaderOnly>Actions</ScreenReaderOnly> }
  ];

  const tooltipContent = (percent, mailboxProvider) =>
    isEstimatedImpact
      ? `During the past 30 days, ${percent} of emails sent were to ${mailboxProvider} recipients`
      : `During this incident, ${percent} of emails sent were to ${mailboxProvider} recipients`;

  const getRowData = ({ provider_action, mailbox_provider, distribution_perc }) => {
    const queryFilter = [{ AND: { mailbox_providers: { eq: [mailbox_provider] } } }];
    const stringifiedQueryFilter = JSON.stringify(queryFilter);
    // For resolved incidents only, the link to Analytics Report includes the relevant date range
    const search = resolvedAt
      ? `?query_filters=${stringifiedQueryFilter}&group_by=mailbox-provider&from=${occurredAt}&to=${resolvedAt}&timezone=Etc/UTC` // Dates returned by the API are in UTC time, so the UI timezone should reflect that as well
      : `?query_filters=${stringifiedQueryFilter}&group_by=mailbox-provider&range=30days`;

    return [
      <StyledWrappedText>{mailbox_provider}</StyledWrappedText>,
      <Box display="inline-flex">
        <Text>{formatPercent(distribution_perc)}</Text>

        <Tooltip dark content={tooltipContent(formatPercent(distribution_perc), mailbox_provider)}>
          <Box data-id="tooltip-box" ml="100" display="inline-flex">
            <StyledIcon tabIndex="0" size="0.9rem" />
          </Box>
        </Tooltip>
      </Box>,
      <StyledWrappedText>{provider_action}</StyledWrappedText>,
      <PageLink
        as={Button}
        variant="minimal"
        to={{
          pathname: '/signals/analytics',
          search
        }}
      >
        View Analytics
        <Button.Icon as={ChevronRight} />
      </PageLink>
    ];
  };

  return (
    <Panel>
      {/* TODO: Replace with react-table hooks. Also maybe make the react-table hooks a bit more componentized */}
      <TableCollection
        wrapperComponent={TableWrapper}
        columns={columns}
        rows={mailboxProviders}
        getRowData={getRowData}
        paginationWrapper={PaginationWrapper}
        pagination={Boolean(mailboxProviders.length > PER_PAGE)}
        saveCsv={false}
        defaultPerPage={PER_PAGE}
        pageRange={3}
        perPageButtons={[]}
      />
    </Panel>
  );
}
