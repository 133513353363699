import React from 'react';
import { DatePicker as MatchboxDatePicker } from '@sparkpost/matchbox';

/**
 * Matchbox DatePicker component
 * @see https://design.sparkpost.com/components/datepicker/
 */
const DatePicker = MatchboxDatePicker;

export default DatePicker;
