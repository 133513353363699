import React from 'react';
import { Box, Panel, Table } from 'src/components/matchbox';
import { useHibana } from 'src/context/HibanaContext';
import TableCollection from './TableCollection';

type PanelSectionTableCollectionProps = {
  collection: JSX.Element;
  filterBox?: JSX.Element;
  heading?: JSX.Element;
  pagination?: JSX.Element;
};

const PanelSectionTableCollection = (props: PanelSectionTableCollectionProps) => {
  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  const [{ isHibanaEnabled }] = useHibana();

  if (!isHibanaEnabled) {
    return <TableCollection {...props} />;
  }

  return (
    <TableCollection {...props} wrapperComponent={Table}>
      {({ collection, filterBox, heading, pagination }: PanelSectionTableCollectionProps) => (
        <>
          {heading && <Panel.LEGACY.Section>{heading}</Panel.LEGACY.Section>}
          {filterBox && <Panel.LEGACY.Section>{filterBox}</Panel.LEGACY.Section>}
          <Box borderBottom="400">{collection}</Box>
          {pagination && <Panel.LEGACY.Section>{pagination}</Panel.LEGACY.Section>}
        </>
      )}
    </TableCollection>
  );
};

export default PanelSectionTableCollection;
