import { tokens } from '@sparkpost/design-tokens';
import _ from 'lodash';
import React from 'react';
import {
  Area,
  AreaProps,
  Bar,
  ComposedChart,
  ItemSorter,
  LabelFormatter,
  RechartsFunction,
  ReferenceLine,
  ReferenceLineProps,
  ResponsiveContainer,
  ScaleType,
  TickFormatterFunction,
  Tooltip,
  TooltipFormatter,
  TooltipPayload,
  XAxis,
  YAxis
} from 'recharts';
import styles from './AreaChart.module.scss';
import Cursor from './Cursor';
import { SPAxisRefLineProp } from './types/charts.types';

const identity = (a: {}) => a;

const orderDesc: ItemSorter<TooltipPayload> = (a: $TODOFIXME, b: $TODOFIXME) => {
  return b.value - a.value;
};

type SPAreaChartProps = {
  areas: AreaProps[] | Readonly<AreaProps[]>;
  data: $TODOFIXME[];
  defs: symbol;
  height: number;
  precision: 'day' | '1min' | '15min';
  showTooltip: boolean;
  showXAxis: boolean;
  syncId: number;
  tooltip: React.ElementType;
  tooltipLabelFormatter: LabelFormatter | undefined;
  tooltipValueFormatter: TooltipFormatter | undefined;
  xAxisKey: string;
  xAxisRefLines: ReferenceLineProps[];
  xTickFormatter: TickFormatterFunction | undefined;
  yAxisRefLines: SPAxisRefLineProp[];
  yLabel: string;
  yScale: RechartsFunction | ScaleType | undefined;
  yTickFormatter: TickFormatterFunction | undefined;
};

const AreaChart = (props: SPAreaChartProps) => {
  const renderAreas = () => {
    const { areas = [] } = props;

    const tempAreas = areas.map((area: $TODOFIXME, index: number) => {
      const areaProps = {
        strokeWidth: 2,
        animationDuration: 400,
        activeDot: false,
        dot: false,
        type: 'linear',
        ...area
      };
      return <Area key={`area-${index}`} {...areaProps} />;
    });
    return tempAreas;
  };

  const {
    data,
    height,
    syncId,
    showTooltip,
    xTickFormatter = identity,
    yTickFormatter = identity,
    yScale = 'linear',
    tooltipLabelFormatter = identity,
    tooltipValueFormatter = identity,
    xAxisKey = 'ts',
    showXAxis,
    defs,
    tooltip: CustomTooltip,
    xAxisRefLines,
    yAxisRefLines,
    yLabel
  } = props;

  return (
    <div className={styles.AreaChart}>
      <ResponsiveContainer width="99%" height={height}>
        <ComposedChart syncId={syncId} barCategoryGap="3%" data={data}>
          <Bar key="noKey" dataKey="noKey" background={{ fill: tokens.color_gray_200 }} />
          <XAxis
            axisLine={false}
            dataKey={xAxisKey}
            height={30}
            hide={!showXAxis}
            interval="preserveStartEnd"
            tickFormatter={xTickFormatter}
            tickLine={false}
          />
          <YAxis
            axisLine={false}
            domain={['dataMin', (dataMax) => Math.max(dataMax, ...yAxisRefLines.map(({ y }) => y))]}
            interval="preserveStartEnd"
            padding={{ top: 8, bottom: 0 }}
            scale={yScale}
            tickFormatter={yTickFormatter}
            tickLine={false}
            width={60}
          />
          <Tooltip
            cursor={<Cursor data={data} />}
            content={CustomTooltip ? <CustomTooltip showTooltip={showTooltip} /> : undefined}
            wrapperStyle={{ zIndex: tokens.zIndex_overlay }}
            isAnimationActive={false}
            itemSorter={orderDesc}
            labelFormatter={tooltipLabelFormatter}
            formatter={tooltipValueFormatter}
          />
          {xAxisRefLines &&
            xAxisRefLines.length &&
            _.map(xAxisRefLines, (xAxisRefLine, index) => (
              <ReferenceLine
                key={`x-${index}`}
                x={xAxisRefLine.x}
                shapeRendering="crispEdges"
                stroke={xAxisRefLine.stroke}
                strokeWidth={xAxisRefLine.strokeWidth}
                strokeDasharray={xAxisRefLine.strokeDasharray}
                label={xAxisRefLine.label}
              />
            ))}
          {yAxisRefLines.length &&
            _.map(yAxisRefLines, (yAxisRefLine, index) => (
              <ReferenceLine
                key={`y-${index}`}
                y={yAxisRefLine.y}
                shapeRendering="crispEdges"
                strokeWidth={yAxisRefLine.strokeWidth}
                strokeDasharray={yAxisRefLine.strokeDasharray}
                stroke={yAxisRefLine.stroke}
              />
            ))}
          {defs ? defs : null}
          {/* The renderAreas function outputs a component that is rendered, so it does not need to be wrapped in a JSXElement */}
          {/* eslint-disable-next-line local/restrict-translatable-text */}
          {renderAreas()}
        </ComposedChart>
      </ResponsiveContainer>
      <span className="sp-linechart-yLabel">{yLabel}</span>
    </div>
  );
};

export default React.memo(AreaChart);
