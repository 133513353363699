import _ from 'lodash';
import React from 'react';
import { Field } from 'redux-form';
import { FieldSet, SelectWrapper, TextFieldWrapper } from 'src/components';
import { Button, Error, Expandable, Label, Panel, Stack } from 'src/components/matchbox';
import { SubduedText } from 'src/components/text';
import { Form } from 'src/components/tracking';
import { maxLength, required } from 'src/helpers/validation';
import {
  METRICS,
  NOTIFICATION_CHANNELS,
  NOTIFICATION_CHANNEL_DATA,
  REALTIME_FILTERS
} from '../constants/formConstants';
import { getFormSpec } from '../helpers/alertForm';
import withAlertForm from './AlertForm.container';
import EvaluatorFields from './fields/EvaluatorFields';
import FilterFields from './fields/FilterFields';
import SubaccountField from './fields/SubaccountsField';

const metricOptions = [
  { value: '', label: 'Select Metric', disabled: true },
  ...Object.keys(METRICS).map((key) => ({ label: METRICS[key], value: key }))
];

export const AlertForm = (props) => {
  const {
    change,
    featureFlaggedAlerts,
    handleSubmit,
    hasSubaccounts,
    formErrors,
    formMeta,
    initialValues,
    isNewAlert,
    isDuplicate,
    metric,
    pristine,
    submitting
  } = props;

  const resetFormValues = (event) => {
    const formSpec = getFormSpec(event.target.value);
    const { defaultFieldValues, defaultRecommendedValue } = formSpec;
    REALTIME_FILTERS.forEach((filter) => {
      change(filter, []);
    });
    change('single_filter', { filter_type: 'none', filter_values: [] });
    defaultFieldValues.forEach(({ fieldName, fieldValue }) => {
      change(fieldName, fieldValue);
    });

    if (defaultRecommendedValue && isNewAlert && !isDuplicate) {
      change('value', defaultRecommendedValue);
    }
  };

  const renderNotificationChannels = () => {
    const notificationChannels = NOTIFICATION_CHANNELS.map((channel) => (
      <Expandable
        icon={NOTIFICATION_CHANNEL_DATA[channel].icon}
        title={_.upperFirst(channel)}
        id={channel}
        subtitle={NOTIFICATION_CHANNEL_DATA[channel].subtitle}
        key={channel}
        my="300"
      >
        <Field
          name={channel}
          component={TextFieldWrapper}
          disabled={submitting}
          data-track={true}
          {...NOTIFICATION_CHANNEL_DATA[channel].fieldProps}
        />
      </Expandable>
    ));
    return <Stack space="100">{notificationChannels}</Stack>;
  };

  const isNotificationChannelsEmpty = (formMeta, formErrors) =>
    NOTIFICATION_CHANNELS.some(
      (channel) =>
        formMeta[channel] &&
        formMeta[channel].touched &&
        formErrors[channel] === 'At least one notification channel must not be empty'
    );

  const renderAlertForm = () => {
    const submitText = submitting ? 'Submitting...' : isNewAlert ? 'Create Alert' : 'Update Alert';
    const isSubmitDisabled = (pristine && !isDuplicate) || submitting; //Allows user to create the same alert if if's a duplicate
    const formSpec = getFormSpec(metric);
    const channelsError = isNotificationChannelsEmpty(formMeta, formErrors);

    const visibleMetricOptions = metricOptions.filter((option) => {
      // show all metrics when feature flag is not defined
      if (!featureFlaggedAlerts.hasOwnProperty(option.value)) {
        return true;
      }

      // hide metric on create form when flag is disabled
      if (isNewAlert && !isDuplicate && !featureFlaggedAlerts[option.value]) {
        return false;
      }

      // hide metric on edit and duplicate forms when metric is a flagged metric or flag is disabled
      if (
        (!isNewAlert || isDuplicate) &&
        initialValues.metric !== option.value &&
        !featureFlaggedAlerts[option.value]
      ) {
        return false;
      }

      return true;
    });

    return (
      <Form onSubmit={handleSubmit} id={isNewAlert ? 'alert-create-form' : 'alert-update-form'}>
        <Panel>
          <Panel.Section>
            <Stack>
              <Field
                name="name"
                id="alert-name"
                label="Alert Name"
                component={TextFieldWrapper}
                disabled={submitting}
                validate={[required, maxLength(50)]}
                data-track={true}
              />

              <Field
                name="metric"
                id="alert-metric"
                label="Alert Metric"
                component={SelectWrapper}
                options={visibleMetricOptions}
                onChange={resetFormValues}
                validate={required}
                disabled={submitting || !isNewAlert}
                data-track={true}
              />

              {metric !== '' && !formSpec.hideEvaluator && (
                <EvaluatorFields
                  key={metric}
                  disabled={submitting}
                  shouldUpdateRecommendation={isNewAlert && !isDuplicate}
                  isNewAlert={isNewAlert}
                />
              )}
            </Stack>
          </Panel.Section>

          {formSpec.hasFilters && (
            <Panel.Section>
              <FieldSet data-id="alert-filters" legend="Filtered by">
                <Stack>
                  <SubduedText>Add up to 10 filters to your alert.</SubduedText>

                  {!formSpec.hideSubaccountFilter && hasSubaccounts && (
                    <SubaccountField disabled={submitting} />
                  )}

                  <FilterFields disabled={submitting} />
                </Stack>
              </FieldSet>
            </Panel.Section>
          )}

          <Panel.Section>
            <Label as="h3">Notify Me</Label>

            {channelsError && (
              <Error wrapper="div" error="At least one notification channel must be not empty" />
            )}

            {renderNotificationChannels()}
          </Panel.Section>

          <Panel.Section>
            <Button variant="primary" type="submit" disabled={isSubmitDisabled}>
              {submitText}
            </Button>
          </Panel.Section>
        </Panel>
      </Form>
    );
  };

  return <>{renderAlertForm()}</>;
};

AlertForm.defaultProps = { metric: '' };

export default withAlertForm(AlertForm);
