import React, { useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { dehydrateFilters } from '@sparkpost/report-builder/helpers/metrics';
import { showAlert } from 'src/actions/globalAlert';
import { createAlert, getAlert, updateAlert } from 'src/helpers/api/alerts';
import { convertDaysToNumbers, convertTo24HourFormat } from 'src/helpers/hour';
import { useSparkPostMutation, useSparkPostQuery } from 'src/hooks';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import { useFillAlertForm } from '../useFillAlertForm';

import _ from 'lodash';
import { useRouteMatch } from 'react-router-dom';
import { ALERT_TYPES } from '../../constants/alertTypes';
import { getAlertTypeID } from '../../helpers/alertTypes';
import { useAlertForm } from '../useAlertForm';
import AnalyticsSetup from './AnalyticsSetup';
import BlocklistSetup from './BlocklistSetup';
import HealthScoreSetup from './HealthscoreSetup';
import UsageSetup from './UsageSetup';

const serializeTargetsToAPIFormat = (notificationData) => {
  const targets = [];
  if (notificationData.isEmailChecked && notificationData.emails.length > 0) {
    notificationData.emails.forEach((email) => {
      targets.push({ target_type: 'email', target: email });
    });
  }
  if (notificationData.isSlackChecked) {
    targets.push({ target_type: 'slack', target: notificationData.slack });
  }
  if (notificationData.isWebhookChecked) {
    targets.push({ target_type: 'webhook', target: notificationData.webhook });
  }
  return targets;
};

export default function SetupSelector({ goToTypeSelection }) {
  const {
    state: { type, accountData, notificationData, alertData, conditionData, metricData }
  } = useAlertForm();
  const { state: filteringState } = useAnalyticsReportContext();
  const { dispatch } = useAlertForm();
  const history = useHistory();
  const dispatchRedux = useDispatch();

  const isDuplicatingAlert = !!useRouteMatch('/alerts/duplicate/:id');
  const isEditingAlert = !!useRouteMatch('/alerts/edit/:id');
  const { id } = useParams();
  const { handleFillAlertForm } = useFillAlertForm();
  const foundAlert = useSparkPostQuery(() => getAlert({ id }), {
    enabled: isDuplicatingAlert || isEditingAlert
  });

  useEffect(() => {
    if ((isDuplicatingAlert || isEditingAlert) && foundAlert.isSuccess) {
      handleFillAlertForm(foundAlert.data, isDuplicatingAlert);
    }
  }, [
    id,
    foundAlert.data,
    foundAlert.isSuccess,
    handleFillAlertForm,
    isDuplicatingAlert,
    isEditingAlert
  ]);

  const getMetric = (alertType, metricData) => {
    switch (alertType) {
      case ALERT_TYPES.metrics:
        return metricData?.selectedMetric?.key;
      default:
        return undefined;
    }
  };

  const getAlertFilters = (alertType, filteringState) => {
    switch (alertType) {
      case ALERT_TYPES.blocklistings:
        const blocklistFilters = _.omit(filteringState.blocklistFilters, ['errors']);
        return _.isEmpty(blocklistFilters) ? undefined : blocklistFilters;
      case ALERT_TYPES.health_score:
        const healthScoreFilters = _.omit(filteringState.healthScoreFilters, ['errors']);
        return _.isEmpty(healthScoreFilters) ? undefined : healthScoreFilters;
      case ALERT_TYPES.metrics:
        return filteringState.filters.length > 0
          ? { groupings: dehydrateFilters(filteringState.filters) }
          : undefined;
      default:
        return undefined;
    }
  };

  const getAlertConditions = (alertType, conditionData) => {
    switch (alertType) {
      case ALERT_TYPES.blocklistings:
        return undefined;
      case ALERT_TYPES.health_score:
        return {
          operator: conditionData.condition,
          value: conditionData.alertThreshold,
          source: conditionData.source
        };
      default:
        return {
          operator: conditionData.condition,
          value: conditionData.alertThreshold,
          precision: conditionData.precision.toLowerCase()
        };
    }
  };

  const getHealthScoreSubAccounts = (filteringState) => {
    const { subaccounts: rawSubaccounts, any_subaccount } = filteringState.healthScoreSubaccounts;
    if (!rawSubaccounts && !any_subaccount) return {};
    const subaccounts = rawSubaccounts || [];
    return {
      subaccounts: subaccounts.map((item) => {
        if (_.isNumber(item)) return item;
        return item.id;
      }),
      any_subaccount
    };
  };

  const alertMutation = useSparkPostMutation(
    () => {
      const targets = serializeTargetsToAPIFormat(notificationData);
      const updateAlertFuncFilledWithId = (data) => updateAlert(id, data);
      const apiFunction = isEditingAlert ? updateAlertFuncFilledWithId : createAlert;
      const metric = getMetric(getAlertTypeID(type), metricData);
      const filters = getAlertFilters(getAlertTypeID(type), filteringState);
      const conditions = getAlertConditions(getAlertTypeID(type), conditionData);
      const healthScoreSubaccounts = getHealthScoreSubAccounts(filteringState);
      const numericDays = convertDaysToNumbers(notificationData.time.days);
      return apiFunction({
        name: alertData.name,
        severity: alertData.severity,
        status: alertData.status,
        alert_type: getAlertTypeID(type),
        subaccount:
          accountData.assignment === 'subaccount' ? accountData.subaccount?.id : undefined,
        alert_configuration: {
          filters,
          metric,
          conditions,
          ...healthScoreSubaccounts,
          group_by: filteringState.groupBy,
          notification_schedule:
            notificationData.time.range === 'defined'
              ? {
                  days: numericDays,
                  timeframe: {
                    start: convertTo24HourFormat(
                      notificationData.time.startTime,
                      notificationData.time.startPeriod
                    ),
                    end: convertTo24HourFormat(
                      notificationData.time.endTime,
                      notificationData.time.endPeriod
                    ),
                    time_zone: notificationData.time.timeZone
                  }
                }
              : {
                  days: [0, 1, 2, 3, 4, 5, 6],
                  timeframe: {
                    start: '00:00:00',
                    end: '23:59:59',
                    time_zone: 'UTC'
                  }
                }
        },
        targets
      });
    },
    {
      onSuccess: () => {
        dispatch({ type: 'CLEAN_UP' });
        dispatchRedux(showAlert({ type: 'success', message: `Alert created successfully` }));
        history.push('/alerts');
      }
    }
  );

  const SetupPage = useMemo(() => {
    switch (type) {
      case 'sending_limit_alert':
        return UsageSetup;
      case 'blocklistings':
        return BlocklistSetup;
      case 'health_score':
        return HealthScoreSetup;
      default:
        return AnalyticsSetup;
    }
  }, [type]);

  return (
    <SetupPage
      goToTypeSelection={goToTypeSelection}
      isDuplicatingAlert={isDuplicatingAlert}
      isEditingAlert={isEditingAlert}
      alertMutation={alertMutation}
    />
  );
}
