import { UseAnalyticsReportContext } from './AnalyticsReportContext.types';

export enum ReportOptionsDrawerTabEnum {
  METRICS,
  FILTERS,
  COMPARE
}

export type ReportOptionsDrawerProps = {
  drawerTab: ReportOptionsDrawerTabEnum;
  getDrawerProps: (props?: { [k: string]: unknown }) => {
    [key: string]: unknown;
    id: string;
    onClose: () => void;
    open: boolean;
  };
  handleSubmitForAllTabs: () => void;
  processedMetrics: UseAnalyticsReportContext['selectors']['selectSummaryMetricsProcessed'];
};
