import { useEffect } from 'react';
import { connect } from 'react-redux';
import getConfig from 'src/helpers/getConfig';
import { rudderstackIdentify, RUDDERSTACK_TRAITS } from 'src/helpers/rudderstack';
import { usernameSelector } from 'src/selectors/currentUser';

interface RudderStackIdentifyProps {
  accessControlReady?: boolean;
}

export const RudderStackIdentify = ({
  accessControlReady,
  ...traits
}: RudderStackIdentifyProps): null => {
  // Any time a trait changes, inform RudderStack via "IDENTIFY" event
  useEffect(() => {
    if (accessControlReady) {
      rudderstackIdentify({
        ...traits,
        tenant: getConfig('tenantId')
      });
    }
  }, [accessControlReady, traits]);

  return null;
};

const mapStateToProps = (state: $TODOFIXME): $TODOFIXME => ({
  accessControlReady: state.accessControlReady,
  [RUDDERSTACK_TRAITS.CUSTOMER_ID]: state.account.customer_id,
  [RUDDERSTACK_TRAITS.USER_ID]: usernameSelector(state),
  [RUDDERSTACK_TRAITS.EMAIL]: state.currentUser.email,
  [RUDDERSTACK_TRAITS.CREATED_AT]: state.currentUser.created,
  [RUDDERSTACK_TRAITS.SERVICE_LEVEL]: state.account.service_level,
  [RUDDERSTACK_TRAITS.PLAN]: state.account?.subscription?.code,
  [RUDDERSTACK_TRAITS.USER_ROLE]: state.currentUser.access_level,
  [RUDDERSTACK_TRAITS.COMPANY]: state.account.company_name,
  [RUDDERSTACK_TRAITS.FIRST_NAME]: state.currentUser.first_name,
  [RUDDERSTACK_TRAITS.SSO_ENABLED]: Boolean(state.currentUser.is_sso),
  [RUDDERSTACK_TRAITS.LAST_NAME]: state.currentUser.last_name,
  [RUDDERSTACK_TRAITS.TFA_ENABLED]: Boolean(state.currentUser.tfa_enabled)
});

export default connect(mapStateToProps, {})(RudderStackIdentify);
