import React from 'react';
import { CheckboxWithUpgradeLink } from 'src/components';
import { LINKS } from 'src/constants';

const CheckboxWithLink =
  ({
    hasSendingProduct,
    hasD12yProduct,
    hasSendingMetrics,
    hasInboxTrackingMetrics,
    isCampaignSubjectGroupBy
  }) =>
  (checkboxProps) => {
    const isInbox = checkboxProps.name === 'seed' || checkboxProps.name === 'panel';
    const isSending = checkboxProps.name === 'sending';
    const link = isSending ? '/account/billing/plan' : LINKS.DELIVERABILITY_ANALYTICS_DEMO;
    const showLink = (isSending && !hasSendingProduct) || (isInbox && !hasD12yProduct);
    const disabled =
      (isSending && (!hasSendingProduct || !hasSendingMetrics)) ||
      (isSending && isCampaignSubjectGroupBy) ||
      (isInbox && (!hasD12yProduct || !hasInboxTrackingMetrics));

    return (
      <CheckboxWithUpgradeLink
        checkboxProps={checkboxProps}
        link={link}
        showLink={showLink}
        disabled={disabled}
        external={isInbox}
      />
    );
  };

export default CheckboxWithLink;
