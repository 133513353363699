import React from 'react';
import { Box, Inline, Tag, Text } from 'src/components/matchbox';
import { Comparison, Emphasized, TranslatableText } from 'src/components/text';
import { AdvancedFiltersType } from 'src/pages/analyticsReport/context/useFilters';
import { toActiveFilterTagGroups, toIterableGroupings } from 'src/pages/analyticsReport/helpers';

type ActiveFiltersProps = {
  filters: AdvancedFiltersType;
  handleFilterRemove: ({
    groupingIndex,
    filterIndex,
    valueIndex
  }: {
    filterIndex: number;
    groupingIndex: number;
    valueIndex: number;
  }) => void;
};

export default function ActiveFilters({ filters, handleFilterRemove }: ActiveFiltersProps) {
  const iterableGroupings = toIterableGroupings(filters);
  const groupings = toActiveFilterTagGroups(iterableGroupings);

  // TODO: Once `Inline` supports the `data-id` prop, extra <div> elements with `data-id` will no longer needed.
  // Matchbox issue: https://github.com/SparkPost/matchbox/issues/661

  return (
    <div data-id="active-filter-tags">
      <Inline space="200">
        {groupings.map((grouping, groupingIndex) => {
          return (
            <div data-id="active-filter-group" key={`active-filter-tag-${groupingIndex}`}>
              <Inline key={`grouping-${groupingIndex}`} space="0">
                <span aria-hidden="true">[</span>
                {grouping.filters.map((filter, filterIndex) => {
                  return (
                    <Box
                      key={`filter-${groupingIndex}-${filterIndex}`}
                      paddingY="100"
                      paddingX="100"
                      data-id="active-filter"
                    >
                      <Inline space="200">
                        <Text fontSize="200" as="span">
                          {filter.label}
                        </Text>

                        <Text fontWeight="500" as="span" fontSize="200">
                          <Emphasized>{filter.compareBy}</Emphasized>
                        </Text>

                        {filter.values &&
                          filter.values.map((rawValue, valueIndex) => {
                            // Some values are objects when returned from the typeahead, others are just strings
                            const value = typeof rawValue === 'object' ? rawValue.value : rawValue;
                            // The remove method is not always present - conditionally rendering the remove button on the relevant `<Tag />`
                            const onRemoveFn = handleFilterRemove
                              ? () => handleFilterRemove({ groupingIndex, filterIndex, valueIndex })
                              : undefined;

                            return (
                              <Tag
                                key={`tag-${groupingIndex}-${filterIndex}-${valueIndex}`}
                                onRemove={onRemoveFn}
                              >
                                <TranslatableText>{value}</TranslatableText>
                              </Tag>
                            );
                          })}

                        {filter.hasComparisonBetweenFilters && grouping.type ? (
                          <Box marginX="200">
                            <Comparison>{grouping.type}</Comparison>
                          </Box>
                        ) : null}
                      </Inline>
                    </Box>
                  );
                })}
                <span aria-hidden="true">]</span>

                {grouping.hasAndBetweenGroups ? (
                  <Box marginX="200">
                    <Comparison>And</Comparison>
                  </Box>
                ) : null}
              </Inline>
            </div>
          );
        })}
      </Inline>
    </div>
  );
}
