import _ from 'lodash';
import { change } from 'redux-form';
import { formName } from 'src/components/support/components/SupportForm';
import getConfig from 'src/helpers/getConfig';
import { selectSupportIssues } from 'src/selectors/support';

export function closeSupportPanel() {
  return {
    type: 'CLOSE_SUPPORT_PANEL'
  };
}

/**
 * Forces the support panel open rather than blindly toggling, good
 * for places where we know we want to open it and the function might
 * run multiple times (cDU etc) so a toggle would open then close
 *
 * @param {Object} options optional options to pass to reducer
 * @param {String} options.view The view you want the panel to load in, currently only 'ticket' works
 *
 * @example
 * openSupportPanel() // opens in default mode
 * openSupportPanel({ view: 'ticket' }) // opens in ticket mode
 */
export function openSupportPanel(options) {
  return {
    type: 'OPEN_SUPPORT_PANEL',
    payload: options
  };
}

/**
 * Trigger a webhook to create a support ticket on Inbox - Bird
 *
 * @param {Object} WEBHOOK_URL
 * @param {Object} data
 * @param {String} tenantId
 * @param {String} issueType
 *
 */
function triggerWebhook(WEBHOOK_URL, data, tenantId, issueType) {
  return fetch(WEBHOOK_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...data,
      userId: data.userId,
      email: data.email,
      accountId: data.accountId,
      account: data.account,
      product: data.subject,
      details: data.message,
      username: data.username,
      tenantId: tenantId,
      issue_type: issueType
    })
  }).then((response) => {
    if (!response.ok) {
      throw new Error('There was a problem with the createTicket request');
    }
    return response.json();
  });
}

/**
 * Creates a support ticket with a subject, message, and optional file attachment
 *
 * @param {Object} data
 * @param {String} data.issueType
 * @param {String} data.subject
 * @param {String} data.message
 * @param {Object} data.attachment
 * @param {String} data.attachment.filename
 * @param {Base64 String} data.attachment.content
 *
 */
export function createTicket({ issueType, ...data }) {
  const WEBHOOK_URL =
    'https://capture.eu-west-1.nest.messagebird.com/webhooks/955ca7c9-e0b3-4e7e-8212-e76ba2f7ca79/07d0b74a-0dc9-46b5-8a0c-fb00ffacf46a';

  const GTM_WEBHOOK_URL =
    'https://capture.eu-west-1.nest.messagebird.com/webhooks/5368a09e-c95c-4e34-a66d-b2aac20f0953/bfb4f4f8-66d1-4128-a95c-00b3a8076075';

  const tenantId = getConfig('tenantId');

  return Promise.all([
    triggerWebhook(WEBHOOK_URL, data, tenantId, issueType),
    triggerWebhook(GTM_WEBHOOK_URL, data, tenantId, issueType)
  ]);
}

// Opens support ticket form and fills values if provided
export function openSupportTicketForm({ issueId, message } = {}) {
  return (dispatch, getState) => {
    // this action assumes access control is ready when called, because
    // selectSupportIssues uses AC state to decide on what issues are visible
    const visibleIssues = selectSupportIssues(getState());
    const issue = _.find(visibleIssues, { id: issueId });

    // the support panel must be open before you can hydrate it
    dispatch(openSupportPanel({ view: 'ticket' }));

    if (issue) {
      dispatch(change(formName, 'issueId', issueId));
    }

    if (message) {
      dispatch(change(formName, 'message', message));
    }
  };
}
