import _ from 'lodash';
/**
 * @description Returns subaccount header object for use with API requests
 * @param {number} subaccountId - relevant subaccount ID
 * @returns {object} headers handed off to axios to make a network request. Intended to be empty when no subaccount ID is present
 */
export function getSubaccountHeader(subaccountId) {
  // For primary accounts, the subaccount header is not required.
  // See: https://developers.sparkpost.com/api/seed-list/#:~:text=A%20primary%20or%20subaccount%20retrieving%20its%20own%20seed%20list%20does%20not%20need%20to%20explicitly%20set%20this.
  let headers = {};

  // `0` is a valid value for a subaccount ID (a falsy value), so a more specific condition is needed
  if (!_.isNil(subaccountId)) {
    headers['X-MSYS-SUBACCOUNT'] = subaccountId;
  }

  return headers;
}
