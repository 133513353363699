import React from 'react';
import { useSelector } from 'react-redux';
import { getTemplates } from 'src/helpers/api/templates';
import { useSparkPostQuery } from 'src/hooks';
import { PageLink, SubduedLink, ExternalLink } from 'src/components/links';
import { TranslatableText } from 'src/components/text';
import { Stack } from 'src/components/matchbox';
import { Settings } from '@sparkpost/matchbox-icons';
import { hasGrants } from 'src/helpers/conditions';

export default function TemplateSubTitle({ id }) {
  const canModifyTemplates = useSelector((state) => hasGrants('templates/modify')(state));
  const { status, data } = useSparkPostQuery(() => getTemplates());
  let templateSettingsLink = `/templates/edit/${id}/published/settings`;
  let template;
  if (status === 'success') {
    template = data && data.find((temp) => temp.id === id);
    if (template?.subaccount_id) templateSettingsLink += `?subaccount=${template.subaccount_id}`;
  }

  return (
    <Stack>
      <SubduedLink
        as={ExternalLink}
        to="https://www.sparkpost.com/docs/getting-started/creating-template/"
      >
        <TranslatableText>Learn more about Templates</TranslatableText>
      </SubduedLink>
      {canModifyTemplates && (
        <PageLink to={templateSettingsLink} fontSize="200">
          <span>Template Settings&nbsp;</span>
          <Settings />
        </PageLink>
      )}
    </Stack>
  );
}
