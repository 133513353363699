import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ComboBox from 'src/components/ComboBox';
import { useMultipleSelection, useCombobox } from 'downshift';
import sortMatch from 'src/helpers/sortMatch';

const returnSelf = (a) => a;

export const ComboBoxTypeahead = ({
  value,
  prefix,
  id,
  error,
  name,
  label,
  onChange,
  results,
  itemToString = returnSelf,
  maxNumberOfResults = 100,
  'data-track': dataTrack,
  'data-id': dataId,
  placeholder,
  disabled
}) => {
  const [inputValue, setInputValue] = useState('');

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
    setSelectedItems
  } = useMultipleSelection({ initialSelectedItems: value });

  useEffect(() => {
    setSelectedItems(value);
  }, [setSelectedItems, value]);

  // TODO: Asynchronously handle this function of filtering down the results (+ loading state). That way we don't need a separate version for this.
  const getFilteredItems = useCallback(() => {
    const unselectedItems = results.filter((item) => !selectedItems.includes(item));
    const stringFilteredItems = inputValue
      ? sortMatch(unselectedItems, inputValue, itemToString)
      : unselectedItems;
    const filteredResults = stringFilteredItems.slice(0, maxNumberOfResults);

    return filteredResults;
  }, [inputValue, itemToString, maxNumberOfResults, results, selectedItems]);

  const {
    isOpen,
    openMenu,
    getMenuProps,
    getLabelProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    selectItem,
    highlightedIndex
  } = useCombobox({
    inputValue,
    items: getFilteredItems(),
    itemToString,
    id,
    onStateChange: ({ inputValue, type, selectedItem }) => {
      // Taken from downshift example

      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue('');
            addSelectedItem(selectedItem);
            selectItem(null);
          }

          break;
        default:
          break;
      }
    }
  });

  const menuItems = useMemo(() => {
    function toItemWithProps(item, index) {
      return getItemProps({
        item,
        index,
        content: itemToString(item),
        highlighted: highlightedIndex === index
      });
    }
    return getFilteredItems().map(toItemWithProps);
  }, [getFilteredItems, getItemProps, highlightedIndex, itemToString]);

  useEffect(() => {
    onChange(selectedItems);
  }, [onChange, selectedItems]);

  return (
    <ComboBox {...getComboboxProps()}>
      <ComboBox.Label {...getLabelProps()}>{label}</ComboBox.Label>
      <ComboBox.Input
        {...getInputProps({
          disabled,
          error: error && !isOpen ? error : undefined,
          ...getDropdownProps({
            preventKeyAction: isOpen
          }),
          name,
          prefix,
          placeholder
        })}
        onClick={openMenu}
        data-id={dataId}
      >
        <ComboBox.Menu {...getMenuProps({ items: menuItems, isOpen })} />
      </ComboBox.Input>
      <ComboBox.Items>
        {selectedItems.map((item, index) => {
          return (
            <ComboBox.Item
              key={`combobox-selected-item-${index}`}
              onRemove={() => removeSelectedItem(item)}
              {...getSelectedItemProps({ item, index })}
            >
              {itemToString(item)}
            </ComboBox.Item>
          );
        })}
      </ComboBox.Items>
      <input
        type="hidden"
        label={label}
        id={id}
        name={name}
        data-track={dataTrack}
        value={value.map((x) => itemToString(x)).join(',')}
      ></input>
    </ComboBox>
  );
};
