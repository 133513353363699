import React from 'react';
import Actions, { ResourceDetailsActions } from '../Actions';
import {
  content,
  contentTotalEngagement,
  contentGood
} from '../../constants/engagementRateByCohortContent';

const EngagementRateByCohortActions = ({
  engagementByCohort,
  recencyByCohort,
  date,
  facet,
  facetId,
  inSubtitle
}) => {
  let actions = [];

  //First check total engagement because it needs to pass along facet info to generate a link
  if (contentTotalEngagement.condition(engagementByCohort)) {
    actions.push({
      content: contentTotalEngagement.content,
      type: contentTotalEngagement.type,
      link: contentTotalEngagement.link(facet, facetId),
      internal: true,
      linkText: 'View Health Score'
    });
  }

  content.forEach(({ condition, ...rest }) => {
    if (condition({ ...engagementByCohort, ...recencyByCohort })) {
      actions.push(rest);
    }
  });
  actions = actions.slice(0, 3);

  //If there no adverse actions, show the good job message.
  if (actions.length === 0) {
    actions.push(contentGood);
  }

  if (inSubtitle) {
    return (
      <ResourceDetailsActions actions={actions} empty={engagementByCohort.p_total_eng === null} />
    );
  }

  return <Actions actions={actions} date={date} empty={engagementByCohort.p_total_eng === null} />;
};

export default EngagementRateByCohortActions;
