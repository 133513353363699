import React from 'react';
import { formatPercent } from 'src/helpers/units';
import { TranslatableText } from '../text';

const Percent = ({ value, roundTo = 2 }: { roundTo: number; value: number }): JSX.Element => (
  <TranslatableText>{formatPercent(value, roundTo)}</TranslatableText>
);
Percent.displayName = 'Percent';

export { Percent };
