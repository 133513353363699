import { useReducer } from 'react';
import { getComputeKeysOrMainKeyFromMetrics } from '@sparkpost/report-builder/helpers/metrics';
import { list } from '@sparkpost/report-builder/config';
import { apiParams } from '../constants/resourceDetails';

const initialState = {
  selectBreakDownType: '',
  topDomainsOnly: true,
  apiParam: '',
  metrics: [].join(','),
  columnsToDisplay: [],
  defaultColumnToSortBy: ''
};

export function useDeliverabilityBreakdown() {
  const deliverabilityColumns = [
    {
      columnName: 'Inbox Folder Rate',
      type: 'metric'
    },
    {
      columnName: 'Spam Folder Rate',
      type: 'metric'
    }
  ];

  const sendingAndDeliverabilityColumns = [
    {
      columnName: 'Sent',
      type: 'metric'
    },
    {
      columnName: 'Accepted Rate',
      type: 'metric'
    },
    {
      columnName: 'Inbox Folder Rate',
      type: 'metric'
    },
    {
      columnName: 'Spam Folder Rate',
      type: 'metric'
    }
  ];

  const deliverabilityMetrics = getComputeKeysOrMainKeyFromMetrics(
    ['Inbox Folder Rate', 'Spam Folder Rate'].map((x) => list.find((item) => item.label === x)),
    true
  ).join(',');

  const sendingAndDeliverabilityMetrics = getComputeKeysOrMainKeyFromMetrics(
    ['Sent', 'Accepted Rate', 'Inbox Folder Rate', 'Spam Folder Rate'].map((x) =>
      list.find((item) => item.label === x)
    ),
    true
  ).join(',');

  const reducer = (state, { pageType, type }) => {
    const commonColumns =
      pageType === 'subject-campaign' ? deliverabilityColumns : sendingAndDeliverabilityColumns;

    const commonMetrics =
      pageType === 'subject-campaign' ? deliverabilityMetrics : sendingAndDeliverabilityMetrics;
    switch (type) {
      case 'CAMPAIGN_SELECTED':
        return {
          ...state,
          defaultColumnToSortBy: commonColumns[0].columnName,
          selectBreakDownType: 'campaign',
          apiParam: apiParams[pageType],
          metrics: commonMetrics,
          columnsToDisplay: [
            {
              columnName: 'Campaign (ID)',
              type: 'resource'
            },
            ...commonColumns
          ]
        };

      case 'SUBJECT_CAMPAIGN_SELECTED':
        return {
          ...state,
          defaultColumnToSortBy: deliverabilityColumns[0].columnName,
          selectBreakDownType: 'subject-campaign',
          apiParam: apiParams[pageType],
          metrics: deliverabilityMetrics,
          columnsToDisplay: [
            {
              columnName: 'Campaign (Subject Line)',
              type: 'resource'
            },
            ...deliverabilityColumns
          ]
        };

      case 'SUBACCOUNT_SELECTED':
        return {
          ...state,
          defaultColumnToSortBy: commonColumns[0].columnName,
          selectBreakDownType: 'subaccount',
          apiParam: apiParams[pageType],
          metrics: commonMetrics,
          columnsToDisplay: [
            {
              columnName: 'Subaccount',
              type: 'resource'
            },
            ...commonColumns
          ]
        };

      case 'IP_POOL_SELECTED':
        return {
          ...state,
          defaultColumnToSortBy: commonColumns[0].columnName,
          selectBreakDownType: 'ip-pool',
          apiParam: apiParams[pageType],
          metrics: commonMetrics,
          columnsToDisplay: [
            {
              columnName: 'IP Pool',
              type: 'resource'
            },
            ...commonColumns
          ]
        };

      case 'SENDING_IP_SELECTED':
        return {
          ...state,
          defaultColumnToSortBy: commonColumns[0].columnName,
          selectBreakDownType: 'sending-ip',
          apiParam: apiParams[pageType],
          metrics: commonMetrics,
          columnsToDisplay: [
            {
              columnName: 'Sending IP',
              type: 'resource'
            },
            {
              columnName: 'Sending IP Warm-up Status',
              type: 'tags'
            },
            ...commonColumns
          ]
        };

      case 'MAILBOX_PROVIDER_REGION_SELECTED':
        return {
          ...state,
          defaultColumnToSortBy: commonColumns[0].columnName,
          selectBreakDownType: 'mailbox-provider-region',
          apiParam: apiParams[pageType],
          metrics: commonMetrics,
          columnsToDisplay: [
            {
              columnName: 'Mailbox Provider Region',
              type: 'resource'
            },
            ...commonColumns
          ]
        };

      case 'MAILBOX_PROVIDER_SELECTED':
        return {
          ...state,
          defaultColumnToSortBy: commonColumns[0].columnName,
          selectBreakDownType: 'mailbox-provider',
          apiParam: apiParams[pageType],
          metrics: commonMetrics,
          columnsToDisplay: [
            {
              columnName: 'Mailbox Provider',
              type: 'resource'
            },
            ...commonColumns
          ]
        };

      case 'TOP_DOMAIN_NOT_SELECTED':
        return {
          ...state,
          defaultColumnToSortBy: commonColumns[0].columnName,
          selectBreakDownType: 'domain',
          topDomainsOnly: false,
          apiParam: apiParams[pageType],
          metrics: commonMetrics,
          columnsToDisplay: [
            {
              columnName: 'Recipient Domain',
              type: 'resource'
            },
            ...commonColumns
          ]
        };
      case 'DOMAIN_SELECTED':
      case 'WATCHED_DOMAIN_SELECTED':
      case 'TOP_DOMAIN_SELECTED':
        return {
          ...state,
          defaultColumnToSortBy: commonColumns[0].columnName,
          selectBreakDownType: 'watched-domain',
          topDomainsOnly: true,
          apiParam: apiParams[pageType],
          metrics: commonMetrics,
          columnsToDisplay: [
            {
              columnName: 'Recipient Domain',
              type: 'resource'
            },
            ...commonColumns
          ]
        };

      case 'SENDING_DOMAIN_SELECTED':
        return {
          ...state,
          defaultColumnToSortBy: commonColumns[0].columnName,
          selectBreakDownType: 'sending-domain',
          apiParam: apiParams[pageType],
          metrics: commonMetrics,
          columnsToDisplay: [
            {
              columnName: 'Sending Domain',
              type: 'resource'
            },
            ...commonColumns
          ]
        };

      case 'TEMPLATE_SELECTED':
        return {
          ...state,
          defaultColumnToSortBy: commonColumns[0].columnName,
          selectBreakDownType: 'template',
          apiParam: apiParams[pageType],
          metrics: commonMetrics,
          columnsToDisplay: [
            {
              columnName: 'Template',
              type: 'resource'
            },
            ...commonColumns
          ]
        };

      default:
        return initialState;
    }
  };

  return useReducer(reducer, initialState);
}
