import _ from 'lodash';
import { roundToPlaces } from '@sparkpost/report-builder/helpers/units';

export const coalesce = (...values) => values.find((value) => value !== undefined);
export const isNumber = (value) => isNaN(parseFloat(value)) === false || isFinite(value) === true;

export const roundToOnePlace = (num) => roundToPlaces(num, 1);

// Formats number count to string with commas
export const formatFullNumber = (value) => {
  if (!isNumber(value)) {
    return value;
  }

  return value.toLocaleString();
};

/**
 * @name formatPercent
 * @description Formats raw percent (between 0 and 1) to display value for percent
 * @description Will display dashes in case of invalid value (preferred invalid value should be NaN)
 * @description
 * @param {*} value - Raw percent (between 0 and 1)
 * @param {*} roundTo =
 * @returns
 */
export const formatPercent = (value, roundTo = 2) => {
  if (!isNumber(value) || value === null || isNaN(value)) {
    return `---`;
  }
  value *= 100;
  let formatted = `${roundToPlaces(value, roundTo)}%`;

  if (value < 0.01 && value > 0) {
    formatted = '< 0.01%';
  }

  if (value > 100) {
    formatted = '> 100%';
  }

  return formatted;
};

export const formatNumberPercent = (value, roundTo = 1) => {
  if (!isNumber(value)) {
    return value;
  }

  return `${roundToPlaces(value, roundTo)}%`;
};

export const formatPrecisePercent = (value) => {
  return formatNumberPercent(value, 4);
};

export const formatCurrency = (value) => `$${value.toFixed(2)}`;

export const formatMinuteString = (value) => {
  const minute = parseInt(value);
  if (!isNumber(minute) || minute > 60) {
    return value;
  }
  return minute < 10 ? '0' + minute : minute.toString(); //prepends 0 so it's 12:09 vs 12:9
};

export const formatHourString = (value) => {
  const hour = parseInt(value);
  if (!isNumber(hour)) {
    return value;
  }
  return (hour % 12 || 12).toString(); //0 is falsy so it resolves to 12.
};
