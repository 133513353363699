import React from 'react';
import { Columns as MatchboxColumns, ColumnsProps } from '@sparkpost/matchbox';

/**
 * Matchbox Columns component
 * @see https://design.sparkpost.com/components/columns/
 */
const Columns = (props: ColumnsProps): JSX.Element => <MatchboxColumns {...props} />;

export default Columns;
