import { Pager as MatchboxPager } from '@sparkpost/matchbox';

/**
 * Matchbox Pager component.
 * @see https://design.sparkpost.com/components/pager/
 */
const Pager = MatchboxPager;

Pager.displayName = 'Pager';
Pager.Next.displayName = 'Pager.Next';
Pager.Previous.displayName = 'Pager.Previous';

export default Pager;
