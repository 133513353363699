import React from 'react';

import { Drawer, ScreenReaderOnly } from 'src/components/matchbox';
import { Tabs } from 'src/components';
import MetricsForm from './MetricsForm';
import FiltersForm from './FiltersForm';
import CompareByForm from './CompareByForm';

// TYPES
import { ReportOptionsDrawerProps } from 'src/typescript';

const drawerTabs = [{ content: 'Metrics' }, { content: 'Filters' }, { content: 'Compare' }];

export function ReportOptionsDrawer({
  getDrawerProps,
  drawerTab,
  processedMetrics,
  handleSubmitForAllTabs
}: ReportOptionsDrawerProps): JSX.Element {
  return (
    <Drawer {...getDrawerProps()}>
      <Drawer.Header showCloseButton>
        <ScreenReaderOnly>Report Options</ScreenReaderOnly>
      </Drawer.Header>
      <Drawer.Content p="0">
        <Tabs defaultTabIndex={drawerTab} forceRender fitted tabs={drawerTabs}>
          <Tabs.Item>
            <MetricsForm selectedMetrics={processedMetrics} handleSubmit={handleSubmitForAllTabs} />
          </Tabs.Item>
          <Tabs.Item>
            <FiltersForm handleSubmit={handleSubmitForAllTabs} />
          </Tabs.Item>
          <Tabs.Item>
            <CompareByForm handleSubmit={handleSubmitForAllTabs} />
          </Tabs.Item>
        </Tabs>
      </Drawer.Content>
    </Drawer>
  );
}
