import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import isIP from 'validator/lib/isIP';
import isFQDN from 'validator/lib/isFQDN';
import { ApiErrorBanner, Loading, PanelLoading } from 'src/components';
import { ExternalLink, PageLink, SupportTicketLink } from 'src/components/links';
import { SubduedText } from 'src/components/text';
import {
  Box,
  Button,
  Columns,
  Column,
  Inline,
  Layout,
  Page,
  Panel,
  Stack,
  Tag
} from 'src/components/matchbox';
import { RUDDERSTACK_EVENTS } from 'src/helpers/rudderstack';
import {
  getIncident,
  listIncidentsForResource,
  listIncidentsForBlocklist,
  listHistoricalResolvedIncidents
} from 'src/actions/blocklist';
import {
  selectIncident,
  selectRelatedIncidentsForResource,
  selectRelatedIncidentsForBlocklist,
  selectHistoricalIncidents,
  selectDetailsPageError
} from 'src/selectors/blocklist';
import IncidentDetails from './components/IncidentDetails';
import RelatedIncidents from './components/RelatedIncidents';
import { MailboxProviderImpacts } from './components/MailboxProviderImpacts';
import { INCIDENT_IMPACTS_CONFIG } from './constants';
import { TranslatableText } from 'src/components/text';
import track from 'src/helpers/track';

export const IncidentDetailsPage = ({
  id,
  monitorId,
  error,
  loading,
  historicalIncidents,
  incident,
  incidentsForBlocklist,
  incidentsForResource,
  getIncident,
  listIncidentsForResource,
  listIncidentsForBlocklist,
  listHistoricalResolvedIncidents,
  incidentsForResourcePending,
  incidentsForBlocklistPending,
  historicalIncidentsPending
}) => {
  useEffect(() => {
    getIncident(id, monitorId).then((incident) => {
      listIncidentsForResource(incident.resource);
      listIncidentsForBlocklist(incident.blocklist_name);
      listHistoricalResolvedIncidents(incident.blocklist_name, incident.monitor_id);
    });
  }, [
    getIncident,
    id,
    listIncidentsForBlocklist,
    listIncidentsForResource,
    listHistoricalResolvedIncidents,
    monitorId
  ]);

  if (loading) {
    return <Loading />;
  }

  const {
    blocklist_name = '',
    display_name,
    resource = '',
    subaccount_id,
    days_listed,
    resolved_at,
    resolved_at_timestamp,
    occurred_at,
    occurred_at_formatted,
    occurred_at_timestamp,
    recommendation = {},
    provider_impacts,
    resource_impact,
    is_estimated_impact
  } = incident || {};

  // Should always be IP or Domain, but added Resource as a failsafe.
  const resourceType = isIP(resource) ? 'IP' : isFQDN(resource) ? 'Domain' : 'Resource';
  const resourceImpact = resource_impact || 'insufficient data'; // Initializes in case of null
  const impactConfig = INCIDENT_IMPACTS_CONFIG.find(({ id }) => id === resourceImpact);

  return (
    <Page
      title="Blocklist Incident"
      breadcrumbAction={{
        content: 'Blocklist Incidents',
        to: '/signals/blocklist/incidents',
        as: PageLink
      }}
    >
      {error ? (
        <div data-id="error-banner">
          <ApiErrorBanner
            message="Sorry, we seem to have had some trouble loading your blocklist incidents."
            errorDetails={error.message}
            reload={() => {
              getIncident(id, monitorId).then((incident) => {
                listIncidentsForResource(incident.resource);
                listIncidentsForBlocklist(incident.blocklist_name);
                listHistoricalResolvedIncidents(incident.blocklist_name, incident.monitor_id);
              });
            }}
          />
        </div>
      ) : (
        <>
          <Layout>
            <Layout.Section annotated>
              <Layout.SectionTitle>Incident Details</Layout.SectionTitle>
            </Layout.Section>
            <Layout.Section>
              {historicalIncidentsPending ? (
                <PanelLoading minHeight="150px" />
              ) : (
                <IncidentDetails
                  subaccount={subaccount_id}
                  resourceName={resource}
                  blocklistName={blocklist_name}
                  listedTimestamp={occurred_at_timestamp}
                  resolvedTimestamp={resolved_at_timestamp}
                  daysListed={days_listed}
                />
              )}
            </Layout.Section>
          </Layout>

          <Layout>
            <Layout.Section annotated>
              <Layout.SectionTitle>Historic Incidents</Layout.SectionTitle>

              <SubduedText>
                Previous incidents involving
                <TranslatableText>{resource}</TranslatableText> and{' '}
                <TranslatableText>{blocklist_name}</TranslatableText>
              </SubduedText>
            </Layout.Section>

            <Layout.Section>
              <RelatedIncidents
                incident={{ ...incident, id }}
                incidents={historicalIncidents}
                loading={historicalIncidentsPending}
                name={`${resource} on ${blocklist_name}`}
                type="history"
              />
            </Layout.Section>
          </Layout>
          {/* Resolved incidents will not have a recommendation value, but we set the default to {} */}
          {Object.keys(recommendation).length > 0 && (
            <Layout>
              <Layout.Section annotated>
                <Layout.SectionTitle>Remediation Checklist</Layout.SectionTitle>

                <SubduedText>
                  There could be a few different reasons why you got listed, take a look at our
                  recommendations to get you back up and running.
                </SubduedText>
              </Layout.Section>

              <Layout.Section>
                <Panel data-id="remediation-steps">
                  <Panel.Section>
                    <Stack>
                      {(recommendation.check_list || []).map((item, count) => (
                        <Columns key={count}>
                          <Column width={1 / 100}>
                            <Box fontWeight="semibold">{count + 1}.</Box>
                          </Column>
                          <Column>{item}</Column>
                        </Columns>
                      ))}
                      <Inline>
                        {recommendation.action_text === 'Contact Support' ? (
                          <SupportTicketLink
                            as={Button}
                            variant="primary"
                            issueId="blocklisting"
                            onClick={() =>
                              track(RUDDERSTACK_EVENTS.BLOCKLIST_INCIDENT_ACTION_CLICKED)
                            }
                            message={`Listed Resource: ${resource}\n\nList: ${display_name} (${blocklist_name})\n\nListing Date: ${occurred_at_formatted}\n\nIn order to help us address this blocklisting as soon as possible, please specify the steps you have already taken to clean your list.`}
                          >
                            {recommendation.action_text}
                          </SupportTicketLink>
                        ) : (
                          <ExternalLink
                            as={Button}
                            onClick={() =>
                              track(RUDDERSTACK_EVENTS.BLOCKLIST_INCIDENT_ACTION_CLICKED)
                            }
                            to={recommendation.action_link}
                            variant="primary"
                          >
                            {recommendation?.action_text}
                          </ExternalLink>
                        )}
                        {recommendation?.info_text && (
                          <ExternalLink
                            as={Button}
                            onClick={() =>
                              track(RUDDERSTACK_EVENTS.BLOCKLIST_INCIDENT_INFO_CLICKED)
                            }
                            to={recommendation.info_url}
                            variant="secondary"
                          >
                            {recommendation?.info_text}
                          </ExternalLink>
                        )}
                      </Inline>
                    </Stack>
                  </Panel.Section>
                </Panel>
              </Layout.Section>
            </Layout>
          )}

          <Layout>
            <Layout.Section annotated>
              <Layout.SectionTitle>Impact Details</Layout.SectionTitle>
              <Stack space="300">
                <Box>
                  <Tag color={impactConfig.tagColor}>{impactConfig.friendlyName}</Tag>
                </Box>

                <SubduedText>{impactConfig.description}</SubduedText>
              </Stack>
            </Layout.Section>
            <Layout.Section>
              <MailboxProviderImpacts
                mailboxProviders={provider_impacts}
                resolvedAt={resolved_at}
                occurredAt={occurred_at}
                isEstimatedImpact={is_estimated_impact}
                daysListed={days_listed}
              />
            </Layout.Section>
          </Layout>

          <Layout>
            <Layout.Section annotated>
              <Layout.SectionTitle>
                Other Incidents for this <TranslatableText>{resourceType}</TranslatableText>
              </Layout.SectionTitle>

              <Stack space="400">
                <SubduedText>
                  Most recent incidents involving <TranslatableText>{resource}</TranslatableText>
                </SubduedText>

                <PageLink to={`/signals/blocklist/incidents?search=${resource}`}>
                  View all incidents for this <TranslatableText>{resourceType}</TranslatableText>
                </PageLink>
              </Stack>
            </Layout.Section>

            <Layout.Section>
              <RelatedIncidents
                incident={{ ...incident, id }}
                incidents={incidentsForResource}
                loading={incidentsForResourcePending}
                type="resource"
              />
            </Layout.Section>
          </Layout>

          <Layout>
            <Layout.Section annotated>
              <Layout.SectionTitle>Other Incidents for this Blocklist</Layout.SectionTitle>

              <Stack space="400">
                <SubduedText>{`Most recent incidents involving ${blocklist_name}`}</SubduedText>
                <PageLink to={`/signals/blocklist/incidents?search=${blocklist_name}`}>
                  View all incidents for this blocklist
                </PageLink>
              </Stack>
            </Layout.Section>

            <Layout.Section>
              <RelatedIncidents
                incidents={incidentsForBlocklist}
                loading={incidentsForBlocklistPending}
                type="blocklist"
              />
            </Layout.Section>
          </Layout>
        </>
      )}
    </Page>
  );
};

const mapStateToProps = (state, props) => {
  const { id, monitorId } = props.match.params;

  return {
    historicalIncidents: selectHistoricalIncidents(state),
    historicalIncidentsPending: state.blocklist.historicalIncidentsPending,
    id,
    monitorId,
    incident: selectIncident(state),
    incidentsForResource: selectRelatedIncidentsForResource(state),
    incidentsForResourcePending: state.blocklist.incidentsForResourcePending,
    incidentsForBlocklist: selectRelatedIncidentsForBlocklist(state),
    incidentsForBlocklistPending: state.blocklist.incidentsForBlocklistPending,
    error: selectDetailsPageError(state),
    loading: state.blocklist.incidentPending
  };
};
export default connect(mapStateToProps, {
  getIncident,
  listIncidentsForResource,
  listIncidentsForBlocklist,
  listHistoricalResolvedIncidents
})(IncidentDetailsPage);
