import React, { useMemo } from 'react';

// COMPONENTS
import { SidebarExpandableItem } from 'src/components/SidebarExpandableItem/SidebarExpandableItem';

// CONSTANTS
import { PRESET_REPORT_CONFIGS } from 'src/pages/analyticsReport/constants';

// HOOKS
import { useSelector } from 'react-redux';
import { useWindowSize } from 'src/hooks';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';

// HELPERS
import { parseSearch } from 'src/helpers/analyticsReport';
import { isUserUiOptionSet } from 'src/helpers/conditions/user';
import { getRecentReports, sanitizeRecentReports } from 'src/helpers/recentSavedReports';

// STYLES
import { AlternativeSidebarWrapper, Section } from '../../sidebarStyles';

// TYPES
import {
  SidebarExpandableItemAction,
  SidebarExpandableItemProps,
  UseAnalyticsReportContext
} from 'src/typescript';

type ReportsTabProps = {
  presetReports: SidebarExpandableItemProps['items'];
  reports: typeof PRESET_REPORT_CONFIGS;
  reportsLoading: boolean;
  sidebarReportsActions: (report: $TODOFIXME) => SidebarExpandableItemAction[];
};

const HEADING_HEIGHT = 434;

export const FavoriteReports = ({
  reports,
  reportsLoading,
  sidebarReportsActions
}: {
  reports: $TODOFIXME;
  reportsLoading: boolean;
  sidebarReportsActions: $TODOFIXME;
}) => {
  const {
    actions: { refreshReportOptions, setReport }
  } = useAnalyticsReportContext() as UseAnalyticsReportContext;

  const favoriteReportIds = useSelector(isUserUiOptionSet('favorite_reports', []));

  const favoriteReports = useMemo(
    () =>
      favoriteReportIds
        .map((x: $TODOFIXME) => reports.find((report: $TODOFIXME) => report.id === x))
        .filter((x: $TODOFIXME) => x),
    [favoriteReportIds, reports]
  );

  const favoriteReportsItems = useMemo(
    () =>
      favoriteReports.reverse().map((x: $TODOFIXME, index: number) => {
        return {
          title: x?.name,
          onClick() {
            const reportOptions = parseSearch(x?.query_string);
            refreshReportOptions({ ...reportOptions, favorite: index + 1 });
            setReport(x);
          },
          actions: sidebarReportsActions({
            report: x
          })
        };
      }),
    [favoriteReports, refreshReportOptions, setReport, sidebarReportsActions]
  );

  return (
    <SidebarExpandableItem
      id="favorites-reports-expandable"
      title="Favorites"
      items={favoriteReportsItems}
      loading={reportsLoading}
      open={true}
    />
  );
};

export const RecentlyViewedReports = ({
  reports,
  reportsLoading,
  sidebarReportsActions
}: {
  reports: $TODOFIXME;
  reportsLoading: boolean;
  sidebarReportsActions: $TODOFIXME;
}) => {
  const {
    actions: { refreshReportOptions, setReport }
  } = useAnalyticsReportContext() as UseAnalyticsReportContext;
  const currentUser = useSelector((state: $TODOFIXME) => state.currentUser);
  const deletedReports = getRecentReports(currentUser.username).filter(
    (x: $TODOFIXME) => !reports.find((report: $TODOFIXME) => report.id === x)
  );
  sanitizeRecentReports(deletedReports, currentUser);

  const recentReports = useMemo(
    () =>
      getRecentReports(currentUser.username)
        .map((x: $TODOFIXME) => reports.find((report: $TODOFIXME) => report.id === x))
        .filter((x: $TODOFIXME) => x),
    [currentUser.username, reports]
  );

  const recentReportsItems = useMemo(
    () =>
      recentReports.reverse().map((x: $TODOFIXME, index: number) => {
        return {
          title: x?.name,
          onClick() {
            const reportOptions = parseSearch(x?.query_string);

            refreshReportOptions({ ...reportOptions, recent: index + 1 });
            setReport(x);
          },
          actions: sidebarReportsActions({
            report: x
          })
        };
      }),
    [recentReports, refreshReportOptions, setReport, sidebarReportsActions]
  );

  if (recentReportsItems.length === 0) return null;

  return (
    <SidebarExpandableItem
      id="recent-reports-expandable"
      title="Recently Viewed"
      items={recentReportsItems}
      loading={reportsLoading}
      open={true}
    />
  );
};

const ReportsTab = ({
  reports,
  reportsLoading,
  sidebarReportsActions,
  presetReports
}: ReportsTabProps): JSX.Element => {
  const { height } = useWindowSize();

  return (
    <>
      <AlternativeSidebarWrapper windowSize={(height as number) - HEADING_HEIGHT}>
        <div data-id="analytics-report-sidebar">
          <Section data-id="favorite-reports" pb="300">
            <FavoriteReports
              reports={[...reports, ...PRESET_REPORT_CONFIGS]}
              reportsLoading={reportsLoading}
              sidebarReportsActions={sidebarReportsActions}
            />
          </Section>
          <Section data-id="preset-reports" pb="300">
            <SidebarExpandableItem
              id="preset-reports"
              title="Presets"
              items={presetReports}
              open
              loading={reportsLoading}
            />
          </Section>
          <Section data-id="recent-reports" pb="300">
            <RecentlyViewedReports
              reports={[...reports, ...PRESET_REPORT_CONFIGS]}
              reportsLoading={reportsLoading}
              sidebarReportsActions={sidebarReportsActions}
            />
          </Section>
        </div>
      </AlternativeSidebarWrapper>
    </>
  );
};

export default ReportsTab;
