import React from 'react';
import styles from './setup.module.scss';

const Setup = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 222" overflow="inherit">
    <path className={styles.st0} d="M205.6 70.2v-.7l7.2-3.9v1l-6.2 3.2 6.2 3.3v1l-7.2-3.9z" />
    <path className={styles.st1} d="M221.8 62h.8l-7.1 16.3h-.8l7.1-16.3z" />
    <path className={styles.st0} d="M224.7 73.1l6.2-3.2-6.2-3.3v-1l7.2 3.9v.7l-7.2 3.9v-1z" />
    <path
      className={styles.st2}
      d="M296.3 148.2c0-.9 1.2-1.6 2.9-2-.2-1-.4-2-.8-2.9-1.7.5-3.1.4-3.5-.3-.4-.7.2-2 1.5-3.2-.6-.8-1.3-1.5-2.1-2.1-1.2 1.3-2.4 1.9-3.2 1.5-.7-.4-.8-1.8-.3-3.5-.9-.4-1.9-.6-2.9-.8-.4 1.7-1.2 2.9-2 2.9-.9 0-1.6-1.1-2-2.9-1 .1-2 .4-2.9.8.5 1.7.4 3.1-.3 3.5-.8.4-2-.2-3.2-1.5-.8.6-1.5 1.3-2.1 2.1 1.3 1.2 1.9 2.5 1.5 3.2-.4.8-1.8.8-3.6.3-.4.9-.6 1.9-.8 2.9 1.8.4 3 1.2 3 2.1 0 .9-1.2 1.6-3 2.1.1 1 .4 2 .8 2.9 1.8-.5 3.2-.5 3.6.3.4.8-.2 2-1.6 3.3.6.8 1.3 1.5 2.1 2.1 1.3-1.4 2.5-2 3.3-1.6.8.4.8 1.9.3 3.7.9.4 1.9.6 2.9.8.4-1.8 1.2-3 2.1-3 .9 0 1.7 1.2 2.1 3 1-.2 2-.4 2.9-.8-.5-1.8-.5-3.2.3-3.6.8-.4 2 .2 3.3 1.6.8-.6 1.5-1.3 2.1-2.1-1.3-1.2-2-2.5-1.5-3.3.4-.8 1.8-.8 3.6-.3.4-.9.6-1.9.7-2.9-2-.7-3.2-1.5-3.2-2.3zm-10.5 8c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"
    />
    <path
      className={styles.st3}
      d="M64.2 129.3H22.9c-.5 0-.9.4-.9.9 0 3.2 1.6 5.1 4.1 7.1 3.8 2.9 7.5 5.9 11.2 8.9 1.5 1.2 4.2 3.8 6.1 3.8 2 0 4.7-2.6 6.1-3.8 3.7-3 7.5-5.9 11.2-8.9 1.8-1.4 4.1-3.7 4.1-6 .3-.8.4-2-.6-2z"
    />
    <path
      className={styles.st3}
      d="M65.1 137.4c-.6.7-1.2 1.3-1.9 1.9-4 3.1-8 6.2-11.9 9.5-2.1 1.8-4.7 3.9-7.6 3.9-2.9 0-5.5-2.2-7.6-3.9-3.9-3.3-7.9-6.4-11.9-9.5-.7-.6-1.3-1.2-1.9-1.9v20.7c0 .5.4.9.9.9h41.3c.5 0 .9-.4.9-.9v-20.7z"
    />
    <path
      className={styles.st4}
      d="M14.4 174.4c-1.8 0-3.3-1.4-3.3-3.1v-23.6c0-1.7 1.5-3.1 3.3-3.1h36.8c1.8 0 3.3 1.4 3.3 3.1v23.6c0 1.7-1.5 3.1-3.3 3.1H14.4z"
    />
    <path
      className={styles.st5}
      d="M53.5 144.6H12.2c-.5 0-.9.4-.9.9 0 3.2 1.6 5.1 4.1 7.1 3.8 2.9 7.5 5.9 11.2 8.9 1.5 1.2 4.2 3.8 6.1 3.8 2 0 4.7-2.6 6.1-3.8 3.7-3 7.5-5.9 11.2-8.9 1.8-1.4 4.1-3.7 4.1-6 .3-.8.5-2-.6-2z"
    />
    <path
      className={styles.st5}
      d="M54.4 152.8c-.6.7-1.2 1.3-1.9 1.9-4 3.1-8 6.2-11.9 9.5-2.1 1.8-4.7 3.9-7.6 3.9-2.9 0-5.5-2.2-7.6-3.9-3.9-3.3-7.9-6.4-11.9-9.5-.7-.6-1.3-1.2-1.9-1.9v20.7c0 .5.4.9.9.9h41.3c.5 0 .9-.4.9-.9v-20.7z"
    />
    <path
      className={styles.st6}
      d="M87.4 187.3c-1.8 0-3.3-1.5-3.3-3.3V95.4c0-1.8 1.5-3.3 3.3-3.3H223c1.8 0 3.3 1.5 3.3 3.3v88.7c0 1.8-1.5 3.3-3.3 3.3H87.4z"
    />
    <path
      className={styles.st2}
      d="M223 92.3c1.7 0 3 1.4 3 3V184c0 1.7-1.4 3-3 3H87.4c-1.7 0-3-1.4-3-3V95.4c0-1.7 1.4-3 3-3H223m0-.6H87.4c-1.9 0-3.5 1.6-3.5 3.5V184c0 1.9 1.6 3.5 3.5 3.5H223c1.9 0 3.5-1.6 3.5-3.5V95.4c0-2-1.6-3.6-3.5-3.6z"
    />
    <path
      className={styles.st2}
      d="M84.1 102.8v-7.4c0-1.8 1.5-3.3 3.3-3.3H223c1.8 0 3.3 1.5 3.3 3.3v7.4H84.1z"
    />
    <path
      className={styles.st2}
      d="M223 92.3c1.7 0 3 1.4 3 3v7.2H84.4v-7.2c0-1.7 1.4-3 3-3H223m0-.5H87.4c-1.9 0-3.5 1.6-3.5 3.5v7.7h142.6v-7.7c0-1.9-1.6-3.5-3.5-3.5z"
    />
    <path
      className={styles.st6}
      d="M91.5 99.4c-1.3 0-2.4-1.1-2.4-2.4 0-1.3 1.1-2.4 2.4-2.4s2.4 1.1 2.4 2.4c0 1.3-1.1 2.4-2.4 2.4z"
    />
    <path
      className={styles.st7}
      d="M91.5 94.8c1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2m0-.5c-1.5 0-2.7 1.2-2.7 2.7s1.2 2.7 2.7 2.7 2.7-1.2 2.7-2.7-1.3-2.7-2.7-2.7z"
    />
    <path
      className={styles.st6}
      d="M98.3 99.4c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4 2.4 1.1 2.4 2.4-1.1 2.4-2.4 2.4z"
    />
    <path
      className={styles.st7}
      d="M98.3 94.8c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2m0-.5c-1.5 0-2.7 1.2-2.7 2.7 0 1.5 1.2 2.7 2.7 2.7s2.7-1.2 2.7-2.7c0-1.5-1.2-2.7-2.7-2.7z"
    />
    <path
      className={styles.st6}
      d="M105.1 99.4c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4 2.4 1.1 2.4 2.4-1.1 2.4-2.4 2.4z"
    />
    <path
      className={styles.st7}
      d="M105.1 94.8c1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2m0-.5c-1.5 0-2.7 1.2-2.7 2.7 0 1.5 1.2 2.7 2.7 2.7s2.7-1.2 2.7-2.7c0-1.5-1.2-2.7-2.7-2.7z"
    />
    <path
      className={styles.st8}
      d="M84.4 102.1H226v1.6H84.4zM169.6 139.5c.9.3 2.2 0 2.8-.7l3.8-3.8c.6-.7 1-1.9.7-2.8l-.9-2.2c-.1-.4-.3-.6-.6-.7-.3-.1-.6.1-.8.3l-2.4 2.4c-.9.9-2.3.9-3.1 0l-2.6-2.6c-.4-.4-.6-1-.6-1.6 0-.6.2-1.2.6-1.6l2.4-2.4c.3-.3.4-.6.3-.8-.1-.3-.3-.5-.7-.6l-2.3-.8c-.9-.3-2.2 0-2.8.7l-3.8 3.8c-.7.7-1 1.9-.7 2.8l1.4 4.1-7.5 7.5c-.1 0-.2.1-.3.1l-11.3-11.3-.8-2.5-5.1-3.3-2 2 3.3 5.1 2.6.9 11.2 11.2c0 .1-.1.2-.1.3L140 153.3c-1.8-.2-3.6.3-5 1.7-2.3 2.3-2.3 6.1 0 8.5 2.3 2.3 6.1 2.3 8.5 0 1.3-1.3 1.8-2.9 1.7-4.6l8.2-8.2 13.1 13.1c1.9 1.9 5 1.9 6.9 0 1.9-1.9 1.9-5 0-6.9l-13.1-13.1 5.4-5.4 3.9 1.1zm-27.8 22c-1.3 1.3-3.4 1.3-4.7 0-1.3-1.3-1.3-3.4 0-4.7 1.3-1.3 3.4-1.3 4.7 0 1.3 1.3 1.3 3.4 0 4.7zm27.1.1c-.2.2-.6.2-.9 0l-12.8-12.8.9-.9 12.8 12.8c.3.3.3.7 0 .9zm2.7-3.5c.2.2.2.6 0 .9-.2.2-.6.2-.9 0L158 146.3l.9-.9 12.7 12.7z"
    />
    <path
      className={styles.st4}
      d="M260.7 94.7c-1.1 0-2-.9-2-2V66.3c0-1.1.9-2 2-2h26.4c1.1 0 2 .9 2 2v26.4c0 1.1-.9 2-2 2h-26.4z"
    />
    <path
      className={styles.st1}
      d="M287.1 64.6c.9 0 1.7.8 1.7 1.7v26.4c0 .9-.8 1.7-1.7 1.7h-26.4c-.9 0-1.7-.8-1.7-1.7V66.3c0-.9.8-1.7 1.7-1.7h26.4m0-.5h-26.4c-1.2 0-2.2 1-2.2 2.2v26.4c0 1.2 1 2.2 2.2 2.2h26.4c1.2 0 2.2-1 2.2-2.2V66.3c0-1.2-1-2.2-2.2-2.2z"
    />
    <path
      className={styles.st8}
      d="M265.3 90c-.2 0-.4-.2-.4-.4V69c0-.2.2-.4.4-.4s.4.2.4.4v20.6c0 .2-.2.4-.4.4zM271.3 90c-.2 0-.4-.2-.4-.4V69c0-.2.2-.4.4-.4s.4.2.4.4v20.6c0 .2-.2.4-.4.4zM277.2 90c-.2 0-.4-.2-.4-.4V69c0-.2.2-.4.4-.4s.4.2.4.4v20.6c0 .2-.2.4-.4.4zM283.2 90c-.2 0-.4-.2-.4-.4V69c0-.2.2-.4.4-.4s.4.2.4.4v20.6c-.1.2-.2.4-.4.4z"
    />
    <circle className={styles.st8} cx="265.3" cy="72.9" r="1.6" />
    <circle className={styles.st8} cx="271.3" cy="85.6" r="1.6" />
    <circle className={styles.st8} cx="277.2" cy="77.7" r="1.6" />
    <circle className={styles.st8} cx="283.2" cy="83" r="1.6" />
    <path className={styles.st9} d="M120.8 198.5h76.9" />
    <path className={styles.st10} d="M13.8 198.3h39.1" />
    <path className={styles.st9} d="M112.9 204.1h70.8" />
    <path className={styles.st10} d="M7.7 203.9h39.2" />
    <path className={styles.st9} d="M110.6 209.6h67.8" />
    <path className={styles.st10} d="M13.8 209.6h39.1" />
    <path className={styles.st9} d="M144.5 215.2h38.2" />
    <path
      className={styles.st10}
      d="M15.5 215.2h71.8M201.1 198.3h39.2M195 203.9h39.2M189 209.6h39.2M192.3 215.2h71.8M59 220.8h108.1"
    />
    <path
      className={styles.st11}
      d="M13.2 133.5H.5v-32.6h29M59 100.9h22.8M122.2 88.5V65.1M122.2 21.1V.5h52v19.2h99.7v41"
    />
    <g>
      <path
        className={styles.st12}
        d="M176.8 46.9c2.2 0 4-1.8 4-4s-1.8-4-4-4h-10.2c-2.2 0-4 1.8-4 4s1.8 4 4 4h10.2zM176.8 60.1c2.2 0 4-1.8 4-4s-1.8-4-4-4h-10.2c-2.2 0-4 1.8-4 4s1.8 4 4 4h10.2zM176.8 73.2c2.2 0 4-1.8 4-4s-1.8-4-4-4h-10.2c-2.2 0-4 1.8-4 4s1.8 4 4 4h10.2z"
      />
      <circle className={styles.st5} cx="166.5" cy="42.9" r="4" />
      <circle className={styles.st5} cx="176.8" cy="56.1" r="4" />
      <circle className={styles.st5} cx="166.5" cy="69.2" r="4" />
    </g>
    <g>
      <path
        className={styles.st8}
        d="M86 56.5c-2.7 0-5 1.9-5.6 4.5h-2.6c-.6-2.6-2.8-4.5-5.6-4.5-3.2 0-5.7 2.6-5.7 5.7 0 3.2 2.6 5.7 5.7 5.7 2.7 0 5-1.9 5.6-4.5h2.6c.6 2.6 2.8 4.5 5.6 4.5 3.2 0 5.7-2.6 5.7-5.7 0-3.2-2.6-5.7-5.7-5.7zm-13.8 9.2c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5zm13.8 0c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5zM80.2 71.9H78c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h2.2c.5 0 .9.4.9.9s-.5.9-.9.9zM86.6 40.3c-2.6 1.9-3.1 5.4-3.2 7.7-4.1-4.7 7.5-18.6-5.7-27.1 7.3 9.4-7.6 18.7-11 31.6-.2.8-.4 1.6-.5 2.5 1.1-1.9 5.8-4.4 10-1.8 6.5 4.1 10.8-2.8 14.2 1.7-.1-.9-.3-1.7-.5-2.5-1.3-5.5-4.6-8.2-3.3-12.1z"
      />
    </g>
    <g>
      <path
        className={styles.st13}
        d="M53.4 108.8c0 .9-.8 1.7-1.7 1.7H34.8c-.9 0-1.7-.8-1.7-1.7V98.6c0-.9.8-1.7 1.7-1.7h.6v-3.4c0-4.3 3.6-7.9 7.9-7.9s7.9 3.6 7.9 7.9V97h.6c.9 0 1.7.8 1.7 1.7v10.1zM47.8 97v-3.4c0-2.5-2-4.5-4.5-4.5s-4.5 2-4.5 4.5V97h9z"
      />
    </g>
    <path
      className={styles.st1}
      d="M267.3 142.9c0-1.1 1.5-2.1 3.7-2.6-.2-1.3-.5-2.5-1-3.7-2.2.6-4 .5-4.5-.4-.6-1 .2-2.5 1.9-4.1-.8-1-1.7-1.9-2.7-2.7-1.6 1.6-3.1 2.4-4.1 1.9-1-.5-1.1-2.3-.4-4.5-1.2-.5-2.4-.8-3.7-1-.5 2.2-1.5 3.7-2.6 3.7s-2.1-1.5-2.6-3.7c-1.3.2-2.5.5-3.7 1 .6 2.2.5 3.9-.4 4.5-1 .6-2.5-.3-4.1-1.9-1 .8-1.9 1.7-2.7 2.7 1.7 1.6 2.5 3.2 1.9 4.1-.6 1-2.4 1.1-4.6.4-.5 1.2-.8 2.4-1 3.7 2.3.5 3.8 1.5 3.8 2.6s-1.5 2.1-3.8 2.6c.2 1.3.5 2.5 1 3.7 2.3-.7 4.1-.6 4.7.4.6 1-.3 2.6-2 4.2.8 1 1.7 1.9 2.7 2.7 1.6-1.7 3.3-2.6 4.2-2 1 .6 1.1 2.4.4 4.7 1.2.5 2.4.8 3.7 1 .5-2.3 1.5-3.9 2.7-3.9 1.1 0 2.1 1.6 2.7 3.9 1.3-.2 2.5-.5 3.7-1-.7-2.3-.6-4.1.4-4.7 1-.6 2.6.3 4.2 2 1-.8 1.9-1.7 2.7-2.7-1.7-1.6-2.5-3.2-2-4.2.6-1 2.4-1.1 4.6-.4.4-1.2.8-2.4 1-3.7-2.6-.5-4.1-1.5-4.1-2.6zm-13.4 10.3c-5.6 0-10.2-4.6-10.2-10.2s4.6-10.2 10.2-10.2 10.2 4.6 10.2 10.2c-.1 5.6-4.6 10.2-10.2 10.2z"
    />
    <path
      className={styles.st13}
      d="M137.1 44c0-1.2 1.6-2.2 3.9-2.8-.2-1.3-.6-2.6-1.1-3.9-2.3.7-4.2.6-4.8-.4-.6-1 .3-2.7 2-4.3-.8-1-1.8-2-2.9-2.8-1.7 1.7-3.3 2.6-4.3 2-1-.6-1.1-2.4-.4-4.7-1.2-.5-2.5-.8-3.9-1-.6 2.3-1.6 3.9-2.7 3.9-1.2 0-2.2-1.6-2.7-3.9-1.3.2-2.7.5-3.9 1 .7 2.3.6 4.2-.4 4.7-1 .6-2.7-.3-4.4-2-1.1.8-2 1.8-2.9 2.8 1.8 1.7 2.6 3.4 2 4.4-.6 1-2.5 1.1-4.8.4-.5 1.2-.8 2.5-1.1 3.9 2.4.6 4 1.6 4 2.8 0 1.2-1.6 2.2-4 2.8.2 1.4.5 2.7 1 3.9 2.4-.7 4.3-.6 4.9.4.6 1-.3 2.8-2.1 4.5.8 1.1 1.8 2 2.8 2.9 1.7-1.8 3.4-2.7 4.5-2.1 1 .6 1.1 2.6.4 5 1.2.5 2.5.8 3.9 1 .6-2.4 1.6-4.1 2.8-4.1 1.2 0 2.2 1.6 2.8 4.1 1.3-.2 2.7-.6 3.9-1.1-.7-2.4-.6-4.3.4-4.9 1-.6 2.7.3 4.4 2.1 1-.8 2-1.8 2.8-2.9-1.8-1.7-2.7-3.4-2.1-4.4.6-1 2.5-1.1 4.8-.4.5-1.2.8-2.5 1-3.9-2.3-.9-3.8-1.9-3.8-3zm-14.2 10.8c-5.9 0-10.8-4.8-10.8-10.8 0-5.9 4.8-10.8 10.8-10.8 5.9 0 10.8 4.8 10.8 10.8-.1 6-4.9 10.8-10.8 10.8z"
    />
    <g>
      <path
        className={styles.st6}
        d="M239.2 51.4c-6 0-11-4.9-11-11s4.9-11 11-11 11 4.9 11 11-4.9 11-11 11z"
      />
      <path
        className={styles.st14}
        d="M239.2 29.8c5.9 0 10.7 4.8 10.7 10.7s-4.8 10.7-10.7 10.7c-5.9 0-10.7-4.8-10.7-10.7s4.8-10.7 10.7-10.7m0-.5c-6.2 0-11.2 5-11.2 11.2 0 6.2 5 11.2 11.2 11.2s11.2-5 11.2-11.2c.1-6.2-5-11.2-11.2-11.2z"
      />
      <path
        className={styles.st14}
        d="M246.8 38.1c0 .3-.1.5-.3.7l-7.9 7.9c-.2.2-.4.3-.7.3-.2 0-.5-.1-.7-.3l-5.3-5.3c-.2-.2-.3-.4-.3-.7 0-.2.1-.5.3-.7l1.3-1.3c.2-.2.4-.3.7-.3.2 0 .5.1.7.3l3.3 3.3 6-5.9c.2-.2.4-.3.7-.3.2 0 .5.1.7.3l1.3 1.3c.1.2.2.5.2.7z"
      />
    </g>
    <g>
      <path className={styles.st6} d="M14.7 85.9c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z" />
      <path
        className={styles.st14}
        d="M14.7 70.2c4.3 0 7.8 3.5 7.8 7.8s-3.5 7.8-7.8 7.8-7.8-3.5-7.8-7.8 3.5-7.8 7.8-7.8m0-.5c-4.6 0-8.3 3.7-8.3 8.3s3.7 8.3 8.3 8.3S23 82.6 23 78s-3.7-8.3-8.3-8.3z"
      />
      <path
        className={styles.st14}
        d="M20.2 76.2c0 .2-.1.4-.2.5l-5.8 5.8c-.1.1-.3.2-.5.2s-.4-.1-.5-.2l-3.9-3.9c-.1-.1-.2-.3-.2-.5s.1-.4.2-.5l1-1c.1-.1.3-.2.5-.2s.4.1.5.2l2.4 2.4 4.4-4.4c.1-.1.3-.2.5-.2s.4.1.5.2l1 1c.1.2.1.4.1.6z"
      />
    </g>
  </svg>
);

export default Setup;
