import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';

// REDUX
import { removeFilter as removeFilterAction } from 'src/actions/messageEvents';

// CONSTANTS
import { ALL_EVENTS_FILTERS } from 'src/constants';

// COMPONENTS
import { Box, Panel, Tag, Text } from 'src/components/matchbox';

// HELPERS
import { getEventColor } from '../helpers/getEventColor';
import { getEventName } from '../helpers/getEventName';
import { getFiltersAsArray, removeEmptyFilters } from '../helpers/transformData';

// TYPES
import { Event, MessageEvents } from 'src/typescript';

const TagsWrapper = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.sizes['300']};
  flex-wrap: wrap;
`;

const filterTypes = [...getFiltersAsArray(ALL_EVENTS_FILTERS)] as {
  label: string;
  value: string;
}[];

export function ActiveFilters(): JSX.Element | null {
  const dispatch = useDispatch();
  const search = useSelector<$TODOFIXME, MessageEvents['search']>(
    (state) => state.messageEvents.search
  );

  const removeFilter = useCallback(
    (options: $TODOFIXME): Dispatch => dispatch(removeFilterAction(options)),
    [dispatch]
  );

  const renderTags = useMemo(() => {
    const nonEmptyFilters = removeEmptyFilters(search) as MessageEvents['search'];
    const nonEmptyFilterTypes = filterTypes.filter(
      (filterType) => nonEmptyFilters[filterType.value]
    );
    const activeFilters = _.flatMap(nonEmptyFilterTypes, ({ value, label }, typeIndex) => {
      const filledFilters = nonEmptyFilters[value] as string[];

      const isEvent = value === 'events';

      return filledFilters.map((item, valueIndex) => (
        <Tag
          color={isEvent ? getEventColor(item as Event['type']) : undefined}
          onRemove={() => removeFilter({ key: value, item })}
          key={`${typeIndex}-${valueIndex}`}
        >
          {isEvent ? (
            getEventName(item as Event['type'])
          ) : (
            <>
              {/* eslint-disable-next-line local/restrict-translatable-text */}
              {label}: {item}
            </>
          )}
        </Tag>
      ));
    });
    return activeFilters;
  }, [removeFilter, search]);

  const isEmpty = () => {
    const { dateOptions, ...rest } = search;
    // eslint-disable-next-line lodash/matches-prop-shorthand
    return _.every(rest, (arr) => arr?.length === 0);
  };

  if (isEmpty()) {
    return null;
  }

  return (
    <Panel.Section>
      <Box data-id="active-filters">
        <Box>
          <Text color="gray.900" fontWeight="600" fontSize="200">
            Filters Applied:
          </Text>
        </Box>
        <TagsWrapper>{renderTags}</TagsWrapper>
      </Box>
    </Panel.Section>
  );
}
