import React from 'react';
import { Button, Modal } from 'src/components/matchbox';
import { setAccountOption } from 'src/helpers/api/account';
import { useAlert, useSparkPostMutation } from 'src/hooks';

export default function PermitAutomaticInlineSeedingModal({ open, onClose, onSuccess }) {
  const { showAlert } = useAlert();
  const mutation = useSparkPostMutation(
    () => {
      return setAccountOption({ option: 'inline_seeds', value: true });
    },
    { onSuccess: handleSuccess }
  );

  function handleSuccess() {
    onClose();
    onSuccess();
    showAlert({ type: 'success', message: 'Account Updated' });
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header showCloseButton>
        Permit Automatic Inline Seeding Across All Accounts
      </Modal.Header>

      <Modal.Content>
        Permit Automatic Inline Seeding on Primary and all subaccounts. This will allow all enabled
        subaccounts to begin seeding based on saved settings.
      </Modal.Content>

      <Modal.Footer>
        <Button variant="primary" loading={mutation.status === 'loading'} onClick={mutation.mutate}>
          Permit Seeding
        </Button>

        <Button variant="secondary" disabled={mutation.status === 'loading'} onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
