import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { AccessControl } from 'src/components/auth';
import ErrorBoundary from 'src/components/errorBoundaries/ErrorBoundary';
import { AUTH0_LOGOUT_ROUTE, AUTH_ROUTE, LOGOUT_ROUTE } from 'src/constants';
import RouteFocusHandler from './RouteFocusHandler';
import { SubaccountSelection } from './SubaccountSelection';

export class ProtectedRoute extends Component {
  renderComponent(reactRouterProps) {
    const { component: Component, condition } = this.props;

    //Check logged in first (done by renderRoute)
    //Pull and check user for subaccounts (TODO:)
    //Check access control on account

    return (
      <AccessControl condition={condition} redirect="/404">
        <RouteFocusHandler />
        <Component {...reactRouterProps} />
      </AccessControl>
    );
  }

  renderRoute = (reactRouterProps) => {
    const { auth, location, currentUser } = this.props;
    const state = {};

    // never store logout page as a post login redirect
    if (
      location.pathname !== LOGOUT_ROUTE &&
      location.pathname !== AUTH0_LOGOUT_ROUTE &&
      location.pathname !== '/subaccount'
    ) {
      state.redirectAfterLogin = location;
    }

    if (location.pathname === '/subaccount') {
      return <SubaccountSelection />;
    }

    return auth.loggedIn ? (
      currentUser.showSubaccountSelect ? (
        <Redirect to={{ pathname: '/subaccount', state }} />
      ) : (
        this.renderComponent(reactRouterProps)
      )
    ) : (
      <Redirect to={{ pathname: AUTH_ROUTE, state }} />
    );
  };

  render() {
    // can't pass component prop to Route below or it confuses RR
    const protectedRouteProps = _.omit(this.props, ['component', 'auth', 'condition']);

    return (
      <ErrorBoundary>
        <Route {...protectedRouteProps} render={this.renderRoute} />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = ({ auth, account, currentUser }) => ({
  auth,
  currentUser,
  accountStatus: account.status
});

export default connect(mapStateToProps, {})(ProtectedRoute);
