const devConfig = require('./dev-config');
const productionConfig = require('./production-config');
const stagingConfig = require('./staging-config');
const testConfig = require('./test-config');
const uatConfig = require('./uat-config');

module.exports = (
  nodeEnv,
  environment = 'production' // for enterprise tenants
) => {
  if (nodeEnv === 'production') {
    const configByEnvironment = {
      production: productionConfig,
      staging: stagingConfig,
      uat: uatConfig,
      dev: devConfig
    };

    return configByEnvironment[environment];
  }

  if (nodeEnv === 'test') {
    return testConfig;
  }

  return devConfig;
};
