import React from 'react';
import { PageLink } from 'src/components/links';
import { Text } from 'src/components/matchbox';
import { slugToFriendly } from 'src/helpers/string';
import { TranslatableText } from '../text';

type NameCellProps = {
  id: string;
  name: string;
  to: string;
};

const NameCell = ({
  id,
  name = slugToFriendly(id), // this default is an edge case, most resources will include a name
  to
}: NameCellProps) => [
  <Text key="name">
    <PageLink to={to}>{name}</PageLink>
  </Text>,
  <Text key="id">
    ID:
    <TranslatableText>{` ${id}`}</TranslatableText>
  </Text>
];

export default NameCell;
