import { tokens } from '@sparkpost/design-tokens';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ActiveFilters } from 'src/components';
import { Box, Button, Modal, Stack, Tag, Text } from 'src/components/matchbox';
import { TranslatableText } from 'src/components/text';
import { snakeToFriendly } from 'src/helpers/string';
import { metricsAndUnits } from '../NewCreatePage/constants';
import { operatorDictionary } from './constants';
import { ALERT_TYPES } from '../constants/alertTypes';
import { useSparkPostMutation } from 'src/hooks';
import { deleteAlert } from 'src/helpers/api/alerts';
import { showAlert } from 'src/actions/globalAlert';

const MetricsSummary = ({ metric, filters, conditions, displayPlaceholder }) => (
  <Box display="flex" flexWrap="wrap" alignItems="center" whiteSpace="pre-wrap">
    <TranslatableText>The alert is triggered when </TranslatableText>
    <Tag>{snakeToFriendly(metric)}</Tag>
    {filters?.groupings && filters?.groupings.length > 0 && (
      <>
        ( <ActiveFilters filters={filters.groupings} /> ){' '}
      </>
    )}
    <TranslatableText> is </TranslatableText>
    <Tag>{operatorDictionary[conditions.operator]}</Tag>{' '}
    <Tag>{`${conditions.value} ${displayPlaceholder}`}</Tag>
    <TranslatableText> at the end of a </TranslatableText>
    <Tag>1 {conditions.precision}</Tag> period.
  </Box>
);

const AccountUsageSummary = ({ filters, conditions }) => (
  <Box display="flex" flexWrap="wrap" alignItems="center" whiteSpace="pre-wrap">
    <TranslatableText>The alert is triggered when </TranslatableText>
    <Tag>Usage</Tag>
    {filters?.groupings && filters?.groupings.length > 0 && (
      <>
        ( <ActiveFilters filters={filters.groupings} /> ){' '}
      </>
    )}
    <TranslatableText> is </TranslatableText>
    <Tag>{conditions.value}%</Tag> <TranslatableText> of the </TranslatableText>
    <Tag>1 {conditions.precision}</Tag> Limit.
  </Box>
);

export default function DeleteAlertModal({ open, onClose, alert = {} }) {
  const dispatchRedux = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const { name, alert_configuration: alertConfiguration } = alert;
  const { metric, filters, conditions } = alertConfiguration;
  const displayPlaceholder = metricsAndUnits.find((el) => el.key === metric)?.placeholder;

  const deleteAlertMutation = useSparkPostMutation(
    () => {
      return deleteAlert(id);
    },
    {
      onSuccess: () => {
        dispatchRedux(showAlert({ type: 'success', message: `Alert deleted successfully` }));
        history.push('/alerts');
      }
    }
  );
  const handleDelete = () => {
    deleteAlertMutation.mutate({});
  };

  const AlertsSummary = () => {
    switch (alert.alert_type) {
      case ALERT_TYPES.sending_limit_alert:
        return <AccountUsageSummary conditions={conditions} filters={filters} />;
      default:
        return (
          <MetricsSummary
            metric={metric}
            filters={filters}
            conditions={conditions}
            displayPlaceholder={displayPlaceholder}
          />
        );
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header showCloseButton>Delete Alert</Modal.Header>
      <Modal.Content>
        <Stack>
          <Box>
            Deleting the alert is permanent. The respective metric and criteria will no longer be
            tracked and all historical incident history will be deleted.
          </Box>
          <Box>
            <Text as="b">Alert Name</Text>
            <Text>{name}</Text>
          </Box>
          <Box>
            <Text as="b">Alert Summary</Text>
            <Text as="i">
              <AlertsSummary />
            </Text>
          </Box>
        </Stack>
      </Modal.Content>
      <Modal.Footer>
        <Button variant="destructive" color="red" onClick={handleDelete}>
          Delete Alert
        </Button>
        <Button onClick={onClose} variant="monochrome-secondary" color={tokens.color_gray_900}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
