import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { get as getCurrentUser } from 'src/actions/currentUser';
import { Loading } from 'src/components';
import { DEFAULT_REDIRECT_ROUTE } from 'src/constants';
import { isUserUiOptionSet } from 'src/helpers/conditions/user';

/*
 * Redirect to the default route.
 * This is meant for use immediately after authentication completes.
 */
export const RedirectAfterLogin = ({
  location: { state } = {},
  isUserPending,
  getCurrentUser,
  currentUser,
  isAuth0User,
  tfaEnabled,
  hasRecoveryCode
}) => {
  // TODO REMOVE(loading logic and redirect to mfa/recovery-code) - https://sparkpost.atlassian.net/browse/FE-1684
  useEffect(() => {
    getCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isUserPending || (!currentUser.access_level && !currentUser.subaccounts)) {
    return <Loading />;
  }

  const route =
    isAuth0User && tfaEnabled && !hasRecoveryCode
      ? { pathname: '/mfa/recovery-code' }
      : { state, pathname: DEFAULT_REDIRECT_ROUTE };

  return <Redirect to={route} />;
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  isAuth0User: state?.currentUser?.auth_migrated,
  isUserPending: state?.currentUser.loading,
  tfaEnabled: state?.tfa?.enabled,
  hasRecoveryCode: isUserUiOptionSet('has_recovery_code', false)(state)
});

export default withRouter(connect(mapStateToProps, { getCurrentUser })(RedirectAfterLogin));
