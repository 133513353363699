import { ChevronRight } from '@sparkpost/matchbox-icons';
import { formatLongDateTime } from '@sparkpost/report-builder/helpers/date';
import { getMetricsFromKeys } from '@sparkpost/report-builder/helpers/metrics';
import React, { useCallback, useMemo } from 'react';
import { Empty, TableCollection } from 'src/components';
import { PageLink } from 'src/components/links';
import { Box, Button, Panel, Tag, Text } from 'src/components/matchbox';
import { TranslatableText } from 'src/components/text';
import {
  generateAnalyticsMetricsURL,
  generateBlocklistURL,
  generateHealthScoreURL,
  generateUsageURL
} from '../helpers/urlGenerator';
import { ALERT_TYPES } from '../constants/alertTypes';
import {
  parseAnalyticsMetricsEvaluation,
  parseBlocklistEvaluation,
  parseHealthScoreEvaluation
} from '../helpers/evaluation';
import { formatNumberPercent, roundToOnePlace } from 'src/helpers/units';
import { HEALTHSCORE_EVALUTATION_TYPES } from '../NewCreatePage/constants';
import { getUnitFormatter } from '../helpers/unit';
import { GROUP_BY_CONFIG } from 'src/pages/analyticsReport/constants';
import { HEALTH_SCORE_BREAKDOWN_CONFIG } from '../constants/healthScore';

const filterBoxConfig = {
  show: true,
  itemToStringKeys: ['name', 'assignment'],
  exampleModifiers: ['name']
};

const getMetricLabel = (metric) => getMetricsFromKeys([metric])[0].label;

const AlertIncidents = ({ incidents = [], alert }) => {
  const { alert_type, alert_configuration } = alert;
  const healthScoreFacetName = alert_configuration.filters?.facet_name;
  const healthScoreSource = alert_configuration.conditions?.source;

  const alertTypeColumns = useMemo(() => {
    switch (alert_type) {
      case ALERT_TYPES.blocklistings:
        return [
          {
            label: 'Resource',
            sortKey: 'resource',
            key: 'alert_incident_header_resource'
          },
          {
            label: 'Blocklist',
            sortKey: 'blocklist',
            key: 'alert_incident_header_blocklist'
          }
        ];

      case ALERT_TYPES.health_score:
        const getHealthScoreBreakdownColumn = () => ({
          label: HEALTH_SCORE_BREAKDOWN_CONFIG[healthScoreFacetName].label,
          sortKey: 'breakdown',
          key: 'alert_incident_header_breakdown'
        });

        return [
          {
            label: 'Subaccount',
            sortKey: 'subaccount',
            key: 'alert_incident_header_subaccount'
          },
          ...(healthScoreFacetName ? [getHealthScoreBreakdownColumn()] : []),
          {
            label: 'Health Score',
            sortKey: 'health_score',
            key: 'alert_incident_header_health_score',
            align: 'right'
          }
        ];

      case ALERT_TYPES.sending_limit_alert:
        return [
          {
            label: 'Monthly Usage',
            sortKey: 'usage',
            key: 'alert_incident_header_usage',
            align: 'right'
          }
        ];

      case ALERT_TYPES.metrics:
        const metricLabel = alert_configuration.metric
          ? getMetricLabel(alert_configuration.metric)
          : 'Value';

        const getMetricsBreakdownColumn = () => ({
          label: GROUP_BY_CONFIG[alert_configuration.group_by].label,
          sortKey: 'breakdown',
          key: 'alert_incident_header_breakdown'
        });

        return [
          ...(alert_configuration.group_by ? [getMetricsBreakdownColumn()] : []),
          {
            label: metricLabel,
            sortKey: 'triggered_value',
            key: 'alert_incident_header_metric_label',
            align: 'right'
          }
        ];

      default:
        return [];
    }
  }, [alert_type, healthScoreFacetName, alert_configuration.group_by, alert_configuration.metric]);

  const columns = useMemo(
    () => [
      { label: 'Date Triggered', sortKey: 'first_fired', key: 'alert_incident_header_triggered' },
      { label: 'Date Resolved', key: 'alert_incident_header_status' },
      ...alertTypeColumns,
      { label: '', key: 'alert_incident_header_view_in' }
    ],
    [alertTypeColumns]
  );

  const getRowDataByAlertType = useCallback(
    (incident) => {
      switch (alert_type) {
        case ALERT_TYPES.metrics:
          const { resource: groupByEvaluation } = parseAnalyticsMetricsEvaluation(
            incident.evaluation.resource
          );
          const { unit: metricUnit } = getMetricsFromKeys([alert_configuration.metric])[0];
          const formatMetricValue = getUnitFormatter(metricUnit);
          const metricValue = formatMetricValue(incident.evaluation.value);
          const reportLink = generateAnalyticsMetricsURL({
            from: incident.beginning,
            to: incident.resolution,
            metric: alert_configuration.metric,
            filters: alert_configuration.filters,
            groupBy: alert_configuration.group_by
          });

          const metricBreakdownValue = (
            <Box>
              <TranslatableText>{groupByEvaluation}</TranslatableText>
            </Box>
          );

          return [
            ...(alert_configuration.group_by ? [metricBreakdownValue] : []),
            <Box textAlign="right">
              <TranslatableText>{metricValue}</TranslatableText>
            </Box>,
            <Box textAlign="right">
              <PageLink as={Button} variant="minimal" to={reportLink}>
                View in Analytics
                <Button.Icon as={ChevronRight} />
              </PageLink>
            </Box>
          ];

        case ALERT_TYPES.blocklistings:
          const { resource, provider } = parseBlocklistEvaluation(incident.evaluation.resource);
          const blockListIncidentsLink = generateBlocklistURL({ resource, provider });
          return [
            <Box>
              <TranslatableText>{resource}</TranslatableText>
            </Box>,
            <Box>
              <TranslatableText>{provider}</TranslatableText>
            </Box>,
            <Box textAlign="right">
              <PageLink as={Button} variant="minimal" to={blockListIncidentsLink}>
                View in Blocklist
                <Button.Icon as={ChevronRight} />
              </PageLink>
            </Box>
          ];

        case ALERT_TYPES.sending_limit_alert:
          const usageLink = generateUsageURL();
          return [
            <Box textAlign="right">
              <TranslatableText>{incident.evaluation.value}%</TranslatableText>
            </Box>,
            <Box textAlign="right">
              <PageLink as={Button} variant="minimal" to={usageLink}>
                View in Usage
                <Button.Icon as={ChevronRight} />
              </PageLink>
            </Box>
          ];

        case ALERT_TYPES.health_score:
          const { resourceId, resourceValue, subaccount } = parseHealthScoreEvaluation(
            incident.evaluation.resource
          );
          const healthScoreLink = generateHealthScoreURL({ resourceId, resourceValue, subaccount });
          const formatHealthScore =
            healthScoreSource === HEALTHSCORE_EVALUTATION_TYPES.raw
              ? roundToOnePlace
              : formatNumberPercent;

          const healthScoreBreakdownValue = (
            <Box textAlign="right">
              <TranslatableText>{resourceValue}</TranslatableText>
            </Box>
          );

          return [
            <Box>
              <TranslatableText>{subaccount}</TranslatableText>
            </Box>,
            ...(healthScoreFacetName ? [healthScoreBreakdownValue] : []),
            <Box textAlign="right">
              <TranslatableText>{formatHealthScore(incident.evaluation.value)}</TranslatableText>
            </Box>,
            <Box textAlign="right">
              <PageLink as={Button} variant="minimal" to={healthScoreLink}>
                View in Health Score
                <Button.Icon as={ChevronRight} />
              </PageLink>
            </Box>
          ];

        default:
          return [];
      }
    },
    [
      healthScoreFacetName,
      healthScoreSource,
      alert_configuration.filters,
      alert_configuration.group_by,
      alert_configuration.metric,
      alert_type
    ]
  );

  const getRowData = useCallback(
    (incident) => {
      const { beginning, incident_status, last_notified } = incident;
      return [
        <Box>{formatLongDateTime(beginning)}</Box>,
        <Box>
          {incident_status === 'open' ? (
            <Tag color="yellow">Active</Tag>
          ) : last_notified ? (
            formatLongDateTime(last_notified)
          ) : (
            <Text as="i">Not yet resolved</Text>
          )}
        </Box>,
        ...getRowDataByAlertType(incident)
      ];
    },
    [getRowDataByAlertType]
  );

  return (
    <>
      {incidents.length <= 0 ? (
        <Panel.LEGACY>
          <Empty message="No incidents" />
        </Panel.LEGACY>
      ) : (
        <TableCollection
          rows={incidents}
          columns={columns}
          getRowData={getRowData}
          pagination
          defaultSortColumn="first_fired"
          defaultSortDirection="desc"
          filterBox={filterBoxConfig}
          placeholder="e.g. Sparky’s Cookie Co. or monthly-newsletter"
        />
      )}
    </>
  );
};

export default AlertIncidents;
