import { Event } from 'src/typescript';

export const getEventName = (eventType: Event['type']): string | undefined => {
  const map = {
    bounce: 'Bounce',
    click: 'Click',
    delay: 'Delay',
    delivery: 'Delivery',
    generation_failure: 'Generation Failure',
    generation_rejection: 'Generation Rejection',
    initial_open: 'Opens (Top Pixel)',
    injection: 'Injection',
    link_unsubscribe: 'Link Unsubscribe',
    list_unsubscribe: 'List Unsubscribe',
    open: 'Opens (Bottom Pixel)',
    out_of_band: 'Out of Band',
    policy_rejection: 'Policy Rejection',
    relay_delivery: 'Relay Delivery',
    relay_injection: 'Relay Injection',
    relay_permfail: 'Relay Permfail',
    relay_rejection: 'Relay Rejection',
    relay_tempfail: 'Relay Tempfail',
    spam_complaint: 'Spam Complaint',
    amp_click: 'AMP Click',
    amp_open: 'AMP Open (Bottom Pixel)',
    amp_initial_open: 'AMP Open (Top Pixel)',
    sms_status: 'SMS Status'
  };

  return map[eventType];
};
