import { tokens } from '@sparkpost/design-tokens';
import { getLineChartFormatters, getRelativeDates } from '@sparkpost/report-builder/helpers/date';
import { getMetricsFromKeys, toQueryFromOptions } from '@sparkpost/report-builder/helpers/metrics';
import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import METRICS_UNIT_CONFIG from 'src/appConfig/metrics-units';
import { ActiveFilters, Empty, Loading } from 'src/components';
import CustomTooltip from 'src/components/analyticsReport/Tooltip';
import LineChart from 'src/components/charts/LineChart';
import { Box, Inline, ListBox, Panel, Stack, Tag, Text } from 'src/components/matchbox';
import { Heading, TranslatableText } from 'src/components/text';
import { TimezoneTypeahead } from 'src/components/typeahead/TimezoneTypeahead';
import { getTimeSeries } from 'src/helpers/api/metrics';
import { transformData } from 'src/helpers/metrics';
import { useSparkPostQuery } from 'src/hooks';
import { GROUP_BY_CONFIG } from 'src/pages/analyticsReport/constants';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import styled from 'styled-components';
import { METRICS_BREAKDOWN_TYPES, metricsAndUnits } from '../constants';
import { useAlertForm } from '../useAlertForm';

const dateTimeReducer = (state, action) => {
  switch (action.type) {
    case 'SET_RANGE':
      const ret = { ...state };
      if (action.value !== 'day') {
        ret.selectedPrecision = 'day';
        ret.selectedTimezone = 'UTC';
      }
      return { ...ret, selectedRange: action.value };
    case 'SET_TIMEZONE':
      return { ...state, selectedTimezone: action.value };
    case 'SET_PRECISION':
      return { ...state, selectedPrecision: action.value };
    default:
      throw new Error(`${action.type} is not a valid action for this reducer`);
  }
};
const initialState = {
  selectedRange: 'day',
  timezone: 'UTC',
  selectedPrecision: 'hour'
};

const StyledQuote = styled(Text).attrs({
  as: 'blockquote'
})`
  border-left: 1px solid #d9e0e6;
  padding-left: ${tokens.spacing_200};
  margin-left: ${tokens.spacing_200};
  margin-top: 0;
  color: ${tokens.color_gray_700};
`;

const LoadingContainer = styled(Box)`
  svg {
    margin-top: ${tokens.spacing_850};
  }
`;

const CONDITION_LABELS = {
  gt: 'above',
  lt: 'below'
};

export default function AlertSummary() {
  const {
    state: {
      metricData,
      conditionData,
      notificationData,
      measurementData: { measurementType }
    }
  } = useAlertForm();
  const [state, dispatch] = React.useReducer(dateTimeReducer, initialState);
  const { selectedMetric } = metricData;
  const hasSelectedMetric = Boolean(selectedMetric.key);
  const forcePrecisionDay = state.selectedRange !== 'day';
  const isEditingAlert = useRouteMatch('/alerts/edit/:alertId');
  const breakdownEnabled = useMemo(
    () => measurementType === METRICS_BREAKDOWN_TYPES.breakdownByProperty,
    [measurementType]
  );

  const formattedMetrics = useMemo(() => {
    if (!hasSelectedMetric) {
      return [];
    }

    return getMetricsFromKeys([selectedMetric], true);
  }, [selectedMetric, hasSelectedMetric]);

  const { from, to } = getRelativeDates(state.selectedRange);

  const { state: reportOptions } = useAnalyticsReportContext();

  const query = toQueryFromOptions({
    filters: reportOptions.filters,
    metrics: formattedMetrics,
    from,
    to,
    precision: state.selectedPrecision,
    timezone: state.selectedTimezone
  });

  const { status, data } = useSparkPostQuery(() => getTimeSeries(query), {
    enabled: Boolean(selectedMetric.key),
    select: (data) => {
      return transformData(data, formattedMetrics);
    }
  });

  const formattedMetric = formattedMetrics[0] || {};
  const unit = formattedMetrics[0]?.unit;

  const { placeholder: displayPlaceholder, unit: selectedUnit } = (selectedMetric?.key &&
    metricsAndUnits.find((el) => el.key === selectedMetric.key)) || {
    placeholder: '',
    unit: 'number'
  };

  const chartConfig = METRICS_UNIT_CONFIG[unit];
  const formatters = getLineChartFormatters(state.selectedPrecision, to);
  const line = [
    {
      key: formattedMetric.key,
      stroke: formattedMetric.stroke,
      dataKey: formattedMetric.key,
      name: formattedMetric.label
    }
  ];

  const setRange = useCallback(
    (e) => {
      dispatch({ type: 'SET_RANGE', value: e.currentTarget.value });
    },
    [dispatch]
  );

  const setTimezone = useCallback(
    ({ value }) => {
      dispatch({ type: 'SET_TIMEZONE', value });
    },
    [dispatch]
  );

  const setPrecision = useCallback(
    (e) => {
      dispatch({ type: 'SET_PRECISION', value: e.currentTarget.value });
    },
    [dispatch]
  );

  const schedule = useMemo(() => {
    return {
      days: notificationData.time.days,
      timeframe: {
        end: notificationData.time.endTime,
        start: notificationData.time.startTime,
        time_zone: notificationData.time.timeZone
      }
    };
  }, [notificationData]);

  return (
    <Box height="296px">
      {status === 'success' || status === 'loading' ? (
        <Panel>
          <Panel.Section>
            <Stack>
              <Heading as="h4">Alert Summary</Heading>
              {!metricData.selectedMetric.key && (
                <Text>
                  The alert summary will auto-populate when a metric and configurations are
                  selected.
                </Text>
              )}
              {metricData.selectedMetric.key && (
                <Box>
                  <TranslatableText>The alert is triggered when </TranslatableText>
                  <Tag color="blue">{metricData.selectedMetric.label}</Tag>
                  <TranslatableText> is </TranslatableText>
                  {conditionData.condition && (
                    <>
                      <Tag color="blue">{CONDITION_LABELS[conditionData.condition]}</Tag>{' '}
                    </>
                  )}

                  {(!conditionData.condition || !conditionData.alertThreshold) && (
                    <TranslatableText>... </TranslatableText>
                  )}

                  {conditionData.alertThreshold && (
                    <Tag color="blue">{`${conditionData.alertThreshold} ${displayPlaceholder}`}</Tag>
                  )}

                  {conditionData.precision && (
                    <>
                      <TranslatableText> at the end of a </TranslatableText>
                      <Tag color="blue">1 {conditionData.precision}</Tag> period.
                    </>
                  )}
                </Box>
              )}
              {reportOptions.filters.length > 0 && (
                <StyledQuote>
                  <ActiveFilters filters={reportOptions.filters} />
                </StyledQuote>
              )}
              {breakdownEnabled && (
                <Box>
                  <TranslatableText>And is broken down by </TranslatableText>
                  {reportOptions.groupBy ? (
                    <Tag color="blue">{GROUP_BY_CONFIG[reportOptions.groupBy].label}</Tag>
                  ) : (
                    <TranslatableText>...</TranslatableText>
                  )}
                </Box>
              )}
            </Stack>
          </Panel.Section>
          <Panel.Section>
            <Stack>
              <Inline>
                <ListBox
                  value={state.selectedRange}
                  onChange={setRange}
                  label="Time Range"
                  id="alert-time-range"
                >
                  <ListBox.Option value="day">Last 24 hours</ListBox.Option>
                  <ListBox.Option value="7days">Last 7 days</ListBox.Option>
                  <ListBox.Option value="30days">Last 30 days</ListBox.Option>
                  <ListBox.Option value="90days">Last 90 days</ListBox.Option>
                </ListBox>
                <TimezoneTypeahead
                  id="alert-time-zone"
                  disabled={forcePrecisionDay}
                  value={state.selectedTimezone}
                  onChange={setTimezone}
                />
                <ListBox
                  id="alert-precision"
                  value={state.selectedPrecision}
                  label="Precision"
                  onChange={setPrecision}
                  disabled={forcePrecisionDay || isEditingAlert}
                >
                  <ListBox.Option value="hour">Hour</ListBox.Option>
                  <ListBox.Option value="day">Day</ListBox.Option>
                </ListBox>
              </Inline>

              {status !== 'loading' ? (
                <LineChart
                  {...formatters}
                  yTickFormatter={chartConfig.yAxisFormatter}
                  showXAxis
                  height={200}
                  unit={selectedUnit}
                  data={data || []}
                  lines={line}
                  tooltip={CustomTooltip}
                  tooltipValueFormatter={chartConfig.yAxisFormatter}
                  showTooltip
                  threshold={conditionData.alertThreshold}
                  operator={conditionData.condition}
                  schedule={schedule}
                />
              ) : (
                <LoadingContainer>
                  <Loading as={Box} minHeight={tokens.spacing_900} />
                </LoadingContainer>
              )}
            </Stack>
          </Panel.Section>
        </Panel>
      ) : (
        <Empty message="No Data Available" description="Select a metric to view graph." />
      )}
    </Box>
  );
}
