import { Method } from 'axios';
import React, { useCallback, useState } from 'react';
import { Banner, Button } from 'src/components/matchbox';
import styled from 'styled-components';

const methodToText = {
  GET: 'loading',
  PUT: 'updating',
  DELETE: 'deleting',
  POST: 'creating'
};

const craftMessage = (method: Method, resource: string): string => {
  if (!method || !resource) {
    return 'Sorry, there was an issue.';
  }

  const message = `Sorry, there was an issue ${methodToText[method]} your ${resource}.`;
  return message;
};

const DetailsP = styled.p`
  margin-top: 20px;
`;

type ApiErrorBannerProps = {
  error?: {
    meta: $TODOFIXME;
    payload: $TODOFIXME;
    resource?: $TODOFIXME;
  };
  errorDetails: $TODOFIXME;
  marginTop?: string;
  message: string;
  reload?: undefined | (() => void);
  status?: 'warning' | 'default' | 'success' | 'danger' | 'info' | 'muted' | undefined;
  title?: string;
};

export default function ApiErrorBanner({
  message,
  errorDetails,
  reload = undefined,
  title = 'An error occurred',
  status = 'warning',
  marginTop = '300',
  error = { payload: {}, meta: {} }
}: ApiErrorBannerProps): JSX.Element {
  const [showErrorDetails, setShowErrorDetails] = useState(false);

  const { payload, meta, resource } = error;
  const showDetailsButton = errorDetails || payload.message;

  const handleShowHideButtonClick = useCallback(() => {
    setShowErrorDetails(!showErrorDetails);
  }, [showErrorDetails]);

  const handleTryAgainButtonClick = useCallback(() => {
    reload && reload();
  }, [reload]);

  const showDetailsText = showErrorDetails ? 'Hide Error Details' : 'Show Error Details';
  return (
    <Banner status={status} title={title} my={marginTop}>
      <p>{message || craftMessage(meta.method, resource)}</p>

      {(reload || showDetailsButton) && (
        <Banner.Actions>
          {reload && (
            <Button
              useMatchboxVariant={false}
              variant="default"
              onClick={handleTryAgainButtonClick}
            >
              Try Again
            </Button>
          )}

          {showDetailsButton && (
            <Button
              useMatchboxVariant={false}
              variant="default"
              onClick={handleShowHideButtonClick}
            >
              <span>{showDetailsText}</span>
            </Button>
          )}
        </Banner.Actions>
      )}

      {showErrorDetails && (
        <DetailsP>
          <strong>Details:</strong> {errorDetails || payload.message}
        </DetailsP>
      )}
    </Banner>
  );
}
