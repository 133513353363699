import { Search } from '@sparkpost/matchbox-icons';
import React, { useMemo } from 'react';
import { useFilters, usePagination, useTable } from 'react-table';
import { ActionPopover, ApiErrorBanner, Empty, Subaccount, TableSkeleton } from 'src/components';
import { Pagination } from 'src/components/collection';
import { PageLink } from 'src/components/links';
import { Panel, ScreenReaderOnly, Stack, Table, Tag, TextField } from 'src/components/matchbox';
import MultiCheckboxDropdown, { useMultiCheckbox } from 'src/components/MultiCheckboxDropdown';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PER_PAGE, PRIMARY_SUBACCOUNT } from 'src/constants';
import { getInlineSeedsOptions } from 'src/helpers/api/seedList';
import { useModal, useSparkPostQuery } from 'src/hooks';
import styled from 'styled-components';
import DisableAccessModal from '../DisableAccessModal';
import EnableAccessModal from '../EnableAccessModal';
import { DISABLED, ENABLED, STATUS_CHECKBOXES } from './constants';

const FilterFields = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.space['400']};
  grid-template-columns: 1fr;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-gap: ${(props) => props.theme.space['500']};
    grid-template-columns: 2fr 1fr 250px; /* Static column width to prevent re-sizing when the user updates their selection */
  }
`;

export function SubaccountsConfigPanel({ getSubaccountName, subaccountsWithD12y }) {
  const { data, status, refetch } = useSparkPostQuery(getInlineSeedsOptions, {
    initialData: [],
    select: (data) => toTableFriendly({ data, getSubaccountName, subaccountsWithD12y })
  });

  const { tableInstance, multiCheckbox } = useSubaccountsConfigPanel({ data });
  const { closeModal, openModal, isModalOpen, meta: { subaccount = {}, name } = {} } = useModal();
  if (status === 'error') {
    return <ApiErrorBanner reload={refetch} />;
  }

  return (
    <Stack>
      <Panel>
        <Panel.Section>
          <FilterFields>
            <TextField
              id="search-subaccounts-field"
              label="Search Subaccounts"
              placeholder="e.g. Subaccount_001"
              prefix={<Search />}
              onChange={(e) => tableInstance.setFilter('Subaccount', e.target.value)}
            />

            <MultiCheckboxDropdown
              id="seeding-status-field"
              label="Seeding Status"
              screenReaderDirections="Checkboxes filter the table. When checked, table rows without the selected value are hidden."
              checkboxes={multiCheckbox.checkboxes}
              allowEmpty={false}
            />
          </FilterFields>
        </Panel.Section>

        {status === 'loading' ? <TableSkeleton numberOfColumns={0} /> : null}

        {tableInstance.rows.length === 0 ? <Empty message="No results found." /> : null}

        <SubaccountsConfigTable tableInstance={tableInstance} openModal={openModal} />
      </Panel>

      <Pagination
        data={tableInstance.rows.map(({ original }) => original)}
        currentPage={tableInstance.state.pageIndex + 1}
        perPage={tableInstance.state.pageSize}
        saveCsv={true}
        onPageChange={(page) => tableInstance.gotoPage(page)}
        onPerPageChange={(perPage) => tableInstance.setPageSize(perPage)}
      />
      <EnableAccessModal
        open={isModalOpen && name === 'Enable Access'}
        onClose={closeModal}
        onSuccess={refetch}
        subaccount={subaccount}
      />

      <DisableAccessModal
        open={isModalOpen && name === 'Disable Access'}
        onClose={closeModal}
        onSuccess={refetch}
        subaccount={subaccount}
      />
    </Stack>
  );
}

function SubaccountsConfigTable({ tableInstance, openModal }) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = tableInstance;
  return (
    <Table title="Subaccount Seeding Activity" {...getTableProps()}>
      <ScreenReaderOnly as="thead">
        {headerGroups.map((headerGroup) => {
          return (
            <Table.Row header {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <Table.HeaderCell {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          );
        })}
      </ScreenReaderOnly>

      <tbody {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row); // `react-table` requires this to be invoked first
          const { values } = row;
          const {
            enabled,
            Subaccount: [subaccountId, subaccountName]
          } = values;

          return (
            <Table.Row key={`subaccount-seeding-activity-row-${index}`} {...row.getRowProps()}>
              <Table.Cell>
                <Subaccount id={subaccountId} name={subaccountName} shrinkLength={24} />
              </Table.Cell>

              <Table.Cell>
                {enabled === ENABLED ? (
                  <Tag color="green">Enabled</Tag>
                ) : (
                  <Tag color="lightGray">Disabled</Tag>
                )}
              </Table.Cell>

              <Table.Cell>
                <ActionPopover
                  actions={[
                    {
                      content: 'Edit Settings',
                      component: PageLink,
                      to: `/seedlist/automatic/config/${subaccountId}`
                    },
                    {
                      content: 'View Activity',
                      component: PageLink,
                      to: `/seedlist/automatic/activity/${subaccountId}`
                    },
                    {
                      is: 'button',
                      content: enabled === ENABLED ? 'Disable Seeding' : 'Enable Seeding',
                      onClick: () =>
                        openModal({
                          name: enabled === ENABLED ? 'Disable Access' : 'Enable Access',
                          subaccount: {
                            name: subaccountName,
                            id: subaccountId
                          }
                        })
                    }
                  ]}
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
      </tbody>
    </Table>
  );
}

function toTableFriendly({ data, getSubaccountName, subaccountsWithD12y: subaccounts }) {
  const subaccountidsInInlineSeedsData = data.map((x) => (x.subaccount_id ? x.subaccount_id : 0));
  const activeSubaccounts = Object.entries(subaccounts)
    .filter(
      (item) => item[1].status === 'active' && !subaccountidsInInlineSeedsData.includes(item[1].id)
    )
    .map((item) => item[1]);
  const inlineSeedDataFormattedForTable = data.map((x) => {
    return {
      subaccountName:
        x.subaccount_id === undefined
          ? getSubaccountName(PRIMARY_SUBACCOUNT.id)
          : getSubaccountName(x.subaccount_id),
      subaccountId: x.subaccount_id === undefined ? PRIMARY_SUBACCOUNT.id : x.subaccount_id,
      enabled: x.enabled ? ENABLED : DISABLED
    };
  });

  const remainingSubaccountsFormattedForTable = activeSubaccounts.map((x) => {
    return {
      subaccountName: x.name,
      subaccountId: x.id,
      enabled: DISABLED
    };
  });

  let allD12ySubaccountsWithStatus = [
    ...inlineSeedDataFormattedForTable,
    ...remainingSubaccountsFormattedForTable
  ];

  const isPrimaryAcctPresent = allD12ySubaccountsWithStatus.find((x) => x.subaccountId === 0);

  if (!isPrimaryAcctPresent) {
    allD12ySubaccountsWithStatus = [
      ...allD12ySubaccountsWithStatus,
      {
        subaccountName: getSubaccountName(PRIMARY_SUBACCOUNT.id),
        subaccountId: PRIMARY_SUBACCOUNT.id,
        enabled: DISABLED
      }
    ];
  }
  return allD12ySubaccountsWithStatus.sort((a, b) => a.subaccountId - b.subaccountId);
}

/**
 * @description Handles merging server state with table state
 * @param {object} options
 * @param {boolean} options.data - Formatted subaccount inline seeding data from `/inline-seeds/options`
 */
function useSubaccountsConfigPanel({ data = [] }) {
  const columns = useMemo(
    () => [
      {
        Header: 'Subaccount',
        accessor: (row) => {
          return [row.subaccountId, row.subaccountName];
        }
      },
      {
        Header: 'Status',
        accessor: 'enabled'
      },
      {
        Header: 'Actions',
        accessor: () => null
      }
    ],
    []
  );
  const tableInstance = useTable(
    {
      columns,
      data,
      autoResetFilters: false, // Avoids filters clearing due to unrelated data changes,
      initialState: {
        pageIndex: DEFAULT_CURRENT_PAGE - 1, // react-table takes a 0 base pageIndex
        pageSize: DEFAULT_PER_PAGE
      }
    },
    useFilters,
    usePagination
  );

  const multiCheckbox = useMultiCheckbox({
    checkboxes: STATUS_CHECKBOXES,
    allowSelectAll: false,
    onChange: (values) => {
      if (values.length === 1 && values.includes('enabled')) {
        return tableInstance.setFilter('enabled', ENABLED);
      }

      if (values.length === 1 && values.includes('disabled')) {
        return tableInstance.setFilter('enabled', DISABLED);
      }

      return tableInstance.setFilter('enabled', undefined);
    }
  });

  return { multiCheckbox, tableInstance };
}
