import { Help } from '@sparkpost/matchbox-icons';
import React from 'react';
import { Tooltip } from 'src/components/matchbox';

const tooltipContent =
  'Domains can be ready for sending (From), sending with DKIM signing, and bounce (Return Path) usage.';

const StatusTooltipHeader = () => (
  <Tooltip dark content={tooltipContent} id="status-tooltip-header">
    Status <Help size={14} />
  </Tooltip>
);

export default StatusTooltipHeader;
