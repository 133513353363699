import React from 'react';
import { Button, Modal, LabelValue, Stack } from 'src/components/matchbox';
import { updateInlineSeedsOptions } from 'src/helpers/api/seedList';
import { useAlert, useSparkPostMutation } from 'src/hooks';

export default function DisableAccessModal({
  open,
  onClose,
  onSuccess,
  subaccount: { name, id } = {}
}) {
  const { showAlert } = useAlert();
  const mutation = useSparkPostMutation(
    () => {
      return updateInlineSeedsOptions({ enabled: false, subaccountId: id });
    },
    { onSuccess: handleSuccess }
  );

  function handleSuccess() {
    onClose();
    onSuccess();
    showAlert({ type: 'success', message: `Disabled Automatic Inline Seeding for ${name}(${id})` });
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header showCloseButton>Disable Automatic Inline Seeding</Modal.Header>

      <Modal.Content>
        <Stack>
          <p>Disable pending and future Automatic Seeding for this subaccount.</p>
          <LabelValue>
            <LabelValue.Label>Subaccount</LabelValue.Label>
            <LabelValue.Value>
              {name}({id})
            </LabelValue.Value>
          </LabelValue>
        </Stack>
      </Modal.Content>

      <Modal.Footer>
        <Button
          color="red"
          useMatchboxVariant
          loading={mutation.status === 'loading'}
          onClick={mutation.mutate}
        >
          Disable Seeding
        </Button>

        <Button
          color="gray"
          outlineBorder
          useMatchboxVariant
          disabled={mutation.status === 'loading'}
          onClick={onClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
