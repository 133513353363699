import React from 'react';
import { useSparkPostQuery, usePrepareAnalyticsReportQuery } from 'src/hooks';
import {
  DELIVERABILITY_LINKS_METRIC_KEYS,
  LINKS_BY_DOMAIN_METRIC_KEYS
} from '@sparkpost/report-builder/config';
import { ApiErrorBanner, Loading } from 'src/components';
import { Panel } from 'src/components/matchbox';
import { getMetricsFromKeys, toFilterFromComparison } from 'src/helpers/metrics';
import { getEngagement, getDeliverability } from 'src/helpers/api/metrics';
import { LinksTable } from '../tables';
import { useAnalyticsReportContext } from '../../context/AnalyticsReportContext';
import { TAB_LOADING_HEIGHT } from '../../constants';

export default function LinksTab({ comparison }) {
  const { aggregatesQuery, linksQuery, isPending, isError } = useQueriesWithComparison(comparison);

  function handleReload() {
    linksQuery.refetch();
    aggregatesQuery.refetch();
  }

  if (isPending) {
    return <Loading minHeight={TAB_LOADING_HEIGHT} />;
  }

  if (isError) {
    return (
      <Panel.Section>
        <ApiErrorBanner reload={handleReload} status="muted" />
      </Panel.Section>
    );
  }

  return (
    <LinksTable
      links={linksQuery.data}
      totalClicks={aggregatesQuery.data.count_clicked}
      loading={false}
    />
  );
}

/**
 * Prepares request parameters using common hooks, then leverages helper functions to determine which `metrics` are passed as arguments to each request.
 *
 * @param {Object} comparison - passed in comparison set by the user via the "Compare By" feature
 *
 */
function useQueriesWithComparison(comparison) {
  const { state: reportOptions } = useAnalyticsReportContext();
  const deliverabilityMetrics = getMetricsFromKeys(DELIVERABILITY_LINKS_METRIC_KEYS);
  const linkMetrics = getMetricsFromKeys(LINKS_BY_DOMAIN_METRIC_KEYS);
  const existingFilters = reportOptions.filters ? reportOptions.filters : [];
  const comparisonFilter = comparison ? [toFilterFromComparison(comparison)] : [];
  const aggregatesArgs = usePrepareAnalyticsReportQuery({
    ...reportOptions,
    filters: [...existingFilters, ...comparisonFilter],
    metrics: deliverabilityMetrics
  });
  const linkArgs = usePrepareAnalyticsReportQuery({
    ...reportOptions,
    filters: [...existingFilters, ...comparisonFilter],
    metrics: linkMetrics
  });
  const linksQuery = useSparkPostQuery(() => getEngagement(linkArgs), {
    refetchOnWindowFocus: true
  });
  const aggregatesQuery = useSparkPostQuery(() => getDeliverability(aggregatesArgs), {
    refetchOnWindowFocus: true,
    select: (data) => data[0]
  });

  return {
    aggregatesQuery,
    linksQuery,
    isPending: linksQuery.status === 'loading' || aggregatesQuery.status === 'loading',
    isError: linksQuery.status === 'error' || aggregatesQuery.status === 'error'
  };
}
