import EditPage from './EditPage';
import CreatePage from './CreatePage';
import NewCreatePage from './NewCreatePage/index';
import NewDetailsPage from './NewDetailsPage/index';
import ListPage from './ListPage';
import DetailsPage from './DetailsPage';

export default {
  CreatePage,
  NewCreatePage,
  NewDetailsPage,
  EditPage,
  ListPage,
  DetailsPage
};
