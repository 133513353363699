import cookie from 'js-cookie';
import _ from 'lodash';
import qs from 'query-string';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { register as registerAction } from 'src/actions/account';
import { authenticate as authenticateAction } from 'src/actions/auth';
import {
  get as getCurrentUserAction,
  updateUserUIOptions as updateUserUIOptionsAction
} from 'src/actions/currentUser';
import { inSPCEU } from 'src/appConfig/tenant';
import { CenteredLogo } from 'src/components';
import { PageLink } from 'src/components/links';
import { Error, Panel, Stack } from 'src/components/matchbox';
import {
  AFTER_JOIN_REDIRECT_ROUTE,
  ANALYTICS_CREATE_ACCOUNT,
  AWS_COOKIE_NAME,
  CROSS_LINK_MAP,
  LINKS,
  RV_AFTER_JOIN_REDIRECT_ROUTE
} from 'src/constants';
import { withInstrumentation, withInstrumentationContext } from 'src/context/Instrumentation';
import ConsentManager from 'src/context/Instrumentation/ConsentManager';
import * as analytics from 'src/helpers/analytics';
import getConfig from 'src/helpers/getConfig';
import { loadScript } from 'src/helpers/loadScript';
import { RUDDERSTACK_EVENTS } from 'src/helpers/rudderstack';
import track from 'src/helpers/track';
import JoinError from './components/JoinError';
import JoinForm from './components/JoinForm';
import SignUpTabs from './components/SignUpTabs';
import { extractQueryParams } from './helpers/extractQueryParams';

const filteredData = (data) => {
  const filtered = {
    company_name: _.get(data, 'company_name'),
    email: _.get(data, 'email'),
    first_name: _.get(data, 'first_name'),
    last_name: _.get(data, 'last_name'),
    tou_accepted: _.get(data, 'tou_accepted'),
    creation_params: _.get(data, 'creation_params')
  };

  return filtered;
};

export const JoinPage = ({ track: birdTrack }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});

  const authenticate = useCallback(
    (username, password) => dispatch(authenticateAction(username, password)),
    [dispatch]
  );
  const register = useCallback((data) => dispatch(registerAction(data)), [dispatch]);
  const getCurrentUser = useCallback(() => dispatch(getCurrentUserAction()), [dispatch]);
  const updateUserUIOptions = useCallback(
    (data) => dispatch(updateUserUIOptionsAction(data)),
    [dispatch]
  );

  const account = useSelector((state) => state.account);

  const isAWSsignUp = !!cookie.get(AWS_COOKIE_NAME);
  const isSPCEU = inSPCEU();

  const location = useLocation();

  const params = useMemo(() => qs.parse(location.search), [location.search]);

  const registerSubmit = useCallback(
    (values) => {
      setFormData(values);
      const { plan, product } = params;

      const {
        sfdcid,
        attributionData,
        creationParams,
        video: sawVideoWhileSignUp
      } = extractQueryParams(params);

      const accountFields = _.omit(values, 'email_opt_in');

      const signupData = {
        ...accountFields,
        sfdcid,
        salesforce_data: { ...attributionData, email_opt_out: !values.email_opt_in },
        creation_params: { ...creationParams, email_opt_in: Boolean(values.email_opt_in) }
      };

      return register(signupData)
        .then((accountData) => {
          analytics.setVariable('username', accountData.username);
          analytics.trackFormSuccess(ANALYTICS_CREATE_ACCOUNT, {
            form_type: ANALYTICS_CREATE_ACCOUNT
          });
          track(RUDDERSTACK_EVENTS.ACCOUNT_CREATED);
          birdTrack(
            'formSubmit',
            Object.assign(
              { formId: 'Product Signup', source: window.location.host },
              filteredData(signupData)
            )
          );
          return authenticate(accountData.username, values.password);
        })
        .then(() => {
          getCurrentUser().then(() => {
            updateUserUIOptions({ sawVideoWhileSignUp });
          });

          if (product === 'rv') {
            return history.push(RV_AFTER_JOIN_REDIRECT_ROUTE);
          }

          return history.push(AFTER_JOIN_REDIRECT_ROUTE, { plan });
        });
    },
    [authenticate, birdTrack, getCurrentUser, history, params, register, updateUserUIOptions]
  );

  const { createError } = account;
  const brand = CROSS_LINK_MAP[getConfig('crossLinkTenant')];
  const signUpText = `Sign Up for SparkPost${isSPCEU ? ' EU' : ''}`;

  return (
    <div>
      {/* eslint-disable-next-line local/restrict-translatable-text */}
      {loadScript({ url: LINKS.RECAPTCHA_LIB_URL })}
      <CenteredLogo showAwsLogo={isAWSsignUp} />
      <Panel.LEGACY>
        {brand && <SignUpTabs brand={brand} isSPCEU={isSPCEU} location={location} />}
        <Panel.LEGACY.Section>
          <Stack>
            <h3>{signUpText}</h3>
            {createError && <Error error={<JoinError errors={createError} data={formData} />} />}
            <JoinForm onSubmit={registerSubmit} />
          </Stack>
        </Panel.LEGACY.Section>
      </Panel.LEGACY>
      <Panel.LEGACY.Footer
        left={
          <small>
            Already have an account? <PageLink to="/auth">Log In</PageLink>.
          </small>
        }
      />

      <ConsentManager />
    </div>
  );
};

export default withInstrumentationContext(withInstrumentation(JoinPage));
