import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { getAccountSingleSignOnDetails } from 'src/actions/accountSingleSignOn';
import { getSubaccount } from 'src/actions/subaccounts';
import { resetMfa } from 'src/actions/tfa';
import { deleteUser, listUsers, updateUser } from 'src/actions/users';
import { FORMS } from 'src/constants';
import { selectUserById } from 'src/selectors/users';

import EditPageV2 from './EditPageV2';

const FlaggedEditPage = (props) => {
  return <EditPageV2 {...props} />;
};

const mapStateToProps = (state, props) => {
  const user = selectUserById(state, props.match.params.id);

  return {
    accountSingleSignOn: state.accountSingleSignOn,
    currentUser: state.currentUser,
    isAccountSingleSignOnEnabled: state.accountSingleSignOn.enabled,
    loading:
      state.users.loading || state.subaccounts.getLoading || state.accountSingleSignOn.loading,
    loadingError: state.users.error || state.accountSingleSignOn.error,
    subaccount: state.subaccounts.subaccount,
    user,
    users: state.users.entities,
    updatePending: state.users.updatePending,
    resetTogglePending: state.tfa.resetTogglePending,
    tfaRequired: state.account.tfa_required,
    initialValues: {
      access: _.get(user, 'access'),
      is_sso: _.get(user, 'is_sso')
    }
  };
};

const mapDispatchToProps = {
  updateUser,
  listUsers,
  deleteUser,
  getAccountSingleSignOnDetails,
  getSubaccount,
  resetMfa
};
const formOptions = { form: FORMS.EDIT_USER, enableReinitialize: true };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm(formOptions)(FlaggedEditPage));
