export function getSubaccounts({ params } = {}) {
  return {
    method: 'GET',
    url: '/v1/subaccounts',
    params
  };
}

export function getSubaccount({ id, params } = {}) {
  return {
    method: 'GET',
    url: `/v1/subaccounts/${id}`,
    params
  };
}

/**
 * @description Returns headers object for use with API requests
 * @param {number} subaccountId - relevant subaccount ID
 * @returns {object} headers handed off to axios to make a network request. Intended to be empty when no subaccount ID is present
 */
export function getHeaders(subaccountId) {
  let headers = {};

  if (subaccountId) {
    headers['x-msys-subaccount'] = subaccountId;
  }

  return headers;
}
