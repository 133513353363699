import React from 'react';
import { ApiErrorBanner } from 'src/components';
import { Loading } from 'src/components/loading';
import { Stack } from 'src/components/matchbox';
import { getAccount } from 'src/helpers/api/account';
import { hasAccountOptionEnabled } from 'src/helpers/api/selectors/account';
import { useSparkPostQuery, useSubaccounts } from 'src/hooks';
import { GuideBanner } from './GuideBanner';
import { SubaccountAccessPanel } from './SubaccountAccessPanel';
import { SubaccountsConfigPanel } from './SubaccountsConfigPanel';

export function SubaccountsListConfig() {
  const {
    status,
    data: primaryAcctEnabled,
    error,
    refetch
  } = useSparkPostQuery(getAccount, {
    select: (state) => hasAccountOptionEnabled('inline_seeds')(state)
  });

  const {
    subaccountsQuery,
    subaccountsById = {},
    getSubaccountName
  } = useSubaccounts({
    withD12yOption: true
  });

  const isLoading = status === 'loading' || subaccountsQuery.status === 'loading';

  const hasError = status === 'error' || subaccountsQuery.status === 'error';

  if (isLoading) return <Loading />;

  if (hasError)
    return (
      <ApiErrorBanner
        message="Sorry, we seem to have had some trouble loading your account information."
        errorDetails={error.message}
        reload={() => {
          refetch();
          subaccountsQuery.refetch();
        }}
      />
    );

  return (
    <Stack>
      <GuideBanner />

      <SubaccountAccessPanel primaryAcctEnabled={primaryAcctEnabled} refetch={refetch} />

      <SubaccountsConfigPanel
        getSubaccountName={getSubaccountName}
        subaccountsWithD12y={subaccountsById}
      />
    </Stack>
  );
}
