import React from 'react';
import { format, getUnixTime } from 'date-fns';
import { ChevronRight } from '@sparkpost/matchbox-icons';
import { FORMATS } from 'src/constants';
import { Empty } from 'src/components';
import { Box, Button, ScreenReaderOnly, Stack, Table, Tag, Text } from 'src/components/matchbox';
import { PageLink } from 'src/components/links';
import { TranslatableText } from 'src/components/text';
import { ScreenReaderExclude } from 'src/components/a11y';
import { getFriendlyImpactNameByScore, getTagColorByImpactScore } from './helpers';

export function BlocklistIncidentsTable({ tableInstance }) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, rows } = tableInstance;

  if (rows.length === 0) {
    return <Empty message="No results found." />;
  }

  return (
    <Table title="Blocklist Incidents" {...getTableProps()}>
      <ScreenReaderOnly as="thead">
        {headerGroups.map((headerGroup) => {
          return (
            <Table.Row header {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <Table.HeaderCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          );
        })}
      </ScreenReaderOnly>

      <tbody {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row); // `react-table` requires this to be invoked first
          const { values, original } = row;
          const { blocklistName, dateListed, dateResolved, id, impactScore, resource, status } =
            values;
          const { monitorId, assignment } = original;
          const formattedDateListed = format(dateListed, FORMATS.DATE_FNS.DATETIME_WITH_YEAR);

          // When `date_resolved` is falsy (e.g. `null`), it is still converted to a date to help handle table sorting.
          // As a result, its value is the beginning of the Unix epoch, and has a value of 0. By checking
          // here for this particular value, we still allow for sorting by date without rendering the date unnecessarily.
          const formattedDateResolved =
            getUnixTime(dateResolved) !== 0
              ? format(dateResolved, FORMATS.DATE_FNS.DATETIME_WITH_YEAR)
              : undefined;

          return (
            <Table.Row key={`blocklist-incidents-row-${id}-${index}`} {...row.getRowProps()}>
              <Table.Cell>
                <Stack space="100">
                  <Box>
                    <Text as="span" fontWeight="medium">
                      <TranslatableText>{resource}</TranslatableText>
                    </Text>

                    <ScreenReaderExclude>
                      <TranslatableText> on </TranslatableText>

                      <Text as="span" fontWeight="medium">
                        <TranslatableText>{blocklistName}</TranslatableText>
                      </Text>
                    </ScreenReaderExclude>
                  </Box>
                  <ScreenReaderExclude as={Text} fontSize="200">
                    {assignment}
                  </ScreenReaderExclude>

                  <ScreenReaderExclude as={Text} fontSize="200">
                    <TranslatableText>Date Listed: </TranslatableText>

                    <TranslatableText>{formattedDateListed}</TranslatableText>
                  </ScreenReaderExclude>

                  {formattedDateResolved ? (
                    <ScreenReaderExclude as={Text} fontSize="200">
                      <TranslatableText>Date Resolved: </TranslatableText>

                      <TranslatableText>{formattedDateResolved}</TranslatableText>
                    </ScreenReaderExclude>
                  ) : null}
                </Stack>
              </Table.Cell>

              <ScreenReaderOnly as={Table.Cell}>{blocklistName}</ScreenReaderOnly>

              <ScreenReaderOnly as={Table.Cell}>{assignment}</ScreenReaderOnly>

              <ScreenReaderOnly as={Table.Cell}>{formattedDateListed}</ScreenReaderOnly>

              <ScreenReaderOnly as={Table.Cell}>
                {formattedDateResolved ? formattedDateResolved : 'No Resolved Date'}
              </ScreenReaderOnly>

              <Table.Cell>
                <ScreenReaderOnly>{status}</ScreenReaderOnly>

                <ScreenReaderExclude as={Stack} space="100">
                  {status ? (
                    <Box>
                      <Tag>{status}</Tag>
                    </Box>
                  ) : null}

                  <Box>
                    <Tag color={getTagColorByImpactScore(impactScore)}>
                      {getFriendlyImpactNameByScore(impactScore)}
                    </Tag>
                  </Box>
                </ScreenReaderExclude>
              </Table.Cell>

              <ScreenReaderOnly as={Table.Cell}>
                {getFriendlyImpactNameByScore(impactScore)}
              </ScreenReaderOnly>

              <Table.Cell align="right">
                <PageLink
                  as={Button}
                  to={`/signals/blocklist/monitors/${monitorId}/incidents/${id}`}
                  variant="minimal"
                  size="small"
                >
                  <TranslatableText>Incident Details </TranslatableText>

                  <Button.Icon as={ChevronRight} />
                </PageLink>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </tbody>
    </Table>
  );
}
