import React from 'react';
import { connect } from 'react-redux';
import { Page, Panel, Stack } from 'src/components/matchbox';
import LabelledValue from 'src/components/labelledValue/LabelledValue';
import CancellationPanel from './components/CancellationPanel';
import SingleSignOnPanel from './components/SingleSignOnPanel';
import EnforceTfaPanel from './components/EnforceTfaPanel';
import UIOptionsPanel from './components/UIOptionsPanel';
import { hasAccountOptionEnabled } from 'src/helpers/conditions/account';
import { useAuthentication } from 'src/context/AuthenticationContext';

export function AccountSettingsPage({ currentUser, tfaRequiredEnforced }) {
  const {
    context: { isAuth0User }
  } = useAuthentication();

  return (
    <Page title="Account Settings">
      <Stack>
        <Panel.LEGACY sectioned>
          <LabelledValue label="Account ID">{currentUser.customer}</LabelledValue>
        </Panel.LEGACY>
        {!tfaRequiredEnforced && <SingleSignOnPanel />}
        {!isAuth0User && <EnforceTfaPanel />}
        <UIOptionsPanel />
        <CancellationPanel />
      </Stack>
    </Page>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    tfaRequiredEnforced: hasAccountOptionEnabled('enforce_tfa_required')(state)
  };
};

export default connect(mapStateToProps)(AccountSettingsPage);
