import _ from 'lodash';
import { useEffect, useReducer } from 'react';
import { usePrevious } from 'src/hooks';

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_VALUES':
      const SPACE = 32;
      const COMMA = 188;

      if (action.value.length === 0) {
        return { ...state };
      }

      // This helps when the user pastes in values with commas
      if (action.keyCodes.includes(COMMA) && action.value.includes(',')) {
        const additionalValues = action.value.split(',').map((string) => string.trim());
        const validationError = action.validate?.(additionalValues);
        if (validationError) {
          return { ...state, value: '', error: validationError };
        }
        return {
          ...state,
          value: '',
          valueList: [...state.valueList, ...additionalValues],
          error: undefined
        };
      }

      // This helps when the user pastes in values with spaces
      if (action.keyCodes.includes(SPACE) && action.value.includes(' ')) {
        const additionalValues = action.value.split(' ');

        const validationError = action.validate?.(additionalValues);
        if (validationError) {
          return { ...state, value: '', error: validationError };
        }

        return {
          ...state,
          value: '',
          valueList: [...state.valueList, ...additionalValues],
          error: undefined
        };
      }

      const validationError = action.validate?.([action.value]);
      if (validationError) {
        return { ...state, value: '', error: validationError };
      }

      return {
        ...state,
        value: '',
        valueList: [...state.valueList, action.value],
        error: undefined
      };

    case 'SET_VALUE_LIST': {
      return { ...state, valueList: action.valueList };
    }

    case 'REMOVE_VALUE': {
      // Filter out any values that match the target for removal
      const valueList = state.valueList.filter((item) => {
        return !_.isEqual(action.value, item);
      });

      return { ...state, valueList };
    }

    case 'ERROR': {
      return { ...state, error: action.error };
    }

    case 'VALUE_CHANGE': {
      // Remove the minimum length error as the user's entry changes, but only when the problem is fixed
      if (
        state.minLength &&
        (action.value.length === 0 || action.value.length >= state.minLength)
      ) {
        return { ...state, value: action.value, error: undefined };
      }

      return { ...state, value: action.value };
    }

    default:
      throw new Error(`${action.type} is not supported by useMultiEntry.`);
  }
}

const defaultInitialState = {
  value: '',
  valueList: [],
  error: undefined // TODO: If other error scenarios are introduced, this should be an object that contains each type of error
};

export default function useMultiEntry(initialState, { validate, keyCodes = [32, 13, 188] } = {}) {
  const prevInitialState = usePrevious(initialState);
  const [state, dispatch] = useReducer(reducer, { ...defaultInitialState, ...initialState });
  const hasMinLengthError = (value) =>
    state.minLength && value.length > 0 && value.length < state.minLength;

  // If the initial state updates (i.e., through an external source of state),
  // update the value list accordingly
  useEffect(() => {
    if (!_.isEqual(prevInitialState, initialState)) {
      return dispatch({ type: 'SET_VALUE_LIST', valueList: initialState.valueList });
    }
  }, [prevInitialState, initialState]);

  function handleKeyDown(e) {
    // Spacebar or enter key or comma
    if (keyCodes.includes(e.keyCode)) {
      e.preventDefault();

      // Configurable min length returns an error when attempting to update valueList before meeting the requirement
      if (hasMinLengthError(e.target.value)) {
        return dispatch({
          type: 'ERROR',
          error: `${state.minLength} or more characters required`
        });
      }

      return dispatch({ type: 'UPDATE_VALUES', value: e.target.value, validate, keyCodes });
    }
  }

  function handleRemove(target) {
    return dispatch({ type: 'REMOVE_VALUE', value: target });
  }

  function handleChange(e) {
    return dispatch({ type: 'VALUE_CHANGE', value: e.target.value });
  }

  function handleBlur(e) {
    // Configurable min length returns an error when attempting to update valueList before meeting the requirement
    if (hasMinLengthError(e.target.value)) {
      return dispatch({
        type: 'ERROR',
        error: `${state.minLength} or more characters required`
      });
    }

    return dispatch({ type: 'UPDATE_VALUES', value: e.target.value, validate, keyCodes });
  }

  return {
    value: state.value,
    valueList: state.valueList,
    minLength: state.minLength,
    error: state.error,
    handleKeyDown,
    handleBlur,
    handleChange,
    handleRemove
  };
}
