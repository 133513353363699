import _ from 'lodash';
import { formatDateTime, formatDateTimeWithoutYear } from '@sparkpost/report-builder/helpers/date';
import { createSelector, createStructuredSelector } from 'reselect';
import { formatInTimeZone } from 'date-fns-tz';

const getMessageEvents = (state) => state.messageEvents.events;
const getMessageEventsCSV = (state) => state.messageEvents.eventsCSV;
const getMessageHistory = (state) => state.messageEvents.history;
const getSingleSelectedEvent = (state) => state.messageEvents.selectedEvent;
export const getMessageIdParam = (state, props) => props.match.params.messageId;
const getEventIdParam = (state, props) => props.match.params.eventId;

const appendFormattedDate = (event) => ({
  ...event,
  formattedDate: formatDateTime(event.timestamp)
});

const appendFormattedDateWithoutYear = (event) => ({
  ...event,
  formattedDate: formatDateTimeWithoutYear(event.timestamp)
});

export const selectMessageEvents = createSelector([getMessageEvents], (events) =>
  _.map(events, appendFormattedDateWithoutYear)
);

export const selectMessageEventsCSV = createSelector([getMessageEventsCSV], (events) =>
  _.map(events, appendFormattedDate)
);

export const selectMessageHistory = createSelector(
  [getMessageHistory, getMessageIdParam],
  (history, id) => _.map(history[id], appendFormattedDate)
);

export const selectInitialEventId = createSelector(
  [selectMessageHistory, getEventIdParam],
  (messageHistory, selectedEventId) => selectedEventId || _.get(messageHistory[0], 'event_id')
);

const selectMessageEventsDateOptions = (state) => {
  const from = _.get(state, 'messageEvents.search.dateOptions.from', new Date());
  const to = _.get(state, 'messageEvents.search.dateOptions.to', new Date());

  const strUTCFrom = formatInTimeZone(from, 'UTC', "yyyy-MM-dd'T'HH:mm:ssX");
  const strUTCTo = formatInTimeZone(to, 'UTC', "yyyy-MM-dd'T'HH:mm:ssX");

  return {
    from: strUTCFrom,
    to: strUTCTo,
    range: _.get(state, 'messageEvents.search.dateOptions.relativeRange')
  };
};

const selectSearch = (state) => _.omit(state.messageEvents.search, ['dateOptions']);

/**
 * Converts reportOptions for url sharing for message events
 */
export const selectMessageEventsSearchOptions = createSelector(
  [selectMessageEventsDateOptions, selectSearch],
  (dates, search) => ({ ...dates, ...search })
);

export const isMessageHistoryEmpty = createSelector(
  [getMessageHistory, getMessageIdParam],
  (history, id) => _.get(history, id, []).length === 0
);

export const getSelectedEventFromMessageHistory = createSelector(
  [selectMessageHistory, getEventIdParam],
  (messageHistory, eventId) => _.find(messageHistory, ['event_id', eventId])
);

//whether the event is without a message_id (defaulted to _noid_)
const isOrphanEvent = createSelector([getMessageIdParam], (messageId) => messageId === '_noid_');

const getSelectedEvent = createSelector(
  [isOrphanEvent, getSingleSelectedEvent, getSelectedEventFromMessageHistory],
  (isOrphanEvent, selectedEvent, eventFromMessageHistory) =>
    isOrphanEvent ? selectedEvent : eventFromMessageHistory
);

// Due to problems with the API, users may see an event rendered to the page via the list endpoint,
// only to find the data missing when clicking "View Details". Here we check to see if the data is available,
// and if not, retry the request a few times to see if the issue has been resolved on the server.
const shouldRetryOrRedirect = createSelector(
  [isOrphanEvent, isMessageHistoryEmpty, getSelectedEvent],
  (isOrphanEvent, isMessageHistoryEmpty, selectedEvent) => {
    return (!isOrphanEvent && isMessageHistoryEmpty) || !selectedEvent;
  }
);

export const eventPageMSTP = () =>
  createStructuredSelector({
    isOrphanEvent,
    shouldRetryOrRedirect,
    loading: (state) =>
      !!(
        state.messageEvents.historyLoading ||
        state.messageEvents.documentationLoading ||
        state.messageEvents.selectedEventLoading
      ),
    messageHistory: selectMessageHistory,
    messageId: getMessageIdParam,
    documentation: (state) => state.messageEvents.documentation,
    selectedEventId: selectInitialEventId,
    selectedEvent: getSelectedEvent
  });
