import React from 'react';
import { Label, ScreenReaderOnly } from 'src/components/matchbox';
import styled from 'styled-components';

const StyledFieldset = styled('fieldset')`
  border-style: none;
  margin: 0;
  padding: 0;
`;

interface FieldSetProps {
  children: React.ReactNode;
  'data-id': string;
  legend: string;
  legendHidden: boolean;
}

const FieldSet = ({ children, legend, legendHidden = false, 'data-id': dataId }: FieldSetProps) => (
  <StyledFieldset data-id={dataId}>
    {legendHidden ? (
      <ScreenReaderOnly as="legend">{legend}</ScreenReaderOnly>
    ) : (
      <Label as="legend">{legend}</Label>
    )}
    {children}
  </StyledFieldset>
);

export default FieldSet;
