import React from 'react';
import { PolymorphicComponentProps } from 'src/typescript';

type ScreenReaderExcludeProps<E extends React.ElementType> = PolymorphicComponentProps<
  E,
  React.PropsWithChildren<{}>
>;

/**
 * @description Hides child content from screen reader users
 */
export const ScreenReaderExclude = <E extends React.ElementType = 'span'>(
  props: ScreenReaderExcludeProps<E>
): JSX.Element => {
  const { as: asComponent = 'span', children, ...rest } = props;
  const Component = asComponent;

  return (
    <Component {...rest} aria-hidden="true">
      {children}
    </Component>
  );
};
