import { INDUSTRY_BENCHMARK_METRICS_MAP } from '@sparkpost/report-builder/config';
import moment from 'moment';
import { useMemo } from 'react';
import { getIndustryBenchmark } from 'src/helpers/api/metrics';
import { useSparkPostQuery } from 'src/hooks';

import { roundBoundaries } from '@sparkpost/report-builder/helpers/date';
import { toQueryFromOptions } from '@sparkpost/report-builder/helpers/metrics';
import { formatISO, isSameDay } from 'date-fns';
import { getMetricsFromKeys } from 'src/helpers/metrics';

function useIndustryBenchmark(reportOptions) {
  const {
    metrics,
    industryBenchmarkFilters: { industryCategory = 'all', mailboxProvider = 'all' } = {},
    industryBenchmarkMetric
  } = reportOptions;

  const today = new Date();

  // Prepares params for request
  const formattedMetrics = useMemo(() => {
    return getMetricsFromKeys(metrics, true);
  }, [metrics]);

  const formattedOptions = useMemo(() => {
    // moment parses an undefined value to `now`
    // formatISO needs a valid date otherwise it throws an error
    const { from, to } = roundBoundaries({
      from: formatISO(reportOptions.from || today),
      to: formatISO(reportOptions.to || today),
      precision: 'day'
    });
    return toQueryFromOptions({
      ...reportOptions,
      metrics: formattedMetrics,
      from: moment(from).toISOString(),
      to: moment(to).toISOString()
    });
  }, [reportOptions, today, formattedMetrics]);

  const queryEnabled = useMemo(() => {
    return (
      Boolean(metrics?.some((metric) => INDUSTRY_BENCHMARK_METRICS_MAP[metric])) &&
      Boolean(industryBenchmarkMetric)
    );
  }, [metrics, industryBenchmarkMetric]);

  const { data, status } = useSparkPostQuery(
    () => {
      return getIndustryBenchmark({
        to: formattedOptions.to,
        from: formattedOptions.from,
        mailbox_provider: mailboxProvider,
        industry_category: industryCategory
      });
    },
    {
      enabled: queryEnabled
    }
  );

  const formattedData = useMemo(() => {
    if (status !== 'success') {
      return data;
    }
    return data.filter(({ ts }) => !isSameDay(new Date(ts), new Date())); //Removes if current day so that way it can't get data not ready yet
  }, [data, status]);

  return {
    data: queryEnabled ? formattedData : [], //If query gets enabled externally, "clears" data
    status,
    industryCategory,
    mailboxProvider
  };
}

export default useIndustryBenchmark;
