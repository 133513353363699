import React from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { PageLink } from 'src/components/links';
import { Page, Stack, Tabs } from 'src/components/matchbox';
import { hasGrants } from 'src/helpers/conditions';
import {
  AccountConfig,
  AccountConfigRouteHandler,
  AutomaticSeedingRouteHandler,
  SubaccountsListConfig
} from './components';
import { AUTOMATIC_SEEDING_ROUTE, MANUAL_SEEDING_ROUTE, SEEDING_ACTIVITY_ROUTE } from './constants';
import { ManualSeedingPage } from './ManualSeedingPage';

export function SeedListPage() {
  const { pathname } = useLocation();
  const { subaccountId } = useParams();
  const history = useHistory();
  const onSeedlistRoute = useRouteMatch(`/seedlist`)?.isExact;
  const { redirectToPromoPage, showAutomaticSeeding } = useGrants();

  // TODO: There should be a way to configure a conditional redirect in `src/appConfig/routes`
  if (redirectToPromoPage) {
    return <Redirect to="/seedlist/promo" />;
  }

  if (onSeedlistRoute && showAutomaticSeeding) {
    return <Redirect to="/seedlist/automatic" />;
  }

  if (onSeedlistRoute && !showAutomaticSeeding) {
    return <Redirect to="/seedlist/manual" />;
  }

  const TABS = [
    {
      content: 'Automatic Seeding',
      key: AUTOMATIC_SEEDING_ROUTE,
      onClick: () => history.push(AUTOMATIC_SEEDING_ROUTE)
    },
    {
      content: 'Manual Seeding',
      key: MANUAL_SEEDING_ROUTE,
      onClick: () => history.push(MANUAL_SEEDING_ROUTE)
    }
  ];

  const tabIndex = TABS.findIndex(({ key }) => pathname.includes(key));

  const activityRoute = subaccountId
    ? `${SEEDING_ACTIVITY_ROUTE}/${subaccountId}`
    : SEEDING_ACTIVITY_ROUTE;

  const activityRouteLocation = {
    pathname: activityRoute,
    state: { fromAutomatic: tabIndex === 0 ? true : false }
  };

  return (
    <Page
      title="Seed List Settings"
      primaryAction={
        showAutomaticSeeding
          ? {
              content: 'View Automatic Seeding Activity',
              to: activityRouteLocation,
              as: PageLink
            }
          : null
      }
    >
      <Stack>
        {showAutomaticSeeding && <Tabs tabs={TABS} selected={tabIndex} />}

        {/* Be careful editing these - the order of the routes is important for conditional rendering */}
        <Switch>
          <Route path={MANUAL_SEEDING_ROUTE}>
            <TabPanel>
              <ManualSeedingPage />
            </TabPanel>
          </Route>

          <Route path={`${AUTOMATIC_SEEDING_ROUTE}/config/list`}>
            <TabPanel>
              <SubaccountsListConfig />
            </TabPanel>
          </Route>

          <Route path={`${AUTOMATIC_SEEDING_ROUTE}/config/:subaccountId`}>
            <TabPanel>
              <AccountConfig />
            </TabPanel>
          </Route>

          <Route path={`${AUTOMATIC_SEEDING_ROUTE}/config`}>
            <TabPanel>
              <AccountConfigRouteHandler />
            </TabPanel>
          </Route>

          <Route path={AUTOMATIC_SEEDING_ROUTE}>
            <TabPanel>
              <AutomaticSeedingRouteHandler />
            </TabPanel>
          </Route>
        </Switch>
      </Stack>
    </Page>
  );
}

function TabPanel({ children }) {
  const { showAutomaticSeeding } = useGrants();

  if (showAutomaticSeeding) return <div role="tabpanel">{children}</div>;

  // If there are no tabs, then it doesn't make sense to render tabpanels
  return <span>{children}</span>;
}

/**
 * @description Handles logic for conditionally rendering page content according to relevant user/account grants
 */
function useGrants() {
  const redirectToPromoPage = useSelector((state) => !hasGrants('seeds/view')(state));
  const showAutomaticSeeding = useSelector((state) => {
    return hasGrants('inline-seeds/read-write')(state);
  });

  return { redirectToPromoPage, showAutomaticSeeding };
}
