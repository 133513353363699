import { roundToPlaces } from '@sparkpost/report-builder/helpers/units';
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Label, Slider, TextField } from 'src/components/matchbox';
import { clamp } from 'src/helpers/math';

const useSliderWrapper = ({ min, max, precision, value }) => {
  const cleanAndClamp = useCallback(
    (rawValue) => {
      const roundedValue = roundToPlaces(rawValue || min, precision);
      return clamp(roundedValue, min, max);
    },
    [max, min, precision]
  );
  const [internalValue, setInternalValue] = useState(() => cleanAndClamp(value));

  // listen for changes to value
  useEffect(() => {
    const nextValue = cleanAndClamp(value);
    setInternalValue(nextValue);
  }, [cleanAndClamp, value]);

  return { cleanAndClamp, setInternalValue, internalValue };
};

function SliderFieldWrapper({
  input: { onChange, value, ...input },
  disabled = false,
  id,
  label,
  max = 100,
  min = 0,
  precision = 0,
  suffix = '',
  ticks,
  'data-track': dataTrack
}) {
  const { cleanAndClamp, internalValue, setInternalValue } = useSliderWrapper({
    min,
    max,
    precision,
    value
  });

  return (
    <Box flexDirection="column">
      <Label>
        {/* This should be possible directly on `Label` but sadly it isn't - the component API dynamically sets the `id` and `for` attributes even when not desired */}
        <span id={`${id}Label`}>{label}</span>
      </Label>

      <Box display="flex" mb="400">
        <Box flexGrow="1" pr="400">
          <Slider
            id={`${id}Slider`}
            aria-labelledby={`${id}Label`}
            data-id={`${id}Slider`}
            aria-controls={id}
            disabled={disabled}
            max={max}
            min={min}
            precision={precision}
            onChange={onChange}
            ticks={ticks}
            value={value}
          />
        </Box>

        <Box flexBasis="25%" maxWidth="200px">
          <TextField
            label={`Fine-grained ${label}`}
            labelHidden
            id={id}
            {...input}
            disabled={disabled}
            onBlur={(event) => {
              const nextValue = cleanAndClamp(event.target.value);
              setInternalValue(nextValue); // set next value just in case it was formatted or clamped
              onChange(nextValue);
            }}
            onChange={(event) => {
              setInternalValue(event.target.value);
              onChange(event.target.value);
            }}
            type="number"
            suffix={suffix}
            value={internalValue}
            data-track={dataTrack}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default SliderFieldWrapper;
