import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Layout,
  Panel,
  Select,
  Text,
  Checkbox,
  Columns,
  Column,
  Box
} from 'src/components/matchbox';
import useResourceDetailsContext from '../../hooks/useResourceDetailsContext';
import { GROUP_BY_CONFIG } from 'src/pages/analyticsReport/constants';
import useUniqueId from 'src/hooks/useUniqueId';
import { useSparkPostQuery } from 'src/hooks';
import { hasSubaccounts as hasSubaccountsSelector } from 'src/selectors/subaccounts';
import { getDeliverability } from 'src/helpers/api/metrics';
import { getAllSendingIps } from 'src/helpers/api/sendingIps';
import { getSubaccounts } from 'src/helpers/api/subaccounts';
import { DeliverabilityBreakdownTableContainer } from './DeliverabilityBreakdownTable.container';
import { areAllThisValue, isAnyThisValue } from '../../helpers';
import { useDeliverabilityBreakdown } from '../../hooks/useDeliverabilityBreakdown';
import { getResourceFromPageType } from '../../constants/resourceDetails';
import { VALID_BREAKDOWNTYPES } from '../../constants/resourceDetails';

export default function DeliverabilityBreakdown() {
  const {
    filters,
    match: { params: { type, id } = {} } = {},
    hasD12yProduct,
    updateFilters
  } = useResourceDetailsContext();
  const [
    {
      selectBreakDownType,
      topDomainsOnly,
      apiParam,
      metrics,
      columnsToDisplay,
      defaultColumnToSortBy
    },
    dispatch
  ] = useDeliverabilityBreakdown();

  const hasSubaccounts = useSelector((state) => hasSubaccountsSelector(state));
  const selectId = useUniqueId('break-down-by');

  const handleDomainsCheckboxChange = () => {
    return topDomainsOnly
      ? dispatch({ pageType: type, type: 'TOP_DOMAIN_NOT_SELECTED' })
      : dispatch({ pageType: type, type: 'TOP_DOMAIN_SELECTED' });
  };

  let apiParams = {
    from: filters.from,
    to: filters.to,
    [apiParam]: [id].join(','),
    metrics: metrics
  };

  const { data: metricsData, status: metricsDataStatus } = useSparkPostQuery(
    () => getDeliverability(apiParams, selectBreakDownType),
    {
      enabled: Boolean(selectBreakDownType)
    }
  );
  const { data: sendingIpData, status: sendingIpDataStatus } = useSparkPostQuery(
    () => getAllSendingIps(),
    {
      enabled: selectBreakDownType === 'sending-ip'
    }
  );
  const { data: subaccountData, status: subaccountDataStatus } = useSparkPostQuery(
    () => getSubaccounts(),
    {
      enabled: selectBreakDownType === 'subaccount'
    }
  );
  const getTableDataAndStatus = () => {
    let loadingStatus = metricsDataStatus;

    if (selectBreakDownType === 'sending-ip') {
      let statuses = [metricsDataStatus, sendingIpDataStatus];

      return {
        tableDataStatus:
          areAllThisValue(statuses, 'success') ||
          isAnyThisValue(statuses, 'error') ||
          isAnyThisValue(statuses, 'loading'),
        metricsData: metricsData,
        sendingIpData: sendingIpData
      };
    }

    if (selectBreakDownType === 'subaccount') {
      let statuses = [metricsDataStatus, subaccountDataStatus];

      return {
        tableDataStatus:
          areAllThisValue(statuses, 'success') ||
          isAnyThisValue(statuses, 'error') ||
          isAnyThisValue(statuses, 'loading'),
        metricsData: metricsData,
        subaccountData: subaccountData
      };
    }

    return {
      tableDataStatus: loadingStatus,
      metricsData: metricsData
    };
  };

  const getSelectOptions = () => {
    const options = Object.keys(GROUP_BY_CONFIG) // filter configuration based on current state
      .filter((key) => {
        return !(
          (key === 'subaccount' && !hasSubaccounts) ||
          (key === 'domain' && topDomainsOnly) ||
          (key === 'watched-domain' && !topDomainsOnly) ||
          (key === 'watched-domain' && type === 'domain') ||
          // If there are active type of page, filter by the comparison type
          key === type
        );
      })
      // Remap configuration to data structure needed by the UI component
      .map((key) => ({
        value: key,
        label: GROUP_BY_CONFIG[key].label
      }));

    return options;
  };

  useEffect(() => {
    updateFilters({ breakDownType: selectBreakDownType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectBreakDownType]);

  useEffect(() => {
    const isValidBreakdown = VALID_BREAKDOWNTYPES.filter((x) => x !== type).includes(
      filters.breakDownType
    );

    if (!isValidBreakdown) {
      if (type === 'ip-pool') {
        dispatch({
          type: 'SENDING_IP_SELECTED',
          pageType: type
        });
      } else if (type === 'mailbox-provider' || type === 'domain') {
        dispatch({
          type: 'SENDING_DOMAIN_SELECTED',
          pageType: type
        });
      } else {
        dispatch({
          type: 'MAILBOX_PROVIDER_SELECTED',
          pageType: type
        });
      }
    } else {
      dispatch({
        type: filters.breakDownType.toUpperCase().replaceAll('-', '_') + '_SELECTED',
        pageType: type
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, id, dispatch]);

  if (!hasD12yProduct) return null;

  return (
    <Layout data-id="deliverability-breakdown">
      <Layout.Section annotated>
        <Layout.SectionTitle>Deliverability Breakdown</Layout.SectionTitle>
        <Text color="gray.700" fontSize="200">
          {`Deliverability data for this ${getResourceFromPageType(
            type
          )} grouped by other resource types.`}
        </Text>
      </Layout.Section>
      <Layout.Section>
        <Panel mb="0" borderBottom={selectBreakDownType !== '' ? '0' : undefined}>
          <Panel.Section>
            <Columns>
              <Column width={1 / 2}>
                <Select
                  label="Break Down By"
                  id={selectId}
                  value={GROUP_BY_CONFIG[selectBreakDownType] ? selectBreakDownType : 'placeholder'}
                  disabled={getTableDataAndStatus().tableDataStatus === 'loading'}
                  options={getSelectOptions()}
                  onChange={(event) =>
                    dispatch({
                      type: event.target.value.toUpperCase().replaceAll('-', '_') + '_SELECTED',
                      pageType: type
                    })
                  }
                  placeholder="Select Resource"
                  placeholderValue="placeholder"
                />
              </Column>

              {(selectBreakDownType === 'watched-domain' || selectBreakDownType === 'domain') && (
                <Column width={1 / 2}>
                  <Box marginTop="600">
                    <Checkbox
                      id="watchedDomains"
                      label="Top Domains Only"
                      checked={topDomainsOnly}
                      onChange={handleDomainsCheckboxChange}
                      disabled={getTableDataAndStatus().tableDataStatus === 'loading'}
                    />
                  </Box>
                </Column>
              )}
            </Columns>
          </Panel.Section>
        </Panel>
        {selectBreakDownType !== '' && (
          <DeliverabilityBreakdownTableContainer
            tableDataAndStatus={getTableDataAndStatus()}
            resourceType={selectBreakDownType}
            columnsToDisplay={columnsToDisplay}
            defaultColumnToSortBy={defaultColumnToSortBy}
          />
        )}
      </Layout.Section>
    </Layout>
  );
}
