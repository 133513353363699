/* eslint-disable local/restrict-translatable-text */
import { CreditCard } from '@sparkpost/matchbox-icons';
import React from 'react';
import { LabelledValue } from 'src/components';

type CardSummaryProps = {
  creditCard: {
    expiration_month: number;
    expiration_year: number;
    id: string;
    number: string;
    type: string;
  };
  label?: string;
};

const CardSummary = ({ creditCard, label }: CardSummaryProps): JSX.Element => {
  if (!creditCard) {
    return <p>No credit card information present.</p>;
  }
  const { type, number, expiration_month, expiration_year } = creditCard;
  return (
    <LabelledValue label={label}>
      <h6>
        <strong>
          <CreditCard size={16} />
          {type} ···· {number.substr(number.length - 4)}
        </strong>
      </h6>
      <p>
        Expires {expiration_month}/{expiration_year}
      </p>
    </LabelledValue>
  );
};

export default CardSummary;
