/* eslint-disable react/forbid-elements */
import ErrorImage from '@sparkpost/matchbox-media/images/Error.webp';
import qs from 'query-string';
import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { auth0Authenticate } from 'src/actions/auth';
import { Loading } from 'src/components';
import { Banner, Button, Picture } from 'src/components/matchbox';
import { LINKS, LOGOUT_ROUTE, JOIN_ROUTE } from 'src/constants';
import { useAuthentication } from 'src/context/AuthenticationContext';
import { authQueryFn } from 'src/helpers/api';
import { getAuthToken } from 'src/helpers/api/auth0';
import authCookie from 'src/helpers/authCookie';
import ErrorTracker from 'src/helpers/errorTracker';
import { useSparkPostMutation } from 'src/hooks';
import { redirectAfterLogin } from 'src/helpers/localStorage';
import RedirectAfterLogin from './components/RedirectAfterLogin';

const ACCOUNT_TERMINATED = 403;

const initialState = {
  exchangeToken: undefined,
  authHandled: false,
  spToken: false,
  accountIsTerminated: false
};

function reducer(state, action) {
  const { type, payload } = action;
  return { ...state, [type]: payload };
}

function LoginPage() {
  const auth0Init = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const [state, stateDispatch] = useReducer(reducer, initialState);
  const update = (key, value) => stateDispatch({ type: key, payload: value });
  const { exchangeToken, authHandled, spToken, accountIsTerminated } = state;
  const params = qs.parse(location?.search);
  const { code } = params;

  const {
    previousRoute,
    context: {
      auth: { loggedIn }
    },
    auth0: {
      user,
      isLoading,
      isAuthenticated,
      loginWithRedirect,
      getAccessTokenSilently,
      logout,
      error: auth0Error
    }
  } = useAuthentication();
  const { report } = ErrorTracker;

  // Note: Provided by webpack
  const cypressUsername = typeof CYPRESS_USERNAME !== 'undefined' ? CYPRESS_USERNAME : null; // eslint-disable-line no-undef
  const IS_CYPRESS_RUNTIME = Boolean(
    window?.Cypress?.cy?.id && window?.Cypress?.env().CY_IGNORE_AUTH
  );
  const CYPRESS_JUST_LOGGED_OUT = IS_CYPRESS_RUNTIME && previousRoute === LOGOUT_ROUTE;
  const userEmail = IS_CYPRESS_RUNTIME ? cypressUsername : user?.email;

  const reportInvalidSession = useCallback(() => {
    report(
      'invalid-session',
      new Error(
        `Sparkpost session expired before Auth0 session. This error has been handled and the users session has been refreshed.`
      ),
      {},
      {
        group: 'invalid-session'
      }
    );
  }, [report]);

  const sendToAuth0Login = useCallback(async () => {
    await loginWithRedirect({
      appState: {
        redirectAfterLogin: redirectAfterLogin.get()
      }
    });
  }, [loginWithRedirect]);

  const clearAndLogout = useCallback(() => {
    update('accountIsTerminated', false);
    authCookie.remove();
    logout({
      returnTo: window.location.origin
    });
  }, [logout]);

  const handleSuccess = useCallback((data) => {
    update('spToken', data?.token);
  }, []);

  const handleErrors = useCallback(
    (error) => {
      report('auth0-login-error', error);

      if (error?.response?.status === ACCOUNT_TERMINATED) {
        update('accountIsTerminated', true);
        return;
      }

      clearAndLogout();
    },
    [clearAndLogout, report]
  );

  const getAuthTokenMutation = useSparkPostMutation(
    ({ exchangeToken } = {}) => {
      return getAuthToken(exchangeToken);
    },
    {
      queryFn: authQueryFn,
      onError: handleErrors,
      onSuccess: handleSuccess,
      retry: false
    }
  );
  // handles error when auth0 post login action fails
  useEffect(() => {
    if (auth0Error) {
      handleErrors(auth0Error);
    }
  }, [auth0Error, handleErrors]);

  /**
   * Step 1. Setup to get exchange token
   */
  useEffect(() => {
    const getAuth0Token = async function () {
      try {
        // Note: getAccessTokenSilently needs to have "allow offline access" turned on with useRefreshTokens={true} prop on the provider.
        const silentToken = await getAccessTokenSilently({
          // https://auth0.github.io/auth0-react/interfaces/index.gettokensilentlyoptions.html#timeoutinseconds
          timeoutInSeconds: 15
        });

        update('exchangeToken', silentToken);

        const readyToMutate = !authHandled && getAuthTokenMutation.isIdle;

        if (readyToMutate) {
          /**
           * Step 2b. Call to exchange auth0 token for SP token
           */
          getAuthTokenMutation.mutate({ exchangeToken: silentToken });
        }
      } catch (error) {
        handleErrors(error);
      }
    };

    if (isLoading) return;

    const NEED_EXCHANGE_TOKEN =
      !IS_CYPRESS_RUNTIME &&
      isAuthenticated &&
      code?.length &&
      !exchangeToken &&
      auth0Init.current === undefined;

    const NEED_AUTH0_LOGIN =
      !IS_CYPRESS_RUNTIME &&
      !loggedIn &&
      !isAuthenticated &&
      !exchangeToken &&
      auth0Init.current === undefined;

    const CYPRESS_READY_TO_MUTATE =
      IS_CYPRESS_RUNTIME &&
      !NEED_AUTH0_LOGIN &&
      !NEED_EXCHANGE_TOKEN &&
      !authHandled &&
      getAuthTokenMutation.isIdle;

    if (NEED_EXCHANGE_TOKEN) {
      auth0Init.current = true; // Must be set during both steps of the real flow to detect invalid sessions.
      /**
       * Step 2. Once auth0 sends the user back with the code in the url... Get the silent token to do the exchange.
       */
      getAuth0Token();
    } else if (NEED_AUTH0_LOGIN) {
      auth0Init.current = true; // Must be set during both steps of the real flow to detect invalid sessions.
      /**
       * Step 1. Send them to Auth0 to log in
       */
      sendToAuth0Login();
    } else if (CYPRESS_READY_TO_MUTATE) {
      auth0Init.current = true; // Must be set during this cypress mock step
      getAuthTokenMutation.mutate();
    }

    /**
     * An invalid session is a device that manually expired the SP token before the natural expiration date.
     * This happens on mobile devices and M1 macs after closing/opening a browser or restarting the computer.
     */
    const INVALID_SESSION =
      loggedIn === false && !isLoading && auth0Init.current === undefined && !NEED_AUTH0_LOGIN;

    if (INVALID_SESSION) {
      auth0Init.current = true; // Must be set to prevent multiple invalid session emits
      reportInvalidSession();
      setTimeout(() => {
        getAuth0Token();
      }, 750);
    }
  }, [
    IS_CYPRESS_RUNTIME,
    authHandled,
    code,
    dispatch,
    exchangeToken,
    getAccessTokenSilently,
    getAuthTokenMutation,
    handleErrors,
    isAuthenticated,
    isLoading,
    loggedIn,
    logout,
    report,
    reportInvalidSession,
    sendToAuth0Login
  ]);

  /**
   * Step 3. Use the token from the exchange to authenticate
   */
  useEffect(() => {
    const handleAuth = (username, accessToken) =>
      dispatch(auth0Authenticate(username, null, false, accessToken));
    if (userEmail && spToken) {
      handleAuth(userEmail, spToken) && update('authHandled', true);
    }
  }, [dispatch, spToken, userEmail]);

  if (CYPRESS_JUST_LOGGED_OUT) return <Redirect to={JOIN_ROUTE} />; // keep cypress away from the auto redirecting /auth route

  if (isLoading) {
    return <Loading />;
  }

  if (loggedIn) return <RedirectAfterLogin />;

  if (accountIsTerminated) {
    return (
      <>
        <Banner title="Account No Longer Exists" status="danger" my="300">
          <p>This account has been terminated. Please contact support for assistance.</p>
          <Banner.Action to={LINKS.GENERIC_SUPPORT_DOC} external variant="outline">
            Go to Help &amp; Support
          </Banner.Action>
        </Banner>
        <Picture seeThrough>
          <Picture.Image alt="" src={ErrorImage} />
        </Picture>
        <Button onClick={clearAndLogout}>Go Back</Button>
      </>
    );
  }

  return null;
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { auth0Authenticate })(LoginPage);
