import copy from 'copy-to-clipboard';
import React, { ChangeEvent, ClipboardEvent, Component } from 'react';
import { Button, TextField, Tooltip } from 'src/components/matchbox';
import { v4 as uuidV4 } from 'uuid';

type CopyFieldProps = {
  hideCopy?: boolean;
  id: string;
  label: string;
  labelHidden?: boolean;
  object: $TODOFIXME;
  onCopy?: (value: ClipboardEvent<HTMLInputElement> | boolean) => void;
  value: string;
};

/**
 * Reusable TextField with a copy button for strings
 */
class CopyField extends Component<CopyFieldProps> {
  static defaultProps = {
    hideCopy: false,
    label: 'Copy Data'
  };

  state = {
    copied: false,
    id: uuidV4()
  };

  timeout?: number = undefined;

  handleFieldFocus = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.target.select();
  };

  handleCopy = () => {
    copy(this.props.value);
    this.setState({ copied: true });
    if (this.props.onCopy) this.props.onCopy(true);
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.setState({ copied: false }), 3000) as unknown as number;
  };

  UNSAFE_componentWillReceiveProps(nextProps: CopyFieldProps) {
    if (nextProps.value !== this.props.object) {
      this.setState({ copied: false });
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { value, hideCopy, label, labelHidden = !label, id, ...fieldProps } = this.props;
    const { copied } = this.state;
    let connectRight = null;

    if (!hideCopy) {
      connectRight = (
        <Tooltip
          id={id ? `${id}-tooltip` : this.state.id}
          dark
          content="Copied to clipboard!"
          disabled={!copied}
        >
          <Button variant="connected" name="copy-field-button" onClick={this.handleCopy}>
            Copy
          </Button>
        </Tooltip>
      );
    }

    return (
      <TextField
        label={label}
        labelHidden={labelHidden}
        id={id}
        name="copy-field"
        readOnly
        connectRight={connectRight}
        value={value}
        onFocus={this.handleFieldFocus}
        {...fieldProps}
      />
    );
  }
}

export default CopyField;
