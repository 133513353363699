import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box, Text } from 'src/components/matchbox';

const StyledBox = styled(Box)`
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    transform: ${(props) => `translateY(${props.theme.space['200']})`};
  }
`;

function PageDescription(props) {
  const { children, className, mb = '500', maxWidth = '1200' } = props;

  return (
    <StyledBox mb={mb} maxWidth={maxWidth} className={className} data-id={props['data-id']}>
      <Text color="gray.700" fontSize="300" lineHeight="300">
        {children}
      </Text>
    </StyledBox>
  );
}

PageDescription.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  mb: PropTypes.string,
  maxWidth: PropTypes.string,
  'data-id': PropTypes.string
};

export default PageDescription;
