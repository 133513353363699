import { useEffect } from 'react';
import { useAuthentication } from 'src/context/AuthenticationContext';
import { LOGOUT_ROUTE } from 'src/constants';

const IS_CYPRESS_RUNTIME = Boolean(
  window?.Cypress?.cy?.id && window?.Cypress?.env().CY_IGNORE_AUTH
);

/**
 * Auth0LogoutPage
 * @route /auth-logout (that redirects to /logout)
 */
export default function Auth0LogoutPage({ history }) {
  const {
    context: {
      auth: { loggedIn }
    },
    auth0: { logout }
  } = useAuthentication();

  const returnToPath = `${window.location.origin}${LOGOUT_ROUTE}`;

  useEffect(() => {
    if (!IS_CYPRESS_RUNTIME && loggedIn) {
      logout({
        returnTo: returnToPath
      });
    } else {
      history.push(LOGOUT_ROUTE);
    }
  }, [history, loggedIn, logout, returnToPath]);

  return null;
}
