import { formatDateTime } from '@sparkpost/report-builder/helpers/date';
import React from 'react';
import { PageLink } from 'src/components/links';
import { Inline, Tag } from 'src/components/matchbox';
import { setSubaccountQuery } from 'src/helpers/subaccounts';
import { routeNamespace } from '../constants/routes';
import styles from './ListComponents.module.scss';

export const Name = ({ list_name: name, id, subaccount_id, ...rowData }) => {
  const version = rowData.list_status === 'draft' ? 'draft' : 'published';

  return (
    <PageLink
      to={`/${routeNamespace}/edit/${id}/${version}/content${setSubaccountQuery(subaccount_id)}`}
    >
      {name}
    </PageLink>
  );
};

export const Status = (rowData) => {
  const { list_status } = rowData;

  if (list_status === 'published') {
    return (
      <Tag color="green" className={styles.published}>
        Published
      </Tag>
    );
  }

  if (list_status === 'published_with_draft') {
    return (
      <Inline space="200">
        <Tag color="green" className={styles.PublishedWithChanges}>
          Published
        </Tag>
        <Tag color="yellow" className={styles.PublishedWithChanges}>
          Unpublished Changes
        </Tag>
      </Inline>
    );
  }

  return <Tag>Draft</Tag>;
};

export const LastUpdated = ({ last_update_time }) => {
  return <p className={styles.LastUpdated}>{formatDateTime.useMomentInput(last_update_time)}</p>;
};
