import React from 'react';
import PropTypes from 'prop-types';
import { Delete } from '@sparkpost/matchbox-icons';
import { ButtonLink } from 'src/components/links';

const DeleteTemplate = (props) => {
  const { className, onClick } = props;

  return (
    <div className={className}>
      <ButtonLink onClick={onClick} data-id="action-delete">
        <Delete />

        <span>Delete</span>
      </ButtonLink>
    </div>
  );
};

DeleteTemplate.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default DeleteTemplate;
