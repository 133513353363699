import { useCallback, useEffect, useMemo } from 'react';
import { useEffectReducer } from 'use-effect-reducer';
import { zonedTimeToUtc } from 'date-fns-tz';
import { getLocalTimezone } from '@sparkpost/report-builder/helpers/date';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { sub } from 'date-fns';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PER_PAGE } from 'src/constants';
import { usePageFilters, useSparkPostQuery, useSubaccounts } from 'src/hooks';
import { useIpPools } from '../../../hooks';

import sortMatch from 'src/helpers/sortMatch';
import { getIncidents } from 'src/helpers/api/blocklist';
import { toTableFriendly } from '../helpers';
import { DEFAULT_SORT_BY, INCIDENT_COLUMNS, INCIDENT_STATUS_CHECKBOXES } from '../constants';

const now = new Date();
const thirtyDaysAgo = sub(now, { days: 30 });
const localTimeZone = getLocalTimezone();

const filterConfiguration = {
  search: {
    defaultValue: undefined
  },
  from: {
    defaultValue: thirtyDaysAgo
  },
  to: {
    defaultValue: now
  },
  relativeRange: {
    defaultValue: '30days'
  }
};

const initialState = {
  status: 'loading', // 'loading' | 'error' | 'success'
  search: filterConfiguration.search.defaultValue,
  from: filterConfiguration.from.defaultValue,
  to: filterConfiguration.to.defaultValue,
  relativeRange: filterConfiguration.relativeRange.defaultValue,
  data: []
};

export function useBlocklistIncidents() {
  const columns = useMemo(() => INCIDENT_COLUMNS, []);
  const pageFilters = usePageFilters(filterConfiguration, { isReplaced: true });

  /**
   * @see {@link https://react-table.tanstack.com/docs/api/useGlobalFilter#table-options} for docs
   */
  const customGlobalFilterFn = useCallback((rows, _columnIds, query) => {
    // Put the resource and blocklist name in to a single string to search against
    const getter = (row) =>
      `${row.values['resource']} ${row.values['blocklistName']}  ${row.values['assignment']}`;

    return sortMatch(rows, query, getter);
  }, []);

  // The state of the API request and the client-side table filtering are fairly tightly coupled
  // The user can change the date, which re-requests API data, though expects other client-side filters to remain.
  // As a result, the table state and the state of the blocklist incidents requests are controlled via
  // a single state reducer.
  const reducer = (state, action, exec) => {
    switch (action.type) {
      case 'DATA_LOADED': {
        return {
          ...state,
          status: 'success',
          data: action.data
        };
      }

      case 'DATA_FAILED_TO_LOAD': {
        return {
          ...state,
          status: 'error',
          error: action.error
        };
      }

      case 'REFETCH_DATA': {
        exec(() => refetch());

        return state;
      }

      case 'DATE_CHANGE': {
        exec(() => {
          return pageFilters.updateFilters({
            from: action.from,
            to: action.to,
            relativeRange: action.relativeRange
          });
        });

        return state;
      }

      case 'URL_QUERY_STRING_DATE_CHANGE': {
        return {
          ...state,
          from: action.from,
          to: action.to,
          relativeRange: action.relativeRange,
          status: 'loading' // An API re-request is required, so return to the loading state to make that happen
        };
      }

      case 'SEARCH_CHANGE': {
        exec(() => pageFilters.updateFilters({ search: action.search }));

        return state;
      }

      case 'URL_QUERY_STRING_SEARCH_CHANGE': {
        exec(() => setGlobalFilter(action.search));
        return {
          ...state,
          search: action.search
        };
      }

      case 'STATUS_CHANGE': {
        if (action.statuses.length === INCIDENT_STATUS_CHECKBOXES.length) {
          exec(() => setTableFilter('status', undefined));

          return state;
        }

        exec(() => setTableFilter('status', action.statuses));
        return state;
      }

      case 'SORT_BY_CHANGE': {
        exec(() => setSortBy(action.sortBy));
        return state;
      }

      default: {
        // eslint-disable-next-line
        console.warn(`${action.type} is not supported by "useBlocklistIncidents".`);

        return state;
      }
    }
  };

  // Though the reducer does store some state, it generally is a means of centralizing
  // logic that controls side effects. Rather than using many `useEffect` statements with
  // complex dependency arrays, this seemed like a more legible approach.
  const [state, dispatch] = useEffectReducer(reducer, initialState);

  // The `/v1/blocklist-monitors/incidents` endpoint expects "ISOdateString format, according to UTC time"
  // See: https://github.com/SparkPost/sparkpost-admin-api-documentation/blob/master/services/blocklist-monitoring-api.apib.md#retrieve-all-incidents-possible-filter-values-get-v1blocklist-monitorsincidentsfromtoblocklistsresourcesstatuslimit
  const fromUtc = zonedTimeToUtc(pageFilters.filters.from, localTimeZone);
  const toUtc = zonedTimeToUtc(pageFilters.filters.to, localTimeZone);

  const { getIPPool } = useIpPools();
  const { getSubaccountName } = useSubaccounts();

  const incidentsQuery = useSparkPostQuery(
    () => getIncidents({ from: fromUtc.toISOString(), to: toUtc.toISOString() }),
    {
      enabled: state.status === 'loading', // The request is controlled by the state of the reducer
      select: (data) => toTableFriendly(data),
      onSuccess: (data) => dispatch({ type: 'DATA_LOADED', data }),
      onError: (error) => dispatch({ type: 'DATA_FAILED_TO_LOAD', error })
    }
  );

  const data = useMemo(() => {
    return state.data.map((row) => {
      const { resource, subaccountId } = row;

      let assignmentValue;
      let assignmentLabel;
      const ipPoolAssignment = getIPPool(resource);
      const subaccountAssignment = getSubaccountName(subaccountId);

      if (ipPoolAssignment) {
        assignmentLabel = 'IP Pool';
        assignmentValue = ipPoolAssignment;
      } else if (subaccountAssignment) {
        assignmentLabel = 'Subaccount';
        assignmentValue = subaccountAssignment;
      } else {
        return row;
      }

      const assignment = `${assignmentLabel}: ${assignmentValue}`;
      return { ...row, assignment };
    });
  }, [state.data, getIPPool, getSubaccountName]);

  const tableInstance = useTable(
    {
      columns,
      data: data,
      globalFilter: customGlobalFilterFn,
      autoResetFilters: false, // Avoids filters clearing due to unrelated data changes,
      autoResetGlobalFilter: false, // Avoids filters clearing due to unrelated data changes,
      initialState: {
        pageIndex: DEFAULT_CURRENT_PAGE - 1, // react-table takes a 0 base pageIndex
        pageSize: DEFAULT_PER_PAGE,
        sortBy: DEFAULT_SORT_BY
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // When the URL "search" query string param changes, update state accordingly
  useEffect(() => {
    if (pageFilters.filters.search) {
      return dispatch({
        type: 'URL_QUERY_STRING_SEARCH_CHANGE',
        search: pageFilters.filters.search
      });
    }

    // Clear search if none is present
    return dispatch({ type: 'URL_QUERY_STRING_SEARCH_CHANGE', search: undefined });
  }, [dispatch, pageFilters.filters.search]);

  // When the URL "from" and "to" query string params change, update state accordingly
  useEffect(() => {
    return dispatch({
      type: 'URL_QUERY_STRING_DATE_CHANGE',
      from: pageFilters.filters.from,
      to: pageFilters.filters.to,
      relativeRange: pageFilters.filters.relativeRange
    });
  }, [
    dispatch,
    pageFilters.filters.from,
    pageFilters.filters.to,
    pageFilters.filters.relativeRange
  ]);

  // NOTE: These setter functions are defined pretty much exclusively to hoist them to the top of the scope, allowing access earlier in the function
  // Otherwise, the `tableInstance` isn't yet available for direct use
  function setTableFilter(accessor, value) {
    return tableInstance.setFilter(accessor, value);
  }

  function setSortBy(sortBy) {
    const [id, direction] = sortBy.split('.');
    const desc = direction === 'desc' ? true : false;

    // Kind of funky that `setSortBy` accepts an array - I think `react-table` supports multi-dimensional sorting
    return tableInstance.setSortBy([{ id, desc }]);
  }

  function setGlobalFilter(search) {
    return tableInstance.setGlobalFilter(search);
  }

  function refetch() {
    return incidentsQuery.refetch();
  }

  return { state, dispatch, tableInstance };
}
