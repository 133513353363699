import React from 'react';

import { Tag } from 'src/components/matchbox';
import { TranslatableText } from 'src/components/text';

type LastStatusProps = { status: 'Success' | 'Failure' | 'Disabled' | '' };

export const LastStatus = ({ status }: LastStatusProps): JSX.Element => {
  const getColor = (): 'green' | 'red' | 'lightGray' => {
    if (status === 'Success') return 'green';
    if (status === 'Failure') return 'red';

    return 'lightGray';
  };

  if (status === '') return <></>;

  return (
    <Tag color={getColor()}>
      <TranslatableText>{status}</TranslatableText>
    </Tag>
  );
};
