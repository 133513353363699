/* eslint-disable max-lines */
import { tokens } from '@sparkpost/design-tokens';

import { differenceInDays } from 'date-fns';

export const DEFAULT_REDIRECT_ROUTE = '/landing';
export const AFTER_JOIN_REDIRECT_ROUTE = '/onboarding/plan';
export const RV_AFTER_JOIN_REDIRECT_ROUTE = '/onboarding/recipient-validation';

export const ROOT_ROUTE = '/';
export const JOIN_ROUTE = '/join';
export const SIGN_UP_ROUTE = '/sign-up';

export const AUTH0_LOGOUT_ROUTE = '/auth-logout';
export const LOGOUT_ROUTE = '/logout';
export const AUTH_ROUTE = '/auth'; // Old Classic login route

export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';

export const REGISTER_ROUTE = '/register';

export const TFA_ROUTE = '/auth/tfa';
export const ENABLE_TFA_AUTH_ROUTE = '/auth/enable-tfa';
export const SSO_AUTH_ROUTE = '/auth/sso';
export const CREATE_SUBACCOUNT = '/account/subaccounts/create';
export const DASHBOARD_ROUTE = '/dashboard';
export const EVENTS_ROUTE = '/reports/message-events';
export const COOKIE_DOMAIN = '.sparkpost.com';

export const FORMATS = {
  MOMENT: {
    SHORT_DATE: 'YYYY-MM-DD',
    TIME: 'h:mma',
    LONG_DATETIME_ALT: 'MMM DD, YYYY [at] h:mma',
    INPUT_DATES: ['YYYY-MM-DD'],
    INPUT_TIMES: ['h:mma', 'H:mm', 'H:mma'],
    INPUT_DATETIMES: ['YYYY-MM-DD h:mma', 'YYYY-MM-DD H:mm', 'YYYY-MM-DD H:mma']
  },
  DATE_FNS: {
    API_DATE_FORMAT: "yyyy-MM-dd'T'HH:mm",
    DATE: 'MMM do',
    DATETIME_WITH_YEAR: 'MMM do yyyy h:mma',
    DATETIME_WITHOUT_YEAR: 'MMM do h:mma',
    LONG_DATETIME: "MMM do yyyy h:mmaaaaa'm'" //date-fns //Bug with date-fns. token "a" not outputing lowercase
  }
};

export const RELATIVE_DATE_OPTIONS = ['hour', 'day', '7days', '10days', 'custom'];
export const SIGNALS_RELATIVE_DATE_OPTIONS = ['day', '7days', '30days', '90days', 'custom'];

export const getRelativeDateOptionFromToAndFromSearchStrings = function (
  toString: string,
  fromString: string,
  dateOptions: string[]
) {
  const diff = differenceInDays(new Date(toString), new Date(fromString));
  const index = dateOptions.includes(`${diff}days`)
    ? dateOptions.indexOf(`${diff}days`)
    : dateOptions.indexOf('custom');
  return dateOptions[index];
};

export const LINKS = {
  SPC_EU_URL: 'https://app.eu.sparkpost.com',
  SPC_US_URL: 'https://app.sparkpost.com',
  SP_HOME_PAGE: 'https://www.sparkpost.com',
  API_DOCS: 'https://developers.sparkpost.com/api',
  DOCS: 'https://www.sparkpost.com/docs',
  PREMIUM_SUPPORT: 'https://www.sparkpost.com/contact-premium',
  ENTERPRISE_SUPPORT: 'https://www.sparkpost.com/contact-enterprise',
  RECIP_API: 'https://developers.sparkpost.com/api/recipient-lists.html',
  AB_TESTING_API: 'https://developers.sparkpost.com/api/ab-testing.html',
  SUBACCOUNTS: 'https://sparkpost.com/docs/user-guide/subaccounts/',
  SUBACCOUNTS_API: 'https://developers.sparkpost.com/api/subaccounts.html',
  MICROENTERPRISE_WAIVER: 'https://www.messagebird.com/en/legal/microenterprise-waiver',
  TOU: 'https://www.messagebird.com/legal/terms-and-conditions',
  SECURITY: 'https://www.sparkpost.com/policies/security/',
  DOMAIN_VERIFICATION:
    'https://www.sparkpost.com/docs/tech-resources/enabling-multiple-custom-tracking-domains',
  SENDING_REQS: 'https://www.sparkpost.com/docs/getting-started/requirements-for-sending-domains',
  SENDING_SETUP: 'https://www.sparkpost.com/docs/getting-started/setting-up-domains/',
  TRACKING_DOMAIN_DOCS:
    'https://www.sparkpost.com/docs/getting-started/getting-started-sparkpost/#tracking-domain',
  SENDING_DOMAIN_DOCS: 'https://www.sparkpost.com/docs/user-guide/managing-sending-domains/',
  BOUNCE_DOMAIN_DOCS:
    'https://www.sparkpost.com/docs/tech-resources/custom-bounce-domain/#using-the-sparkpost-ui',
  TFA_BACKUP_CODES:
    'https://support.sparkpost.com/docs/my-account-and-profile/enabling-two-factor-authentication',
  IP_WARM_UP: 'https://support.sparkpost.com/docs/deliverability/ip-warm-up-overview',
  DAILY_USAGE: 'https://www.sparkpost.com/docs/getting-started/what-counts-daily-monthly-usage',
  LEARN_MORE_TFA:
    'https://www.sparkpost.com/docs/my-account-and-profile/enabling-two-factor-authentication',
  ONBOARDING_SENDING:
    'https://www.sparkpost.com/docs/getting-started/getting-started-sparkpost/#preparing-your-from-address',
  ONBOARDING_SENDING_EMAIL:
    'https://www.sparkpost.com/docs/getting-started/getting-started-sparkpost/#sending-email',
  RECAPTCHA_LIB_URL: 'https://www.google.com/recaptcha/api.js',
  DAILY_MONTHLY_QUOTA_LIMIT_DOC:
    'https://support.sparkpost.com/docs/faq/daily-and-monthly-quota-limits',
  GETTING_STARTED_GUIDE: 'https://www.sparkpost.com/docs/getting-started/getting-started-sparkpost',
  ADMIN_BOUNCE: 'https://support.sparkpost.com/', // TODO Add this to the bounce page
  GENERIC_SUPPORT_DOC: 'https://www.sparkpost.com/support/',
  SSO_GUIDE: 'https://www.sparkpost.com/docs/my-account-and-profile/sso',
  ALERTS_DOCS: 'https://developers.sparkpost.com/api/alerts/#alerts-post-create-an-alert',
  ALERTS_SURVEY: 'https://goo.gl/forms/rnIuTvdVF2xhpKCy2',
  SNIPPET_API_DOCS: 'https://developers.sparkpost.com/api/snippets/',
  IP_POOL_DOCS: 'https://www.sparkpost.com/docs/deliverability/dedicated-ip-pools/',
  MANDATORY_TFA:
    'https://www.sparkpost.com/docs/my-account-and-profile/enabling-two-factor-authentication/',
  RECIPIENT_VALIDATION_ACCESS: 'https://www.sparkpost.com/rv-access',
  AUTO_IP_WARMUP_SETUP: 'https://www.sparkpost.com/docs/user-guide/automated-ip-warmup/',
  MAILBOX_PROVIDERS: 'https://www.sparkpost.com/docs/user-guide/alerts/#mailbox-providers',
  MAILBOX_PROVIDER_DOCS: 'https://www.sparkpost.com/blog/hitchhikers-guide-mailbox-providers/',
  STATUS: 'https://status.sparkpost.com/',
  AB_TESTING_DOCS: 'https://www.sparkpost.com/docs/tech-resources/a-b-testing-sparkpost/',
  TRANSMISSIONS_API:
    'https://developers.sparkpost.com/api/transmissions/#transmissions-post-send-an-a-b-test',
  RECIP_DOCS: 'https://developers.sparkpost.com/api/recipient-lists/',
  API_KEYS_GUIDE: 'https://www.sparkpost.com/docs/getting-started/create-api-keys/',
  SNIPPETS_DOCS: 'https://developers.sparkpost.com/api/template-language/#header-snippets',
  ANALYTICS_DOCS: 'https://www.sparkpost.com/docs/reporting/signals-analytics/#',
  METRICS_GROUPINGS_STRUCTURE_DOCS:
    'https://developers.sparkpost.com/api/metrics/#header-groupings-structure',
  INTEGRATION_DOCS: 'https://www.sparkpost.com/docs/getting-started/getting-started-sparkpost/',
  INBOX_TRACKER: 'https://www.sparkpost.com/inbox-tracker/',
  SEED_LIST_DOCS:
    'https://www.sparkpost.com/docs/deliverability/deliverability-analytics/?pw=fd20beb9819df40e9411764431a8275b',
  INLINE_SEEDING_DOCS: ' https://www.sparkpost.com/docs/user-guide/automatic-inline-seeds/',
  DELIVERABILITY_ANALYTICS:
    'https://www.sparkpost.com/docs/deliverability/deliverability-analytics/',
  DELIVERABILITY_ANALYTICS_DEMO:
    'https://www.sparkpost.com/deliverability-analytics-get-a-demo/?src=SP-Website&pc=app-banner-d12y-rfi&utm_source=App&utm_medium=app-banner&utm_campaign=D12Y-RFI&utm_content=D12Y-RFI',
  ENGAGEMENT_RECENCY_DOCS: 'https://www.sparkpost.com/docs/reporting/engagement-recency/'
};

export const ENTERPRISE_PLAN_CODES = ['ent1'];

export const AWS_COOKIE_NAME = 'aws-mkt';

export const DAILY_LIMIT_REQUEST_TEMPLATE = 'daily-limit-increase';

export const SPC_EU_TENANT = 'spceu';
export const SPC_TENANT = 'spc';

export const CROSS_LINK_MAP = {
  [SPC_TENANT]: { label: '', url: LINKS.SPC_US_URL },
  [SPC_EU_TENANT]: { label: 'EU', url: LINKS.SPC_EU_URL }
};

export const TYPEAHEAD_LIMIT = 100;
export const METRICS_API_LIMIT = 1000;

export const FORMS = {
  LOGIN: 'loginForm',
  SSO: 'ssoLoginForm',
  JOIN: 'joinForm',
  JOIN_PLAN: 'joinPlanForm',
  JOIN_SENDING_DOMAIN: 'joinSendingDomainForm',
  INVITE_USER: 'userInviteForm',
  EDIT_USER: 'userEditForm',
  EVENTS_SEARCH: 'eventsSearchForm',
  RV_ADDPAYMENTFORM: 'rvAddPaymentForm',
  RV_ADDPAYMENTFORM_UPLOADLISTPAGE: 'rvAddPaymentFormUploadListPage'
};

//These filters are the searchable filters in the 'more filters' modal on the Events Search page
export const EVENTS_SEARCH_FILTERS = {
  recipient_domains: {
    placeholder: 'list of domain parts (e.g., gmail, yahoo.co, yahoo.co.jp, .fr)',
    label: 'Recipient Domains'
  },
  from_addresses: {
    placeholder: 'list of full from addresses (e.g., john@mycompany.com)',
    label: 'From Addresses'
  },
  sending_domains: {
    placeholder: 'list of domain parts (e.g., joespizza, marketing.joespizza)',
    label: 'Sending Domains'
  },
  subjects: {
    placeholder: 'list of full words (e.g. happy birthday, christmas)',
    label: 'Subjects'
  },
  bounce_classes: {
    placeholder: 'list of bounce classification codes (e.g. 10, 30)',
    label: 'Bounce Classes'
  },
  reasons: {
    placeholder: 'list of full words in raw reasons (e.g. 5.4.7, IP, Mail rejected)',
    label: 'Reasons'
  },
  campaigns: {
    placeholder: 'list of full words in campaign ids (e.g. newsletter, fire-sale)',
    label: 'Campaign IDs'
  },
  templates: {
    placeholder: 'list of full words in template ids (e.g. newsletter, fire-sale)',
    label: 'Template IDs'
  },
  sending_ips: { placeholder: 'list of ip addresses (e.g., 10.0.0.4.25)', label: 'Sending IPs' },
  ip_pools: {
    placeholder: 'list of full words in ip pool id (e.g billing, administrative)',
    label: 'IP Pools'
  },
  subaccounts: { placeholder: 'list of subaccount ids (e.g. 346, 823)', label: 'Subaccount IDs' },
  messages: {
    placeholder: 'list of message ids (e.g. 00129004225c33cc4c45)',
    label: 'Message IDs'
  },
  transmissions: {
    placeholder: 'list of transmission ids (e.g. 337221619334066426)',
    label: 'Transmissions IDs'
  },
  ab_tests: {
    placeholder: 'list of full words in ab test ids (e.g. newsletter, fire-sale)',
    label: 'AB Test IDs'
  },
  ab_test_versions: {
    placeholder: 'list of version numbers; requires AB Test ID filter(e.g. 4)',
    label: 'AB Test Versions'
  },
  mailbox_providers: {
    placeholder: 'list of full words in mailbox provider names (e.g. Gmail, Verizon Media Group)',
    label: 'Mailbox Providers'
  },
  mailbox_provider_regions: {
    placeholder:
      'list of full words in regions of the mailbox providers (e.g. North America, APAC)',
    label: 'Mailbox Provider Regions'
  }
};

export const ALL_EVENTS_FILTERS = {
  recipients: { placeholder: 'Filter by recipient email address', label: 'Recipients' },
  events: { label: 'Event' },
  ...EVENTS_SEARCH_FILTERS
};

export const DEFAULT_PER_PAGE_BUTTONS = [10, 25, 50, 100];
export const DEFAULT_PAGE_RANGE = 5;
export const DEFAULT_CURRENT_PAGE = 1;
export const DEFAULT_PER_PAGE = 10;

export const ANALYTICS_CREATE_ACCOUNT = 'create account';
export const ANALYTICS_ADDON_IP = 'dedicated_ips';
export const ANALYTICS_PREMIUM_SUPPORT = 'premium-support';
export const ANALYTICS_ENTERPRISE_SUPPORT = 'enterprise-support';
export const ANALYTICS_ONBOARDING = 'onboarding';
export const ANALYTICS_ONBOARDING_CREATE_DOMAIN = 'create-sending-domain-verify-';
export const ANALYTICS_FORMS = [
  FORMS.LOGIN,
  FORMS.JOIN,
  FORMS.JOIN_PLAN,
  FORMS.JOIN_SENDING_DOMAIN
];

export const PRECISION_TO_SECONDS = {
  hour: 1 * 60 * 60,
  day: 1 * 24 * 60 * 60,
  week: 1 * 7 * 24 * 60 * 60,
  '15min': 15 * 60,
  '5min': 5 * 60,
  '1min': 60
};

export const DOTTED_LINE_PREFIX_KEY = 'dotted_';

export const MAILBOX_PROVIDERS = {
  apple: 'Apple',
  gmail: 'Gmail',
  google_apps: 'Google Apps',
  microsoft: 'Microsoft',
  verizon_media: 'Verizon Media',
  yahoo_uk: 'Yahoo UK',
  us_broadband: 'US Broadband',
  uk_providers: 'UK Providers',
  italian_providers: 'Italian Providers',
  russian_providers: 'Russian Providers',
  french_providers: 'French Providers',
  chinese_providers: 'Chinese Providers',
  german_providers: 'German Providers',
  canadian_providers: 'Canadian Providers',
  japanese_providers: 'Japanese Providers',
  other_european_providers: 'Other European Providers',
  other: 'Other Providers'
};

export const PLAN_TIERS = {
  test: 'Test Account',
  starter: 'Starter',
  premier: 'Premier'
};
export const GUIDE_IDS = {
  CHECKOUT_EVENTS: '535b6562-8d0f-49fa-afe9-c96816634aed',
  VERIFY_SENDING_DOMAIN: '0937cdff-abfc-4395-a1d1-eb718f71dbc3',
  CANCEL_ACCOUNT: '86f7f5ed-54b6-476e-880b-ec6a671e2090'
};

export const RECIPIENT_VALIDATION_TIERS = [
  { volumeMin: 0, volumeMax: 5000, cost: 0.01, displayedCost: '$0.010' },
  { volumeMin: 5000, volumeMax: 10000, cost: 0.008, displayedCost: '$0.008' },
  { volumeMin: 10000, volumeMax: 50000, cost: 0.006, displayedCost: '$0.006' },
  { volumeMin: 50000, volumeMax: 100000, cost: 0.004, displayedCost: '$0.004' },
  { volumeMin: 100000, volumeMax: 250000, cost: 0.003, displayedCost: '$0.003' },
  { volumeMin: 250000, volumeMax: 750000, cost: 0.0015, displayedCost: '$0.0015' },
  { volumeMin: 750000, volumeMax: 1000000, cost: 0.001, displayedCost: '$0.0010' },
  { volumeMin: 1000000, volumeMax: Infinity, cost: 0.00075, displayedCost: '$0.00075' }
];

export const SENDING_DOMAIN_TOKEN_TYPE = {
  MAILBOX: 'verification_mailbox',
  POSTMASTER: 'postmaster_at',
  ABUSE: 'abuse_at'
};

export const HIBANA_METRICS_COLORS = [
  tokens.color_blue_700,
  tokens.color_teal_600,
  tokens.color_purple_800,
  tokens.color_magenta_600,
  tokens.color_blue_900,
  tokens.color_yellow_500,
  tokens.color_gray_700,
  tokens.color_purple_500,
  tokens.color_gray_1000
];

export const UNLIMITED_PLAN_VOLUME = -1;

export { ROLES, ROLE_LABELS, SUBACCOUNT_ROLES } from './users';

export const INDUSTRY_BENCHMARK_INDUSTRIES = [
  { label: 'All Industries', value: 'all' },
  { label: 'Automotive', value: 'automotive' },
  { label: 'B2B', value: 'b2b' },
  { label: 'CPG', value: 'cpg' },
  { label: 'Entertainment', value: 'entertainment' },
  { label: 'Finance', value: 'finance' },
  { label: 'Food/service; beverages', value: 'food_beverage_service' },
  { label: 'Govt, Education, Charities', value: 'govt_education_charity' },
  { label: 'Job Search', value: 'job_search' },
  { label: 'Media/Publishing', value: 'media_publishing' },
  { label: 'Medical', value: 'medical' },
  { label: 'Misc', value: 'misc' },
  { label: 'Personal Services', value: 'personal_services' },
  { label: 'Retail-Apparel', value: 'retail_apparel' },
  { label: 'Retail-Online', value: 'retail_online' },
  { label: 'Retail-Other', value: 'retail_other' },
  { label: 'Retail-Supermarket and Drug', value: 'retail_supermarket_drug' },
  { label: 'Social/Review', value: 'social_review' },
  { label: 'Telco', value: 'telco' },
  { label: 'Travel', value: 'travel' }
];
export const INDUSTRY_BENCHMARK_MAILBOX_PROVIDERS = [
  { label: 'All Mailbox Providers', value: 'all' },
  { label: 'Hotmail / Outlook', value: 'hotmail' },
  { label: 'Verizon Media Group', value: 'verizon_media_group' },
  { label: 'Gmail', value: 'gmail' }
];

export const ONBOARDING_STEP = {
  PINNED_REPORT: 'done',
  ADD_SENDING_DOMAIN: 'addSending',
  VERIFY_SENDING_DOMAIN: 'verifySending',
  CREATE_API_KEY: 'createApiKey',
  START_SENDING: 'startSending',
  ANALYTICS_REPORT_PROMO: 'analyticsReportPromo'
};

// <option> data structure for the UTC timezone
export const UTC_TYPEAHEAD_OPTION = {
  value: 'Etc/UTC',
  label: 'UTC'
};

// These timezones returned by `timezone-support` do not work with metrics endpoints and the exclusion is related to SAA-721
const METRICS_TIMEZONE_DIFFERENT_OFFSETS = [
  'Pacific/Chatham',
  'Australia/Lord_Howe',
  'Australia/Adelaide',
  'Australia/Broken_Hill',
  'Australia/Darwin',
  'Australia/Eucla',
  'Asia/Rangoon',
  'Indian/Cocos',
  'Asia/Kathmandu',
  'Asia/Kolkata',
  'Asia/Colombo',
  'Asia/Kabul',
  'Asia/Tehran',
  'America/St_Johns',
  'Pacific/Marquesas'
];

// These timezones returned by `timezone-support` do not work with metrics endpoints
export const METRICS_TIMEZONE_BLOCK_LIST = [
  'Asia/Barnaul',
  'Asia/Tomsk',
  'Asia/Famagusta',
  'Asia/Qostanay',
  'Asia/Atyrau',
  'Europe/Astrakhan',
  'Europe/Saratov',
  'Europe/Ulyanovsk',
  'Europe/Kirov',
  'America/Punta_Arenas',
  'America/Fort_Nelson',
  ...METRICS_TIMEZONE_DIFFERENT_OFFSETS
];

export const PRIMARY_SUBACCOUNT = {
  name: 'Primary Account',
  id: 0
};

export const PRIMARY_AND_ALL_SUBACCOUNTS = {
  name: 'Primary and all subaccounts',
  id: -1
};

export const PORTALS = {
  POPOVER: {
    ID: 'popover-portal'
  },
  MODAL: {
    ID: 'modal-portal'
  },
  DRAWER: {
    ID: 'drawer-portal'
  },
  TOOLTIP: {
    ID: 'tooltip-portal'
  },
  ALERT: {
    ID: 'alert-portal'
  }
};

export const FIELD_MAX_WIDTH = '1200';

export const MEDIA_QUERY_TOKENS = {
  [tokens.mediaQuery_xs]: 448,
  [tokens.mediaQuery_sm]: 720,
  [tokens.mediaQuery_md]: 960,
  [tokens.mediaQuery_lg]: 1200,
  [tokens.mediaQuery_xl]: 1440
};
