import React from 'react';
import { CodeBlock as MatchboxCodeBlock, CodeBlockProps } from '@sparkpost/matchbox';

/**
 * Matchbox CodeBlock component
 * @see https://design.sparkpost.com/components/codeblock/
 */
const CodeBlock = (props: CodeBlockProps): JSX.Element => <MatchboxCodeBlock {...props} />;

CodeBlock.displayName = 'CodeBlock';

export default CodeBlock;
