import React from 'react';
import styles from './templates.module.scss';

const Templates = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 231" overflow="inherit">
    <path
      className={styles.st0}
      d="M28 66.3H2.4c-1.2 0-2.1-.9-2.1-2.1V43.3c0-1.2.9-2.1 2.1-2.1H28c1.2 0 2.1.9 2.1 2.1v20.9c0 1.2-.9 2.1-2.1 2.1z"
    />
    <path
      d="M9.2 52.1c-1.8 0-3.4-1.5-3.4-3.4 0-1.8 1.5-3.4 3.4-3.4s3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4z"
      fill="#fa6423"
      stroke="#6e6e73"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M28 66.3H2.4c-1.2 0-2.1-.9-2.1-2.1v-4.7L5 55.7l5.7 3.8 10.7-9.3 8.8 5v9c-.1 1.2-1 2.1-2.2 2.1z"
      fill="#f2f2f5"
      stroke="#37aadc"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      className={styles.st0}
      d="M9.2 52.1c-1.8 0-3.4-1.5-3.4-3.4 0-1.8 1.5-3.4 3.4-3.4s3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4z"
    />
    <path
      className={styles.st3}
      d="M203.5 81.3c0-4-3.2-7.3-7.3-7.3H51.7c-4 0-7.3 3.2-7.3 7.3v92.6h159V81.3z"
    />
    <path className={styles.st4} d="M50.7 80.1h147.2v87.4H50.7z" />
    <path
      d="M44.5 173.9v3.4c0 4 3.3 7.3 7.3 7.3h54.7v15.8h-16l-4.9 3.9h76.2l-4.9-3.9H141v-15.8h55.2c4 0 7.3-3.3 7.3-7.3v-3.4h-159z"
      fill="#d2d2d7"
    />
    <path className={styles.st6} d="M106.4 184.6H141v1.6h-34.6zM161.9 203.3H85.6l4.8-3H157z" />
    <path fill="#aaaaaf" d="M85.6 203.3h76.3v.9H85.6z" />
    <path
      className={styles.st8}
      d="M97.6 135.6c-3.1 0-5.7 2.2-6.4 5.1h-3c-.6-2.9-3.2-5.1-6.4-5.1-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5c3.1 0 5.7-2.2 6.4-5.1h3c.6 2.9 3.2 5.1 6.4 5.1 3.6 0 6.5-2.9 6.5-6.5s-2.9-6.5-6.5-6.5zm-15.7 10.6c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm15.7 0c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zM91 153.2h-2.5c-.5 0-1-.4-1-1s.4-1 1-1H91c.5 0 1 .4 1 1s-.5 1-1 1zM98.3 117.2c-2.9 2.2-3.5 6.1-3.6 8.8-4.6-5.4 8.6-21.1-6.4-30.9 8.3 10.7-8.7 21.3-12.5 36-.2.9-.4 1.9-.6 2.8 1.3-2.2 6.7-5 11.4-2 7.4 4.7 12.3-3.2 16.2 2-.1-1-.3-1.9-.5-2.8-1.7-6.3-5.5-9.4-4-13.9z"
    />
    <path className={styles.st9} d="M2.7 211.5H124" />
    <path className={styles.st10} d="M133.4 211.5h49.3" />
    <path className={styles.st9} d="M20 216.3h94.6" />
    <path className={styles.st10} d="M124 216.3h49.3" />
    <path className={styles.st9} d="M34.1 221h121.3" />
    <path className={styles.st10} d="M164.8 221h49.3" />
    <path className={styles.st9} d="M77.8 225.8h68.3" />
    <path className={styles.st10} d="M155.5 225.8H246M59 230.5h132.3" />
    <path className={styles.st3} d="M72.6 52.9v-.7l7.2-3.9v1l-6.2 3.2 6.2 3.3v1l-7.2-3.9z" />
    <path className={styles.st6} d="M88.7 44.6h.8L82.4 61h-.8l7.1-16.4z" />
    <path className={styles.st3} d="M91.7 55.8l6.2-3.2-6.2-3.3v-1l7.2 3.9v.7l-7.2 3.9v-1z" />
    <path
      d="M207.2 160c9.8-16.9 14.6-37 12.6-58-5.2-53.3-52.6-92.3-105.9-87.1S21.6 67.5 26.8 120.8c1.5 15 6.3 28.9 13.7 41"
      fill="none"
      stroke="#d2d2d7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeDasharray="3.9632,5.2842"
    />
    <path
      className={styles.st12}
      d="M168.8 56.9h-6.2l-1.1 2.5c-.3.6-.4 1.1-.4 1.4 0 .2.1.5.3.6s.7.3 1.5.4v.4h-5v-.4c.7-.1 1.1-.3 1.3-.5.4-.4.8-1.1 1.3-2.3l5.6-13.1h.4l5.5 13.2c.4 1.1.8 1.8 1.2 2.1s.9.5 1.5.5v.4h-6.3v-.4c.6 0 1.1-.1 1.3-.3s.3-.4.3-.7c0-.3-.2-.9-.5-1.6l-.7-2.2zm-.3-.9l-2.7-6.4L163 56h5.5z"
    />
    <path
      d="M183.2 60.7c-1.1.9-1.8 1.3-2.1 1.5-.4.2-.9.3-1.3.3-.7 0-1.4-.3-1.8-.8s-.7-1.2-.7-2c0-.5.1-1 .4-1.4.3-.5.9-1 1.7-1.5s2.1-1 4-1.7v-.4c0-1.1-.2-1.8-.5-2.2s-.8-.6-1.5-.6c-.5 0-.9.1-1.2.4-.3.3-.4.6-.4.9v.7c0 .4-.1.6-.3.8s-.4.3-.7.3c-.3 0-.5-.1-.7-.3s-.3-.5-.3-.8c0-.7.3-1.3 1-1.9s1.7-.8 2.9-.8c1 0 1.7.2 2.3.5.5.2.8.6 1 1.1.1.3.2 1 .2 2.1v3.6c0 1 0 1.7.1 1.9s.1.4.2.5.2.1.3.1c.1 0 .2 0 .3-.1.2-.1.5-.4.9-.9v.7c-.9 1.2-1.7 1.8-2.5 1.8-.4 0-.7-.1-.9-.4s-.4-.8-.4-1.4zm0-.8v-4.1c-1.2.5-1.9.8-2.3 1-.6.3-1.1.7-1.3 1.1s-.4.8-.4 1.2c0 .6.2 1 .5 1.4s.7.6 1.2.6 1.3-.4 2.3-1.2z"
      fill="#96969b"
    />
    <path
      className={styles.st14}
      d="M121.7 58.6c-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.1-1.1h7.9c.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1h-7.9z"
    />
    <path
      className={styles.st8}
      d="M129.6 56.6c.5 0 .9.4.9.9s-.4.9-.9.9h-7.9c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h7.9m0-.5h-7.9c-.8 0-1.4.6-1.4 1.4 0 .8.6 1.4 1.4 1.4h7.9c.8 0 1.4-.6 1.4-1.4 0-.8-.6-1.4-1.4-1.4z"
    />
    <path
      className={styles.st14}
      d="M121.7 61.8c-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.1-1.1h7.9c.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1h-7.9z"
    />
    <path
      className={styles.st8}
      d="M129.6 59.8c.5 0 .9.4.9.9s-.4.9-.9.9h-7.9c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h7.9m0-.5h-7.9c-.8 0-1.4.6-1.4 1.4 0 .8.6 1.4 1.4 1.4h7.9c.8 0 1.4-.6 1.4-1.4 0-.8-.6-1.4-1.4-1.4z"
    />
    <path
      className={styles.st4}
      d="M126.3 54.5v-9.7c1-.3 1.7-1.2 1.7-2.3 0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4c0 1.1.7 2 1.7 2.3v9.7H121c-.1-2.4-1.3-5.1-2.7-7.8-1.3-2.8-2.7-5.7-2.7-8.4 0-5.5 4.5-9.9 9.9-9.9 5.5 0 9.9 4.5 9.9 9.9 0 2.6-1.4 5.5-2.8 8.4-1.4 2.8-2.6 5.4-2.7 7.8h-3.6z"
    />
    <path
      className={styles.st8}
      d="M125.7 28.6c5.3 0 9.7 4.3 9.7 9.7 0 2.6-1.4 5.5-2.8 8.2-1.3 2.6-2.6 5.3-2.7 7.7h-3.3v-9.3c1-.4 1.7-1.3 1.7-2.4 0-1.4-1.2-2.6-2.6-2.6-1.4 0-2.6 1.2-2.6 2.6 0 1.1.7 2.1 1.7 2.4v9.3H121.4c-.2-2.4-1.4-5.1-2.7-7.7-1.3-2.8-2.7-5.7-2.7-8.2 0-5.4 4.3-9.7 9.7-9.7m0-.5c-5.6 0-10.2 4.6-10.2 10.2 0 5.3 5.4 11.5 5.4 16.4h4.4V44.5c-1-.2-1.7-1-1.7-2.1 0-1.2.9-2.1 2.1-2.1 1.2 0 2.1.9 2.1 2.1 0 1-.7 1.9-1.7 2.1v10.2h4.2c0-5 5.5-11.2 5.5-16.4.1-5.7-4.5-10.2-10.1-10.2z"
    />
    <path
      className={styles.st4}
      d="M230 110.9c-1.2 0-2.8-.5-3.9-.9-.3-.1-.5-.2-.7-.2-3.5-1.1-7.1-2.2-10.6-3.3-2.6-.8-4.5-2.5-5.4-4.7-.2-.5.1-1 .5-1.2L239.7 89c.1 0 .3-.1.4-.1.7 0 .9.7 1.1 1.2l.1.2c.7 1.9-.2 4.9-1 6.4-1.9 3.2-3.8 6.4-5.6 9.6-.1.2-.2.4-.4.7-.8 1.4-1.8 3.4-3.1 3.9H230z"
    />
    <path
      className={styles.st8}
      d="M240.1 89.1c.5 0 .7.8.9 1.2.7 1.7-.1 4.6-1 6.2-1.9 3.2-3.8 6.4-5.6 9.6-.7 1.3-2 3.9-3.4 4.5-.3.1-.6.2-.9.2-1.5 0-3.4-.8-4.6-1.2-3.5-1.1-7.1-2.2-10.6-3.3-2.4-.7-4.3-2.3-5.2-4.6-.1-.3.1-.8.4-.9l29.8-11.6c.1-.1.1-.1.2-.1m0-.5c-.1 0-.3 0-.5.1l-29.8 11.6c-.3.1-.5.3-.7.6-.1.3-.1.6 0 .9.9 2.3 2.9 4.1 5.5 4.9 3.5 1.1 7.1 2.1 10.6 3.3.2.1.5.2.7.2 1.2.4 2.7.9 4 .9.4 0 .8-.1 1.1-.2 1.4-.5 2.5-2.6 3.3-4 .1-.2.3-.5.4-.7 1.9-3.2 3.8-6.4 5.6-9.6.9-1.5 1.8-4.6 1.1-6.6l-.1-.2c0-.4-.3-1.2-1.2-1.2z"
    />
    <path
      className={styles.st4}
      d="M218.9 124.4c-.4 0-.8-.2-.9-.6l-6.3-16.2.6.3c.6.3 1.2.6 1.9.8 3.4 1 7.4 2.2 11.3 3.5 1.3.5 3 1 4.6 1 .7 0 1.3-.1 1.9-.3 2.1-.8 3.4-3.2 4.3-4.9 2-3.6 4.1-7.2 6-10.2l.9-1.8.2-.6 6.3 16.2c.2.5-.1 1-.5 1.2l-29.8 11.6h-.5z"
    />
    <path
      className={styles.st8}
      d="M243.3 96l6.1 15.5c.1.3-.1.8-.4.9L219.2 124h-.2c-.3 0-.6-.2-.7-.4l-6.1-15.5c.6.3 1.3.6 1.9.8 3.8 1.1 7.6 2.2 11.3 3.5 1.4.5 3.1 1 4.7 1 .7 0 1.3-.1 2-.3 2.1-.8 3.4-3.1 4.4-5 1.9-3.4 3.9-6.8 6-10.2.2-.6.5-1.2.8-1.9m0-1.4l-.5 1.3c-.2.6-.5 1.2-.8 1.8-1.8 3-4 6.6-6 10.2-1 1.8-2.2 4-4.1 4.8-.6.2-1.2.3-1.8.3-1.5 0-3.2-.6-4.5-1-3.9-1.3-7.9-2.5-11.3-3.5-.6-.2-1.2-.5-1.8-.8l-1.2-.6.5 1.2 6.1 15.5c.2.5.6.8 1.1.8.1 0 .3 0 .4-.1l29.8-11.6c.6-.2.9-.9.7-1.5l-6.1-15.5-.5-1.3z"
    />
    <path
      className={styles.st14}
      d="M124.5 151.2c-.4 0-.9-.4-.9-.9v-32l.6-.6c2.8-2.6 3.4-3.2 18.2-14.6.3-.2.6-.5 1-.8 1.8-1.5 4.5-3.7 6.4-3.7 2 0 4.7 2.2 6.5 3.7.4.3.7.6 1 .8 14.8 11.4 15.5 12.1 18.2 14.6l.6.6v32c0 .4-.4.9-.9.9h-50.7z"
    />
    <path
      className={styles.st8}
      d="M150 98.8c1.8 0 4.5 2.2 6.2 3.7.4.3.7.6 1 .8 14.8 11.4 15.4 12 18.2 14.6l.6.5v31.9c0 .3-.3.6-.6.6h-50.9c-.3 0-.6-.3-.6-.6v-31.9l.6-.5c2.8-2.6 3.4-3.2 18.2-14.6.3-.2.6-.5 1-.8 1.8-1.5 4.4-3.7 6.3-3.7m0-.5c-2.5 0-5.9 3.3-7.7 4.6-16 12.4-15.5 12.1-19 15.3v32.1c0 .6.5 1.1 1.1 1.1h50.9c.6 0 1.1-.5 1.1-1.1v-32.1c-3.5-3.2-2.9-2.9-19-15.3-1.5-1.4-4.9-4.6-7.4-4.6z"
    />
    <path
      d="M147.8 138.2l-2.8-.9-13.5-11V97.5c0-1.2 1-2.1 2.1-2.1h32.7c1.2 0 2.1 1 2.1 2.1V127L153 138.2h-5.2z"
      fill="#e1e1e6"
    />
    <path
      className={styles.st3}
      d="M166.4 95.5c1.1 0 2 .9 2 2v29.4L153 138.1h-5.1l-2.7-.9-13.4-11V97.5c0-1.1.9-2 2-2h32.6m0-.3h-32.7c-1.3 0-2.3 1-2.3 2.3v28.8l13.6 11.1 2.9.9h5.3l15.6-11.4V97.5c-.1-1.3-1.2-2.3-2.4-2.3z"
    />
    <path className={styles.st14} d="M144.2 100.3h12.7v5h-12.7z" />
    <path className={styles.st8} d="M156.7 100.5v4.5h-12.2v-4.5h12.2m.5-.5H144v5.5h13.2V100z" />
    <path className={styles.st14} d="M135.2 110h29.9v13.2h-29.9z" />
    <path
      className={styles.st8}
      d="M164.8 110.3V123h-29.4v-12.7h29.4m.5-.5h-30.4v13.7h30.4v-13.7z"
    />
    <path
      d="M172.5 123.7c-3.7 2.9-12.2 9.4-12.6 9.7-2.6 2.2-6.2 5.1-10 5.1-3.9 0-7.4-2.9-10-5.1-.4-.3-8.6-6.7-12.3-9.5"
      fill="none"
      stroke="#828287"
      strokeLinecap="round"
      strokeMiterlimit="10"
    />
    <path
      className={styles.st8}
      d="M64.6 25.8H64c-1.8 0-2.7-.7-2.7-2.5V4.6c0-.7.5-1.2 1.2-1.2h7.8c1.4 0 2.1 2 3.4 2h10.6c.7 0 1.2.5 1.2 1.2V8"
    />
    <path className={styles.st4} d="M63.3 25.5V8.1c0-.6.5-1 1-1h23.3c.6 0 1 .5 1 1v17.4H63.3z" />
    <path
      className={styles.st8}
      d="M87.6 7.3c.4 0 .8.4.8.8v17.2H63.6V8.1c0-.4.4-.8.8-.8h23.2m0-.5H64.3c-.7 0-1.3.6-1.3 1.3v17.7h25.9V8.1c0-.7-.6-1.3-1.3-1.3z"
    />
    <path
      className={styles.st6}
      d="M234.6 40.4c.5.2 1.3 0 1.7-.4l2.3-2.3c.4-.4.6-1.2.4-1.7l-.5-1.4c-.1-.2-.2-.4-.4-.4s-.3 0-.5.2l-1.5 1.5c-.5.5-1.4.5-1.9 0l-1.6-1.6c-.3-.3-.4-.6-.4-.9 0-.4.1-.7.4-1l1.5-1.5c.2-.2.2-.3.2-.5s-.2-.3-.4-.4l-1.4-.5c-.5-.2-1.3 0-1.7.4l-2.3 2.3c-.4.4-.6 1.2-.4 1.7l.8 2.5-4.6 4.6c-.1 0-.1 0-.2.1l-6.8-6.8-.5-1.5-3.1-2-1.2 1.2 2 3.1 1.6.5 6.8 6.8s0 .1-.1.2l-6.2 6.2c-1.1-.1-2.2.2-3 1-1.4 1.4-1.4 3.7 0 5.1 1.4 1.4 3.7 1.4 5.1 0 .8-.8 1.1-1.8 1.1-2.8l5-5 7.9 7.9c1.2 1.2 3 1.2 4.2 0 1.2-1.2 1.2-3 0-4.2l-7.9-7.9 3.3-3.3 2.3.8zm-16.8 13.4c-.8.8-2.1.8-2.8 0-.8-.8-.8-2.1 0-2.8.8-.8 2.1-.8 2.8 0 .8.7.8 2 0 2.8zm16.5 0c-.1.1-.4.1-.5 0l-7.7-7.7.5-.5 7.7 7.7c.1.1.1.4 0 .5zm1.6-2.1c.1.1.1.4 0 .5-.1.1-.4.1-.5 0l-7.7-7.7.5-.5 7.7 7.7z"
    />
    <path
      className={styles.st12}
      d="M28.5 101.9c-3 4.9-8.4 8.2-14.1 8.2S3.3 106.8.3 101.9c-.2-.3-.3-.7-.3-1.1 0-.4.1-.8.3-1.1 3-4.9 8.4-8.2 14.1-8.2s11.1 3.3 14.1 8.2c.2.3.3.7.3 1.1 0 .4-.1.8-.3 1.1zm-7.9-6.8c.6 1.1 1 2.3 1 3.6 0 4-3.2 7.2-7.2 7.2s-7.2-3.2-7.2-7.2c0-1.3.3-2.5 1-3.6-2.5 1.3-4.6 3.3-6.1 5.7 2.7 4.2 7.2 7.2 12.3 7.2s9.6-3 12.3-7.2c-1.5-2.3-3.6-4.4-6.1-5.7zm-6.2-1.2c-2.7 0-4.9 2.2-4.9 4.9 0 .4.4.8.8.8s.8-.4.8-.8c0-1.8 1.5-3.3 3.3-3.3.4 0 .8-.4.8-.8s-.4-.8-.8-.8z"
    />
    <path
      className={styles.st4}
      d="M160.6 26c-3.1 0-5.6-2.5-5.6-5.6 0-2.3 1.5-4.4 3.7-5.2l.2-.1-.1-.2c-.3-.7-.5-1.5-.5-2.3 0-3.3 2.7-6 6-6 .9 0 1.8.2 2.6.6l.3.1.1-.3c1-4 4.5-6.8 8.6-6.8 4.6 0 8.5 3.6 8.8 8.1v.2h1.7c4.8 0 8.6 3.9 8.6 8.6 0 4.8-3.9 8.6-8.6 8.6h-8.6c-.4.1-.8.1-1.2.1h-16z"
    />
    <path
      d="M175.9.5c4.5 0 8.2 3.5 8.6 7.9v.5h2c4.6 0 8.4 3.8 8.4 8.4s-3.8 8.4-8.4 8.4H177.7c-.4.1-.7.1-1.1.1h-16.1c-2.9 0-5.3-2.4-5.3-5.3 0-2.2 1.4-4.2 3.5-5l.5-.2-.2-.5c-.3-.7-.4-1.5-.4-2.2 0-3.2 2.6-5.7 5.7-5.7.9 0 1.7.2 2.5.6l.6.3.1-.6c1-4 4.4-6.7 8.4-6.7m0-.5c-4.3 0-7.9 3-8.9 7-.8-.4-1.7-.6-2.7-.6-3.4 0-6.2 2.8-6.2 6.2 0 .9.2 1.7.5 2.4-2.2.8-3.9 2.9-3.9 5.5 0 3.2 2.6 5.8 5.8 5.8h16.1c.4 0 .8 0 1.2-.1h8.6c4.9 0 8.9-4 8.9-8.9s-4-8.9-8.9-8.9H185c-.4-4.7-4.3-8.4-9.1-8.4z"
      fill="#55b7e1"
    />
  </svg>
);

export default Templates;
