import _ from 'lodash';
import moment from 'moment';
import { Dispatch } from 'redux';
import sparkpostApiRequest from 'src/actions/helpers/sparkpostApiRequest';
import config from 'src/appConfig';
import { qsParseWithPlusSignEncoded } from 'src/helpers/qsParseWithPlusSignEncoded';
import { MessageEvents } from 'src/typescript';

const {
  apiDateFormat,
  messageEvents: { retentionPeriodDays }
} = config;

export type GetMessageEventsOptions = GetParamsProps<MessageEvents['search']>;

export function getMessageEvents(options: GetMessageEventsOptions): $TODOFIXME {
  const params = getParams(options);

  return sparkpostApiRequest({
    type: 'GET_MESSAGE_EVENTS',
    meta: {
      method: 'GET',
      url: '/v1/events/message',
      params,
      showErrorAlert: false
    }
  });
}

export function getMessageEventsCSV(options: $TODOFIXME): Dispatch<$TODOFIXME> {
  const params = getParams({ ...options, perPage: 5000 });

  return sparkpostApiRequest({
    type: 'GET_MESSAGE_EVENTS_CSV',
    meta: {
      method: 'GET',
      url: '/v1/events/message',
      params,
      showErrorAlert: false
    }
  });
}

export function clearCSV(): {
  type: 'RESET_MESSAGE_EVENTS_CSV';
} {
  return { type: 'RESET_MESSAGE_EVENTS_CSV' };
}

// eslint-disable-next-line @typescript-eslint/ban-types
type GetParamsProps<Params = {}> = Record<string, $TODOFIXME> &
  Params & {
    dateOptions: {
      from: string;
      to: string;
    };
    perPage?: number;
  };

// eslint-disable-next-line @typescript-eslint/ban-types
function getParams<Params = {}>(options: GetParamsProps<Params>): Record<string, $TODOFIXME> {
  const { dateOptions, perPage, ...rest } = options;
  const { from, to } = dateOptions;
  const params = {
    per_page: perPage ? perPage : 25
  } as Record<string, $TODOFIXME>;

  if (from) {
    params.from = moment(from).utc().format(apiDateFormat);
  }

  if (to) {
    params.to = moment(to).utc().format(apiDateFormat);
  }

  _.forEach(rest, (value, key) => {
    const val = typeof value === 'string' ? [value] : value;
    if (!_.isEmpty(value)) {
      params[key] = val.join(',');
    }
  });

  return params;
}

export function changePage(currentPage: number): $TODOFIXME {
  return (dispatch: $TODOFIXME, getState: $TODOFIXME): void => {
    const { linkByPage, cachedResultsByPage } = getState().messageEvents;
    const currentPageIndex = currentPage - 1;
    const params = qsParseWithPlusSignEncoded(linkByPage[currentPageIndex]);

    if (cachedResultsByPage[currentPageIndex]) {
      dispatch({
        type: 'LOAD_EVENTS_FROM_CACHE',
        payload: currentPageIndex
      });
    } else {
      dispatch(
        sparkpostApiRequest({
          type: 'GET_MESSAGE_EVENTS_PAGE',
          meta: {
            method: 'GET',
            url: '/v1/events/message',
            params,
            showErrorAlert: false,
            currentPageIndex //may need to move to context object (see actions/templates)
          }
        })
      );
    }
  };
}

/**
 * Refreshes the date range for message events
 *
 * Calculates relative ranges if a non-custom relativeRange value is present,
 * which will override passed in from/to dates
 *
 * @param {Object} dateOptions
 * @param {Date} dateOptions.from
 * @param {Date} dateOptions.to
 * @param {String} dateOptions.relativeRange
 */
type RefreshMessageEventsDateRangeOptions = {
  from?: string;
  relativeRange?: string;
  to?: string;
};
export function refreshMessageEventsDateRange(dateOptions: RefreshMessageEventsDateRangeOptions): {
  payload: RefreshMessageEventsDateRangeOptions;
  type: string;
} {
  return {
    type: 'REFRESH_MESSAGE_EVENTS_DATE_OPTIONS',
    payload: dateOptions
  };
}

/**
 * Overwrites filters options
 */
export function updateMessageEventsSearchOptions({
  dateOptions,
  ...options
}: $TODOFIXME): $TODOFIXME {
  const updatedOptions = _.mapValues(options, (arr) => _.uniq(arr)); // Dedupes filter options
  return {
    type: 'REFRESH_MESSAGE_EVENTS_SEARCH_OPTIONS',
    payload: { dateOptions, ...updatedOptions }
  };
}

/**
 * Appends additional filter options to existing options
 */
export function addFilters(filters: $TODOFIXME): $TODOFIXME {
  return {
    type: 'ADD_MESSAGE_EVENTS_FILTERS',
    payload: filters
  };
}

export function removeFilter(filter: $TODOFIXME): $TODOFIXME {
  return {
    type: 'REMOVE_MESSAGE_EVENTS_FILTER',
    payload: filter
  };
}

export function getMessageHistory({ messageId }: $TODOFIXME): $TODOFIXME {
  return sparkpostApiRequest({
    type: 'GET_MESSAGE_HISTORY',
    meta: {
      method: 'GET',
      url: '/v1/events/message',
      params: {
        messages: messageId,
        // Must pass a time range because the defaults are too narrow (now to 24 hours ago) and
        // must cast a wide time range (even wider than the standard 10 day retention) to avoid
        // missing message events
        to: moment.utc().format(apiDateFormat),
        from: moment
          .utc()
          .subtract(retentionPeriodDays, 'days')
          .startOf('day')
          .format(apiDateFormat)
      }
    }
  });
}

export function getSelectedEvent({ eventId }: $TODOFIXME): $TODOFIXME {
  return sparkpostApiRequest({
    type: 'GET_SELECTED_EVENT',
    meta: {
      method: 'GET',
      url: '/v1/events/message',
      params: {
        event_ids: eventId,
        to: moment.utc().format(apiDateFormat),
        from: moment
          .utc()
          .subtract(retentionPeriodDays, 'days')
          .startOf('day')
          .format(apiDateFormat)
      }
    }
  });
}

export function getDocumentation(): $TODOFIXME {
  return sparkpostApiRequest({
    type: 'GET_MESSAGE_EVENTS_DOCUMENTATION',
    meta: {
      method: 'GET',
      url: '/v1/events/message/documentation',
      showErrorAlert: false
    }
  });
}
