import React, { Fragment } from 'react';
import { Box, Panel, Stack, Tag, Text } from 'src/components/matchbox';
import { Heading, TranslatableText } from 'src/components/text';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import { useAlertForm } from '../useAlertForm';

export default function BlocklistAlertSummary() {
  const {
    state: { metricData }
  } = useAlertForm();
  const {
    state: { blocklistFilters }
  } = useAnalyticsReportContext();

  return (
    <Box height="296px">
      <Panel>
        <Panel.Section>
          <Stack>
            <Heading as="h4">Alert Summary</Heading>
            {!metricData.selectedMetric.key && (
              <Text>
                The alert summary will auto-populate when a metric and configurations are selected.
              </Text>
            )}
            {metricData.selectedMetric.key && (
              <Box>
                <TranslatableText>The alert is triggered when a </TranslatableText>
                <Tag color="blue">Blocklist Incident</Tag>
                <TranslatableText> is found in </TranslatableText>
                {blocklistFilters.blocklist_providers.map((blocklist, index) => (
                  <Fragment key={`summary-blocklist-${blocklist}`}>
                    <Tag color="blue">{blocklist}</Tag>
                    {index + 1 < blocklistFilters.blocklist_providers.length && (
                      <TranslatableText>, </TranslatableText>
                    )}
                  </Fragment>
                ))}
                {blocklistFilters.blocklist_resources && (
                  <>
                    <TranslatableText> for </TranslatableText>
                    {blocklistFilters.blocklist_resources.map((resource, index) => (
                      <Fragment key={`summary-resource-${resource}`}>
                        <Tag color="blue">{resource}</Tag>
                        {index + 1 < blocklistFilters.blocklist_resources.length && (
                          <TranslatableText>, </TranslatableText>
                        )}
                      </Fragment>
                    ))}
                  </>
                )}
              </Box>
            )}
          </Stack>
        </Panel.Section>
      </Panel>
    </Box>
  );
}
