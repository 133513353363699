import _ from 'lodash';
import React, { useCallback, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Loading, Tabs } from 'src/components';
import RedirectAndAlert from 'src/components/globalAlert/RedirectAndAlert';
import { ExternalLink, PageLink, SubduedLink } from 'src/components/links';
import { Box, Layout, Page, Stack } from 'src/components/matchbox';
import { Heading } from 'src/components/text';
import { getAlert, getIncident } from 'src/helpers/api/alerts';
import { useSparkPostQuery } from 'src/hooks';
import { AnalyticsReportContextProvider } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import AlertDetails from './AlertDetails';
import AlertOverView from './AlertOverview';
import AlertSummary from './AlertSummary';
import AlertIncidents from './IncidentsTable';

const backBreadcrumb = { content: 'Alerts', to: '/alerts', as: PageLink };

const tabs = [{ content: 'Incident History' }, { content: 'Alert Overview' }];

const TAB_OVERVIEW_AND_HISTORY = 0;
const TAB_ALERT_HISTORY = 1;

export default function NewDetailsPage() {
  const [selectedTab, setSelectedTab] = useState(TAB_OVERVIEW_AND_HISTORY);

  const showDetailsSection = selectedTab === TAB_ALERT_HISTORY;

  const selectTab = useCallback((tab) => {
    setSelectedTab(tab);
  }, []);
  const { id } = useParams();

  const { data, status } = useSparkPostQuery(() => getAlert({ id }));
  const { data: incidents } = useSparkPostQuery(() => getIncident({ id }));

  const primaryAction = {
    content: 'Edit Alert',
    Component: PageLink,
    to: `/alerts/edit/${id}`
  };
  const secondaryAction = {
    content: 'Duplicate Alert',
    Component: PageLink,
    to: `/alerts/duplicate/${id}`
  };

  if (status === 'loading') return <Loading />;
  if (_.isEmpty(data)) {
    return (
      <RedirectAndAlert
        to="/alerts"
        alert={{ type: 'error', message: 'Resource could not be found' }}
      />
    );
  }

  const shouldRenderAlertSummary =
    selectedTab === TAB_OVERVIEW_AND_HISTORY && data.alert_type === 1;

  return (
    <AnalyticsReportContextProvider>
      <Page
        title="Alert Details"
        breadcrumbAction={backBreadcrumb}
        primaryAction={primaryAction}
        secondaryActions={[secondaryAction]}
      >
        <Tabs tabs={tabs} onSelect={selectTab} selected={selectedTab} mb="500" />
        <Box>
          <Layout>
            {showDetailsSection && (
              <Layout.Section annotated>
                <Stack>
                  <Heading as="h4">Details</Heading>
                  <SubduedLink
                    as={ExternalLink}
                    to="https://support.sparkpost.com/docs/user-guide/alerts/"
                  >
                    Alert Documentation
                  </SubduedLink>
                </Stack>
              </Layout.Section>
            )}
            <AlertDetails
              alert={data}
              loading={status === 'loading'}
              showDetailsSection={showDetailsSection}
            />
          </Layout>
          {shouldRenderAlertSummary && <AlertSummary alert={data} />}
          {selectedTab === TAB_OVERVIEW_AND_HISTORY && (
            <AlertIncidents
              alert={data}
              placeholder="e.g. Sparky’s Cookie Co. or monthly-newsletter"
              incidents={incidents}
            />
          )}
          {selectedTab === TAB_ALERT_HISTORY && <AlertOverView alert={data} />}
        </Box>
      </Page>
    </AnalyticsReportContextProvider>
  );
}
