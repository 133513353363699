import React from 'react';
import { Tooltip as MatchboxTooltip, TooltipProps } from '@sparkpost/matchbox';
import { omitDeprecatedProps } from 'src/helpers/hibana';

/**
 * Matchbox Tooltip component.
 * @see https://design.sparkpost.com/components/tooltip/
 */
const Tooltip: typeof MatchboxTooltip = (props: TooltipProps) => {
  return <MatchboxTooltip id={props.id} {...omitDeprecatedProps(props, ['dark'])} />;
};

Tooltip.displayName = 'Tooltip';

export default Tooltip;
