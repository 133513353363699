import { RowProps, CollectionProps } from '../Collection.types';

type CalculateVisibleRowsProps = Pick<CollectionProps, 'rows'> & {
  currentIndex: number;
  filteredRows: $TODOFIXME;
  perPage: number;
};

const calculateVisibleRows = ({
  filteredRows,
  rows,
  currentIndex,
  perPage
}: CalculateVisibleRowsProps): Array<RowProps> => {
  return (filteredRows || rows).slice(currentIndex, currentIndex + perPage);
};

export default calculateVisibleRows;
