import React from 'react';
import ComboBox from 'src/components/ComboBox';

export function MultiEntryField({
  label,
  id,
  name,
  error,
  helpText,
  onChange,
  onBlur,
  onKeyDown,
  value,
  disabled,
  placeholder,
  selectedItems = [],
  onRemoveSelectedItem,
  'data-track': dataTrack,
  'data-id': dataId
}) {
  return (
    <ComboBox>
      <ComboBox.Label htmlFor={`multi-entry-field-${id}`}>{label}</ComboBox.Label>

      <ComboBox.Input
        id={`multi-entry-field-${id}`}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        onKeyDown={onKeyDown}
        data-id={dataId}
        error={error}
        disabled={disabled}
        helpText={helpText}
        placeholder={placeholder}
      />

      <ComboBox.Items>
        {selectedItems.map((item, index) => {
          return (
            <ComboBox.Item
              key={`combobox-selected-item-${index}`}
              onRemove={() => onRemoveSelectedItem({ item, index })}
            >
              {item}
            </ComboBox.Item>
          );
        })}
      </ComboBox.Items>

      {/* Hidden input for analytics data */}
      <input
        type="hidden"
        label={label}
        id={id}
        name={name}
        data-track={dataTrack}
        value={selectedItems.join(',')}
      />
    </ComboBox>
  );
}
