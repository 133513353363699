import { formatNumber } from '@sparkpost/report-builder/helpers/units';
import React from 'react';
import { TranslatableText } from '../text';

// Formats count into an abbreviated value
const Count = ({ value }: { value: number }): JSX.Element => (
  <TranslatableText>{formatNumber(value)}</TranslatableText>
);
Count.displayName = 'Count';

export { Count };
