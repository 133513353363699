import React from 'react';
import { CheckboxCard, Box } from 'src/components/matchbox';

interface DataSourcesProps {
  checkboxes: $TODOFIXME;
}

export default function DataSources({ checkboxes }: DataSourcesProps): JSX.Element {
  return (
    <Box position="relative">
      <CheckboxCard.Group label="Deliverabilty Data Sources" space="compact">
        {checkboxes.map((checkbox: $TODOFIXME) => (
          <CheckboxCard
            size="small"
            id={`checkbox_${checkbox.name}`}
            key={`checkbox_${checkbox.name}`}
            {...checkbox}
          />
        ))}
      </CheckboxCard.Group>
    </Box>
  );
}
