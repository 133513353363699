import React from 'react';
import { Field } from 'redux-form';
import GrantsCheckboxes from 'src/components/grantBoxes/GrantsCheckboxes';
import { ExternalLink } from 'src/components/links';
import { Stack } from 'src/components/matchbox';
import {
  CheckboxWrapper,
  RadioGroup,
  SelectWrapper,
  TextFieldWrapper,
  ToggleBlockWrapper
} from 'src/components/reduxFormWrappers';
import { LINKS } from 'src/constants';
import { required } from 'src/helpers/validation';
import ipValidator from '../helpers/ipValidator';

const uneditableMsg = 'System set statuses cannot be edited.';

const keyBoxHelpText = (createApiKey) =>
  createApiKey
    ? 'The key will only be shown once when created, so be sure to copy and save it somewhere safe.'
    : 'Every subaccount you create will need its own API key. You can create one later.';

const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'suspended', label: 'Suspended' },
  { value: 'terminated', label: 'Terminated' }
];

const grantsOptions = [
  { value: 'all', label: 'All' },
  { value: 'select', label: 'Select' }
];

const NameField = ({ disabled }) => (
  <Field
    name="name"
    component={TextFieldWrapper}
    label="Subaccount Name"
    validate={required}
    disabled={disabled}
    helpText={<ExternalLink to={LINKS.SUBACCOUNTS}>Learn more about Subaccounts</ExternalLink>}
    data-track={true}
  />
);

const ApiKeyCheckBox = ({ disabled, createApiKey }) => (
  <Field
    name="createApiKey"
    component={CheckboxWrapper}
    type="checkbox"
    label="Create API Key"
    helpText={keyBoxHelpText(createApiKey)}
    disabled={disabled}
    data-track={true}
  />
);

const ApiKeyFields = ({ show, showGrants = false, grants, disabled }) => {
  if (!show) {
    return null;
  }

  return (
    <div>
      <Stack>
        <Field
          name="keyName"
          component={TextFieldWrapper}
          label="Key Name"
          validate={required}
          disabled={disabled}
          data-track={true}
        />

        <Stack space="200">
          <Field
            name="grantsRadio"
            component={RadioGroup}
            data-track={true}
            label="API Permissions"
            options={grantsOptions}
            disabled={disabled}
          />

          {showGrants && <GrantsCheckboxes grants={grants} />}
        </Stack>

        <Field
          name="validIps"
          component={TextFieldWrapper}
          label="Allowed IPs"
          helpText="Leaving the field blank will allow access by valid API keys from any IP address."
          placeholder="10.20.30.40, 10.20.30.0/24"
          validate={ipValidator}
          disabled={disabled}
          data-track={true}
        />
      </Stack>
    </div>
  );
};

const StatusSelect = ({ disabled, compliance }) =>
  compliance ? (
    <Field
      name="status"
      component={TextFieldWrapper}
      label="Status"
      disabled={true}
      helpText={uneditableMsg}
      data-track={true}
    />
  ) : (
    <Field
      name="status"
      component={SelectWrapper}
      options={statusOptions}
      label="Status"
      disabled={disabled}
      data-track={true}
    />
  );

const DeliverabilityToggleBlock = ({ disabled }) => {
  return (
    <Field
      name="options.deliverability"
      component={ToggleBlockWrapper}
      variant="dense"
      label="Enable Access to Deliverability Data"
      helpText="The subaccount will be able to access its respective deliverability metrics and seed list configurations."
      disabled={disabled}
      data-track={true}
    />
  );
};

export { NameField, ApiKeyCheckBox, ApiKeyFields, DeliverabilityToggleBlock, StatusSelect };
