import React, { useCallback } from 'react';
import { Box, QueryCard, Stack } from 'src/components/matchbox';
import getConfig from 'src/helpers/getConfig';

type Metric = {
  category: string;
  description: string;
  inSummary: boolean;
  isUniquePerTimePeriod?: boolean;
  key: string;
  label: string;
  name: string;
  prefetchedKey?: string;
  stroke: string;
  tab?: string;
  type: string;
  unit: string;
};

type MetricRollup = {
  format: string;
  max: number;
  min: number;
  recommended: number;
  uniqueLabel: string;
  value: string;
};

type ActiveMetricsProps = {
  metrics: Metric[];
  precision: string;
  removeMetric: (index: number) => void;
};

export default function ActiveMetrics({
  metrics,
  removeMetric,
  precision
}: ActiveMetricsProps): JSX.Element | null {
  const metricsRollupPrecisionMap: MetricRollup[] = getConfig(
    'metricsRollupPrecisionMap'
  ) as MetricRollup[];
  const precisionObj = metricsRollupPrecisionMap.find((p) => p.value === precision);
  const uniqueLabel = precisionObj?.uniqueLabel;

  if (!metrics) return null;

  return (
    <Box marginY="200" position="relative">
      <Stack space="200">
        {metrics.map((metric, index) => {
          return (
            <Box key={metric.name} data-id="metric-tag">
              <MetricTag
                metric={metric}
                uniqueLabel={uniqueLabel}
                onRemove={removeMetric}
                index={index}
              />
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
}

type MetricTagProps = {
  index: number;
  metric: Metric;
  onRemove: (index: number) => void;
  uniqueLabel: string | undefined;
};

export function MetricTag({ metric, uniqueLabel, onRemove, index }: MetricTagProps): JSX.Element {
  const handleRemove = useCallback(() => {
    onRemove(index);
  }, [index, onRemove]);

  return (
    <Box data-id={`metric-tag-${metric.name}`}>
      <QueryCard onClose={handleRemove}>
        <QueryCard.Legend color={metric.stroke} />
        <QueryCard.Title>{metric.label}</QueryCard.Title>
        {uniqueLabel && metric.isUniquePerTimePeriod && (
          <QueryCard.Subtitle>{uniqueLabel}</QueryCard.Subtitle>
        )}
      </QueryCard>
    </Box>
  );
}
