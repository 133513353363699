import { XhrRequestMeta } from 'src/typescript/interfaces/XhrRequestMeta';

export function generateTwoFactorInvite(username: string): XhrRequestMeta {
  return {
    method: 'POST',
    url: `/v1/users/${username}/two-factor/invite`
  };
}

export function resetTwoFactorConfig(username: string): XhrRequestMeta {
  return {
    method: 'DELETE',
    url: `/v1/users/${username}/two-factor`
  };
}

export function getTfaBackupStatus(username: string): XhrRequestMeta {
  return {
    method: 'GET',
    url: `/v1/users/${username}/two-factor/backup`
  };
}

export function generateBackupCodes({ username }: { username: string }): XhrRequestMeta {
  return {
    method: 'POST',
    url: `/v1/users/${username}/two-factor/backup`
  };
}

export function createUser(email: string, access_level: string): XhrRequestMeta {
  return {
    method: 'POST',
    url: '/v1/users/invite',
    data: {
      email,
      access_level
    }
  };
}

export function createSubaccountUser(
  email: string,
  subaccounts: { access_level: string; subaccount_id: number | string }[]
): XhrRequestMeta {
  return {
    method: 'POST',
    url: '/v1/users/invite',
    data: {
      email,
      subaccounts
    }
  };
}

export function addSubaccountAssignment(
  username: string,
  data: { access_level: string; subaccount_id: string }
): XhrRequestMeta {
  return {
    method: 'POST',
    url: `/v1/users/${username}/subaccounts`,
    data: {
      ...data,
      username
    }
  };
}

export function removeSubaccountAssignment(username: string, subaccountId: string): XhrRequestMeta {
  return {
    method: 'DELETE',
    url: `/v1/users/${username}/subaccounts/${subaccountId}`,
    data: {
      username
    }
  };
}

export function listAllPendingUserInvitations() {
  return {
    method: 'GET',
    url: '/v1/users/pending-invites/all'
  };
}

export function deletePendingInvitation(invitationId: string) {
  return {
    method: 'DELETE',
    url: `/v1/users/pending-invites/${invitationId}`
  };
}
