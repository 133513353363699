/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useMemo, useRef } from 'react';

import { useRouteMatch } from 'react-router-dom';
import { Box, Stack, Tag, Text, Tooltip } from 'src/components/matchbox';
import { Heading, TranslatableText } from 'src/components/text';
import { Typeahead, TypeaheadItem } from 'src/components/typeahead';
import { getBlocklists, getSendingDomains, getSendingIps } from 'src/helpers/api/metrics';
import { useSparkPostQuery } from 'src/hooks';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import { useAlertForm } from '../useAlertForm';

export default function BlocklistMetric() {
  const isEditingAlert = useRouteMatch('/alerts/edit/:id');
  const blocklistRef = useRef();

  const {
    state: { accountData }
  } = useAlertForm();
  const {
    state: { blocklistFilters },
    actions: { setBlocklistFilters }
  } = useAnalyticsReportContext();

  const { data: blocklistRequestData } = useSparkPostQuery(getBlocklists);
  const { data: sendingDomainsRequestData } = useSparkPostQuery(getSendingDomains);
  const { data: sendingIpsRequestData } = useSparkPostQuery(getSendingIps);

  const anyOption = { key: 'Any', label: 'Any' };
  const sendingDomains = useMemo(() => {
    if (!sendingDomainsRequestData) {
      return [];
    }
    const sendingDomainsData = sendingDomainsRequestData['sending-domains'];
    return sendingDomainsData.map((data) => ({ key: data, label: data }));
  }, [sendingDomainsRequestData]);

  const blocklists = useMemo(() => {
    if (!blocklistRequestData) {
      return [];
    }
    return [
      anyOption,
      ...blocklistRequestData?.map((data) => ({ key: data.code, label: data.name }))
    ];
  }, [blocklistRequestData, anyOption]);

  const sendingIps = useMemo(() => {
    if (!sendingIpsRequestData) {
      return [];
    }
    const sendingIpsData = sendingIpsRequestData['sending-ips'];
    return sendingIpsData.map((data) => ({ key: data, label: data }));
  }, [sendingIpsRequestData]);

  const resources = useMemo(() => {
    return [anyOption, ...sendingIps.concat(sendingDomains)];
  }, [sendingDomains, sendingIps, anyOption]);

  const handleOnChangeBlocklist = useCallback(
    (selected) => {
      if (!selected) return;

      setBlocklistFilters({
        blocklist_providers: [...blocklistFilters.blocklist_providers, selected.label],
        errors: { blocklist_providers: false }
      });
    },
    [blocklistFilters, setBlocklistFilters]
  );

  const removeBlocklist = useCallback(
    (blocklist) => {
      setBlocklistFilters({
        blocklist_providers: blocklistFilters.blocklist_providers.filter(
          (item) => item !== blocklist
        ),
        errors: { blocklist_providers: false }
      });
    },
    [blocklistFilters, setBlocklistFilters]
  );

  const handleOnChangeResource = useCallback(
    (selected) => {
      if (!selected) return;

      setBlocklistFilters({
        blocklist_resources: [...blocklistFilters.blocklist_resources, selected.key],
        errors: { blocklist_resources: false }
      });
    },
    [blocklistFilters.blocklist_resources, setBlocklistFilters]
  );

  const removeResource = useCallback(
    (resource) => {
      setBlocklistFilters({
        blocklist_resources: blocklistFilters.blocklist_resources.filter(
          (item) => item !== resource
        ),
        errors: { blocklist_resources: false }
      });
    },
    [blocklistFilters, setBlocklistFilters]
  );

  return (
    <>
      <Stack>
        <Heading as="h4">Metric and Filters</Heading>
        <Box>
          <Text as="span">Customize the alert by defining the specific metric and filters.</Text>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Text as="h5">Metric</Text>
            <Text>Blocklistings</Text>
          </Box>
          <Box mr="25%">
            <Text as="h5">Subaccount Assignment</Text>
            <Text>
              {accountData.assignment === 'subaccount' && accountData.subaccount.value !== undefined
                ? accountData.subaccount.value
                : 'Primary Account'}
            </Text>
          </Box>
        </Box>
        <Box maxWidth="400px">
          <Typeahead
            results={blocklists}
            itemToString={(item) => (item?.label ? item.label : '')}
            placeholder="Spamhaus"
            label="Blocklist"
            renderItem={({ label, key }) => <TypeaheadItem id={key} label={label || ''} />}
            onChange={handleOnChangeBlocklist}
            canChange
            disabled={isEditingAlert}
            error={blocklistFilters.errors?.blocklist_providers && 'Required'}
            ref={blocklistRef}
            unpersist
          />
          <Box>
            {blocklistFilters.blocklist_providers.map((blocklist, index) => (
              <Tooltip
                key={`tag-${blocklist}-${index}`}
                id={`tag-${blocklist}-${index}`}
                content={blocklist}
              >
                <Tag onRemove={() => removeBlocklist(blocklist)} mr="100" mb="100">
                  <TranslatableText>{blocklist}</TranslatableText>
                </Tag>
              </Tooltip>
            ))}
          </Box>
          <Typeahead
            results={resources}
            itemToString={(item) => (item?.label ? item.label : '')}
            placeholder="e.g. 00.000.00"
            label="Resource"
            renderItem={({ label, value }) => <TypeaheadItem id={value} label={label || ''} />}
            onChange={handleOnChangeResource}
            canChange
            disabled={isEditingAlert}
            required
            error={blocklistFilters.errors?.blocklist_resources && 'Required'}
            unpersist
          />
          <Box>
            {blocklistFilters.blocklist_resources.map((resource, index) => (
              <Tooltip
                key={`tag-${resource}-${index}`}
                id={`tag-${resource}-${index}`}
                content={resource}
              >
                <Tag onRemove={() => removeResource(resource)} mr="100" mb="100">
                  <TranslatableText>{resource}</TranslatableText>
                </Tag>
              </Tooltip>
            ))}
          </Box>
        </Box>
      </Stack>
    </>
  );
}
