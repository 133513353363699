import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RUDDERSTACK_EVENTS } from 'src/helpers/rudderstack';
import track from 'src/helpers/track';

import { usePrevious } from 'src/hooks';

export const TrackModal = ({ open, title, children }) => {
  const wasOpen = usePrevious(open);
  const location = useLocation();

  useEffect(() => {
    if (!wasOpen && open) {
      track(RUDDERSTACK_EVENTS.MODAL_OPENED, {
        title: title,
        location: location.pathname
      });
    }

    if (wasOpen && !open) {
      track(RUDDERSTACK_EVENTS.MODAL_CLOSED, {
        title: title,
        location: location.pathname
      });
    }
  }, [location.pathname, open, title, wasOpen]);

  return <>{children}</>;
};
