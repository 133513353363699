/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { Box, Stack, Text, Radio, RadioCard, Select, Grid } from 'src/components/matchbox';
import { Heading } from 'src/components/text';
import { useAlertForm } from '../useAlertForm';
import { getFormSpec } from '../../helpers/alertForm';
import { LINKS, MAILBOX_PROVIDERS } from 'src/constants';
import { listBlocklists, listMonitors } from 'src/actions/blocklist';
import { listPools } from 'src/actions/ipPools';
import { list as listSendingDomains } from 'src/actions/sendingDomains';
import { list as listSendingIps } from 'src/actions/sendingIps';
import { ExternalLink } from 'src/components/links';
import Typeahead from 'src/pages/analyticsReport/components/Typeahead';

import { useDispatch, useSelector } from 'react-redux';
import { getIpPools } from 'src/selectors/ipPools';
import { selectVerifiedDomains } from 'src/selectors/sendingDomains';
import { ComboBoxTypeahead } from 'src/components/typeahead/ComboBoxTypeahead.js';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import { FILTER_OPTIONS } from 'src/pages/analyticsReport/constants/index.js';
import { useRouteMatch } from 'react-router-dom';

const subaccountFilter = FILTER_OPTIONS.find((option) => option.value === 'subaccounts');
const propertyValuesSelector = (state) => {
  return {
    blocklists: state.blocklist.blocklists,
    blocklistsPending: state.blocklist.blocklistsPending,
    blocklistMonitors: state.blocklist.monitors,
    blocklistMonitorsPending: state.blocklist.monitorsPending,
    ipPools: getIpPools(state) || [],
    sendingDomains: selectVerifiedDomains(state) || [],
    sendingIps: state.sendingIps.list || [],
    ipPoolLoading: state.ipPools.listLoading,
    sendingDomainsLoading: state.sendingDomains.listLoading
  };
};
const mbItemToString = (item) => MAILBOX_PROVIDERS[item] || '';

export default function HealthScoreMetric() {
  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(listBlocklists());
    reduxDispatch(listMonitors());
    reduxDispatch(listPools());
    reduxDispatch(listSendingDomains());
    reduxDispatch(listSendingIps());
  }, [reduxDispatch]);

  const {
    state: { accountData }
  } = useAlertForm();
  const {
    state: { healthScoreFilters, healthScoreSubaccounts },
    actions
  } = useAnalyticsReportContext();
  const { setHealthScoreFilters, setHealthScoreSubaccounts, cleanHealthScoreFilters } = actions;

  const selectedMonitoredSubaccount = useMemo(() => {
    const { any_subaccount: anySubaccount, subaccounts } = healthScoreSubaccounts;
    if (anySubaccount) {
      if (!subaccounts || subaccounts.length === 0) {
        return -1;
      }
    } else if (subaccounts?.length === 1 && !subaccounts[0]) {
      return 0;
    }

    return -2;
  }, [healthScoreSubaccounts]);
  const [breakdown, setBreakdown] = useState(!!healthScoreFilters?.facet_name);
  const [showSubaccountsInput, setShowSubaccountsInput] = useState(
    selectedMonitoredSubaccount === -2
  );

  const setSelectedFacet = useCallback(
    (facet) => {
      setHealthScoreFilters({ facet_name: facet, errors: { facet_name: false } });
    },
    [setHealthScoreFilters]
  );
  const setFacetValues = useCallback(
    (filters) => {
      setHealthScoreFilters({ facet_values: filters, errors: { facet_values: false } });
    },
    [setHealthScoreFilters]
  );
  const handleSubaccountSelection = useCallback(
    ({ values }) => {
      setHealthScoreSubaccounts({ any_subaccount: false, subaccounts: values });
    },
    [setHealthScoreSubaccounts]
  );

  const isDuplicatingAlert = !!useRouteMatch('/alerts/duplicate/:id');
  const isEditingAlert = !!useRouteMatch('/alerts/edit/:id');
  useEffect(() => {
    if (breakdown && !healthScoreFilters?.facet_name) {
      setSelectedFacet('none');
    }
    if (!isDuplicatingAlert && !isEditingAlert) {
      setShowSubaccountsInput(false);
      setHealthScoreSubaccounts({ any_subaccount: true, subaccounts: undefined });
    }
  }, [
    breakdown,
    healthScoreFilters.facet_name,
    isDuplicatingAlert,
    isEditingAlert,
    setHealthScoreSubaccounts,
    setSelectedFacet
  ]);

  const formSpec = getFormSpec('health_score');
  const {
    blocklists,
    blocklistMonitors,
    ipPools,
    sendingDomains,
    sendingIps,
    ipPoolLoading,
    blocklistMonitorsPending,
    blocklistsPending,
    sendingDomainsLoading
  } = useSelector(propertyValuesSelector);

  const filterTypeaheadResults = {
    ip_pool: ipPools.map(({ id }) => id),
    mailbox_provider: Object.keys(MAILBOX_PROVIDERS),
    sending_domain: sendingDomains.map(({ domain }) => domain),
    sending_ip: sendingIps.map(({ external_ip }) => external_ip),
    blocklist_provider: blocklists.map(({ code }) => code),
    blocklist_resource: blocklistMonitors.map(({ resource }) => resource)
  };
  const extraProps = {
    none: {
      disabled: true,
      placeholder: 'No facet type selected'
    },
    ip_pool: {
      disabled: ipPoolLoading || ipPools.length === 0,
      placeholder: ipPoolLoading
        ? 'Loading your IP Pools...'
        : ipPools.length === 0
        ? 'No Options Available'
        : 'Type To Search'
    },
    mailbox_provider: {
      itemToString: mbItemToString,
      placeholder: 'Type To Search',
      helpText: (
        <>
          For a breadown of popular inbox providers, see our{' '}
          <ExternalLink to={LINKS.MAILBOX_PROVIDERS}>alerts guide</ExternalLink>
        </>
      )
    },
    sending_domain: {
      disabled: sendingDomainsLoading || sendingDomains.length === 0,
      placeholder: sendingDomainsLoading
        ? 'Loading your Sending Domains...'
        : sendingDomains.length === 0
        ? 'No Options Available'
        : 'Type To Search'
    },
    sending_ip: {
      placeholder: 'Type To Search'
    },
    blocklist_provider: {
      disabled: blocklistsPending,
      placeholder: 'Type To Search'
    },
    blocklist_resource: {
      disabled: blocklistMonitorsPending || blocklistMonitors.length === 0,
      placeholder: 'Type To Search'
    }
  };

  return (
    <>
      <Stack>
        <Heading as="h4">Metric and Filters</Heading>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Text as="h5">Metric</Text>
            <Text>Health Score</Text>
          </Box>
          <Box mr="25%">
            <Text as="h5">Subaccount Assignment</Text>
            <Text>
              {accountData.assignment === 'subaccount' && accountData.subaccount.value !== undefined
                ? accountData.subaccount.value
                : 'Primary Account'}
            </Text>
          </Box>
        </Box>
        <Box>
          <Text as="h5">Breakdown</Text>
          <Text>
            Monitor Health Score by its total value or by a specific property. Properties include
            Subaccount, Mailbox Provider, IP Pool, Campaign (ID), and Sending Domain.
          </Text>
          <Box>
            <RadioCard.Group orientation="horizontal">
              <RadioCard
                label="No Breakdown"
                id="no_breakdown"
                name="type"
                checked={!breakdown}
                onClick={() => {
                  cleanHealthScoreFilters();
                  setBreakdown(false);
                }}
              >
                The alert is triggered if the total value of the metric meets the threshold.
              </RadioCard>
              <RadioCard
                label="Breakdown by Property"
                id="breakdown"
                name="type"
                checked={breakdown}
                onClick={() => setBreakdown(true)}
              >
                The alert is triggered if any one item in a specified property (i.e. subaccount,
                mailbox provider, campaign_id, etc.) meets the threshold.
                <Box padding="12px" />
              </RadioCard>
            </RadioCard.Group>
          </Box>
        </Box>
        <Box>
          <Text>Monitored Subaccount</Text>
          <Radio.Group>
            <Radio
              id="-1"
              label="Monitor health of the Primary Account and all subaccounts"
              name="group"
              checked={selectedMonitoredSubaccount === -1}
              onChange={() => {
                setShowSubaccountsInput(false);
                setHealthScoreSubaccounts({ any_subaccount: true, subaccounts: undefined });
              }}
            />
            <Radio
              id="0"
              label="Monitor the health of the Primary Account only"
              name="group"
              checked={selectedMonitoredSubaccount === 0}
              onChange={() => {
                setShowSubaccountsInput(false);
                setHealthScoreSubaccounts({ any_subaccount: false, subaccounts: [0] });
              }}
            />
            <Radio
              id="-2"
              label="Monitor the health of selected subaccounts"
              name="group"
              checked={selectedMonitoredSubaccount === -2}
              onChange={() => {
                setHealthScoreSubaccounts({ subaccounts: [] });
                setShowSubaccountsInput(true);
              }}
            />
          </Radio.Group>
          {showSubaccountsInput && (
            <Box maxWidth="400px" mt="12px">
              <Typeahead
                placeholder="e.g. Sparky's Cookie Co."
                lookaheadRequest={subaccountFilter.query}
                type={subaccountFilter.label}
                label={subaccountFilter.label}
                filterType={subaccountFilter.value}
                selector={subaccountFilter.selector}
                itemToString={(item) => (item?.value ? item.value : '')}
                value={healthScoreSubaccounts?.subaccounts || []}
                setFilterValues={handleSubaccountSelection}
                error={accountData.errors.subaccount && 'Required'}
              />
            </Box>
          )}
        </Box>
        <Box mb="-12px">
          {breakdown && (
            <>
              <Grid>
                <Grid.Column sm={12} md={3} lg={3}>
                  <Select
                    label="Property"
                    options={formSpec.filterOptions}
                    value={healthScoreFilters?.facet_name || 'none'}
                    error={healthScoreFilters?.errors?.facet_name && 'Required'}
                    onChange={(e) => {
                      setSelectedFacet(e.target.value);
                      setFacetValues([]);
                    }}
                  />
                </Grid.Column>
                <Grid.Column sm={12} md={9} lg={9}>
                  <ComboBoxTypeahead
                    label="&nbsp;"
                    results={filterTypeaheadResults[healthScoreFilters?.facet_name] || []}
                    key={healthScoreFilters?.facet_name}
                    value={healthScoreFilters?.facet_values || []}
                    onChange={setFacetValues}
                    error={healthScoreFilters?.errors?.facet_values && 'Required'}
                    {...extraProps[healthScoreFilters?.facet_name]}
                  />
                </Grid.Column>
              </Grid>
            </>
          )}
        </Box>
      </Stack>
    </>
  );
}
