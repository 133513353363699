import React, { forwardRef, LegacyRef, useCallback } from 'react';
import { Box, Button, ScreenReaderOnly } from 'src/components/matchbox';

type RadioButtonGroupProps = {
  children: React.ReactNode;
  label: string;
};

const RadioButtonGroup = ({ label, children }: RadioButtonGroupProps) => {
  return (
    <Box border="0" padding="0" as="fieldset">
      <ScreenReaderOnly as={'legend' as $TODOFIXME}>{label}</ScreenReaderOnly>

      <Button.Group>{children}</Button.Group>
    </Box>
  );
};

type RadioButtonProps = {
  checked: boolean;
  children: React.ReactNode;
  className?: string;
  'data-track': boolean;
  id: string;
  name: string;
  onChange: (value: string) => void;
  value: string;
};

const RadioButton = forwardRef((props: RadioButtonProps, ref: LegacyRef<HTMLInputElement>) => {
  const {
    id,
    name,
    value,
    children,
    checked = false,
    onChange,
    className,
    'data-track': dataTrack
  } = props;

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();

      onChange(value);
    },
    [onChange, value]
  );

  return (
    <Button
      variant={checked ? 'primary' : 'connected'}
      onClick={handleClick}
      className={className}
      role="presentation"
      useMatchboxVariant={false}
    >
      {/* Content within the button, only renders visually but not for screen reader users */}
      <span aria-hidden="true">{children}</span>

      {/* Radio button available to screen reader users, but not sighted users - and removed from the tab order via `tabIndex="-1"` */}
      <ScreenReaderOnly as="span">
        <label htmlFor={id}>{children}</label>

        <input
          type="radio"
          ref={ref}
          value={value}
          name={name}
          id={id}
          readOnly
          checked={checked}
          data-track={dataTrack}
          tabIndex={-1}
        />
      </ScreenReaderOnly>
    </Button>
  );
});

RadioButtonGroup.displayName = 'RadioButtonGroup';
RadioButton.displayName = 'RadioButtonGroup.Button';
RadioButtonGroup.Button = RadioButton;

export default RadioButtonGroup;
