/* eslint-disable max-lines */
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'src/actions/globalAlert';
import {
  ActionPopover,
  ApiErrorBanner,
  DeleteModal,
  Loading,
  TableCollection
} from 'src/components';
import { Users } from 'src/components/images';
import { PageLink } from 'src/components/links';
import { Page, ScreenReaderOnly, Tag } from 'src/components/matchbox';
import { deletePendingInvitation, listAllPendingUserInvitations } from 'src/helpers/api/users';
import { useSparkPostMutation, useSparkPostQuery } from 'src/hooks';
import { deriveQueryKey } from 'src/hooks/api/helpers/deriveQueryKey';
import { usePrepareQuery } from 'src/hooks/api/helpers/usePrepareQuery';

const COLUMNS = [
  { label: 'Email', sortKey: 'email' },
  { label: 'From', sortKey: 'from' },
  { label: <ScreenReaderOnly>Invitation Status</ScreenReaderOnly>, key: 'invitationStatus' },
  { label: <ScreenReaderOnly>Actions</ScreenReaderOnly>, key: 'actions' }
];

export const Actions = ({ onDelete }) => {
  const actions = [{ content: 'Delete', onClick: () => onDelete() }];
  return <ActionPopover actions={actions} />;
};

Actions.displayName = 'Actions';

const ViewInvitedUsers = () => {
  // STATE
  const dispatch = useDispatch();
  const prepareQuery = usePrepareQuery();
  const [toBeDeletedInvitationId, setToBeDeletedInvitationId] = useState(null);

  // API
  const { data, error, isLoading, refetch, isSuccess } = useSparkPostQuery(() =>
    listAllPendingUserInvitations()
  );

  const pendingInvitations = useMemo(() => {
    return data ?? [];
  }, [data]);

  const deleteMutation = useSparkPostMutation(
    ({ invitationId } = {}) => deletePendingInvitation(invitationId),
    {
      onError(error) {
        dispatch(
          showAlert({
            type: 'error',
            message: error.message || 'Something went wrong. Please try again.'
          })
        );
      },
      onSuccess() {
        const email = data.find((invite) => invite.id === toBeDeletedInvitationId).email;

        dispatch(
          showAlert({
            type: 'success',
            message: email
              ? `Invitation to "${email}" has been cancelled.`
              : `Invitation has been cancelled.`
          })
        );
        setToBeDeletedInvitationId(null);
        prepareQuery.queryClient.invalidateQueries({
          queryKey: deriveQueryKey({
            queryFn: listAllPendingUserInvitations,
            auth: prepareQuery.auth,
            currentUser: prepareQuery.currentUser
          })
        });
      }
    }
  );

  const { mutate: deleteInvitationId } = deleteMutation;

  // METHODS

  const handleCancel = useCallback(() => {
    setToBeDeletedInvitationId(null);
  }, []);

  const handleDelete = useCallback(() => {
    if (!toBeDeletedInvitationId) return;

    deleteInvitationId({
      invitationId: toBeDeletedInvitationId
    });
  }, [deleteInvitationId, toBeDeletedInvitationId]);

  // Do not allow current user to change their access/role or delete their account
  const getRowData = useCallback((invite) => {
    const data = [
      invite.email,
      invite.from,
      <Tag color="yellow">Pending</Tag>,
      <Actions
        // eslint-disable-next-line react/jsx-no-bind
        onDelete={() => setToBeDeletedInvitationId(invite.id)}
      />
    ];
    return data;
  }, []);

  // VIEW

  // @note This component must always be the page to properly handle css transition
  const deleteModalDialog = useMemo(() => {
    if (!toBeDeletedInvitationId) return;

    const inviteToDelete = pendingInvitations.find(
      (invite) => invite.id === toBeDeletedInvitationId
    );

    if (!inviteToDelete) return;

    return (
      <DeleteModal
        deleting={deleteMutation.isLoading}
        onDelete={handleDelete}
        onCancel={handleCancel}
        open={true}
        content={
          <p>
            <span>The pending invitation for</span> <Tag>{inviteToDelete.email}</Tag>{' '}
            <span>will be cancelled</span>
          </p>
        }
        title="Are you sure you want to delete this invitation?"
      />
    );
  }, [
    deleteMutation.isLoading,
    handleCancel,
    handleDelete,
    pendingInvitations,
    toBeDeletedInvitationId
  ]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page
      title="Invited Users"
      empty={{
        show: isSuccess && pendingInvitations.length === 0,
        title: 'Invite Your Team to SparkPost',
        image: Users,
        content: <p>Manage your team's accounts and roles.</p>,
        secondaryAction: {
          Component: PageLink,
          content: 'Invite a new user',
          to: `/account/users/create`
        }
      }}
      breadcrumbAction={{ content: 'View Users', as: PageLink, to: '/account/users' }}
    >
      {error ? (
        <ApiErrorBanner
          errorDetails={error.message}
          message="Sorry, we seem to have had some trouble loading your users."
          reload={refetch}
        />
      ) : (
        <div>
          <TableCollection
            columns={COLUMNS}
            getRowData={getRowData}
            pagination={true}
            rows={pendingInvitations}
            filterBox={{
              show: true,
              exampleModifiers: ['email', 'from'],
              itemToStringKeys: ['email', 'from']
            }}
            defaultSortColumn="email"
          />

          {/* eslint-disable-next-line local/restrict-translatable-text */}
          {deleteModalDialog}
        </div>
      )}
    </Page>
  );
};

export default ViewInvitedUsers;
