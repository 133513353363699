import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Label, Text, Toggle } from 'src/components/matchbox';

function ToggleBlock(props) {
  /* variant prop helps adjusting the spacing between toggle and label text*/
  const { input, label, helpText, variant, ...rest } = props;
  const columns = variant === 'dense' ? 4 : 8;
  const justifyContent = variant === 'dense' ? 'flex-start' : 'flex-end';

  return (
    <Box data-id="toggle-block">
      <Grid middle="xs">
        <Grid.Column xs={columns}>
          {/* actual label for ScreenReaders made available via the `Toggle` component */}
          <div aria-hidden="true">
            <Label label={label} fontSize="200" />
          </div>
        </Grid.Column>

        <Grid.Column xs={4}>
          <Box display="flex" alignItems="center" justifyContent={justifyContent}>
            <Toggle
              id={input.name}
              label={label}
              aria-describedby={helpText && `help-text-${input.name}`}
              {...input}
              {...rest}
            />
          </Box>
        </Grid.Column>
      </Grid>

      {helpText && (
        <Box maxWidth="70%">
          <Text color="gray.700" fontSize="200" id={`help-text-${input.name}`}>
            {helpText}
          </Text>
        </Box>
      )}
    </Box>
  );
}

ToggleBlock.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  helpText: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default ToggleBlock;
