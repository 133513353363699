/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';

import { list } from '@sparkpost/report-builder/config';
import { useRouteMatch } from 'react-router-dom';
import { ActiveFilters } from 'src/components';
import { Box, Button, Drawer, Stack, Text } from 'src/components/matchbox';
import { Heading } from 'src/components/text';
import { Typeahead, TypeaheadItem } from 'src/components/typeahead';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import { useAlertForm } from '../useAlertForm';
import MetricsDrawer from './MetricsDrawer';

export default function Metric() {
  const { getDrawerProps, openDrawer, closeDrawer } = Drawer.useDrawer({
    id: 'alerts-metrics-drawer'
  });

  const isEditingAlert = useRouteMatch('/alerts/edit/:alertId');

  const {
    state: { metricData, accountData },
    dispatch
  } = useAlertForm();

  const { state: reportOptions, actions } = useAnalyticsReportContext();
  const { refreshReportOptions, removeFilter } = actions;

  const handleSubmit = useCallback(
    (props) => {
      refreshReportOptions({ ...reportOptions, ...props });
      closeDrawer();
    },
    [closeDrawer, refreshReportOptions, reportOptions]
  );

  const handleFilterRemove = useCallback(
    ({ groupingIndex, filterIndex, valueIndex }) => {
      return removeFilter({ groupingIndex, filterIndex, valueIndex });
    },
    [removeFilter]
  );

  const handleSelectMetric = useCallback(
    (metric) => {
      handleSubmit({ metrics: [metric.key] });
      dispatch({ type: 'UPDATE_METRIC_DATA', data: { selectedMetric: metric } });
    },
    [dispatch, handleSubmit]
  );

  return (
    <>
      <Stack>
        <Heading as="h4">Metric and Filters</Heading>
        <Box>
          <Text as="span">Customize the alert by defining the specific metric and filters.</Text>
        </Box>
        <Box maxWidth="400px">
          <Typeahead
            results={list.filter((item) => item.inSummary || item.inAnalyticsReport)}
            itemToString={(item) => (item?.label ? item.label : '')}
            placeholder="e.g. Bounce Rate"
            label="Metric"
            renderItem={({ label, value }) => <TypeaheadItem id={value} label={label || ''} />}
            selectedItem={metricData.selectedMetric}
            onChange={handleSelectMetric}
            canChange
            disabled={isEditingAlert}
            required
            error={metricData.errors.selectedMetric && 'Required'}
          />
        </Box>
        <Box mb="-12px">
          <Text>Filters</Text>
          <ActiveFilters filters={reportOptions.filters} handleFilterRemove={handleFilterRemove} />
        </Box>
        <Box>
          <Button variant="secondary" onClick={openDrawer}>
            Add Filters
          </Button>
        </Box>
        <Box>
          <Text as="h5">Subaccount Assignment</Text>
          <Text>
            {accountData.assignment === 'subaccount' && accountData.subaccount.value !== undefined
              ? accountData.subaccount.value
              : 'Primary Account'}
          </Text>
        </Box>
      </Stack>

      <MetricsDrawer getDrawerProps={getDrawerProps} onChange={handleSubmit} />
    </>
  );
}
