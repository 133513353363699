import React, { useState, useEffect } from 'react';
import { Grid, Button, Modal, Stack } from 'src/components/matchbox';
import { CopyField } from 'src/components';

export default function BackupCodeAuth0Modal(props) {
  const { open, codes, onClose } = props;
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);

  const handleClose = () => {
    if (copied) onClose();
    else {
      setError('Copy the new recovery code before proceeding.');
    }
  };

  useEffect(() => {
    if (copied) {
      setError('');
    }
  }, [copied]);

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>Recovery Code</Modal.Header>
      <Modal.Content>
        <Stack>
          <p>
            Copy the new recovery code and keep it somewhere safe. It will be needed in case the
            authenticator app is inaccessible.
          </p>
          <Grid>
            <Grid.Column xs={12} md={9}>
              <CopyField
                id="recovery-code"
                label="New Recovery Code"
                value={codes[0]}
                onCopy={(state) => setCopied(state)}
                error={error}
              />
            </Grid.Column>
          </Grid>
        </Stack>
      </Modal.Content>

      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
