import React, { forwardRef } from 'react';
import { Button as MatchboxButton } from '@sparkpost/matchbox';
import { ForwardRefComponent } from '@sparkpost/matchbox/dist/helpers/types';

// TYPES
import { ButtonMatchboxVariant, ButtonProps, ButtonWrapperVariant } from './Button.types';

type GetVariantPropsResult = {
  color?: ButtonProps['color'];
  flat?: boolean;
  outline?: boolean;
  outlineBorder?: boolean;
  variant?: ButtonMatchboxVariant;
};

/**
 * Matchbox Button component
 * @see https://design.sparkpost.com/components/button/
 */
const Button = forwardRef(({ useMatchboxVariant, variant, ...props }, ref) => {
  const variantProps = useMatchboxVariant ? { variant } : getVariantProps(variant);
  // This is because we have our implementation of variant as well as the other variant, but if we want to pass through straight to matchbox
  const variantTrueProps = variantProps as GetVariantPropsResult;

  return <MatchboxButton {...variantTrueProps} {...props} ref={ref} />;
}) as ForwardRefComponent<'button', ButtonProps> & {
  Group: typeof MatchboxButton.Group;
  Icon: typeof MatchboxButton.Icon;
};

/**
 * @param {string} variant - Returns Matchbox style props for each variant of `Button`
 * @returns {object} Matchbox `Button` style props
 */
function getVariantProps(
  variant: ButtonWrapperVariant | ButtonMatchboxVariant
): GetVariantPropsResult {
  switch (variant) {
    case 'primary':
      return {
        color: 'blue',
        variant: 'filled'
      };
    case 'secondary':
      return {
        color: 'blue',
        variant: 'outline'
      };
    case 'tertiary':
      return {
        color: 'blue',
        variant: 'text'
      };
    case 'minimal':
      return {
        color: 'gray',
        flat: true
      };
    case 'monochrome':
      return {
        color: 'gray'
      };
    case 'monochrome-secondary':
      return {
        color: 'gray',
        outlineBorder: true
      };
    case 'destructive':
      return {
        color: 'red',
        variant: 'filled'
      };
    case 'outline-destructive':
      return {
        color: 'red',
        variant: 'outline'
      };
    // use when connecting a button to a TextField
    case 'connected':
      return {
        color: 'blue',
        outline: true
      };
    default:
      return {
        color: 'blue',
        flat: true
      };
  }
}

Button.displayName = 'Button';
Button.Group = MatchboxButton.Group;
Button.Icon = MatchboxButton.Icon;

export default Button;
