import React, { createContext, useCallback, useContext, useReducer } from 'react';
import {
  TAB_CONDITION_CONFIGURATION,
  TAB_DETAILS_AND_NOTIFICATIONS,
  TAB_METRIC_AND_FILTERING,
  METRICS_BREAKDOWN_TYPES
} from '../constants';

import { TAB_TYPE_SELECTION } from '../TypeSelection';

const AlertFormContext = createContext();

const initialState = {
  type: 'metrics',
  accountData: {
    assignment: 'primary',
    subaccount: {},
    errors: {}
  },
  conditionData: {
    condition: undefined,
    alertThreshold: '',
    precision: 'hour',
    errors: {}
  },
  metricData: {
    selectedMetric: {},
    errors: {}
  },
  alertData: {
    name: '',
    severity: undefined,
    status: 'active',
    errors: {}
  },
  measurementData: {
    measurementType: METRICS_BREAKDOWN_TYPES.byValue
  },
  notificationData: {
    isEmailChecked: false,
    emails: [],
    isSlackChecked: false,
    slack: '',
    isWebhookChecked: false,
    webhook: '',
    time: {
      range: 'anytime',
      days: [],
      startTime: '',
      startPeriod: 'AM',
      endTime: '',
      endPeriod: 'AM',
      timeZone: '',
      errors: {}
    },
    errors: {}
  }
};

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_TYPE':
      return { ...state, type: action.data };
    case 'UPDATE_ACCOUNT_DATA':
      return {
        ...state,
        accountData: {
          ...state.accountData,
          errors: {
            subaccount: state.conditionData.errors.subaccount && !Boolean(action.data.subaccount)
          },
          ...action.data
        }
      };
    case 'UPDATE_MEASUREMENT_DATA':
      return {
        ...state,
        measurementData: { ...state.measurementData, ...action.data }
      };
    case 'UPDATE_CONDITION_DATA':
      return {
        ...state,
        conditionData: {
          ...state.conditionData,
          errors: {
            condition: state.conditionData.errors.condition && !Boolean(action.data.condition),
            alertThreshold:
              state.conditionData.errors.alertThreshold && !Boolean(action.data.alertThreshold)
          },
          ...action.data
        }
      };
    case 'UPDATE_METRIC_DATA':
      return { ...state, metricData: { ...state.metricData, errors: {}, ...action.data } };
    case 'UPDATE_ALERT_DATA':
      return {
        ...state,
        alertData: {
          ...state.alertData,
          errors: {
            name: state.alertData.errors.name && !Boolean(action.data.name),
            severity: state.alertData.errors.severity && !Boolean(action.data.severity)
          },
          ...action.data
        }
      };
    case 'UPDATE_NOTIFICATION_DATA':
      return {
        ...state,
        notificationData: {
          ...state.notificationData,
          errors: {
            emails:
              state.notificationData.errors.emails &&
              state.notificationData.isEmailChecked &&
              Boolean(!action.data.emails || action.data.emails.length === 0),
            slack:
              state.notificationData.errors.slack &&
              state.notificationData.isSlackChecked &&
              !Boolean(action.data.slack),
            webhook:
              state.notificationData.errors.webhook &&
              state.notificationData.isWebhookChecked &&
              !Boolean(action.data.webhook)
          },
          ...action.data
        }
      };
    case 'UPDATE_NOTIFICATION_TIME':
      return {
        ...state,
        notificationData: {
          ...state.notificationData,
          time: {
            ...state.notificationData.time,
            ...action.data,
            errors: {
              days:
                state.notificationData.time.errors.days &&
                Boolean(!action.data.days || action.data.days.length === 0),
              startTime:
                state.notificationData.time.errors.startTime && !Boolean(action.data.startTime),
              endTime: state.notificationData.time.errors.endTime && !Boolean(action.data.endTime),
              timeZone:
                state.notificationData.time.errors.timeZone && !Boolean(action.data.timeZone)
            },
            ...action.data
          }
        }
      };
    case 'CLEAN_UP':
      return initialState;
    default:
      throw new Error();
  }
}

export function AlertFormContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const validatePageFields = useCallback(
    (page) => {
      let hasErrors = false;

      switch (page) {
        case TAB_TYPE_SELECTION:
          if (state.accountData.assignment === 'subaccount' && !state.accountData.subaccount.id) {
            dispatch({ type: 'UPDATE_ACCOUNT_DATA', data: { errors: { subaccount: true } } });
            return true;
          }
          break;

        case TAB_METRIC_AND_FILTERING:
          if (!state.metricData.selectedMetric.key) {
            dispatch({ type: 'UPDATE_METRIC_DATA', data: { errors: { selectedMetric: true } } });
            return true;
          }
          break;

        case TAB_CONDITION_CONFIGURATION:
          if (!state.conditionData.condition || !state.conditionData.alertThreshold) {
            dispatch({
              type: 'UPDATE_CONDITION_DATA',
              data: {
                errors: {
                  condition: !Boolean(state.conditionData.condition),
                  alertThreshold: !Boolean(state.conditionData.alertThreshold)
                }
              }
            });
            hasErrors = true;
          }
          break;

        case TAB_DETAILS_AND_NOTIFICATIONS:
          if (!state.alertData.name || !state.alertData.severity) {
            dispatch({
              type: 'UPDATE_ALERT_DATA',
              data: {
                errors: {
                  name: !Boolean(state.alertData.name),
                  severity: Boolean(state.alertData.severity === undefined)
                }
              }
            });
            hasErrors = true;
          }
          if (
            !state.notificationData.isEmailChecked &&
            !state.notificationData.isSlackChecked &&
            !state.notificationData.isWebhookChecked
          ) {
            dispatch({
              type: 'UPDATE_NOTIFICATION_DATA',
              data: {
                errors: {
                  targets: true
                }
              }
            });
            hasErrors = true;
          }
          if (
            (state.notificationData.isEmailChecked && state.notificationData.emails.length === 0) ||
            (state.notificationData.isSlackChecked && !state.notificationData.slack) ||
            (state.notificationData.isWebhookChecked && !state.notificationData.webhook)
          ) {
            dispatch({
              type: 'UPDATE_NOTIFICATION_DATA',
              data: {
                errors: {
                  emails: Boolean(state.notificationData.emails.length === 0),
                  slack: !Boolean(state.notificationData.slack),
                  webhook: !Boolean(state.notificationData.webhook)
                }
              }
            });
            hasErrors = true;
          }
          if (state.notificationData.time.range === 'defined') {
            if (
              state.notificationData.time.days.length === 0 ||
              !state.notificationData.time.startTime ||
              !state.notificationData.time.endTime ||
              !state.notificationData.time.timeZone
            ) {
              dispatch({
                type: 'UPDATE_NOTIFICATION_TIME',
                data: {
                  errors: {
                    days: !Boolean(state.notificationData.time.days.length === 0),
                    startTime: !Boolean(state.notificationData.time.startTime),
                    endTime: !Boolean(state.notificationData.time.endTime),
                    timeZone: !Boolean(state.notificationData.time.timeZone)
                  }
                }
              });
              hasErrors = true;
            }
          }
          break;

        default:
          break;
      }

      return hasErrors;
    },
    [state]
  );

  return (
    <AlertFormContext.Provider
      value={{
        state,
        dispatch,
        validatePageFields
      }}
    >
      {children}
    </AlertFormContext.Provider>
  );
}

export const useAlertForm = () => useContext(AlertFormContext);
