import React from 'react';

import { Empty, SubaccountShrinkWrapper } from 'src/components';
import { PageLink } from 'src/components/links';
import { LabelValueLoadingSkeleton } from 'src/components/loading';
import { Column, Columns, LabelValue, Panel } from 'src/components/matchbox';
import { TranslatableText } from 'src/components/text';
import { getSubaccount } from 'src/helpers/api/subaccounts';
import { useSparkPostQuery } from 'src/hooks';
import DataSources from './DataSources';

export default function Subaccount({
  hasD12yProduct,
  id,
  deliverabilityDataStatus,
  deliverabilityData
}) {
  const subaccountIdNumber = parseInt(id);
  const { status: subaccountStatus, data: subaccount } = useSparkPostQuery(
    () => getSubaccount({ id }),
    {
      enabled: subaccountIdNumber !== 0
    }
  );

  const labelValues = [
    { value: 'Subaccount', width: 1 / 6 },
    { value: 'Sent', width: 1 / 6 },
    hasD12yProduct && { width: 1 / 6, value: 'Seed Count' },
    hasD12yProduct && { width: 1 / 6, value: 'Panel Count' }
  ];

  const subaccountId = subaccountIdNumber;
  const subaccountName = subaccount?.name;
  const subaccountIpPool = subaccount?.ip_pool;

  const subaccountMetricData =
    deliverabilityData &&
    deliverabilityData.find(
      (metricData) => parseInt(metricData.subaccount_id) === subaccountIdNumber
    );

  const countSent = subaccountMetricData && subaccountMetricData.count_sent;

  const ERROR = subaccountStatus === 'error' || deliverabilityDataStatus === 'error';
  const hasNoData = !ERROR && !subaccountName && !countSent && !subaccountIpPool;
  const LOADING = subaccountStatus === 'loading' || deliverabilityDataStatus === 'loading';
  const SUCCESS =
    (subaccountStatus === 'success' && deliverabilityDataStatus === 'success') ||
    (subaccountStatus === 'idle' && deliverabilityDataStatus === 'success');

  return (
    <>
      {LOADING && (
        <Panel.Section>
          <LabelValueLoadingSkeleton labelValues={labelValues} />
        </Panel.Section>
      )}
      {!LOADING && hasNoData && <Empty message="Insufficient data to populate this panel."></Empty>}
      {!LOADING && ERROR && <Empty message="Failed to load the data. Refresh page."></Empty>}
      {!LOADING && SUCCESS && (
        <>
          <Panel.Section>
            <Columns>
              <Column>
                <LabelValue>
                  <LabelValue.Label>Subaccount</LabelValue.Label>
                  <LabelValue.Value>
                    <SubaccountShrinkWrapper
                      master={subaccountId === 0}
                      receiveAll={false}
                      sharedWithSubaccounts={false}
                      subaccountId={subaccountId}
                      subaccountName={subaccountName}
                    ></SubaccountShrinkWrapper>
                  </LabelValue.Value>
                </LabelValue>
              </Column>
            </Columns>
          </Panel.Section>
          <Panel.Section>
            <Columns>
              {subaccountIpPool && (
                <Column width={1 / 6}>
                  <LabelValue>
                    <LabelValue.Label>IP Pool Assignment</LabelValue.Label>
                    <LabelValue.Value>
                      <PageLink to={`/account/ip-pools/edit/${subaccountIpPool}`}>
                        <TranslatableText>{subaccountIpPool}</TranslatableText>
                      </PageLink>
                    </LabelValue.Value>
                  </LabelValue>
                </Column>
              )}
              <DataSources
                dataDisplayOnly={true}
                hasD12yProduct={hasD12yProduct}
                deliverabilityData={deliverabilityData}
                deliverabilityDataStatus={deliverabilityDataStatus}
              />
            </Columns>
          </Panel.Section>
        </>
      )}
    </>
  );
}
