export const RUDDERSTACK_EVENTS = {
  ACCOUNT_CANCELLED: 'Account Cancelled',
  ACCOUNT_CREATED: 'Account Created',
  ACCOUNT_DOWNGRADED: 'Account Downgraded',
  ACCOUNT_UPGRADED: 'Account Upgraded',
  ALERT_CREATED: 'Alert Created',
  API_KEY_CREATED: 'API Key Created',
  BLOCKLIST_INCIDENT_ACTION_CLICKED: 'Blocklist Incident Action Button Clicked',
  BLOCKLIST_INCIDENT_INFO_CLICKED: 'Blocklist Incident Info Button Clicked',
  DASHBOARD_ONBOARDING: 'Dashboard Onboarding Loaded',
  DRAWER_CLOSED: 'Drawer Closed',
  DRAWER_OPENED: 'Drawer Opened',
  EMPTY_STATE_LOADED: 'Empty state loaded',
  FORM_SUBMITTED: 'Form Submitted',
  HIBANA_TOGGLED_OFF: 'Hibana Toggled Off',
  HIBANA_TOGGLED_ON: 'Hibana Toggled On',
  INVITE_SENT: 'Invite Sent',
  MODAL_CLOSED: 'Modal Closed',
  MODAL_OPENED: 'Modal Opened',
  REPORT_BUILDER_COMPARISON_ADDED: 'Analytics Report Comparison Added',
  REPORT_BUILDER_VIEWED: 'Report Builder Viewed',
  SCHEDULED_REPORT_CREATED: 'Created Scheduled Report',
  SENDING_DOMAIN_ADDED: 'Sending Domain Added',
  SENDING_DOMAIN_VERIFIED: 'Sending Domain Verified'
};

const UX_EVENTS = [RUDDERSTACK_EVENTS.HIBANA_TOGGLED_ON, RUDDERSTACK_EVENTS.HIBANA_TOGGLED_OFF];

export const RUDDERSTACK_TRAITS = {
  COMPANY: 'company',
  CREATED_AT: 'createdAt',
  CUSTOMER_ID: 'customer_id',
  EMAIL: 'email',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  PLAN: 'plan',
  SERVICE_LEVEL: 'service_level',
  TENANT: 'tenant',
  USER_ID: 'user_id',
  USER_ROLE: 'user_role',
  SSO_ENABLED: 'sso_enabled',
  TFA_ENABLED: 'tfa_enabled'
};

/**
 * Helper to identify and group a user, partial updates are supported
 * @param {} traits must include RUDDERSTACK_TRAITS.USER_ID, RUDDERSTACK_TRAITS.CUSTOMER_ID, and RUDDERSTACK_TRAITS.EMAIL
 */
export const rudderstackIdentify = (traits: {}): void => {
  if (
    window.rudderanalytics &&
    window.rudderanalytics.identify &&
    window.rudderanalytics.group &&
    traits[RUDDERSTACK_TRAITS.CUSTOMER_ID] &&
    traits[RUDDERSTACK_TRAITS.USER_ID] &&
    traits[RUDDERSTACK_TRAITS.EMAIL] &&
    traits[RUDDERSTACK_TRAITS.TENANT]
  ) {
    const filteredTraits = Object.values(RUDDERSTACK_TRAITS).reduce((filtered, key) => {
      if (traits[key] !== undefined) {
        filtered[key] = traits[key];
      }
      return filtered;
    }, {});

    window.rudderanalytics.identify(
      `${filteredTraits[RUDDERSTACK_TRAITS.EMAIL]}//${traits[RUDDERSTACK_TRAITS.CUSTOMER_ID]}`,
      filteredTraits
    );
    const groupIdentifier = `${filteredTraits[RUDDERSTACK_TRAITS.TENANT]}//${
      traits[RUDDERSTACK_TRAITS.CUSTOMER_ID]
    }`;

    // Mixpanel ignores the first argument for grouping, so we need to pass it twice
    window.rudderanalytics.group(groupIdentifier, {
      groupId: groupIdentifier
    });
  }
};

const getUTMsFromURL = (): {} => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const utms = {
    utm_campaign: urlParams.get('utm_campaign'),
    utm_medium: urlParams.get('utm_medium'),
    utm_source: urlParams.get('utm_source'),
    utm_content: urlParams.get('utm_content'),
    utm_term: urlParams.get('utm_term')
  };

  return Object.keys(utms).reduce((clean, key) => {
    if (utms[key] !== null) {
      clean[key] = utms[key];
    }
    return clean;
  }, {});
};

export const rudderstackPage = (url: string, { genericPath }: { genericPath: string }): void => {
  if (window.rudderanalytics && window.rudderanalytics.page) {
    const UTMs = getUTMsFromURL();
    window.rudderanalytics.page(url, {
      ...UTMs,
      genericPath: genericPath
    });
  }
};

export const rudderstackTrack = (eventType: string, traits = {}): void => {
  if (window.rudderanalytics && window.rudderanalytics.track) {
    if (UX_EVENTS.includes(eventType)) {
      window.rudderanalytics.track(eventType, { ...traits, ux: true });
    } else {
      window.rudderanalytics.track(eventType, traits);
    }
  }
};
