import { Dispatch } from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'src/typescript/interfaces/Auth';
import { CurrentUser } from 'src/typescript/interfaces/CurrentUser';

export function usePrepareQuery(): {
  auth: Auth;
  currentUser: CurrentUser;
  dispatch: Dispatch<any>;
  queryClient: QueryClient;
} {
  const queryClient = useQueryClient();
  const auth = useSelector((state: { auth: Auth }) => state.auth);
  const currentUser = useSelector((state: { currentUser: CurrentUser }) => state.currentUser);
  const dispatch = useDispatch();

  return {
    queryClient,
    currentUser,
    auth,
    dispatch
  };
}
