export function getDraft(id, params = {}) {
  return {
    method: 'GET',
    url: `/v1/templates/${id}`,
    params
  };
}

export function getPublished(id, params = { draft: false }) {
  return {
    method: 'GET',
    url: `/v1/templates/${id}`,
    params
  };
}

export function getTemplates(params) {
  return {
    method: 'GET',
    url: '/v1/templates',
    params
  };
}
