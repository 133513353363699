import React from 'react';
import cx from 'classnames';
import { ActionList } from 'src/components/matchbox';
import styles from './FromEmail.module.scss';
import { TranslatableText } from 'src/components/text';

const FromEmailMenu = ({ downshift: { getItemProps, highlightedIndex, isOpen }, items = [] }) => {
  const actions = items.map((item, index) =>
    getItemProps({
      children: item,
      highlighted: highlightedIndex === index,
      index,
      item
    })
  );
  const listClasses = cx(styles.List, isOpen && items.length && styles.open);

  return (
    <ActionList className={listClasses} maxHeight={300}>
      {actions.map(({ children, ...action }, i) => {
        return (
          <ActionList.Action key={`action-item-${i}`} {...action}>
            <TranslatableText>{children}</TranslatableText>
          </ActionList.Action>
        );
      })}
    </ActionList>
  );
};

export default FromEmailMenu;
