import { Modal as MatchboxModal, ModalProps } from '@sparkpost/matchbox';
import React, { ReactNode } from 'react';
import { TrackModal } from 'src/components/tracking/TrackModal';

type LegacyProps = {
  open: boolean;
  title: string;
};

type Child = {
  name?: string;
  props: {
    children: React.ReactNode;
  };
  type: {
    displayName: string;
    name: string;
  };
};

type ChildrenChild = {
  name?: string;
  props: {
    children: React.ReactNode;
  };
  type: string | { name: string };
};

/**
 * Matchbox Modal component. NOTE - title prop is required for RudderStack tracking only
 * @see https://design.sparkpost.com/components/modal/
 */
const Modal = (props: ModalProps): JSX.Element => {
  let modalTitle: string | number | boolean | {} | Iterable<ReactNode> | null | undefined = '';

  // eslint-disable-next-line prettier/prettier
  React.Children.map(props.children, function (child: Child) {
    if (child?.type && child.type.displayName === 'Modal.Header') {
      if (React.isValidElement(child.props.children)) {
        // eslint-disable-next-line prettier/prettier
        React.Children.map(child.props.children, function (c: ChildrenChild) {
          if (c?.type && typeof c?.type !== 'string' && c.type.name === 'ScreenReaderOnly')
            modalTitle = c.props.children;
        });
      } else {
        modalTitle = child.props.children;
      }
    }
  });

  if (!modalTitle && props.open) {
    // eslint-disable-next-line
    console.warn(
      'This modal will not be tracked with a correct name. Please use `Modal.Header` along with `ScreenReaderOnly` to avoid this problem'
    );
  }

  return (
    <TrackModal open={props.open} title={modalTitle}>
      <MatchboxModal {...props} />
    </TrackModal>
  );
};

const LEGACY = (props: LegacyProps): JSX.Element => {
  const { title, ...restProps } = props;

  return (
    <TrackModal open={props.open} title={title}>
      <MatchboxModal.LEGACY {...restProps} />
    </TrackModal>
  );
};

const Header = MatchboxModal.Header;
const Content = MatchboxModal.Content;
const Footer = MatchboxModal.Footer;

Modal.displayName = 'Modal';
LEGACY.displayName = 'Modal.LEGACY';
Header.displayName = 'Modal.Header';
Content.displayName = 'Modal.Content';
Footer.displayName = 'Modal.Footer';

Modal.LEGACY = LEGACY;
Modal.Header = Header;
Modal.Content = Content;
Modal.Footer = Footer;

export default Modal;
