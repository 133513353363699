import {
  formatNumber,
  formatBytes,
  formatMilliseconds,
  formatYAxisPercent
} from '@sparkpost/report-builder/helpers/units';

export default {
  number: {
    label: 'Count',
    yAxisFormatter: formatNumber
  },
  percent: {
    label: 'Percent',
    yAxisFormatter: formatYAxisPercent
  },
  milliseconds: {
    label: 'Time',
    yAxisFormatter: formatMilliseconds
  },
  bytes: {
    label: 'Size, in Bytes',
    yAxisFormatter: formatBytes
  }
};
