import cookie from 'js-cookie';
import _ from 'lodash';
import getConfig from 'src/helpers/getConfig';

export const extractQueryParams = (params = {}) => {
  const { video, ...restParams } = params;
  const existingCookie = cookie.getJSON(getConfig('attribution.cookieName')) || {};

  const allData = { ...existingCookie, ...restParams };

  return {
    sfdcid: allData.sfdcid,
    attributionData: _.pick(allData, getConfig('salesforceDataParams')),
    creationParams: allData,
    video
  };
};
