import React from 'react';
import { ComboBox as MatchboxComboBox, ComboBoxProps } from '@sparkpost/matchbox';

/**
 * Matchbox Combobox component
 * @see https://design.sparkpost.com/components/combobox/
 */
function ComboBox(props: ComboBoxProps): JSX.Element {
  return <MatchboxComboBox {...props} />;
}

ComboBox.displayName = 'ComboBox';

export default ComboBox;
