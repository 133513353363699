import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserUIOptions } from 'src/actions/currentUser';
import { deleteReport, getReports } from 'src/actions/reports';
import { ConfirmationModal, DeleteModal } from 'src/components';
import { Bold, TranslatableText } from 'src/components/text';
import { isUserUiOptionSet } from 'src/helpers/conditions/user';
import { useAlert } from 'src/hooks';
import ReportsListModal from './ReportsListModal';
import SaveReportModal from './SaveReportModal';
import ScheduledReportsModal from './ScheduledReportsModal';
import { useAnalyticsReportContext } from '../context/AnalyticsReportContext';
import { deleteRecentReport } from 'src/helpers/recentSavedReports';
import useAnalyticsReportModal from '../hooks/useAnalyticsReportModal';
import _ from 'lodash';

export function AnalyticsReportsModals(props) {
  const {
    actions,
    modalActions,
    modalState,
    state: { selectedReport }
  } = useAnalyticsReportContext();
  const { refreshReportOptions, setReport } = actions;
  const { showAlert } = useAlert();
  const { closeModal } = modalActions;
  const { isModalOpen, meta: { type, focusedReport = {}, previouslyPinnedReport = {} } = {} } =
    modalState;
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.currentUser);
  const reports = useSelector((state) => state.reports.list);
  const isDeletePending = useSelector((state) => state.reports.deletePending);
  const userOptionsPending = useSelector((state) => state.currentUser.userOptionsPending);
  const favoriteReportsIds = useSelector((state) =>
    isUserUiOptionSet('favorite_reports', [])(state)
  );

  const { openDeleteModal, openPinToDashboardModal, openEditReportModal } =
    useAnalyticsReportModal();

  const { handleReportChange } = props;

  const onPinConfirm = () => {
    dispatch(updateUserUIOptions({ pinned_report_id: focusedReport.id })).then(() => {
      closeModal();
      showAlert({
        type: 'success',
        message: `Successfully pinned ${focusedReport.name} to the Dashboard.`
      });
    });
  };

  const onDelete = () => {
    dispatch(deleteReport(focusedReport.id)).then(() => {
      closeModal();
      deleteRecentReport(focusedReport.id, currentUser.username);
      showAlert({
        type: 'success',
        message: `Successfully deleted ${focusedReport.name}.`
      });
      // Unsets the report if it's the report that's deleted.
      if (selectedReport && focusedReport.id === selectedReport?.id) {
        refreshReportOptions({
          metrics: undefined,
          filters: [],
          relativeRange: undefined,
          precision: undefined,
          timezone: undefined
        });
        handleReportChange(null);
      }
      dispatch(getReports());
    });
  };

  const handleAddtoFavorites = ({ reportId, reportName, addToFavorites }) => {
    if (addToFavorites) {
      dispatch(
        updateUserUIOptions({
          favorite_reports: _.uniqBy([...favoriteReportsIds, reportId])
        })
      ).then(() => {
        showAlert({
          type: 'success',
          message: `You have successfully added ${reportName} to Favorite Reports`
        });
      });
    } else {
      dispatch(
        updateUserUIOptions({
          favorite_reports: _.uniqBy(favoriteReportsIds.filter((id) => id !== reportId))
        })
      ).then(() => {
        showAlert({
          type: 'success',
          message: `You have successfully removed ${reportName} from Favorite Reports`
        });
      });
    }
  };

  return (
    <>
      {isModalOpen && type === 'edit' && (
        <SaveReportModal
          open={isModalOpen && type === 'edit'}
          report={focusedReport}
          setReport={handleReportChange}
          onCancel={closeModal}
        />
      )}

      {isModalOpen && type === 'save' && (
        <SaveReportModal
          open={isModalOpen && type === 'save'}
          saveQuery
          isOwner={currentUser.userName === focusedReport.creator}
          report={focusedReport}
          setReport={handleReportChange}
          onCancel={closeModal}
        />
      )}

      {selectedReport?.id && (
        <ScheduledReportsModal
          open={isModalOpen && type === 'scheduled'}
          onClose={closeModal}
          handleReportChange={handleReportChange}
          report={focusedReport}
        />
      )}

      <ReportsListModal
        open={isModalOpen && type === 'view'}
        onClose={closeModal}
        handleDelete={openDeleteModal}
        handlePin={openPinToDashboardModal}
        handleEdit={openEditReportModal}
        handleReportChange={handleReportChange}
        handleAddtoFavorites={handleAddtoFavorites}
        reports={reports}
        favoriteReportsIds={favoriteReportsIds}
      />
      <ConfirmationModal
        title="Pin to Dashboard"
        confirmVerb="Pin to Dashboard"
        content={
          <>
            {previouslyPinnedReport &&
              previouslyPinnedReport.id &&
              focusedReport.id !== previouslyPinnedReport.id && (
                <p>
                  <TranslatableText>
                    <Bold>{focusedReport.name}</Bold>
                  </TranslatableText>
                  <TranslatableText>&nbsp;will now replace&nbsp;</TranslatableText>
                  <TranslatableText>
                    <Bold>{previouslyPinnedReport.name}</Bold>
                  </TranslatableText>
                  <TranslatableText>&nbsp;on the Dashboard.</TranslatableText>
                </p>
              )}
            {!previouslyPinnedReport.id && (
              <p>
                <TranslatableText>
                  <Bold>{focusedReport.name}</Bold>
                </TranslatableText>
                <TranslatableText>&nbsp;will be pinned to the Dashboard.&nbsp;</TranslatableText>
              </p>
            )}
          </>
        }
        open={isModalOpen && type === 'confirm-pin'}
        isPending={userOptionsPending}
        onCancel={closeModal}
        onConfirm={onPinConfirm}
      />
      <DeleteModal
        title="Are you sure you want to delete your saved report?"
        confirmVerb="Delete"
        content={
          <p>
            <TranslatableText>The report&nbsp;</TranslatableText>
            <TranslatableText>
              <Bold>"{focusedReport.name}"</Bold>
            </TranslatableText>
            <TranslatableText>
              &nbsp;will be permanently removed. This cannot be undone.
            </TranslatableText>
          </p>
        }
        open={isModalOpen && type === 'delete'}
        isPending={isDeletePending}
        onCancel={closeModal}
        onConfirm={onDelete}
      />

      {isModalOpen && type === 'create' && (
        <SaveReportModal
          create
          open={isModalOpen && type === 'create'}
          setReport={setReport}
          onCancel={closeModal}
        />
      )}
    </>
  );
}
