import { ErrorOutline, Schedule } from '@sparkpost/matchbox-icons';
import React from 'react';
import { Tag, Tooltip } from 'src/components/matchbox';

type DomainStatusTagProps = {
  className?: string;
  status: string;
};

const DomainStatusTag = ({ status, className }: DomainStatusTagProps) => {
  const size = 15;

  if (status === 'verified') {
    return <Tag>Verified</Tag>;
  }

  if (status === 'unverified') {
    return (
      <Tooltip content="This domain must be verified before use." dark id="unverified">
        <Tag className={className} color="yellow">
          <ErrorOutline size={size} />
          <span> Unverified</span>
        </Tag>
      </Tooltip>
    );
  }

  if (status === 'blocked') {
    return (
      <Tooltip
        content="This domain is not available for use. For more information, please contact support."
        dark
        id="blocked"
      >
        <Tag className={className} color="red">
          <ErrorOutline size={size} />
          <span> Blocked</span>
        </Tag>
      </Tooltip>
    );
  }

  if (status === 'pending') {
    return (
      <Tooltip
        content="This domain is pending review, please check back again soon."
        dark
        id="pending"
      >
        <Tag className={className}>
          <Schedule size={size} />
          <span> Pending</span>
        </Tag>
      </Tooltip>
    );
  }

  return null;
};

export default DomainStatusTag;
