import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { formatDistanceStrict } from 'date-fns';

// ACTIONS
import { getBatches as getBatchesAction } from 'src/actions/webhooks';

// SELECTORS
import { selectWebhookBatches } from 'src/selectors/webhooks';

// COMPONENTS
import { BatchTabContent } from './BatchTabContent';

const A_MINUTE = 1000 * 60;

export function BatchTab({ webhook }) {
  const [lastUpdate, setLastUpdate] = useState();
  const [now, setNow] = useState(new Date());
  const intervalRef = useRef();

  const { batches, batchesLoading } = useSelector((state) => ({
    batches: selectWebhookBatches(state),
    batchesLoading: state.webhooks.batchesLoading
  }));

  const dispatch = useDispatch();

  const getBatches = useCallback(
    ({ id, subaccount = null }) => dispatch(getBatchesAction({ id, subaccount })),
    [dispatch]
  );

  const lastUpdateStr = useMemo(() => {
    return lastUpdate
      ? `${formatDistanceStrict(lastUpdate, now, {
          unit: 'minute'
        })} ago`
      : '';
  }, [lastUpdate, now]);

  const refreshBatches = useCallback(() => {
    const { id, subaccount } = webhook;
    setLastUpdate(new Date());
    setNow(new Date());

    return getBatches({ id, subaccount });
  }, [getBatches, webhook]);

  // initialize
  useEffect(() => {
    refreshBatches();

    intervalRef.current = setInterval(() => {
      setNow(new Date());
    }, A_MINUTE);

    return () => clearInterval(intervalRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BatchTabContent
      batches={batches}
      batchesLoading={batchesLoading}
      onRefreshBatches={refreshBatches}
      lastUpdateText={lastUpdateStr}
    />
  );
}
export default BatchTab;
