import _ from 'lodash';

export function setSubaccountQuery(id) {
  return _.isNil(id) ? '' : `?subaccount=${id}`;
}

export function setSubaccountHeader(subaccount = null) {
  const headers = {};

  if (subaccount !== null) {
    headers['x-msys-subaccount'] = _.get(subaccount, 'id', subaccount);
  }

  return headers;
}

/**
 * @description derive string from passed-in subaccount
 * @returns {string}
 */
export function subaccountToString(subaccount) {
  if (!subaccount) return '';

  if (_.isNil(subaccount.id)) {
    throw new Error('A valid "id" is required for any subaccount passed to "subaccountToString"');
  }

  if (_.isNil(subaccount.name)) {
    throw new Error('A valid "name" is required for any subaccount passed to "subaccountToString"');
  }

  if (subaccount.id === 0) return subaccount.name;

  return `${subaccount.name} (${subaccount.id})`;
}
