/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

// https://messagebird.atlassian.net/wiki/spaces/~641589493/pages/9747464193/Instrumentation
import { noop } from 'lodash';
import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { MbConsentCookieBar } from 'src/components/MbConsentCookieBar';

import helpers from 'src/helpers/cookieConsent';
import { useStore, withInstrumentation, withInstrumentationContext } from './index';
import { ITrack } from './types';

type Settings = {
  essential: boolean;
  functional: boolean;
  marketing: boolean;
  performance: boolean;
  responded: boolean;
  social: boolean;
  wiped: boolean;
};

// Hook
function useLocalStorage(
  key: string,
  initialValue: Settings
): [Settings, (value: SetStateAction<Settings>) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<Settings>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return (item ? JSON.parse(item) : initialValue) as Settings;
    } catch (error) {
      // If error also return initialValue
      // eslint-disable-next-line no-console
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: SetStateAction<Settings>) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

export const ConsentManager = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const track = (props?.track || noop) as ITrack;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [settings, updateSettings] = useLocalStorage('consentDialog', {
    responded: false,
    essential: true,
    functional: true,
    marketing: true,
    performance: true,
    social: true,
    wiped: false
  });

  const [store, setStore] = useStore();

  useEffect(() => {
    if (!settings || (!settings.responded && !settings.wiped && isOpen === false)) {
      setIsOpen(true);
    }
  }, [settings, isOpen]);

  const handleOnAcceptClick = useCallback(() => {
    updateSettings({
      responded: true,
      wiped: false,
      essential: true,
      functional: true,
      marketing: true,
      performance: true,
      social: true
    });
    setIsOpen(false);
    setStore({
      enabled: true,
      loading: true
    });

    // set cookie for SP CookieBar too so we avoid show the notice again
    helpers.setCookie();
  }, [setStore, updateSettings]);

  const handleOnDeclinedClick = useCallback(() => {
    if (window.confirm('Are you sure you want to delete your data with us?') === true) {
      updateSettings({
        responded: false,
        wiped: true,
        essential: false,
        functional: false,
        marketing: false,
        performance: false,
        social: false
      });
      setIsOpen(false);
      track('forgetMe', {});
      setStore({ enabled: false, loading: false });
    }
  }, [setStore, track, updateSettings]);

  if (!isOpen) return null;

  return (
    <MbConsentCookieBar
      {...props}
      floating
      onAccept={handleOnAcceptClick}
      onDecline={handleOnDeclinedClick}
    />
  );
};

export default withInstrumentationContext(withInstrumentation(ConsentManager));
