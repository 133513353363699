import { any } from 'src/helpers/conditions';
import _ from 'lodash';
import { ROLES } from 'src/constants';

export const isSubaccountReportingUser = ({ currentUser }) =>
  currentUser.access_level === ROLES.SUBACCOUNT_REPORTING;

export const isSubaccountDeveloperUser = ({ currentUser }) =>
  currentUser.access_level === ROLES.SUBACCOUNT_DEVELOPER;

export const isSubaccountUser = ({ currentUser }) => {
  return isSubaccountReportingUser({ currentUser }) || isSubaccountDeveloperUser({ currentUser });
};

export const hasRole =
  (role) =>
  ({ currentUser }) =>
    currentUser.access_level === role;

export const isAdmin = any(hasRole('admin'), hasRole('superuser'));

export const isSso = ({ currentUser }) => currentUser.is_sso;

export const isEmailVerified = ({ currentUser }) => currentUser.email_verified;

export const isUserUiOptionSet =
  (option, defaultValue) =>
  ({ currentUser }) =>
    _.get(currentUser.options, `ui.${option}`, defaultValue);
