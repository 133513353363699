import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { defaultQueryFn } from 'src/helpers/api';
import { XhrRequestMeta } from 'src/typescript/interfaces/XhrRequestMeta';
import { deriveQueryKey } from './helpers/deriveQueryKey';
import { handleError } from './helpers/handleError';
import { usePrepareQuery } from './helpers/usePrepareQuery';

/**
 * Wrapper hook for react-query `useMutation` hook that handles consistent auth/error behavior
 * See: https://react-query.tanstack.com/reference/useMutation
 */
export function useSparkPostMutation(
  mutationFn: (vars?: unknown, options?: unknown) => XhrRequestMeta,
  options: {
    onError?: (arg0?: AxiosError) => $TODOFIXME;
    onSuccess?: (arg0?: unknown) => $TODOFIXME;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryFn?: (arg0?: unknown) => Promise<any>;
  }
) {
  const { method } = mutationFn();
  const { queryClient, currentUser, auth, dispatch } = usePrepareQuery();
  const { onError, queryFn = defaultQueryFn } = options;

  // Derive an async request based on the passed-in variables derived from the `mutationFn`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function derivedMutationFn(vars?: unknown, options?: unknown): Promise<any> {
    const derivedQueryKey = deriveQueryKey({
      queryFn: () => mutationFn(vars, options),
      auth,
      currentUser
    });

    return queryFn({ queryKey: derivedQueryKey });
  }

  // Return an instance of `useMutation` with the derived mutation function as well as some default configuration
  return useMutation(derivedMutationFn, {
    onError: (error: AxiosError) => {
      if (onError) onError(error);
      return void handleError({ currentUser, error, method, queryClient, auth, dispatch });
    },
    ...options
  });
}
