const _ = require('lodash');
const defaultConfig = require('./default');
const envConfig = require('./env');
const getConfigFromWindow = require('./getConfigFromWindow');

const { tenantConfig, hostname, hasWindow, auth0Configs } = getConfigFromWindow();
const nodeEnv = process.env.NODE_ENV;

function getDevTenantFromEnv(auth0Configs, stage) {
  if (nodeEnv === 'development') {
    switch (stage) {
      case 'tst':
        return auth0Configs.uat;
      case 'stg':
        return auth0Configs.staging;
      case 'prd':
        return auth0Configs.spc;
      default:
        return undefined;
    }
  }

  return undefined;
}

const mergedConfig = _.merge(
  {},
  defaultConfig(hostname),
  envConfig(nodeEnv, tenantConfig.environment),
  tenantConfig
);

mergedConfig['auth0'] =
  getDevTenantFromEnv(auth0Configs, mergedConfig?.STAGE) || auth0Configs[mergedConfig.tenantId];

if (hasWindow) {
  // Note: We need this for Cypress to run the app on localhost
  if (window?.Cypress?.cy?.id && window?.Cypress?.env().CY_IGNORE_AUTH) {
    mergedConfig['auth0'] = {
      domain: 'login.tst.sparkpost.com',
      clientId: 'mock_clientid',
      audience: 'mock_audience'
    };
  }

  window.SP = {
    productionConfig: mergedConfig
  };
}

module.exports = mergedConfig;
