import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showAlert } from 'src/actions/globalAlert';
import { getEventDocs, getWebhook, updateWebhook } from 'src/actions/webhooks';
import Loading from 'src/components/loading';
import { selectWebhookEventListing } from 'src/selectors/eventListing';
import resolveAuthUpdates from '../helpers/resolveAuthUpdates';
import WebhookForm from './WebhookForm';

export class EditTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCheckboxBanner: false
    };
  }

  componentDidMount() {
    this.props.getEventDocs();
    // always start with change password unchecked
    this.props.uncheckChangePasswordCheckbox();
  }

  /*
    Passed as onSubmit to WebhookForm. Figures out what updates need to be passed
    to the updateWebhook action.
  */
  update = async (values, webhook) => {
    const { getWebhook, updateWebhook, showAlert, eventListing, shouldChangePassword } = this.props;
    const { name, target, active, events = [] } = values;
    const { id, subaccount } = webhook;
    const eventKeys = eventListing.map(({ key }) => key);

    const noEventSelected = Object.entries(events || {}).every((event) => event[1] === false);

    let exceptionSubaccounts = Object.keys(values.exception_subaccounts || {})
      .filter((key) => values.exception_subaccounts[key])
      .map((subaccountId) => Number(subaccountId));

    if (values.assignTo !== 'all' || exceptionSubaccounts.length === 0) {
      exceptionSubaccounts = undefined;
    }

    if (noEventSelected) {
      return this.setState({ showCheckboxBanner: true });
    }

    this.setState({ showCheckboxBanner: false });

    updateWebhook({
      id,
      subaccount,
      ...resolveAuthUpdates(values, webhook, shouldChangePassword),
      name,
      target,
      active,
      events: eventKeys.filter((e) => events[e]),
      exception_subaccounts: exceptionSubaccounts
    }).then(() => {
      showAlert({
        type: 'success',
        message: (
          <>
            Webhook Details update successful!
            <br /> A test batch has been sent to Target URL.
          </>
        )
      });
      getWebhook({ id, subaccount });
    });
  };

  render() {
    const { webhook, eventsLoading, eventListing } = this.props;

    // to match form values, where none is undefined
    if (webhook.auth_type === 'none') {
      delete webhook.auth_type;
    }

    if (eventListing.length === 0 && eventsLoading) {
      return <Loading />;
    }

    return (
      <WebhookForm
        selectedEvents={webhook.events}
        onSubmit={(values) => this.update(values, webhook)}
        newWebhook={false}
        openDeleteModal={this.props.openDeleteModal}
        showCheckboxBanner={this.state.showCheckboxBanner}
        exceptionSubaccounts={webhook.exception_subaccounts}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  eventsLoading: state.webhooks.docsLoading,
  shouldChangePassword: state.webhooks.shouldChangePassword,
  eventListing: selectWebhookEventListing(state)
});

const uncheckChangePasswordCheckbox = () => ({
  type: 'SET_CHANGE_PASSWORD',
  payload: false
});

const EditTabPage = withRouter(
  connect(mapStateToProps, {
    getWebhook,
    getEventDocs,
    updateWebhook,
    showAlert,
    uncheckChangePasswordCheckbox
  })(EditTab)
);

export default EditTabPage;
