/* eslint-disable react/jsx-no-bind */
import { formatDateTime, relativeDateOptionsIndexed } from '@sparkpost/report-builder/helpers/date';
import { dehydrateFilters } from '@sparkpost/report-builder/helpers/metrics';
import _ from 'lodash';
import qs from 'qs';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { updateUserUIOptions } from 'src/actions/currentUser';
import { showAlert } from 'src/actions/globalAlert';
import { createReport, getReports, updateReport } from 'src/actions/reports';
import { ActiveFilters } from 'src/components/analyticsReport';
import {
  Box,
  Button,
  Checkbox,
  Inline,
  LabelValue,
  Modal,
  Stack,
  Tag,
  TextField
} from 'src/components/matchbox';
import { Heading } from 'src/components/text';
import { Form } from 'src/components/tracking';
import { isUserUiOptionSet } from 'src/helpers/conditions/user';
import { getMetricsFromKeys } from 'src/helpers/metrics';
import { useAnalyticsReportContext } from '../context/AnalyticsReportContext';
import ActiveComparisons from './ActiveComparisons';

const DateRange = ({ to, from, relativeRange }) => {
  if (relativeRange === 'custom') {
    return (
      <div>
        From {formatDateTime.useMomentInput(from)} to {formatDateTime.useMomentInput(to)}
      </div>
    );
  }

  return <div>{relativeDateOptionsIndexed[relativeRange]}</div>;
};

const ActiveMetrics = ({ metrics }) => {
  const processedMetrics = getMetricsFromKeys(metrics);
  return (
    <Box>
      <Inline>
        {processedMetrics.map((metric) => {
          return (
            <Tag key={metric.name} data-id="metric-tag">
              {metric.label}
            </Tag>
          );
        })}
      </Inline>
    </Box>
  );
};

export function SaveReportModal(props) {
  const {
    report,
    open,
    onCancel,
    createReport,
    getReports,
    updateUserUIOptions,
    loading,
    showAlert,
    isOwner,
    updateReport,
    favoriteReports,
    create,
    saveQuery,
    setReport
  } = props;
  const isReportFavorited = favoriteReports.includes(report?.id);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    register,
    control
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      is_editable: false,
      add_to_favorites: isReportFavorited
    }
  });

  const { state: reportOptions, selectors } = useAnalyticsReportContext();
  const { selectUiParams } = selectors;

  const hasFilters = Boolean(reportOptions.filters.length);
  const hasComparisons = Boolean(reportOptions.comparisons.length);
  const modalHeader = create
    ? 'Save New Report'
    : saveQuery
    ? 'Save Report Changes'
    : 'Edit Report';

  React.useEffect(() => {
    if (!report) return;
    const { name = '', description = '', is_editable = false } = report;
    reset({ name, description, is_editable });
  }, [report, reset]);

  const addOrRemoveFromFavorites = ({ reportId, reportName, addToFavorites }) => {
    if (addToFavorites) {
      updateUserUIOptions({
        favorite_reports: _.uniqBy([...favoriteReports, reportId])
      }).then(() => {
        showAlert({ type: 'success', message: `You have successfully saved ${reportName}` });
      });
    } else {
      updateUserUIOptions({
        favorite_reports: _.uniqBy(favoriteReports.filter((id) => id !== reportId))
      }).then(() => {
        showAlert({ type: 'success', message: `You have successfully saved ${reportName}` });
      });
    }
  };

  const onSubmit = (data) => {
    const { filters: _selectedFilters, ...update } = selectUiParams;
    const { filters } = reportOptions;
    if (Boolean(filters.length)) {
      update.query_filters = JSON.stringify(dehydrateFilters(filters));
    }

    const query_string = qs.stringify(update, { arrayFormat: 'indices' });
    if (saveQuery || create) {
      data.query_string = query_string;
    }

    const saveAction = create ? createReport : updateReport;
    return saveAction({ ...data, id: report?.id }).then((response) => {
      const reportId = report ? report?.id : response?.id;

      addOrRemoveFromFavorites({
        reportId: reportId,
        reportName: data.name,
        addToFavorites: data.add_to_favorites
      });

      onCancel();

      getReports().then((reports) => {
        setReport(reports.find(({ id }) => id === reportId));
      });
    });
  };

  const renderContent = () => {
    return (
      <Form onSubmit={handleSubmit(onSubmit)} id={modalHeader}>
        <Stack>
          <TextField
            label="Name"
            name="name"
            id="saved-reports-name"
            error={errors.name && 'Required'}
            data-track={true}
            {...register('name', { required: true })}
          />
          {saveQuery && (
            <Stack>
              <Box>
                <LabelValue>
                  <LabelValue.Label>Metrics</LabelValue.Label>
                  <LabelValue.Value>
                    <ActiveMetrics metrics={reportOptions.metrics} />
                  </LabelValue.Value>
                </LabelValue>
              </Box>

              {hasFilters ? (
                <Box>
                  <LabelValue>
                    <LabelValue.Label>Filters</LabelValue.Label>
                    <LabelValue.Value>
                      <ActiveFilters filters={reportOptions.filters} />
                    </LabelValue.Value>
                  </LabelValue>
                </Box>
              ) : null}

              {hasComparisons ? (
                <Box>
                  <LabelValue>
                    <LabelValue.Label>Comparisons</LabelValue.Label>
                    <LabelValue.Value>
                      <ActiveComparisons comparisons={reportOptions.comparisons} />
                    </LabelValue.Value>
                  </LabelValue>
                </Box>
              ) : null}

              <Box>
                <Heading as="h6">Date Range</Heading>

                <DateRange
                  to={reportOptions.to}
                  from={reportOptions.from}
                  relativeRange={reportOptions.relativeRange}
                />
              </Box>
            </Stack>
          )}
          <TextField
            multiline
            rows="5"
            label="Description"
            name="description"
            id="saved-reports-description"
            placeholder="Enter short description for your report"
            data-track={true}
            {...register('description')}
          />
          <Checkbox.Group>
            {(isOwner || create) && (
              <Checkbox
                label="Allow others to edit report"
                id="saved-reports-allow-others-to-edit"
                name="is_editable"
                setValue={setValue}
                data-track={true}
                {...register('is_editable')}
              />
            )}

            <Controller
              control={control}
              name="add_to_favorites"
              render={({ field: { onChange, value, ref } }) => (
                <>
                  <Checkbox
                    label="Add to Favorites"
                    inputRef={ref}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                  />
                </>
              )}
            />
          </Checkbox.Group>
        </Stack>
      </Form>
    );
  };

  return (
    <Modal open={open} onClose={onCancel}>
      <Modal.Header showCloseButton>{modalHeader}</Modal.Header>
      <Modal.Content>{open && renderContent()}</Modal.Content>

      <Modal.Footer>
        <Button
          type="submit"
          loading={loading}
          disabled={loading}
          form={modalHeader}
          variant="primary"
        >
          Save Report
        </Button>
        <Button onClick={onCancel} disabled={loading} variant="secondary">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
const mapStateToProps = (state) => ({
  loading: state.reports.saveStatus === 'loading',
  favoriteReports: isUserUiOptionSet('favorite_reports', [])(state)
});
const mapDispatchToProps = {
  createReport,
  getReports,
  updateReport,
  showAlert,
  updateUserUIOptions
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveReportModal);
