import React, { forwardRef } from 'react';
import { Spinner as MatchboxSpinner } from '@sparkpost/matchbox';

const Spinner: typeof MatchboxSpinner = forwardRef(
  ({ label = 'loading', ...rest }, ref): JSX.Element => (
    <MatchboxSpinner ref={ref} label={label} {...rest} />
  )
);

Spinner.displayName = 'Spinner';

export default Spinner;
