import React from 'react';
import { Empty, Unit } from 'src/components';
import { Box, Column, Columns, Label, LabelValue, Panel, Skeleton } from 'src/components/matchbox';
import { sumTwoNumbers } from 'src/helpers/number';
import useResourceDetailsContext from '../../hooks/useResourceDetailsContext';

export default function DataSources({
  dataDisplayOnly,
  hasD12yProduct,
  checkboxes,
  deliverabilityData,
  deliverabilityDataStatus
}) {
  let inboxSeedCount, spamSeedCount, sentCount, inboxPanelCount, spamPanelCount;
  if (deliverabilityData && deliverabilityData.length > 0) {
    inboxSeedCount = deliverabilityData[0]?.count_inbox_seed;
    spamSeedCount = deliverabilityData[0]?.count_spam_seed;
    sentCount = deliverabilityData[0]?.count_sent;
    inboxPanelCount = deliverabilityData[0]?.count_inbox_panel;
    spamPanelCount = deliverabilityData[0]?.count_spam_panel;
  }

  if (deliverabilityDataStatus === 'error')
    return (
      <div data-id="data-sources">
        <Empty message="Failed to load the data. Refresh page."></Empty>
      </div>
    );

  if (dataDisplayOnly) {
    return (
      <DataSourcesData
        hasD12yProduct={hasD12yProduct}
        deliverabilityDataStatus={deliverabilityDataStatus}
        sentCount={sentCount}
        spamSeedCount={spamSeedCount}
        inboxSeedCount={inboxSeedCount}
        spamPanelCount={spamPanelCount}
        inboxPanelCount={inboxPanelCount}
        checkboxes={checkboxes}
      />
    );
  }

  return (
    <Panel.Section>
      <Columns>
        <DataSourcesData
          hasD12yProduct={hasD12yProduct}
          deliverabilityDataStatus={deliverabilityDataStatus}
          sentCount={sentCount}
          spamSeedCount={spamSeedCount}
          inboxSeedCount={inboxSeedCount}
          spamPanelCount={spamPanelCount}
          inboxPanelCount={inboxPanelCount}
          checkboxes={checkboxes}
        />
      </Columns>
    </Panel.Section>
  );
}

const DataSourcesData = ({
  hasD12yProduct,
  deliverabilityDataStatus,
  sentCount,
  spamSeedCount,
  inboxSeedCount,
  spamPanelCount,
  inboxPanelCount
}) => {
  const hasSentCount = Boolean(sentCount);
  const { match: { params: { type } = {} } = {} } = useResourceDetailsContext();

  let showSent = type !== 'subject-campaign';

  const SENT_COUNT = sentCount;
  const SEED_COUNT = sumTwoNumbers(spamSeedCount, inboxSeedCount);
  const PANEL_COUNT = sumTwoNumbers(spamPanelCount, inboxPanelCount);

  if (deliverabilityDataStatus === 'loading') {
    return (
      <>
        {showSent && (
          <Column width={1 / 6}>
            <Box>
              <Label mb="0" fontWeight="semibold" looksLike="h4">
                Sent
              </Label>
              <Skeleton.Header />
            </Box>
          </Column>
        )}
        {hasD12yProduct && (
          <Column width={1 / 6}>
            <Box>
              <Label mb="0" fontWeight="semibold" looksLike="h4">
                Seed Count
              </Label>
              <Skeleton.Header />
            </Box>
          </Column>
        )}
        {hasD12yProduct && (
          <Column width={1 / 6}>
            <Box>
              <Label mb="0" fontWeight="semibold" looksLike="h4">
                Panel Count
              </Label>
              <Skeleton.Header />
            </Box>
          </Column>
        )}
      </>
    );
  }

  return (
    <>
      {hasSentCount && (
        <Column width={1 / 6}>
          <LabelValue>
            <LabelValue.Label>Sent</LabelValue.Label>
            <LabelValue.Value>
              <Unit value={SENT_COUNT} unit="number" />
            </LabelValue.Value>
          </LabelValue>
        </Column>
      )}
      {hasD12yProduct && (
        <Column width={1 / 6}>
          <LabelValue>
            <LabelValue.Label>Seed Count</LabelValue.Label>
            <LabelValue.Value>
              <Unit value={SEED_COUNT} unit="number" />
            </LabelValue.Value>
          </LabelValue>
        </Column>
      )}
      {hasD12yProduct && (
        <Column width={1 / 6}>
          <LabelValue>
            <LabelValue.Label>Panel Count</LabelValue.Label>
            <LabelValue.Value>
              <Unit value={PANEL_COUNT} unit="number" />
            </LabelValue.Value>
          </LabelValue>
        </Column>
      )}
    </>
  );
};
