import React from 'react';

function AlertIcon({ cx, cy, r }: { cx: number; cy: number; r: number }) {
  return (
    <path
      fill="#D9363E"
      r={r}
      transform={`translate(${cx ? cx - 6 : ''}, ${cy ? cy : ''})`}
      d="M7 .333A6.67 6.67 0 00.335 7a6.67 6.67 0 006.667 6.667A6.67 6.67 0 0013.667 7 6.67 6.67 0 007.001.333zm.667 10H6.334V9h1.333v1.333zm0-2.666H6.334v-4h1.333v4z"
    ></path>
  );
}

export default AlertIcon;
