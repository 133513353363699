import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ConfigurationWebp from '@sparkpost/matchbox-media/images/Configuration.webp';
import Configuration from '@sparkpost/matchbox-media/images/Configuration.jpg';
import { Banner, Picture } from 'src/components/matchbox';
import { ExternalLink } from 'src/components/links';
import { LINKS } from 'src/constants';
import { isUserUiOptionSet } from 'src/helpers/conditions/user';
import { updateUserUIOptions } from 'src/actions/currentUser';

// TODO: This component (and its accompanying hook) could probably be extracted in to a global component
export function GuideBanner() {
  const { handleDismiss, isDismissed } = usePromoBanner();

  if (isDismissed) return null;

  return (
    <Banner status="muted" title="Automatic Seeding" onDismiss={handleDismiss}>
      <p>
        Configuration for automatic inline seeding can be done at the subaccount level. Edit
        configurations per subaccount by clicking on edit settings in the table rows below.
      </p>

      <Banner.Action
        color="blue"
        variant="outline"
        to={LINKS.INLINE_SEEDING_DOCS}
        as={ExternalLink}
      >
        Inline Seeding Documentation
      </Banner.Action>

      <Banner.Media>
        <Picture seeThrough>
          <source srcSet={ConfigurationWebp} type="image/webp" />

          <Picture.Image alt="" src={Configuration} />
        </Picture>
      </Banner.Media>
    </Banner>
  );
}

function usePromoBanner() {
  const [isDismissed, setIsDismissed] = useState(
    useSelector((state) => isUserUiOptionSet('seed_list.automatic_seeding_banner_dismissed')(state))
  );
  const dispatch = useDispatch();

  const handleDismiss = () => {
    // Rather than re-requesting data from the server, just handling rendering via state
    // In the event updating UI options fails, there isn't a huge downside with having the banner re-appear
    setIsDismissed(true);

    dispatch(
      updateUserUIOptions({
        seed_list: {
          automatic_seeding_banner_dismissed: true
        }
      })
    );
  };

  return { handleDismiss, isDismissed };
}
