import {
  categorizedMetricsList,
  list as METRICS_LIST,
  NON_PREFETCHED_KEY
} from '@sparkpost/report-builder/config';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Checkbox, Drawer, Expandable, Stack, Tooltip } from 'src/components/matchbox';
import { Form } from 'src/components/tracking';
import { PORTALS } from 'src/constants';
import { hasProductOnBillingSubscription } from 'src/helpers/conditions/account';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import styled from 'styled-components';
import { comparisonTypeExists, filterKeyExists } from '../../helpers';
import { DeliverabilityBanner, MetricsSubjectCampaignsWarning } from './components';

const ColumnList = styled.div`
  padding: inherit;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: ${(props) => `repeat(${props.count}, auto)`};
  grid-auto-flow: column;
  word-wrap: break-word;
  max-width: 100%;
`;

const INITIAL_STATE = METRICS_LIST.reduce((accumulator, { key }) => {
  accumulator[key] = false;
  return accumulator;
}, {});

const DESCRIPTIONS = {
  Injection: 'Processing of messages through SparkPost',
  Delivery: 'Transmission of messages to the mailbox',
  Deliverability: 'Placement of messages in the inbox',
  Engagement: 'Interaction with messages in the inbox'
};

export default function MetricsForm(props) {
  const { state: reportOptions, includePrefetchedOpens } = useAnalyticsReportContext();

  const getStateFromProps = useCallback(() => {
    return props.selectedMetrics.reduce(
      (accumulator, current) => {
        accumulator[current.key] = true;
        return accumulator;
      },
      { ...INITIAL_STATE }
    );
  }, [props.selectedMetrics]);

  const [selectedMetrics, setSelectedMetrics] = useState(getStateFromProps());

  const { filters, comparisons } = reportOptions;

  const getMetricKey = useCallback(
    (metric) => {
      if (!includePrefetchedOpens && metric.hasOwnProperty(NON_PREFETCHED_KEY))
        return metric[NON_PREFETCHED_KEY];

      return metric.key;
    },
    [includePrefetchedOpens]
  );

  const isUsingSubjectCampaignsCompareBy = useMemo(
    () => comparisonTypeExists(comparisons, 'subject_campaigns'),
    [comparisons]
  );

  const isUsingSubjectCampaignsFilter = useMemo(
    () => filterKeyExists(filters, 'subject_campaigns'),
    [filters]
  );

  const isUsingSubjectCampaign = isUsingSubjectCampaignsFilter || isUsingSubjectCampaignsCompareBy;

  const hasD12yProduct = useSelector((state) =>
    hasProductOnBillingSubscription('deliverability')(state)
  );

  useEffect(() => {
    const newSelectedMetrics = getStateFromProps();
    setSelectedMetrics(newSelectedMetrics);
  }, [getStateFromProps, props.selectedMetrics]);

  const handleCheckbox = (key) => {
    const newSelectedMetric = { ...selectedMetrics };
    newSelectedMetric[key] = !newSelectedMetric[key];
    setSelectedMetrics(newSelectedMetric);
  };

  const handleApply = (event) => {
    event.preventDefault();
    props.handleSubmit({ metrics: getSelectedMetrics() });
  };

  const getSelectedMetrics = () => _.keys(selectedMetrics).filter((key) => !!selectedMetrics[key]);

  const isSelectedMetricsSameAsCurrentlyAppliedMetrics =
    props.selectedMetrics
      .map(({ key }) => key)
      .sort()
      .join(',') === getSelectedMetrics().sort().join(',');

  // Needs this for current tests as hibana is not enabled for tests but is required for the Drawer component
  const { DrawerFooter = Drawer.Footer } = props;
  return (
    <Form id="analyticsreport-metrics-form" onSubmit={handleApply}>
      <Box padding="500" paddingBottom="100px">
        <Stack>
          {!hasD12yProduct && <DeliverabilityBanner />}

          {isUsingSubjectCampaign && (
            <MetricsSubjectCampaignsWarning
              filter={isUsingSubjectCampaignsFilter}
              compare={isUsingSubjectCampaignsCompareBy}
            />
          )}

          {/* Renders categories */}
          {categorizedMetricsList.map(({ category, metrics }) => {
            return (
              <Box key={category}>
                <Expandable
                  defaultOpen
                  id={category}
                  subtitle={DESCRIPTIONS[category]}
                  title={`${category} Metrics`}
                >
                  {/* Renders metrics inside each category */}
                  <ColumnList count={Math.ceil(metrics.length / 2)}>
                    {metrics
                      .filter(({ key }) => key !== 'prefetched_open_rate')
                      .map((metric) => {
                        const isDisabled =
                          (!hasD12yProduct && metric.product === 'deliverability') ||
                          (isUsingSubjectCampaign && metric.product !== 'deliverability');

                        const metricKey = getMetricKey(metric);

                        return (
                          <div key={metricKey}>
                            <Tooltip
                              id={metricKey}
                              content={metric.description}
                              portalId={PORTALS.TOOLTIP.ID}
                              disabled={!Boolean(metric.description)}
                            >
                              <Box marginRight="300" width="200px" paddingLeft="100">
                                <Checkbox
                                  id={metricKey}
                                  key={`${category}-${metricKey}`}
                                  onChange={() => handleCheckbox(metricKey)}
                                  checked={selectedMetrics[metricKey]}
                                  disabled={isDisabled}
                                  label={metric.label}
                                  data-track={true}
                                />
                              </Box>
                            </Tooltip>
                          </div>
                        );
                      })}
                  </ColumnList>
                </Expandable>
              </Box>
            );
          })}
        </Stack>
      </Box>
      <DrawerFooter margin="400">
        <Box display="flex">
          <Box pr="100" flex="1">
            <Button
              width="100%"
              type="submit"
              variant="primary"
              disabled={
                getSelectedMetrics().length < 1 || isSelectedMetricsSameAsCurrentlyAppliedMetrics
              }
            >
              Apply Metrics
            </Button>
          </Box>
          <Box pl="100" flex="1">
            <Button
              width="100%"
              onClick={() => setSelectedMetrics(INITIAL_STATE)}
              variant="secondary"
            >
              Clear Metrics
            </Button>
          </Box>
        </Box>
      </DrawerFooter>
    </Form>
  );
}
