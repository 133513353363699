import { ProgressBar as MatchboxProgressBar } from '@sparkpost/matchbox';

/**
 * Matchbox ProgressBar component.
 * @see https://design.sparkpost.com/components/progressbar/
 */
const ProgressBar = MatchboxProgressBar;

ProgressBar.displayName = 'ProgressBar';

export default ProgressBar;
