import React from 'react';
import { formatDateTime } from '@sparkpost/report-builder/helpers/date';
import { slugToFriendly } from 'src/helpers/string';
import { Unit } from 'src/components';
import { TranslatableText } from 'src/components/text';
import { ScreenReaderOnlyTable } from 'src/components/a11y';
import EmptyCell from 'src/components/collection/EmptyCell';

export function ChartScreenReaderOnlyTable({
  caption = 'Analytics Data Over Time',
  captionUnit,
  metrics,
  data
}) {
  const headers = [
    'Timestamp',
    'Industry Benchmark Rate',
    ...metrics.map((metric) => metric.label)
  ];

  return (
    <ScreenReaderOnlyTable caption={`${caption} by ${captionUnit}`}>
      <ScreenReaderOnlyTable.Head headers={headers} />

      <ScreenReaderOnlyTable.Body metrics={metrics} rows={data} as={TableRow} />
    </ScreenReaderOnlyTable>
  );
}

function TableRow({ rowData, metrics }) {
  // The defensive checks are a little annoying here - is there a better way to handle this?
  const [q25 = '', q75 = '', industry = '', mailboxProvider = ''] = rowData.industry_rate || [];

  return (
    <tr>
      <td>{formatDateTime.useMomentInput(rowData.ts)}</td>

      <td>
        {Boolean(q25) ? (
          <>
            25th percentile is <TranslatableText>{(q25 * 100).toFixed()}</TranslatableText>%, 75th
            percentile is <TranslatableText>{(q75 * 100).toFixed()}</TranslatableText>% for the{' '}
            <TranslatableText>{slugToFriendly(industry)}</TranslatableText> industry and for{' '}
            <TranslatableText>{mailboxProvider}</TranslatableText>
          </>
        ) : (
          <EmptyCell />
        )}
      </td>

      {metrics.map((metric, metricIndex) => {
        return (
          <td key={`${metric.key}-${metricIndex}`}>
            <Unit value={rowData[metric.key]} unit={metric.unit} />
          </td>
        );
      })}
    </tr>
  );
}
