import {
  Popover as MatchboxPopover,
  PopoverProps as MatchboxPopoverProps
} from '@sparkpost/matchbox';
import React from 'react';
import { PORTALS } from 'src/constants';

type PopoverProps = Omit<MatchboxPopoverProps, 'as'> & { as: 'span' | 'div'; className?: string };

/**
 * Matchbox Popover component.
 * @see https://design.sparkpost.com/components/popover/
 */
const Popover = ({children, ...props}: PopoverProps): JSX.Element => (
  <MatchboxPopover portalId={PORTALS.POPOVER.ID} {...props}>
    {children}
  </MatchboxPopover>
);

Popover.displayName = 'Popover';

export default Popover;
