import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Loading } from 'src/components/loading';
import { Panel } from 'src/components/matchbox';

const StyledPanel = styled.div<{ minHeight: string }>`
  min-height: ${(props) => props.minHeight};
  position: relative;
`;

type PanelLoadingPropsType = {
  accent: $TODOFIXME;
  as: $TODOFIXME;
  minHeight: string;
  title: string;
};

const PanelLoading = (props: PanelLoadingPropsType) => {
  const { minHeight, accent, title, as = Panel.LEGACY } = props;

  return (
    <StyledPanel
      as={as}
      accent={accent}
      data-id="panel-loading"
      title={title}
      minHeight={minHeight}
    >
      <Loading minHeight={minHeight} />
    </StyledPanel>
  );
};

PanelLoading.propTypes = {
  minHeight: PropTypes.string,
  accent: PropTypes.bool
};

PanelLoading.defaultProps = {
  minHeight: '400px',
  accent: false
};

export default PanelLoading;
