/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import copy from 'copy-to-clipboard';
import _, { omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { match as Match, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import styled from 'styled-components';

// REDUX
import {
  getMessageHistory as getMessageHistoryAction,
  getSelectedEvent as getSelectedEventAction
} from 'src/actions/messageEvents';
import { eventPageMSTP } from 'src/selectors/messageEvents';

// HELPERS
import { download } from 'src/helpers/downloading';
import { getDetailsPath } from 'src/helpers/messageEvents';
import { snakeToFriendly } from 'src/helpers/string';
import { getBackPageParams } from '../helpers/getBackPageParams';
import { getEventColor } from '../helpers/getEventColor';
import { getEventName } from '../helpers/getEventName';

// COMPONENTS
import { CopyField, Loading } from 'src/components';
import { RedirectAndAlert } from 'src/components/globalAlert';
import { PageLink } from 'src/components/links';
import { Box, Button, LabelValue, Layout, Page, Panel, Tag } from 'src/components/matchbox';
import { EventTimeline } from '../components/EventTimeline';

// STYLES
import { EventContentWrapper, LayoutWrapper, SectionTitle } from './styles';

// TYPES
import { Event } from 'src/typescript';

type EventPageParams = {
  eventId: string;
  messageId: string;
};

type EventPageState = {
  documentation: unknown;
  isOrphanEvent: boolean;
  loading: boolean;
  messageHistory: Event[];
  messageId: string;
  selectedEvent: Event;
  selectedEventId: string;
  shouldRetryOrRedirect: boolean;
};

const StyledWrappedText = styled.span`
  word-wrap: anywhere;
`;

const eventSelector = (state: $TODOFIXME, match: Match<EventPageParams>): EventPageState => {
  const selector = eventPageMSTP() as (
    state: $TODOFIXME,
    props: { match: Match<EventPageParams> }
  ) => EventPageState;
  return selector(state, { match });
};

const friendlyObjectRenderer = (
  value?: object | unknown,
  keyModifierFn?: (key: string) => string,
  prefix = ''
): JSX.Element | null => {
  if (!value) return null;

  const val = value as object;

  if (Object.keys(val).length === 0) return null;

  return (
    <Box>
      <CopyField
        label=""
        id="copy-field-object"
        object={val}
        value={JSON.stringify(val, null, 2)}
      />
    </Box>
  );
};

const friendlyArrayRenderer = (
  value?: string[] | unknown,
  valModifierFn?: (key: string) => string
): JSX.Element | null => {
  if (!value) return null;

  const val = value as string[];

  if (val.length === 0) return null;

  return (
    <Box>
      {val.map((str, index) => (
        <>
          <span key={str}>{valModifierFn ? valModifierFn(str) : str}</span>
          {/* eslint-disable-next-line local/restrict-translatable-text */}
          {val.length === index + 1 ? '' : ', '}
        </>
      ))}
    </Box>
  );
};

export default function EventPage(): JSX.Element {
  const [copied, setCopied] = useState(false);
  const [requestState, setRequestState] = useState({
    shouldRedirect: false,
    requestCount: 0
  });

  const dispatch = useDispatch();
  const match = useRouteMatch<EventPageParams>();
  const { messageId, eventId } = useParams<EventPageParams>();
  const history = useHistory();

  const getMessageHistory = useCallback(
    (messageId: string): Dispatch => dispatch(getMessageHistoryAction({ messageId })),
    [dispatch]
  );

  const getSelectedEvent = useCallback(
    (eventId: string): Dispatch => dispatch(getSelectedEventAction({ eventId })),
    [dispatch]
  );

  const state = useSelector((state) => eventSelector(state, match));
  const { from, to } = useSelector((state: $TODOFIXME) => state.messageEvents.search.dateOptions);

  const filtersAppliedToURL: { [key: string]: string[] } = useSelector(
    (state: $TODOFIXME) => state.messageEvents.search
  );

  const prevStateLoading = useRef(state.loading);
  const firstLoad = useRef(true);
  const refresh = useCallback(() => {
    if (state.isOrphanEvent && eventId) {
      getSelectedEvent(eventId);
    } else {
      getMessageHistory(messageId);
    }
  }, [eventId, getMessageHistory, getSelectedEvent, messageId, state.isOrphanEvent]);

  const requestInitial = useCallback((): void => {
    refresh();

    // Increment request count for each time this method is invoked
    setRequestState((state) => ({
      ...state,
      requestCount: state.requestCount + 1
    }));
  }, [refresh]);

  const debounceRequest = _.debounce(requestInitial, 1000);

  const handleTimelineClick = useCallback(
    (eventId: string) => {
      history.push(getDetailsPath(messageId, eventId));
    },
    [history, messageId]
  );

  const handleCopy = useCallback(() => {
    const detailsToRender = omit(state.selectedEvent, 'formattedDate');

    copy(JSON.stringify(detailsToRender));
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, [state.selectedEvent]);

  const handleDownload = useCallback(() => {
    const detailsToRender = omit(state.selectedEvent, 'formattedDate');
    const url = new Blob([JSON.stringify(detailsToRender)], { type: 'application/json' });
    const fileName = state.isOrphanEvent
      ? `sparkpost-event-${eventId}.json`
      : `sparkpost-msg-${messageId}-event-${eventId}.json`;
    download({ name: fileName, url });
  }, [eventId, messageId, state.selectedEvent, state.isOrphanEvent]);

  type EventContentPropsCol =
    | {
        key: keyof Event;
        renderer?: (value: unknown) => React.ReactNode;
        title?: string;
      }
    | keyof Event;

  type EventContentProps = {
    col1: EventContentPropsCol[];
    col2?: EventContentPropsCol[];
    title: string;
  };

  const EventContent = useCallback(
    ({ title, col1, col2 }: EventContentProps): JSX.Element | null => {
      if (!state.selectedEvent) return null;

      const mapContent = (
        group: EventContentPropsCol
      ): {
        key: keyof Event;
        title: string;
        value: React.ReactNode;
      } => {
        const safeValue = (value: unknown): React.ReactNode =>
          typeof value === 'object' ? JSON.stringify(value) : (value as React.ReactNode);

        const event = {
          ...state.selectedEvent
        };
        const key = typeof group === 'string' ? group : group.key;

        const defaultTitle = snakeToFriendly(key);
        const title = typeof group !== 'string' ? group.title || defaultTitle : defaultTitle;
        let value = safeValue(event[key]);

        try {
          if (typeof group !== 'string' && group.renderer) {
            value = group.renderer(event[key]);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Error in event renderer function', e);
        }

        const keysWithValues = {
          key,
          title,
          value
        };

        return keysWithValues;
      };

      const col1Mapped = col1.map(mapContent).filter(({ value }) => value);
      const col2Mapped = (col2 || []).map(mapContent).filter(({ value }) => value);

      const grouped = [col1Mapped, col2Mapped];

      if (col1Mapped.length === 0 && col2Mapped.length === 0) return null;

      return (
        <Panel.Section>
          <SectionTitle>{title}</SectionTitle>

          <EventContentWrapper>
            {grouped.map((group, index) => (
              <Box key={`group-${index}`}>
                {group.map((content, index) => (
                  <Box key={`${content.key}-${index}`} mb={500}>
                    <StyledWrappedText>
                      <LabelValue.Label>{content.title}</LabelValue.Label>
                      <LabelValue.Value>{content.value}</LabelValue.Value>
                    </StyledWrappedText>
                  </Box>
                ))}
              </Box>
            ))}
          </EventContentWrapper>
        </Panel.Section>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.selectedEvent]
  );

  // first load
  useEffect(() => {
    requestInitial();
  }, [eventId, messageId, refresh, requestInitial]);

  useEffect(() => {
    if (prevStateLoading.current && !state.loading) {
      firstLoad.current = false;
      // First, wait until finished the request has finished loading.
      if (state.shouldRetryOrRedirect) {
        // Redirect after 3 request attempts
        if (requestState.requestCount === 3) {
          return setRequestState((state) => ({
            ...state,
            shouldRedirect: true
          }));
        }

        // Re-try until request count limit is reached
        return debounceRequest();
      } else {
        debounceRequest.cancel(); //Makes sure if it does finally load, it cancels request
      }
    }

    prevStateLoading.current = state.loading;
  }, [
    debounceRequest,
    requestInitial,
    requestState.requestCount,
    state.loading,
    state.shouldRetryOrRedirect
  ]);

  const content = (
    <LayoutWrapper>
      <Layout collapseBelow="sm">
        {!state.isOrphanEvent && (
          <Layout.Section annotated>
            <EventTimeline
              history={state.messageHistory}
              selectedId={state.selectedEventId}
              onClick={handleTimelineClick}
            />
          </Layout.Section>
        )}

        <Layout.Section>
          <Panel>
            <Panel.Section>
              <SectionTitle>Raw JSON</SectionTitle>

              <Box>
                <Button
                  useMatchboxVariant={false}
                  variant="secondary"
                  mr="300"
                  mb="300"
                  onClick={handleCopy}
                  disabled={state.loading}
                >
                  {/* eslint-disable-next-line local/restrict-translatable-text */}
                  {copied ? 'Copied' : 'Copy Raw JSON'}
                </Button>

                <Button
                  useMatchboxVariant={false}
                  variant="secondary"
                  onClick={handleDownload}
                  disabled={state.loading}
                >
                  Download Raw JSON
                </Button>
              </Box>
            </Panel.Section>

            {state.loading ? (
              <Loading />
            ) : (
              <>
                <EventContent
                  title="Event Details"
                  col1={[
                    {
                      key: 'type',
                      title: 'Event Type',
                      renderer(value: Event['type']): JSX.Element {
                        return <Tag color={getEventColor(value)}>{getEventName(value)}</Tag>;
                      }
                    },
                    'bounce_class',
                    'device_token',
                    'error_code',
                    { key: 'event_id', title: 'Event ID' },
                    { key: 'fbtype', title: 'Feedback Report Type' },
                    {
                      key: 'geo_ip',
                      title: 'Geo IP',
                      renderer: (value: object | undefined): JSX.Element | null =>
                        friendlyObjectRenderer(value, snakeToFriendly)
                    },
                    { key: 'ip_address', title: 'IP Address' },
                    { key: 'outbound_tls', title: 'Outbound TLS' },
                    'queue_time'
                  ]}
                  col2={[
                    'timestamp',
                    'reason',
                    { key: 'raw_reason', title: 'Reason (raw)' },
                    { key: 'remote_addr', title: 'Remote Address' },
                    'report_by',
                    'report_to',
                    { key: 'num_retries', title: 'Retry Count' },
                    'target_link_name',
                    { key: 'target_link_url', title: 'Target Link URL' },
                    'user_agent'
                  ]}
                />

                <EventContent
                  title="Recipient"
                  col1={[
                    'mailbox_provider',
                    'mailbox_provider_region',
                    'recipient_domain',
                    { key: 'rcpt_to', title: 'Recipient Address' },
                    { key: 'raw_rcpt_to', title: 'Recipient Address (raw)' },
                    { key: 'rcpt_hash', title: 'Recipient Address (hashed)' }
                  ]}
                  col2={[
                    {
                      key: 'rcpt_meta',
                      title: 'Recipient Metadata',
                      renderer: friendlyObjectRenderer
                    },
                    {
                      key: 'rcpt_subs',
                      title: 'Recipient Substituitions',
                      renderer: friendlyObjectRenderer
                    },
                    { key: 'rcpt_tags', title: 'Recipient Tags', renderer: friendlyArrayRenderer },
                    { key: 'rcpt_type', title: 'Recipient Type' },
                    'routing_domain'
                  ]}
                />

                <EventContent
                  title="Sender"
                  col1={[
                    { key: 'customer_id', title: 'Customer ID' },
                    'friendly_from',
                    { key: 'ip_pool', title: 'IP Pool' },
                    { key: 'mailfrom', title: 'Mail From' },
                    { key: 'msg_from', title: 'Message From' }
                  ]}
                  col2={[
                    'sending_domain',
                    { key: 'sending_ip', title: 'Sending IP' },
                    { key: 'subaccount_id', title: 'Subaccount ID' }
                  ]}
                />

                <EventContent
                  title="Content"
                  col1={[
                    { key: 'ab_test_id', title: 'A/B Test ID' },
                    { key: 'ab_test_version', title: 'A/B Test Version' },
                    { key: 'amp_enabled', title: 'AMP Enabled' },
                    { key: 'campaign_id', title: 'Campaign ID' }
                  ]}
                  col2={[
                    'subject',
                    { key: 'template_id', title: 'Template ID' },
                    'template_version'
                  ]}
                />

                <EventContent
                  title="Transmission Details"
                  col1={[
                    { key: 'delv_method', title: 'Delivery Method' },
                    'injection_time',
                    { key: 'message_id', title: 'Message ID' },
                    { key: 'msg_size', title: 'Message Size' }
                  ]}
                  col2={[
                    { key: 'recv_method', title: 'Receive Method' },
                    'scheduled_time',
                    'transactional',
                    { key: 'transmission_id', title: 'Transmission ID' }
                  ]}
                />

                <EventContent
                  title="SMS"
                  col1={[
                    { key: 'sms_coding', title: 'Data Encoding' },
                    { key: 'sms_dst', title: 'Destination Address' },
                    { key: 'sms_dst_npi', title: 'Destination NPI' },
                    { key: 'sms_dst_ton', title: 'Destination TON' },
                    { key: 'sms_remoteids', title: 'Remote IDs' }
                  ]}
                  col2={[
                    { key: 'sms_segments', title: 'Segments' },
                    { key: 'sms_src', title: 'Source Address' },
                    { key: 'sms_src_npi', title: 'Source NPI' },
                    { key: 'sms_src_ton', title: 'Source TON' },
                    { key: 'sms_text', title: 'Text' }
                  ]}
                />

                <EventContent
                  title="Engagement Tracking"
                  col1={[
                    'initial_pixel',
                    'click_tracking',
                    { key: 'sms_remoteids', title: 'Remote IDs' }
                  ]}
                  col2={['open_tracking']}
                />
              </>
            )}
          </Panel>
        </Layout.Section>
      </Layout>
    </LayoutWrapper>
  );

  const redirectAlert = (
    <RedirectAndAlert
      to="/reports/message-events"
      alert={{
        type: 'warning',
        message: `Unable to find event(s) data with ${
          state.isOrphanEvent ? `event_id # ${state.selectedEventId}` : `message_id # ${messageId}`
        }`
      }}
    />
  );

  const backPageParams = getBackPageParams({
    from,
    to,
    filtersAppliedToURL
  });

  return (
    <Page
      title="Message Details"
      breadcrumbAction={{
        content: 'Events',
        component: PageLink,
        to: `/reports/message-events${backPageParams}`
      }}
      primaryArea={
        <Button
          variant="secondary"
          onClick={refresh}
          useMatchboxVariant={false}
          disabled={state.loading}
        >
          Refresh Events
        </Button>
      }
    >
      {state.loading && firstLoad.current ? (
        <Loading />
      ) : requestState.shouldRedirect ? (
        redirectAlert
      ) : (
        content
      )}
    </Page>
  );
}
