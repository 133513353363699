import { formatDate } from '@sparkpost/report-builder/helpers/date';
import React from 'react';
import { Heading } from 'src/components/text';
import styles from './ChartHeader.module.scss';
import InfoTooltip from './InfoTooltip';

const ChartHeader = ({ date, title, primaryArea, hideLine, tooltipContent }) => {
  return (
    <>
      <div className={styles.ChartHeader}>
        <Heading as="h6" className={styles.Title}>
          {title}
          {date && ` – ${formatDate.useMomentInput(date)}`}
        </Heading>
        {tooltipContent && <InfoTooltip content={tooltipContent} size={17} />}
        {primaryArea && <div className={styles.PrimaryArea}>{primaryArea}</div>}
      </div>
      {!hideLine && <hr className={styles.Line} />}
    </>
  );
};

export default ChartHeader;
