import React, { PropsWithChildren } from 'react';
import { Panel as MatchboxPanel } from '@sparkpost/matchbox';
import { Box } from 'src/components/matchbox';

interface PanelHeadline {
  (props: PropsWithChildren<{}>): JSX.Element;

  displayName?: string | undefined;
}

type PanelHeadlineIconProps = {
  as: $TODOFIXME;
};

interface PanelHeadlineIcon {
  (props: PanelHeadlineIconProps): JSX.Element;

  displayName?: string | undefined;
}

type Panel = typeof MatchboxPanel & {
  Headline: PanelHeadline;
  HeadlineIcon: PanelHeadlineIcon;
};

/**
 * Matchbox Panel component.
 * @see https://design.sparkpost.com/components/panel/
 */
const Panel = MatchboxPanel as Panel;

/**
 * @description Used to consistently render a slightly larger heading font size and style within <Panel.Header /> component instances
 */
const Headline: PanelHeadline = ({ children }) => {
  return (
    <Box as="span" fontSize="500" fontWeight="medium" display="flex" alignItems="center">
      {children}
    </Box>
  );
};

Headline.displayName = 'Panel.Headline';

/**
 * @description Renders an icon within a `<Panel.Headline />` instance
 */
const HeadlineIcon: PanelHeadlineIcon = ({ as }) => {
  return <Box as={as} mr="200" marginTop="3px" size={24} />;
};

HeadlineIcon.displayName = 'Panel.HeadlineIcon';

Panel.displayName = 'Panel';
Panel.LEGACY.displayName = 'Panel.LEGACY';
Panel.LEGACY.Section.displayName = 'Panel.LEGACY.Section';
Panel.LEGACY.Footer.displayName = 'Panel.LEGACY.Footer';
Panel.Header.displayName = 'Panel.Header';
Panel.SubHeader.displayName = 'Panel.SubHeader';
Panel.Action.displayName = 'Panel.Action';
Panel.Section.displayName = 'Panel.Section';

Panel.Headline = Headline;
Panel.HeadlineIcon = HeadlineIcon;

export default Panel;
