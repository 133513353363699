import { Radio as MatchboxRadio } from '@sparkpost/matchbox';

/**
 * Matchbox Radio component.
 * @see https://design.sparkpost.com/components/radio/
 */
const Radio = MatchboxRadio;
Radio.Group = MatchboxRadio.Group;

Radio.displayName = 'Radio';
Radio.Group.displayName = 'Radio.Group';

export default Radio;
