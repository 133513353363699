import React, { useEffect, useRef, useState } from 'react';
import { useCombobox } from 'downshift';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { KeyboardArrowDown, KeyboardArrowUp } from '@sparkpost/matchbox-icons';
import { FIELD_MAX_WIDTH } from 'src/constants';
import { ActionList, Box, Label, TextField } from 'src/components/matchbox';
import sortMatch from 'src/helpers/sortMatch';

const TypeSelectWrapper = styled.div`
  position: relative;
`;

const TypeSelectActionList = styled(ActionList)`
  background: ${(props) =>
    props.children.length === 0 ? 'transparent' : props.theme.colors.white};
  border: 1px solid;
  border-color: ${(props) =>
    props.children.length === 0 ? 'transparent' : props.theme.colors.gray['400']};
  box-shadow: ${(props) => (props.children.length === 0 ? 'none' : props.theme.shadows['200'])};
  /* When "helpText" is present, offset its height with negative margin */
  margin-top: ${(props) => (props.helpText ? `-${props.theme.space['450']}` : '3px')};
  max-height: ${(props) => props.theme.sizes['1000']};
  opacity: ${(props) => (props.open ? 1 : 0)};
  visibility: ${(props) => (props.children.length === 0 ? 'hidden' : 'unset')};
  overflow-y: scroll;
  pointer-events: ${(props) => (props.open ? 'auto' : 'none')};
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  transform: ${(props) => (props.open ? 'scaleY(1)' : 'scaleY(0.97)')};
  transform-origin: top;
  transition-duration: ${(props) => props.theme.motion.duration.fast};
  transition-timing-function: ${(props) => props.theme.motion.ease.inOut};
  transition-property: opacity, visibility, transform;
  z-index: ${(props) => Number(props.theme.zIndices.overlay) + 1};
`;

// It would be preferable to use Matchbox (Columns or Inline), but they both use <div>'s and this is rendered inside <a>'s.
// See: https://css-tricks.com/flexbox-truncated-text/
const ItemWrapper = styled.span`
  display: flex;
  min-width: 0;
`;

const ItemText = styled.span`
  flex-grow: 1;
  flex-shrink: 1;
  font-size: ${(props) => props.theme.fontSizes['200']};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ItemMetaText = styled.span`
  color: ${(props) => props.theme.colors.gray['700']};
  flex-shrink: 0;
  font-size: ${(props) => props.theme.fontSizes['100']};
  margin-left: ${(props) => props.theme.space['300']};
`;

function TypeSelect({
  disabled,
  helpText,
  itemToString,
  label,
  maxHeight,
  maxNumberOfResults,
  maxWidth,
  onChange,
  placeholder,
  renderItem,
  results,
  selectedItem,
  'data-track': dataTrack,
  'data-id': dataId
}) {
  const inputRef = useRef(null);
  const [matches, setMatches] = useState(results);
  const combobox = useCombobox({
    items: matches,
    itemToString,
    selectedItem,
    onSelectedItemChange: handleSelectedItemChange,
    onIsOpenChange: handleIsOpenChange
  });

  function handleSelectedItemChange({ selectedItem }) {
    return onChange(selectedItem);
  }

  function handleBlur() {
    // Set the currently selected item as selected in order to prevent the selected item from being empty
    return combobox.setInputValue(itemToString(selectedItem));
  }

  function handleFocus(event) {
    if (!combobox.isOpen) {
      event.target.select(); // Using browser API for text selection
      setMatches(results);
      combobox.openMenu();
    }
  }

  // Handle keyboard events that should open the menu, even when the field already focused
  function handleKeyDown(event) {
    if (!combobox.isOpen && event.key === 'ArrowDown') {
      setMatches(results);
      combobox.openMenu();
    }
  }

  function handleIsOpenChange() {
    // Highlight the first item if no other item is selected
    if (!combobox.highlightedIndex) {
      return combobox.setHighlightedIndex(0);
    }
  }

  function handleIconClick() {
    if (combobox.isOpen) {
      return combobox.closeMenu();
    }

    return inputRef.current.focus();
  }

  // When items are selected, leverage the custom input value setter
  // to ensure the input value remains after a selection.
  useEffect(() => {
    if (selectedItem) {
      const value = itemToString(selectedItem);

      return combobox.setInputValue(value);
    }
    // 🤔 Not sure why eslint is complaining about this dependency array - adding `combobox` to the list causes an infinite loop/crash
    /* eslint-disable-next-line */
  }, [combobox.setInputValue, itemToString, selectedItem]);

  const [updateMatches] = useDebouncedCallback((value) => {
    // Show all results if no search has been entered
    if (!value || value.length === 0) return setMatches(results);

    // Generate list of matches based on the user's entry and limit the results based on the max number allowed
    const nextMatches = sortMatch(results, value, itemToString).slice(0, maxNumberOfResults);

    return setMatches(nextMatches);
  }, 300);

  // Invoking `updateMatches` occurs after the debounce duration
  useEffect(() => {
    updateMatches(combobox.inputValue);
  }, [updateMatches, combobox.inputValue, results]);

  return (
    <TypeSelectWrapper>
      <Label {...combobox.getLabelProps()}>{label}</Label>

      <Box maxWidth={maxWidth} position="relative" {...combobox.getComboboxProps()}>
        <TextField
          {...combobox.getInputProps({
            onBlur: handleBlur,
            onFocus: handleFocus,
            onKeyDown: handleKeyDown,
            ref: inputRef
          })}
          disabled={disabled}
          placeholder={placeholder}
          helpText={helpText}
          data-lpignore={true} // Ignore attribute for LastPass
          data-track={dataTrack}
          data-id={dataId}
          suffix={<SuffixIcon isOpen={combobox.isOpen} onClick={handleIconClick} />}
        />

        {/* Not the best way, but until the ref can get passed, a hacky workaround */}
        <div {...combobox.getMenuProps()}>
          <TypeSelectActionList open={combobox.isOpen} maxHeight={maxHeight} helpText={helpText}>
            {matches.map((item, index) => {
              return (
                <ActionList.Action
                  key={item.key}
                  {...combobox.getItemProps({
                    highlighted: combobox.highlightedIndex === index,
                    index,
                    item,
                    key: item.key
                  })}
                >
                  {renderItem ? renderItem(item) : <TypeaheadItem label={itemToString(item)} />}
                </ActionList.Action>
              );
            })}
          </TypeSelectActionList>
        </div>
      </Box>
    </TypeSelectWrapper>
  );
}

function SuffixIcon({ isOpen, onClick }) {
  const icon = isOpen ? KeyboardArrowUp : KeyboardArrowDown;

  return <Box as={icon} size={25} color="blue.700" onClick={onClick} />;
}

TypeSelect.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  itemToString: PropTypes.func,
  label: PropTypes.string.isRequired,
  maxHeight: PropTypes.number,
  maxNumberOfResults: PropTypes.number,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  renderItem: PropTypes.func,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired
    })
  ),
  'data-track': PropTypes.bool,
  'data-id': PropTypes.string
};

TypeSelect.defaultProps = {
  disabled: false,
  itemToString: (item) => item,
  maxHeight: 300,
  maxNumberOfResults: 100,
  maxWidth: FIELD_MAX_WIDTH,
  placeholder: 'Type to search',
  results: []
};

function TypeaheadItem({ label, meta }) {
  return (
    <ItemWrapper>
      <ItemText>{label}</ItemText>

      {meta && <ItemMetaText>{meta}</ItemMetaText>}
    </ItemWrapper>
  );
}

TypeaheadItem.propTypes = {
  label: PropTypes.string.isRequired,
  meta: PropTypes.string
};

TypeSelect.Item = TypeaheadItem;

export default TypeSelect;
