import capitalize from 'lodash/capitalize';
import qs from 'qs';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { ButtonLink } from 'src/components/links';
import { Box, Page, Tabs } from 'src/components/matchbox';
import { useMultiCheckbox } from 'src/components/MultiCheckboxDropdown';
import {
  getRelativeDateOptionFromToAndFromSearchStrings,
  SIGNALS_RELATIVE_DATE_OPTIONS
} from 'src/constants';
import { hasProductOnBillingSubscription } from 'src/helpers/conditions/account';
import hasGrants from 'src/helpers/conditions/hasGrants';
import { usePageFilters } from 'src/hooks';
import { PageNotFound } from 'src/pages';
import {
  dataSourceCheckboxes,
  dataSourceCheckboxesForSubjectCampaign
} from 'src/pages/signals/constants/dataSources';
import { DETAILS_PAGES, getComponentProps, getNewTabUrl } from './constants/resourceDetails';
import { ResourceDetailsContextProvider } from './context/ResourceDetailsContext';
import useResourceDetailsContext from './hooks/useResourceDetailsContext';

function getPageTitle(type) {
  if (!type) return;
  switch (type) {
    case 'sending-domain':
      return 'Sending Domain | Signals Analytics | SparkPost';
    case 'mailbox-provider-region':
      return 'Mailbox Provider Region | Signals Analytics | SparkPost';
    case 'mailbox-provider':
      return 'Mailbox Provider | Signals Analytics | SparkPost';
    case 'ip-pool':
      return 'IP Pool | Signals Analytics | SparkPost';
    case 'sending-ip':
      return 'Sending IP | Signals Analytics | SparkPost';
    case 'subaccount':
      return 'Subaccount | Signals Analytics | SparkPost';
    case 'template':
      return 'Template | Signals Analytics | SparkPost';
    case 'campaign':
      return 'Campaign | Signals Analytics | SparkPost';
    case 'subject-campaign':
      return 'Subject Campaign | Signals Analytics | SparkPost';
    case 'domain':
      return 'Domain | Signals Analytics | SparkPost';
    default:
      return 'SparkPost';
  }
}

/**
 * ResourceDetailsPage - Handles the routing and URL params for signals details pages
 */
function ResourceDetailsPage() {
  const { match: { params: { type, tab } = {} } = {}, history } = useResourceDetailsContext();

  const PAGE_META = DETAILS_PAGES[type];
  const pageMetaHasTabs = tab && PAGE_META?.tabs?.length;
  const activeTabIndex = tab ? PAGE_META.tabs.findIndex((tabMeta) => tabMeta.tabName === tab) : 0;
  const tabNotFoundInMeta = pageMetaHasTabs && activeTabIndex === -1;

  const breadcrumbAction = {
    content: 'Analytics Report',
    component: ButtonLink,
    onClick: () => {
      if (history?.length <= 2) {
        history.push('/signals/analytics');
      } else {
        history.go(-1);
      }
    }
  };

  if (!PAGE_META || tabNotFoundInMeta) {
    return <PageNotFound></PageNotFound>;
  }

  const tabMeta = PAGE_META.tabs[activeTabIndex];
  const TabComponent = tabMeta?.component || function () {};
  return (
    <Page breadcrumbAction={breadcrumbAction} title={PAGE_META.title}>
      <Helmet>
        <title>{getPageTitle(type)}</title>
      </Helmet>
      {pageMetaHasTabs && (
        <Box mb="600">
          <Tabs
            onSelect={(selectedIndex) => {
              const selectedTabUrl = getNewTabUrl(
                PAGE_META.tabs[selectedIndex],
                history?.location?.pathname
              );

              if (selectedTabUrl) {
                history.push(selectedTabUrl);
              }
            }}
            selected={activeTabIndex}
            tabs={PAGE_META.tabs.map((tab) => {
              return { content: capitalize(tab.tabName) };
            })}
          />
        </Box>
      )}

      <TabComponent
        {...getComponentProps({
          tabMeta: tabMeta,
          propData: { pageMeta: PAGE_META, tabMeta }
        })}
      />
    </Page>
  );
}

const initFilters = {
  from: {},
  to: {},
  range: {},
  breakDownType: {},
  timezone: {},
  precision: {}
};

/**
 * ResourceDetailsContainer - Provides URL filter info and redux state info through a context layer.
 */
export default function ResourceDetailsContainer(props) {
  const { match: { params: { type } = {} } = {} } = props;
  const urlParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  if (urlParams.from && urlParams.to && !urlParams.range) {
    initFilters.range.defaultValue = getRelativeDateOptionFromToAndFromSearchStrings(
      urlParams.to,
      urlParams.from,
      SIGNALS_RELATIVE_DATE_OPTIONS
    );
  }

  const sourceCheckboxes = useMultiCheckbox(
    type === 'subject-campaign' ? dataSourceCheckboxesForSubjectCampaign : dataSourceCheckboxes
  );

  const hasD12yProduct = useSelector((state) =>
    hasProductOnBillingSubscription('deliverability')(state)
  );

  const hasSendingProduct = useSelector((state) =>
    hasProductOnBillingSubscription('messaging')(state)
  );

  const isBlocklistEnabled = useSelector((state) => hasGrants('blocklist-monitors/manage')(state));

  const pageFilters = usePageFilters(initFilters, { isReplaced: true });

  return (
    <ResourceDetailsContextProvider
      value={{
        hasD12yProduct,
        hasSendingProduct,
        sourceCheckboxes,
        isBlocklistEnabled,
        ...pageFilters,
        ...props
      }}
    >
      <ResourceDetailsPage />
    </ResourceDetailsContextProvider>
  );
}
