import React, { useCallback } from 'react';
import { DownloadLink } from 'src/components/links';
import { Button } from 'src/components/matchbox';
import { useHibana } from 'src/context/HibanaContext';
import { formatToCsv } from 'src/helpers/downloading';
import { TranslatableText } from '../text';

export const createDownloadLink = (data: SaveCSVButtonProps['data'], download: string) => {
  const a = document.createElement('a');
  a.download = download;
  a.referrerPolicy = 'origin';
  a.href = formatToCsv({ data, returnBlob: false }) as string;
  return a;
};

type SaveCSVButtonProps = {
  caption?: string;
  data: $TODOFIXME[];
  filename?: string | null;
  saveCsv: boolean;
};

const SaveCSVButton = ({
  data,
  saveCsv,
  caption = 'Save As CSV',
  filename,
  ...props
}: SaveCSVButtonProps) => {
  const [state] = useHibana() as [{ isHibanaEnabled: boolean }];
  const { isHibanaEnabled } = state;
  const now = Math.floor(Date.now() / 1000);
  const download = filename ? filename : `sparkpost-csv-${now}.csv`;
  let buttonStyleProps;

  if (isHibanaEnabled) {
    buttonStyleProps = {
      variant: 'monochrome-secondary'
    };
  }

  const handleOnClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      const a = createDownloadLink(data, download);
      a.click();
    },
    [data, download]
  );

  if (!saveCsv || !data) {
    return null;
  }

  return (
    <DownloadLink
      {...buttonStyleProps}
      as={Button as $TODOFIXME} // Update when we migrate useHibana hook.
      onClick={handleOnClick}
      {...props}
      href="#"
      data-download={download}
    >
      <TranslatableText>{caption}</TranslatableText>
    </DownloadLink>
  );
};

export default SaveCSVButton;
