import React, { Component } from 'react';
import BackupCodesView from './BackupCodesView';
import { Grid, Banner, Button, TextField, Modal, Stack } from 'src/components/matchbox';
import { Form } from 'src/components/tracking';
import { TranslatableText } from 'src/components/text';

const initialState = {
  password: '',
  showErrors: false
};

export default class BackupCodesModal extends Component {
  state = initialState;

  componentDidUpdate(oldProps) {
    if (oldProps.open && !this.props.open) {
      this.setState(initialState);
    }

    if (!oldProps.error && this.props.error) {
      this.setState({ showErrors: true });
    }
  }

  componentWillUnmount() {
    this.props.clearCodes();
  }

  handleInputChange = ({ target }) => {
    this.setState({ password: target.value });
  };

  generateCodes = () => {
    this.props.generate(this.state.password);
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.generateCodes();
  };

  getModalContent = ({ generatedCodes, hasCodes, codes, activeCount, error }) => {
    if (!generatedCodes)
      return (
        <Form onSubmit={this.onSubmit} id="generate-2fa-backup-codes">
          <Stack>
            {hasCodes && (
              <Banner status="warning" marginBottom="500">
                Clicking Generate will overwrite your existing{' '}
                <TranslatableText>{activeCount}</TranslatableText> active backup codes.
              </Banner>
            )}
            Recovery codes can be used to log in as an alternative to MFA when the authenticator app
            is unavailable. Codes should be copied and kept in a safe, accessible location.
            <Grid>
              <Grid.Column xs={12} md={6}>
                <TextField
                  name="Password"
                  label="Password"
                  id="tfa-backup-codes-generate-password"
                  required
                  type="password"
                  onChange={this.handleInputChange}
                  data-sensitive={true}
                  placeholder="Password"
                  value={this.state.password}
                  error={this.state.showErrors && error ? 'Incorrect Password' : ''}
                />
              </Grid.Column>
            </Grid>
          </Stack>
        </Form>
      );

    if (generatedCodes)
      return (
        <>
          <Stack>
            <p>
              Recovery codes can be used to log in as an alternative to MFA when the authenticator
              app is unavailable. Codes should be copied and kept in a safe, accessible location.
            </p>
            <Grid>
              <Grid.Column xs={12} md={6}>
                <BackupCodesView codes={codes} />
              </Grid.Column>
            </Grid>
          </Stack>
        </>
      );
  };

  render() {
    const { open, activeCount, error, codes, pending, onClose } = this.props;
    const generatedCodes = codes?.length > 0;
    const hasCodes = activeCount > 0;

    return (
      <Modal open={open} onClose={onClose}>
        <Modal.Header>Multi-Factor Authentication Recovery Codes</Modal.Header>
        <Modal.Content>
          {this.getModalContent({
            generatedCodes,
            hasCodes,
            codes,
            activeCount,
            error
          })}
        </Modal.Content>

        {generatedCodes && (
          <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
          </Modal.Footer>
        )}
        {!generatedCodes && (
          <Modal.Footer>
            <Button
              variant="primary"
              type="submit"
              disabled={pending}
              form="generate-2fa-backup-codes"
            >
              {pending ? (
                <TranslatableText>Generating...</TranslatableText>
              ) : (
                <TranslatableText>Generate</TranslatableText>
              )}
            </Button>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}
