import { coalesce } from './units';

/**
 * @description Clamps value within a range when it drops below a minimum or exceeds a maximum
 * @param {number} value - number to be clamped
 * @param {number} min - minimum floor
 * @param {number} max - maximum ceiling
 * @returns {number}
 */
export const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

/**
 * Linearly interpolates and clamps between two values
 * @param  {number} min
 * @param  {number} max
 * @param  {number} percent
 * @return {number}
 * @example
 *   lerp(10, 20, 0.5)
 *   > 15
 */
export function lerp(min, max, percent) {
  const value = (max - min) * percent + min;
  return clamp(value, min, max);
}

export const sum = (...numbers) => numbers.reduce((acc, number) => acc + coalesce(number, 0), 0);
