import React, { forwardRef } from 'react';
import { RUDDERSTACK_EVENTS } from 'src/helpers/rudderstack';
import track from 'src/helpers/track';

import { getFormData } from './helpers';

function handleSubmit(event: $TODOFIXME): void {
  const formName = event.target.getAttribute('id');
  const formElements = event.target.elements;
  track(RUDDERSTACK_EVENTS.FORM_SUBMITTED, {
    'Form Name': formName,
    ...getFormData(formElements, formName)
  });
}

document.addEventListener('submit', handleSubmit, false);

type FormProps = React.HTMLProps<HTMLFormElement> & {
  children: React.ReactNode;
  id: string;
};

const Form = forwardRef<HTMLFormElement, FormProps>(({ children, ...props }, ref) => (
  /* eslint-disable-next-line react/forbid-elements */
  <form ref={ref} {...props}>
    {children}
  </form>
));

Form.displayName = 'Form';

export { Form };
