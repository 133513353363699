import React, { useCallback } from 'react';

import { Box, Select, Stack, Text, TextField } from 'src/components/matchbox';
import { Heading } from 'src/components/text';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import { metricsAndUnits } from '../constants';
import { useAlertForm } from '../useAlertForm';

export default function Condition() {
  const {
    state: {
      conditionData: { condition, alertThreshold, precision, errors }
    },
    dispatch
  } = useAlertForm();

  const { state: reportOptions, actions } = useAnalyticsReportContext();

  const selectedMetric = reportOptions.metrics[0];
  const displayPlaceholder = metricsAndUnits.find((el) => el.key === selectedMetric)?.placeholder;

  const { refreshReportOptions } = actions;

  const handleConditionChange = useCallback(
    (e) => {
      dispatch({
        type: 'UPDATE_CONDITION_DATA',
        data: { condition: e.target.value === 'Select' ? undefined : e.target.value }
      });
    },
    [dispatch]
  );

  const handleAlertThresholdChange = useCallback(
    (e) => {
      dispatch({
        type: 'UPDATE_CONDITION_DATA',
        data: { alertThreshold: e.target.valueAsNumber }
      });
    },
    [dispatch]
  );

  const handlePrecisionChange = useCallback(
    (e) => {
      refreshReportOptions({ ...reportOptions, precision: e.target.value.toLowerCase() });
      dispatch({
        type: 'UPDATE_CONDITION_DATA',
        data: { precision: e.target.value }
      });
    },
    [dispatch, refreshReportOptions, reportOptions]
  );

  return (
    <Stack>
      <Heading as="h4">Condition Configurations</Heading>
      <Box width="600px">
        <Text fontSize="18px">Define the alert conditions.</Text>
      </Box>
      <Box display="flex">
        <Box display="flex">
          <Box mr="20px" minWidth="140px">
            <Select
              id="comparison"
              label="Comparison"
              required
              onChange={handleConditionChange}
              value={condition}
              error={errors.condition && 'Required'}
              options={[
                'Select',
                {
                  value: 'gt',
                  label: 'Above'
                },
                {
                  value: 'lt',
                  label: 'Below'
                }
              ]}
            />
          </Box>
          <Box mr="20px">
            <TextField
              id="threshold"
              type="number"
              label="Alert Threshold"
              placeholder={displayPlaceholder}
              value={alertThreshold}
              onChange={handleAlertThresholdChange}
              name="threshold"
              error={errors.alertThreshold && 'Required'}
            />
          </Box>
          <Box>
            <Select
              id="precision"
              label="Alert Precision"
              onChange={handlePrecisionChange}
              value={precision}
              options={[
                {
                  value: 'Hour',
                  label: '1 Hour'
                },
                {
                  value: 'Day',
                  label: '1 Day'
                }
              ]}
            />
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
