export const getMessageFilterLabel = (eventType: string): string => {
  const map: { [key: string]: string } = {
    ab_tests: 'contains',
    campaigns: 'contains',
    ip_pools: 'contains',
    mailbox_provider_regions: 'contains',
    mailbox_providers: 'contains',
    reasons: 'contains',
    recipient_domains: 'contains',
    sending_domains: 'contains',
    subjects: 'contains',
    templates: 'contains'
  };

  return map[eventType] || 'is equal to';
};
