import EventEmitter from 'eventemitter3';

const LOGOUT_EVENT = 'LOGOUT';
const REFRESH_EVENT = 'REFRESH';

const SessionEmitter = new EventEmitter();

export default {
  // LOGOUT
  countLogout: () => SessionEmitter.listenerCount(LOGOUT_EVENT),
  emitLogout: () => SessionEmitter.emit(LOGOUT_EVENT),
  onLogout: (fn) => SessionEmitter.on(LOGOUT_EVENT, fn),
  offLogout: (fn) => SessionEmitter.off(LOGOUT_EVENT, fn),

  // REFRESH
  countRefresh: () => SessionEmitter.listenerCount(REFRESH_EVENT),
  emitRefresh: (context) => SessionEmitter.emit(REFRESH_EVENT, context),
  onRefresh: (fn) => SessionEmitter.on(REFRESH_EVENT, fn),
  offRefresh: (fn) => SessionEmitter.off(REFRESH_EVENT, fn)
};
