export function getSendingDomain(domainName) {
  return {
    method: 'GET',
    url: `/v1/sending-domains/${domainName}`
  };
}

export function getSendingDomains() {
  return {
    method: 'GET',
    url: `/v1/sending-domains/`
  };
}
