import React from 'react';
import SparkPost from './SparkPost';
import { Box, UnstyledLink } from 'src/components/matchbox';
import { LINKS } from 'src/constants';
import PartnerLogos from './partners/PartnerLogos';
// eslint-disable-next-line local/no-css-module-import
import styles from './CenteredLogo.module.scss';

const CenteredLogo = ({ showAwsLogo }) => (
  <Box textAlign="center" marginBottom="400">
    {/* note, ExternalLink should be used instead of PageLink because the logo is linking to a
          different domain, our corp website, however, ExternalLink doesn't look good with the
          warning icon */}
    <UnstyledLink to={LINKS.SP_HOME_PAGE} title="SparkPost">
      <SparkPost.Logo className={styles.CenteredLogo} />
    </UnstyledLink>

    {showAwsLogo && <PartnerLogos.AwsMP height={37} width={222} />}
  </Box>
);

export default CenteredLogo;
