/*
Called by updateWebhook. Figures out if the webhooks auth details need to be updated,
then returns those updates if so.
*/

export const PASSWORD_MASK = '********';

export default function resolveAuthUpdates(values, webhook = {}, shouldChangePassword = true) {
  const { auth, basicUser, basicPass, clientId, clientSecret, tokenURL } = values;

  const filterSensitiveData = (values, type) => {
    const data = { ...values };

    if (type === 'basic') {
      if (data.password === PASSWORD_MASK || !shouldChangePassword) delete data.password;
    }

    if (type === 'oauth2') {
      if (data.access_token === PASSWORD_MASK || !shouldChangePassword) delete data.access_token;
    }

    return data;
  };

  switch (auth) {
    case 'basic':
      return {
        auth_credentials: filterSensitiveData(
          {
            username: basicUser,
            password: basicPass
          },
          'basic'
        ),
        auth_type: 'basic'
      };
    case 'oauth2':
      return {
        auth_request_details: {
          url: tokenURL,
          body: filterSensitiveData(
            {
              ...webhook?.auth_request_details?.body,
              client_id: clientId,
              client_secret: clientSecret,
              grant_type: 'client_credentials'
            },
            'oauth2'
          )
        },
        auth_type: 'oauth2'
      };
    default:
      return {
        auth_type: 'none'
      };
  }
}
