import React from 'react';
import { tokens } from '@sparkpost/design-tokens';

const Generic = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 323 226" overflow="inherit">
    <path
      fill={tokens.color_gray_500}
      d="M209.5 101.7c0-3.2-2.6-5.7-5.7-5.7H79.3a5.7 5.7 0 0 0-5.7 5.7v88.8h136v-88.8z"
    />
    <path fill={tokens.color_white} d="M78.5 100.7h126.7v84.7H78.5z" />
    <path
      stroke={tokens.color_gray_300}
      fill={tokens.color_gray_300}
      d="M222.8 192.1v-1.6H60.2v1.6c0 .4.1.9.2 1.3h162.1c.3-.5.3-.9.3-1.3z"
    />
    <path
      fill={tokens.color_gray_500}
      d="M63.7 195.6h155.6c1.5 0 2.7-.9 3.3-2.2H60.5c.5 1.2 1.8 2.2 3.2 2.2zM115.7 190.4h40.8v1.3h-40.8z"
    />
    <path fill={tokens.color_white} d="M78 100.3h127.1v85.3H78z" />
    <path
      stroke={tokens.color_gray_300}
      fill={tokens.color_gray_300}
      d="M319.7 109c.2 12.2-9.4 22.2-21.6 22.5-12.2.2-22.2-9.4-22.5-21.6-.2-12.2 9.4-22.2 21.6-22.5 12.2-.2 22.2 9.5 22.5 21.6z"
    />
    <path
      fill={tokens.color_white}
      d="M313.9 109.1a16.3 16.3 0 1 1-32.6.6 16.3 16.3 0 0 1 32.6-.6z"
    />
    <path
      d="M308.2 109.3c.1 5.8-4.5 10.6-10.3 10.8-5.8.1-10.6-4.5-10.8-10.3-.1-5.8 4.5-10.6 10.3-10.8 5.9-.2 10.7 4.4 10.8 10.3z"
      fill={tokens.color_gray_400}
    />
    <path
      fill={tokens.color_white}
      d="M302.5 109.4c.1 2.7-2.1 4.9-4.7 4.9s-4.9-2.1-4.9-4.7c-.1-2.7 2.1-4.9 4.7-4.9 2.6-.1 4.8 2 4.9 4.7z"
    />
    <path stroke={tokens.color_gray_300} d="M217.1 141.7c.7-.1 1.3-.3 1.9-.4" />
    <path
      d="M224 140c44.6-12.1 49.8-35.8 24.2-43.6-20.8-6.3-10.8-31.3 12.9-41.8"
      fill="none"
      stroke={tokens.color_gray_300}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="3.8644,5.1526"
    />
    <path stroke={tokens.color_gray_300} d="M263.6 53.6c.6-.2 1.2-.5 1.9-.7M26.2 126.5l-1.7-1.1" />
    <path
      d="M20.3 122.5C-9.7 99.8-.4 70.3 26 63.4"
      fill="none"
      stroke={tokens.color_gray_300}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="3.8218,5.0957"
    />
    <path stroke={tokens.color_gray_300} d="M28.5 62.8l2-.3M81.7 60.9c.7 0 1.3.1 2 .2" />
    <path
      d="M88.9 61.7c18.2 2.7 32.3 10.7 29.6 24.8"
      fill="none"
      stroke={tokens.color_gray_300}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="3.9495,5.2661"
    />
    <path stroke={tokens.color_gray_300} d="M117.9 89.1c-.2.6-.4 1.2-.7 1.9" />
    <path
      fill={tokens.color_brand_orange}
      d="M126.1 154.5c-3.1 0-5.7 2.2-6.3 5.1h-2.9a6.49 6.49 0 0 0-12.8 1.4 6.49 6.49 0 0 0 12.8 1.4h2.9a6.49 6.49 0 0 0 12.8-1.4c0-3.7-2.9-6.5-6.5-6.5zm-15.6 10.4c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm15.6 0c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zM119.5 171.9H117c-.5 0-1-.4-1-1s.4-1 1-1h2.5c.5 0 1 .4 1 1s-.4 1-1 1zM126.8 136.2c-2.9 2.2-3.5 6.1-3.6 8.7-4.6-5.3 8.5-21-6.4-30.7 8.2 10.6-8.6 21.1-12.4 35.7-.2.9-.4 1.8-.5 2.8 1.3-2.2 6.6-4.9 11.3-2 7.4 4.6 12.2-3.2 16 2-.1-1-.3-1.9-.5-2.8-1.6-6.2-5.3-9.3-3.9-13.7z"
    />
    <ellipse
      stroke={tokens.color_gray_300}
      fill={tokens.color_gray_300}
      cx="85.5"
      cy="16.2"
      rx="1.9"
      ry="2.1"
    />
    <ellipse
      stroke={tokens.color_gray_300}
      fill={tokens.color_gray_300}
      cx="126"
      cy="13.1"
      rx="1.9"
      ry="2.1"
    />
    <path
      stroke={tokens.color_gray_300}
      fill={tokens.color_gray_300}
      d="M146.3 29.3L126 13.8l-20.2 15.4-20.2-12.4-20 12.2-.5-.9 20.5-12.5L105.8 28 126 12.5l20.2 15.4 23.9-23.4.6.8z"
    />
    <path
      fill={tokens.color_gray_300}
      stroke={tokens.color_gray_300}
      strokeMiterlimit="10"
      d="M174.4.9L166.6 4l3.5 1.2.8 3.8z"
    />
    <path stroke={tokens.color_blue_400} d="M4.4 207.8h85.7" />
    <path stroke={tokens.color_gray_400} d="M102 207.8h128.2" />
    <path stroke={tokens.color_blue_400} d="M26.1 213.5h118.7" />
    <path stroke={tokens.color_gray_400} d="M156.6 213.5h79.9" />
    <path stroke={tokens.color_blue_400} d="M43.8 219.2H196" />
    <path stroke={tokens.color_gray_400} d="M260.5 219.2h61.9" />
    <path stroke={tokens.color_blue_400} d="M.2 224.9h141.6" />
    <path stroke={tokens.color_gray_400} d="M206.3 224.9h93.3" />
    <g>
      <path
        strokeLinejoin="round"
        stroke={tokens.color_blue_700}
        fill={tokens.color_white}
        d="M312.5 155l-40.4-8.7c-.5-.1-1 .2-1.1.7-.7 3.1.5 5.3 2.5 7.8 3.1 3.7 6.1 7.4 9.1 11.1 1.2 1.5 3.3 4.6 5.2 5 1.9.4 5.1-1.5 6.8-2.4l12.9-6.3c2.1-1 4.8-2.7 5.3-5 .3-.8.7-1.9-.3-2.2z"
      />
      <path
        strokeLinejoin="round"
        stroke={tokens.color_blue_700}
        fill={tokens.color_white}
        d="M311.6 163.2c-.7.5-1.5 1-2.3 1.4-4.6 2.2-9.2 4.4-13.7 6.7-2.4 1.3-5.4 2.8-8.3 2.2-2.9-.6-4.9-3.3-6.6-5.5-3.1-4-6.4-7.9-9.7-11.8-.6-.7-1.1-1.4-1.5-2.2l-4.4 20.3c-.1.5.2 1 .7 1.1l40.4 8.7c.5.1 1-.2 1.1-.7l4.3-20.2z"
      />
      <g>
        <path
          fill={tokens.color_white}
          d="M257.9 196.8c-1.8 0-3.2-1.5-3.2-3.2v-24.7c0-1.8 1.5-3.2 3.2-3.2h36.4c1.8 0 3.2 1.5 3.2 3.2v24.7c0 1.8-1.5 3.2-3.2 3.2h-36.4z"
        />
        <path
          fill={tokens.color_white}
          stroke={tokens.color_green_600}
          d="M296.8 166.3h-41.3c-.5 0-.9.4-.9.9 0 3.2 1.6 5.1 4.1 7.1 3.8 2.9 7.5 5.9 11.2 8.9 1.5 1.2 4.2 3.8 6.1 3.8 2 0 4.7-2.6 6.1-3.8 3.7-3 7.5-5.9 11.2-8.9 1.8-1.4 4.1-3.7 4.1-6 .3-.7.5-2-.6-2z"
        />
        <path
          strokeLinejoin="round"
          fill={tokens.color_white}
          stroke={tokens.color_green_600}
          d="M297.7 174.5c-.6.7-1.2 1.3-1.9 1.9-4 3.1-8 6.2-11.9 9.5-2.1 1.8-4.7 3.9-7.6 3.9-2.9 0-5.5-2.2-7.6-3.9-3.9-3.3-7.9-6.4-11.9-9.5-.7-.6-1.3-1.2-1.9-1.9v20.7c0 .5.4.9.9.9h41.3c.5 0 .9-.4.9-.9v-20.7z"
        />
      </g>
    </g>
    <g>
      <path
        fill={tokens.color_white}
        d="M15.5 163.9c-2 0-3.7-1.6-3.7-3.7v-28c0-2 1.6-3.7 3.7-3.7h41.3c2 0 3.7 1.6 3.7 3.7v28c0 2-1.6 3.7-3.7 3.7H15.5z"
      />
      <path
        strokeLinejoin="round"
        stroke={tokens.color_blue_700}
        fill={tokens.color_white}
        d="M56.8 131.3H15.5c-.5 0-.9.4-.9.9 0 3.2 1.6 5.1 4.1 7.1 3.8 2.9 7.5 5.9 11.2 8.9 1.5 1.2 4.2 3.8 6.1 3.8 2 0 4.7-2.6 6.1-3.8 3.7-3 7.5-5.9 11.2-8.9 1.8-1.4 4.1-3.7 4.1-6 .3-.7.5-2-.6-2z"
      />
      <path
        strokeLinejoin="round"
        stroke={tokens.color_blue_700}
        fill={tokens.color_white}
        d="M57.7 139.5c-.6.7-1.2 1.3-1.9 1.9-4 3.1-8 6.2-11.9 9.5-2.1 1.8-4.7 3.9-7.6 3.9-2.9 0-5.5-2.2-7.6-3.9-3.9-3.3-7.9-6.4-11.9-9.5-.7-.6-1.3-1.2-1.9-1.9v20.7c0 .5.4.9.9.9h41.3c.5 0 .9-.4.9-.9v-20.7z"
      />
    </g>
    <g>
      <path
        stroke={tokens.color_gray_300}
        fill={tokens.color_gray_300}
        d="M179.9 144.2a6.6 6.6 0 1 0 6.6 6.6c.1-3.6-2.9-6.6-6.6-6.6zm0 12.7c-3.4 0-6.1-2.7-6.1-6.1 0-3.4 2.7-6.1 6.1-6.1 3.4 0 6.1 2.7 6.1 6.1 0 3.4-2.7 6.1-6.1 6.1z"
      />
      <path
        stroke={tokens.color_gray_300}
        fill={tokens.color_gray_300}
        d="M189.6 150.7c0-.8 1.1-1.5 2.7-1.9-.1-.9-.4-1.8-.7-2.7-1.6.5-2.9.4-3.3-.3-.4-.7.2-1.8 1.4-3-.6-.7-1.2-1.4-2-1.9-1.1 1.2-2.3 1.7-2.9 1.4-.7-.4-.8-1.7-.3-3.2-.8-.3-1.7-.6-2.7-.7-.4 1.6-1.1 2.6-1.9 2.6-.8 0-1.5-1.1-1.9-2.6-.9.1-1.8.4-2.7.7.5 1.6.4 2.8-.3 3.2-.7.4-1.8-.2-3-1.4-.7.6-1.4 1.2-2 1.9 1.2 1.1 1.8 2.3 1.4 3-.4.7-1.7.8-3.3.3-.3.8-.6 1.7-.7 2.7 1.6.4 2.7 1.1 2.7 1.9 0 .8-1.1 1.5-2.8 1.9.1.9.4 1.8.7 2.7 1.6-.5 3-.4 3.4.3.4.7-.2 1.9-1.5 3.1.6.7 1.2 1.4 1.9 2 1.2-1.3 2.4-1.9 3.1-1.5.7.4.8 1.7.3 3.4.8.3 1.7.6 2.7.7.4-1.7 1.1-2.8 1.9-2.8.8 0 1.5 1.1 1.9 2.8.9-.1 1.8-.4 2.7-.7-.5-1.6-.4-3 .3-3.4.7-.4 1.9.2 3 1.4.7-.6 1.4-1.2 1.9-2-1.2-1.2-1.8-2.3-1.4-3 .4-.7 1.7-.8 3.3-.3.3-.8.6-1.7.7-2.7-1.5-.4-2.6-1.1-2.6-1.9zm-9.7 7.5c-4.1 0-7.4-3.3-7.4-7.4 0-4.1 3.3-7.4 7.4-7.4 4.1 0 7.4 3.3 7.4 7.4 0 4.1-3.3 7.4-7.4 7.4z"
      />
    </g>
    <g>
      <path
        strokeWidth="2"
        fill={tokens.color_gray_400}
        stroke={tokens.color_gray_400}
        d="M156.1 125.8a7.81 7.81 0 0 0 0 15.6 7.81 7.81 0 0 0 0-15.6zm0 15c-3.9 0-7.1-3.2-7.1-7.1 0-3.9 3.2-7.1 7.1-7.1 3.9 0 7.1 3.2 7.1 7.1 0 3.9-3.2 7.1-7.1 7.1z"
      />
      <path
        fill={tokens.color_gray_400}
        stroke={tokens.color_gray_400}
        d="M167.5 133.5c0-.9 1.3-1.8 3.1-2.2-.2-1.1-.5-2.1-.8-3.1-1.9.5-3.3.5-3.8-.4-.5-.8.2-2.1 1.6-3.5-.7-.8-1.4-1.6-2.3-2.3-1.3 1.4-2.7 2-3.5 1.6-.8-.5-.9-1.9-.4-3.8-1-.4-2-.7-3.1-.8-.5 1.9-1.3 3.1-2.2 3.1-.9 0-1.7-1.2-2.2-3.1-1.1.2-2.1.4-3.1.8.5 1.8.5 3.3-.4 3.8-.8.5-2.2-.2-3.5-1.6-.8.7-1.6 1.4-2.3 2.3 1.4 1.3 2.1 2.7 1.6 3.5-.5.8-2 .9-3.9.3-.4 1-.7 2-.8 3.1 1.9.5 3.2 1.3 3.2 2.2 0 1-1.3 1.8-3.2 2.2.2 1.1.4 2.1.8 3.1 1.9-.6 3.5-.5 3.9.3.5.8-.2 2.2-1.7 3.6.7.8 1.4 1.6 2.3 2.3 1.4-1.5 2.8-2.2 3.6-1.7.8.5.9 2 .3 4 1 .4 2 .7 3.1.8.5-2 1.3-3.3 2.2-3.3 1 0 1.8 1.3 2.2 3.3 1.1-.2 2.1-.5 3.1-.8-.6-1.9-.5-3.5.3-3.9.8-.5 2.2.2 3.6 1.7.8-.7 1.6-1.5 2.3-2.3-1.4-1.4-2.1-2.7-1.7-3.5.5-.8 2-.9 3.9-.3.4-1 .7-2 .8-3.1-1.7-.5-3-1.3-3-2.3zm-11.4 8.8a8.6 8.6 0 1 1 0-17.2 8.6 8.6 0 0 1 0 17.2z"
      />
    </g>
    <g>
      <circle fill={tokens.color_white} cx="57.5" cy="160.7" r="8.9" />
      <path
        stroke={tokens.color_blue_700}
        fill={tokens.color_blue_700}
        d="M57.5 152a8.7 8.7 0 1 1-.01 17.41A8.7 8.7 0 0 1 57.5 152m0-.5c-5.1 0-9.2 4.1-9.2 9.2s4.1 9.2 9.2 9.2 9.2-4.1 9.2-9.2-4.1-9.2-9.2-9.2z"
      />
      <path
        stroke={tokens.color_blue_700}
        fill={tokens.color_blue_700}
        d="M63.7 158.7c0 .2-.1.4-.2.5l-6.5 6.5c-.1.1-.3.2-.5.2s-.4-.1-.5-.2l-4.3-4.3c-.1-.1-.2-.3-.2-.5s.1-.4.2-.5l1.1-1.1c.1-.1.3-.2.5-.2s.4.1.5.2l2.7 2.7 4.9-4.8c.1-.1.3-.2.5-.2s.4.1.5.2l1.1 1.1c.1 0 .2.2.2.4z"
      />
    </g>
    <g>
      <path
        fill={tokens.color_green_600}
        d="M41.1 78.1c-.8 0-1.5-.3-2.1-.9-.6-.6-.9-1.3-.9-2.1V47.2c0-.8.3-1.5.9-2.1.6-.6 1.3-.9 2.1-.9h5.3v-2.5c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3s.5.1.7.3c.2.2.3.4.3.7v2.5h7.4v-2.5c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3s.5.1.7.3c.2.2.3.4.3.7v2.5h7.4v-2.5c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3s.5.1.7.3c.2.2.3.4.3.7v2.5h5.3c.8 0 1.6.3 2.1.9.6.6.9 1.3.9 2.1v27.9c0 .8-.3 1.5-.9 2.1-.6.6-1.3.9-2.1.9H41.1zm0-31.9c-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7v27.9c0 .3.1.5.3.7.2.2.4.3.7.3h31.5c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7V47.2c0-.3-.1-.5-.3-.7a.9.9 0 0 0-.7-.3h-5.3v2.5c0 .3-.1.5-.3.7s-.4.3-.7.3-.5-.1-.7-.3a.9.9 0 0 1-.3-.7v-2.5h-7.4v2.5c0 .3-.1.5-.3.7s-.4.3-.7.3-.5-.1-.7-.3a.9.9 0 0 1-.3-.7v-2.5h-7.4v2.5c0 .3-.1.5-.3.7s-.4.3-.7.3-.5-.1-.7-.3a.9.9 0 0 1-.3-.7v-2.5h-5.4z"
      />
      <path
        fill={tokens.color_white}
        d="M66.3 40.8c.3 0 .4.1.6.2s.2.4.2.6V44.3h5.5c.8 0 1.5.3 2 .8.6.6.8 1.2.8 2V75c0 .8-.3 1.5-.8 2-.6.6-1.2.8-2 .8H41.1c-.8 0-1.5-.3-2-.8-.6-.6-.8-1.2-.8-2V47.2c0-.8.3-1.5.8-2 .6-.6 1.2-.8 2-.8h5.5v-2.8c0-.3.1-.4.2-.6s.4-.2.6-.2.4.1.6.2.2.4.2.6V44.3H56V41.6c0-.3.1-.4.2-.6s.4-.2.6-.2.4.1.6.2.2.4.2.6V44.3h7.9V41.6c0-.3.1-.4.2-.6s.3-.2.6-.2m0 8.7c-.3 0-.4-.1-.6-.2s-.2-.4-.2-.6V46h-7.9V48.7c0 .3-.1.4-.2.6s-.4.2-.6.2-.4-.1-.6-.2-.2-.3-.2-.6V46h-7.9V48.7c0 .3-.1.4-.2.6s-.4.2-.6.2-.4-.1-.6-.2-.2-.4-.2-.6V46H41c-.3 0-.6.1-.8.3s-.3.5-.3.8V75c0 .3.1.6.3.8.2.2.5.3.8.3h31.5c.3 0 .6-.1.8-.3.2-.2.3-.5.3-.8V47.2c0-.3-.1-.6-.3-.8-.2-.2-.5-.3-.8-.3H67V48.8c0 .3-.1.4-.2.6s-.3.1-.5.1m0-9.1c-.3 0-.6.1-.8.3s-.3.5-.3.8V44H58v-2.4c0-.3-.1-.6-.3-.8s-.5-.3-.8-.3-.6.1-.8.3-.3.5-.3.8V44h-7.1v-2.4c0-.3-.1-.6-.3-.8s-.5-.3-.8-.3-.6.1-.8.3-.3.5-.3.8V44h-5.1c-.9 0-1.6.3-2.3.9-.6.6-.9 1.4-.9 2.3v27.9c0 .9.3 1.6.9 2.3.6.6 1.4.9 2.3.9h31.5c.9 0 1.6-.3 2.3-.9.6-.6.9-1.4.9-2.3V47.2c0-.9-.3-1.6-.9-2.3-.6-.6-1.4-.9-2.3-.9h-5.1v-2.4c0-.3-.1-.6-.3-.8s-.9-.4-1.2-.4zm0 9.5c.3 0 .6-.1.8-.3s.3-.5.3-.8v-2.4h5.1c.2 0 .4.1.6.2s.2.3.2.6v27.9c0 .2-.1.4-.2.6s-.3.2-.6.2H41.1c-.2 0-.4-.1-.6-.2-.1-.1-.2-.3-.2-.6V47.2c0-.2.1-.4.2-.6.1-.1.3-.2.6-.2h5.1v2.4c0 .3.1.6.3.8s.5.3.9.3c.3 0 .6-.1.8-.3s.3-.5.3-.8v-2.4h7.1v2.4c0 .3.1.6.3.8s.5.3.9.3c.3 0 .6-.1.8-.3s.4-.6.4-.9v-2.4h7.1v2.4c0 .3.1.6.3.8s.5.4.9.4z"
      />
      <path
        fill={tokens.color_green_600}
        d="M46.2 70v-3.6h4.7V70h-4.7zM46.2 64.1v-3.6h4.7v3.6h-4.7zM46.2 58.2v-3.6h4.7v3.6h-4.7zM54.4 66.4h4.7v3.5h-4.7zM54.4 60.5h4.7V64h-4.7zM54.4 54.6h4.7v3.5h-4.7zM62.7 66.4h4.7v3.5h-4.7zM62.7 60.5h4.7V64h-4.7zM62.7 54.6h4.7v3.5h-4.7z"
      />
    </g>
    <g>
      <path
        fill={tokens.color_green_600}
        d="M304.8 16.8L298.2 56c-.1.5-.4.9-.8 1.1-.2.1-.5.2-.8.2-.2 0-.4-.1-.6-.1l-13.4-5.5L275 60c-.3.4-.7.5-1.2.5-.2 0-.4 0-.6-.1-.6-.3-1-.9-1-1.5V47.4l-12-4.9c-.6-.2-1-.8-1-1.4-.1-.6.3-1.2.8-1.5l42.4-24.5c.5-.3 1.2-.3 1.7.1s.8 1 .7 1.6z"
      />
      <path fill={tokens.color_white} d="M301 19.7l-36.5 21 8.5 3.5L295 28l-12.2 20.3 12.6 5.1z" />
    </g>
    <g>
      <path
        stroke={tokens.color_blue_700}
        fill={tokens.color_blue_700}
        d="M169.5 64.8v-.6-1-1-.6-.3a20.4 20.4 0 0 0-20.2-18.5 20.3 20.3 0 0 0-20.1 17.6v.1c0 .3-.1.5-.1.8v3.8c0 .3.1.5.1.8v.1c1.3 9.9 9.9 17.6 20.1 17.6 10.6 0 19.3-8.1 20.2-18.5v-.3z"
      />
      <path
        fill={tokens.color_white}
        d="M165.8 71.5c-.1.1-.1.3-.2.4-.1.1-.1.2-.2.4-.1.1-.2.3-.2.4-.1.1-.1.2-.2.4-.1.1-.2.3-.2.4-.1.1-.2.2-.2.3-.1.1-.2.3-.3.4l-.3.3c-.1.1-.2.2-.3.4l-.3.3-.3.3-.3.3-.3.3-.3.3-.3.3-.3.3c-.1.1-.2.2-.4.3l-.3.3c-.1.1-.2.2-.4.3-.1.1-.2.2-.3.2-.1.1-.3.2-.4.3-.1.1-.2.2-.3.2-.1.1-.3.2-.4.3-.1.1-.2.1-.3.2-.1.1-.3.2-.4.2-.1.1-.2.1-.4.2-.1.1-.3.1-.4.2-.1.1-.2.1-.4.2-.1.1-.3.1-.4.2-.1.1-.2.1-.4.2s-.3.1-.5.2c-.1 0-.2.1-.4.1h-.1c2.2-2.2 4-5.7 5-9.7h5.6c0 .1-.1.1-.1.2.1.2 0 .3-.1.4zM155.9 45.9c.2.1.3.1.5.2.1 0 .2.1.4.2s.3.1.4.2c.1.1.2.1.4.2.1.1.3.1.4.2.1.1.2.1.4.2.1.1.3.2.4.2.1.1.2.1.3.2.1.1.3.2.4.3.1.1.2.2.3.2.1.1.3.2.4.3.1.1.2.2.3.2.1.1.2.2.4.3l.3.3c.1.1.2.2.4.3l.3.3.3.3.3.3.3.3.3.3.3.3.3.3c.1.1.2.2.3.4l.3.3c.1.1.2.2.3.4.1.1.2.2.2.3.1.1.2.3.2.4.1.1.1.2.2.4.1.1.2.3.2.4.1.1.1.2.2.4.1.1.1.3.2.4.1.1.1.3.2.4 0 .1.1.1.1.2h-5.6c-1-4.1-2.8-7.5-5-9.7h.1c.1 0 .2.1.3.1zM159.6 63.2c0 2.1-.2 4.1-.6 6h-8.9V57.3h8.9c.4 1.9.6 3.9.6 5.9zM158.7 71c-1.5 5.9-4.7 10.1-8.5 10.7V71h8.5zM150.2 55.5V44.8c3.8.6 7 4.8 8.5 10.7h-8.5zM167.8 64.1v.8c0 .2 0 .4-.1.6-.2 1.2-.4 2.5-.9 3.7h-5.9c.3-1.9.5-3.9.5-6s-.2-4.1-.5-6h5.9c.4 1.2.7 2.4.9 3.7 0 .2 0 .4.1.6v2.6z"
      />
      <g>
        <path
          fill={tokens.color_white}
          d="M130.9 65.6v-.1c0-.2 0-.4-.1-.6v-.4-.4-.8-.8-.4-.4c0-.2 0-.4.1-.6V61c.2-1.2.4-2.4.8-3.6h5.9c-.3 1.9-.5 3.9-.5 6s.2 4.1.5 6h-5.9c-.4-1.4-.7-2.6-.8-3.8zM138.9 63.2c0-2.1.2-4.1.6-6h8.9v11.9h-8.9c-.4-1.8-.6-3.8-.6-5.9zM148.4 44.8v10.7h-8.5c1.5-5.9 4.7-10.1 8.5-10.7zM148.4 71v10.7c-3.8-.6-7-4.8-8.5-10.7h8.5zM143.1 45.8c-2.2 2.2-4 5.7-5 9.7h-5.6c2-4.5 5.8-8.1 10.6-9.7zM132.4 71h5.6c1 4.1 2.8 7.5 5 9.7-4.7-1.7-8.5-5.2-10.6-9.7z"
        />
      </g>
    </g>
    <path
      fill={tokens.color_gray_500}
      d="M319.2 93.5l3.8-3.9-4.3-1.1-1.1-4.3-3.8 3.8.9 3.5-13 12.9-1.8-3.6-1.2 4.4-1.2 4.5 4.5-1.2 4.4-1.2-3.6-1.8 12.9-13z"
    />
    <path
      fill={tokens.color_gray_400}
      stroke={tokens.color_gray_400}
      d="M221.6 47.9c-1.1-.9-2.6-1.4-4-1.4-.3 0-.7 0-1 .1-.8-.5-1.7-.8-2.7-.8V45a11.71 11.71 0 0 0-7.7-20.5c-6.4 0-11.7 5.3-11.7 11.7 0 5.1 3.3 9.5 7.9 11.1v3.2c-.9-.3-1.9-.5-2.9-.5-3.8 0-5.8 2.9-5.8 6.5 0 3 3.9 4 6 5.3 1.1.7 2 1.4 2.9 2.3.7.7 2.7 2.2 2.7 3.3v6.5c0 1.6 1.3 2.9 2.9 2.9h14.4c1.6 0 2.9-1.3 2.9-2.9v-6.5c0-1.4.8-3.7 1.3-5 .9-2.3 1.6-4.7 1.6-7.3 0-4.3-2.5-7.2-6.8-7.2zm-24.4-11.7c0-5 4-9 9-9a8.96 8.96 0 0 1 7.6 13.8c-.5-2.7-2.9-4.8-5.7-4.8-3.1 0-5.7 2.7-5.7 5.8v2.3a8.95 8.95 0 0 1-5.2-8.1zm13.1 37.5c-.8 0-1.4-.7-1.4-1.4s.7-1.4 1.4-1.4 1.4.7 1.4 1.4-.6 1.4-1.4 1.4zm12.3-6.5v.8h-14.4v-.7c0-2.4-2-4-3.7-5.4-1-.9-2.1-1.8-3.3-2.5-.5-.3-1-.6-1.5-.8-.5-.2-3.1-1.4-3.1-2 0-1.9.7-3.6 2.9-3.6 2.9 0 4.4 2.2 5.8 2.2V42c0-1.5 1.4-2.9 2.9-2.9 1.6 0 2.9 1.3 2.9 2.9v7.5c.5-.5 1.6-.8 2.3-.8 1.1 0 2 .4 2.7 1.2.5-.3 1-.4 1.6-.4 1 0 2.3.5 2.8 1.5.4-.1.8-.1 1.3-.1 2.7 0 3.8 1.7 3.8 4.3-.1 4.2-3 8.1-3 12z"
    />
  </svg>
);

export default Generic;
