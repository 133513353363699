import React from 'react';
import { LabelValue, Panel } from 'src/components/matchbox';

export default function SubjectInfo({ id }) {
  const subject = id;
  return (
    <Panel.Section>
      <LabelValue>
        <LabelValue.Label>Subject</LabelValue.Label>
        <LabelValue.Value>{subject}</LabelValue.Value>
      </LabelValue>
    </Panel.Section>
  );
}
