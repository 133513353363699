import classNames from 'classnames';
import React, { useState } from 'react';
import Transition from 'react-transition-group/Transition';
// eslint-disable-next-line local/no-css-module-import
import styles from './Animator.module.scss';

type TransitionNodeProps = HTMLElement | null;

function Animator({ children, in: inProp }: $TODOFIXME) {
  const [node, setNode] = useState<TransitionNodeProps>(null);

  const transitionNode = (node: TransitionNodeProps) => {
    setNode(node);
  };

  return (
    <Transition in={inProp} timeout={{ enter: 150, exit: 100 }}>
      {(state) => {
        const classes = classNames(styles.Animator, state && styles[state]);
        const height = node && state === 'exiting' ? node.getBoundingClientRect().height : 'auto';

        /* eslint-disable react/forbid-dom-props */
        return (
          <div className={classes} style={{ height }} ref={transitionNode}>
            {children}
          </div>
        );
      }}
    </Transition>
  );
}

export default Animator;
