import React from 'react';
import { Layout, Text } from 'src/components/matchbox';
import useResourceDetailsContext from '../../hooks/useResourceDetailsContext';
import { useSparkPostQuery } from 'src/hooks';
import { LINKS_BY_DOMAIN_METRIC_KEYS } from '@sparkpost/report-builder/config';
import { getEngagement } from 'src/helpers/api/metrics';
import { apiParams as API_PARAMS, getResourceFromPageType } from '../../constants/resourceDetails';
import { LinkEngagementTableContainer } from './LinkEngagementTable';

export default function LinkEngagement({ deliverabilityData, deliverabilityDataStatus }) {
  const { filters, match: { params: { type, id } = {} } = {} } = useResourceDetailsContext();

  let linkArgs = {
    from: filters.from,
    to: filters.to,
    metrics: LINKS_BY_DOMAIN_METRIC_KEYS.join(','),
    [API_PARAMS[type]]: [id].join(',')
  };

  const linksQuery = useSparkPostQuery(() => getEngagement(linkArgs));

  const totalClicks = deliverabilityData?.[0]?.count_clicked;

  return (
    <div data-id="link-engagement">
      <Layout>
        <Layout.Section annotated>
          <Layout.SectionTitle>Link Engagement</Layout.SectionTitle>
          <Text color="gray.700" fontSize="200">
            {`Describes how links in email performed under this ${getResourceFromPageType(type)}.`}
          </Text>
        </Layout.Section>
        <Layout.Section>
          <LinkEngagementTableContainer
            links={linksQuery.data}
            totalClicks={totalClicks}
            loading={deliverabilityDataStatus === 'loading' || linksQuery.status === 'loading'}
            isError={deliverabilityDataStatus === 'error' || linksQuery.status === 'error'}
          />
        </Layout.Section>
      </Layout>
    </div>
  );
}
