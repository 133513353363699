import React, { useCallback, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { defaultStore } from 'src';
import { clearCSV, getMessageEventsCSV } from 'src/actions/messageEvents';
import { isAccountUiOptionSet } from 'src/helpers/conditions/account';
import { download, formatToCsv } from 'src/helpers/downloading';
import { selectMessageEventsCSV } from 'src/selectors/messageEvents';

// CONSTANTS
import { DEFAULT_PER_PAGE_BUTTONS } from 'src/constants';

// COMPONENTS
import { CursorPaging, PerPageButtons } from 'src/components';
import { Box, Button } from 'src/components/matchbox';
import { TranslatableText } from 'src/components/text';

// TYPES

type LocalComponentProps = {
  nextDisabled: boolean;
  onFirstPageClick: () => void;
  onPageChange: (currentPage: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  pageIndex: number;
  pageSize: number;
  previousDisabled: boolean;
  totalCount: number;
};

type ReduxStateProps = typeof defaultStore.getState;

type ConnectedProps = {
  eventsCSV: $TODOFIXME[];
  eventsCSVLoading: boolean;
  search: string;
  shouldHideCSVDownload: boolean;
};

// All Props
type FooterProps = ConnectedProps & LocalComponentProps;

const Footer = ({
  pageIndex,
  onPageChange,
  previousDisabled,
  nextDisabled,
  onFirstPageClick,
  pageSize,
  totalCount,
  onPageSizeChange,
  ...otherProps
}: FooterProps): JSX.Element => {
  const { eventsCSVLoading, shouldHideCSVDownload, eventsCSV, search } = otherProps;

  const labelCSV = eventsCSVLoading ? 'Saving CSV...' : 'Save as CSV';
  const mounted = useRef<boolean>();
  const dispatch = useDispatch();

  const getCSV = () => dispatch(getMessageEventsCSV(search));
  const handleClearCSV = useCallback(() => dispatch(clearCSV()), [dispatch]);

  const downloadCSV = useCallback(() => {
    const url = formatToCsv({ data: eventsCSV, returnBlob: true });
    const now = Math.floor(Date.now() / 1000);
    download({ name: `sparkpost-csv-${now}.csv`, url });
    handleClearCSV();
  }, [eventsCSV, handleClearCSV]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (eventsCSV.length) {
        downloadCSV();
      }
    }
  }, [downloadCSV, eventsCSV.length]);

  return (
    <Box mt={300} display="flex" alignItems="center" justifyContent="space-between">
      <Box>
        <CursorPaging
          currentPage={pageIndex + 1}
          handlePageChange={onPageChange}
          previousDisabled={previousDisabled}
          nextDisabled={nextDisabled}
          handleFirstPage={onFirstPageClick}
          perPage={pageSize}
          totalCount={totalCount}
        />
      </Box>
      <Box display="flex">
        <PerPageButtons
          totalCount={totalCount}
          onPerPageChange={onPageSizeChange}
          perPageButtons={DEFAULT_PER_PAGE_BUTTONS}
          perPage={pageSize}
        />
        {!shouldHideCSVDownload && (
          <Button onClick={getCSV} disabled={eventsCSVLoading} variant="outline" useMatchboxVariant>
            <TranslatableText>{labelCSV}</TranslatableText>
          </Button>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: ReduxStateProps) => {
  const eventsCSV = selectMessageEventsCSV(state);
  const { messageEvents } = state;
  const { search, eventsCSVLoading } = messageEvents;
  return {
    search,
    eventsCSV,
    eventsCSVLoading,
    shouldHideCSVDownload: isAccountUiOptionSet('hide_events_csv_download')(state)
  };
};

export default connect<ConnectedProps, LocalComponentProps, ReduxStateProps>(mapStateToProps)(
  Footer
);
