import { RadioCard as MatchboxRadioCard } from '@sparkpost/matchbox';

/**
 * Matchbox RadioCard component.
 * @see https://design.sparkpost.com/components/radiocard/
 */
const RadioCard = MatchboxRadioCard;

RadioCard.displayName = 'RadioCard';
RadioCard.Group.displayName = 'RadioCard.Group';

export default RadioCard;
