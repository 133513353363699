import _ from 'lodash';
import { intervalToDuration, differenceInMinutes, addMinutes } from 'date-fns';
import { divide } from '@sparkpost/report-builder/helpers/math';

/**
 * @description Campaign retrieved via `/v1/inline-seeds/status`
 * @typedef Campaign
 * @type {object}
 * @property {string} campaign - Campaign ID
 * @property {string} sending_domain - Sending domain associated with the campaign
 * @property {number} count - Number of messages seen
 * @property {number} threshold - Number of messages seen for a campaign before seed sending starts.
 * @property {number} seed_start - Epoch Unix timestamp at which seed sending starts
 * @property {number} duration - Number of minutes after threshold is met over which to spread out seed messages.
 */

/**
 * @description Table-friendly data derived from data requested via `/v1/inline-seeds/status`
 * @typedef TableFriendlyCampaign
 * @type {object}
 * @property {string} campaignId - Campaign ID
 * @property {string} status - Status of the campaign. 'pending' | 'active' | undefined
 * @property {number} thresholdPercentage - Float number, e.g., 95.4
 * @property {number} timeRemaining - Number of minutes remaining until seeding is complete
 */

/**
 * @description Re-formats raw APi response from `/api/v1/inline-seeds/status` for consumption by a data table
 * @param {Array<Campaign>} seedingActivity
 * @returns {Array<TableFriendlyCampaign>}
 */
export function toTableFriendly(seedingActivity) {
  if (_.isEmpty(seedingActivity)) return [];

  return seedingActivity.map((campaign) => {
    return {
      campaignId: campaign.campaign,
      status: getCampaignStatus(campaign),
      thresholdPercentage: divide(campaign.count, campaign.threshold),
      timeRemaining: getTimeRemaining({
        seedStart: campaign.seed_start,
        duration: campaign.duration
      })
    };
  });
}

/**
 * @param {Object} args
 * @param {Date} args.currentDate - Current date for the end-user
 * @param {string} args.seedStart - Date timestamp. See Campaign.seed_start
 * @param {string} args.duration - number of hours. See Campaign.duration
 * @returns {number} remaining time in minutes until the campaign ends
 */
export function getTimeRemaining({ currentDate = new Date(), seedStart, duration }) {
  // The start date can be `null` if there is no time remaining, so best to just return early if so
  if (!seedStart) return 0;

  const startDate = new Date(seedStart);
  const campaignEnd = addMinutes(startDate, duration);
  const remainingTime = differenceInMinutes(campaignEnd, currentDate);

  // If the value is negative, show no remaining time
  if (Math.sign(remainingTime) !== 1) return 0;

  return remainingTime;
}

/**
 * @description Returns digital formatted time string, e.g., 9:35 from a number of passed-in minutes
 * @param {number} remainingTime - remaining time in minutes
 * @see {@link https://sparkpost.atlassian.net/browse/TR-2812} for acceptance criteria defining business logic
 * @returns {string}
 */
export function formatTimeRemaining(remainingTime) {
  const remainingTimeMs = remainingTime * 1000 * 60;
  const duration = intervalToDuration({ start: 0, end: remainingTimeMs });
  const minutes = duration.minutes < 10 ? `0${duration.minutes}` : duration.minutes;
  const formatted = `${duration.hours}:${minutes}`;

  return formatted;
}

/**
 * @param {Campaign} campaign
 * @returns {string} 'active' | 'pending' | undefined
 * @see {@link https://sparkpost.atlassian.net/browse/TR-2812} for acceptance criteria defining business logic
 */
export function getCampaignStatus(campaign) {
  if (_.isNil(campaign.seed_start)) {
    return 'pending';
  }

  if (campaign.count < campaign.threshold) {
    return 'pending';
  }

  if (campaign.count >= campaign.threshold) {
    return 'active';
  }
}
