import React from 'react';
import { Button, Inline, Modal, Stack, Tag, Text } from 'src/components/matchbox';

export function SendingMetricsRemovalModal({
  isModalOpen,
  filter,
  addFilter,
  onClose,
  metricsToRemove = []
}) {
  return (
    <Modal open={isModalOpen}>
      <Modal.Header>Add Filter</Modal.Header>
      <Modal.Content>
        <Stack>
          <Text>
            The sending metrics listed below will automatically be removed if a Campaign (Subject)
            filter is applied.
          </Text>
          <Inline>
            {metricsToRemove.map(({ label }, index) => {
              return <Tag key={`metric_to_remove_${index}`}>{label}</Tag>;
            })}
          </Inline>
        </Stack>
      </Modal.Content>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            addFilter(filter);
            onClose();
          }}
        >
          Add Filter
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}
