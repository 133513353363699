import React, { useState } from 'react';
import { formatToCsv } from 'src/helpers/downloading';
import { getReferenceSeed } from 'src/helpers/seedList';
import { Bold, TranslatableText } from 'src/components/text';
import { DownloadLink } from 'src/components/links';
import { PanelLoading } from 'src/components/loading';
import { Banner, Button, CodeBlock, Panel, Stack, Text } from 'src/components/matchbox';
import { ApiErrorBanner, ButtonWrapper, CopyToClipboard } from 'src/components';

export function ManualSeedListPanel({ seedListQuery }) {
  const [hasInfoBanner, setHasInfoBanner] = useState(true);
  const { status, data: seeds, error, refetch } = seedListQuery;
  const data = useSeedListData(seeds);

  if (status === 'loading') return <PanelLoading />;

  if (status === 'error') {
    return (
      <ApiErrorBanner
        message="Sorry, we seem to have had some trouble loading your seed list."
        errorDetails={error.message}
        reload={refetch}
      />
    );
  }

  return (
    <Panel>
      <Panel.Section>
        <Stack>
          <Text>
            To use this seed list for deliverability data, add the following email addresses to the
            list.
          </Text>

          {hasInfoBanner ? (
            <Banner
              size="small"
              status="info"
              onDismiss={() => setHasInfoBanner(false)}
              data-id="reference-seed-banner"
            >
              <Text>
                <TranslatableText>Make sure the reference email </TranslatableText>

                <Bold>{data.referenceSeed}</Bold>

                <TranslatableText> is the first one in the list.</TranslatableText>
              </Text>
            </Banner>
          ) : null}

          <CodeBlock code={data.friendly} height={256} />
        </Stack>
      </Panel.Section>

      <Panel.Section>
        <ButtonWrapper>
          <CopyToClipboard variant="primary" value={data.copyable}>
            Copy Seed List
          </CopyToClipboard>

          <DownloadLink
            as={Button}
            variant="secondary"
            href={formatToCsv({ data: data.csv, returnBlob: false })}
            download="sparkpost-seed-list.csv"
          >
            Download List
          </DownloadLink>
        </ButtonWrapper>
      </Panel.Section>
    </Panel>
  );
}

/**
 * @description formats for rendering on the seed list settings page
 * @param {Array<Object>} seedList - List of seeds retrieved from the seed list endpoint. See https://developers.sparkpost.com/api/seed-list/#seed-list-get-retrieve-seed-list
 * @returns {Object} object of formatted data properties
 */
function useSeedListData(seeds = []) {
  const referenceSeed = getReferenceSeed(seeds);
  const friendly = seeds.join('\n');
  const copyable = seeds.join(',');
  const csv = seeds.map((address) => ({ 'Seed Address': address }));

  return {
    referenceSeed,
    friendly,
    copyable,
    csv
  };
}
