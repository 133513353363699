import { LabelValue as MatchboxLabelValue } from '@sparkpost/matchbox';

/**
 * Matchbox LabelValue component
 * @see https://design.sparkpost.com/components/labelvalue/
 */
const LabelValue = MatchboxLabelValue;

LabelValue.displayName = 'LabelValue';
LabelValue.Label.displayName = 'LabelValue.Label';
LabelValue.Value.displayName = 'LabelValue.Value';

export default LabelValue;
