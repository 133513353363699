import styled from 'styled-components';
import { Warning } from '@sparkpost/matchbox-icons';

export const WarningIcon = styled(Warning)`
  color: ${(props) => props.theme.colors.red['700']};
  margin-top: -0.4em;
  margin-right: 0.2em;
`;

export const BlockListCountContainer = styled.div`
  margin-left: calc(18px + 0.2em);
`;
