interface GetBackPageProps {
  filtersAppliedToURL: { [key: string]: string[] };
  from: string;
  to: string;
}

const messageEventsDictionary = {
  ab_test_versions: '&ab_test_versions=',
  ab_tests: '&ab_tests=',
  bounce_classes: '&bounce_classes=',
  campaigns: '&campaigns=',
  events: '&events=',
  from_addresses: '&from_addresses=',
  ip_pools: '&ip_pools=',
  mailbox_provider_regions: '&mailbox_provider_regions=',
  mailbox_providers: '&mailbox_providers=',
  messages: '&messages=',
  reasons: '&reasons=',
  recipient_domains: '&recipient_domains=',
  recipients: '&recipients=',
  sending_domains: '&sending_domains=',
  sending_ips: '&sending_ips=',
  subaccounts: '&subaccounts=',
  subjects: '&subjects=',
  templates: '&templates=',
  transmissions: '&transmissions='
};

export const getBackPageParams = ({ from, to, filtersAppliedToURL }: GetBackPageProps): string => {
  const sanitizedFrom = from && new Date(from).toISOString();
  const sanitizedTo = to && new Date(to).toISOString();

  const selectedFilters = Object.keys(filtersAppliedToURL).filter(
    (filterKey) => filtersAppliedToURL[filterKey].length > 0
  );

  const formattedFiltersForBackParams = selectedFilters
    .map((filterKey) =>
      filtersAppliedToURL[filterKey]
        .map((val: string) => `${messageEventsDictionary[filterKey]}${val}`)
        .join('')
    )
    .join('');

  return (
    (sanitizedFrom &&
      sanitizedTo &&
      `?from=${sanitizedFrom}&to=${sanitizedTo}${formattedFiltersForBackParams}`) ||
    ''
  );
};
