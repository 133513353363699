/* eslint-disable local/restrict-translatable-text */
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

// HELPERS
import { formatDate } from '@sparkpost/report-builder/helpers/date';

// ICONS
import { ContentCopy, Search } from '@sparkpost/matchbox-icons';

// CONSTANTS
import { list } from '@sparkpost/report-builder/config';
import { METRICS, OPERATOR_FRIENDLY_NAMES } from 'src/pages/alerts/constants/formConstants';

// COMPONENTS
import { Empty } from 'src/components';
import { ScreenReaderExclude } from 'src/components/a11y';
import Pagination from 'src/components/collection/Pagination';
import { PageLink } from 'src/components/links';
import {
  Box,
  Button,
  Panel,
  ScreenReaderOnly,
  Stack,
  Table,
  Text,
  TextField
} from 'src/components/matchbox';
import { SortSelect } from 'src/components/SortSelect/SortSelect';
import { TranslatableText } from 'src/components/text';
import { Typeahead, TypeaheadItem } from 'src/components/typeahead';

// HOOKS
import { usePageFilters } from 'src/hooks';

// STYLES
import {
  FiltersBar,
  Label,
  MetricsWrapper,
  NotificationTag,
  StyledIcon,
  TableWrapper
} from './styles';

// eslint-disable-next-line lodash/collection-ordering
const metrics = _.orderBy(list, ['category', 'label'], ['asc', 'asc']);

const sortByOptions = [
  {
    label: 'Date Triggered (Newest - Oldest)',
    value: 'last_triggered_desc',
    id: 'last_triggered',
    desc: true
  },
  {
    label: 'Date Triggered (Oldest - Newest)',
    value: 'last_triggered_asc',
    id: 'last_triggered',
    desc: false
  },
  {
    label: 'Date Created (Newest - Oldest)',
    value: 'created_desc',
    id: 'created',
    desc: true
  },
  {
    label: 'Date Created (Oldest - Newest)',
    value: 'created_asc',
    id: 'created',
    desc: false
  },
  {
    label: 'Alert Name (A - Z)',
    value: 'name_asc',
    id: 'name',
    desc: false
  },
  {
    label: 'Alert Name (Z - A)',
    value: 'name_desc',
    id: 'name',
    desc: true
  }
];

function getColumns() {
  return [
    {
      label: 'Alert Name',
      sortKey: 'name',
      width: '35%',
      sortType: 'basic',
      filter: 'fuzzyText',
      screenReaderOnly: false
    },
    {
      label: 'Subaccount',
      sortKey: 'subaccount',
      screenReaderOnly: true
    },
    {
      label: 'Created',
      sortKey: 'created',
      filterOnly: true
    },
    {
      label: 'Last Triggered',
      sortKey: 'last_triggered',
      sortType: 'basic',
      screenReaderOnly: true
    },
    {
      label: 'Metric',
      sortKey: 'alert_configuration.metric',
      screenReaderOnly: false
    },
    {
      label: 'Condition',
      sortKey: 'alert_configuration.conditions',
      sortType: 'basic',
      screenReaderOnly: false
    },
    { label: 'Status', sortKey: 'status', screenReaderOnly: false },
    {
      label: 'Duplicate',
      sortKey: 'duplicate',
      sortType: 'basic',
      screenReaderOnly: false,
      disableSortBy: true
    }
  ];
}

const pageFilters = {
  alert: {},
  metric: {},
  sortBy: {},
  tab: {}
};

const getMetricLabel = (alert_type, alert) => {
  switch (alert_type) {
    case 'metrics_alert':
      const metricLabel =
        METRICS[alert.alert_configuration.metric] ||
        list.find((item) => item.key === alert.alert_configuration.metric)?.label;
      return metricLabel;
    case 'blocklist_alert':
      return 'Blocklist';
    case 'sending_limit_alert':
      return 'Account Usage';
    case 'health_score_alert':
      return 'Health Score';
    default:
      return '';
  }
};

const Cell = ({ cell }) => {
  const alert = cell.row.original;
  const subaccount = alert.subaccount;
  const metricLabel = getMetricLabel(alert.alert_type, alert);
  const shouldRenderCondition = alert.alert_type !== 'blocklist_alert';
  const metricUnit = alert.alert_type === 'sending_limit_alert' ? '%' : '';

  if (cell.column.sortKey === 'name')
    return (
      <Box minWidth="140px">
        <Stack space="0">
          <PageLink to={`/alerts/details/${alert.id}`}>{alert.name}</PageLink>
          <ScreenReaderExclude>
            <Text>
              Last Triggered:{' '}
              <TranslatableText>
                {alert.last_triggered ? formatDate(alert.last_triggered) : 'Never'}
              </TranslatableText>
            </Text>
            <Text>
              Subaccount: <span>{subaccount}</span>
            </Text>
          </ScreenReaderExclude>
        </Stack>
      </Box>
    );

  if (cell.column.sortKey === 'alert_configuration.conditions')
    return (
      <Box minWidth="150px">
        <Stack space="100">
          {shouldRenderCondition && (
            <>
              <Label>Condition</Label>
              <Text>
                {OPERATOR_FRIENDLY_NAMES[alert.alert_configuration.conditions.operator]}{' '}
                {alert.alert_configuration.conditions.value}
                {metricUnit}
              </Text>
            </>
          )}
        </Stack>
      </Box>
    );

  if (cell.column.sortKey === 'status')
    return <NotificationTag enabled={alert.status === 'active'} />;

  if (cell.column.sortKey === 'alert_configuration.metric')
    return (
      <Box minWidth="150px">
        <Stack space="100">
          <Label>Metric</Label>
          <Text>{metricLabel}</Text>
        </Stack>
      </Box>
    );

  if (cell.column.sortKey === 'duplicate')
    return (
      <Button variant="minimal" to={`/alerts/duplicate/${alert.id}`}>
        <TranslatableText>Duplicate Alert</TranslatableText>
        <StyledIcon>
          <ContentCopy />
        </StyledIcon>
      </Button>
    );

  // screen reader only
  if (cell.column.sortKey === 'subaccount')
    return (
      <ScreenReaderOnly>
        Subaccount: <span>{subaccount}</span>
      </ScreenReaderOnly>
    );

  if (cell.column.sortKey === 'last_triggered')
    return (
      <ScreenReaderOnly>
        Last Triggered:{' '}
        {alert.last_triggered ? (
          <time dateTime={alert.last_triggered}>{formatDate(alert.last_triggered)}</time>
        ) : (
          'Never'
        )}
      </ScreenReaderOnly>
    );

  // filter only
  if (cell.column.sortKey === 'created') return null;

  return cell.value || '';
};

export default function ConfiguredAlerts({ alerts }) {
  const { filters, updateFilters } = usePageFilters(pageFilters);
  const [selectedMetric, setSelectedMetric] = useState(undefined);
  const [alertQuery, setAlertQuery] = useState('');
  const [selectSortBy, setSelectSortBy] = useState(filters.sortBy || 'name_asc');

  const filterPlaceholder = useMemo(() => _.sample(alerts)?.name || 'Alert name', [alerts]);

  const columnsMemoized = useMemo(
    () =>
      getColumns().map((col) => ({
        ...col,
        accessor: col.sortKey,
        Cell
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Cell]
  );

  const data = useMemo(() => {
    return alerts.map((alert) => {
      return {
        ...alert
      };
    });
  }, [alerts]);

  const {
    headerGroups,
    getTableProps,
    getTableBodyProps,
    rows,
    page,
    prepareRow,
    setPageSize,
    state,
    gotoPage,
    setSortBy,
    setFilter,
    setAllFilters,
    setGlobalFilter
  } = useTable(
    {
      columns: columnsMemoized,
      data,
      initialState: {
        sortBy: [
          {
            id: 'created',
            desc: true
          }
        ]
      }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  // effect selected metric
  useEffect(() => {
    if (selectedMetric) {
      setFilter('alert_configuration.metric', selectedMetric.key);

      return;
    }

    updateFilters({ ...filters, metric: selectedMetric?.key });

    // reset
    setAllFilters([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMetric]);

  // effect alert query
  useEffect(() => {
    setGlobalFilter(alertQuery);
    updateFilters({ ...filters, alert: alertQuery });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertQuery]);

  // effect sort by
  useEffect(() => {
    const sortby = sortByOptions.find((option) => option.value === selectSortBy);

    setSortBy([]); // reset actual filter

    setSortBy([
      {
        id: sortby.id,
        desc: sortby.desc
      }
    ]);

    updateFilters({ ...filters, sortBy: sortby.value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectSortBy]);

  // initialize filters from url
  useEffect(() => {
    const metric = list.find((metric) => metric.key === filters.metric);

    if (metric) setSelectedMetric(metric);
    if (filters.alert) setAlertQuery(filters.alert);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (e) => {
    setAlertQuery(e.target.value);
  };

  const handleMetricChange = (metric) => {
    setSelectedMetric(metric);
  };

  const handleSortByChange = (e) => {
    const { currentTarget } = e;

    setSelectSortBy(currentTarget.value);
  };

  const table = (
    <Panel mb="500">
      <Panel.Section p="0">
        <TableWrapper>
          <Table {...getTableProps()} title="Alerts: Recent Incidents">
            <ScreenReaderOnly as="thead">
              {headerGroups.map((headerGroup) => {
                return (
                  <Table.Row header {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers
                      .filter((header) => header.filterOnly !== true) // remove headers that are only for filtering
                      .map((column) => {
                        return (
                          <Table.HeaderCell
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                          >
                            {column.render('label')}
                          </Table.HeaderCell>
                        );
                      })}
                  </Table.Row>
                );
              })}
            </ScreenReaderOnly>

            <tbody {...getTableBodyProps()}>
              {page.length === 0 && (
                <Table.Row>
                  <Table.Cell colSpan="100%" p="0">
                    <Empty message="No results found." />
                  </Table.Cell>
                </Table.Row>
              )}

              {page.map((row) => {
                prepareRow(row);
                return (
                  <Table.Row {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                      );
                    })}
                  </Table.Row>
                );
              })}
            </tbody>
          </Table>
        </TableWrapper>
      </Panel.Section>
    </Panel>
  );

  const originalRows = useMemo(() => rows.map((row) => row.original), [rows]);

  return (
    <>
      <Panel>
        <Panel.Section>
          <FiltersBar>
            <TextField
              id="filter-alert"
              maxWidth="inherit"
              label="Filter Alerts"
              prefix={<Search />}
              onChange={handleSearch}
              value={alertQuery}
              placeholder={`e.g. ${filterPlaceholder}`}
            />

            <MetricsWrapper>
              <Typeahead
                results={metrics}
                itemToString={(item) => (item?.label ? item.label : '')}
                placeholder="e.g. Bounce Rate"
                label="Metric"
                renderItem={({ label, category }) => (
                  <TypeaheadItem id={category} label={label || ''} />
                )}
                selectedItem={selectedMetric}
                onChange={handleMetricChange}
              />
            </MetricsWrapper>

            <SortSelect
              options={sortByOptions}
              defaultValue={selectSortBy}
              onChange={handleSortByChange}
            />
          </FiltersBar>
        </Panel.Section>
      </Panel>

      <div>{table}</div>

      <Pagination
        data={originalRows}
        perPage={state.pageSize}
        currentPage={state.pageIndex + 1}
        onPageChange={gotoPage}
        onPerPageChange={setPageSize}
      />
    </>
  );
}
