import React from 'react';
import styles from './users.module.scss';

const Users = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 282.3 224.9" overflow="inherit">
    <style></style>
    <path className={styles.st0} d="M256 74.8v-.7l7.2-3.9v1l-6.2 3.2 6.2 3.3v1l-7.2-3.9z" />
    <path className={styles.st1} d="M272.2 66.6h.8L265.9 83h-.9l7.2-16.4z" />
    <path className={styles.st0} d="M275.1 77.7l6.2-3.2-6.2-3.3v-1l7.2 3.9v.7l-7.2 3.9v-1z" />
    <path
      className={styles.st2}
      d="M41.2 121.9L7 129.2c-.4.1-.7.5-.6.9.6 2.6 2.2 3.9 4.7 5.1 3.6 1.8 7.3 3.6 10.9 5.4 1.4.7 4.1 2.4 5.8 2.1 1.6-.3 3.4-3 4.4-4.2l7.8-9.3c1.3-1.5 2.8-3.8 2.4-5.7-.2-.7-.3-1.7-1.2-1.6z"
    />
    <path
      className={styles.st2}
      d="M43.4 128.6c-.4.7-.8 1.3-1.3 1.9-2.8 3.3-5.6 6.6-8.2 10-1.4 1.8-3.2 4.1-5.6 4.6-2.4.5-5-.8-7-1.9-3.8-2-7.7-3.9-11.6-5.7-.7-.3-1.3-.7-1.9-1.2l3.6 17.2c.1.4.5.7.9.6l34.2-7.3c.4-.1.7-.5.6-.9l-3.7-17.3z"
    />
    <path
      className={styles.st3}
      d="M8.1 167.7c-1.5.3-2.9-.6-3.2-1.9l-4-19c-.3-1.4.7-2.7 2.1-3.1l30-6.4c1.5-.3 2.9.6 3.2 1.9l4 19c.3 1.4-.7 2.7-2.1 3.1l-30 6.4z"
    />
    <path
      className={styles.st4}
      d="M35.1 136.5L.9 143.8c-.4.1-.7.5-.6.9.6 2.6 2.2 3.9 4.7 5.1 3.6 1.8 7.3 3.6 10.9 5.4 1.4.7 4.1 2.4 5.8 2.1 1.6-.3 3.4-3 4.4-4.2l7.8-9.3c1.3-1.5 2.8-3.8 2.4-5.7-.3-.7-.4-1.8-1.2-1.6z"
    />
    <path
      className={styles.st4}
      d="M37.2 143.1c-.4.7-.8 1.3-1.3 1.9-2.8 3.3-5.6 6.6-8.2 10-1.4 1.8-3.2 4.1-5.6 4.6-2.4.5-5-.8-7-1.9-3.8-2-7.7-3.9-11.6-5.7-.7-.3-1.3-.7-1.9-1.2L5.2 168c.1.4.5.7.9.6l34.2-7.3c.4-.1.7-.5.6-.9l-3.7-17.3z"
    />
    <path
      className={styles.st2}
      d="M245.8 21l-26.3-5.6c-.3-.1-.6.1-.7.5-.4 2 .3 3.5 1.7 5.1l6 7.2c.8 1 2.2 3 3.4 3.2 1.3.3 3.3-1 4.4-1.6 2.8-1.4 5.6-2.8 8.4-4.1 1.4-.7 3.1-1.8 3.5-3.3 0-.5.3-1.2-.4-1.4z"
    />
    <path
      className={styles.st2}
      d="M245.3 26.3c-.5.4-1 .7-1.5.9-3 1.4-6 2.9-8.9 4.4-1.6.8-3.5 1.9-5.4 1.5-1.9-.4-3.2-2.1-4.3-3.5-2-2.6-4.2-5.2-6.3-7.7-.4-.5-.7-.9-1-1.4L215 33.7c-.1.3.1.6.5.7l26.3 5.6c.3.1.6-.1.7-.5l2.8-13.2z"
    />
    <path
      className={styles.st3}
      d="M222.8 44c-1.1-.2-1.9-1.3-1.6-2.5l3.3-15.4c.2-1.1 1.4-1.8 2.5-1.6l23.1 4.9c1.1.2 1.9 1.3 1.6 2.5l-3.3 15.4c-.2 1.1-1.4 1.8-2.5 1.6L222.8 44z"
    />
    <path
      className={styles.st2}
      d="M251.6 30l-26.3-5.6c-.3-.1-.6.1-.7.5-.4 2 .3 3.5 1.7 5.1l6 7.2c.8 1 2.2 3 3.4 3.2 1.3.3 3.3-1 4.4-1.6 2.8-1.4 5.6-2.8 8.4-4.1 1.4-.7 3.1-1.8 3.5-3.3 0-.5.3-1.2-.4-1.4z"
    />
    <path
      className={styles.st2}
      d="M251.1 35.3c-.5.4-1 .7-1.5.9-3 1.4-6 2.9-8.9 4.4-1.6.8-3.5 1.9-5.4 1.5-1.9-.4-3.2-2.1-4.3-3.5-2-2.6-4.2-5.2-6.3-7.7-.4-.5-.7-.9-1-1.4l-2.8 13.2c-.1.3.1.6.5.7l26.3 5.6c.3.1.6-.1.7-.5l2.7-13.2z"
    />
    <path
      className={styles.st5}
      d="M174.3 39.6c8.9 3.9 17 9.4 23.9 16.2M83.3 58.8c6-6.6 13.1-12.1 21-16.4M72.6 152.1c-6.6-11.6-10.3-25-10.3-39.3 0-2.2.1-4.4.3-6.6M167.9 188.5c-8.1 2.7-16.7 4.2-25.7 4.2-11.2 0-21.9-2.3-31.5-6.5M222.1 114.8c-.4 16.5-5.8 31.7-14.7 44.2"
    />
    <path
      className={styles.st1}
      d="M128.3.5c6.1 0 11 5 11 11 0 6.1-5 11-11 11-6.1 0-11-4.9-11-11-.1-6 4.9-11 11-11m0-.5c-6.4 0-11.5 5.2-11.5 11.5 0 6.4 5.2 11.5 11.5 11.5 6.4 0 11.5-5.2 11.5-11.5S134.7 0 128.3 0z"
    />
    <path
      className={styles.st1}
      d="M118.8 22.7c2.7 2.3 6 3.5 9.5 3.5s6.8-1.2 9.5-3.5c6.4 3.6 10.7 13.1 10.8 23.9H108c.1-10.8 4.4-20.3 10.8-23.9m18.9-.6c-2.5 2.2-5.8 3.6-9.4 3.6-3.6 0-6.9-1.4-9.4-3.6-6.7 3.6-11.4 13.4-11.4 25h41.6c0-11.6-4.7-21.5-11.4-25zM155.3.8c5.1 0 9.2 4.1 9.2 9.2s-4.1 9.2-9.2 9.2-9.2-4.1-9.2-9.2 4.2-9.2 9.2-9.2m0-.5c-5.3 0-9.7 4.3-9.7 9.7 0 5.3 4.3 9.7 9.7 9.7 5.4 0 9.7-4.3 9.7-9.7 0-5.4-4.3-9.7-9.7-9.7z"
    />
    <path
      className={styles.st1}
      d="M147.4 19.4c2.2 1.9 5 2.9 8 2.9 2.9 0 5.7-1 8-2.9 5.3 3 8.8 10.9 9 19.9H151c-1.2-6.7-3.9-12.4-7.7-16.2 1.2-1.6 2.6-2.9 4.1-3.7m15.8-.6c-2.1 1.9-4.9 3-7.9 3s-5.8-1.1-7.9-3c-1.8.9-3.4 2.4-4.8 4.3 4 3.8 6.8 9.7 7.9 16.7h22.2c.1-9.7-3.8-18-9.5-21zM71.1 57.2c6.4 0 11.7 5.2 11.7 11.7 0 6.4-5.2 11.7-11.7 11.7-6.4 0-11.7-5.2-11.7-11.7 0-6.4 5.2-11.7 11.7-11.7m0-.5c-6.7 0-12.2 5.5-12.2 12.2 0 6.7 5.4 12.2 12.2 12.2 6.7 0 12.2-5.4 12.2-12.2 0-6.7-5.5-12.2-12.2-12.2z"
    />
    <path
      className={styles.st1}
      d="M81.1 80.6c6.9 3.8 11.2 11.1 11.4 19.2H49.6c.2-8.1 4.6-15.4 11.4-19.2 2.8 2.4 6.3 3.7 10 3.7 3.8 0 7.3-1.3 10.1-3.7M81 80c-2.6 2.4-6.1 3.8-9.9 3.8-3.8 0-7.3-1.4-10-3.8-7.1 3.8-11.9 11.4-12 20.3H93c0-8.9-4.9-16.5-12-20.3z"
    />
    <path
      className={styles.st3}
      d="M91.1 106.6c-5.4 0-9.9-4.4-9.9-9.9 0-5.4 4.4-9.9 9.9-9.9 5.4 0 9.9 4.4 9.9 9.9s-4.4 9.9-9.9 9.9z"
    />
    <path
      className={styles.st6}
      d="M91.1 87.1c5.3 0 9.6 4.3 9.6 9.6s-4.3 9.6-9.6 9.6-9.6-4.3-9.6-9.6 4.3-9.6 9.6-9.6m0-.5c-5.6 0-10.1 4.5-10.1 10.1s4.5 10.1 10.1 10.1 10.1-4.5 10.1-10.1c.1-5.5-4.5-10.1-10.1-10.1z"
    />
    <path
      className={styles.st6}
      d="M97.9 94.6c0 .2-.1.4-.2.6l-7.2 7.2c-.2.2-.4.3-.6.3-.2 0-.4-.1-.6-.3l-4.8-4.8c-.2-.2-.2-.4-.2-.6s.1-.4.2-.6l1.2-1.2c.2-.2.4-.3.6-.3.2 0 .4.1.6.3l3 3 5.4-5.4c.2-.2.4-.3.6-.3.2 0 .4.1.6.3l1.2 1.2c.2.2.2.4.2.6z"
    />
    <path
      className={styles.st1}
      d="M88.1 132.7c6.4 0 11.7 5.2 11.7 11.7 0 6.4-5.2 11.7-11.7 11.7-6.4 0-11.7-5.2-11.7-11.7 0-6.4 5.2-11.7 11.7-11.7m0-.5c-6.7 0-12.2 5.5-12.2 12.2 0 6.7 5.4 12.2 12.2 12.2 6.7 0 12.2-5.4 12.2-12.2 0-6.7-5.5-12.2-12.2-12.2z"
    />
    <path
      className={styles.st1}
      d="M98.1 156.1c6.9 3.8 11.2 11.1 11.4 19.2H66.6c.2-8.1 4.6-15.4 11.4-19.2 2.8 2.4 6.3 3.7 10 3.7 3.8 0 7.3-1.3 10.1-3.7m-.1-.6c-2.6 2.4-6.1 3.8-9.9 3.8-3.8 0-7.3-1.4-10-3.8-7.1 3.8-11.9 11.4-12 20.3H110c0-8.9-4.9-16.5-12-20.3z"
    />
    <path
      className={styles.st3}
      d="M108.1 182.1c-5.4 0-9.9-4.4-9.9-9.9 0-5.4 4.4-9.9 9.9-9.9 5.4 0 9.9 4.4 9.9 9.9s-4.4 9.9-9.9 9.9z"
    />
    <path
      className={styles.st6}
      d="M108.1 162.6c5.3 0 9.6 4.3 9.6 9.6s-4.3 9.6-9.6 9.6-9.6-4.3-9.6-9.6 4.3-9.6 9.6-9.6m0-.5c-5.6 0-10.1 4.5-10.1 10.1s4.5 10.1 10.1 10.1 10.1-4.5 10.1-10.1c.1-5.5-4.5-10.1-10.1-10.1z"
    />
    <path
      className={styles.st6}
      d="M108.4 165.8l1.9 3.9 4.3.6-3.1 3.1.7 4.2-3.8-2-3.9 2 .8-4.2-3.1-3.1 4.3-.6z"
    />
    <path
      className={styles.st7}
      d="M62.3 206.4h93.1M52.7 210.9h85.8M30.8 215.4h101.3M46.6 219.9h90.6M58.4 224.6h171.2"
    />
    <path
      className={styles.st8}
      d="M160.4 206.3h71.7M152.2 210.8h88.1M144.9 215.3h115.4M148.8 219.9h91.5"
    />
    <path
      className={styles.st9}
      d="M152 118.6c-3.8 0-6.9 2.7-7.7 6.2h-3.6c-.8-3.5-3.9-6.2-7.7-6.2-4.3 0-7.8 3.5-7.8 7.8s3.5 7.8 7.8 7.8c3.8 0 6.9-2.7 7.7-6.2h3.6c.8 3.5 3.9 6.2 7.7 6.2 4.3 0 7.8-3.5 7.8-7.8s-3.5-7.8-7.8-7.8zm-18.9 12.6c-2.7 0-4.8-2.2-4.8-4.8 0-2.7 2.2-4.8 4.8-4.8s4.8 2.2 4.8 4.8c0 2.7-2.1 4.8-4.8 4.8zm18.9 0c-2.7 0-4.8-2.2-4.8-4.8 0-2.7 2.2-4.8 4.8-4.8 2.7 0 4.8 2.2 4.8 4.8 0 2.7-2.1 4.8-4.8 4.8zM144.1 139.7h-3c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2h3c.7 0 1.2.5 1.2 1.2-.1.6-.6 1.2-1.2 1.2zM152.9 96.4c-3.5 2.7-4.2 7.4-4.3 10.5-5.6-6.5 10.3-25.4-7.8-37.2 10 12.9-10.5 25.6-15 43.3-.3 1.1-.5 2.2-.7 3.4 1.5-2.6 8-6 13.7-2.4 8.9 5.6 14.8-3.9 19.4 2.4-.1-1.2-.4-2.3-.6-3.4-2-7.5-6.5-11.2-4.7-16.6z"
    />
    <path
      className={styles.st10}
      d="M206.6 82.3c-6.6 0-11.9-5.3-11.9-11.9 0-6.6 5.4-11.9 11.9-11.9 6.6 0 11.9 5.4 11.9 11.9 0 6.6-5.3 11.9-11.9 11.9z"
    />
    <path
      className={styles.st1}
      d="M206.6 58.7c6.4 0 11.7 5.2 11.7 11.7 0 6.4-5.2 11.7-11.7 11.7-6.4 0-11.7-5.2-11.7-11.7 0-6.4 5.2-11.7 11.7-11.7m0-.5c-6.7 0-12.2 5.5-12.2 12.2 0 6.7 5.4 12.2 12.2 12.2 6.7 0 12.2-5.4 12.2-12.2 0-6.7-5.5-12.2-12.2-12.2z"
    />
    <path
      className={styles.st10}
      d="M184.9 101.6c.1-8.4 4.6-15.9 11.7-19.7 2.8 2.4 6.3 3.8 10 3.8s7.2-1.3 10-3.8c7.1 3.8 11.6 11.4 11.7 19.7h-43.4z"
    />
    <path
      className={styles.st1}
      d="M216.6 82.1c6.9 3.8 11.2 11.1 11.4 19.2h-42.9c.2-8.1 4.6-15.4 11.4-19.2 2.8 2.4 6.3 3.7 10 3.7 3.8 0 7.3-1.3 10.1-3.7m-.1-.6c-2.6 2.4-6.1 3.8-9.9 3.8-3.8 0-7.3-1.4-10-3.8-7.1 3.8-11.9 11.4-12 20.3h43.9c0-8.9-4.9-16.5-12-20.3z"
    />
    <circle className={styles.st3} cx="226.6" cy="98.2" r="9.9" />
    <path
      className={styles.st6}
      d="M226.6 88.6c5.3 0 9.6 4.3 9.6 9.6s-4.3 9.6-9.6 9.6-9.6-4.3-9.6-9.6 4.3-9.6 9.6-9.6m0-.5c-5.6 0-10.1 4.5-10.1 10.1s4.5 10.1 10.1 10.1 10.1-4.5 10.1-10.1c.1-5.5-4.5-10.1-10.1-10.1z"
    />
    <path
      className={styles.st6}
      d="M232.6 97.4c0-.5-.4-.8-.8-.8h-3.4v-3.4c0-.5-.4-.8-.8-.8h-1.7c-.5 0-.8.4-.8.8v3.4h-3.4c-.5 0-.8.4-.8.8v1.7c0 .5.4.8.8.8h3.4v3.4c0 .5.4.8.8.8h1.7c.5 0 .8-.4.8-.8v-3.4h3.4c.5 0 .8-.4.8-.8v-1.7z"
    />
    <path
      className={styles.st1}
      d="M190.1 141.7c6.4 0 11.7 5.2 11.7 11.7 0 6.4-5.2 11.7-11.7 11.7-6.4 0-11.7-5.2-11.7-11.7 0-6.4 5.2-11.7 11.7-11.7m0-.5c-6.7 0-12.2 5.5-12.2 12.2 0 6.7 5.4 12.2 12.2 12.2 6.7 0 12.2-5.4 12.2-12.2 0-6.7-5.5-12.2-12.2-12.2z"
    />
    <path
      className={styles.st1}
      d="M200.1 165.1c6.9 3.8 11.2 11.1 11.4 19.2h-42.9c.2-8.1 4.6-15.4 11.4-19.2 2.8 2.4 6.3 3.7 10 3.7 3.8 0 7.3-1.3 10.1-3.7m-.1-.6c-2.6 2.4-6.1 3.8-9.9 3.8-3.8 0-7.3-1.4-10-3.8-7.1 3.8-11.9 11.4-12 20.3H212c0-8.9-4.9-16.5-12-20.3z"
    />
    <circle className={styles.st3} cx="210.1" cy="181.2" r="9.9" />
    <path
      className={styles.st6}
      d="M210.1 171.6c5.3 0 9.6 4.3 9.6 9.6s-4.3 9.6-9.6 9.6-9.6-4.3-9.6-9.6 4.3-9.6 9.6-9.6m0-.5c-5.6 0-10.1 4.5-10.1 10.1s4.5 10.1 10.1 10.1 10.1-4.5 10.1-10.1c.1-5.5-4.5-10.1-10.1-10.1z"
    />
    <path
      className={styles.st6}
      d="M216.1 180.4c0-.5-.4-.8-.8-.8h-10.2c-.5 0-.8.4-.8.8v1.7c0 .5.4.8.8.8h10.2c.5 0 .8-.4.8-.8v-1.7z"
    />
    <path
      className={styles.st9}
      d="M237.7 150.4c-.7 0-1.3-.2-1.8-.8-.5-.5-.8-1.1-.8-1.8v-24.3c0-.7.2-1.3.8-1.8.5-.5 1.1-.8 1.8-.8h4.6v-2.2c0-.3.1-.5.2-.6.2-.2.4-.2.6-.2s.5.1.6.2.2.4.2.6v2.2h6.5v-2.2c0-.3.1-.5.2-.6.2-.2.4-.2.6-.2s.5.1.6.2.2.4.2.6v2.2h6.5v-2.2c0-.3.1-.5.2-.6s.4-.2.6-.2.5.1.6.2c.2.2.2.4.2.6v2.2h4.6c.7 0 1.3.2 1.8.8.5.5.8 1.1.8 1.8v24.3c0 .7-.2 1.3-.8 1.8s-1.1.8-1.8.8h-27zm0-27.8c-.3 0-.5.1-.6.2-.2.2-.2.4-.2.6v24.3c0 .3.1.5.2.6.2.2.4.2.6.2h27.4c.3 0 .5-.1.6-.2.2-.2.2-.4.2-.6v-24.3c0-.3-.1-.5-.2-.6-.2-.2-.4-.2-.6-.2h-4.6v2.2c0 .3-.1.5-.2.6-.2.2-.4.2-.6.2s-.5-.1-.6-.2-.2-.4-.2-.6v-2.2h-6.5v2.2c0 .3-.1.5-.2.6-.2.2-.4.2-.6.2s-.5-.1-.6-.2-.2-.4-.2-.6v-2.2H244v2.2c0 .3-.1.5-.2.6-.2.2-.4.2-.6.2s-.5-.1-.6-.2-.2-.4-.2-.6v-2.2h-4.7z"
    />
    <path
      className={styles.st10}
      d="M259.6 118c.2 0 .4.1.5.2.1.1.2.3.2.5v2.4H265.1c.7 0 1.2.2 1.7.7.5.5.7 1 .7 1.7v24.3c0 .7-.2 1.2-.7 1.7-.5.5-1 .7-1.7.7h-27.4c-.7 0-1.2-.2-1.7-.7-.5-.5-.7-1-.7-1.7v-24.3c0-.7.2-1.2.7-1.7.5-.5 1-.7 1.7-.7H242.5v-2.4c0-.2.1-.4.2-.5s.3-.2.5-.2.4.1.5.2c.1.1.2.3.2.5v2.4H250.9v-2.4c0-.2.1-.4.2-.5.1-.1.3-.2.5-.2s.4.1.5.2c.1.1.2.3.2.5v2.4H259.3v-2.4c0-.2.1-.4.2-.5-.3-.1-.1-.2.1-.2m0 7.5c-.2 0-.4-.1-.5-.2-.1-.1-.2-.3-.2-.5v-2.4H252v2.4c0 .2-.1.4-.2.5-.1.1-.3.2-.5.2s-.4-.1-.5-.2c-.1-.1-.2-.3-.2-.5v-2.4H243.6v2.4c0 .2-.1.4-.2.5s-.3.2-.5.2-.4-.1-.5-.2c-.1-.1-.2-.3-.2-.5v-2.4H237.4c-.3 0-.6.1-.8.3-.2.2-.3.5-.3.8v24.3c0 .3.1.6.3.8.2.2.5.3.8.3h27.4c.3 0 .6-.1.8-.3.2-.2.3-.5.3-.8v-24.3c0-.3-.1-.6-.3-.8-.2-.2-.5-.3-.8-.3H260v2.4c0 .2-.1.4-.2.5.2.2 0 .2-.2.2m0-7.8c-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7v2.1h-6.2v-2.1c0-.3-.1-.5-.3-.7-.2-.2-.4-.3-.7-.3-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7v2.1h-6.2v-2.1c0-.3-.1-.5-.3-.7-.2-.2-.4-.3-.7-.3s-.5.1-.7.3c-.2.2-.3.4-.3.7v2.1h-4.4c-.8 0-1.4.3-2 .8-.5.5-.8 1.2-.8 2v24.3c0 .8.3 1.4.8 2 .5.5 1.2.8 2 .8h27.4c.8 0 1.4-.3 2-.8.5-.5.8-1.2.8-2v-24.3c0-.8-.3-1.4-.8-2-.5-.5-1.2-.8-2-.8h-4.4v-2.1c0-.3-.1-.5-.3-.7-.4-.2-.6-.3-.9-.3zm0 8.2c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7v-2.1h4.4c.2 0 .4.1.5.2s.2.3.2.5v24.3c0 .2-.1.4-.2.5s-.3.2-.5.2h-27.4c-.2 0-.4-.1-.5-.2-.1-.1-.2-.3-.2-.5v-24.3c0-.2.1-.4.2-.5s.3-.2.5-.2h4.4v2.1c0 .3.1.5.3.7.2.2.4.3.7.3s.5-.1.7-.3c.2-.2.3-.4.3-.7v-2.1h6.2v2.1c0 .3.1.5.3.7.2.2.4.3.7.3s.5-.1.7-.3c.2-.2.3-.4.3-.7v-2.1h6.2v2.1c0 .3.1.5.3.7.3.2.6.3.9.3z"
    />
    <g>
      <path
        className={styles.st9}
        d="M237.7 150.2c-.7 0-1.2-.2-1.7-.7-.5-.5-.7-1-.7-1.7v-24.3c0-.7.2-1.2.7-1.7s1-.7 1.7-.7h4.8v-2.4c0-.2.1-.4.2-.5.1-.1.3-.2.5-.2s.4.1.5.2c.1.1.2.3.2.5v2.4h6.9v-2.4c0-.2.1-.4.2-.5.1-.1.3-.2.5-.2s.4.1.5.2c.1.1.2.3.2.5v2.4h6.9v-2.4c0-.2.1-.4.2-.5.1-.1.3-.2.5-.2s.4.1.5.2c.1.1.2.3.2.5v2.4h4.8c.7 0 1.2.2 1.7.7.5.5.7 1 .7 1.7v24.3c0 .7-.2 1.2-.7 1.7-.5.5-1 .7-1.7.7h-27.6zm0-27.7c-.3 0-.6.1-.8.3-.2.2-.3.5-.3.8v24.3c0 .3.1.6.3.8.2.2.5.3.8.3h27.4c.3 0 .6-.1.8-.3.2-.2.3-.5.3-.8v-24.3c0-.3-.1-.6-.3-.8-.2-.2-.5-.3-.8-.3h-4.8v2.4c0 .2-.1.4-.2.5-.1.1-.3.2-.5.2s-.4-.1-.5-.2c-.1-.1-.2-.3-.2-.5v-2.4H252v2.4c0 .2-.1.4-.2.5-.1.1-.3.2-.5.2s-.4-.1-.5-.2c-.1-.1-.2-.3-.2-.5v-2.4h-6.9v2.4c0 .2-.1.4-.2.5-.1.1-.3.2-.5.2s-.4-.1-.5-.2c-.1-.1-.2-.3-.2-.5v-2.4h-4.6z"
      />
    </g>
    <path
      className={styles.st9}
      d="M242.1 143.4v-3.1h4.1v3.1h-4.1zM242.1 138.2v-3.1h4.1v3.1h-4.1zM242.1 133.1V130h4.1v3.1h-4.1zM249.3 140.3h4.1v3.1h-4.1zM249.3 135.1h4.1v3.1h-4.1zM249.3 130h4.1v3.1h-4.1zM256.5 140.3h4.1v3.1h-4.1zM256.5 135.1h4.1v3.1h-4.1zM256.5 130h4.1v3.1h-4.1z"
    />
    <g>
      <path
        className={styles.st3}
        d="M28.7 45.1c-.8 0-1.4-.6-1.4-1.4V8.9c0-.8.6-1.4 1.4-1.4h53.1c.8 0 1.4.6 1.4 1.4v34.8c0 .8-.6 1.4-1.4 1.4H28.7z"
      />
      <path
        d="M81.8 7.8c.6 0 1.2.5 1.2 1.2v34.8c0 .7-.5 1.2-1.2 1.2H28.7c-.6 0-1.2-.5-1.2-1.2V8.9c0-.7.5-1.2 1.2-1.2h53.1m0-.4H28.7c-1 0-1.7.7-1.7 1.6v34.8c0 .9.8 1.7 1.7 1.7h53.1c.9 0 1.7-.8 1.7-1.7V8.9c0-.9-.8-1.6-1.7-1.6z"
        fill="#d2d2d7"
      />
      <path
        className={styles.st9}
        d="M27.2 12V8.9c0-.8.6-1.4 1.4-1.4h53.1c.8 0 1.4.6 1.4 1.4V12H27.2z"
      />
      <path
        className={styles.st9}
        d="M81.8 7.8c.6 0 1.2.5 1.2 1.2v2.8H27.5V8.9c0-.7.5-1.2 1.2-1.2h53.1m0-.4H28.7c-1 0-1.7.7-1.7 1.6v3.3h56.5V8.9c0-.9-.8-1.6-1.7-1.6z"
      />
      <g>
        <circle className={styles.st10} cx="30.3" cy="9.6" r=".9" />
        <circle className={styles.st10} cx="32.9" cy="9.6" r=".9" />
        <circle className={styles.st10} cx="35.6" cy="9.6" r=".9" />
      </g>
    </g>
    <path
      d="M63.3 27.7c0-.7.9-1.2 2.2-1.6-.1-.8-.3-1.5-.6-2.2-1.3.4-2.4.3-2.7-.2-.3-.6.1-1.5 1.1-2.5-.5-.6-1-1.1-1.6-1.6-.9 1-1.9 1.5-2.5 1.1-.6-.3-.6-1.4-.3-2.7-.7-.3-1.4-.5-2.2-.6-.3 1.3-.9 2.2-1.6 2.2-.7 0-1.2-.9-1.6-2.2-.8.1-1.5.3-2.2.6.4 1.3.3 2.4-.3 2.7-.6.3-1.5-.2-2.5-1.2-.6.5-1.2 1-1.6 1.6 1 1 1.5 1.9 1.2 2.5-.3.6-1.4.6-2.8.2-.3.7-.5 1.4-.6 2.2 1.4.3 2.3.9 2.3 1.6 0 .7-.9 1.3-2.3 1.6.1.8.3 1.5.6 2.2 1.4-.4 2.5-.4 2.8.2.3.6-.2 1.6-1.2 2.5.5.6 1 1.1 1.6 1.6 1-1 2-1.6 2.6-1.2.6.3.6 1.5.2 2.8.7.3 1.4.5 2.2.6.3-1.4.9-2.3 1.6-2.3.7 0 1.3.9 1.6 2.3.8-.1 1.5-.3 2.2-.6-.4-1.4-.4-2.5.2-2.8.6-.3 1.6.2 2.5 1.2.6-.5 1.1-1 1.6-1.6-1-1-1.5-1.9-1.2-2.5.3-.6 1.4-.6 2.8-.2.3-.7.5-1.4.6-2.2-1.2-.3-2.1-.8-2.1-1.5zm-8.1 6.2c-3.4 0-6.1-2.8-6.1-6.1 0-3.4 2.8-6.1 6.1-6.1 3.4 0 6.1 2.8 6.1 6.1.1 3.3-2.7 6.1-6.1 6.1z"
      fill="#aaaaaf"
    />
  </svg>
);

export default Users;
