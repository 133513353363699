import React from 'react';
import { Columns, Column, Text, Skeleton, Stack } from 'src/components/matchbox';
type IterableType = {
  label: string;
  width: string;
};
export function HorizontalBarLoading({
  iterableRows
}: {
  iterableRows: IterableType[];
}): JSX.Element {
  return (
    <Stack space="400">
      {iterableRows.map(({ width = '100%', label }, index: number) => {
        return (
          <Columns key={`column-${index}`}>
            <Column width={1 / 3}>
              <Text>{label}</Text>
            </Column>
            <Column width={2 / 3}>
              <Skeleton.Box data-id="skeleton-bar" height="500" width={width} />
            </Column>
          </Columns>
        );
      })}
    </Stack>
  );
}
