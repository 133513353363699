/** Dev (localhost) specific tenant settings */

module.exports = {
  apiBase: 'http://api.sparkpost.test/api',
  authentication: {
    app: {
      cookie: {
        options: {
          secure: false
        }
      }
    },
    site: {
      cookie: {
        options: {
          domain: '.sparkpost.test',
          secure: false
        }
      }
    }
  },
  cookieConsent: {
    cookie: {
      options: {
        domain: 'sparkpost.test'
      }
    }
  },
  crossLinkTenant: 'spc', // so we can test locally
  featureFlags: {
    allow_default_signing_domains_for_ip_pools: true,
    allow_mailbox_verification: true,
    allow_anyone_at_verification: true,
    has_signup: true
  },
  gaTag: 'UA-111136819-2',
  gtmId: 'GTM-P87NNJ4',
  sentry: {
    dsn: 'https://4184c351d4f84f6eabbf6b350d500084@o113111.ingest.sentry.io/4504045984546816',
    publicKey: '4184c351d4f84f6eabbf6b350d500084'
  },
  rudderstack: {
    enabled: true,
    publicKey: '2A59r5cgf9ZJnZBtj6kggLgmQKM'
  },
  siftScience: {
    id: '88affa8e11'
  },
  splashPage: '/dashboard',
  smtpAuth: {
    host: 'smtp.sparkmail.com',
    alternativePort: 2525
  },
  website: {
    domain: 'sparkpost.test'
  }
};
