import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import { LINKS } from 'src/constants';
import { TextFieldWrapper, CheckboxWrapper } from 'src/components/reduxFormWrappers';
import { ExternalLink } from 'src/components/links';
import { Box, Button, Stack, Checkbox } from 'src/components/matchbox';
import { TranslatableText } from 'src/components/text';
import { Form } from 'src/components/tracking';
import { required, minLength } from 'src/helpers/validation';

export class RegisterUserForm extends Component {
  render() {
    const { handleSubmit, submitting, invalid, pristine } = this.props;

    return (
      <Form onSubmit={handleSubmit} id="register-user-form">
        <Stack>
          <Field
            name="first_name"
            component={TextFieldWrapper}
            label="First Name"
            autoComplete="given-name"
            validate={required}
            disabled={submitting}
            data-sensitive={true}
          />
          <Field
            name="last_name"
            component={TextFieldWrapper}
            label="Last Name"
            autoComplete="family-name"
            validate={required}
            disabled={submitting}
            data-sensitive={true}
          />
          <Field
            name="username"
            component={TextFieldWrapper}
            label="Username"
            validate={required}
            disabled={true}
            data-sensitive={true}
          />
          <Field
            name="password"
            component={TextFieldWrapper}
            label="Password"
            helpText="Passwords must be at least 12 characters long."
            validate={[required, minLength(12)]}
            disabled={submitting}
            data-sensitive={true}
            type="password"
            autoComplete="new-password"
            data-lpignore={true} // removes inaccurate LastPass password management behavior
          />
          <Checkbox.Group>
            <Field
              name="email_opt_in"
              id="email_opt_in"
              component={CheckboxWrapper}
              disabled={submitting}
              data-track={true}
              type="checkbox"
              label={
                <span>Sign up for product updates, customer event invitations, and more!</span>
              }
            />

            <Field
              name="tou_accepted"
              id="tou_accepted"
              component={CheckboxWrapper}
              type="checkbox"
              disabled={submitting}
              validate={required}
              data-track={true}
              label={
                <TranslatableText>
                  I agree to the <ExternalLink to={LINKS.TOU}>Terms of Use</ExternalLink> and the{' '}
                  <ExternalLink to={LINKS.MICROENTERPRISE_WAIVER}>
                    Microenterprise Waiver
                  </ExternalLink>
                </TranslatableText>
              }
            />
          </Checkbox.Group>

          <Box>
            <Button
              children={submitting ? 'Loading' : 'Confirm'}
              loading={submitting}
              variant="primary"
              submit
              disabled={submitting || pristine || invalid}
            />
          </Box>
        </Stack>
      </Form>
    );
  }
}

const mapStateToProps = (state, { email }) => ({
  initialValues: { username: email, email_opt_in: false }
});

const RegisterUserReduxForm = reduxForm({ form: 'RegisterUser' })(RegisterUserForm);
export default connect(mapStateToProps, {})(RegisterUserReduxForm);
