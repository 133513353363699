import React from 'react';
import styled from 'styled-components';

const List = styled.ul`
  padding: 0;
  column-count: 2;
  column-gap: ${(props) => props.theme.space['500']};
  list-style-type: none;

  li {
    position: relative;
    display: inline-block;
    list-style-type: none;
    font-family: ${(props) => props.theme.fonts['monospace']};
    font-weight: ${(props) => props.theme.fontWeights['semibold']};
    font-size: ${(props) => props.theme.fontSizes['200']};
    line-height: ${(props) => props.theme.lineHeights['500']};
  }
`;
const BackupCodesList = ({ codes }) => {
  return (
    <List>
      {codes.map((code) => (
        <li key={code}>{code}</li>
      ))}
    </List>
  );
};

export default BackupCodesList;
