import React, { Component } from 'react';
import { connect } from 'react-redux';
import { list as getSubaccountsList } from 'src/actions/subaccounts';
import { Typeahead, TypeaheadItem } from 'src/components/typeahead/Typeahead';
import { getSubaccounts, hasSubaccounts, selectSubaccounts } from 'src/selectors/subaccounts';

export class SubaccountTypeahead extends Component {
  static defaultProps = {
    name: 'subaccount'
  };

  render() {
    const { hasSubaccounts } = this.props;

    if (!hasSubaccounts) {
      return null;
    }

    return (
      <Typeahead
        renderItem={(item) => <TypeaheadItem label={item.name} id={item.id} />}
        itemToString={(item) => (item ? `${item.name} (${item.id})` : '')}
        label="Subaccount"
        data-track={this.props['data-track']}
        {...this.props}
      />
    );
  }

  componentDidMount() {
    const { hasSubaccounts } = this.props;
    if (hasSubaccounts) {
      this.props.getSubaccountsList();
    }
  }
}

const mapStateToProps = (state, props) => ({
  hasSubaccounts: hasSubaccounts(state),
  results: props.unfiltered ? getSubaccounts(state) : selectSubaccounts(state)
});

export default connect(mapStateToProps, { getSubaccountsList })(SubaccountTypeahead);
