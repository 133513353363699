import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { isAfter } from 'date-fns';

// REDUX
import { listWebhooks } from 'src/actions/webhooks';
import { list as listSubaccounts } from 'src/actions/subaccounts';
import { selectSubaccounts } from 'src/selectors/subaccounts';
import { selectWebhooks } from 'src/selectors/webhooks';

// HELPERS
import { setSubaccountQuery } from 'src/helpers/subaccounts';
import { formatDateTime } from '@sparkpost/report-builder/helpers/date';

// COMPONENTS
import { Loading, ApiErrorBanner } from 'src/components';
import { Page } from 'src/components/matchbox';
import { Setup } from 'src/components/images';
import { PageLink } from 'src/components/links';
import { LastStatus } from './styles';
import Collection from './components/Collection';
import { TranslatableText } from 'src/components/text';

const getEventSource = (webhook, subaccounts) => {
  const getSubaccountName = (subaccountId) => {
    const subaccount = subaccounts.find((subacc) => subacc.id === subaccountId);

    return subaccount?.name || '';
  };

  if (webhook.subaccount_id === undefined)
    return <TranslatableText>Primary and all subaccounts</TranslatableText>;

  if (webhook.subaccount_id === 0) return <TranslatableText>Primary only</TranslatableText>;

  return getSubaccountName(webhook.subaccount_id);
};

const getMostRecentDate = (date1, date2) => {
  if (date1 && date2) {
    return isAfter(new Date(date2), new Date(date1)) ? date2 : date1;
  }

  return date1 || date2;
};

const Cell = ({ cell }, subaccounts) => {
  const webhook = cell.row.original;
  const lastAttempt = getMostRecentDate(webhook.last_failure, webhook.last_successful);

  if (cell.column.sortKey === 'name') {
    return (
      <PageLink to={`/webhooks/details/${webhook.id}${setSubaccountQuery(webhook?.subaccount_id)}`}>
        {webhook.name}
      </PageLink>
    );
  }

  if (cell.column.sortKey === 'last_attempt' && lastAttempt) {
    return <time dateTime={lastAttempt}>{formatDateTime(lastAttempt)}</time>;
  }

  if (cell.column.sortKey === 'event_source') {
    return getEventSource(webhook, subaccounts);
  }

  if (cell.column.sortKey === 'last_status') return <LastStatus status={webhook.last_status} />;

  return cell.value || '';
};

function getStatus({ last_successful, last_failure, active }) {
  if (!active) return 'Disabled';

  if (!last_successful && !last_failure) return '';

  if (last_successful & !last_failure) return 'Success';

  if (last_failure && !last_successful) return 'Failure';

  const successInterval = Date.now() - new Date(last_successful);
  const failureInterval = Date.now() - new Date(last_failure);

  const lastStatus = successInterval < failureInterval ? 'Success' : 'Failure';

  return lastStatus;
}

export function WebhooksList({
  listWebhooks,
  loading,
  error,
  webhooks,
  listSubaccounts,
  subaccounts
}) {
  useEffect(() => {
    listWebhooks();
    listSubaccounts();
  }, [listWebhooks, listSubaccounts]);

  const memoizedWebhooks = useMemo(
    () => webhooks.map((webhook) => ({ ...webhook, last_status: getStatus(webhook) })),
    [webhooks]
  );

  const getColumns = useCallback(() => {
    const columns = [
      { label: 'Name', sortKey: 'name' },
      { label: 'Target', width: '20%', sortKey: 'target' },
      { label: 'Event Source', sortKey: 'event_source' },
      { label: 'Last Attempt', sortKey: 'last_attempt' },
      { label: 'Last Status', sortKey: 'last_status', filter: 'multiple' }
    ];

    return columns;
  }, []);

  const filterTypes = useMemo(
    () => ({
      multiple: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined && filterValue.length !== 0
            ? filterValue.includes(rowValue)
            : true;
        });
      }
    }),
    []
  );

  const columns = useMemo(
    () =>
      getColumns().map((col) => ({
        ...col,
        accessor: col.sortKey,
        Cell: (props) => {
          return Cell(props, subaccounts);
        }
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    headerGroups,
    getTableProps,
    getTableBodyProps,
    page,
    prepareRow,
    setPageSize,
    state,
    gotoPage,
    setFilter,
    rows
  } = useTable(
    {
      columns,
      data: memoizedWebhooks,
      defaultCanSort: true,
      disableMultiSort: true,
      initialState: {
        pageSize: 25,
        sortBy: [
          {
            id: 'name',
            desc: true
          }
        ]
      },
      filterTypes
    },
    useFilters,
    useSortBy,
    usePagination
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <Page
      primaryAction={{ content: 'Create Webhook', as: PageLink, to: '/webhooks/create' }}
      title="Webhooks"
      empty={{
        show: !error && webhooks.length === 0,
        image: Setup,
        title: 'Create a Webhook',
        content: <p>Push message events directly to your own endpoints</p>
      }}
    >
      {error ? (
        <ApiErrorBanner
          message="Sorry, we seem to have had some trouble loading your webhooks."
          errorDetails={error.message}
          reload={listWebhooks}
        />
      ) : (
        <Collection
          headerGroups={headerGroups}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          page={page}
          prepareRow={prepareRow}
          setPageSize={setPageSize}
          state={state}
          gotoPage={gotoPage}
          rows={rows}
          setFilter={setFilter}
        />
      )}
    </Page>
  );
}

function mapStateToProps(state) {
  return {
    webhooks: selectWebhooks(state),
    loading: state.webhooks.listLoading,
    error: state.webhooks.listError,
    subaccounts: selectSubaccounts(state)
  };
}

const ListPage = withRouter(
  connect(mapStateToProps, { listWebhooks, listSubaccounts })(WebhooksList)
);

export default ListPage;
