import React from 'react';
import { Box, Text } from 'src/components/matchbox';
import { tokens } from '@sparkpost/design-tokens';

const height = '80px';

export function Content({ children }) {
  return <Box marginBottom={height}>{children}</Box>;
}

export default function Footer({ label, children }) {
  return (
    <Box
      position="fixed"
      bottom="0"
      left="0"
      width="100%"
      height={height}
      backgroundColor="#fff"
      pl={tokens.spacing_450}
      pb={tokens.spacing_550}
      pt={tokens.spacing_550}
      pr={tokens.spacing_450}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderTop="1px solid"
      borderColor={tokens.color_gray_300}
      zIndex="100"
    >
      <Text>{label}</Text>
      <Box>{children}</Box>
    </Box>
  );
}
