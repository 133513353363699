import React, { useState, useMemo, useCallback } from 'react';

import { useAnalyticsReportContext } from '../../context/AnalyticsReportContext';
import { useGroupByTable } from './useGroupByTable';
import { useModal } from 'src/hooks';
import { GROUP_BY_CONFIG } from '../../constants';

import { Box, Column, Columns, Panel, Checkbox, Button, Banner } from 'src/components/matchbox';

/**
 * useGroupByTableCheckboxes
 *
 * @param { Object} options
 * @param { Boolean } [options.hideCompareButton=false]
 */
export function useGroupByTableCheckboxes(options = {}) {
  const hideCompareButton = options.hideCompareButton || false;
  const { meta, isModalOpen, closeModal, openModal } = useModal();

  const [items, setItems] = useState([]);

  const {
    selectors: { selectSummaryMetricsProcessed: displayMetrics },
    actions: { addFilters, refreshReportOptions }
  } = useAnalyticsReportContext();

  const { setGroupBy, groupBy } = useGroupByTable();

  const modal = useMemo(() => {
    return {
      meta,
      isModalOpen,
      closeModal
    };
  }, [closeModal, isModalOpen, meta]);

  const MAX_ITEMS_CHECKED = 10;

  const NO_FILTER = () => true;
  const group = GROUP_BY_CONFIG[groupBy];
  const metricsToRemove = displayMetrics.filter(group?.metricsFilter || NO_FILTER);

  const onFilterClick = useCallback(
    (filter) => {
      if (groupBy === 'subject-campaign' && metricsToRemove.length > 0) {
        openModal(filter);
      } else {
        addFilters(filter);
      }
    },
    [addFilters, groupBy, metricsToRemove, openModal]
  );

  const reset = useCallback(() => {
    setItems([]);
  }, [setItems]);

  const toggle = useCallback(
    (checkbox) => {
      // show the checkbox as checked before component re-render
      window.setTimeout(() => {
        setItems((prevState) => {
          const newCheckboxes =
            prevState.findIndex((check) => check.id === checkbox.id) > -1
              ? prevState.filter((check) => check.id !== checkbox.id)
              : [...prevState, checkbox];

          setTimeout(() => {
            const checkboxElement = document.querySelector(`[id="${checkbox.id}"]`);
            if (checkboxElement) {
              checkboxElement.focus();
            }
          }, 0);

          return newCheckboxes;
        });
      }, 2);
    },
    [setItems]
  );

  const hasChecked = useCallback(
    (id) => {
      return items.findIndex((checkbox) => checkbox.id === id) > -1;
    },
    [items]
  );

  const isMulticheckboxChecked = useMemo(() => {
    if (items.length === 0) return false;

    if (items.length < MAX_ITEMS_CHECKED) return 'indeterminate';

    return true;
  }, [items]);

  const multicheckboxLabel = useMemo(() => {
    const number = items.length;
    return `${number} selected`;
  }, [items]);

  const handleMulticheckboxClick = useCallback(() => {
    return reset();
  }, [reset]);

  const isAboveTheMaxItemsSelected = useMemo(() => {
    return items.length >= MAX_ITEMS_CHECKED + 1;
  }, [items]);

  const isNoItemsSelected = useMemo(() => {
    return items.length === 0;
  }, [items]);

  const addAllCheckboxesItemsAsFilters = useCallback(() => {
    const baseFilter = { ...items[0].filter };

    if (items.length === 1) {
      onFilterClick(baseFilter);
      return;
    }

    onFilterClick({
      type: baseFilter.type,
      filters: items.map((checkbox) => checkbox.filter)
    });

    reset();
  }, [items, onFilterClick, reset]);

  const compareSelectedCheckboxesItems = useCallback(() => {
    const comparisons = items.map((checkbox) => checkbox.filter);

    refreshReportOptions({
      comparisons
    });

    reset();
    setGroupBy('');
  }, [items, refreshReportOptions, setGroupBy, reset]);

  const Header = useMemo(
    () => () =>
      (
        <Panel.Section>
          <Columns collapseBelow="sm">
            <Column>
              <Box display="flex" alignItems="center" height="30px">
                <Box display="inline-flex" pr="400">
                  <Checkbox
                    disabled={isNoItemsSelected}
                    checked={isMulticheckboxChecked}
                    label={multicheckboxLabel}
                    onChange={handleMulticheckboxClick}
                  />
                </Box>

                {items.length > 0 && (
                  <>
                    <Box display="inline-flex" pl="200">
                      <Button
                        size="small"
                        variant="connected"
                        disabled={isAboveTheMaxItemsSelected}
                        onClick={addAllCheckboxesItemsAsFilters}
                      >
                        Add as Filters
                      </Button>
                    </Box>

                    {!hideCompareButton && (
                      <Box display="inline-flex" pl="200">
                        <Button
                          size="small"
                          variant="connected"
                          disabled={items.length < 2 || isAboveTheMaxItemsSelected}
                          onClick={compareSelectedCheckboxesItems}
                        >
                          Compare Selected
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </Box>

              {isAboveTheMaxItemsSelected && (
                <Banner mt="200" size="small" status="warning">
                  A maximum of 10 items can be selected
                </Banner>
              )}
            </Column>
          </Columns>
        </Panel.Section>
      ),
    [
      addAllCheckboxesItemsAsFilters,
      compareSelectedCheckboxesItems,
      handleMulticheckboxClick,
      hideCompareButton,
      isAboveTheMaxItemsSelected,
      isMulticheckboxChecked,
      isNoItemsSelected,
      items.length,
      multicheckboxLabel
    ]
  );

  return {
    reset,
    toggle,
    hasChecked,
    isMulticheckboxChecked,
    multicheckboxLabel,
    handleMulticheckboxClick,
    isAboveTheMaxItemsSelected,
    isNoItemsSelected,
    addAllCheckboxesItemsAsFilters,
    compareSelectedCheckboxesItems,
    onFilterClick,
    items,
    metricsToRemove,
    Header,
    modal
  };
}
