import { useEffect } from 'react';

/**
 * Only use this if you don't need to do your own cleanup - clearing the timeout should be the only cleanup needed
 */
const useDebouncedEffect = ({ effect, deps, delay }) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};

export default useDebouncedEffect;
