import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { FieldSet, TextFieldWrapper } from 'src/components';
import { Banner, Box, Checkbox, Stack, Toggle } from 'src/components/matchbox';
import RadioCardWrapper from 'src/components/reduxFormWrappers/RadioCardWrapper';
import { maxLength, required, url } from 'src/helpers/validation';
import { PASSWORD_MASK } from '../helpers/resolveAuthUpdates';

const BasicAuthFields = ({ disabled, initialValues, newWebhook }) => {
  const isMaskedPassword = initialValues?.basicPass === PASSWORD_MASK;
  const [isInputTouched, setIsInputTouched] = useState(false);
  const shouldChangePassword = useSelector((state) => state.webhooks.shouldChangePassword);
  const dispatch = useDispatch();

  const togglePasswordInput = () =>
    dispatch({
      type: 'SET_CHANGE_PASSWORD',
      payload: !newWebhook && !shouldChangePassword
    });

  // format to empty field if input is hidden
  const format = (value, name) =>
    !isInputTouched && name === 'basicPass' && value === PASSWORD_MASK ? '' : value;

  return (
    <FieldSet legend="Basic Auth" legendHidden>
      <Stack>
        <Field
          name="basicUser"
          label="Username"
          component={TextFieldWrapper}
          validate={required}
          disabled={disabled}
          data-sensitive={true}
          placeholder="johndoe"
        />

        {!newWebhook && (
          <Checkbox
            value={shouldChangePassword}
            label="Change password"
            onChange={togglePasswordInput}
            data-track={true}
          />
        )}

        {(shouldChangePassword || newWebhook) && (
          <Field
            name="basicPass"
            label="Password"
            placeholder={isMaskedPassword ? '(Password is saved)' : '•••••••••••••'}
            component={TextFieldWrapper}
            validate={isMaskedPassword ? undefined : required}
            type="password"
            disabled={disabled}
            // eslint-disable-next-line react/jsx-no-bind
            format={newWebhook ? undefined : format}
            data-sensitive={true}
            helpText={newWebhook ? undefined : 'Type a new password to change.'}
            onChange={() => setIsInputTouched(true)}
          />
        )}
      </Stack>
    </FieldSet>
  );
};

const OAuth2Fields = ({ disabled }) => (
  <FieldSet legend="OAuth 2.0" legendHidden>
    <Stack>
      <Field
        name="clientId"
        label="Client ID"
        component={TextFieldWrapper}
        validate={required}
        disabled={disabled}
        data-sensitive={true}
        placeholder="johndoe"
      />

      <Field
        name="clientSecret"
        label="Client Secret"
        type="password"
        autoComplete="new-password"
        component={TextFieldWrapper}
        validate={required}
        disabled={disabled}
        data-sensitive={true}
        placeholder="•••••••••••••"
      />
      <Field
        name="tokenURL"
        label="Token URL"
        component={TextFieldWrapper}
        validate={required}
        disabled={disabled}
        data-sensitive={true}
        placeholder="https://example.com/tokens"
      />
    </Stack>
  </FieldSet>
);

const NameField = ({ disabled, placeholder }) => (
  <Field
    name="name"
    component={TextFieldWrapper}
    validate={[required, maxLength(24)]}
    label="Webhook Name"
    helpText="A friendly label for your webhook, only used for display"
    disabled={disabled}
    data-track={true}
    placeholder={placeholder}
  />
);

const TargetField = ({ disabled, placeholder }) => (
  <Field
    name="target"
    component={TextFieldWrapper}
    validate={[required, url]}
    normalize={(value = '') => value.trim()}
    label="Target URL"
    helpText="An endpoint to receive your webhook. May require use of HTTPS service."
    disabled={disabled}
    data-sensitive={true}
    placeholder={placeholder}
    autocomplete="off"
    data-lpignore="true"
  />
);

const AuthRadio = ({ disabled, authType }) => {
  const [showNoneAuthWarningBanner, setShowNoneAuthWarningBanner] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setShowNoneAuthWarningBanner(!Boolean(authType));
  }, [authType]);

  useEffect(() => {
    dispatch({
      type: 'SET_CHANGE_PASSWORD',
      payload: false
    });
  }, [dispatch, authType]);

  const handleDismissBanner = useCallback(() => {
    setShowNoneAuthWarningBanner(false);
  }, []);

  return (
    <Field
      name="auth"
      label={
        <>
          Authentication Type
          {showNoneAuthWarningBanner && (
            <Box mt="200" mb="-16px">
              <Banner status="warning" size="small" onDismiss={handleDismissBanner}>
                This form of authentication is not recommended.
              </Banner>
            </Box>
          )}
        </>
      }
      component={RadioCardWrapper}
      data-track={true}
      orientation="horizontal"
      options={[
        {
          value: 'oauth2',
          label: 'OAuth 2.0',
          description:
            'An authentication token is required for setup and is checked by the Target URL when accepting the request.'
        },
        {
          value: 'basic',
          label: 'Basic Auth',
          description:
            'The username and password associated with the Target URL is passed through the header during the request'
        },
        {
          value: '',
          label: 'None',
          description: (
            <>
              The target URL does not have an authentication scheme. <Box padding="24px" />
            </>
          )
        }
      ]}
      disabled={disabled}
    />
  );
};

const ActiveField = ({ disabled, onChange }) => {
  const ToggleField = useCallback(
    ({ input, ...rest }) => {
      const handleChange = (...args) => {
        input.onChange(...args);
        setImmediate(() => {
          onChange();
        });
      };

      return (
        <Toggle
          paddingTop="200"
          data-id="active"
          label="Webhook Enabled"
          id="active"
          name="active"
          {...input}
          {...rest}
          checked={input.value}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={handleChange}
        />
      );
    },
    [onChange]
  );

  return (
    <Field
      name="active"
      label="Active"
      component={ToggleField}
      disabled={disabled}
      data-track={true}
    />
  );
};

export { NameField, TargetField, AuthRadio, BasicAuthFields, OAuth2Fields, ActiveField };
