import { tokens } from '@sparkpost/design-tokens';
import { AddCircle, Error, FolderOpen, LastPage } from '@sparkpost/matchbox-icons';
import _ from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserUIOptions } from 'src/actions/currentUser';
import { PageLink } from 'src/components/links';
import { Box, Button, Drawer, ScreenReaderOnly, Tabs, Tooltip } from 'src/components/matchbox';
import { MEDIA_QUERY_TOKENS } from 'src/constants';
import { SidebarContext } from 'src/context/SidebarContext';
import { WindowSizeContext } from 'src/context/WindowSize';
import { parseSearch } from 'src/helpers/analyticsReport';
import { isUserUiOptionSet } from 'src/helpers/conditions/user';
import { allMetricsAreDeliverability, getDeliverabilityOnlyMetrics } from 'src/helpers/metrics';
import { useAlert } from 'src/hooks';
import { ReportOptionsDrawer } from 'src/pages/analyticsReport/components/ReportOptionsDrawer';
import { PRESET_REPORT_CONFIGS } from 'src/pages/analyticsReport/constants';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import { comparisonTypeExists, filterKeyExists } from 'src/pages/analyticsReport/helpers';
import useAnalyticsReportModal from 'src/pages/analyticsReport/hooks/useAnalyticsReportModal';
import {
  CreateButton,
  CreateButtonContainer,
  FooterButton,
  HeaderButton,
  SidebarFooter,
  StyledBoxAlternativeSidebar
} from '../sidebarStyles';
import { FooterSidebar, ReportsTab, SettingsTab } from './components';

import {
  BarChart,
  LibraryAddCheck,
  Mail,
  NotInterested,
  Timer,
  Warning
} from '@sparkpost/matchbox-icons';

const getIconByReportKey = (reportKey) => {
  const icons = {
    summary: <BarChart />,
    engagement: <Mail />,
    bounce: <Warning />,
    delayed: <Timer />,
    rejections: <NotInterested />,
    accepted: <LibraryAddCheck />
  };

  return icons[reportKey] || null;
};

const Sidebar = () => {
  const [drawerTab, setDrawerTab] = useState(0);

  const { screenWidth } = useContext(WindowSizeContext);
  const { isSidebarOpen, openSidebar, closeSidebar } = useContext(SidebarContext);
  const isMobileVersion = screenWidth < MEDIA_QUERY_TOKENS[tokens.mediaQuery_md];
  const SidebarContentContainer = isMobileVersion ? Drawer.Content : StyledBoxAlternativeSidebar;
  const {
    state: reportOptions,
    actions: { refreshReportOptions, setReport, removeFilter },
    modalActions: { openModal },
    selectors
  } = useAnalyticsReportContext();
  const { metrics } = reportOptions;

  const removeMetric = useCallback(
    (index) => {
      const updatedMetrics = [...reportOptions.metrics];
      updatedMetrics.splice(index, 1);
      refreshReportOptions({ metrics: updatedMetrics });
    },
    [refreshReportOptions, reportOptions.metrics]
  );

  const { getDrawerProps, openDrawer, closeDrawer } = Drawer.useDrawer({
    id: 'alerts-options-drawer'
  });

  const handleDrawerOpen = useCallback(
    (index) => {
      setDrawerTab(index);
      openDrawer();
    },
    [openDrawer]
  );

  const { openDeleteModal, openPinToDashboardModal, openEditReportModal, openSaveNewReportModal } =
    useAnalyticsReportModal();

  const reports = useSelector((state) => state.reports.list);
  const subscription = useSelector((state) => state.billing.subscription);
  const reportsLoading =
    useSelector((state) => state.reports.status) === 'loading' || !reportOptions.isReady;
  const favoriteReportsIds = useSelector((state) =>
    isUserUiOptionSet('favorite_reports', [])(state)
  );
  const dispatch = useDispatch();
  const { showAlert } = useAlert();

  const { selectSummaryMetricsProcessed: processedMetrics } = selectors;

  const [selectedTab, setSelectedTab] = useState(0);

  const handleOpenAllReports = useCallback(() => {
    openModal({ type: 'view' });
  }, [openModal]);

  const handleAddtoFavorites = useCallback(
    ({ reportId, reportName, addToFavorites }) => {
      const updatedFavorites = addToFavorites
        ? _.uniqBy([...favoriteReportsIds, reportId])
        : _.uniqBy(favoriteReportsIds.filter((id) => id !== reportId));

      const action = addToFavorites ? `added ${reportName} to` : `removed ${reportName} from`;
      dispatch(
        updateUserUIOptions({
          favorite_reports: updatedFavorites
        })
      ).then(() => {
        showAlert({
          type: 'success',
          message: `You have successfully ${action} Favorite Reports`
        });
      });
    },
    [dispatch, favoriteReportsIds, showAlert]
  );

  const sidebarReportsActions = useCallback(
    ({ report = {} }) => {
      const { current_user_can_edit } = report;
      let isReportFavorite = Boolean(favoriteReportsIds.find((id) => id === report?.id));

      return [
        current_user_can_edit && {
          content: 'Delete',
          onClick: () => {
            openDeleteModal(report);
          }
        },
        {
          content: 'Schedule',
          to: `/signals/schedule/${report.id}`,
          component: PageLink
        },
        {
          content: 'Pin to Dashboard',
          onClick: () => {
            openPinToDashboardModal(report);
          }
        },
        current_user_can_edit && {
          content: 'Edit',
          onClick: () => {
            openEditReportModal(report);
          }
        },
        !isReportFavorite && {
          content: 'Add to Favorites',
          onClick: () =>
            handleAddtoFavorites({
              reportId: report?.id,
              reportName: report?.name,
              addToFavorites: true
            })
        },
        isReportFavorite && {
          content: 'Remove from Favorites',
          onClick: () =>
            handleAddtoFavorites({
              reportId: report?.id,
              reportName: report?.name,
              addToFavorites: false
            })
        }
      ].filter((x) => x);
    },
    [
      favoriteReportsIds,
      handleAddtoFavorites,
      openDeleteModal,
      openEditReportModal,
      openPinToDashboardModal
    ]
  );

  const presetReports = PRESET_REPORT_CONFIGS.map((report) => ({
    title: report.name,
    icon: getIconByReportKey(report.key),
    onClick: () => {
      const reportOptions = parseSearch(report.query_string);
      refreshReportOptions(reportOptions);
      setReport(report);
    },
    actions: sidebarReportsActions({ report: report })
  }));

  const isMaxReports = useMemo(() => {
    const reportsProduct = subscription?.products?.find(({ product }) => product === 'reports');

    if (!reportsProduct) {
      return true;
    }
    const { limit, quantity = 0 } = reportsProduct;
    return quantity >= limit;
  }, [subscription]);

  function filterMetricsBasedOnSubjectCampaignsFilter(filters, metrics) {
    if (
      filters &&
      metrics &&
      filterKeyExists(filters, 'subject_campaigns') &&
      !allMetricsAreDeliverability(metrics)
    ) {
      return getDeliverabilityOnlyMetrics(metrics);
    }
  }

  function filterMetricsBasedOnSubjectCampaignsCompare(comparisons, metrics) {
    if (
      comparisons &&
      metrics &&
      comparisonTypeExists(comparisons, 'subject_campaigns') &&
      !allMetricsAreDeliverability(metrics)
    ) {
      return getDeliverabilityOnlyMetrics(metrics);
    }
  }

  const handleSubmitForAllTabs = useCallback(
    (props) => {
      // Because we need to remove non-deliverability metrics if the user picks a subject_campaigns filter (API will break otherwise...)
      const metricsForSubjectCampaignFilter = filterMetricsBasedOnSubjectCampaignsFilter(
        props?.filters,
        metrics
      );
      if (metricsForSubjectCampaignFilter) props.metrics = metricsForSubjectCampaignFilter;

      // Because we need to remove non-deliverability metrics if the user picks a subject_campaigns compare by (API will break otherwise...)
      const metricsForSubjectCampaignCompare = filterMetricsBasedOnSubjectCampaignsCompare(
        props?.comparisons,
        metrics
      );
      if (metricsForSubjectCampaignCompare) props.metrics = metricsForSubjectCampaignCompare;

      refreshReportOptions(props);
      closeDrawer();
    },
    [closeDrawer, metrics, refreshReportOptions]
  );

  if (!isSidebarOpen) {
    return (
      <StyledBoxAlternativeSidebar data-id="analytics-report-sidebar">
        <Tooltip
          content="Create new report"
          key="create-report"
          as="div"
          id="create_report_tooltip"
        >
          <HeaderButton disabled={isMaxReports} onClick={openSaveNewReportModal}>
            <ScreenReaderOnly>Create New Report</ScreenReaderOnly>
            {isMaxReports ? (
              <Tooltip
                id="reports_limit_tooltip"
                content="Your account has reached its limit on custom saved reports. You either need to delete a report or upgrade your plan."
              >
                <Button.Icon as={AddCircle} size={22} />
              </Tooltip>
            ) : (
              <Button.Icon as={AddCircle} size={22} />
            )}
          </HeaderButton>
        </Tooltip>

        <SidebarFooter reverse>
          <Tooltip content="Open Sidebar" key="open-sidebar" as="div" id="open_sidebar_tooltip">
            <FooterButton onClick={openSidebar} variant="minimal">
              <ScreenReaderOnly>Open Sidebar</ScreenReaderOnly>
              <Button.Icon as={LastPage} size={22} />
            </FooterButton>
          </Tooltip>
          <Tooltip content="All Reports" key="all-reports" as="div" id="all_reports_tooltip">
            <FooterButton onClick={handleOpenAllReports} variant="minimal" first={true}>
              <ScreenReaderOnly>All Reports</ScreenReaderOnly>
              <Button.Icon as={FolderOpen} size={22} />
            </FooterButton>
          </Tooltip>
        </SidebarFooter>
      </StyledBoxAlternativeSidebar>
    );
  }

  return (
    <Box display="flex" flexDirection="column" data-id="analytics-report-sidebar">
      <Box paddingX="500" paddingBottom={0} paddingTop="750">
        <CreateButtonContainer>
          {isMaxReports && (
            <Tooltip
              id="reports_limit_tooltip"
              content="Your account has reached its limit on custom saved reports. You either need to delete a report or upgrade your plan."
            >
              <div tabIndex="0" data-id="reports-limit-tooltip-icon">
                <Error color="gray.700" />
              </div>
            </Tooltip>
          )}
          <CreateButton disabled={isMaxReports} variant="primary" onClick={openSaveNewReportModal}>
            Create New Report
          </CreateButton>
        </CreateButtonContainer>
      </Box>
      <Box marginY={450} marginX={500} border="400" height="88%" width={!isMobileVersion && 272}>
        <Tabs
          selected={selectedTab}
          onSelect={setSelectedTab}
          tabs={[{ content: 'Reports' }, { content: 'Settings' }]}
          fitted
        />
        <Box>
          {selectedTab === 0 && (
            <ReportsTab
              SidebarContentContainer={SidebarContentContainer}
              presetReports={presetReports}
              reports={reports}
              reportsLoading={reportsLoading}
              sidebarReportsActions={sidebarReportsActions}
            />
          )}
          {selectedTab === 1 && (
            <SettingsTab
              handleDrawerOpen={handleDrawerOpen}
              removeFilter={removeFilter}
              removeMetric={removeMetric}
            />
          )}
          {isSidebarOpen && (
            <FooterSidebar
              closeSidebar={closeSidebar}
              handleOpenAllReports={handleOpenAllReports}
              isMobileVersion={isMobileVersion}
            />
          )}
        </Box>
      </Box>

      <ReportOptionsDrawer
        getDrawerProps={getDrawerProps}
        drawerTab={drawerTab}
        processedMetrics={processedMetrics}
        handleSubmitForAllTabs={handleSubmitForAllTabs}
      />
    </Box>
  );
};

export default Sidebar;
