import _ from 'lodash';

/**
 *
 * @param {Array<Object>} seedList - List of seeds retrieved from the seed list endpoint. See https://developers.sparkpost.com/api/seed-list/#seed-list-get-retrieve-seed-list
 * @returns {string} a single reference seed email address
 */
export function getReferenceSeed(seedList) {
  return seedList.find((seed) => seed.endsWith('seed.sparkpost.com'));
}
