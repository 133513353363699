import { Table as MatchboxTable } from '@sparkpost/matchbox';

/**
 * Matchbox Table component.
 * @see https://design.sparkpost.com/components/table/
 */
const Table = MatchboxTable;

const Cell = MatchboxTable.Cell;

const HeaderCell = MatchboxTable.HeaderCell;

const Row = MatchboxTable.Row;

const TotalsRow = MatchboxTable.TotalsRow;

const SortButton = MatchboxTable.SortButton;

Table.displayName = 'Table';
Cell.displayName = 'Table.Cell';
HeaderCell.displayName = 'Table.HeaderCell';
Row.displayName = 'Table.Row';
TotalsRow.displayName = 'Table.TotalsRow';
SortButton.displayName = 'Table.SortButton';

Table.Cell = Cell;
Table.HeaderCell = HeaderCell;
Table.Row = Row;
Table.TotalsRow = TotalsRow;
Table.SortButton = SortButton;

export default Table;
