import { VpnKey } from '@sparkpost/matchbox-icons';
import React, { ChangeEvent, FormEvent, useCallback } from 'react';
import { QRCode } from 'react-qr-svg';
import { connect } from 'react-redux';
import { showAlert } from 'src/actions/globalAlert';
import { getTfaSecret, toggleTfa } from 'src/actions/tfa';
import ButtonWrapper from 'src/components/buttonWrapper';
import { Loading } from 'src/components/loading/Loading';
import { Button, Grid, Panel, Stack, TextField } from 'src/components/matchbox';
import { TranslatableText } from 'src/components/text';
import { Form } from 'src/components/tracking';
import { usernameSelector } from 'src/selectors/currentUser';
import styled from 'styled-components';
import styles from './EnableTfaForm.module.scss';

const RightAlignedText = styled.div`
  text-align: 'right';
`;

const QRIcon = styled.div`
  width: 230px;
`;

type EnableTfaFormProps = {
  afterEnable: () => void;
  getTfaSecret: () => void;
  showAlert: ({ type, message }: { message: string; type: string }) => void;
  toggleError: boolean;
  togglePending: boolean;
  toggleTfa: ({ enabled, code }: { code: string; enabled: boolean }) => void;
};

export class EnableTfaForm extends React.Component<EnableTfaFormProps> {
  state = {
    code: ''
  };

  componentDidMount() {
    this.props.getTfaSecret();
  }

  componentDidUpdate(prevProps: EnableTfaFormProps) {
    const { togglePending, toggleError, showAlert, afterEnable } = this.props;
    // If we just finished a toggle operation without an error, hit the afterEnable callback.
    if (prevProps.togglePending && !togglePending && !toggleError) {
      showAlert({ type: 'success', message: 'Two-factor authentication enabled' });
      afterEnable();
    }
  }

  handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    this.setState({ code: target.value });
  };

  onEnable = () => {
    const { toggleTfa } = this.props;
    return toggleTfa({ enabled: true, code: this.state.code });
  };

  renderForm() {
    return (
      <RenderedForm
        {...this.props}
        code={this.state.code}
        handleInputChange={this.handleInputChange}
        onEnable={this.onEnable}
      />
    );
  }

  render() {
    return this.renderForm();
  }
}

type RenderedFormProps = {
  code: string;
  handleInputChange: ({ target }: ChangeEvent<HTMLInputElement>) => void;
  onClose?: () => void;
  onEnable: () => void;
  secret?: string;
  toggleError: boolean;
  togglePending?: boolean;
  username?: string;
};

export const RenderedForm = (props: RenderedFormProps) => {
  const {
    code,
    handleInputChange,
    onClose,
    onEnable,
    secret,
    togglePending,
    toggleError,
    username = ''
  } = props;

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onEnable();
    },
    [onEnable]
  );

  if (!secret) {
    return (
      <div className={styles.Loading}>
        <Loading />
      </div>
    );
  }

  const qrData = `otpauth://totp/${username}?secret=${encodeURIComponent(secret)}&issuer=SparkPost`;

  return (
    <Form onSubmit={handleSubmit} id="enable-tfa-form">
      <Panel.LEGACY.Section>
        <Stack space="600">
          <Grid>
            <Grid.Column xs={12} md={7}>
              <Stack>
                <h6>Step 1: Configure your MFA app</h6>
                <p>
                  To enable MFA, you&#39;ll need to have a MFA auth app installed on your phone or
                  tablet (examples include Google Authenticator, Duo Mobile, and Authy).
                </p>
                <p>
                  Most apps will let you get set up by scanning our QR code from within the app. If
                  you prefer, you can type in the key manually.
                </p>
                <p>
                  <strong>
                    <VpnKey /> <code>{secret}</code>
                  </strong>
                </p>
              </Stack>
            </Grid.Column>
            <Grid.Column xs={12} md={5}>
              <RightAlignedText>
                <QRIcon as={QRCode} bgColor="#FFFFFF" fgColor="#000000" level="Q" value={qrData} />
              </RightAlignedText>
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column xs={12} md={7}>
              <Stack>
                <h6>Step 2: Enter a MFA code</h6>
                <p>
                  Generate a code from your newly-activated MFA app to confirm that you&#39;re all
                  set up.
                </p>
                <TextField
                  id="tfa-setup-passcode"
                  name="Passcode"
                  label="Passcode"
                  data-sensitive={true}
                  required={true}
                  data-lpignore={true}
                  error={toggleError ? 'Problem verifying your code, please try again' : ''}
                  placeholder="Enter a generated MFA passcode"
                  onChange={handleInputChange}
                  value={code}
                />
              </Stack>
            </Grid.Column>
          </Grid>
        </Stack>
      </Panel.LEGACY.Section>

      <Panel.LEGACY.Section>
        <ButtonWrapper>
          <Button type="submit" variant="primary" disabled={togglePending}>
            {togglePending ? (
              <TranslatableText>Verifying Code...</TranslatableText>
            ) : (
              <TranslatableText>Enable MFA</TranslatableText>
            )}
          </Button>
          {onClose && (
            <Button variant="secondary" disabled={togglePending} onClick={onClose}>
              Cancel
            </Button>
          )}
        </ButtonWrapper>
      </Panel.LEGACY.Section>
    </Form>
  );
};

const mapStateToProps = (state: $TODOFIXME) => ({
  ...state.tfa,
  username: state.currentUser.email || usernameSelector(state),
  enabled: state.tfa.enabled === true
});

export default connect(mapStateToProps, {
  getTfaSecret,
  toggleTfa,
  showAlert
})(EnableTfaForm);
