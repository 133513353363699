export const selectRecipientDomains = ({ domains = [] } = {}) =>
  domains.map((value) => ({
    type: 'domains',
    value
  }));

export const selectSubaccounts = (subaccounts = []) =>
  subaccounts.map(({ name, id }) => ({
    type: 'subaccounts',
    value: `${name} (ID ${id})`,
    id
  }));

export const selectSendingDomains = ({ 'sending-domains': sendingDomains = [] } = {}) =>
  sendingDomains.map((sendingDomain) => ({
    type: 'sending_domains',
    value: sendingDomain
  }));

export const selectCampaigns = ({ campaigns = [] } = {}) =>
  campaigns.map((value) => ({
    type: 'campaigns',
    value
  }));

export const selectSubjectCampaigns = ({ 'subject-campaigns': subjectCampaigns = [] } = {}) =>
  subjectCampaigns.map((value) => ({
    type: 'subject_campaigns',
    value
  }));

export const selectSendingIps = (data = {}) => {
  const sendingIps = data['sending-ips'] || [];
  return sendingIps.map((value) => ({
    type: 'sending_ips',
    value
  }));
};

export const selectIpPools = (data = {}) => {
  const ipPools = data['ip-pools'] || [];
  return ipPools.map((value) => ({
    type: 'ip_pools',
    value
  }));
};

export const selectTemplates = ({ templates = [] } = {}) =>
  templates.map((value) => ({
    type: 'templates',
    value
  }));

export const selectMailboxProviders = ({ 'mailbox-providers': mailboxProviders = [] } = {}) =>
  mailboxProviders.map((value) => ({
    type: 'mailbox_providers',
    value
  }));

export const selectMailboxProviderRegions = ({
  'mailbox-provider-regions': mailboxProviderRegions = []
} = {}) =>
  mailboxProviderRegions.map((value) => ({
    type: 'mailbox_provider_regions',
    value
  }));
