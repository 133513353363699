import _ from 'lodash';
import { matchPath } from 'react-router';
import { hibanaRoutes } from 'src/appConfig/routes';

export default function findRouteByPath(pathToFind, options = {}, routes = hibanaRoutes) {
  // matchPath uses the same matching that react router's <Route> uses
  return (
    _.find(routes, ({ path, exact = true }) =>
      matchPath(pathToFind, {
        path,
        exact,
        strict: false,
        ...options
      })
    ) || {}
  );
}
