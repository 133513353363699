import { useMemo, useReducer, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useSparkPostQuery, useMultiEntry } from 'src/hooks';
import isIP from 'validator/lib/isIP';

import { getMonitors } from 'src/helpers/api/blocklist';
import { getIpPools } from 'src/helpers/api/ipPools';
import { useSparkPostMutation, useAlert } from 'src/hooks';
import { addMonitors } from 'src/helpers/api/blocklist';
import useSubaccounts from 'src/hooks/useSubaccounts';

const initialState = {
  sparkpostIpsOption: 'no-ips',
  selectedIpPool: '',
  selectedIps: [],
  subaccountOption: 'all-subaccounts',
  subaccount: null,
  allIpsCheckbox: false,
  errors: {}
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_IP': {
      if (state.selectedIps.includes(action.checkbox)) {
        return {
          ...state,
          errors: {},
          selectedIps: [...state.selectedIps.filter((ip) => action.checkbox !== ip)]
        };
      } else {
        return {
          ...state,
          errors: {},
          selectedIps: [...state.selectedIps, action.checkbox]
        };
      }
    }

    case 'SELECT_IP_POOL':
      return {
        ...state,
        errors: {},
        ipCheckboxes: {},
        selectedIpPool: action.value
      };

    case 'EXTERNAL_IP_FIELD_UPDATE':
      return {
        ...state,
        errors: {},
        externalIps: action.value
      };

    case 'SELECT_SUBACCOUNT': {
      const hasRestrictedIPPool = Boolean(action.subaccount?.ip_pool);
      return {
        ...state,
        errors: {},
        subaccount: action.subaccount,
        selectedIps: hasRestrictedIPPool ? [] : state.selectedIps,
        selectedIpPool: hasRestrictedIPPool ? action.subaccount.ip_pool : state.selectedIpPool,
        allIpsCheckbox: hasRestrictedIPPool ? false : state.allIpsCheckbox
      };
    }

    case 'SELECT_SUBACCOUNT_OPTION':
      return {
        ...state,
        errors: {},
        subaccountOption: action.subaccountOption
      };

    case 'SELECT_SPARKPOST_IP_OPTION':
      return {
        ...state,
        errors: {},
        sparkpostIpsOption: action.sparkpostIpsOption
      };

    case 'EXTERNAL_IPS_CHANGE': {
      return {
        ...state,
        errors: {},
        externalIps: action.externalIps
      };
    }

    case 'SET_ERROR': {
      return {
        ...state,
        errors: action.errors
      };
    }

    case 'TYPEAHEAD_CHANGE': {
      return {
        ...state,
        selectedIps: action.selectedIps
      };
    }

    case 'TOGGLE_ALL_IPS': {
      return {
        ...state,
        allIpsCheckbox: !state.allIpsCheckbox
      };
    }

    default:
      throw new Error(`Invalid action ${action.type}`);
  }
};

export function useIpsForm() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { status: monitorsStatus, data: monitorsData } = useSparkPostQuery(getMonitors, {
    select: (data) => {
      return data.reduce((acc, { resource }) => {
        acc[resource] = true;
        return acc;
      }, {});
    }
  });

  const handleExternalIpsChange = useCallback(
    (newValues) => {
      if (newValues.some((ip) => Boolean(monitorsData[ip]))) {
        return 'IP is already being monitored.';
      } else if (newValues.some((ip) => !isIP(ip))) {
        return 'Resource entered is not a valid IP.';
      } else {
        return;
      }
    },
    [monitorsData]
  );

  const { setValues, ...multiEntry } = useMultiEntry(undefined, {
    validate: handleExternalIpsChange
  });

  const { status: ipPoolsStatus, data: ipPoolsData } = useSparkPostQuery(getIpPools);
  const { subaccountsQuery } = useSubaccounts();

  const history = useHistory();
  const { showAlert } = useAlert();

  const availableIpPools = useMemo(() => {
    if (!(monitorsStatus === 'success' && ipPoolsStatus === 'success')) {
      return [];
    }
    return ipPoolsData;
  }, [monitorsStatus, ipPoolsStatus, ipPoolsData]);
  const selectedIpPool = useMemo(() => {
    return availableIpPools.find(({ id }) => id === state.selectedIpPool);
  }, [availableIpPools, state.selectedIpPool]);

  const allIps = useMemo(() => {
    if (ipPoolsStatus !== 'success' || monitorsStatus !== 'success') {
      return [];
    }
    return ipPoolsData.reduce((acc, { ips }) => {
      ips.forEach(({ external_ip }) => {
        if (!Boolean(monitorsData[external_ip])) {
          acc.push(external_ip);
        }
      });
      return acc;
    }, []);
  }, [ipPoolsStatus, ipPoolsData, monitorsStatus, monitorsData]);

  const ipsFromSelectedPool = useMemo(() => {
    const ipsByKey = state.selectedIps.reduce((acc, ip) => {
      acc[ip] = true;
      return acc;
    }, {});
    if (!selectedIpPool) {
      return [];
    }

    return selectedIpPool.ips.map(({ external_ip: ip }, index) => {
      return {
        value: ip,
        checked: Boolean(ipsByKey[ip]),
        name: `checkbox-${ip}`,
        id: `ip-checkbox-${index}`,
        label: ip,
        disabled: monitorsData[ip], //If IP is in monitors, disables checkbox
        onChange: () => dispatch({ type: 'TOGGLE_IP', checkbox: ip })
      };
    });
  }, [selectedIpPool, monitorsData, dispatch, state.selectedIps]);

  //Unique values for all the IPs in the structure of an array

  const subaccountMap = useCallback(
    (ip) => {
      if (state.subaccountOption === 'all-subaccounts') {
        return { resource: ip, shared_with_subaccounts: true };
      } else if (state.subaccountOption === 'primary-subaccount') {
        return { resource: ip };
      } else {
        return { resource: ip, subaccount: state.subaccount?.id };
      }
    },
    [state.subaccountOption, state.subaccount]
  );
  const monitorsToAdd = [
    ...new Set([...(state.allIpsCheckbox ? allIps : state.selectedIps), ...multiEntry.valueList])
  ].map(subaccountMap);

  const mutation = useSparkPostMutation(() => addMonitors(monitorsToAdd), {
    onSuccess: () => {
      showAlert({ type: 'success', message: `Added resources to watchlist` });
      history.push('/signals/blocklist/monitors');
    }
  });

  function handleSubmit(e) {
    e.preventDefault();

    const errors = {};

    if (monitorsToAdd.length === 0) {
      errors['no-ips-banner'] = 'Select IPs to monitor.';
    }

    if (Object.keys(errors).length > 0) {
      return dispatch({ type: 'SET_ERROR', errors });
    }

    mutation.mutate();
  }

  const select = {
    availableIpPools,
    selectedIpPool,
    ipsFromSelectedPool
  };

  return {
    select,
    allIps,
    mutation,
    handleSubmit,
    state,
    dispatch,
    subaccountsQuery,
    multiEntry
  };
}
