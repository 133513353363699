import { tokens } from '@sparkpost/design-tokens';
import React from 'react';
import { ActiveFilters } from 'src/components';
import { Box, Panel, Stack, Tag, Text } from 'src/components/matchbox';
import { Heading, TranslatableText } from 'src/components/text';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import styled from 'styled-components';
import { useAlertForm } from '../useAlertForm';

const StyledQuote = styled(Text).attrs({
  as: 'blockquote'
})`
  border-left: 1px solid #d9e0e6;
  padding-left: ${tokens.spacing_200};
  margin-left: ${tokens.spacing_200};
  margin-top: 0;
  color: ${tokens.color_gray_700};
`;

const CONDITION_LABELS = {
  gt: 'above',
  lt: 'below'
};

export default function AccountUsageAlertSummary() {
  const {
    state: { metricData, conditionData }
  } = useAlertForm();
  const { state: reportOptions } = useAnalyticsReportContext();

  return (
    <Box height="296px">
      <Panel>
        <Panel.Section>
          <Stack>
            <Heading as="h4">Alert Summary</Heading>
            {!metricData.selectedMetric.key && (
              <Text>
                The alert summary will auto-populate when a metric and configurations are selected.
              </Text>
            )}
            {metricData.selectedMetric.key && (
              <Box>
                <TranslatableText>The alert is triggered when </TranslatableText>
                <Tag color="blue">{metricData.selectedMetric.label}</Tag>
                <TranslatableText> is </TranslatableText>
                {conditionData.condition && (
                  <>
                    <Tag color="blue">{CONDITION_LABELS[conditionData.condition]}</Tag>{' '}
                  </>
                )}

                {conditionData.alertThreshold && (
                  <Tag color="blue">{`${conditionData.alertThreshold}%`}</Tag>
                )}
              </Box>
            )}
            {reportOptions.filters.length > 0 && (
              <StyledQuote>
                <ActiveFilters filters={reportOptions.filters} />
              </StyledQuote>
            )}
          </Stack>
        </Panel.Section>
      </Panel>
    </Box>
  );
}
