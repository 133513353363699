import React from 'react';
import {
  ComboBoxTextField as MatchboxComboBoxTextField,
  ComboBoxTextFieldProps
} from '@sparkpost/matchbox';
import { FIELD_MAX_WIDTH } from 'src/constants';
import { Box } from 'src/components/matchbox';

type ComboBoxTextFieldComponentProps = ComboBoxTextFieldProps & {
  maxItems?: number;
  maxWidth?: number;
};

/**
 * Matchbox ComboBoxTextField component
 * @see https://design.sparkpost.com/components/combobox/
 */
function ComboBoxTextField(props: ComboBoxTextFieldComponentProps): JSX.Element {
  const { maxWidth, maxItems, ...rest } = props;

  const maxItemsReached = maxItems && rest.selectedItems && rest.selectedItems.length >= maxItems;

  const value =
    props.selectedItems && props.itemToString
      ? props.selectedItems.map((x) => props.itemToString && props.itemToString(x)).join(',')
      : '';

  return (
    <Box maxWidth={maxWidth ? maxWidth : FIELD_MAX_WIDTH}>
      <MatchboxComboBoxTextField
        {...rest}
        readOnly={Boolean(rest.readOnly || maxItemsReached)}
        placeholder={maxItemsReached ? undefined : rest.placeholder}
      />

      <input
        type="hidden"
        data-label={props.label}
        id={props.id}
        name={props.name}
        value={value}
        data-track={props['data-track']}
      ></input>
    </Box>
  );
}

ComboBoxTextField.displayName = 'ComboBoxTextField';

export default ComboBoxTextField;
