import React from 'react';
import { ScreenReaderOnly } from 'src/components/matchbox';
import { PolymorphicComponentProps } from 'src/typescript';

type BodyProps<E extends React.ElementType> = PolymorphicComponentProps<
  E,
  {
    rows: $TODOFIXME[];
  }
>;

type HeadComponent = (props: { headers: string[] }) => JSX.Element;

type ScreenReaderOnlyTableComponent = (
  props: React.PropsWithChildren<{ caption: string }>
) => JSX.Element;

type ScreenReaderOnlyTable = {
  Body: { displayName: string } & typeof Body;
  Head: { displayName: string } & HeadComponent;
} & ScreenReaderOnlyTableComponent;

const ScreenReaderOnlyTable: ScreenReaderOnlyTable = ({ caption, children }) => {
  return (
    <ScreenReaderOnly as="div">
      <table>
        <caption>{caption}</caption>
        {children}
      </table>
    </ScreenReaderOnly>
  );
};

const Head: ScreenReaderOnlyTable['Head'] = ({ headers }) => {
  return (
    <thead>
      <tr>
        {headers.map((header, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <th key={`sr-only-th-${index}`} scope="col">
              {header}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const Body = <E extends React.ElementType = 'tr'>(props: BodyProps<E>): JSX.Element => {
  const { rows, as: asComponent = 'tr', ...rest } = props;
  const BodyRow = asComponent;

  return (
    <tbody>
      {rows.map((row, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return <BodyRow key={`sr-only-td-${index}`} rowData={row} {...rest} />;
      })}
    </tbody>
  );
};

ScreenReaderOnlyTable.Head = Head;
Head.displayName = 'ScreenReaderOnlyTable.Head';
ScreenReaderOnlyTable.Body = Body;
Body.displayName = 'ScreenReaderOnlyTable.Body';

export { ScreenReaderOnlyTable };
