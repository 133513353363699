/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useMemo } from 'react';
import { Button, Stack, Text } from 'src/components/matchbox';
import Box from 'src/components/matchbox/Box';
import { SidebarExpandable } from 'src/components/SidebarExpandableItem/SidebarExpandableItem';
import ActiveComparisons from './ActiveComparisons';
import ActiveFilters from './ActiveFilters';
import ActiveMetrics from './ActiveMetrics';
import ActiveDataSources from './DataSources';
import PrefetchedOpens from './Prefetched';

// HOOKS
import { INDUSTRY_BENCHMARK_METRICS_MAP } from '@sparkpost/report-builder/config';
import { useWindowSize } from 'src/hooks/index.js';
import { AlternativeIndustryBenchmarkFields } from 'src/pages/analyticsReport/components/AlternativeIndustryBenchmarkFields';
import { useAnalyticsReportContext } from 'src/pages/analyticsReport/context/AnalyticsReportContext';

// STYLES
import { AlternativeSidebarWrapper, Section } from '../../sidebarStyles';

// TYPES
import { ReportOptionsDrawerTabEnum, UseAnalyticsReportContext } from 'src/typescript';

const HEADING_HEIGHT = 434;

type SettingsTabProps = {
  handleDrawerOpen: (index: ReportOptionsDrawerTabEnum) => void;
  removeFilter: (values: {
    filterIndex: number;
    groupingIndex: number;
    valueIndex: number;
  }) => void;
  removeMetric: (index: number) => void;
};

function SettingsTab({
  handleDrawerOpen,
  removeFilter,
  removeMetric
}: SettingsTabProps): JSX.Element {
  const { height } = useWindowSize();
  const {
    state: reportOptions,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    actions: { removeComparisonFilter },
    selectors,
    dataSourceOptions,
    canSelectPrefetchedOpens
  } = useAnalyticsReportContext() as UseAnalyticsReportContext;
  const { checkboxes } = dataSourceOptions;
  const { formattedMetrics } = selectors;

  const hasD12yMetrics = useMemo(
    () => formattedMetrics.some(({ product }: $TODOFIXME): boolean => product === 'deliverability'),
    [formattedMetrics]
  );

  const openMetricDrawer = useCallback(() => {
    handleDrawerOpen(ReportOptionsDrawerTabEnum.METRICS);
  }, [handleDrawerOpen]);

  const openFilterDrawer = useCallback(() => {
    handleDrawerOpen(ReportOptionsDrawerTabEnum.FILTERS);
  }, [handleDrawerOpen]);

  const openComparisonDrawer = useCallback(() => {
    handleDrawerOpen(ReportOptionsDrawerTabEnum.COMPARE);
  }, [handleDrawerOpen]);

  const handleFilterRemove = useCallback(
    ({
      groupingIndex,
      filterIndex,
      valueIndex
    }: {
      filterIndex: number;
      groupingIndex: number;
      valueIndex: number;
    }) => {
      return removeFilter({ groupingIndex, filterIndex, valueIndex });
    },
    [removeFilter]
  );

  const handleFilterMetric = useCallback(
    (metricIndex: number): void => {
      return removeMetric(metricIndex);
    },
    [removeMetric]
  );

  const industryBenchmarkMetrics = reportOptions.metrics?.filter(
    (metric: string) => INDUSTRY_BENCHMARK_METRICS_MAP[metric]
  );
  const allowIndustryBenchmark = industryBenchmarkMetrics.length !== 0;

  return (
    <AlternativeSidebarWrapper windowSize={height! - HEADING_HEIGHT}>
      <Section>
        <SidebarExpandable title="Metrics" id="metrics-settings" defaultOpen>
          <Box px="400" pb="400">
            <Stack space="200">
              <ActiveMetrics
                metrics={formattedMetrics}
                precision={reportOptions.precision}
                removeMetric={handleFilterMetric}
              />
              <Box>
                <Button
                  variant="mutedOutline"
                  color="blue"
                  onClick={openMetricDrawer}
                  useMatchboxVariant={true}
                  size="small"
                >
                  Add Metric
                </Button>
              </Box>
            </Stack>
          </Box>
        </SidebarExpandable>
      </Section>

      <Section>
        <SidebarExpandable title="Filters" id="filters-settings" defaultOpen>
          <Box px="400" pb="400">
            <Stack space="200">
              <Box>
                <ActiveFilters
                  filters={reportOptions.filters}
                  handleFilterRemove={handleFilterRemove}
                />
              </Box>
              <Box>
                <Button
                  variant="mutedOutline"
                  color="blue"
                  onClick={openFilterDrawer}
                  useMatchboxVariant={true}
                  size="small"
                >
                  Add Filter
                </Button>
              </Box>
            </Stack>
          </Box>
        </SidebarExpandable>
      </Section>

      <Section>
        <SidebarExpandable title="Compare" id="compare-settings" defaultOpen>
          <Box px="400" pb="400">
            <Stack space="200">
              {Boolean(reportOptions.comparisons.length) && (
                <ActiveComparisons
                  comparisons={reportOptions.comparisons}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
                  handleFilterRemove={(index) => removeComparisonFilter({ index })}
                />
              )}
              <Box>
                <Button
                  variant="mutedOutline"
                  color="blue"
                  onClick={openComparisonDrawer}
                  useMatchboxVariant={true}
                  size="small"
                >
                  Add Comparison
                </Button>
              </Box>
            </Stack>
          </Box>
        </SidebarExpandable>
      </Section>
      {hasD12yMetrics && (
        <Section p="400">
          <ActiveDataSources checkboxes={checkboxes} />
        </Section>
      )}

      {allowIndustryBenchmark && (
        <Section p="400">
          <Stack space="200">
            <Text fontSize="200" fontWeight="500">
              Industry Benchmark
            </Text>
            <Box>
              <AlternativeIndustryBenchmarkFields />
            </Box>
          </Stack>
        </Section>
      )}

      {canSelectPrefetchedOpens && (
        <Section p="400">
          <PrefetchedOpens />
        </Section>
      )}
    </AlternativeSidebarWrapper>
  );
}

export default SettingsTab;
