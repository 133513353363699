import React from 'react';
import { useSparkPostQuery } from 'src/hooks';
import { getSubaccounts } from 'src/helpers/api/subaccounts';
import { selectSubaccountsForTypeahead } from 'src/helpers/api/selectors/subaccounts';
import { Panel } from 'src/components/matchbox';
import { SubaccountTypeSelect } from 'src/components/typeahead';

export function SubaccountPanel({ onChange, value, disabled }) {
  const { status, data: subaccounts } = useSparkPostQuery(
    () => getSubaccounts({ params: { option: 'deliverability' } }),
    {
      refetchOnWindowFocus: false,
      select: (subaccounts) =>
        selectSubaccountsForTypeahead(subaccounts, { hasPrimaryAccountOption: true })
    }
  );

  return (
    <Panel>
      <Panel.Section>
        <SubaccountTypeSelect
          subaccounts={subaccounts}
          onChange={onChange}
          value={value}
          helpText="Seed list displayed will be specific to this account"
          disabled={disabled || status === 'loading'}
        />
      </Panel.Section>
    </Panel>
  );
}
