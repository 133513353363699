import React from 'react';
import styled from 'styled-components';

const WordWrapper = styled.span`
  word-wrap: break-word;
`;

type WrappedTextProps = {
  children: React.ReactNode;
};

const WrappedText = ({ children }: WrappedTextProps): JSX.Element => {
  return <WordWrapper>{children}</WordWrapper>;
};

export default WrappedText;
