import React from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import { Box, Button, Panel, Pagination, Table } from 'src/components/matchbox';
import { ShowChart } from '@sparkpost/matchbox-icons';
import { Empty, Percent, Unit } from 'src/components';
import { TableSkeleton } from 'src/components/loading';
import { divide } from '@sparkpost/report-builder/helpers/math';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PER_PAGE } from 'src/constants';
import useResourceDetailsContext from '../../hooks/useResourceDetailsContext';
import { PageLink } from 'src/components/links';

export function LinkEngagementTableContainer({ totalClicks, links = [], loading, isError }) {
  if (loading) {
    return (
      <Panel>
        <Panel.Section>
          <TableSkeleton numberOfColumns={4} numberOfRows={5} />
        </Panel.Section>
      </Panel>
    );
  }
  if (isError) {
    return (
      <Panel>
        <Empty message="Failed to load the data. Refresh page."></Empty>
      </Panel>
    );
  }

  if (!Boolean(links.length))
    return (
      <Panel>
        <Empty message="No results found." />
      </Panel>
    );

  return <LinkEngagementTable totalClicks={totalClicks} links={links} />;
}

export function LinkEngagementTable({ totalClicks, links }) {
  const { filters } = useResourceDetailsContext();

  let queryParamsForMetrics = ['count_unique_clicked', 'count_clicked']
    .map((x) => `&metrics=${x}`)
    .join('');

  let viewFullReportLink = '/signals/analytics';
  if (filters && filters.to && filters.from) {
    const TO = new Date(filters.to);
    const FROM = new Date(filters.from);
    viewFullReportLink = `/signals/analytics?from=${FROM.toISOString()}&to=${TO.toISOString()}${queryParamsForMetrics}`;
  }

  const tableInstanceColumns = React.useMemo(
    () => [
      {
        Header: 'Link',
        width: '40%',
        textAlign: 'left',
        accessor: (row) => {
          return row.link_name;
        }
      },
      {
        Header: 'Unique Clicks',
        width: '20%',
        textAlign: 'right',
        accessor: (row) => {
          return row.count_raw_clicked;
        },
        sortType: 'basic'
      },
      {
        Header: 'Clicks',
        width: '20%',
        textAlign: 'right',
        accessor: (row) => {
          return row.count_clicked;
        },
        sortType: 'basic'
      },
      {
        Header: 'Percent of Total',
        width: '20%',
        textAlign: 'right',
        accessor: (row) => {
          return divide(row.count_clicked, totalClicks);
        },
        sortType: 'basic'
      }
    ],
    [totalClicks]
  );

  const tableInstance = useTable(
    {
      columns: tableInstanceColumns,
      data: links,
      initialState: {
        pageIndex: DEFAULT_CURRENT_PAGE - 1, // react-table takes a 0 base pageIndex
        pageSize: DEFAULT_PER_PAGE,
        sortBy: [
          {
            id: 'Unique Clicks',
            desc: true
          }
        ]
      },
      disableSortRemove: true
    },
    useSortBy,
    usePagination
  );

  const { prepareRow, page, pageOptions, gotoPage } = tableInstance;
  return (
    <div data-id="link-engagement-table">
      <Panel mb="0" borderBottom="0">
        <Table title="Breakdown Table">
          <thead>
            <Table.Row>
              {tableInstance.headerGroups[0].headers.map((column, index) => (
                <Table.HeaderCell key={index} width={column.width} align={column.textAlign}>
                  <Table.SortButton
                    direction={column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : null}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                  </Table.SortButton>
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </thead>
          <tbody>
            {page?.map((row, index) => {
              prepareRow(row);

              return (
                <Table.Row key={`table-row-${index}`}>
                  <Table.Cell>{row.values['Link']}</Table.Cell>
                  <Table.Cell align="right">
                    <Unit value={row.values['Unique Clicks']} unit="number" />
                  </Table.Cell>
                  <Table.Cell align="right">
                    <Unit value={row.values['Clicks']} unit="number" />
                  </Table.Cell>
                  <Table.Cell align="right">
                    <Percent value={row.values['Percent of Total']} />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </tbody>
        </Table>
      </Panel>
      <Panel>
        <Panel.Section>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Pagination
              onChange={(page) => gotoPage(page)}
              pageRange={5}
              pages={pageOptions.length}
            />
            <Panel.Action as={PageLink} to={viewFullReportLink}>
              View Full Report <Button.Icon as={ShowChart} />
            </Panel.Action>
          </Box>
        </Panel.Section>
      </Panel>
    </div>
  );
}
