import ListPage from './ListPage';
import CreatePage from './CreatePage';
import DetailsPage from './DetailsPage';
import DetailsPageV2 from './DetailsPageV2';

export default {
  ListPage,
  CreatePage,
  DetailsPage,
  DetailsPageV2
};
