import { tokens } from '@sparkpost/design-tokens';
import { Text } from 'src/components/matchbox';
import styled from 'styled-components';

export const StyledQuote = styled(Text).attrs({
  as: 'blockquote'
})`
  border-left: 1px solid #d9e0e6;
  padding-left: ${tokens.spacing_200};
  margin-left: ${tokens.spacing_200};
  margin-top: 0;
  color: ${tokens.color_gray_700};
`;
