import React, { useCallback } from 'react';
import { UseSortByColumnProps, UseTableColumnProps } from 'react-table';
import { Table } from 'src/components/matchbox';

type TableHeaderCellProps = {
  column: UseSortByColumnProps<$TODOFIXME> & UseTableColumnProps<$TODOFIXME>;
};

export default function TableHeaderCell({ column }: TableHeaderCellProps) {
  const toggleSort = useCallback(() => {
    column.toggleSortBy();
  }, [column]);

  return (
    <Table.HeaderCell {...column.getHeaderProps(column.getSortByToggleProps())}>
      <Table.SortButton
        onClick={toggleSort}
        direction={column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : undefined}
      >
        {column.render('label')}
      </Table.SortButton>
    </Table.HeaderCell>
  );
}
