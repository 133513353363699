import { useMemo } from 'react';

import { useSparkPostQuery } from 'src/hooks';

import { getSendingDomains } from 'src/helpers/api/sendingDomains';

export function useSendingDomains() {
  const query = useSparkPostQuery(getSendingDomains, {
    staleTime: 1000 * 30,
    refetchOnWindowFocus: false
  });

  const verifiedDomains = useMemo(() => {
    if (query.status !== 'success') {
      return [];
    }

    return query.data.filter(
      ({ status }) => status.ownership_verified && status.compliance_status === 'valid'
    );
  }, [query.data, query.status]);

  const select = {
    verifiedDomains
  };

  return { query, select };
}
