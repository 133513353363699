import IncidentsPage from './IncidentsPage';
import MonitoredResourcesPage from './MonitoredResourcesPage';
import MonitorsAddPage from './MonitorsAddPage';
import IncidentDetailsPage from './IncidentDetailsPage';

export default {
  IncidentsPage,
  MonitoredResourcesPage,
  MonitorsAddPage,
  IncidentDetailsPage
};
