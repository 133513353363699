import React from 'react';
import { Table } from 'src/components/matchbox';
import { Empty } from 'src/components';
import { PanelSectionLoading } from 'src/components/loading';
import Billboard from './Billboard';

const Body = ({ columns, data, empty, error, loading, perPage }) => {
  if (loading) {
    return (
      <Billboard>
        <PanelSectionLoading minHeight="175px" />
      </Billboard>
    );
  }
  if (error) {
    return (
      <Billboard>
        <Empty message="Unable to Load Data" />
      </Billboard>
    );
  }

  if (empty) {
    return (
      <Billboard>
        <Empty message="No Data Available" />
      </Billboard>
    );
  }

  return (
    <tbody>
      {data.slice(0, perPage).map((rowOfData, rowIndex) => (
        <Table.Row key={`row-${rowIndex}`}>
          {columns.map(({ align = 'left', component: Component, dataKey }) => {
            /* eslint-disable react/forbid-component-props */
            return (
              <Table.Cell style={{ textAlign: align }} key={`cell-${dataKey}`}>
                {Component ? <Component {...rowOfData} /> : rowOfData[dataKey]}
              </Table.Cell>
            );
            /* eslint-enable react/forbid-component-props */
          })}
        </Table.Row>
      ))}
    </tbody>
  );
};

export default Body;
