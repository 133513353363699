import React from 'react';
import { Box, Drawer, ScreenReaderOnly } from 'src/components/matchbox';
import { Heading } from 'src/components/text';
import { FiltersForm } from 'src/pages/analyticsReport/components';

export default function MetricsDrawer({ onChange, getDrawerProps }) {
  return (
    <Drawer {...getDrawerProps()}>
      <Drawer.Header showCloseButton>
        <ScreenReaderOnly>Report Options</ScreenReaderOnly>
      </Drawer.Header>
      <Drawer.Content p="0">
        <Box ml="500">
          <Heading as="h5">Metric Filters</Heading>
        </Box>
        <FiltersForm handleSubmit={onChange} />
      </Drawer.Content>
    </Drawer>
  );
}
