import React, { forwardRef, Ref } from 'react';
import { Select as MatchboxSelect, SelectProps } from '@sparkpost/matchbox';
import { FIELD_MAX_WIDTH } from 'src/constants';
import { Box } from 'src/components/matchbox';

/**
 * Matchbox Select component.
 * @see https://design.sparkpost.com/components/select/
 */
const Select = forwardRef((props: SelectProps, ref: Ref<HTMLSelectElement>) => {
  const { maxWidth, ...rest } = props;

  return (
    <Box maxWidth={maxWidth ? maxWidth : FIELD_MAX_WIDTH}>
      <MatchboxSelect ref={ref} {...rest} />
    </Box>
  );
});

Select.displayName = 'Select';

export default Select;
