import React from 'react';
import styled from 'styled-components';
import { Search } from '@sparkpost/matchbox-icons';
import { Box, Columns, Column, ListBox, Panel, Stack, TextField } from 'src/components/matchbox';
import { Pagination } from 'src/components/collection';
import { ApiErrorBanner, TableSkeleton } from 'src/components';
import useUniqueId from 'src/hooks/useUniqueId';
import { SORT_BY_OPTIONS } from './constants';
import { MonitorsTable } from './MonitorsTable';
import { useMonitorsCollection } from './hooks/useMonitorsCollection';

const StyledListBox = styled(Box)`
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    float: right;
    width: 250px;
  }
`;
export const MonitorsCollection = (props) => {
  const { query, table } = useMonitorsCollection();
  const { handleDelete } = props;

  const { gotoPage, data, setPageSize, state, setSortBy, setGlobalFilter } = table;

  const { status, error, refetch } = query;
  const sortbyListboxId = useUniqueId('monitor-collection-sortby');

  if (status === 'error') {
    return (
      <ApiErrorBanner
        message="Sorry, we seem to have had some trouble loading your blocklist monitors."
        errorDetails={error.message}
        reload={refetch}
      />
    );
  }

  return (
    <Stack>
      <Panel mb="0">
        <Panel.Section>
          <Columns collapseBelow="xs">
            <Column>
              <TextField
                onChange={(e) => {
                  setGlobalFilter(e.currentTarget.value);
                }}
                id="monitors-search"
                prefix={<Search />}
                label="Search Monitored IPs and Domains"
                placeholder="e.g. sampledomain.com or 00.000.000.00"
              />
            </Column>
            <Column>
              <StyledListBox>
                <ListBox
                  id={sortbyListboxId}
                  label="Sort By"
                  defaultValue={SORT_BY_OPTIONS[0].value}
                  onChange={(e) => {
                    const [id, direction] = e.currentTarget.value.split('.');
                    const desc = direction === 'desc' ? true : false;
                    setSortBy([{ id, desc }]);
                  }}
                >
                  {SORT_BY_OPTIONS.map((option, index) => {
                    return (
                      <ListBox.Option key={`sort-option-${index}`} value={option.value}>
                        {option.label}
                      </ListBox.Option>
                    );
                  })}
                </ListBox>
              </StyledListBox>
            </Column>
          </Columns>
        </Panel.Section>
        {status === 'loading' && (
          <Panel.Section>
            <TableSkeleton numberOfColumns={0} />
          </Panel.Section>
        )}
        {status === 'success' && (
          <MonitorsTable table={table} handleDelete={handleDelete} refetch={refetch} />
        )}
      </Panel>
      <Pagination
        data={data}
        currentPage={state.pageIndex + 1}
        perPage={state.pageSize}
        saveCsv={true}
        onPageChange={gotoPage}
        onPerPageChange={setPageSize}
      />
    </Stack>
  );
};

export default MonitorsCollection;
