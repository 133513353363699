/* eslint-disable react/jsx-no-bind */
import { tokens } from '@sparkpost/design-tokens';
import { Add, RemoveCircle } from '@sparkpost/matchbox-icons';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { match as Match, Redirect } from 'react-router-dom';
import { ConfirmationModal } from 'src/components';
import { PageLink } from 'src/components/links';
import {
  Box,
  Button,
  Column,
  Columns,
  LabelValue,
  Layout,
  ListBox,
  Page,
  Panel,
  Radio,
  RadioCard,
  ScreenReaderOnly,
  Stack,
  Text
} from 'src/components/matchbox';
import DeleteModal from 'src/components/modals/DeleteModal';
import { TypeaheadController } from 'src/components/reactHookFormControllers/TypeaheadController';
import { TranslatableText } from 'src/components/text';
import { Form } from 'src/components/tracking';
import { TypeaheadItem } from 'src/components/typeahead';
import { ROLES } from 'src/constants';
import { addSubaccountAssignment, removeSubaccountAssignment } from 'src/helpers/api/users';
import { isAccountUiOptionSet } from 'src/helpers/conditions/account';
import { useAlert, useModal, useSparkPostMutation, useSubaccounts } from 'src/hooks';
const content = [
  {
    label: 'Admin',
    value: ROLES.ADMIN,
    helpText:
      'This role is a full access user. Admins have all permissions available and are the only users that can manage users, security, and billing settings.'
  },
  {
    label: 'Developer',
    value: ROLES.DEVELOPER,
    helpText:
      'This role is a powerful setup and development user. Developer users are able to create and edit API Keys and have access to all email related account settings.'
  },
  {
    label: 'Templates',
    value: ROLES.TEMPLATES,
    helpText:
      'This role is a content and campaign management user. These users can manage Templates, AB Tests, Recipient Lists, Suppressions. They also have view only access to Domains, Subaccounts, IP Pools, and all reporting and analytics features.'
  },
  {
    label: 'Reporting',
    value: ROLES.REPORTING,
    helpText:
      "This role is a data analytics user. They have access to all reporting features and View Only access to Domains, Subaccounts, and IP Pools. Reporting users can't edit any account or feature settings"
  }
];

type Subaccount = {
  id: string;
};

type SubaccountRole = {
  role: string;
  subaccount: Subaccount | null;
};

type FormValues = {
  email: string;
  subaccount_assignment: string;
  subaccounts: SubaccountRole[];
  user_role: string;
};

type EventPageParams = {
  id: string;
};

type User = {
  access?: string;
  auth_migrated?: boolean;
  email: string;
  isCurrentUser?: boolean;
  is_sso?: boolean;
  name: string;
  subaccount_id?: string;
  subaccounts?: $TODOFIXME[];
  tfa_enabled?: boolean;
  username: string;
};

type EditPageProps = {
  accountSingleSignOn: boolean;
  deleteUser: (userId: string) => void;
  getAccountSingleSignOnDetails: () => void;
  getSubaccount: (subaccountId?: string) => void;
  isAccountSingleSignOnEnabled: boolean;
  isSubAccountReportingLive?: boolean;
  listUsers: () => void;
  loading: boolean;
  match: Match<EventPageParams>;
  resetMfa: (username: string) => Promise<void>;
  resetTogglePending?: (username: string) => void;
  subaccount: Subaccount;
  submitting: boolean;
  updatePending: boolean;
  updateUser: (
    username: string,
    {
      access_level,
      is_sso,
      tfa_enabled,
      subaccounts
    }: {
      access_level?: string;
      is_sso?: boolean;
      subaccounts?: $TODOFIXME[];
      tfa_enabled?: boolean;
    }
  ) => $TODOFIXME;
  user: User;
  users: User[];
};

type Action = { content: string; onClick: () => $TODOFIXME };

function EditPageV2({
  isAccountSingleSignOnEnabled,
  accountSingleSignOn,
  getAccountSingleSignOnDetails,
  loading,
  subaccount,
  getSubaccount,
  submitting,
  user = {
    email: '',
    name: '',
    username: '',
    auth_migrated: undefined,
    isCurrentUser: undefined,
    tfa_enabled: undefined,
    is_sso: undefined,
    access: undefined
  },
  users,
  listUsers,
  updateUser,
  resetMfa,
  resetTogglePending,
  deleteUser,
  match,
  updatePending,
  isSubAccountReportingLive
}: EditPageProps) {
  const { handleSubmit, register, control, watch } = useForm<FormValues>({
    defaultValues: {
      subaccount_assignment: 'primary',
      user_role: user.access
    }
  });
  const { fields, append, remove } = useFieldArray({ control, name: 'subaccounts' });
  const { closeModal, openModal, isModalOpen, meta = {} } = useModal();
  const isUserToEditAuth0 = user?.auth_migrated;
  const subaccountsAppended = useRef(false);
  const [collapsedSubaccounts, setCollapsedSubaccounts] = useState<
    {
      action: 'add' | 'remove' | 'update';
      index: number;
      originalSubaccountId?: number;
    }[]
  >([]);

  const actions = useMemo<Action[]>(() => {
    const deleteActions = [];

    if (!user.isCurrentUser) {
      deleteActions.push({
        content: 'Delete User',
        onClick: () => openModal({ type: 'DeleteUser' })
      });
    }

    if (user.tfa_enabled && !user.isCurrentUser && !isUserToEditAuth0) {
      deleteActions.push({
        content: 'Disable Two-Factor Authentication',
        onClick: () => openModal({ type: 'DisableTfa' })
      });
    }

    if (user.tfa_enabled && !user.isCurrentUser && isUserToEditAuth0) {
      deleteActions.push({
        content: 'Reset Two-Factor Authentication',
        onClick: () => openModal({ type: 'ResetTfa' })
      });
    }

    return deleteActions;
  }, [isUserToEditAuth0, openModal, user.isCurrentUser, user.tfa_enabled]);

  const subAssignmentRadio =
    !user.subaccounts || user.subaccounts.length === 0 ? 'primary' : 'subaccount';

  const { showAlert } = useAlert();

  const subValues = watch('subaccounts')?.map(
    ({ subaccount }: $TODOFIXME) => subaccount?.id ?? null
  );

  const { subaccountsQuery } = useSubaccounts();
  const { data: subaccounts } = subaccountsQuery as { data: { id: string }[] };
  const subaccountOptions = subaccounts?.filter(({ id }) => !subValues?.includes(id)) ?? [];

  const handleResetTfa = useCallback(() => {
    const username = match.params.id;

    return resetMfa(username).then(() => {
      closeModal();
      showAlert({
        type: 'success',
        message: `Two-factor authentication for ${username} has been reset.`
      });
    });
  }, [closeModal, match.params.id, resetMfa, showAlert]);

  const handleDeleteUser = useCallback(() => {
    const userId = match.params.id;
    return deleteUser(userId);
  }, [deleteUser, match.params.id]);

  const recentlyAddedSubaccountIds = useMemo<string[]>(() => [], []);

  useEffect(() => {
    if (isEmpty(accountSingleSignOn)) {
      getAccountSingleSignOnDetails();
    }

    // only request if user visits page directly
    if (isEmpty(users)) {
      listUsers();
    }
  }, [accountSingleSignOn, getAccountSingleSignOnDetails, listUsers, users]);

  const handleDisableTfa = useCallback(() => {
    const username = match.params.id;
    return updateUser(username, {
      access_level: user.access,
      is_sso: user.is_sso,
      tfa_enabled: false
    }).then(closeModal);
  }, [closeModal, match.params.id, updateUser, user.access, user.is_sso]);

  const handleUserUpdate = useCallback(
    ({ is_sso, user_role }) => {
      const username = match.params.id;

      updateUser(username, { access_level: user_role, is_sso, tfa_enabled: user.tfa_enabled });
    },
    [match.params.id, updateUser, user.tfa_enabled]
  );

  const addSubaccountAssignmentRequest = useSparkPostMutation(
    (
      { username, id, role }: { id: string; role: string; username: string } = {
        id: '',
        username: '',
        role: ''
      }
    ) => addSubaccountAssignment(username, { subaccount_id: id, access_level: role }),
    {}
  );

  const removeSubaccountAssignmentRequest = useSparkPostMutation(
    ({ username, id }: { id: string; username: string } = { username: '', id: '' }) =>
      removeSubaccountAssignment(username, id),
    {}
  );

  const requestPending =
    addSubaccountAssignmentRequest.status === 'loading' ||
    removeSubaccountAssignmentRequest.status === 'loading' ||
    updatePending;

  const handleShowAddConfirmation = useCallback(
    (index: number, value?: $TODOFIXME) => {
      if (!value?.id) {
        return setCollapsedSubaccounts((prevState) =>
          prevState.filter((item) => item.index !== index)
        );
      }

      const itemAlreadyCollapsed = collapsedSubaccounts.find((item) => item.index === index);

      if (itemAlreadyCollapsed) {
        return setCollapsedSubaccounts((prevState) =>
          prevState.map((item) => (item.index !== index ? item : { ...item, action: 'add' }))
        );
      }

      setCollapsedSubaccounts((prevState) => [...prevState, { index, action: 'add' }]);
    },
    [collapsedSubaccounts]
  );

  const handleShowUpdateConfirmation = useCallback(
    (index: number) => {
      const originalValue = watch(`subaccounts.${index}.subaccount`);
      const itemAlreadyCollapsed = collapsedSubaccounts.find((item) => item.index === index);

      if (itemAlreadyCollapsed) {
        return setCollapsedSubaccounts((prevState) =>
          prevState.map((item) =>
            item.index !== index
              ? item
              : {
                  ...item,
                  originalSubaccountId: item.originalSubaccountId || Number(originalValue?.id),
                  action: 'update'
                }
          )
        );
      }

      setCollapsedSubaccounts((prevState) => [
        ...prevState,
        {
          index,
          originalSubaccountId: Number(originalValue?.id),
          action: 'update'
        }
      ]);
    },
    [watch, collapsedSubaccounts]
  );

  const handleHideActionConfirmation = useCallback(
    (index: number, isNew?: boolean) => {
      if (isNew) {
        remove(index);
      }

      setCollapsedSubaccounts((prevState) => prevState.filter((item) => item.index !== index));
    },
    [remove]
  );

  const handleShowRemoveConfirmation = useCallback(
    (index: number, assignmentExists: boolean) => {
      const subaccount = watch(`subaccounts.${index}.subaccount`);

      if (!assignmentExists && !recentlyAddedSubaccountIds?.includes(subaccount?.id || '')) {
        return handleHideActionConfirmation(index, true);
      }

      const itemAlreadyCollapsed = collapsedSubaccounts.find((item) => item.index === index);

      if (itemAlreadyCollapsed) {
        return setCollapsedSubaccounts((prevState) =>
          prevState.map((item) => (item.index !== index ? item : { ...item, action: 'remove' }))
        );
      }

      setCollapsedSubaccounts((prevState) => [...prevState, { index, action: 'remove' }]);
    },
    [collapsedSubaccounts, handleHideActionConfirmation, recentlyAddedSubaccountIds, watch]
  );

  const handleAddSubaccountAssignment = useCallback(
    async (index: number) => {
      const subaccount = watch(`subaccounts.${index}.subaccount`);
      const role = watch(`subaccounts.${index}.role`);

      const username = match.params.id;
      const id = subaccount?.id;

      if (!id) {
        return;
      }

      await addSubaccountAssignmentRequest.mutateAsync({ username, id, role });

      recentlyAddedSubaccountIds.push(id);

      showAlert({
        type: 'success',
        message: `Subaccount access granted`
      });
    },
    [addSubaccountAssignmentRequest, match.params.id, recentlyAddedSubaccountIds, showAlert, watch]
  );

  const handleRemoveSubaccountAssignment = useCallback(
    async (index) => {
      const username = match.params.id;
      const subaccount = watch(`subaccounts.${index}.subaccount`);
      const id = subaccount?.id;

      if (!id) {
        return;
      }

      await removeSubaccountAssignmentRequest.mutateAsync({ username, id });
      remove(index);

      const recentlyAddedSubaccountIdsIndex = recentlyAddedSubaccountIds.indexOf(id);

      if (recentlyAddedSubaccountIdsIndex !== -1) {
        recentlyAddedSubaccountIds.splice(recentlyAddedSubaccountIdsIndex, 1);
      }

      showAlert({
        type: 'success',
        message: `Subaccount access removed`
      });
    },
    [
      match.params.id,
      recentlyAddedSubaccountIds,
      remove,
      removeSubaccountAssignmentRequest,
      showAlert,
      watch
    ]
  );

  const handleUpdateSubaccountAssignment = useCallback(
    async (index, originalSubaccountId) => {
      const subaccount = watch(`subaccounts.${index}.subaccount`);
      const role = watch(`subaccounts.${index}.role`);
      const username = match.params.id;
      const id = subaccount?.id;

      if (!id) {
        return;
      }

      await removeSubaccountAssignmentRequest.mutateAsync({ username, id: originalSubaccountId });
      await addSubaccountAssignmentRequest.mutateAsync({ username, id: id, role });

      showAlert({
        type: 'success',
        message: `Subaccount access updated`
      });
    },
    [
      addSubaccountAssignmentRequest,
      match.params.id,
      removeSubaccountAssignmentRequest,
      showAlert,
      watch
    ]
  );

  const handleChangeConfirmation = useCallback(
    async (index: number) => {
      const collapsedSubaccount = collapsedSubaccounts.find(
        (subaccount) => index === subaccount.index
      );

      if (!collapsedSubaccount) return;

      if (collapsedSubaccount.action === 'add') {
        await handleAddSubaccountAssignment(index);
      } else if (collapsedSubaccount.action === 'remove') {
        await handleRemoveSubaccountAssignment(index);
      } else if (collapsedSubaccount.action === 'update') {
        await handleUpdateSubaccountAssignment(index, collapsedSubaccount.originalSubaccountId);
      }

      handleHideActionConfirmation(index);
    },
    [
      collapsedSubaccounts,
      handleAddSubaccountAssignment,
      handleHideActionConfirmation,
      handleRemoveSubaccountAssignment,
      handleUpdateSubaccountAssignment
    ]
  );

  useEffect(() => {
    if (
      user &&
      (user.access === ROLES.SUBACCOUNT_REPORTING || user.access === ROLES.SUBACCOUNT_DEVELOPER) &&
      (!subaccount || subaccount.id !== user.subaccount_id)
    ) {
      getSubaccount(user.subaccount_id);
    }
  }, [getSubaccount, subaccount, user]);

  useEffect(() => {
    if (user.subaccounts && user.subaccounts.length > 0 && !subaccountsAppended.current) {
      user.subaccounts.forEach(
        ({
          subaccount_id,
          subaccount_name,
          access_level
        }: {
          access_level: string;
          subaccount_id: string;
          subaccount_name: string;
        }) => {
          append({
            subaccount: {
              id: subaccount_id,
              name: subaccount_name
            } as $TODOFIXME,
            role: access_level
          });

          subaccountsAppended.current = true;
        }
      );
    }
  }, [append, user.subaccounts]);

  const subaccountUser =
    user.access === ROLES.SUBACCOUNT_REPORTING || user.access === ROLES.SUBACCOUNT_DEVELOPER;

  const hideUpdateUser = subaccountUser && !isAccountSingleSignOnEnabled;

  if (!isEmpty(users) && !user.username) {
    return <Redirect to="/account/users" />;
  }

  return (
    <Page
      title="User Details"
      breadcrumbAction={{ content: 'Users', Component: PageLink, to: '/account/users' }}
      loading={loading}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <Form id="users-create-form" onSubmit={handleSubmit(handleUserUpdate)}>
        <Layout>
          <Layout.Section annotated>
            <Layout.SectionTitle>User Information</Layout.SectionTitle>
          </Layout.Section>
          <Layout.Section>
            <Stack>
              <Panel>
                <Panel.Section>
                  <Columns space="500">
                    <Column>
                      <LabelValue orientation="vertical">
                        <LabelValue.Label>First Name</LabelValue.Label>
                        <LabelValue.Value>{user.name?.split(' ')[0]}</LabelValue.Value>
                      </LabelValue>
                      <LabelValue orientation="vertical" mt="500">
                        <LabelValue.Label>Email Address</LabelValue.Label>
                        <LabelValue.Value>{user.email}</LabelValue.Value>
                      </LabelValue>
                    </Column>
                    <Column>
                      <LabelValue orientation="vertical">
                        <LabelValue.Label>Last Name</LabelValue.Label>
                        <LabelValue.Value>{user.name?.split(' ').at(-1)}</LabelValue.Value>
                      </LabelValue>
                      <LabelValue orientation="vertical" mt="500">
                        <LabelValue.Label>Username</LabelValue.Label>
                        <LabelValue.Value>{user.username}</LabelValue.Value>
                      </LabelValue>
                    </Column>
                  </Columns>
                </Panel.Section>
              </Panel>
            </Stack>
          </Layout.Section>
        </Layout>
        <Layout>
          <Layout.Section annotated>
            <Layout.SectionTitle>User Management Settings</Layout.SectionTitle>
          </Layout.Section>
          <Layout.Section>
            <Stack>
              <Panel>
                <Panel.Section>
                  <Stack>
                    {isSubAccountReportingLive && (
                      <Radio.Group label="Subaccount Assignment">
                        {subAssignmentRadio === 'primary' ? (
                          <Radio
                            disabled
                            value="primary"
                            id="primary"
                            label="Assign to Primary Account"
                            checked
                          />
                        ) : (
                          <Radio
                            disabled
                            id="subaccount"
                            helpText="Users assigned to a subaccount can only be assinged to the Developer or Reporting Role"
                            value="subaccount"
                            label="Assign to Subaccount"
                            checked
                          />
                        )}
                      </Radio.Group>
                    )}
                    {subAssignmentRadio === 'primary' ? (
                      <RadioCard.Group label="User Role" orientation="grid">
                        {content.map(({ label, helpText, value }) => (
                          <RadioCard
                            {...register('user_role')}
                            disabled={requestPending || user.isCurrentUser}
                            value={value}
                            key={`role-radio-${value}`}
                            id={`role-radio-${value}`}
                            label={label}
                            defaultChecked={user.access === value}
                          >
                            <TranslatableText>{helpText}</TranslatableText>
                          </RadioCard>
                        ))}
                      </RadioCard.Group>
                    ) : (
                      <Box pl="500">
                        <Stack>
                          {fields.map((field, index) => {
                            return (
                              <Box key={field.id}>
                                <Columns key={field.id}>
                                  <Column width={1 / 2}>
                                    <TypeaheadController
                                      disabled={requestPending}
                                      control={control}
                                      id={`${field.id}-subaccount`}
                                      label="Subaccount"
                                      helpText=""
                                      name={`subaccounts.${index}.subaccount`}
                                      results={subaccountOptions}
                                      renderItem={(item: $TODOFIXME) => (
                                        <TypeaheadItem label={item.name} id={item.id} />
                                      )}
                                      itemToString={(item: $TODOFIXME) =>
                                        item ? `${item.name} (${item.id})` : ''
                                      }
                                      onChange={(value) => {
                                        const assignmentExists = user.subaccounts?.find(
                                          (subaccount) =>
                                            subaccount.subaccount_id === field.subaccount?.id
                                        );
                                        if (assignmentExists) {
                                          handleShowUpdateConfirmation(index);
                                        } else {
                                          handleShowAddConfirmation(index, value);
                                        }
                                      }}
                                    />
                                  </Column>
                                  <Column>
                                    <Controller
                                      control={control}
                                      //eslint-disable-next-line react/jsx-no-bind
                                      render={({ field: { onChange, ...fieldProps } }) => (
                                        <ListBox
                                          disabled={requestPending}
                                          id={`${field.id}-role`}
                                          label="Role"
                                          onChange={(e) => {
                                            const assignmentExists = user.subaccounts?.find(
                                              (subaccount) =>
                                                subaccount.subaccount_id === field.subaccount?.id
                                            );
                                            onChange({ target: e.currentTarget, ...e });
                                            if (assignmentExists) {
                                              handleShowUpdateConfirmation(index);
                                            }
                                          }}
                                          {...fieldProps}
                                        >
                                          <ListBox.Option value={ROLES.SUBACCOUNT_DEVELOPER}>
                                            Developer
                                          </ListBox.Option>
                                          <ListBox.Option value={ROLES.SUBACCOUNT_REPORTING}>
                                            Reporting
                                          </ListBox.Option>
                                        </ListBox>
                                      )}
                                      name={`subaccounts.${index}.role`}
                                    ></Controller>
                                  </Column>
                                  <Column width={1 / 12}>
                                    <Box mt="500">
                                      <Button
                                        useMatchboxVariant={true}
                                        variant="text"
                                        color="red"
                                        onClick={() => {
                                          const assignmentExists = Boolean(field.subaccount?.id);

                                          handleShowRemoveConfirmation(index, assignmentExists);
                                        }}
                                      >
                                        <ScreenReaderOnly>Remove Subaccount</ScreenReaderOnly>
                                        <Button.Icon as={RemoveCircle} />
                                      </Button>
                                    </Box>
                                  </Column>
                                </Columns>
                                {collapsedSubaccounts.find(
                                  (collapsedSubaccount) => collapsedSubaccount.index === index
                                ) && (
                                  <Box mt="300">
                                    <Button
                                      useMatchboxVariant={false}
                                      variant="secondary"
                                      mr="300"
                                      onClick={() => handleChangeConfirmation(index)}
                                      loading={requestPending}
                                    >
                                      Confirm Change
                                    </Button>
                                    <Button
                                      useMatchboxVariant={false}
                                      variant="monochrome-secondary"
                                      onClick={() => {
                                        const assignmentExists = user.subaccounts?.find(
                                          (subaccount) =>
                                            subaccount.subaccount_id === field.subaccount?.id
                                        );
                                        handleHideActionConfirmation(index, !assignmentExists);
                                      }}
                                      loading={requestPending}
                                    >
                                      Cancel
                                    </Button>
                                  </Box>
                                )}
                              </Box>
                            );
                          })}
                        </Stack>
                      </Box>
                    )}
                  </Stack>
                </Panel.Section>
                <Panel.Section>
                  {subAssignmentRadio === 'subaccount' && (
                    <Box>
                      <Button
                        useMatchboxVariant={false}
                        loading={requestPending}
                        variant="tertiary"
                        onClick={() =>
                          append({ subaccount: null, role: ROLES.SUBACCOUNT_DEVELOPER })
                        }
                        disabled={submitting || user.isCurrentUser}
                      >
                        <TranslatableText>Add Subaccount</TranslatableText>
                        <Button.Icon as={Add} />
                      </Button>
                    </Box>
                  )}
                  {subAssignmentRadio === 'primary' && !hideUpdateUser && (
                    <Box>
                      <Button
                        variant="primary"
                        disabled={submitting || user.isCurrentUser}
                        submit
                        loading={requestPending}
                      >
                        Update User
                      </Button>
                    </Box>
                  )}
                </Panel.Section>
              </Panel>
            </Stack>
          </Layout.Section>
        </Layout>
        <Layout>
          <Layout.Section annotated>
            <Layout.SectionTitle>Delete User</Layout.SectionTitle>
          </Layout.Section>
          <Layout.Section>
            <Panel accent="red">
              <Box padding={tokens.spacing_450}>
                <Text color={tokens.color_gray_900}>
                  Deleting a user is permanent and cannot be undone.
                </Text>
              </Box>
            </Panel>
            <Panel>
              <Panel.Section>
                {actions.map(({ content, onClick }) => (
                  <Button
                    color="red"
                    variant="outline-destructive"
                    onClick={onClick}
                    key={`delete-actions-${content}`}
                    mr="200"
                  >
                    <TranslatableText>{content}</TranslatableText>
                  </Button>
                ))}
              </Panel.Section>
            </Panel>
          </Layout.Section>
        </Layout>
      </Form>

      <DeleteModal
        onDelete={handleDeleteUser}
        onCancel={closeModal}
        open={isModalOpen && meta.type === 'DeleteUser'}
        content={
          <p>
            <span>User "</span>
            <span>{match.params.id}</span>
            <span>
              " will no longer be able to log in or access this SparkPost account. All API keys
              associated with this user will be transferred to you.
            </span>
          </p>
        }
        title="Are you sure you want to delete this user?"
        deleting={false}
      />
      <ConfirmationModal
        open={isModalOpen && meta.type === 'DisableTfa'}
        title="Are you sure you want to disable two-factor authentication for this user?"
        content={
          <p>
            Disabling two-factor authentication will also delete their two-factor backup codes. The
            next time they log in, they'll have to use their password or single sign-on if
            available.
          </p>
        }
        onConfirm={handleDisableTfa}
        onCancel={closeModal}
        confirming={requestPending}
        confirmVerb={requestPending ? 'Disabling' : 'Disable'}
      />
      <DeleteModal
        open={isModalOpen && meta.type === 'ResetTfa'}
        title="Reset Multi-Factor Authentication (MFA)"
        content={
          <p>
            Resetting MFA for this user will also delete their Recovery Code. Resetting MFA is
            permanent and cannot be undone
          </p>
        }
        onConfirm={handleResetTfa}
        onCancel={closeModal}
        confirming={resetTogglePending}
        confirmVerb={resetTogglePending ? 'Resetting MFA' : 'Reset MFA'}
        deleting={false}
        onDelete={() => {
          // onDelete is not used but is required
        }}
      />
    </Page>
  );
}

const mapStateToProps = (state: $TODOFIXME) => ({
  isSubAccountReportingLive: isAccountUiOptionSet('subaccount_reporting')(state)
});

export default connect(mapStateToProps)(EditPageV2);
