/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled from 'styled-components';
import { Box, Button, Text } from 'src/components/matchbox';

export const StyledBox = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const StyledBoxAlternativeSidebar = styled(Box)<{ isSidebarOpen: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${({ isSidebarOpen }) => !isSidebarOpen && 'position: absolute;bottom: 0;height:100%;'}
  ${({ isSidebarOpen, theme }) =>
    isSidebarOpen
      ? `background-color: ${theme.colors.white};`
      : `background-color: ${theme.colors.gray['100']}};`}
`;

export const SidebarContent = styled(Box)`
  flex: 8;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const SidebarHeader = styled(Box)`
  border-bottom: 1px solid #d9e0e6;
  padding: ${(props) => props.theme.space[450]};
  overflow: hidden;
`;

export const SidebarFooter = styled(Box)<{ reverse: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: ${(props) => (props.reverse ? 'column-reverse' : 'column')};
  border-top: 1px solid #d9e0e6;
`;

export const FooterButton = styled(Button)<{ first: boolean }>`
  width: 100%;
  align-self: flex-end;
  justify-content: left;
  ${(props) => props.first && 'margin-top: auto;'}
`;

export const AlternativeSidebarWrapper = styled.div<{ windowSize: number }>`
  overflow-y: auto;
  ${({ windowSize }) =>
    `height:
      ${windowSize}px
    ;`}
`;
export const HeaderButton = styled(Button)`
  width: 100%;
`;

export const CreateButtonContainer = styled(Box)`
  display: flex;
`;

export const CreateButton = styled(Button)`
  flex-grow: 1;
`;

export const Section = styled(Box)`
  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.gray['300']};
  }
`;

export const SectionTitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[100]};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
