import React from 'react';
import { Button, Modal, Text } from 'src/components/matchbox';

export default function AutomaticSeedingSettingsModal({ open, onClose, handleClick, isLoading }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header showCloseButton>Automatic Seeding Settings</Modal.Header>

      <Modal.Content>
        <Text>
          These settings will be used once automatic seeding has been permitted across all accounts.
        </Text>
      </Modal.Content>

      <Modal.Footer>
        <Button variant="primary" loading={isLoading} onClick={handleClick}>
          Save Settings
        </Button>

        <Button variant="secondary" disabled={isLoading} onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
