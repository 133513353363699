import { analyticsReport, reports, signals } from 'src/pages';
import AnalyticsReport from 'src/pages/analyticsReport/AnalyticsReport';
import App from 'src/components/layout/App';
import AppWithSidebar from 'src/components/layout/AppWithSidebar';
import { hasGrants } from 'src/helpers/conditions';
import { hasAccountOptionEnabled } from 'src/helpers/conditions/account';
import { getQueryStringFromObject } from 'src/helpers/queryString';
import Sidebar from 'src/components/analyticsReport/Sidebar';
import { AnalyticsReportContextProvider } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
const signalsRoutes = [
  {
    path: '/signals',
    redirect: '/signals/analytics',
    category: 'Signals Analytics',
    subcategory: 'Analytics Report'
  },
  {
    path: '/signals/details/:type/:id/:tab?',
    title: 'Resource Details | Signals Analytics',
    component: signals.ResourceDetailsPage,
    layout: App,
    category: 'Signals Analytics',
    subcategory: 'Analytics Report'
  },
  {
    path: '/reports/summary',
    redirect: '/signals/analytics',
    category: 'Signals Analytics',
    subcategory: 'Analytics Report'
  },
  {
    path: '/reports/bounce',
    redirect: '/signals/analytics',
    category: 'Signals Analytics',
    subcategory: 'Analytics Report',
    search: '?report=bounce'
  },
  {
    path: '/reports/rejections',
    redirect: '/signals/analytics',
    category: 'Signals Analytics',
    subcategory: 'Analytics Report',
    search: '?report=rejections'
  },
  {
    path: '/reports/accepted',
    redirect: '/signals/analytics',
    category: 'Signals Analytics',
    subcategory: 'Analytics Report',
    search: '?report=accepted'
  },
  {
    path: '/reports/delayed',
    redirect: '/signals/analytics',
    category: 'Signals Analytics',
    subcategory: 'Analytics Report',
    search: '?report=delayed'
  },
  {
    path: '/reports/engagement',
    redirect: '/signals/analytics',
    category: 'Signals Analytics',
    subcategory: 'Analytics Report',
    search: '?report=engagement'
  },
  {
    path: '/signals/analytics',
    component: AnalyticsReport,
    layout: AppWithSidebar,
    sidebar: Sidebar,
    container: AnalyticsReportContextProvider,
    title: 'Analytics Report | Signals Analytics',
    supportDocSearch: 'reporting',
    category: 'Signals Analytics',
    subcategory: 'Analytics Report'
  },
  {
    path: '/signals/schedule/:reportId/',
    component: analyticsReport.ScheduledReportCreatePage,
    layout: App,
    category: 'Signals Analytics',
    subcategory: 'Analytics Report',
    title: 'Schedule Report'
  },
  {
    path: '/signals/schedule/:reportId/:scheduleId',
    component: analyticsReport.ScheduledReportEditPage,
    layout: App,
    category: 'Signals Analytics',
    subcategory: 'Analytics Report',
    title: 'Edit Scheduled Report'
  },
  {
    path: '/reports/message-events',
    component: reports.MessageEventsPage,
    layout: App,
    title: 'Events Search | Events',
    supportDocSearch: 'event',
    category: 'Events'
  },
  {
    path: '/reports/message-events/details/:messageId/:eventId?',
    component: reports.EventPage,
    layout: App,
    title: 'Message History | Events',
    supportDocSearch: 'event',
    category: 'Events'
  },
  {
    path: '/signals/health-score',
    component: signals.HealthScoreDashboard,
    condition: hasGrants('signals/manage'),
    layout: App,
    title: 'Health Score | Signals Analytics',
    supportDocSearch: 'signals',
    category: 'Signals Analytics',
    subcategory: 'Health Score'
  },
  {
    path: '/signals/spam-traps',
    component: signals.SpamTrapDashboard,
    condition: hasGrants('signals/manage'),
    layout: App,
    title: 'Spam Trap Monitoring | Signals Analytics',
    supportDocSearch: 'signals',
    category: 'Signals Analytics',
    subcategory: 'Spam Traps'
  },
  {
    path: '/signals/engagement',
    component: signals.EngagementRecencyDashboard,
    condition: hasGrants('signals/manage'),
    layout: App,
    title: 'Engagement Recency | Signals Analytics',
    supportDocSearch: 'signals',
    category: 'Signals Analytics',
    subcategory: 'Engagement Recency'
  },
  {
    path: '/signals/health-score/:facet/:facetId',
    component: signals.HealthScorePage,
    condition: hasGrants('signals/manage'),
    layout: App,
    title: 'Subaccount | Health Score | Signals Analytics',
    supportDocSearch: 'signals',
    category: 'Signals Analytics',
    subcategory: 'Health Score'
  },
  {
    path: '/signals/spam-traps/:facet/:facetId',
    component: signals.SpamTrapPage,
    condition: hasGrants('signals/manage'),
    layout: App,
    title: 'Spam Trap Monitoring | Signals Analytics',
    supportDocSearch: 'signals',
    category: 'Signals Analytics',
    subcategory: 'Spam Traps'
  },
  {
    path: '/signals/engagement/cohorts/:facet/:facetId',
    component: signals.EngagementRecencyPage,
    condition: hasGrants('signals/manage'),
    layout: App,
    title: 'Engagement Details | Engagement Recency | Signals Analytics',
    supportDocSearch: 'signals',
    category: 'Signals Analytics',
    subcategory: 'Engagement Recency'
  },
  {
    path: '/signals/engagement/engagement-rate/:facet/:facetId',
    component: signals.EngagementRateByCohortPage,
    condition: hasGrants('signals/manage'),
    layout: App,
    title: 'Engagement Rate | Signals Analytics',
    supportDocSearch: 'signals',
    category: 'Signals Analytics',
    subcategory: 'Engagement Recency'
  },
  {
    path: '/signals/engagement/unsubscribes/:facet/:facetId',
    component: signals.UnsubscribeRateByCohortPage,
    condition: hasGrants('signals/manage'),
    layout: App,
    title: 'Signals',
    supportDocSearch: 'signals',
    category: 'Signals Analytics',
    subcategory: 'Engagement Recency'
  },
  {
    path: '/signals/engagement/complaints/:facet/:facetId',
    component: signals.ComplaintsByCohortPage,
    condition: hasGrants('signals/manage'),
    layout: App,
    title: 'Signals',
    supportDocSearch: 'signals',
    category: 'Signals Analytics',
    subcategory: 'Engagement Recency'
  },
  {
    path: '/signals/integration',
    component: signals.IntegrationPage,

    condition: hasAccountOptionEnabled('allow_events_ingest'),
    layout: App,
    title: 'Signals Integration',
    supportDocSearch: 'signals',
    category: 'Configuration',
    subcategory: 'Signals Integration'
  }
];

export default signalsRoutes;

/**
 *
 * @param {*} type the type of details page
 * @param {*} id the key to lookup for the details type
 * @returns The details page URL with the right type value
 */
export function getDetailsPageUrl(type, id, params = {}) {
  let detailsPage = '/signals/details';
  let detailPageType = '';

  switch (type) {
    case 'sending_domain':
    case 'sending-domain':
      detailPageType = 'sending-domain';
      break;

    case 'watched_domain':
    case 'watched-domain':
    case 'domain':
      detailPageType = 'domain';
      break;

    case 'template':
    case 'template_id':
    case 'template-id':
      detailPageType = 'template';
      break;

    case 'subject_campaign':
    case 'subject-campaign':
      detailPageType = 'subject-campaign';
      break;

    case 'campaign':
    case 'campaign_id':
    case 'campaign-id':
      detailPageType = 'campaign';
      break;

    case 'subaccount':
    case 'subaccount_id':
    case 'subaccount-id':
      detailPageType = 'subaccount';
      break;

    case 'sending_ip':
    case 'sending-ip':
      detailPageType = 'sending-ip';
      break;

    case 'ip_pool':
    case 'ip-pool':
      detailPageType = 'ip-pool';
      break;

    case 'mailbox_provider':
    case 'mailbox-provider':
      detailPageType = 'mailbox-provider';
      break;

    case 'mailbox_provider_region':
    case 'mailbox-provider-region':
      detailPageType = 'mailbox-provider-region';
      break;

    default:
      break;
  }

  return `${detailsPage}/${detailPageType}/${encodeURIComponent(id)}?${getQueryStringFromObject(
    params
  )}`;
}
