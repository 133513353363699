import React, { useCallback, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { logout } from 'src/actions/auth';
import { PublicRoute, ProtectedRoute } from 'src/components/auth';
import { ROOT_ROUTE, AUTH_ROUTE, AUTH0_LOGOUT_ROUTE } from 'src/constants';
import { hibanaRoutes } from 'src/appConfig/routes';
import qs from 'query-string';
import sessionEmitter from 'src/helpers/sessionEmitter';
import { useAuthentication } from 'src/context/AuthenticationContext';
import { useDispatch } from 'react-redux';

function AppRoutes() {
  const routes = hibanaRoutes;

  return (
    <Switch>
      {routes.map((route) => {
        const MyRoute = route.public ? PublicRoute : ProtectedRoute;

        route.exact = !(route.exact === false); // this makes exact default to true

        if (route.redirect) {
          return (
            <Route
              key={route.path}
              exact
              path={route.path}
              render={({ location }) => {
                const search = qs.stringify({
                  ...qs.parse(location.search),
                  ...qs.parse(route.search)
                });
                return <Redirect to={{ ...location, search, pathname: route.redirect }} />;
              }}
            />
          );
        }

        return <MyRoute key={route.path} {...route} />;
      })}
    </Switch>
  );
}

export default () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const useAuth = useAuthentication();
  const {
    context: {
      isAuth0User,
      auth: { loggedIn }
    },
    previousRoute
  } = useAuth;

  const handleLogout = useCallback(() => {
    if (loggedIn && previousRoute !== '/auth-logout') {
      if (isAuth0User) {
        history.push(AUTH0_LOGOUT_ROUTE);
      } else {
        dispatch(logout());
      }
    }
  }, [loggedIn, previousRoute, isAuth0User, history, dispatch]);

  useEffect(() => {
    if (sessionEmitter.countLogout() < 1) sessionEmitter.onLogout(handleLogout);

    return () => sessionEmitter.offLogout(handleLogout);
  }, [handleLogout]);

  /**
   * Track Previous Route
   */
  useEffect(() => {
    if (location?.pathname !== ROOT_ROUTE && location?.pathname !== AUTH_ROUTE) {
      localStorage.setItem(`app.sp.previous-route`, location.pathname);
    }
  }, [location]);

  return <AppRoutes />;
};
