import React, { useEffect } from 'react';
import { Button, Modal } from 'src/components/matchbox';
import { domainRegex } from 'src/helpers/regex';
import { useAlert, useSparkPostQuery, useSparkPostMutation } from 'src/hooks';
import { getMonitors, deleteMonitor } from 'src/helpers/api/blocklist';

export const RemoveFromWatchlistModal = ({ closeModal, monitorToDelete }) => {
  const { showAlert } = useAlert();

  const getMonitorsQuery = useSparkPostQuery(() => getMonitors(), {
    enabled: false
  });

  const deleteMonitorMutation = useSparkPostMutation(() => deleteMonitor(monitorToDelete?.id), {
    onSuccess: getMonitorsQuery.refetch
  });

  useEffect(() => {
    if (getMonitorsQuery.status === 'success' && deleteMonitorMutation.status === 'success') {
      showAlert({
        type: 'success',
        message: `Stopped Monitoring ${monitorToDelete?.resource}.`
      });
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMonitorMutation.status, getMonitorsQuery.status]);

  if (!monitorToDelete?.resource) {
    return null;
  }

  const isPending =
    getMonitorsQuery.status === 'loading' || deleteMonitorMutation.status === 'loading';

  return (
    <Modal open={Boolean(monitorToDelete)} onClose={closeModal} showCloseButton={true}>
      <Modal.Header>Stop Monitoring</Modal.Header>
      <Modal.Content>
        {`Removing ${monitorToDelete?.resource.match(domainRegex) ? 'domain' : 'IP'} ${
          monitorToDelete?.resource
        } from your watchlist means you won't get notified of changes, but don't
          worry you can always add it again later.`}
      </Modal.Content>
      <Modal.Footer>
        <Button
          color="red"
          useMatchboxVariant
          disabled={isPending}
          loading={isPending}
          onClick={deleteMonitorMutation.mutate}
        >
          Stop Monitoring
        </Button>

        <Button
          color="gray"
          outlineBorder
          useMatchboxVariant
          onClick={closeModal}
          disabled={isPending}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveFromWatchlistModal;
