import styled from 'styled-components';
import { tokens } from '@sparkpost/design-tokens';

const YAxisTooltip = styled.div`
  width: 200px;
  max-width: 200px;
  color: ${tokens.color_white};
  text-align: left;
  background: ${tokens.color_gray_1000};
  font-size: ${tokens.fontSize_100};
  padding: ${tokens.spacing_200};
`;

export default YAxisTooltip;
