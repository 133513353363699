import React, { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { hasSubaccounts } from 'src/selectors/subaccounts';
import { hasProductOnBillingSubscription } from 'src/helpers/conditions/account';
import { PRIMARY_SUBACCOUNT } from 'src/constants';
import { getSeedList } from 'src/helpers/api/seedList';
import { useSparkPostQuery } from 'src/hooks';
import { LINKS } from 'src/constants';
import { SubduedText, TranslatableText } from 'src/components/text';
import { ExternalLink, SubduedLink } from 'src/components/links';
import { Box, Layout, Stack } from 'src/components/matchbox';
import { ManualSeedListPanel, SubaccountPanel } from './components';

export function ManualSeedingPage() {
  const [state, dispatch] = useManualSeedingPage();
  const { subaccount, hasSubaccountsSection } = state;
  const seedListQuery = useSparkPostQuery(() => getSeedList({ subaccountId: subaccount.id }));

  return (
    <Box>
      {hasSubaccountsSection ? (
        <Layout>
          <Layout.Section annotated>
            <Layout.SectionTitle>Account</Layout.SectionTitle>

            <Stack>
              <SubduedText>
                Select account the generated seed list will be used on for deliverability data.
              </SubduedText>

              {/* Prevents link from spanning full width of the sidebar */}
              <div>
                <SubduedLink as={ExternalLink} to={LINKS.SEED_LIST_DOCS}>
                  Seeding Documentation
                </SubduedLink>
              </div>
            </Stack>
          </Layout.Section>

          <Layout.Section>
            <SubaccountPanel
              onChange={(subaccount) => dispatch({ type: 'SUBACCOUNT_CHANGE', subaccount })}
              value={subaccount}
              disabled={seedListQuery.status === 'loading'}
            />
          </Layout.Section>
        </Layout>
      ) : null}

      <Layout>
        <Layout.Section annotated>
          <Layout.SectionTitle>Seed List</Layout.SectionTitle>

          {hasSubaccountsSection ? (
            <SubduedText>
              <TranslatableText>Seed list and seeding instructions for&nbsp;</TranslatableText>
              {subaccount.name}.
            </SubduedText>
          ) : (
            <SubduedText>Seed list and seeding instructions.</SubduedText>
          )}
        </Layout.Section>

        <Layout.Section>
          <ManualSeedListPanel seedListQuery={seedListQuery} />
        </Layout.Section>
      </Layout>
    </Box>
  );
}

function useManualSeedingPage() {
  const hasD12yProduct = useSelector((state) =>
    hasProductOnBillingSubscription('deliverability')(state)
  );

  const initialState = {
    subaccount: PRIMARY_SUBACCOUNT,
    // TODO: Remove use of Redux in favor of `react-query` once initial app requests are made without Redux
    hasSubaccountsSection: useSelector(hasSubaccounts) && hasD12yProduct
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'SUBACCOUNT_CHANGE': {
        const { subaccount } = action;

        return {
          ...state,
          subaccount
        };
      }

      default: {
        throw new Error(
          `${action.type} is not supported by the Seed List Settings page state reducer`
        );
      }
    }
  }

  return useReducer(reducer, initialState);
}
