import React from 'react';

// todo, switch to use Text and configure as needed
const Bold = (props) => {
  const { id, children, className } = props;

  return (
    <strong data-id={props['data-id']} id={id} className={className}>
      {children}
    </strong>
  );
};

export default Bold;
