import _ from 'lodash';
import { INCIDENT_STATUSES } from '../constants';
import { INCIDENT_IMPACTS_CONFIG } from '../../../constants';

/**
 * @description An incident retrieved via `/v1/blocklist-monitors/incidents`
 * @typedef BlocklistIncident
 * @type {object}
 * @property {string} id
 * @property {string} resource
 * @property {string} blocklist_name
 * @property {string} occurred_at - Date string
 * @property {string} resolved_at - Date string
 * @property {string} status - 'flagged' | 'resolved'
 * @property {string} resource_impact - 'low' | 'medium' | 'high' | 'insufficient data' | null
 * @property {boolean} is_estimated_impact
 */

/**
 * @description Table-friendly data derived from data requested via `/v1/blocklist-monitors/incidents`
 * @typedef TableFriendlyBlocklistIncident
 * @type {object}
 * @property {string} resource
 * @property {string} blocklistName
 * @property {string} dateListed - Date string
 * @property {string} dateResolved - Date string
 * @property {string} status - 'Active' | 'Resolved'
 * @property {number} impactScore - Number value derived from `impact_score` to allow for logically ordered sorting
 * @property {string} id
 */

/**
 * @description Derives friendly status content from a passed-in blocklist incident `status`
 * @param {string} incidentStatus - 'flagged' | 'resolved'
 * @returns {string} - 'Active' | 'Resolved'
 */
export function toFriendlyStatus(incidentStatus) {
  return INCIDENT_STATUSES[incidentStatus];
}

/**
 * @description Derives sorting score from the passed-in blocklist incident `resource_impact`
 * @param {(string|null)} resourceImpact - 'low' | 'medium' | 'high' | 'insufficient data' | null
 * @returns {number}
 */
export function toSortingScore(resourceImpact) {
  if (_.isNull(resourceImpact)) resourceImpact = 'unknown';

  const impact = INCIDENT_IMPACTS_CONFIG.find((impact) => impact.id === resourceImpact);

  return impact.sortingScore;
}

/**
 * @description Derives friendly impact content from a passed-in blocklist incident `resource_impact`
 * @param {number} sortingScore
 * @returns {(string|undefined)} 'Unknown' | 'Low Impact' | 'Medium Impact' | 'High Impact' | undefined
 */
export function getFriendlyImpactNameByScore(sortingScore) {
  const impact = INCIDENT_IMPACTS_CONFIG.find((impact) => impact.sortingScore === sortingScore);

  return impact.friendlyName;
}

/**
 * @description Derives <Tag /> component props from the passed-in incident's `resource_impact`
 * @param {number} sortingScore
 * @returns {(string|undefined)} 'gray' | 'yellow' | 'red' | undefined
 */
export function getTagColorByImpactScore(sortingScore) {
  const impact = INCIDENT_IMPACTS_CONFIG.find((impact) => impact.sortingScore === sortingScore);

  return impact.tagColor;
}

/**
 * @description Reformats blocklist incidents returned via `/v1/blocklist-monitors/incidents`
 * @param {array<BlocklistIncident>} incidents
 * @returns {array<TableFriendlyBlocklistIncident>}
 */
export function toTableFriendly(incidents) {
  return incidents.map((incident) => {
    return {
      id: incident.id,
      monitorId: incident.monitor_id,
      resource: incident.resource,
      blocklistName: incident.blocklist_name,
      dateListed: new Date(incident.occurred_at),
      dateResolved: new Date(incident.resolved_at),
      status: toFriendlyStatus(incident.status),
      impactScore: toSortingScore(incident.resource_impact),
      subaccountId: incident.subaccount_id
    };
  });
}
