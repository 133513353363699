import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { PageLink } from 'src/components/links';
import { Button, Grid, LabelValue, Panel, Tag } from 'src/components/matchbox';
import { formatDateTime } from '@sparkpost/report-builder/helpers/date';
import { domainRegex } from 'src/helpers/regex';
import { StackedLineChart } from '@sparkpost/matchbox-icons';
import { useSubaccounts } from 'src/hooks';

export default function IncidentDetails({
  resourceName,
  blocklistName,
  listedTimestamp,
  resolvedTimestamp,
  daysListed,
  subaccount
}) {
  const { getSubaccountName } = useSubaccounts();
  const engagementSummaryFrom = moment.utc(listedTimestamp).subtract('7', 'days').format();

  const now = moment.utc();
  let engagementSummaryTo = now.format();

  if (resolvedTimestamp) {
    const timestamp = moment.utc(resolvedTimestamp);
    const weekLater = timestamp.add('7', 'days');
    if (weekLater.isBefore(now)) {
      engagementSummaryTo = weekLater.format();
    }
  }

  const engagementSummaryResource = resourceName.match(domainRegex)
    ? 'Sending Domain'
    : 'Sending IP';

  return (
    <Panel data-id="incident-details">
      <Panel.Section>
        <Grid>
          <Grid.Column sm={3}>
            <LabelValue>
              <LabelValue.Label>Resource</LabelValue.Label>
              <LabelValue.Value>{resourceName}</LabelValue.Value>
            </LabelValue>
          </Grid.Column>
          <Grid.Column sm={3}>
            <LabelValue>
              <LabelValue.Label>Blocklist</LabelValue.Label>
              <LabelValue.Value>{blocklistName}</LabelValue.Value>
            </LabelValue>
          </Grid.Column>
          {!_.isNil(subaccount) && (
            <Grid.Column sm={3}>
              <LabelValue>
                <LabelValue.Label>Subaccount</LabelValue.Label>
                <LabelValue.Value>{getSubaccountName(subaccount)}</LabelValue.Value>
              </LabelValue>
            </Grid.Column>
          )}
        </Grid>
      </Panel.Section>
      <Panel.Section>
        <Grid>
          <Grid.Column sm={4}>
            <LabelValue>
              <LabelValue.Label>Date Listed</LabelValue.Label>
              <LabelValue.Value>{formatDateTime.useMomentInput(listedTimestamp)}</LabelValue.Value>
            </LabelValue>
          </Grid.Column>
          <Grid.Column sm={resolvedTimestamp ? 4 : 3}>
            <LabelValue>
              <LabelValue.Label>Date Resolved</LabelValue.Label>
              <LabelValue.Value>
                {resolvedTimestamp ? (
                  formatDateTime.useMomentInput(resolvedTimestamp)
                ) : (
                  <Tag color="lightGray">Active</Tag>
                )}
              </LabelValue.Value>
            </LabelValue>
          </Grid.Column>
          <Grid.Column sm={2}>
            <LabelValue>
              <LabelValue.Label>Days Listed</LabelValue.Label>
              <LabelValue.Value>{daysListed}</LabelValue.Value>
            </LabelValue>
          </Grid.Column>
        </Grid>
        <Panel.Action
          as={PageLink}
          to={{
            pathname: '/signals/analytics',
            search: `?from=${engagementSummaryFrom}&to=${engagementSummaryTo}&range=custom&filters=${engagementSummaryResource}:${resourceName}&report=engagement`
          }}
        >
          View Engagement
          <Button.Icon as={StackedLineChart} marginLeft="200" />
        </Panel.Action>
      </Panel.Section>
    </Panel>
  );
}
