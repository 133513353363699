import React from 'react';
import { Count, Duration, Percent, Size } from 'src/components';
import { TranslatableText } from '../text';

const formatUnit = (value: number, unit: string, roundTo = 2): string | JSX.Element => {
  if (typeof value === 'undefined') {
    return '';
  }

  // NaN it's a number ¯\_(ツ)_/¯
  if (typeof value === 'number' && isNaN(value)) {
    return '---';
  }

  if (typeof value !== 'number') {
    return value;
  }

  if (unit === 'bytes') {
    return <Size value={value} />;
  }

  if (unit === 'milliseconds') {
    return <Duration value={value} />;
  }

  if (unit === 'percent') {
    return <Percent value={value} roundTo={roundTo} />;
  }

  if (unit === 'number') {
    return <Count value={value} />;
  }

  return value.toLocaleString();
};

// Formats numerical value to a readable format for its provided unit
const Unit = ({
  value,
  unit,
  roundTo = 2
}: {
  roundTo: number;
  unit: string;
  value: number;
}): JSX.Element => <TranslatableText>{formatUnit(value, unit, roundTo)}</TranslatableText>;

Unit.displayName = 'Unit';

export { Unit, formatUnit };
