import { formatISO, subDays, subHours } from 'date-fns';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CellProps, useFilters, usePagination, useSortBy, useTable } from 'react-table';

// REDUX
import {
  addFilters as addFiltersAction,
  changePage as changePageAction,
  getMessageEvents as getMessageEventsAction,
  GetMessageEventsOptions,
  refreshMessageEventsDateRange as refreshMessageEventsDateRangeAction,
  updateMessageEventsSearchOptions as updateMessageEventsSearchOptionsAction
} from 'src/actions/messageEvents';
import { selectMessageEvents } from 'src/selectors/messageEvents';

// CONSTANTS
import config from 'src/appConfig';
import { FORMATS } from 'src/constants';

// HELPERS
import { formatDateTime } from '@sparkpost/report-builder/helpers/date';
import { onEnter } from 'src/helpers/keyEvents';
import { allMetricsAreDeliverability, getDeliverabilityOnlyMetrics } from 'src/helpers/metrics';
import { stringToArray } from 'src/helpers/string';
import { recipientEmail as recipientEmailValidator } from 'src/helpers/validation';
import { filterKeyExists } from 'src/pages/analyticsReport/helpers';
import { getEventColor } from '../helpers/getEventColor';
import { getEventName } from '../helpers/getEventName';

// HOOKS
import { usePageFilters } from 'src/hooks';
import {
  AnalyticsReportContextProvider,
  useAnalyticsReportContext
} from 'src/pages/analyticsReport/context/AnalyticsReportContext';

// COMPONENTS
import { ScreenReaderExclude } from 'src/components/a11y';
import DatePicker from 'src/components/datePicker/DatePickerV2';
import {
  Button,
  Column,
  Columns,
  Drawer,
  Page,
  Panel,
  ScreenReaderOnly,
  Stack,
  Tag,
  Text,
  TextField,
  Tooltip
} from 'src/components/matchbox';
import { Bold } from 'src/components/text';
import { ActiveFilters } from '../components/ActiveFilters';
import FiltersDrawer from '../components/FiltersDrawer';
import Footer from '../components/Footer';
import { Table } from '../components/Table';
import ViewDetailsButton from '../components/ViewDetailsButton';

// STYLES
import { AddFiltersButtonWrapper, SubjectText, TimestampText } from './styles';

// TYPES
import { Event, MessageEvents, UseAnalyticsReportContext } from 'src/typescript';
import { Col, Data, ReactTableInstanceWithHooks, ReactTableOptionsWithHooks } from '../types';
const { retentionPeriodDays } = config.messageEvents;

const RELATIVE_DATE_OPTIONS = ['hour', 'day', '7days', '10days', 'custom'];

const getInvalidAddresses = (addresses: string[]): string[] => {
  const invalids = _.filter(addresses, (address) => {
    address = _.trim(address);

    return address && recipientEmailValidator(address) !== undefined;
  }) as string[];

  return invalids;
};

const Cell = ({ cell }: CellProps<Data>): string | JSX.Element => {
  const col = cell.column as Col;
  const event = cell.row.original;

  if (cell.column.id === 'timestamp')
    return (
      <TimestampText as="time" dateTime={cell.value}>
        {/* eslint-disable-next-line local/restrict-translatable-text */}
        {formatDateTime(cell.value)}
      </TimestampText>
    );

  if (cell.column.id === 'event')
    return <Tag color={getEventColor(cell.value)}>{getEventName(cell.value)}</Tag>;

  if (cell.column.id === 'subject')
    return (
      <Tooltip
        content={cell.value}
        id={`timestamp-${cell.getCellProps().key}`}
        backgroundColor="white"
        color="gray.900"
        boxShadow="400"
      >
        <SubjectText>{cell.value}</SubjectText>
      </Tooltip>
    );

  if (cell.column.id === 'recipient_and_from')
    return (
      <Stack space={200}>
        <Text>
          <Bold>Recipient</Bold> {event.rcpt_to}
        </Text>
        <Text>
          <Bold>From</Bold> {event.friendly_from}
        </Text>
      </Stack>
    );

  if (cell.column.id === 'details')
    return <ViewDetailsButton eventId={event.event_id} messageId={event.message_id} />;

  if (col.readerScreenIncludeOnly) return <ScreenReaderOnly>{cell.value || ''}</ScreenReaderOnly>;

  if (col.readerScreenExclude) return <ScreenReaderExclude>{cell.value || ''}</ScreenReaderExclude>;

  return cell.value || '';
};

const columns: Col[] = [
  {
    Header: 'Timestamp',
    id: 'timestamp',
    Cell,
    accessor: 'timestamp',
    defaultCanSort: false,
    disableSortBy: true
  },
  {
    Header: 'Event',
    id: 'event',
    Cell,
    accessor: 'type',
    disableSortBy: true
  },
  {
    Header: 'Subject',
    id: 'subject',
    Cell,
    accessor: 'subject',
    disableSortBy: true
  },
  // only outside a reader screen
  {
    readerScreenExclude: true,
    Header: 'Recipient and From Addresses',
    id: 'recipient_and_from',
    Cell,
    disableSortBy: true,
    accessor: 'rcpt_to'
  },
  // only in reader screen
  {
    readerScreenIncludeOnly: true,
    Header: 'Recipient',
    id: 'recipient',
    Cell,
    disableSortBy: true,
    accessor: 'rcpt_to'
  },
  // only in reader screen
  {
    readerScreenIncludeOnly: true,
    Header: 'From',
    id: 'from',
    Cell,
    disableSortBy: true,
    accessor: 'friendly_from'
  },
  {
    Header: <ScreenReaderOnly>Details</ScreenReaderOnly>,
    id: 'details',
    Cell,
    disableSortBy: true
  }
];

const pageFiltersInitial = {
  dateOptions: {},
  recipients: {},
  events: {},
  recipient_domains: {},
  from_addresses: {},
  sending_domains: {},
  subjects: {},
  bounce_classes: {},
  reasons: {},
  campaigns: {},
  templates: {},
  sending_ips: {},
  ip_pools: {},
  subaccounts: {},
  messages: {},
  transmissions: {},
  ab_tests: {},
  ab_test_versions: {},
  mailbox_providers: {},
  mailbox_provider_regions: {},
  from: {},
  to: {}
};

export function MessageEventsPage(): JSX.Element {
  const firstLoad = useRef(true);
  const [search, setSearch] = useState('');
  const [perPageSize, setPerPageSize] = useState(25);
  const [pageIndex, setPageIndex] = useState(0);
  const [recipientError, setRecipientError] = useState<string | null>(null);
  const { getDrawerProps, openDrawer, closeDrawer } = Drawer.useDrawer({
    id: 'message-events-drawer'
  });

  const {
    state: reportOptions,
    actions: { refreshReportOptions }
  } = useAnalyticsReportContext() as UseAnalyticsReportContext;
  const { metrics } = reportOptions;

  const events = useSelector<unknown, Event[]>(selectMessageEvents);
  const messageEvents = useSelector<$TODOFIXME, MessageEvents>((state) => state?.messageEvents);
  const { updateFilters, ...pageFilters } = usePageFilters(pageFiltersInitial);

  const filters = pageFilters.filters as typeof pageFiltersInitial;

  const location = useLocation();
  const dispatch = useDispatch();

  const addFilters = useCallback(
    (filters: $TODOFIXME): void => {
      dispatch(addFiltersAction(filters));
    },
    [dispatch]
  );

  const getMessageEvents = useCallback(
    (options: GetMessageEventsOptions): void => {
      dispatch(getMessageEventsAction(options));
    },
    [dispatch]
  );

  const changePage = useCallback(
    (currentPage: number): void => {
      dispatch(changePageAction(currentPage));
    },
    [dispatch]
  );

  const updateMessageEventsSearchOptions = useCallback(
    (options: $TODOFIXME): void => {
      dispatch(updateMessageEventsSearchOptionsAction(options));
    },
    [dispatch]
  );

  const refreshMessageEventsDateRange = useCallback(
    (options: $TODOFIXME): void => {
      dispatch(refreshMessageEventsDateRangeAction(options));
    },
    [dispatch]
  );

  const filterMetricsBasedOnSubjectCampaignsFilter = useCallback((filters, metrics) => {
    if (
      filters &&
      metrics &&
      filterKeyExists(filters, 'subject_campaigns') &&
      !allMetricsAreDeliverability(metrics)
    ) {
      return getDeliverabilityOnlyMetrics(metrics);
    }
  }, []);

  const handleSubmitForAllTabs = useCallback(
    (props) => {
      // Because we need to remove non-deliverability metrics if the user picks a subject_campaigns filter (API will break otherwise...)
      const metricsForSubjectCampaignFilter = filterMetricsBasedOnSubjectCampaignsFilter(
        props?.filters,
        metrics
      );

      if (metricsForSubjectCampaignFilter) props.metrics = metricsForSubjectCampaignFilter;

      const events = props.events || {};

      const filtersObj = {};

      if (props.filters && props.filters.length)
        props.filters.map(({ AND }: $TODOFIXME) => {
          const key = Object.keys(AND)[0];
          filtersObj[key] = AND[key].eq;
        });

      const enabledEventsArray = Object.keys(events).filter((key) => Boolean(events[key]));

      updateMessageEventsSearchOptions({
        ...messageEvents.search,
        events: enabledEventsArray,
        ...filtersObj
      });
      closeDrawer();
    },
    [
      filterMetricsBasedOnSubjectCampaignsFilter,
      metrics,
      updateMessageEventsSearchOptions,
      messageEvents.search,
      closeDrawer
    ]
  );

  const data = useMemo(() => events, [events]);

  const tableOptions: ReactTableOptionsWithHooks = {
    columns,
    data,
    pageCount: -1,
    manualPagination: true,
    initialState: {
      pageSize: perPageSize,
      pageIndex,
      sortBy: [
        {
          id: 'timestamp',
          desc: true
        }
      ]
    }
  };

  const {
    headerGroups,
    getTableProps,
    getTableBodyProps,
    page,
    prepareRow,
    setPageSize,
    gotoPage,
    canPreviousPage
  } = useTable<Data>(
    tableOptions,
    useFilters,
    useSortBy,
    usePagination
  ) as ReactTableInstanceWithHooks;

  const handlePageChange = (currentPage: number): void => {
    const pageIndex = currentPage - 1;
    setPageIndex(pageIndex);
    gotoPage(pageIndex);
    return changePage(currentPage);
  };

  // Reload the first page w/ api call, NOT from cache
  const handleFirstPage = (): void => {
    setPageIndex(0);
    gotoPage(0);
    getMessageEvents({ perPage: perPageSize, ...messageEvents.search });
  };

  const handleSearchOnChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [setSearch]
  );

  const handleDateRangeChange = useCallback(
    ({ from, to }: { from: string; precision: string; to: string }) => {
      refreshMessageEventsDateRange({
        perPage: perPageSize,
        from,
        to
      });
    },
    [perPageSize, refreshMessageEventsDateRange]
  );

  const handleRecipientSearch = useCallback(() => {
    if (search.trim() === '') return;

    const recipients = stringToArray(search) as string[];
    const invalids = getInvalidAddresses(recipients);

    if (invalids.length) {
      setRecipientError(
        `${invalids.join(', ')} ${invalids.length > 1 ? 'are not' : 'is not a'} valid email ${
          invalids.length > 1 ? 'addresses' : 'address'
        }`
      );
      return;
    }

    setSearch('');
    setRecipientError(null);

    addFilters({ recipients });
  }, [addFilters, search]);

  const getMessagesEventsWithPageSize = useCallback(
    (options: GetMessageEventsOptions) => {
      getMessageEvents({ perPage: perPageSize, ...options });
    },
    [getMessageEvents, perPageSize]
  );

  useEffect(() => {
    if (firstLoad.current) return;

    setPageIndex(0);

    const options = {
      ...messageEvents.search,
      dateOptions: {
        from: messageEvents.search.dateOptions.from || formatISO(subHours(new Date(), 1)),
        to: messageEvents.search.dateOptions.to || formatISO(new Date())
      }
    };

    getMessagesEventsWithPageSize(options);

    const { dateOptions } = options;

    updateFilters({
      ...options,
      from: dateOptions.from,
      to: dateOptions.to
    });
  }, [messageEvents.search, getMessagesEventsWithPageSize, updateFilters]);

  useEffect(() => {
    const { from, to, ..._filters } = filters;

    const filtersNormalized = {};

    Object.keys(_filters).map((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      filtersNormalized[key] = typeof _filters[key] === 'string' ? [_filters[key]] : _filters[key];
    });

    const options = {
      ...filtersNormalized,
      dateOptions: {
        from: from || formatISO(subHours(new Date(), 1)),
        to: to || formatISO(new Date())
      }
    };

    updateMessageEventsSearchOptions(options);

    firstLoad.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Events">
      <Panel>
        <Panel.Section>
          <Columns collapseBelow="sm" alignY="top" space={300}>
            <Column width={1}>
              <DatePicker
                dateFieldFormat={FORMATS.DATE_FNS.DATETIME_WITHOUT_YEAR}
                relativeDateOptions={RELATIVE_DATE_OPTIONS}
                disabled={messageEvents.loading}
                shouldChangePrecision
                label="Date Range"
                precision="hour"
                now={new Date()}
                from={messageEvents.search.dateOptions.from || subHours(new Date(), 1)}
                to={messageEvents.search.dateOptions.to || new Date()}
                onChange={handleDateRangeChange}
                datePickerProps={{
                  disabledDays: {
                    after: new Date(),
                    before: subDays(new Date(), retentionPeriodDays)
                  },
                  canChangeMonth: false
                }}
              />
            </Column>
            <Column width={1}>
              <TextField
                id="recipient-email"
                label="Recipient Email Address"
                placeholder="e.g. johndoe@example.com"
                value={search}
                onChange={handleSearchOnChange}
                error={recipientError}
                onBlur={handleSearchOnChange}
                onKeyDown={onEnter(handleRecipientSearch)}
                onFocus={() => setRecipientError(null)}
                connectRight={
                  <Button
                    variant="connected"
                    useMatchboxVariant={false}
                    onClick={handleRecipientSearch}
                  >
                    Search
                  </Button>
                }
              />
            </Column>
            <Column width="content">
              <AddFiltersButtonWrapper>
                <Button useMatchboxVariant={false} onClick={openDrawer} variant="secondary">
                  Add Filters
                </Button>
              </AddFiltersButtonWrapper>
            </Column>
          </Columns>
        </Panel.Section>

        <ActiveFilters />

        <Table
          getTableBodyProps={getTableBodyProps}
          getTableProps={getTableProps}
          page={page}
          headerGroups={headerGroups}
          prepareRow={prepareRow}
          loading={messageEvents.loading}
          reload={handleFirstPage}
          error={messageEvents.error}
        />
      </Panel>

      <Footer
        pageIndex={pageIndex}
        onFirstPageClick={handleFirstPage}
        pageSize={perPageSize}
        onPageChange={handlePageChange}
        previousDisabled={!canPreviousPage}
        nextDisabled={!messageEvents.hasMorePagesAvailable}
        totalCount={messageEvents.totalCount}
        onPageSizeChange={(perPage: number): void => {
          setPageSize(perPage);
          setPerPageSize(perPage);
          getMessageEvents({
            ...messageEvents.search,
            perPage
          });
        }}
      />

      <FiltersDrawer
        getDrawerProps={getDrawerProps}
        closeDrawer={closeDrawer}
        onChange={handleSubmitForAllTabs}
      />
    </Page>
  );
}

export default function MessageEventsPageWrapper(): JSX.Element {
  return (
    <AnalyticsReportContextProvider>
      <MessageEventsPage />
    </AnalyticsReportContextProvider>
  );
}
