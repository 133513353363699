import React from 'react';
import { Box, Text } from 'src/components/matchbox';

type CardProps = {
  children: React.ReactNode;
  textAlign: 'center' | 'end' | 'justify' | 'left' | 'match-parent' | 'right' | 'start';
};

export const Card = ({ children, textAlign }: CardProps): JSX.Element => {
  return (
    <Box border="400" padding="400" textAlign={textAlign} color="gray.900">
      {children}
    </Box>
  );
};

type CardContentProps = {
  children: React.ReactNode;
};

export const CardContent = ({ children }: CardContentProps): JSX.Element => {
  return (
    <Box display="inline-block" fontSize="500" color="gray.900">
      {children}
    </Box>
  );
};

type CardTitleProps = {
  children: React.ReactNode;
  level: string;
};

export const CardTitle = ({ children, level }: CardTitleProps): JSX.Element => {
  return (
    <Text
      as="div"
      mb="400"
      color="gray.900"
      role="heading"
      aria-level={Number(level)}
      fontSize={level === '2' ? 22 : 20}
      fontWeight="500"
    >
      {children}
    </Text>
  );
};
