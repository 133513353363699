import React, { useCallback } from 'react';
import { Search } from '@sparkpost/matchbox-icons';
import { Loading } from 'src/components/loading';

import { Banner, Box, Button, Layout, Panel, Radio, Stack } from 'src/components/matchbox';
import { Form } from 'src/components/tracking';
import { SubduedText, TranslatableText } from 'src/components/text';
import { PageLink } from 'src/components/links';

import { ComboBoxTypeahead } from 'src/components/typeahead/ComboBoxTypeaheadV2';

import { useDomainsForm } from './hooks/useDomainsForm';

const SENDING_DOMAINS_PAGE = '/domains/list/sending';

export function DomainsForm() {
  const { availableDomains, loading, state, dispatch, mutation } = useDomainsForm();

  const submitting = mutation.status === 'loading';

  function onSubmit(e) {
    e.preventDefault();
    mutation.mutate();
  }

  const onChange = (e) => {
    dispatch({ type: 'SET_OPTION', domainsOption: e.currentTarget.value });
  };

  const onTypeaheadChange = useCallback(
    (selectedDomains) => {
      dispatch({ type: 'SET_SELECTED_DOMAINS', selectedDomains });
    },
    [dispatch]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <Form id="blocklist-watchlist-add-domain" onSubmit={onSubmit}>
      <Layout>
        <Layout.Section annotated>
          <Layout.SectionTitle>Domains to Monitor</Layout.SectionTitle>
          <Stack>
            <SubduedText>
              <TranslatableText>
                Only verified domains can be monitored. Select verified domains to monitor or verify
                a new domain in&nbsp;
              </TranslatableText>
              <PageLink to={SENDING_DOMAINS_PAGE}>Domains.</PageLink>
            </SubduedText>
            <SubduedText>
              Any new domains verified after this selection will need to be added to the monitor
              list.
            </SubduedText>
          </Stack>
        </Layout.Section>
        <Layout.Section>
          {availableDomains.length > 0 ? (
            <Stack>
              <Panel>
                <Panel.Section>
                  <Stack space="200">
                    <Radio.Group onChange={onChange} label="Select Domains">
                      <Radio
                        disabled={submitting}
                        id="all-domains"
                        value="all-domains"
                        checked={state.domainsOption === 'all-domains'}
                        label="All Current Verified Domains"
                        onClick={onChange}
                        name="select-domains"
                        helpText={`Includes ${availableDomains.length} unmonitored verified domains.`}
                      />
                      <Radio
                        disabled={submitting}
                        id="specified-domains"
                        value="specified-domains"
                        checked={state.domainsOption === 'specified-domains'}
                        label="Specific Verified Domains"
                        onClick={onChange}
                        name="select-domains"
                      />
                    </Radio.Group>
                    <Box ml="500">
                      {state.domainsOption === 'specified-domains' && (
                        <ComboBoxTypeahead
                          label="Select Domains"
                          prefix={<Search />}
                          placeholder="e.g. mydomain.com"
                          value={state.selectedDomains}
                          itemToString={(item) => item?.domain}
                          onChange={onTypeaheadChange}
                          results={availableDomains}
                        />
                      )}
                    </Box>
                  </Stack>
                </Panel.Section>
              </Panel>
              <Box>
                <Button loading={submitting} variant="primary" type="submit">
                  Add Domains to Monitor
                </Button>
              </Box>
            </Stack>
          ) : (
            <Banner title="All Current Domains Are Monitored">
              <TranslatableText>
                All verified domains are already being monitored. To monitor a new domain, add and
                verify ownership of the new domain.
              </TranslatableText>

              <Banner.Actions>
                <PageLink as={Banner.Action} variant="filled" to={SENDING_DOMAINS_PAGE}>
                  Add A New Domain
                </PageLink>
              </Banner.Actions>
            </Banner>
          )}
        </Layout.Section>
      </Layout>
    </Form>
  );
}
