import { useMemo } from 'react';
import { getDeliverability } from 'src/helpers/api/metrics';
import { useSelector } from 'react-redux';
import { hasProductOnBillingSubscription } from 'src/helpers/conditions/account';
import { useSparkPostQueries, useSparkPostQuery } from 'src/hooks';
import {
  getMetricsFromKeys,
  transformData,
  splitDeliverabilityMetric,
  sortDataByKeys
} from 'src/helpers/metrics';
import { toQueryFromOptions } from '@sparkpost/report-builder/helpers/metrics';
import { useAnalyticsReportContext } from '../../context/AnalyticsReportContext';
import _ from 'lodash';
import { deliverabilityComputeKeys as DELIVERABILITY_METRICS_COMPUTE_KEYS } from 'src/helpers/metrics';
import { GROUP_BY_CONFIG } from '../../constants';

const DELIVERABILITY_PRODUCT = 'deliverability';

const separateCompareOptions = (reportOptions) => {
  const { comparisons } = reportOptions;
  if (!Boolean(comparisons.length)) {
    return [reportOptions];
  }

  return comparisons.map((compareFilter) => {
    const filterType = compareFilter.type;
    const comparedFilters = [
      ...reportOptions.filters,
      { AND: { [filterType]: { eq: [compareFilter] } } }
    ];
    return { ...reportOptions, filters: comparedFilters };
  });

  // Appends each compared filter as a new filter for individual requests
};

function filterMetricsBasedOnSubjectCampaignFilters(currentGroupBy, preparedOptionMetrics) {
  let appliedMetrics = preparedOptionMetrics && preparedOptionMetrics.split(',');
  if (appliedMetrics && currentGroupBy && currentGroupBy === 'subject-campaign') {
    return appliedMetrics
      .filter((metric) => DELIVERABILITY_METRICS_COMPUTE_KEYS.indexOf(metric) >= 0)
      .join();
  }
}

export function useGroupByTable() {
  const {
    selectors: { selectSummaryMetricsProcessed: displayMetrics },
    state: reportOptions,
    actions: { setGroupBy },
    dataSourceOptions
  } = useAnalyticsReportContext();

  const { groupBy } = reportOptions;

  const hasD12yProduct = useSelector((state) =>
    hasProductOnBillingSubscription('deliverability')(state)
  );
  const hasSendingProduct = useSelector((state) =>
    hasProductOnBillingSubscription('messaging')(state)
  );

  const inboxTrackerMetrics = displayMetrics.filter(
    ({ product }) => product === DELIVERABILITY_PRODUCT
  );
  const sendingMetrics = displayMetrics.filter(({ product }) => product !== DELIVERABILITY_PRODUCT);
  const hasInboxTrackingMetrics = Boolean(inboxTrackerMetrics.length);
  const hasSendingMetrics = Boolean(sendingMetrics.length);

  const { checkboxes, values, resetCheckboxes } = dataSourceOptions;

  const filteredMetrics = displayMetrics.filter(
    (metric) =>
      metric.product !== DELIVERABILITY_PRODUCT ||
      values.includes('panel') ||
      values.includes('seed')
  );

  const reformattedMetrics = filteredMetrics.map((metric) =>
    splitDeliverabilityMetric(metric, values)
  );
  const preparedOptions = toQueryFromOptions({
    ...reportOptions,
    metrics: reformattedMetrics,
    dataSource: values,
    limit: 10000
  });

  const {
    data = [],
    status,
    refetch
  } = useSparkPostQuery(
    () => {
      // Because we need to filter out non-deliverability metrics when they breakdown by subject campaign
      const deliverabilityOnlyMetrics = filterMetricsBasedOnSubjectCampaignFilters(
        groupBy,
        preparedOptions.metrics
      );
      if (deliverabilityOnlyMetrics) preparedOptions.metrics = deliverabilityOnlyMetrics;

      return getDeliverability(preparedOptions, groupBy);
    },
    {
      enabled: Boolean(reportOptions.isReady && groupBy && reformattedMetrics.length)
    }
  );

  const formattedData = transformData(data, reformattedMetrics);

  // Get column header keys in the correct order
  const columnHeaderKeys = useMemo(() => {
    if (groupBy) {
      const groupKey = GROUP_BY_CONFIG[groupBy].keyName;
      return [groupKey, ...displayMetrics.map(({ key }) => key)];
    }
    return [];
  }, [displayMetrics, groupBy]);

  // Sort the data so they look pretty in the CSV
  const sortedData = sortDataByKeys(formattedData, columnHeaderKeys);

  return {
    data: sortedData,
    status,
    groupBy,
    setGroupBy,
    refetch,
    checkboxes,
    resetCheckboxes,
    apiMetrics: reformattedMetrics,
    hasSendingMetrics,
    hasSendingProduct,
    hasInboxTrackingMetrics,
    hasD12yProduct
  };
}

export function useCompareByGroupByTable() {
  const {
    selectors: { selectSummaryMetricsProcessed: displayMetrics },
    state: reportOptions,
    actions: { setGroupBy },
    dataSourceOptions
  } = useAnalyticsReportContext();

  const { checkboxes, values, resetCheckboxes } = dataSourceOptions;
  const { groupBy } = reportOptions;
  const { metrics, comparisons } = reportOptions;

  // Prepares params for request
  const formattedMetrics = useMemo(() => {
    return getMetricsFromKeys(metrics, true).map((metric) =>
      splitDeliverabilityMetric(metric, values)
    );
  }, [metrics, values]);

  const hasD12yProduct = useSelector((state) =>
    hasProductOnBillingSubscription('deliverability')(state)
  );
  const hasSendingProduct = useSelector((state) =>
    hasProductOnBillingSubscription('messaging')(state)
  );

  const inboxTrackerMetrics = formattedMetrics.filter(
    ({ product }) => product === DELIVERABILITY_PRODUCT
  );

  const sendingMetrics = formattedMetrics.filter(
    ({ product }) => product === DELIVERABILITY_PRODUCT
  );

  const hasInboxTrackingMetrics = Boolean(inboxTrackerMetrics.length);
  const hasSendingMetrics = Boolean(sendingMetrics.length);

  const filteredMetrics = formattedMetrics.filter(
    (metric) =>
      metric.product !== DELIVERABILITY_PRODUCT ||
      values.includes('panel') ||
      values.includes('seed')
  );
  const reformattedMetrics = filteredMetrics.map((metric) =>
    splitDeliverabilityMetric(metric, values)
  );

  const separatedOptions = separateCompareOptions(reportOptions);
  const separatedRequests = separatedOptions.map((options) => {
    const preparedOptions = toQueryFromOptions({
      ...options,
      metrics: reformattedMetrics,
      dataSources: values,
      limit: 10000
    });

    // Because we need to filter out non-deliverability metrics when they breakdown by subject campaign
    const deliverabilityOnlyMetrics = filterMetricsBasedOnSubjectCampaignFilters(
      groupBy,
      preparedOptions.metrics
    );
    if (deliverabilityOnlyMetrics) preparedOptions.metrics = deliverabilityOnlyMetrics;

    return () => getDeliverability(preparedOptions, groupBy);
  });

  const queries = useSparkPostQueries([...separatedRequests], {
    enabled: Boolean(reportOptions.isReady && groupBy && reformattedMetrics.length)
  });

  const refetchAll = () => {
    queries.forEach((query) => query.refetch());
  };

  const statuses = queries.map((query) => query.status);

  const formatData = (rawData) => {
    if (!rawData) {
      return [];
    }

    if (!groupBy) {
      return [];
    }

    const groupKey = GROUP_BY_CONFIG[groupBy].keyName;
    // Array of objects (nested array turned to object)
    const keyedData = rawData.map((dataArray) => _.keyBy(dataArray, groupKey));
    // Array of objects (unique group-by key)
    const objectKeys = _.uniqBy(_.flatten(rawData), groupKey);

    return objectKeys.reduce((acc, obj) => {
      const key = obj[groupKey];
      const newRows = comparisons.map((compareFilter, index) => {
        const currentDataCompare = keyedData[index][key] || {};
        return {
          ...currentDataCompare,
          [groupKey]: key, // Re-add key (in case it's empty. We want a row to show even if data is null as comparison value must exist)
          [compareFilter.type]: compareFilter.value // Comparison value
        };
      });

      return [...acc, ...newRows];
    }, []);
  };

  const generatedRows = queries.every((query) => query.status === 'success')
    ? transformData(formatData(queries.map((query) => query.data)), formattedMetrics)
    : [];

  const comparisonType = comparisons[0].type;

  // Get column header keys in the correct order
  const columnHeaderKeys = useMemo(() => {
    if (groupBy) {
      const groupKey = GROUP_BY_CONFIG[groupBy].keyName;
      return [groupKey, comparisonType, ...displayMetrics.map(({ key }) => key)];
    }
    return [];
  }, [comparisonType, displayMetrics, groupBy]);

  // Sort the data so they look pretty in the CSV
  const sortedData = sortDataByKeys(generatedRows, columnHeaderKeys);

  return {
    groupBy,
    setGroupBy,
    data: sortedData,
    statuses,
    refetchAll,
    comparisonType,
    checkboxes,
    resetCheckboxes,
    apiMetrics: reformattedMetrics,
    hasSendingMetrics,
    hasInboxTrackingMetrics,
    hasSendingProduct,
    hasD12yProduct
  };
}
