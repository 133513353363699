import { ArrowDropDown, ArrowDropUp, ShowChart } from '@sparkpost/matchbox-icons';
import { formatDate } from '@sparkpost/report-builder/helpers/date';
import { roundToPlaces } from '@sparkpost/report-builder/helpers/units';
import { isToday, sub } from 'date-fns';
import React from 'react';
import { Empty, Unit } from 'src/components';
import { PageLink } from 'src/components/links';
import { LabelValueLoadingSkeleton } from 'src/components/loading';
import { Box, Button, LabelValue, Layout, Panel, Stack, Text } from 'src/components/matchbox';
import { getHealthScore } from 'src/helpers/api/signals';
import { useSparkPostQuery } from 'src/hooks';
import useResourceDetailsContext from '../../hooks/useResourceDetailsContext';

const FACET = {
  subaccount: 'sid'
};

export default function HealthScore() {
  const loadingLabelValues = [{ value: 'Health Score' }, { value: 'Week Over Week' }];

  const { filters, match: { params: { type, id } = {} } = {} } = useResourceDetailsContext();

  let toParam;
  let viewFullReportLink = '/signals/analytics';
  if (filters && filters.to && filters.from) {
    const ONE_DAY_AGO = new Date(sub(new Date(), { days: 1 }));
    const TO = new Date(filters.to);
    toParam = isToday(TO) ? ONE_DAY_AGO : TO;
    // TODO: https://sparkpost.atlassian.net/browse/SAA-272 - send date param info and accept date param info on the health score page.
    viewFullReportLink = `/signals/health-score/${FACET[type]}/${id}`;
  }

  const { data: healthScoreData, status: healthScoreDataStatus } = useSparkPostQuery(() =>
    getHealthScore({ from: filters.from, to: toParam, facet: FACET[type], filter: id })
  );

  const healthScore =
    healthScoreData?.data?.length > 0 ? healthScoreData.data[0].current_health_score : null;

  const WoW = healthScoreData?.data?.length > 0 ? healthScoreData.data[0].WoW : null;
  return (
    <div data-id="health-score">
      <Layout>
        <Layout.Section annotated>
          <Layout.SectionTitle>Health Score</Layout.SectionTitle>
          <Stack space="100">
            <Text color="gray.700" fontSize="300" font-weight="600">
              {formatDate.useMomentInput(toParam)}
            </Text>
          </Stack>
        </Layout.Section>
        <Layout.Section>
          <Panel mb="0">
            {healthScoreDataStatus === 'success' && (
              <Panel.Section>
                <Panel.Action as={PageLink} to={viewFullReportLink}>
                  View Full Report <Button.Icon as={ShowChart} />
                </Panel.Action>
                <div data-id="health-score-section">
                  <Box display="flex">
                    <LabelValue mr="600">
                      <LabelValue.Label>Health Score</LabelValue.Label>
                      <LabelValue.Value>
                        {healthScore ? roundToPlaces(healthScore * 100, 1) : 'None'}
                      </LabelValue.Value>
                    </LabelValue>
                    <LabelValue>
                      <LabelValue.Label>Week Over Week</LabelValue.Label>
                      <LabelValue.Value>
                        <WoWData value={WoW} />
                      </LabelValue.Value>
                    </LabelValue>
                  </Box>
                </div>
              </Panel.Section>
            )}
            {healthScoreDataStatus === 'loading' && (
              <Panel.Section>
                <LabelValueLoadingSkeleton labelValues={loadingLabelValues} />
              </Panel.Section>
            )}
            {healthScoreDataStatus === 'error' && (
              <Empty message="Failed to load the data. Refresh page."></Empty>
            )}
          </Panel>
        </Layout.Section>
      </Layout>
    </div>
  );
}

const WoWData = ({ value }) => {
  let caret = null;
  let content = 'None';

  if (value > 0) {
    caret = (
      <Box color="green.700" as="span">
        <ArrowDropUp />
      </Box>
    );
  }

  if (value < 0) {
    caret = (
      <Box color="red.700" as="span">
        <ArrowDropDown />
      </Box>
    );
  }

  if (value !== null) {
    content = (
      <>
        <Unit value={value} unit="percent" />
        {caret}
      </>
    );
  }

  return <>{content}</>;
};
