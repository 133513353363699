/* eslint-disable max-lines */
import {
  abTesting,
  AccountSettingsPage,
  alerts,
  apiKeys,
  AuthPage,
  billing,
  DashboardPage,
  DefaultRedirect,
  domains,
  EnableTfaPage,
  ImmediateCancelAccountPage,
  ipPools,
  JoinPage,
  LoginPage,
  PageNotFound,
  passwordReset,
  PremiumSupportPage,
  recipientLists,
  RecipientValidationPage,
  RecoveryCodePage,
  RegisterPage,
  SingleResultPage,
  SmtpPage,
  snippets,
  SsoAuthPage,
  SSOPage,
  subaccounts,
  suppressions,
  TfaPage,
  UploadedListPage,
  UsagePage,
  users,
  webhooks
} from 'src/pages';

import Auth0LogoutPage from 'src/pages/logout/Auth0LogoutPage';
import LogoutPage from 'src/pages/logout/LogoutPage';

import { default as emailVerification } from 'src/components/emailVerification/EmailVerification';
import DataPrivacyPage from 'src/pages/dataPrivacy/DataPrivacyPage';
import onboarding from 'src/pages/onboarding';
import SecretBillingPlanOrBillingSummaryPage from '../SecretBillingPlanOrBillingSummaryPage';

import App from 'src/components/layout/App';
import LargeForm from 'src/components/layout/LargeForm';
import { all, any, hasGrants, not } from 'src/helpers/conditions';
import {
  isAccountUiOptionSet,
  isAws,
  isCustomBilling,
  isEnterprise,
  isSelfServeBilling
} from 'src/helpers/conditions/account';
import { configEquals, configFlag } from 'src/helpers/conditions/config';
import {
  isSubaccountDeveloperUser,
  isSubaccountReportingUser,
  isUserUiOptionSet
} from 'src/helpers/conditions/user';

import {
  AUTH0_LOGOUT_ROUTE,
  AUTH_ROUTE,
  CREATE_SUBACCOUNT,
  DEFAULT_REDIRECT_ROUTE,
  ENABLE_TFA_AUTH_ROUTE,
  FORGOT_PASSWORD,
  JOIN_ROUTE,
  LOGOUT_ROUTE,
  REGISTER_ROUTE,
  RESET_PASSWORD,
  SIGN_UP_ROUTE,
  SSO_AUTH_ROUTE,
  TFA_ROUTE
} from 'src/constants';

// route modules
import AuthConfigErrorPage from 'src/pages/error/AuthConfigErrorPage';
import blocklistRoutes, { hibanaBlocklistRoutes } from './blocklist';
import { emailRedirects, emailVerificationRedirect } from './emailRoutes';
import seedlistRoutes from './seedList';
import signalsRoutes, { getDetailsPageUrl } from './signals';
import { default as templateRoutes, default as templatesRoutes } from './templates';

// January 2024
// Permissions updated for Template and Reporting users as requested in NES-14195
// Now those users should be able to VIEW ONLY the following pages:
// 1. Subaccounts
// 2. Sending Domains
// 3. Bounce Domains
// 4. Tracking Domains
// 5. Templates
// 6. Snippets
// 7. IP Pools

// See @sparkpost/access for role to grant mappings

const appRoutes = [
  {
    path: '/',
    public: true,
    redirect: AUTH_ROUTE
  },
  {
    path: AUTH_ROUTE,
    public: true,
    component: LoginPage,
    title: 'Log In'
  },
  {
    path: '/classic-auth',
    public: true,
    component: AuthPage,
    title: 'Log In'
  },
  {
    path: TFA_ROUTE,
    public: true,
    component: TfaPage,
    title: 'Two-factor Authentication'
  },
  {
    path: SSO_AUTH_ROUTE,
    public: true,
    component: SsoAuthPage,
    title: 'Single Sign-On'
  },
  {
    path: '/sso',
    public: true,
    component: SSOPage,
    title: 'Single Sign-On'
  },
  {
    path: '/error',
    public: true,
    component: AuthConfigErrorPage,
    title: 'Error'
  },
  //this page is to be removed when all users are migrated to auth0
  {
    path: '/mfa/recovery-code',
    public: false,
    component: RecoveryCodePage,
    layout: LargeForm,
    title: 'MFA | Recovery Code'
  },
  {
    path: ENABLE_TFA_AUTH_ROUTE,
    public: true,
    component: EnableTfaPage,
    layout: LargeForm,
    title: 'Enable TFA'
  },
  {
    path: REGISTER_ROUTE,
    public: true,
    forceLogout: true,
    component: RegisterPage,
    title: 'Finish Your Registration'
  },
  {
    path: SIGN_UP_ROUTE,
    public: true,
    redirect: JOIN_ROUTE
  },
  {
    path: JOIN_ROUTE,
    public: true,
    forceLogout: true,
    component: JoinPage,
    condition: configFlag('featureFlags.has_signup'),
    title: 'Sign Up'
  },
  {
    path: FORGOT_PASSWORD,
    public: true,
    forceLogout: true,
    component: passwordReset.ForgotPasswordPage,
    title: 'Reset Password'
  },
  {
    path: `${RESET_PASSWORD}/:token`,
    public: true,
    forceLogout: true,
    component: passwordReset.ResetPasswordPage,
    title: 'Choose a New Password'
  },

  /**
   * This "DefaultRedirect" route is where we send _everyone_ once they first
   * log in, through Auth or Register or SSO or anything else. It will display
   * a loading icon until the access control state is loaded, then make a decision
   * on where to send them based on config, role, etc.
   *
   * TODO: Once the Dashboard is universally accessible, this can probably go away
   */
  {
    path: DEFAULT_REDIRECT_ROUTE,
    component: DefaultRedirect,
    layout: App,
    condition: () => true, // this route MUST be accessible to all logged-in app users
    title: 'Loading...'
  },

  /**
   * Handles route redirects for cutover to GA from old email template links
   * TODO: Should remove at a later date
   */
  ...emailRedirects,

  {
    path: '/dashboard',
    component: DashboardPage,
    layout: App,
    title: 'Dashboard',
    condition: all(
      not(isSubaccountReportingUser),
      configEquals('splashPage', '/dashboard') // want to hide if not a splash page https://jira.int.messagesystems.com/browse/FAD-6046
    ),
    // TODO: implement some kind of blockedRoutes check that runs on every route so we can
    // hide routes based on config, account/user settings, etc. without having to mess
    // around with grants in the web UI keys
    supportDocSearch: 'started'
  },
  {
    path: '/account/security',
    redirect: '/account/profile',
    category: 'Account'
  },
  {
    path: '/account/email-verification/:token',
    component: emailVerification,
    title: 'Verify Your Email',
    supportDocSearch: 'verification',
    category: 'Account'
  },
  {
    path: '/subaccount',
    title: 'Select Subaccount'
  },
  {
    path: '/account/subaccounts',
    component: subaccounts.ListPage,
    condition: any(
      hasGrants('subaccount/manage', 'api_keys/manage', 'sending_domains/manage'),
      hasGrants('subaccount/view')
    ),
    layout: App,
    title: 'Subaccounts',
    supportDocSearch: 'subaccount',
    category: 'Configuration',
    subcategory: 'Subaccounts'
  },
  {
    path: CREATE_SUBACCOUNT,
    component: subaccounts.CreatePage,
    condition: hasGrants('subaccount/manage', 'api_keys/manage', 'sending_domains/manage'),
    layout: App,
    title: 'Create Subaccount',
    supportDocSearch: 'subaccount',
    category: 'Configuration',
    subcategory: 'Subaccounts'
  },
  {
    path: '/account/subaccounts/:id',
    component: subaccounts.DetailsPage,
    condition: hasGrants('subaccount/manage', 'api_keys/manage', 'sending_domains/manage'),
    layout: App,
    title: 'Subaccount Details',
    exact: false,
    supportDocSearch: 'subaccount',
    category: 'Configuration',
    subcategory: 'Subaccounts'
  },
  {
    path: '/account/users',
    component: users.ListPage,
    condition: hasGrants('users/manage'),
    layout: App,
    title: 'Users',
    supportDocSearch: 'user',
    category: 'Account'
  },
  {
    path: '/account/users/create',
    component: users.CreatePage,
    condition: hasGrants('users/manage'),
    layout: App,
    title: 'Invite User',
    supportDocSearch: 'user',
    category: 'Account'
  },
  {
    path: '/account/users/invite',
    component: users.ViewInvitedUsers,
    condition: hasGrants('users/manage'),
    layout: App,
    title: 'View Invited Users',
    supportDocSearch: 'user',
    category: 'Account'
  },
  {
    path: '/account/users/edit/:id',
    component: users.EditPage,
    condition: hasGrants('users/manage'),
    layout: App,
    title: 'Edit User',
    supportDocSearch: 'user',
    category: 'Account'
  },
  {
    path: '/snippets',
    component: snippets.ListPage,
    condition: hasGrants('templates/view'),
    layout: App,
    title: 'Snippets',
    supportDocSearch: 'snippet',
    category: 'Content',
    subcategory: 'Snippets'
  },
  {
    path: '/snippets/create',
    component: snippets.CreatePage,
    condition: hasGrants('templates/modify'),
    layout: App,
    title: 'New Snippet',
    supportDocSearch: 'snippet',
    category: 'Content',
    subcategory: 'Snippets'
  },
  {
    path: '/snippets/edit/:id',
    component: snippets.EditPage,
    condition: hasGrants('templates/view'),
    layout: App,
    title: 'Edit Snippet',
    supportDocSearch: 'snippet',
    category: 'Content',
    subcategory: 'Snippets'
  },

  {
    path: '/lists/recipient-lists',
    component: recipientLists.ListPage,
    condition: hasGrants('recipient_lists/manage'),
    layout: App,
    title: 'Recipient Lists',
    supportDocSearch: 'recipient list',
    category: 'Recipients',
    subcategory: 'Recipient Lists'
  },
  {
    path: '/lists/recipient-lists/create',
    component: recipientLists.CreatePage,
    condition: hasGrants('recipient_lists/manage'),
    layout: App,
    title: 'New Recipient List',
    supportDocSearch: 'recipient list',
    category: 'Recipients',
    subcategory: 'Recipient Lists'
  },
  {
    path: '/lists/recipient-lists/edit/:id',
    component: recipientLists.EditPage,
    condition: hasGrants('recipient_lists/manage'),
    layout: App,
    title: 'Edit Recipient List',
    supportDocSearch: 'recipient list',
    category: 'Recipients',
    subcategory: 'Recipient Lists'
  },
  {
    path: '/lists/suppressions',
    component: suppressions.ListPage,
    condition: hasGrants('suppression_lists/manage'),
    layout: App,
    title: 'Suppressions',
    supportDocSearch: 'suppression list',
    category: 'Recipients',
    subcategory: 'Suppressions'
  },
  {
    path: '/lists/suppressions/create',
    component: suppressions.CreatePage,
    condition: hasGrants('suppression_lists/manage'),
    layout: App,
    title: 'Add Suppressions | Suppressions',
    supportDocSearch: 'suppression list',
    category: 'Recipients',
    subcategory: 'Suppressions'
  },
  {
    path: '/webhooks',
    component: webhooks.ListPage,
    condition: hasGrants('webhooks/view'),
    layout: App,
    title: 'Webhooks',
    supportDocSearch: 'webhook',
    category: 'Configuration',
    subcategory: 'Webhooks'
  },
  {
    path: '/webhooks/create',
    component: webhooks.CreatePage,
    condition: hasGrants('webhooks/modify'),
    layout: App,
    title: 'New Webhook',
    supportDocSearch: 'webhook',
    category: 'Configuration',
    subcategory: 'Webhooks'
  },
  {
    path: '/webhooks/details/:id',
    component: webhooks.DetailsPage,
    condition: hasGrants('webhooks/modify'),
    layout: App,
    title: 'Webhook Details',
    exact: false,
    supportDocSearch: 'webhook',
    category: 'Configuration',
    subcategory: 'Webhooks'
  },
  {
    path: '/account/api-keys',
    component: apiKeys.ListPage,
    condition: hasGrants('api_keys/manage'),
    layout: App,
    title: 'API Keys',
    supportDocSearch: 'api key',
    category: 'Configuration',
    subcategory: 'API Keys'
  },
  {
    path: '/account/api-keys/create',
    component: apiKeys.CreatePage,
    condition: hasGrants('api_keys/manage'),
    layout: App,
    title: 'New API Key',
    supportDocSearch: 'api key',
    category: 'Configuration',
    subcategory: 'API Keys'
  },
  {
    path: '/account/api-keys/edit/:id',
    component: apiKeys.EditPage,
    condition: hasGrants('api_keys/manage'),
    layout: App,
    title: 'Edit API Key',
    supportDocSearch: 'api key',
    category: 'Configuration',
    subcategory: 'API Keys'
  },
  {
    path: '/account/api-keys/view/:id',
    component: apiKeys.DetailsPage,
    condition: hasGrants('api_keys/manage'),
    layout: App,
    title: 'View API Key',
    supportDocSearch: 'api key',
    category: 'Configuration',
    subcategory: 'API Keys'
  },
  {
    path: '/account/settings',
    component: AccountSettingsPage,
    condition: hasGrants('users/manage'),
    layout: App,
    title: 'Account settings',
    supportDocSearch: 'account settings',
    category: 'Account'
  },
  {
    path: '/account/data-privacy',
    redirect: '/account/data-privacy/single-recipient',
    category: 'Account'
  },
  {
    path: '/account/data-privacy/:category',
    component: DataPrivacyPage,
    condition: hasGrants('users/manage'),
    layout: App,
    title: 'Data and Privacy',
    category: 'Account'
  },
  {
    path: '/account/cancel',
    component: ImmediateCancelAccountPage,
    condition: all(hasGrants('account/manage'), not(isEnterprise)),
    layout: App,
    title: 'Account | Cancellation In Progress',
    category: 'Account'
  },
  {
    path: '/account/profile',
    component: emailVerificationRedirect,
    condition: hasGrants('users/self-manage'),
    layout: App,
    title: 'My Profile',
    supportDocSearch: 'account profile',
    category: 'Account'
  },
  {
    path: '/account/smtp',
    component: SmtpPage,
    condition: all(hasGrants('api_keys/manage'), not(isSubaccountDeveloperUser)),
    layout: App,
    title: 'SMTP Settings',
    supportDocSearch: 'smtp',
    category: 'Configuration',
    subcategory: 'SMTP Settings'
  },
  {
    path: '/account/billing',
    component: SecretBillingPlanOrBillingSummaryPage,
    condition: all(hasGrants('account/manage'), not(isEnterprise)),
    layout: App,
    title: 'Billing',
    supportDocSearch: 'billing',
    category: 'Account'
  },
  {
    path: '/account/billing/enable-automatic',
    component: billing.EnableAutomaticBillingPage,
    condition: all(hasGrants('account/manage'), not(isSelfServeBilling)),
    layout: App,
    title: 'Billing | Enable Automatic Billing',
    supportDocSearch: 'upgrade account',
    category: 'Account'
  },
  {
    path: '/account/billing/plan',
    component: billing.ChangePlanPage,
    condition: all(
      hasGrants('account/manage'),
      not(isEnterprise),
      not(isCustomBilling),
      not(isAws)
    ),
    layout: App,
    title: 'Billing | Change My Plan',
    supportDocSearch: 'upgrade account',
    category: 'Account'
  },
  {
    path: '/usage',
    component: UsagePage,
    layout: App,
    title: 'Usage',
    category: 'Account',
    condition: all(hasGrants('usage/view'), isUserUiOptionSet('isHibanaEnabled'))
  },
  {
    path: '/account/ip-pools',
    component: ipPools.ListPage,
    condition: any(hasGrants('ip_pools/manage'), hasGrants('ip_pools/view')),
    layout: App,
    title: 'IP Pools',
    supportDocSearch: 'ip pool',
    category: 'Configuration',
    subcategory: 'IP Pools'
  },
  {
    path: '/account/ip-pools/create',
    component: ipPools.CreatePage,
    condition: hasGrants('ip_pools/manage'),
    layout: App,
    title: 'New IP Pool',
    supportDocSearch: 'ip pool',
    category: 'Configuration',
    subcategory: 'IP Pools'
  },
  {
    path: '/account/ip-pools/edit/:poolId',
    component: ipPools.EditPage,
    condition: hasGrants('ip_pools/manage'),
    layout: App,
    title: 'Edit IP Pool',
    supportDocSearch: 'ip pool',
    category: 'Configuration',
    subcategory: 'IP Pools'
  },
  {
    path: '/account/ip-pools/edit/:poolId/:ip',
    component: ipPools.EditIpPage,
    condition: hasGrants('ip_pools/manage'),
    layout: App,
    title: 'Edit IP',
    supportDocSearch: 'ip pool',
    category: 'Configuration',
    subcategory: 'IP Pools'
  },
  {
    path: '/ab-testing',
    component: abTesting.ListPage,
    condition: hasGrants('ab-testing/manage'),
    layout: App,
    title: 'A/B Testing',
    supportDocsSearch: 'A/B test',
    category: 'Content',
    subcategory: 'A/B Testing'
  },
  {
    path: '/ab-testing/create',
    component: abTesting.CreatePage,
    condition: hasGrants('ab-testing/manage'),
    layout: App,
    title: 'Create A/B Test',
    supportDocsSearch: 'A/B test',
    category: 'Content',
    subcategory: 'A/B Testing'
  },
  {
    path: '/ab-testing/:id/:version',
    component: abTesting.DetailsPage,
    condition: hasGrants('ab-testing/manage'),
    layout: App,
    title: 'A/B Testing',
    supportDocsSearch: 'A/B test',
    category: 'Content',
    subcategory: 'A/B Testing'
  },
  {
    path: '/alerts',
    component: alerts.ListPage,
    condition: hasGrants('alerts/manage'),
    layout: App,
    title: 'Alerts',
    supportDocsSearch: 'Alerts',
    category: 'Alerts'
  },
  {
    path: '/alerts/create/:id?',
    component: alerts.CreatePage,
    condition: hasGrants('alerts/manage'),
    layout: App,
    title: 'Create Alert | Alerts',
    supportDocsSearch: 'Alerts',
    category: 'Alerts'
  },
  {
    path: '/alerts/details/:id',
    component: alerts.DetailsPage,
    condition: hasGrants('alerts/manage'),
    layout: App,
    title: 'Alert Details | Alerts',
    supportDocsSearch: 'Alerts',
    category: 'Alerts'
  },
  {
    path: '/alerts/duplicate/:id',
    component: alerts.NewCreatePage,
    condition: all(hasGrants('alerts/manage'), isAccountUiOptionSet('new_alerts_enabled')),
    layout: App,
    title: 'Duplicate Alert | Alerts',
    supportDocsSearch: 'Alerts',
    category: 'Alerts'
  },
  {
    path: '/alerts/edit/:id',
    component: alerts.EditPage,
    condition: hasGrants('alerts/manage'),
    layout: App,
    title: 'Edit Alert | Alerts',
    supportDocsSearch: 'Alerts',
    category: 'Alerts'
  },
  {
    path: '/onboarding/plan',
    component: onboarding.ChoosePlan,
    condition: configFlag('featureFlags.has_signup'),
    title: 'Choose Your Plan | Onboarding',
    supportDocSearch: 'upgrade account'
  },
  {
    path: '/onboarding/recipient-validation',
    component: onboarding.RVBundlePage,
    condition: configFlag('featureFlags.has_signup'),
    title: 'Recipient Validation | Onboarding',
    layout: LargeForm
  },
  {
    path: '/recipient-validation',
    redirect: '/recipient-validation/list',
    category: 'Recipients',
    subcategory: 'Recipient Validation'
  },
  {
    path: '/recipient-validation/single/:email',
    component: SingleResultPage,
    condition: hasGrants('recipient-validation/manage'),
    layout: App,
    title: 'Results | Recipient Validation',
    supportDocsSearch: 'Recipient Validation',
    category: 'Recipients',
    subcategory: 'Recipient Validation'
  },
  {
    path: '/recipient-validation/list/:listId',
    component: UploadedListPage,
    condition: hasGrants('recipient-validation/preview'),
    layout: App,
    title: 'Validation Status | List | Recipient Validation',
    supportDocsSearch: 'Recipient Validation',
    category: 'Recipients',
    subcategory: 'Recipient Validation'
  },
  {
    path: '/recipient-validation/:category',
    component: RecipientValidationPage,
    condition: hasGrants('recipient-validation/preview'), // must manual keep in sync with nav item
    layout: App,
    title: 'Recipient Validation',
    supportDocsSearch: 'Recipient Validation',
    category: 'Recipients',
    subcategory: 'Recipient Validation'
  },
  {
    path: '/support/aws-premium',
    component: PremiumSupportPage,
    condition: isAws,
    title: 'Support | Request Premium Support',
    layout: App,
    supportDocSearch: 'upgrade plan',
    category: 'Account'
  },
  {
    path: '/error-boundary',
    public: true,
    component: () => {
      throw new Error('Oops');
    }
  },
  {
    path: '/private-error-boundary',
    layout: App,
    component: () => {
      throw new Error('Oops');
    }
  },
  {
    path: AUTH0_LOGOUT_ROUTE,
    component: Auth0LogoutPage,
    public: true,
    title: 'Logging out...'
  },
  {
    path: LOGOUT_ROUTE,
    component: LogoutPage,
    public: true,
    title: 'Logging out...'
  }
];

const hibanaDomainRoutes = [
  {
    path: '/domains/list/:domainType',
    component: domains.ListPage,
    title: 'Domains',
    condition: any(
      all(hasGrants('tracking_domains/manage'), hasGrants('sending_domains/manage')),
      hasGrants('sending_domains/view')
    ),
    layout: App,
    category: 'Configuration',
    subcategory: 'Domains'
  },
  {
    path: '/domains/create',
    component: domains.CreatePage,
    condition: all(hasGrants('tracking_domains/manage'), hasGrants('sending_domains/manage')),
    layout: App,
    title: 'Add a Domain | Domains',
    category: 'Configuration',
    subcategory: 'Domains'
  },
  {
    path: '/domains/details/sending-bounce/:id',
    component: domains.DetailsPage,
    condition: hasGrants('sending_domains/manage'),
    layout: App,
    title: 'Domain Details | Domains',
    category: 'Configuration',
    subcategory: 'Domains'
  },
  {
    path: '/domains/details/tracking/:id',
    component: domains.DetailsPage,
    condition: hasGrants('tracking_domains/manage'),
    layout: App,
    title: 'Domain Details | Domains',
    category: 'Configuration',
    subcategory: 'Domains'
  },
  {
    path: '/domains/details/:id/verify-bounce',
    component: domains.VerifyBounceDomainPage,
    condition: hasGrants('sending_domains/manage'),
    layout: App,
    title: 'Verify Bounce Domain | Domains',
    category: 'Configuration',
    subcategory: 'Domains'
  },
  {
    path: '/domains/details/:id/verify-sending',
    component: domains.VerifySendingDomainPage,
    condition: hasGrants('sending_domains/manage'),
    layout: App,
    title: 'Verify Sending Domain | Domains',
    category: 'Configuration',
    subcategory: 'Domains'
  },
  {
    path: '/domains/details/:id/verify-sending-bounce',
    component: domains.VerifySendingBounceDomainPage,
    condition: hasGrants('sending_domains/manage'),
    layout: App,
    title: 'Verify Sending/Bounce Domain | Domains',
    category: 'Configuration',
    subcategory: 'Domains'
  },
  {
    path: '/domains/details/:id/verify-tracking',
    component: domains.VerifyTrackingDomainPage,
    condition: hasGrants('tracking_domains/manage'),
    layout: App,
    title: 'Verify Tracking Domain | Domains',
    category: 'Configuration',
    subcategory: 'Domains'
  },
  {
    path: '/domains*',
    redirect: '/domains/list/sending',
    category: 'Configuration',
    subcategory: 'Domains'
  }
];

const routes = [
  ...appRoutes,
  ...signalsRoutes,
  ...templateRoutes,
  ...seedlistRoutes,
  ...blocklistRoutes
];

const hibanaRoutes = [
  ...appRoutes,
  ...hibanaDomainRoutes,
  ...signalsRoutes,
  ...templatesRoutes,
  ...seedlistRoutes,
  ...hibanaBlocklistRoutes
];

// ensure 404 is always last in routes
routes.push({
  path: '*',
  component: PageNotFound,
  layout: App,
  title: 'Page Not Found'
});

hibanaRoutes.push({
  path: '*',
  component: PageNotFound,
  layout: App,
  title: 'Page Not Found'
});

export { routes as default, getDetailsPageUrl, hibanaRoutes };
