/* eslint-disable local/restrict-translatable-text */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Text } from 'src/components/matchbox';

type EmptyProps = {
  description?: string;
  message: string;
};

function Empty({ description, message }: EmptyProps) {
  return (
    <Box
      paddingTop="800"
      paddingRight="500"
      paddingBottom="800"
      paddingLeft="500"
      textAlign="center"
      backgroundColor="gray.200"
      display="flex"
      alignItems="center"
      justifyContent="center"
      size="100%"
    >
      <Stack space="0">
        <Text color="gray.900" fontSize="400" fontWeight="medium">
          {message}
        </Text>
        {description && (
          <Text color="gray.900" fontSize="400" fontWeight="normal">
            {description}
          </Text>
        )}
      </Stack>
    </Box>
  );
}

Empty.propTypes = {
  message: PropTypes.string.isRequired,
  description: PropTypes.string
};

export default Empty;
