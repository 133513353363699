import _ from 'lodash';
import { getGenericApiUrl } from './errorTracker';

// TODO: Return an Error object as if we were to create it with class and extends Error
function SparkpostApiError(error) {
  const apiError = _.get(error, 'response.data.errors[0]', {});

  const additionalInfo = [
    error.response?.config?.method?.toUpperCase(),
    getGenericApiUrl(error.response?.config?.url),
    error.response?.status && error.response?.config?.url ? 'errors with' : undefined,
    error.response?.status && error.response?.config?.url ? error.response?.status : undefined
  ].filter((x) => x !== undefined);

  this.name =
    error.response && additionalInfo.length > 0
      ? `SparkpostApiError ${additionalInfo.join(' ')}`
      : 'SparkpostApiError';
  this.stack = error.stack; // must manually assign prototype value
  this.message =
    apiError.description ||
    apiError.message ||
    (error.response
      ? error.message
      : 'You may be having network issues or an adblocker may be blocking part of the app.');

  // Intentionally assigning additional properties
  Object.assign(this, error);
}

SparkpostApiError.prototype = Object.create(Error.prototype);

export default SparkpostApiError;
