import {
  REPORT_BUILDER_FILTER_KEY_INVERTED_MAP,
  REPORT_BUILDER_FILTER_KEY_MAP
} from '@sparkpost/report-builder/config';
import { formatTimezonesToOptions } from '@sparkpost/report-builder/helpers/date';
import _ from 'lodash';
import { METRICS_TIMEZONE_BLOCK_LIST } from 'src/constants';
import { FilterComparator, Grouping } from 'src/typescript';
import { COMPARE_BY_OPTIONS } from '../constants';
import { AdvancedFiltersType } from '../context/useFilters';

/**
 * @name toFilterTypeLabel
 * @description Returns the filter label for REPORT_BUILDER_FILTER_KEY_MAP object based on the passed in value
 * @param {string} key either the key or label value for the filters map
 * @returns {string} the relevant, human-readable filter label
 */
export function toFilterTypeLabel(key: string) {
  // eslint-disable-next-line no-prototype-builtins
  if (REPORT_BUILDER_FILTER_KEY_MAP.hasOwnProperty(key)) {
    if (key === 'Campaign') {
      const campaignKey = REPORT_BUILDER_FILTER_KEY_MAP[key];
      return REPORT_BUILDER_FILTER_KEY_INVERTED_MAP[campaignKey];
    }

    return key;
  }

  return REPORT_BUILDER_FILTER_KEY_INVERTED_MAP[key];
}

type ToIterableGroupingsFunctionReturn = {
  filters: {
    compareBy?: FilterComparator;
    type?: string;
    values?: $TODOFIXME[];
  }[];
  type?: string;
  values?: $TODOFIXME[];
}[];

/**
 * @name toIterableGroupings
 * @description remaps advanced filter groupings data to make it iterable for use by the UI
 * @param {Array[Object]} groupings - An array of grouped [advanced filters](https://developers.sparkpost.com/api/metrics/#header-advanced-filters)
 * @returns {Array[Object]} returns an array of UI-consumable filters
 * */
export function toIterableGroupings(
  groupings: AdvancedFiltersType
): ToIterableGroupingsFunctionReturn {
  return groupings.map((grouping) => {
    const groupingKeys = Object.keys(grouping);
    const groupingType = groupingKeys[0];
    const filters = Object.keys(grouping[groupingType]);

    if (filters.length === 0) {
      return {
        type: groupingType,
        filters: [getInitialFilterState()]
      };
    }

    return {
      type: groupingType,
      filters: _.flatten(
        filters.map((filter) => {
          const filterObj = grouping[groupingType][filter];
          const comparisons = Object.keys(filterObj);

          return comparisons.map((compareBy: FilterComparator) => {
            return {
              type: filter,
              compareBy: compareBy,
              values: _.get(filterObj, compareBy)
            };
          });
        })
      )
    };
  });
}

/**
 * @name getTotalFilterCount
 * @param {Array} iterableGroupings array of iterable filter groupings, usually derived from `toIterableGroupings`
 * @returns {number} number of filters applied within a group of filters
 */
export function getTotalFilterCount(iterableGroupings = []) {
  return iterableGroupings.reduce((accumulator, currentGrouping: Grouping) => {
    // Filter groups only contribute to the total when their `values` are not empty
    if (currentGrouping.filters.some((filter) => filter.values && filter.values.length > 0)) {
      let incrementor = 0;

      currentGrouping.filters.forEach((filter) => {
        incrementor += (filter.values && filter.values.length) || 0;
      });

      return accumulator + incrementor;
    }

    return accumulator;
  }, 0);
}

/**
 * @name getInitialFilterState
 * @description returns initial state for an individual filter, i.e., after it is added to a list of filters
 * @returns {Object} UI-consumable, initial, filter state
 */
export function getInitialFilterState(): {
  compareBy: FilterComparator;
  type?: string;
  values: $TODOFIXME[];
} {
  return {
    type: undefined,
    compareBy: 'eq',
    values: []
  };
}

/**
 * @name getInitialGroupState
 * @description returns initial state for a group, i.e., after it is added to a list of groupings
 * @returns {Object} UI-consumable, initial filter group state
 */
export function getInitialGroupState() {
  return {
    type: 'AND',
    filters: [getInitialFilterState()]
  };
}

/**
 * @name toGroupingFields
 * @description returns UI state to render multi-layer filter forms based on the user's entry
 * Unit tests for this function were intentionally not written as they are tied closely to UI state
 * and are well tested by integration tests.
 * @param {Array} iterableGroupings array of iterable filter groupings, usually derived from `toIterableGroupings`
 * @returns {Array[Object]} UI-consumable array of objects that describe the state of a series of filter group form fields. Used within the Analytics Report filters form UI.
 */
export function toGroupingFields(iterableGroupings: Grouping[]): Grouping[] {
  const groupings = iterableGroupings;
  const getValueFieldType = (compareBy?: FilterComparator) => {
    switch (compareBy) {
      case 'eq':
      case 'notEq':
        return 'typeahead';

      case 'like':
      case 'notLike':
        return 'multi-entry';

      default:
        return undefined;
    }
  };

  return groupings.map((grouping, groupingIndex) => {
    if (!Array.isArray(grouping.filters))
      throw new Error('Each iterable grouping must have an array of "filters"');

    return {
      type: '',
      ...grouping,

      // Renders an "AND" below all groups *except* the last one in the list
      hasAndBetweenGroups: Boolean(groupingIndex + 1 < groupings.length),

      // Renders below the last group in the list when one of the filters has values
      hasAndButton: Boolean(
        groupingIndex + 1 === groupings.length &&
          groupings[groupingIndex].filters.find(
            (filter) => filter.values && filter.values.length > 0
          )
      ),

      // Whether or not this particular group has duplicate filters
      hasDuplicateFilters: hasDuplicateFilters(grouping.filters),

      filters: grouping.filters.map((filter, filterIndex) => {
        return {
          values: [],
          ...filter,
          // Grabbing the label by the key value
          label: Object.keys(REPORT_BUILDER_FILTER_KEY_MAP).find(
            (key) => REPORT_BUILDER_FILTER_KEY_MAP[key] === filter.type
          ),

          // The form comparison field does not render without a type selected,
          hasCompareBySelect: Boolean(filter.type),

          // Controls whether a filter has "like" comparison options
          hasCompareByLikeOptions: Boolean(filter.type && filter.type !== 'subaccounts'),

          // Filter comparison type controls which type of form control renders next
          valueField: filter.type ? getValueFieldType(filter.compareBy) : undefined,

          // Renders on the first group of filters only and when valid filter values exist
          hasGroupingTypeRadioGroup: Boolean(
            filterIndex === 0 && filter.values && filter.values.length > 0
          ),

          // Renders when the grouping is an "AND", when valid filter values exist, on the last filter
          hasAndButton: Boolean(
            grouping.type === 'AND' &&
              filter.values &&
              filter.values.length > 0 &&
              filterIndex + 1 === grouping.filters.length
          ),

          // Renders when the grouping is an "OR", when valid filter values exist, on the last filter
          hasOrButton: Boolean(
            grouping.type === 'OR' &&
              filter.values &&
              filter.values.length &&
              filterIndex + 1 === grouping.filters.length
          ),

          // Renders comparison text ("OR" or "AND") when more than one grouping exists but not on the last filter in the grouping
          hasComparisonBetweenFilters: Boolean(
            grouping.filters.length > 1 && filterIndex + 1 !== grouping.filters.length
          ),

          // Renders when there is more than 1 grouping in the form
          hasRemoveButton: Boolean(
            (groupingIndex === 0 && grouping.filters.length > 1) || groupingIndex > 0
          )
        };
      })
    };
  });
}

/**
 * @name toActiveFilterTagGroups
 * @description returns UI state to render active filter tags based on the user's entry
 * Unit tests for this function were intentionally not written as they are tied closely to UI state
 * and are well tested by integration tests.
 * @param {Array} iterableGroupings array of iterable filter groupings, usually derived from `toIterableGroupings`
 * @returns {Array[Object]} UI-consumable array of objects that describes the state of active filter tags rendered to the page
 */
export function toActiveFilterTagGroups(iterableGroupings: ToIterableGroupingsFunctionReturn) {
  const groupings = iterableGroupings;
  const getCompareByText = (compareBy?: FilterComparator) => {
    switch (compareBy) {
      case 'eq':
        return 'is equal to';
      case 'notEq':
        return 'is not equal to';
      case 'like':
        return 'contains';
      case 'notLike':
        return 'does not contain';
      default:
        return '';
    }
  };

  return groupings.map((grouping, groupingIndex) => {
    if (!Array.isArray(grouping.filters))
      throw new Error('Each iterable grouping must have an array of "filters"');

    return {
      type: grouping.type,
      values: grouping.values,

      // Renders below all groups *except* the last one in the list
      hasAndBetweenGroups: Boolean(groupingIndex + 1 < groupings.length),

      filters: grouping.filters.map((filter, filterIndex) => {
        return {
          values: filter.values,

          // Grabbing the label by the key value
          label: REPORT_BUILDER_FILTER_KEY_INVERTED_MAP[filter.type || ''],

          compareBy: getCompareByText(filter.compareBy),

          // Renders comparison text ("OR" or "AND") when more than one grouping exists but not on the last filter in the grouping
          hasComparisonBetweenFilters: Boolean(
            grouping.filters.length > 1 && filterIndex + 1 !== grouping.filters.length
          )
        };
      })
    };
  });
}

/**
 * @name toApiFormattedGroupings
 * @description reverts UI-formatted data mapping and re-structures said data to match API structure
 * @param {Array[Object]} groupingFields - array of UI filter group fields, typically derived from `toGroupingFields`
 * @returns {Array[Object]} array of API-consumable [advanced filters](https://developers.sparkpost.com/api/metrics/#header-advanced-filters)
 */
export function toApiFormattedGroupings(groupingFields: ToIterableGroupingsFunctionReturn) {
  return groupingFields
    .map((grouping) => {
      const groupingHasValues = Boolean(
        grouping.filters.find((filter) => filter.values && filter.values.length > 0)
      );
      const groupingType = grouping.type; // "AND" or "OR"

      // Only reformat and return a grouping when it has valid filters within
      if (!groupingHasValues) return undefined;

      // Converts filters array to object/key structure
      const formattedFilters = grouping.filters.reduce((obj, filter) => {
        const filterHasValues = filter.values && filter.values.length > 0;
        const comparisonWithValues = _.setWith({}, filter.compareBy as string, filter.values); // Pairs values with the grouping type, i.e., `"domains": [ ...values ]`
        const filterObj = {
          [filter.type || '']: {
            ...obj[filter.type || ''],
            ...comparisonWithValues
          }
        };

        return {
          ...obj,
          ...(filterHasValues ? filterObj : undefined) // Only incorporate the object key and its comparisons when values are present
        };
      }, {});

      return {
        [groupingType || '']: {
          ...formattedFilters
        }
      };
    })
    .filter(Boolean); // Remove undefined entries in the object
}

/**
 * @name hasDuplicateFilters
 * @description within a filter grouping, this function returns `true` when duplicate filters are present.
 * Only the "type" and "compareBy" keys are relevant when making this comparison.
 * @param {Array[Object]} filters - iterable array of filter objects derived from `toIterableGroupings`
 * @returns {boolean} whether or not the group of filters has a duplicate
 */
export function hasDuplicateFilters(filters: Grouping['filters']) {
  // Simplifies filters according to relevant keys
  const formattedFilters = filters.map(({ type, compareBy }) => {
    return {
      type,
      compareBy
    };
  });
  const uniqueFilters = _.uniqWith(formattedFilters, _.isEqual); // Generates an array of unique filters

  // If there are more filters than unique filters, then there must be a duplicate present
  return filters.length > uniqueFilters.length;
}

/**
 * @name filterTypeExistsInGroupings
 * @description within a list of groupings, this function returns `true` when any grouping has the type specified
 * @param {Array[Object]} groupings - iterable array of grouping objects
 * @returns {boolean} whether or not any of the groupings has any filter that of the type passed in
 */
export function filterTypeExistsInGroupings(groupings: Grouping[], type: string) {
  let hasSubjectCampaign = false;

  if (!groupings || !type) return;

  for (let ind = 0; ind < groupings.length; ind++) {
    const grouping = groupings[ind];
    if (hasSubjectCampaign === false)
      hasSubjectCampaign = grouping.filters.some((filter) => filter.type === type);
  }

  return hasSubjectCampaign;
}

/**
 * @name filterKeyExists
 * @description within a list of filters, this function returns `true` when any filter has the key specified
 * @param {Array[Object]} filters - iterable array of filter objects
 * @returns {boolean} whether or not any of the filters have the type passed in
 */
export const filterKeyExists = (filters: Grouping[], key: string) =>
  filters &&
  key &&
  filters.some((filter) =>
    Object.values(filter).some((filterObject) =>
      Object.keys(filterObject).some((filterKey) => filterKey === key)
    )
  );

type Comparison = {
  type: string;
};
/**
 * @name comparisonTypeExists
 * @description within a list of comparisons, this function returns `true` when the 0 index has the type specified
 * @param {Array[Object]} comparisons - iterable array of comparison objects
 * @returns {boolean} whether or not any of the comparisons has the type passed in
 */
export const comparisonTypeExists = (comparisons: Comparison[], type: string) =>
  comparisons && type && comparisons.length > 0 && comparisons[0].type === type;

/**
 * Hashmap of valid filter types
 */
const VALID_FILTERS_KEY_MAP = Object.values(REPORT_BUILDER_FILTER_KEY_MAP || {}).reduce(
  (map, key) => {
    //The `|| {}` is needed to fix some breaking unit tests.
    return map.set(key, true);
  },
  new Map()
);

/**
 * Hashmap of valid comparator values
 */
const VALID_COMPARE_BY_LOWERCASE_MAP = COMPARE_BY_OPTIONS.reduce(
  (accumulator, { value }) => accumulator.set(value.toLowerCase(), true),
  new Map()
);

/**
 * @name getValidFilters
 * @description returns a new array of only valid filters. It only removes at the top level. If any part of
 * the filter is invalid, it will remove the entire top level filter.
 * @param {Array[Object]} filters - iterable array of filter objects derived from `hydrateFilters`
 * @returns {Array[Object]} array of API-consumable [advanced filters](https://developers.sparkpost.com/api/metrics/#header-advanced-filters)
 */
export function getValidFilters(filters: Grouping[]) {
  // Remaps top level comparators to true/false
  // EX: [{AND:{...}},{OR:{...}}] => [true ,false] where true = valid filter and false = invalid filter
  const validFiltersArray = filters.map((grouping: Grouping) => {
    // Checks that every comparator is formatted correctly
    if (typeof grouping !== 'object' || Object.keys(grouping).length < 1) {
      return false;
    }
    const groupingType = Object.keys(grouping)[0];
    // Checks that the top level comparison conjugate is AND/OR
    if (groupingType.toUpperCase() !== 'AND' && groupingType.toUpperCase() !== 'OR') {
      return false;
    }

    // Checks that every filter is formatted correctly
    if (
      typeof grouping[groupingType] !== 'object' ||
      Object.keys(grouping[groupingType]).length < 1
    ) {
      return false;
    }

    // Checks that every filter is valid
    const filterType = grouping[groupingType];
    return Object.keys(filterType).every((filter) => {
      if (!VALID_FILTERS_KEY_MAP.has(filter) || typeof filterType[filter] !== 'object') {
        return false;
      }
      const comparators = filterType[filter];
      // Checks that every comparator is allowed
      return Object.keys(comparators).every((comparator) => {
        return (
          VALID_COMPARE_BY_LOWERCASE_MAP.has(comparator.toLowerCase()) &&
          Array.isArray(comparators[comparator])
        );
      });
    });
  });

  // Re-maps the truthy filters to the actual filters and removes all the falsy ones.
  return validFiltersArray
    .map((isValidFilter, index) => (isValidFilter ? filters[index] : false))
    .filter(Boolean);
}

type TimezoneOptionProps = { label: string; value: string };
/**
 * @name getFilteredTimezoneOptions
 * @description returns a new array of valid timezone options, removing timezones not accepted by the API
 * @returns {Array[TimezoneOptionProps]} array of timezone options
 */
export const getFilteredTimezoneOptions = (): Array<TimezoneOptionProps> =>
  formatTimezonesToOptions().filter(
    (option: TimezoneOptionProps) => !METRICS_TIMEZONE_BLOCK_LIST.includes(option.value)
  );
