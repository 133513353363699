import React, { useCallback, useContext, useMemo } from 'react';
import { connect } from 'react-redux';
import { BarChart, Star, StarOutline, MoreHoriz } from '@sparkpost/matchbox-icons';
import { Button } from 'src/components/matchbox';
import { TranslatableText } from 'src/components/text';
import { useAnalyticsReportContext } from '../../context/AnalyticsReportContext';
import { tokens } from '@sparkpost/design-tokens';
import { SidebarContext } from 'src/context/SidebarContext';
import { WindowSizeContext } from 'src/context/WindowSize';
import { MEDIA_QUERY_TOKENS } from 'src/constants';
import useAnalyticsReportModal from '../../hooks/useAnalyticsReportModal';
import { isUserUiOptionSet } from 'src/helpers/conditions/user';
import { updateUserUIOptions } from 'src/actions/currentUser';
import { showAlert } from 'src/actions/globalAlert';
import { uniqBy } from 'lodash';
import { ActionPopover } from 'src/components';

export function SavedReportsSection(props) {
  const {
    state: { selectedReport }
  } = useAnalyticsReportContext();
  const {
    openEditReportModal,
    openSaveReportModal,
    openScheduledReportModal,
    openPinToDashboardModal
  } = useAnalyticsReportModal();
  const { openSidebar } = useContext(SidebarContext);
  const { screenWidth } = useContext(WindowSizeContext);

  const { scheduledReports, favoriteReports, showAlert, updateUserUIOptions, isPending } = props;

  const hasScheduledReports = scheduledReports && scheduledReports.length > 0;
  const isMobileVersion = screenWidth < MEDIA_QUERY_TOKENS[tokens.mediaQuery_md];
  const isReportFavorited = favoriteReports?.includes(selectedReport?.id);
  const canSaveReport = selectedReport?.current_user_can_edit && selectedReport.type !== 'preset';

  const addOrRemoveFromFavorites = useCallback(() => {
    const addToFavorites = !isReportFavorited;

    updateUserUIOptions({
      favorite_reports: uniqBy(
        addToFavorites
          ? [...favoriteReports, selectedReport?.id]
          : uniqBy(favoriteReports.filter((id) => id !== selectedReport?.id))
      )
    }).then(() => {
      showAlert({
        type: 'success',
        message: `You have successfully saved ${selectedReport.name}`
      });
    });
  }, [favoriteReports, isReportFavorited, selectedReport, showAlert, updateUserUIOptions]);

  const popoverActions = useMemo(() => {
    const actions = [
      canSaveReport
        ? {
            content: 'Edit Details',
            onClick: () => {
              openEditReportModal(selectedReport);
            }
          }
        : {},
      hasScheduledReports && selectedReport?.id
        ? {
            id: '1',
            content: `Schedule Report`,
            onClick: () => {
              openScheduledReportModal(selectedReport);
            }
          }
        : {},
      !hasScheduledReports && selectedReport?.id
        ? {
            id: '2',
            content: `Schedule Report`,
            to: `/signals/schedule/${selectedReport?.id}`
          }
        : {},
      {
        content: 'Pin to Dashboard',
        onClick: () => {
          openPinToDashboardModal(selectedReport);
        }
      }
    ];

    return actions.filter((action) => action.content);
  }, [
    canSaveReport,
    hasScheduledReports,
    openEditReportModal,
    openPinToDashboardModal,
    openScheduledReportModal,
    selectedReport
  ]);

  return (
    <>
      {isMobileVersion && (
        <Button variant="tertiary" onClick={openSidebar}>
          <TranslatableText>View Reports</TranslatableText>
          <Button.Icon as={BarChart} ml="100" />
        </Button>
      )}
      <ActionPopover
        direction="right"
        display="inline"
        textAlign="left"
        actions={popoverActions}
        buttonId="report-actions-popover"
        buttonProps={{
          size: 'small',
          variant: 'secondary'
        }}
        popoverProps={{
          position: 'bottomLeft'
        }}
        customIcon={MoreHoriz}
      />
      {canSaveReport && (
        <>
          <Button
            data-id="favorite-report-button"
            variant="secondary"
            size="small"
            onClick={addOrRemoveFromFavorites}
            loading={isPending}
            mx="200"
          >
            <TranslatableText>{isReportFavorited ? 'Unfavorite' : 'Favorite'}</TranslatableText>
            <Button.Icon as={isReportFavorited ? Star : StarOutline} ml="100" />
          </Button>
          <Button
            data-id="save-report-changes-button"
            variant="secondary"
            size="small"
            onClick={() => {
              openSaveReportModal(selectedReport);
            }}
          >
            <TranslatableText>Save Changes</TranslatableText>
          </Button>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    reports: state.reports.list,
    status: state.reports.status,
    favoriteReports: isUserUiOptionSet('favorite_reports', [])(state),
    isPending: state.currentUser.userOptionsPending
  };
};

const mapDispatchToProps = {
  showAlert,
  updateUserUIOptions
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedReportsSection);
