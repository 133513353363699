import { useMemo } from 'react';
import { getMetricsFromKeys } from 'src/helpers/metrics';
import { toQueryFromOptions } from '@sparkpost/report-builder/helpers/metrics';

/**
 * Prepares options for requests based on the current state of the passed in `reportOptions`. Returns URL encoded JSON.
 *
 * @param {Object} reportOptions - state object of the user's currently selected filters, comparisons, etc.
 */
export default function usePrepareAnalyticsReportQuery(reportOptions) {
  const { metrics } = reportOptions;
  const formattedMetrics = useMemo(() => {
    return getMetricsFromKeys(metrics, true);
  }, [metrics]);
  const formattedOptions = useMemo(() => {
    return toQueryFromOptions({
      ...reportOptions,
      metrics: formattedMetrics
    });
  }, [reportOptions, formattedMetrics]);

  return formattedOptions;
}
