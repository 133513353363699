import React from 'react';
import { SubduedLink, ExternalLink } from 'src/components/links';
import { LINKS } from 'src/constants';

function MailboxProviderSubTitle() {
  return (
    <SubduedLink as={ExternalLink} to={LINKS.MAILBOX_PROVIDER_DOCS} fontSize="200">
      Learn more about ISPs
    </SubduedLink>
  );
}

export default MailboxProviderSubTitle;
