import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Panel, Stack, TextField } from 'src/components/matchbox';
import { SubaccountTypeaheadController } from 'src/components/reactHookFormControllers';
import { Form } from 'src/components/tracking';
import { createOrUpdateSuppressions } from 'src/helpers/api/suppressions';
import { isSubaccountUser as isSubaccountUserSelector } from 'src/helpers/conditions/user';
import { toRecipientsArray } from 'src/helpers/suppressions';
import { email } from 'src/helpers/validation';
import { useAlert, useSparkPostMutation } from 'src/hooks';
import { hasSubaccounts as hasSubaccountsSelector } from 'src/selectors/subaccounts';

export default function AddForm() {
  const { showAlert } = useAlert();
  const form = useForm({ mode: 'onBlur' });
  const mutation = useSparkPostMutation(
    ({ recipients, subaccount } = {}) => createOrUpdateSuppressions({ recipients, subaccount }),
    { onSuccess: handleSuccess }
  );
  const isSubaccountUser = useSelector(isSubaccountUserSelector);
  const hasSubaccounts = useSelector(hasSubaccountsSelector);
  const selectedSubaccount = useSelector((state) => state.currentUser.selectedSubaccount);

  /**
   * @description handles successful form submission/API request
   */
  function handleSuccess() {
    showAlert({ type: 'success', message: 'Successfully updated your suppression list' });

    form.reset({
      recipient: '',
      subaccount: '',
      'type.transactional': '',
      'type.non_transactional': '',
      description: ''
    });
  }

  /**
   * @description handles form submission after `react-hook-form` handles validation
   * @returns {function} the returned mutation function derived from `useSparkPostMutation`
   */
  function submitHandler(data) {
    const { subaccount: subaccountChosen } = data;
    const recipients = toRecipientsArray(data);

    /**
     * `isSubacc` will check if the user is a subaccount user and if they have no subaccounts
     *
     * When `isSubacc === false`, `<SubaccountTypeaheadController /> doesn't render. By default, this component allows user select the `subaccount`
     * and when the user leaves the field blank, the `subaccount` is empty (which is handled to be the primary account)
     *
     * But when `isSubacc === true` and `subaccount` is empty, the request returns an error because the user doesn't have persmission to change the primary account
     *
     * So, when `isSubacc === true` and `subaccount` is empty, we set the `subaccount` to the user's selected subaccount
     */
    const isSubacc = isSubaccountUser && !hasSubaccounts;

    const subaccount = isSubacc && subaccountChosen === '' ? selectedSubaccount : subaccountChosen;

    return mutation.mutate({ recipients, subaccount });
  }

  /**
   * @description validates whether either "type" field is checked
   * @returns {boolean}
   */
  function hasTypeSelected() {
    const { type } = form.getValues();

    return type.non_transactional || type.transactional;
  }

  return (
    <Form onSubmit={form.handleSubmit(submitHandler)} id="suppressions-add-form">
      <Panel.Section>
        <Stack>
          <TextField
            {...form.register('recipient', { required: true, validate: email })}
            label="Email Address"
            name="recipient"
            id="email-address-textfield"
            disabled={mutation.status === 'loading'}
            error={form.formState.errors.recipient ? 'A valid email address is required.' : null}
            defaultValue=""
            data-sensitive={true}
          />
          <SubaccountTypeaheadController
            control={form.control}
            name="subaccount"
            id="subaccount-typeahead"
            disabled={mutation.status === 'loading'}
            helpText="Leaving this field blank will add the suppressions to the primary account."
          />
          <Checkbox.Group label="Type">
            <Checkbox
              {...form.register('type.transactional', { validate: hasTypeSelected })}
              label="Transactional"
              name="type.transactional"
              id="transactional-checkbox"
              disabled={mutation.status === 'loading'}
              data-track={true}
              error={form.formState.errors.type ? 'You must select at least one Type' : null}
            />

            <Checkbox
              {...form.register('type.non_transactional')}
              label="Non-Transactional"
              name="type.non_transactional"
              id="non-transactional-checkbox"
              data-track={true}
              disabled={mutation.status === 'loading'}
            />
          </Checkbox.Group>
          <TextField
            {...form.register('description')}
            label="Description"
            name="description"
            id="description-textfield"
            data-track={true}
            defaultValue=""
          />
        </Stack>
      </Panel.Section>

      <Panel.Section>
        <Button
          variant="primary"
          disabled={!form.formState.isDirty}
          loading={mutation.status === 'loading'}
          type="submit"
        >
          Add / Update
        </Button>
      </Panel.Section>
    </Form>
  );
}
