import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { Drawer } from 'src/components/matchbox';

type UseDrawer = Omit<ReturnType<typeof Drawer.useDrawer>, 'openDrawer' | 'closeDrawer'>;

type UseSidebarContext = UseDrawer & {
  closeSidebar: () => void;
  isSidebarOpen: boolean;
  openSidebar: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = (): void => {};

const defaultContext: UseSidebarContext = {
  isSidebarOpen: false,
  closeSidebar: noop,
  getActivatorProps: () => ({
    onClick: noop,
    'aria-controls': undefined
  }),
  getDrawerProps: () => ({
    onClose: noop,
    open: false,
    id: undefined
  }),
  isOpen: false,
  openSidebar: noop,
  toggleDrawer: noop
};

export const SidebarContext = createContext(defaultContext);

export const SidebarProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { getDrawerProps, getActivatorProps, openDrawer, closeDrawer, ...rest } = Drawer.useDrawer({
    id: 'view-reports'
  });

  const openSidebar = (): void => {
    setIsSidebarOpen(true);
    openDrawer();
  };

  const closeSidebar = (): void => {
    setIsSidebarOpen(false);
    closeDrawer();
  };

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        getDrawerProps,
        getActivatorProps,
        openSidebar,
        closeSidebar,
        ...rest
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = (): UseSidebarContext => useContext(SidebarContext);

export default SidebarProvider;
