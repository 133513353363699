export const ALERT_TYPES = {
  metrics: 1,
  blocklistings: 2,
  health_score: 3,
  sending_limit_alert: 4
};

export const ALERT_TYPES_NAMES = Object.keys(ALERT_TYPES);

export const SENDING_LIMIT_ALERT_METRIC = {
  key: 'sending_limit_alert',
  label: 'Sending Limit Alert'
};

export const BLOCKLIST_ALERT_METRIC = {
  key: 'blocklistings',
  label: 'Blocklist'
};

export const HEALTH_SCORE_ALERT_METRIC = {
  key: 'health_score',
  label: 'Health Score'
};
