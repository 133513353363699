import React from 'react';
import { Empty } from 'src/components';
import { LabelValueLoadingSkeleton } from 'src/components/loading';
import { Column, Columns, LabelValue, Panel } from 'src/components/matchbox';
import useResourceDetailsContext from '../../hooks/useResourceDetailsContext';
import DataSources from './DataSources';

export default function SendingIpTopSection({
  hasD12yProduct,
  deliverabilityDataStatus,
  deliverabilityData
}) {
  const { match: { params: { id } = {} } = {} } = useResourceDetailsContext();
  const labelValues = [
    { width: 1 / 6, value: 'Mailbox Provider' },
    { width: 1 / 6, value: 'Sent' },
    hasD12yProduct && { width: 1 / 6, value: 'Seed Count' },
    hasD12yProduct && { width: 1 / 6, value: 'Panel Count' }
  ];

  if (deliverabilityDataStatus === 'loading') {
    return (
      <Panel.Section>
        <LabelValueLoadingSkeleton labelValues={labelValues} />
      </Panel.Section>
    );
  }

  return (
    <div data-id="mailbox-provider-top-section">
      {deliverabilityDataStatus === 'error' && (
        <Empty message="Failed to load the data. Refresh page."></Empty>
      )}
      {deliverabilityDataStatus === 'success' && (
        <Panel.Section>
          <Columns>
            <Column width={1 / 6}>
              <LabelValue>
                <LabelValue.Label>Mailbox Provider</LabelValue.Label>
                <LabelValue.Value>{id}</LabelValue.Value>
              </LabelValue>
            </Column>

            <DataSources
              dataDisplayOnly={true}
              hasD12yProduct={hasD12yProduct}
              deliverabilityData={deliverabilityData}
              deliverabilityDataStatus={deliverabilityDataStatus}
            />
          </Columns>
        </Panel.Section>
      )}
    </div>
  );
}
