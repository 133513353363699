import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSparkPostQuery } from 'src/hooks';
import { getSubaccounts } from 'src/helpers/api/subaccounts';
import { isSubaccountUser as isSubaccountUserSelector } from 'src/helpers/conditions/user';
import { ApiErrorBanner, Loading } from 'src/components';
import { PRIMARY_SUBACCOUNT } from 'src/constants';
import { AUTOMATIC_SEEDING_ROUTE } from '../../constants';

export function AccountConfigRouteHandler() {
  const isSubaccountUser = useSelector(isSubaccountUserSelector);
  const {
    status,
    data: subaccount,
    error,
    refetch
  } = useSparkPostQuery(getSubaccounts, {
    enabled: isSubaccountUser, // This request only occurs if the user is a subaccount user
    select: (data) => data[0] // Only the current subaccount is returned when a subaccount user makes this request
  });

  if (status === 'loading') return <Loading />;

  if (status === 'error') return <ApiErrorBanner reload={refetch} errorDetails={error.message} />;

  const subaccountId = isSubaccountUser ? subaccount.id : PRIMARY_SUBACCOUNT.id;

  return <Redirect to={`${AUTOMATIC_SEEDING_ROUTE}/config/${subaccountId}`} />;
}
