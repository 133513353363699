import React from 'react';

// ICONS
import { ChevronRight } from '@sparkpost/matchbox-icons';

// COMPONENTS
import { PageLink } from 'src/components/links';
import { Button } from 'src/components/matchbox';

// HELPERS
import { getDetailsPath } from 'src/helpers/messageEvents';

// STYLES
import styles from './ViewDetailsButton.module.scss';

// TYPES
import { Event } from 'src/typescript';

type ViewDetailsButtonProps = {
  eventId: Event['event_id'];
  /** @deprecated use eventId instead */
  event_id?: Event['event_id'];
  messageId: Event['message_id'];
  /** @deprecated use messageId instead */
  message_id?: Event['message_id'];
};

export default function ViewDetailsButton({
  eventId,
  messageId,
  ...deprecated
}: ViewDetailsButtonProps): JSX.Element {
  const paramEventId = eventId || deprecated.event_id;
  const paramMessageId = messageId || deprecated.message_id;

  const to = {
    pathname: getDetailsPath(paramMessageId, paramEventId)
  };

  return (
    <div className={styles.AlignRight}>
      <PageLink as={Button} to={to} variant="minimal" size="small">
        <span>View Details</span>
        <ChevronRight />
      </PageLink>
    </div>
  );
}
