export enum WeekDays {
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
}

export const TAB_METRIC_AND_FILTERING = 1;
export const TAB_DATA_MEASUREMENT = 2;
export const TAB_CONDITION_CONFIGURATION = 3;
export const TAB_DETAILS_AND_NOTIFICATIONS = 4;

export const HEALTHSCORE_EVALUTATION_TYPES = Object.freeze({
  raw: 'raw',
  day_over_day: 'day_over_day',
  week_over_week: 'week_over_week'
});
export const HEALTHSCORE_EVALUTATION_LABELS = Object.freeze({
  raw: 'Score',
  day_over_day: 'Day Over Day',
  week_over_week: 'Week Over Week'
});
export const METRICS_BREAKDOWN_TYPES = Object.freeze({
  byValue: 'by-value',
  breakdownByProperty: 'breakdown-by-property'
});

export const metricsAndUnits = [
  {
    key: 'count_targeted',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_injected',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_sent',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_accepted',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_delivered_first',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_delivered_subsequent',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_spam_complaint',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'spam_complaint_rate',
    unit: 'percent',
    placeholder: '%'
  },
  {
    key: 'count_rendered',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_unique_rendered',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_initial_rendered',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_unique_initial_rendered',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_unique_confirmed_opened',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_clicked',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_unique_clicked',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_bounce',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_hard_bounce',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_soft_bounce',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_block_bounce',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_admin_bounce',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_undetermined_bounce',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_rejected',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_policy_rejection',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_generation_failed',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_unsubscribe',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_generation_rejection',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'accepted_rate',
    unit: 'percent',
    placeholder: '%'
  },
  {
    key: 'open_rate',
    unit: 'percent',
    placeholder: '%'
  },
  {
    key: 'prefetched_open_rate',
    unit: 'percent',
    placeholder: '%'
  },
  {
    key: 'click_through_rate',
    unit: 'percent',
    placeholder: '%'
  },
  {
    key: 'bounce_rate',
    unit: 'percent',
    placeholder: '%'
  },
  {
    key: 'hard_bounce_rate',
    unit: 'percent',
    placeholder: '%'
  },
  {
    key: 'soft_bounce_rate',
    unit: 'percent',
    placeholder: '%'
  },
  {
    key: 'block_bounce_rate',
    unit: 'percent',
    placeholder: '%'
  },
  {
    key: 'admin_bounce_rate',
    unit: 'percent',
    placeholder: '%'
  },
  {
    key: 'undetermined_bounce_rate',
    unit: 'percent',
    placeholder: '%'
  },
  {
    key: 'unsubscribe_rate',
    unit: 'percent',
    placeholder: '%'
  },
  {
    key: 'count_delayed',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'delayed_rate',
    unit: 'percent',
    placeholder: '%'
  },
  {
    key: 'rejected_rate',
    unit: 'percent',
    placeholder: '%'
  },
  {
    key: 'count_delayed_first',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'avg_delivery_time_first',
    unit: 'milliseconds',
    placeholder: 'ms'
  },
  {
    key: 'avg_delivery_time_subsequent',
    unit: 'milliseconds',
    placeholder: 'ms'
  },
  {
    key: 'avg_msg_size',
    unit: 'bytes',
    placeholder: 'bytes'
  },
  {
    key: 'total_msg_volume',
    unit: 'bytes',
    placeholder: 'bytes'
  },
  {
    key: 'count_inband_bounce',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_outofband_bounce',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_raw_clicked',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_inbox',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_spam',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'inbox_folder_rate',
    unit: 'percent',
    placeholder: '%'
  },
  {
    key: 'spam_folder_rate',
    unit: 'percent',
    placeholder: '%'
  },
  {
    key: 'count_moved_to_inbox',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'count_moved_to_spam',
    unit: 'number',
    placeholder: ' '
  },
  {
    key: 'moved_to_inbox_rate',
    unit: 'percent',
    placeholder: '%'
  },
  {
    key: 'moved_to_spam_rate',
    unit: 'percent',
    placeholder: '%'
  }
];
