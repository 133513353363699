import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Panel, Stack, Button, Box, Checkbox, TextField, Text } from 'src/components/matchbox';
import { PanelLoading } from 'src/components/loading';
import { generateBackupCodes } from 'src/helpers/api/users';
import { get as getCurrentUser } from 'src/actions/currentUser';
import { updateUserUIOptions } from 'src/actions/currentUser';
import { useAlert, useSparkPostMutation } from 'src/hooks';
import copy from 'copy-to-clipboard';
import CenteredLogo from 'src/components/sparkPost/CenteredLogo';
import { isUserUiOptionSet } from 'src/helpers/conditions/user';

// TODO REMOVE - https://sparkpost.atlassian.net/browse/FE-1684
function RecoveryCodePage({ getCurrentUser, isCurrentUserLoading, isAuth0User }) {
  const [backupCode, setBackupCode] = useState('');
  const [codeCopied, setCodeCopied] = useState(false);
  const [error, setError] = useState();
  const { showAlert } = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  const hasRecoveryCode = useSelector((state) =>
    isUserUiOptionSet('has_recovery_code', false)(state)
  );

  const onCheckboxChange = (e) => {
    if (error) setError('');
    setCodeCopied(e.target.checked);
  };
  const handleGenerateBackupCodeSuccess = (resp) => {
    setBackupCode(resp.codes);
  };

  const handleGenerateBackupCodeError = (err) => {
    showAlert({ type: 'error', message: 'Something went wrong.', details: err.message });
  };
  const generateBackupCodeMutation = useSparkPostMutation(
    ({ username } = {}) => generateBackupCodes({ username }),
    {
      onSuccess: handleGenerateBackupCodeSuccess,
      onError: handleGenerateBackupCodeError
    }
  );
  const handleSubmit = () => {
    if (!codeCopied) {
      setError('Please confirm you have copied the code before continuing');
    } else {
      history.replace({ pathname: '/landing' });
      dispatch(
        updateUserUIOptions({
          has_recovery_code: true
        })
      );
    }
  };
  const handleCopyCode = () => copy(backupCode);
  useEffect(() => {
    getCurrentUser().then((resp) => {
      if (resp?.auth_migrated && !hasRecoveryCode)
        generateBackupCodeMutation.mutate({ username: resp.username });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isCurrentUserLoading || generateBackupCodeMutation.status === 'loading') {
    return <PanelLoading />;
  }

  if (!isAuth0User || hasRecoveryCode) {
    history.replace({ pathname: '/landing' });
  }

  return (
    <Box maxWidth="1100" margin="auto">
      <Panel>
        <Panel.SubHeader as="div">
          <Box alignItems="center">
            <CenteredLogo />
            <Text textAlign="center" as="h1" looksLike="h4">
              Save Your Recovery Code{' '}
            </Text>
          </Box>
        </Panel.SubHeader>
        <Panel.Section>
          <Stack>
            <>
              Copy this recovery code and keep it somewhere safe. You’ll need it if you ever need to
              log in without your device.
            </>
            <TextField
              id="hostname-copy-field"
              label="Recovery Code"
              value={backupCode}
              labelHidden
            />
            <Button variant="secondary" onClick={handleCopyCode}>
              Copy Code
            </Button>
            <Checkbox
              id="Code Copied"
              key="code-copied"
              label="I have safely recorded this code."
              value={codeCopied}
              onClick={onCheckboxChange}
              error={!codeCopied && error}
            />
            <Button
              variant="primary"
              onClick={() => {
                handleSubmit();
              }}
            >
              Continue
            </Button>
          </Stack>
        </Panel.Section>
      </Panel>
    </Box>
  );
}

const mapStateToProps = ({ currentUser }) => {
  return {
    isCurrentUserLoading: currentUser?.loading,
    isAuth0User: currentUser?.auth_migrated
  };
};

export default connect(mapStateToProps, { getCurrentUser })(RecoveryCodePage);
