import { sparkpostLogin } from '../helpers/http';
import sparkpostApiRequest from 'src/actions/helpers/sparkpostApiRequest';
import * as websiteAuth from 'src/actions/websiteAuth';
import { getTfaStatusBeforeLoggedIn } from 'src/actions/tfa';
import { showAlert } from 'src/actions/globalAlert';
import authCookie from '../helpers/authCookie';
import { initializeAccessControl } from './accessControl';
import { AUTH_ROUTE } from 'src/constants';

const authErrorHandler = (dispatch) => (err) => {
  const { response = {} } = err;
  const { data = {}, status } = response;
  let { error_description: errorDescription } = data;

  if (status === 403) {
    errorDescription = `${
      errorDescription || 'Something went wrong.'
    } Please email compliance@sparkpost.com if you need assistance.`;
  } else if (status === 406) {
    // API says user is migrated to Auth0 already. Do not let them log in via the app login form.
    errorDescription = ''; // so they don't get the "unknown error" if they hit the back button
  }

  dispatch({
    type: 'LOGIN_FAIL',
    payload: {
      errorDescription
    }
  });

  let result;
  if (status === 406) {
    result = { auth: false, redirectLogin: true, redirectTo: AUTH_ROUTE };
  } else {
    result = { auth: false };
  }

  return result;
};

/**
 * does the necessary actions to log a user in and set appropriate data in redux
 * store and auth cookie
 * Note: the login details are updated separately from setting "logged in"
 *
 * @param authData {Object} data used to update redux store and cookie
 * @param saveCookie {Boolean} saves session cookie
 *
 */
export function login({ authData = {}, saveCookie = false }) {
  if (saveCookie) {
    // save auth cookie
    authCookie.save(authData);
  }

  return (dispatch) => {
    dispatch(websiteAuth.login(saveCookie)); // Complete the website cookie set up process
    dispatch({
      type: 'LOGIN_SUCCESS',
      payload: authData
    });
    dispatch(initializeAccessControl());
  };
}

/**
 * The login form flow
 * 1. See if user is logged in
 * 2. Authenticate user
 * 3. Check if 2FA is on, if so - set the flag in the redux store
 * 4. If not, update auth details in redux store, set cookie and update loggedIn
 *    within redux store
 *
 */
export function authenticate(username, password, rememberMe = false, access_token) {
  // return a thunk
  return (dispatch, getState) => {
    const { loggedIn } = getState().auth;
    const isTokenLogin = !!access_token;
    if (loggedIn) {
      return;
    }

    dispatch({ type: 'LOGIN_PENDING' });

    const maybeLogin = isTokenLogin
      ? Promise.resolve({ data: { access_token } })
      : sparkpostLogin(username, password, rememberMe);

    return maybeLogin
      .then(({ data = {} } = {}) => {
        const authData = { ...data, username };

        //Skips website login if token login
        //Token login is used for internal use, so won't need website auth
        if (!isTokenLogin) {
          dispatch(websiteAuth.authenticate(username, password, rememberMe));
        }
        // Start website auth token cookie setup process

        return Promise.all([
          authData,
          getTfaStatusBeforeLoggedIn({ username, token: authData.access_token })
        ]);
      })
      .then(([authData, tfaResponse]) => {
        const { enabled: tfaEnabled, required: tfaRequired } = tfaResponse.data.results;
        const tfaAction = actOnTfaStatus(tfaEnabled, tfaRequired, authData);
        if (tfaAction) {
          dispatch(tfaAction);
        } else {
          dispatch(login({ authData, saveCookie: true }));
        }

        return { auth: true, tfaRequired };
      })
      .catch(authErrorHandler(dispatch));
  };
}

/**
 *
 * The auth0 login form flow
 * 1. See if user is logged in
 * 2. Authenticate user
 * 3. Do NOT check tfa required (auth0 does that for us)
 * 4. Update auth details in redux store, set cookie and update loggedIn within redux store
 *
 * @returns {function} Thunk function that dispatches a call to login on success and a call to "LOGIN_FAIL" when an error happens.
 *
 */
export function auth0Authenticate(username, password, rememberMe = false, access_token) {
  // return a thunk
  return (dispatch, getState) => {
    const { loggedIn } = getState().auth;
    const isTokenLogin = !!access_token;
    if (loggedIn) {
      return;
    }

    dispatch({ type: 'LOGIN_PENDING' });

    const maybeLogin = isTokenLogin
      ? Promise.resolve({ data: { access_token } })
      : sparkpostLogin(username, password, rememberMe);

    return maybeLogin
      .then(({ data = {} } = {}) => {
        const authData = { ...data, username };
        return Promise.all([
          authData,
          getTfaStatusBeforeLoggedIn({ username, token: access_token })
        ]);
      })
      .then(([authData, tfaResponse]) => {
        const { enabled: tfaEnabled, required: tfaRequired } = tfaResponse.data.results;
        const tfaAction = actOnTfaStatusForAuth0(tfaEnabled, tfaRequired, authData);
        if (tfaAction) {
          dispatch(tfaAction);
        }
        dispatch(login({ authData, saveCookie: true }));

        return { auth: true };
      })
      .catch(authErrorHandler(dispatch));
  };
}

function actOnTfaStatus(tfaEnabled, tfaRequired, authData) {
  if (tfaEnabled) {
    return { type: 'TFA_ENABLED_ON_LOGIN', payload: authData };
  }
  if (tfaRequired) {
    return { type: 'TFA_REQUIRED_ON_LOGIN', payload: authData };
  }
  return null;
}

function actOnTfaStatusForAuth0(tfaEnabled, _tfaRequired, authData) {
  if (tfaEnabled) {
    return { type: 'TFA_ENABLED_ON_LOGIN', payload: authData };
  }
  // tfa required is not an option available to auth0 users,
  // this code is left in if in case we decide to handle the edge case during migration
  // if (tfaRequired) {
  //   return { type: 'TFA_REQUIRED_ON_LOGIN', payload: authData };
  // }
  return null;
}

export function confirmPassword(username, password) {
  return (dispatch) => {
    dispatch({ type: 'CONFIRM_PASSWORD' });

    return sparkpostLogin(username, password, false)
      .then(({ data = {} } = {}) => {
        const payload = { ...data, username };

        // dispatch login success event
        dispatch({
          type: 'CONFIRM_PASSWORD_SUCCESS',
          payload
        });
      })
      .catch((err) => {
        const { response = {} } = err;
        const { data = {} } = response;
        const { error_description: errorDescription } = data;

        dispatch({
          type: 'CONFIRM_PASSWORD_FAIL',
          payload: {
            errorDescription
          }
        });

        // To match sparkpostApiRequest behavior
        dispatch(showAlert({ type: 'error', message: errorDescription }));

        throw err;
      });
  };
}

export function ssoCheck(username) {
  return sparkpostApiRequest({
    type: 'SSO_CHECK',
    meta: {
      method: 'GET',
      url: `/v1/users/${username}/saml`,
      username
    }
  });
}

export function invalidateAuthToken(token) {
  return sparkpostApiRequest({
    type: 'LOGOUT',
    meta: {
      method: 'POST',
      url: '/v1/authenticate/logout',
      data: `token=${token}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `${token}`
      }
    }
  });
}

export function refreshAuth0(token) {
  return (dispatch) => {
    const newCookie = authCookie.merge({ access_token: token });
    return dispatch(login({ authData: newCookie }));
  };
}

export function refresh(token, refreshToken) {
  return (dispatch) => {
    const newCookie = authCookie.merge({ access_token: token, refresh_token: refreshToken });
    dispatch(websiteAuth.refresh());
    return dispatch(login({ authData: newCookie }));
  };
}

export function logout() {
  return (dispatch, getState) => {
    const { auth } = getState();
    const { token, refreshToken } = auth;

    // only log out if currently logged in
    if (!auth.loggedIn) {
      return;
    }

    dispatch(invalidateAuthToken(token));

    if (refreshToken) {
      dispatch(invalidateAuthToken(refreshToken));
    }

    dispatch(websiteAuth.logout());

    authCookie.remove();

    dispatch({
      type: 'LOGOUT'
    });
  };
}

export function setRedirectAfterLogin(redirectAfterLogin) {
  return {
    type: 'SET_REDIRECT_AFTER_LOGIN',
    payload: { redirectAfterLogin }
  };
}

export function clearRedirectAfterLogin() {
  return {
    type: 'CLEAR_REDIRECT_AFTER_LOGIN',
    payload: {}
  };
}
