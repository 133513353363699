import { formatInputDate } from '@sparkpost/report-builder/helpers/date';
import { setSubaccountHeader } from 'src/helpers/subaccounts';

// order_by param values do not match field names, so we have to translate here
const ORDER_BY_MAPPING = {
  current_engaged_recipients: 'c_14d',
  current_health_score: 'health_score',
  current_relative_engaged_recipients: 'perc',
  current_relative_trap_hits: 'perc',
  current_trap_hits: 'trap_hits',
  current_total_injection_count: 'total_injection_count'
};

const getSignalsInformation =
  ({ dimension }) =>
  ({ facet = '', filter, from, limit, offset, order, orderBy, subaccount, to }) => {
    let order_by;

    if (orderBy) {
      // To order by subaccount, only pass order direction and do not set order_by
      // does not apply to health score, and health score should set order_by: 'sid'
      order_by =
        orderBy === 'sid' && dimension !== 'health-score'
          ? undefined
          : ORDER_BY_MAPPING[orderBy] || orderBy;
    }

    if (facet === 'sid') {
      facet = '';
      subaccount = filter;
      filter = '';
    }

    // Block spam trap request for mb provider
    if (facet === 'mb_provider' && dimension === 'spam-hits') {
      return () => undefined;
    }

    return {
      method: 'GET',
      headers: setSubaccountHeader(subaccount),
      url: `/v1/signals/${dimension}/${facet}`,
      params: {
        filter,
        from: formatInputDate.useMomentInput(from),
        limit,
        offset,
        order,
        order_by,
        to: formatInputDate.useMomentInput(to)
      }
    };
  };

export const getEngagementRecency = getSignalsInformation({
  dimension: 'cohort-engagement'
});

export const getEngagementRateByCohort = getSignalsInformation({
  dimension: 'eng-cohort'
});

export const getUnsubscribeRateByCohort = getSignalsInformation({
  dimension: 'unsub-cohort'
});

export const getComplaintsByCohort = getSignalsInformation({
  dimension: 'fbl-cohort'
});

export const getHealthScore = getSignalsInformation({
  dimension: 'health-score'
});

export const getSpamHits = getSignalsInformation({
  dimension: 'spam-hits'
});
