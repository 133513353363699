import { useReducer } from 'react';
import {
  getEngagementRateByCohort,
  getUnsubscribeRateByCohort,
  getComplaintsByCohort
} from 'src/helpers/api/signals';
const ACTION = {
  ENGAGEMENT_RATE_SELECTED: 'cohort-engagement',
  UNSUBSCRIBE_RATE_SELECTED: 'unsub-cohort',
  COMPLAINT_RATE_SELECTED: 'fbl-cohort'
};
const reducer = (state, action = {}) => {
  switch (action.type) {
    case ACTION.UNSUBSCRIBE_RATE_SELECTED:
      return {
        selectedType: ACTION.UNSUBSCRIBE_RATE_SELECTED,
        get: getUnsubscribeRateByCohort
      };
    case ACTION.COMPLAINT_RATE_SELECTED:
      return {
        selectedType: ACTION.COMPLAINT_RATE_SELECTED,
        get: getComplaintsByCohort
      };
    default:
    case ACTION.ENGAGEMENT_RATE_SELECTED:
      return {
        selectedType: ACTION.ENGAGEMENT_RATE_SELECTED,
        get: getEngagementRateByCohort
      };
  }
};

export default function useEngagementRateByCohort() {
  const [state, dispatch] = useReducer(reducer, reducer());

  return { ...state, dispatch };
}
