/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';

import { Box, Button, Layout, Panel, Radio, RadioCard, Stack, Text } from 'src/components/matchbox';

import { useParams } from 'react-router-dom';
import { ExternalLink, SubduedLink } from 'src/components/links';
import { Heading } from 'src/components/text';
import { Form } from 'src/components/tracking';
import Typeahead from 'src/pages/analyticsReport/components/Typeahead';
import { FILTER_OPTIONS } from 'src/pages/analyticsReport/constants/index.js';
import { useAlertForm } from './useAlertForm';

const subaccountFilter = FILTER_OPTIONS.find((option) => option.value === 'subaccounts');

export const TAB_TYPE_SELECTION = 0;

export default function TypeSelection({ goToSetup }) {
  const {
    state: { type, accountData },
    dispatch,
    validatePageFields
  } = useAlertForm();
  const { alertId } = useParams();
  const isDuplicateAlert = alertId !== undefined;

  const handleChangeType = useCallback(
    (e) => {
      dispatch({ type: 'UPDATE_TYPE', data: e.target.id });
    },
    [dispatch]
  );

  const handleChangeAccountAssignment = useCallback(
    (isChecked, assignment) => {
      if (isChecked) {
        dispatch({ type: 'UPDATE_ACCOUNT_DATA', data: { assignment } });
      }
    },
    [dispatch]
  );

  const handleGoToSetup = useCallback(() => {
    const hasErrors = validatePageFields(TAB_TYPE_SELECTION);

    if (hasErrors) return;

    goToSetup();
  }, [validatePageFields, goToSetup]);

  const handleSubaccountSelection = useCallback(
    ({ values }) => {
      const subaccount = values[0] || {};

      if (subaccount.value === accountData.subaccount.value) {
        return;
      }

      if (!subaccount.value) {
        return dispatch({ type: 'UPDATE_ACCOUNT_DATA', data: { subaccount: {} } });
      }

      dispatch({ type: 'UPDATE_ACCOUNT_DATA', data: { subaccount: values[0] } });
    },
    [accountData.subaccount.value, dispatch]
  );

  return (
    <Layout>
      <Layout.Section annotated>
        <Stack space="300">
          <Heading as="h4">Alert Type</Heading>
          <Text>
            Alerts provide real-time notifications for important metrics. To configure an alert,
            choose a metric category.
          </Text>
          <SubduedLink as={ExternalLink} to="https://support.sparkpost.com/docs/user-guide/alerts/">
            Alert Documentation
          </SubduedLink>
        </Stack>
      </Layout.Section>
      <Layout.Section>
        <Box maxWidth="786px">
          <Panel mb="24px">
            <Panel.Section>
              <Form onSubmit={() => {}} id="new-alert-create-form">
                <Stack>
                  <Heading as="h4">Alert Metric Category</Heading>
                  <Box>
                    <RadioCard.Group orientation="horizontal" label="Metric Category">
                      <RadioCard
                        label="Analytics Metrics"
                        id="metrics"
                        defaultChecked={type === 'metrics'}
                        name="type"
                        disabled={isDuplicateAlert}
                        onChange={handleChangeType}
                      >
                        Reporting Metrics consists of all analytics reporting data, including
                        injection, delivery, engagement, and deliverability (if applicable) metrics.
                      </RadioCard>
                      <RadioCard
                        label="Health Score"
                        id="health_score"
                        defaultChecked={type === 'tools'}
                        name="type"
                        onChange={handleChangeType}
                      >
                        Health Score alerts notifies users when the monitored Health Score is
                        outside a defined threshold.
                        <Box padding="12px" />
                      </RadioCard>
                    </RadioCard.Group>
                    <RadioCard.Group orientation="horizontal">
                      <RadioCard
                        label="Blocklistings"
                        id="blocklistings"
                        defaultChecked={type === 'blocklistings'}
                        name="type"
                        onChange={handleChangeType}
                      >
                        Blocklistings will identify and notify of blocklist incidents for defined
                        domain or IP listings.
                      </RadioCard>
                      <RadioCard
                        label="Account Usage"
                        id="sending_limit_alert"
                        defaultChecked={type === 'usage'}
                        name="type"
                        onChange={handleChangeType}
                      >
                        Account Usage includes all allotment and limit metrics related to the
                        account’s sending usage.
                      </RadioCard>
                    </RadioCard.Group>
                  </Box>
                  <Box>
                    <Text as="h5">Subaccount Assignment</Text>
                    <Text>
                      The subaccount assignment defines which subaccount can view the alert. All
                      alerts are visible to the Primary Account. Subaccount assignments cannot be
                      altered.
                    </Text>
                  </Box>
                  <Box>
                    <Radio.Group>
                      <Radio
                        id="primary"
                        label="Assign to Primary Account"
                        name="group"
                        defaultChecked={accountData.assignment === 'primary'}
                        onChange={(e) => handleChangeAccountAssignment(e.target.checked, 'primary')}
                      />
                      <Radio
                        id="subaccount"
                        label="Assign to a single subaccount"
                        name="group"
                        defaultChecked={accountData.assignment === 'subaccount'}
                        onChange={(e) =>
                          handleChangeAccountAssignment(e.target.checked, 'subaccount')
                        }
                      />
                    </Radio.Group>

                    {accountData.assignment === 'subaccount' && (
                      <Box maxWidth="400px" mt="12px">
                        <Typeahead
                          placeholder="e.g. Sparky's Cookie Co."
                          lookaheadRequest={subaccountFilter.query}
                          type={subaccountFilter.label}
                          label={subaccountFilter.label}
                          filterType={subaccountFilter.value}
                          selector={subaccountFilter.selector}
                          itemToString={(item) => (item?.value ? item.value : '')}
                          value={accountData.subaccount.value ? [accountData.subaccount] : []}
                          setFilterValues={handleSubaccountSelection}
                          data-track={true}
                          maxItems={1}
                          error={accountData.errors.subaccount && 'Required'}
                        />
                      </Box>
                    )}
                  </Box>
                </Stack>
              </Form>
            </Panel.Section>
          </Panel>
          <Button variant="primary" onClick={handleGoToSetup}>
            <Text>Continue</Text>
          </Button>
        </Box>
      </Layout.Section>
    </Layout>
  );
}
