import React from 'react';
import { connect } from 'react-redux';
import { Columns, Column, LabelValue, Layout, Page, Panel, Stack } from 'src/components/matchbox';
import { updateUser } from 'src/actions/users';
import { get as getCurrentUser } from 'src/actions/currentUser';
import { confirmPassword } from 'src/actions/auth';
import { Loading } from 'src/components';
import VerifyEmailBanner from 'src/components/verifyEmailBanner/VerifyEmailBanner';
import NameForm from './components/NameForm';
import PasswordForm from './components/PasswordForm';
import TfaManager from './components/TfaManager';
import NewTfaManager from './components/NewTfaManager';
import { AccessControl } from 'src/components/auth';
import ErrorTracker from 'src/helpers/errorTracker';
import { not } from 'src/helpers/conditions';
import { isSso } from 'src/helpers/conditions/user';
import { useAuthentication } from 'src/context/AuthenticationContext';

export function ProfilePage(props) {
  const {
    context: { isAuth0User }
  } = useAuthentication();

  const updateProfile = (values) => {
    const { username } = props.currentUser;
    const data = { first_name: values.firstName, last_name: values.lastName };

    return props.updateUser(username, data).then(
      // update success, re-fetch current user but ignore re-fetch errors
      () =>
        props.getCurrentUser().catch((err) => {
          ErrorTracker.report('silent-ignore-refetch-current-user', err);
        })
    );
  };

  const updatePassword = (values) => {
    const { username } = props.currentUser;
    const { currentPassword, newPassword } = values;

    return props
      .confirmPassword(username, currentPassword)
      .then(() => props.updateUser(username, { password: newPassword }));
  };

  const { isPending } = props;
  const { email, email_verified, username, verifyingEmail } = props.currentUser;

  if (isPending) return <Loading />;

  return (
    <Page title="Profile">
      {email_verified === false && <VerifyEmailBanner verifying={verifyingEmail} />}
      <Stack>
        <Layout>
          <Layout.Section annotated>
            <Layout.SectionTitle as="h2">User Details</Layout.SectionTitle>
          </Layout.Section>
          <Layout.Section>
            <Panel mb="-1px">
              <Panel.Section>
                <Columns>
                  <Column width={1 / 2}>
                    <LabelValue>
                      <LabelValue.Label>Username</LabelValue.Label>
                      <LabelValue.Value>{username}</LabelValue.Value>
                    </LabelValue>
                  </Column>
                  <Column width={1 / 2}>
                    <LabelValue>
                      <LabelValue.Label>Email Address</LabelValue.Label>
                      <LabelValue.Value>{email}</LabelValue.Value>
                    </LabelValue>
                  </Column>
                </Columns>
              </Panel.Section>
              <NameForm onSubmit={updateProfile} />
            </Panel>
          </Layout.Section>
        </Layout>

        <AccessControl condition={not(isSso)}>
          <Layout>
            <Layout.Section annotated>
              <Layout.SectionTitle as="h2">Change Password</Layout.SectionTitle>
            </Layout.Section>
            <Layout.Section>
              <Panel mb="-1px">
                <PasswordForm onSubmit={updatePassword} isAuth0User={isAuth0User} />
              </Panel>
            </Layout.Section>
          </Layout>
        </AccessControl>

        <AccessControl condition={not(isSso)}>
          {!isAuth0User && <TfaManager />}
          {isAuth0User && <NewTfaManager />}
        </AccessControl>
      </Stack>
    </Page>
  );
}

const mapStateToProps = ({ account, currentUser }) => ({
  account,
  currentUser,
  isPending: currentUser.userOptionsPending
});

const mapDispatchToProps = {
  confirmPassword,
  getCurrentUser,
  updateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
