import React from 'react';
import { DomainStatusTag } from 'src/components';
import { resolveReadyFor, resolveStatus } from 'src/helpers/domains';
import { SendingDomain } from 'src/typescript/interfaces/SendingDomain';
import ReadyFor from './ReadyFor';

interface DomainStatusCell {
  domain: SendingDomain;
}

const DomainStatusCell = ({ domain }: DomainStatusCell) => {
  const { status, is_default_bounce_domain, subaccount_id } = domain;
  const domainStatus = resolveStatus(status);

  if (domainStatus !== 'verified') {
    return <DomainStatusTag status={domainStatus} />;
  }

  return (
    <ReadyFor
      {...resolveReadyFor(status)}
      bounceDefault={is_default_bounce_domain}
      subaccount={subaccount_id}
    />
  );
};

export default DomainStatusCell;
