import { useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';

import { useSparkPostQuery } from 'src/hooks';

import { getMonitors } from 'src/helpers/api/blocklist';
import { useSendingDomains } from '../../../hooks/useSendingDomains';
import { useSparkPostMutation, useAlert } from 'src/hooks';
import { addMonitors } from 'src/helpers/api/blocklist';

const initialState = {
  selectedDomains: [],
  domainsOption: 'all-domains'
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_DOMAINS':
      return { ...state, selectedDomains: action.selectedDomains };
    case 'SET_OPTION':
      return { ...state, domainsOption: action.domainsOption };

    default:
      return state;
  }
};

export function useDomainsForm() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { status: monitorsStatus, data: monitorsData } = useSparkPostQuery(getMonitors, {
    refetchOnWindowFocus: false
  });

  const { query, select } = useSendingDomains();
  const { status: sendingDomainsStatus } = query;
  const { verifiedDomains } = select;

  const history = useHistory();
  const { showAlert } = useAlert();

  const availableDomains = useMemo(() => {
    if (!(monitorsStatus === 'success')) {
      return [];
    }

    return verifiedDomains.filter(({ domain }) => {
      return !monitorsData.find(({ resource }) => resource === domain);
    });
  }, [verifiedDomains, monitorsStatus, monitorsData]);

  const monitorsToAdd = useMemo(() => {
    const domains =
      state.domainsOption === 'all-domains' ? availableDomains : state.selectedDomains;
    return domains.map(({ domain, shared_with_subaccounts, subaccount_id }) => {
      if (subaccount_id) {
        return { resource: domain, subaccount: subaccount_id };
      } else {
        return { resource: domain, shared_with_subaccounts };
      }
    });
  }, [state.domainsOption, availableDomains, state.selectedDomains]);

  const mutation = useSparkPostMutation(() => addMonitors(monitorsToAdd), {
    onSuccess: () => {
      showAlert({ type: 'success', message: `Added resources to watchlist` });
      history.push('/signals/blocklist/monitors');
    }
  });

  const loading = !(monitorsStatus === 'success' && sendingDomainsStatus === 'success');

  return { availableDomains, mutation, loading, state, dispatch };
}
