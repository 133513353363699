import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from 'src/components/matchbox';
import { shrinkToFit } from 'src/helpers/string';
import styles from 'styled-components';
const TooltipWrapper = styles.div``;

const Subaccount = ({
  id,
  name,
  all,
  allText = 'Shared with all',
  receiveAll,
  master,
  isDefault,
  shrinkLength
}) => {
  let content = null;
  let defaultContent = null;
  let noNameButId = `Subaccount ${id}`;
  let displayName;
  let title = '';

  if (name && shrinkLength) {
    displayName = shrinkToFit(name, shrinkLength);
  } else {
    displayName = name;
  }

  if (id && shrinkLength) {
    noNameButId = shrinkToFit(noNameButId, shrinkLength);
  }

  // already shrunk, then add id if we have it
  if (displayName && id) {
    content = `${displayName} (${id})`;
  } else if (displayName) {
    content = displayName;
    title = name;
  } else if (id) {
    content = noNameButId;
  }

  // override newName/id if any of these flags are present
  if (all) {
    content = allText;
  } else if (receiveAll) {
    content = 'All';
  } else if (master) {
    content = 'Primary Account';
  }

  if (!content && !defaultContent) {
    return null;
  }

  const isDefaultDisplayText = isDefault ? ' (Default)' : '';
  if (name && !content.includes(name))
    return (
      <Tooltip id="subaccount-top-section-tooltip" content={name} dark as={TooltipWrapper}>
        <span title={title} data-id="subaccount">
          <span>{content}</span>
          <span>{isDefaultDisplayText}</span>
        </span>
      </Tooltip>
    );

  return (
    <span title={title} data-id="subaccount">
      <span>{content}</span>
      <span>{isDefaultDisplayText}</span>
    </span>
  );
};

Subaccount.propTypes = {
  // 'Subaccount ${id}'
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  // 'Shared with all'
  all: PropTypes.bool,

  // 'Primary account'
  master: PropTypes.bool,

  // 'All'
  receiveAll: PropTypes.bool,

  // Makes the tag orange and appends '(Default)'
  isDefault: PropTypes.bool
};

Subaccount.defaultProps = {
  id: null,
  all: false,
  master: false,
  isDefault: false,
  receiveAll: false
};

export default Subaccount;
