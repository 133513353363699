import React from 'react';
import { useDispatch } from 'react-redux';
import { showAlert as _showAlert, clear as _clearAlert } from 'src/actions/globalAlert';

/**
 * @description used to render or clear snackbar UI
 */
export default function useAlert() {
  const dispatch = useDispatch();

  const showAlert = React.useCallback(
    ({ type, message }) => dispatch(_showAlert({ type, message })),
    [dispatch]
  );

  const clearAlert = React.useCallback((id) => dispatch(_clearAlert(id)), [dispatch]);

  return { showAlert, clearAlert };
}
