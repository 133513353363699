import React from 'react';
import debounce from 'lodash/debounce';
import { ApiErrorBanner } from 'src/components';
import { Panel, Stack } from 'src/components/matchbox';
import { TableSkeleton } from 'src/components/loading';
import { Pagination } from 'src/components/collection';
import { useMultiCheckbox } from 'src/components/MultiCheckboxDropdown';
import { useBlocklistIncidents } from './hooks';
import { INCIDENT_STATUS_CHECKBOXES } from './constants';
import { BlocklistIncidentsTable } from './BlocklistIncidentsTable';
import { BlocklistIncidentsFilters } from './BlocklistIncidentsFilters';

export function BlocklistIncidents() {
  const { state, dispatch, tableInstance } = useBlocklistIncidents();
  const multiCheckbox = useMultiCheckbox({
    checkboxes: INCIDENT_STATUS_CHECKBOXES,
    allowSelectAll: false,
    onChange: (values) => {
      return dispatch({ type: 'STATUS_CHANGE', statuses: values });
    }
  });

  const debouncedSearchChange = debounce((value) => {
    return dispatch({ type: 'SEARCH_CHANGE', search: value });
  }, 300);

  return (
    <Stack>
      <Panel mb="0">
        <Panel.Section>
          <BlocklistIncidentsFilters
            onDateChange={(date) => {
              return dispatch({
                type: 'DATE_CHANGE',
                from: date.from,
                to: date.to,
                relativeRange: date.relativeRange
              });
            }}
            onSearchChange={(e) => debouncedSearchChange(e.target.value)}
            onSortByChange={(e) => {
              return dispatch({ type: 'SORT_BY_CHANGE', sortBy: e.currentTarget.value });
            }}
            from={state.from}
            to={state.to}
            relativeRange={state.relativeRange}
            search={state.search}
            statusCheckboxes={multiCheckbox.checkboxes}
            disabled={state.status === 'loading'}
          />
        </Panel.Section>

        {state.status === 'loading' ? (
          <Panel.Section>
            <TableSkeleton numberOfColumns={0} />
          </Panel.Section>
        ) : null}

        {/* Avoiding rendering the table unless the network request is explicitly successful */}
        {state.status === 'success' ? (
          <BlocklistIncidentsTable tableInstance={tableInstance} />
        ) : null}
      </Panel>

      {/* The table filters still render as a date update can trigger a network re-request (in addition to the reload UI here) */}
      {state.status === 'error' ? (
        <ApiErrorBanner
          message="Sorry, we seem to have had some trouble loading your blocklist incidents."
          errorDetails={state.error.message}
          reload={() => dispatch({ type: 'REFETCH_DATA' })}
        />
      ) : null}

      <Pagination
        data={tableInstance.rows.map(({ original }) => original)}
        currentPage={tableInstance.state.pageIndex + 1}
        perPage={tableInstance.state.pageSize}
        saveCsv={true}
        onPageChange={(page) => tableInstance.gotoPage(page)}
        onPerPageChange={(perPage) => tableInstance.setPageSize(perPage)}
      />
    </Stack>
  );
}
