import React, { createContext } from 'react';

const ResourceDetailsContext = createContext();

export function ResourceDetailsContextProvider({ value, children }) {
  value = {
    ...value,
    match: {
      ...value.match,
      params: {
        ...value.match.params,
        id: decodeURIComponent(value.match.params.id)
      }
    }
  };
  return (
    <ResourceDetailsContext.Provider value={value}>{children}</ResourceDetailsContext.Provider>
  );
}

export default ResourceDetailsContext;
