import React from 'react';
import { Button, Modal, Stack, LabelValue } from 'src/components/matchbox';
import { updateInlineSeedsOptions } from 'src/helpers/api/seedList';
import { useAlert, useSparkPostMutation } from 'src/hooks';

export default function EnableAccessModal({
  open,
  onClose,
  onSuccess,
  subaccount: { name, id } = {}
}) {
  const { showAlert } = useAlert();
  const mutation = useSparkPostMutation(
    () => {
      return updateInlineSeedsOptions({ enabled: true, subaccountId: id });
    },
    { onSuccess: handleSuccess }
  );

  function handleSuccess() {
    onClose();
    onSuccess();
    showAlert({ type: 'success', message: `Enabled Automatic Inline Seeding for ${name}(${id})` });
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header showCloseButton>Enable Automatic Inline Seeding</Modal.Header>

      <Modal.Content>
        <Stack>
          <p>Enable Automatic Inline Seeding for this subaccount according to saved settings.</p>
          <LabelValue>
            <LabelValue.Label>Subaccount</LabelValue.Label>
            <LabelValue.Value>
              {name}({id})
            </LabelValue.Value>
          </LabelValue>
        </Stack>
      </Modal.Content>

      <Modal.Footer>
        <Button variant="primary" loading={mutation.status === 'loading'} onClick={mutation.mutate}>
          Enable Seeding
        </Button>

        <Button variant="secondary" disabled={mutation.status === 'loading'} onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
