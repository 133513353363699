import { PRIMARY_SUBACCOUNT } from 'src/constants';
import { getSubaccountHeader } from './headers';
/**
 * @param {object} config
 * @param {number} config.subaccountId - Applicable subaccount ID for this request
 */
export function getInlineSeedsConfig({ subaccountId }) {
  return {
    method: 'GET',
    url: '/v1/inline-seeds/config',
    headers: getSubaccountHeader(subaccountId)
  };
}

/**
 * @description Returns read-only list of seed-eligible campaigns that have recent activity.
 * @param {object} options
 * @param {number} options.subaccountId - relevant subaccount ID
 * @see {@link https://developers.sparkpost.com/api/seed-list/}
 */
export function getSeedList({ subaccountId } = {}) {
  return {
    method: 'GET',
    url: '/v1/seeds',
    headers: subaccountId === PRIMARY_SUBACCOUNT.id ? {} : getSubaccountHeader(subaccountId)
  };
}

/**
 * @param {object} config
 * @param {number} config.subaccountId - Applicable subaccount ID for this request
 * @param {number} config.threshold - Number of messages seen for a campaign before seed sending starts.
 * @param {number} config.duration - Number of minutes after threshold is met over which to spread out seed messages.
 * @param {number} config.reset - Number of hours after threshold is met until seed sending can happen again.
 * @param {Array} config.match - Array of campaign ids or prefixes that are eligible. Strings ending with an asterisk are treated as prefixes (MAX OF 5 ITEMS!).
 * @param {Array} config.exclude - Array of campaign ids or prefixes that are NOT eligible. Strings ending with an asterisk are treated as prefixes (MAX OF 5 ITEMS!).
 */
export function createInlineSeedsConfig({
  subaccountId,
  threshold,
  duration,
  reset,
  match,
  exclude
}) {
  return {
    method: 'POST',
    url: '/v1/inline-seeds/config',
    data: {
      threshold,
      duration,
      reset,
      match,
      exclude
    },
    headers: getSubaccountHeader(subaccountId)
  };
}

/**
 * @param {object} config
 * @param {number} config.subaccountId - Applicable subaccount ID for this request
 * @param {number} config.threshold - Number of messages seen for a campaign before seed sending starts.
 * @param {number} config.duration - Number of minutes after threshold is met over which to spread out seed messages.
 * @param {number} config.reset - Number of hours after threshold is met until seed sending can happen again.
 * @param {Array} config.match - Array of campaign ids or prefixes that are eligible. Strings ending with an asterisk are treated as prefixes (MAX OF 5 ITEMS!).
 * @param {Array} config.exclude - Array of campaign ids or prefixes that are NOT eligible. Strings ending with an asterisk are treated as prefixes (MAX OF 5 ITEMS!).
 */
export function updateInlineSeedsConfig({
  subaccountId,
  threshold,
  duration,
  reset,
  match,
  exclude
}) {
  return {
    method: 'PUT',
    url: '/v1/inline-seeds/config',
    data: {
      threshold,
      duration,
      reset,
      match,
      exclude
    },
    headers: getSubaccountHeader(subaccountId)
  };
}

/**
 * @description Returns read-only list of seed-eligible campaigns that have recent activity.
 * @param {object} options
 * @param {number} config.subaccountId - Applicable subaccount ID for this request
 */
export function getInlineSeedsStatus({ subaccountId } = {}) {
  return {
    url: '/v1/inline-seeds/status',
    method: 'GET',
    headers: getSubaccountHeader(subaccountId)
  };
}

/**
 * @description Returns all inline seeding options for a given subaccount id.  When no subaccount header is passed to the endpoint, it returns a list with the status of all of the subaccounts for a particular account.
 * @param {object} options
 * @param {number} config.subaccountId - Applicable subaccount ID for this request
 * @see {@link https://github.com/SparkPost/sparkpost-admin-api-documentation/pull/528/files#diff-c320c752c28bb740756e51747b84697edb74cebdd80ad68443cc7064c0a8e5d9R724}
 */
export function getInlineSeedsOptions({ subaccountId } = {}) {
  return {
    url: '/v1/inline-seeds/options',
    method: 'GET',
    headers: getSubaccountHeader(subaccountId)
  };
}

/**
 * @description - Make updates to subaccount inline seeding options for the authenticated account.
 * @param {object} options
 * @param {boolean} options.enabled
 * @param {number} options.subaccountId - relevant subaccount ID
 * @see {@link https://github.com/SparkPost/sparkpost-admin-api-documentation/pull/528/files#diff-c320c752c28bb740756e51747b84697edb74cebdd80ad68443cc7064c0a8e5d9R749}
 */
export function updateInlineSeedsOptions({ enabled, subaccountId }) {
  return {
    url: '/v1/inline-seeds/options',
    method: 'PUT',
    headers: getSubaccountHeader(subaccountId),
    data: { enabled }
  };
}
