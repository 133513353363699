import { tokens } from '@sparkpost/design-tokens';

import React from 'react';
import { Box, ScreenReaderOnly, Skeleton, styles } from 'src/components/matchbox';
import styled, { DefaultTheme } from 'styled-components';

const openStyles = ({ theme }: { theme: DefaultTheme }) => `
  background: ${theme.colors.blue[700]};
  color: ${theme.colors.white};
`;

const closedStyles = ({ theme }: { theme: DefaultTheme }) => `
  background: ${theme.colors.white};
  color: ${theme.colors.blue[700]};
`;

const DesktopAccountButton = styled.button<{ isOpen: boolean }>`
  ${styles.buttonReset}
  ${styles.focusOutline({ radius: tokens.borderRadius_pill })}

  text-transform: uppercase;
  height: ${({ theme }) => theme.sizes[650]};
  width: ${({ theme }) => theme.sizes[650]};
  font-size: ${({ theme }) => theme.fontSizes[500]};
  line-height: ${({ theme }) => theme.sizes[100]} !important;
  border: 1px solid ${({ theme }) => theme.colors.blue[700]};
  border-radius: 50%;
  padding: ${({ theme }) => theme.space[200]};
  cursor: pointer;
  transition: background ${({ theme }) => theme.motion.duration.fast},
    color ${({ theme }) => theme.motion.duration.fast};

  ${({ isOpen }) => (isOpen ? openStyles : closedStyles)}

  &:hover {
    ${openStyles}
  }
`;

type AccountButtonProps = {
  currentUser: {
    email: string;
    first_name: string;
    loading: boolean;
  };
  isOpen: boolean;
  onClick: () => void;
};

function AccountButton(props: AccountButtonProps) {
  const { currentUser, onClick, isOpen } = props;
  const { loading: isCurrentUserLoading, email, first_name } = currentUser;

  const firstChar = (first_name || email)?.charAt(0);

  if (isCurrentUserLoading) {
    return <Skeleton.Box height="650" width="900" borderRadius="pill" />;
  }

  return (
    <DesktopAccountButton
      aria-controls="desktop-navigation-account-popover-contents"
      onClick={onClick}
      isOpen={isOpen}
      data-id="desktop-navigation-account-popover-trigger"
    >
      <Box display="inline-flex" alignItems="center" aria-hidden="true">
        <Box>{firstChar}</Box>
      </Box>
      <ScreenReaderOnly>Account Menu</ScreenReaderOnly>
    </DesktopAccountButton>
  );
}

export default AccountButton;
