export const HealthScoreBreakdown = {
  Campaign: 'campaign_id',
  IPPool: 'ip_pool',
  MailboxProvider: 'mb_provider',
  SendingDomain: 'sending_domain'
};

export const HEALTH_SCORE_BREAKDOWN_CONFIG = {
  [HealthScoreBreakdown.Campaign]: {
    label: 'Recipient Domain'
  },
  [HealthScoreBreakdown.IPPool]: {
    label: 'IP Pool'
  },
  [HealthScoreBreakdown.MailboxProvider]: {
    label: 'Mailbox Provider'
  },
  [HealthScoreBreakdown.SendingDomain]: {
    label: 'Sending Domain'
  }
};
