export const CONFIG = {
  THRESHOLD: {
    MIN: 1000,
    MAX: 1000000,
    DEFAULT: 10000
  },
  DURATION: {
    MIN: 20,
    MAX: 720,
    DEFAULT: 30
  },
  RESET: {
    MIN: 22,
    MAX: 168,
    DEFAULT: 24
  },
  CAMPAIGN_IDS: {
    MAX: 5
  }
};

export const DEFAULT_FORM_DATA = {
  mutationType: 'create',
  campaignsToSeed: 'include',
  campaignIdsInput: '',
  campaignIds: [],
  threshold: CONFIG.THRESHOLD.DEFAULT,
  duration: CONFIG.DURATION.DEFAULT,
  reset: CONFIG.RESET.DEFAULT
};
