import { useState, useMemo } from 'react';
import { differenceInHours } from 'date-fns';
import _ from 'lodash';

import { useIndustryBenchmark } from 'src/hooks/analyticsReport';
import { getMetricsFromKeys, transformData } from 'src/helpers/metrics';
import { getTimeSeries } from 'src/helpers/api/metrics';
import { useSparkPostQueries } from 'src/hooks';
import { getUniqueUnits, toQueryFromOptions } from '@sparkpost/report-builder/helpers/metrics';

import { INDUSTRY_BENCHMARK_INDUSTRIES, INDUSTRY_BENCHMARK_MAILBOX_PROVIDERS } from 'src/constants';
import METRICS_UNIT_CONFIG from 'src/appConfig/metrics-units';

export function useAnalyticsReportCharts(reportOptions, defaultMetrics) {
  const { comparisons, metrics, ...otherOptions } = reportOptions;

  const [activeChart, setActiveChart] = useState(null);

  const hasComparisons = comparisons.length > 0;

  const formattedMetrics = useMemo(() => {
    return defaultMetrics || getMetricsFromKeys(metrics, true);
  }, [metrics, defaultMetrics]);

  const preparedRequests = hasComparisons
    ? comparisons.map((compareFilter) => {
        const { type } = compareFilter;
        const comparedFilters = [
          ...reportOptions.filters,
          { AND: { [type]: { eq: [compareFilter] } } }
        ];

        const formattedOptions = toQueryFromOptions({
          ...otherOptions,
          filters: comparedFilters,
          metrics: formattedMetrics
        });

        return () => getTimeSeries(formattedOptions);
      })
    : [() => getTimeSeries(toQueryFromOptions({ ...otherOptions, metrics: formattedMetrics }))]; //Default singular version of the chart

  const queries = useSparkPostQueries(preparedRequests, {
    enabled: Boolean(reportOptions.isReady),
    select: (data) => {
      return transformData(data, formattedMetrics);
    }
  });

  // Industry benchmark data
  const {
    data: industryBenchmarkData,
    industryCategory,
    mailboxProvider
  } = useIndustryBenchmark(reportOptions);

  //Contains meta information on charts to render (y axis info, domain, etc)
  const flattenedTimeSeriesData = _.flatten(queries.map(({ data = [] }) => data));
  const charts = getUniqueUnits(formattedMetrics)
    .map((unit) => ({
      metrics: formattedMetrics.filter((metric) => metric.unit === unit),
      ...METRICS_UNIT_CONFIG[unit],
      unit
    }))
    .map((chart) => {
      let max = 0;
      let min = Infinity;
      flattenedTimeSeriesData.forEach((dataPoint) => {
        chart.metrics.map(({ key }) => {
          if (dataPoint[key] > max) {
            max = dataPoint[key];
          }
          if (dataPoint[key] < min) {
            min = dataPoint[key];
          }
        });
      });

      return { ...chart, max, min };
    });

  //Joins mailbox provider data with chart data

  const combinedData = queries.map((query) => {
    if (!industryBenchmarkData) {
      return query;
    }

    const industryLabel = INDUSTRY_BENCHMARK_INDUSTRIES.find(
      ({ value }) => value === industryCategory
    )?.label;

    const mailboxProviderLabel = INDUSTRY_BENCHMARK_MAILBOX_PROVIDERS.find(
      ({ value }) => value === mailboxProvider
    )?.label;

    return {
      ...query,
      data: (query.data || []).map((data) => {
        const industryRate = industryBenchmarkData.find(({ ts: industryTs }) => {
          const diffInHours = differenceInHours(new Date(data.ts), new Date(industryTs));
          return diffInHours < 24 && diffInHours >= 0;
        });

        return {
          ...data,
          industry_rate: industryRate
            ? [industryRate.q25, industryRate.q75, industryLabel, mailboxProviderLabel]
            : [undefined, undefined, undefined, undefined] //Rather than just undefined, we need to pass in this so it doesn't render 0 value
        };
      })
    };
  });

  return { charts, queries: combinedData, activeChart, setActiveChart };
}
