import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { KeyboardArrowDown, KeyboardArrowUp } from '@sparkpost/matchbox-icons';
import { setSubaccount } from 'src/actions/accessControl';
import { ActionList, Box, Button, Popover, Text } from 'src/components/matchbox';
import styled from 'styled-components';
import styles from './AccountPopover.module.scss';

import { CurrentUser } from 'src/typescript/interfaces/CurrentUser';

const CustomActionList = styled(ActionList)`
  max-height: 340px;
  overflow-y: auto;
  overflow-x: none;
`;

type UserSubaccount = {
  access_level: string;
  status: string;
  subaccount_id: number;
  subaccount_name: string;
};

function SubaccountPopover() {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const onFilterChange = useCallback(
    (e: $TODOFIXME) => {
      setFilter(e.currentTarget.value);
    },
    [setFilter]
  );
  const history = useHistory();

  useEffect(() => {
    if (!isOpen) {
      setFilter('');
    }
  }, [isOpen]);
  const togglePopover = () => setIsOpen(!isOpen);
  const dispatch = useDispatch();

  const { subaccounts = [], selectedSubaccount: subaccountId } = useSelector(
    (state: { currentUser: CurrentUser }) => state.currentUser
  ) as { selectedSubaccount: number; subaccounts: UserSubaccount[] };

  const filteredSubaccounts = subaccounts
    ?.filter(({ status }) => status !== 'terminated')
    .filter(({ subaccount_name }) => subaccount_name.toLowerCase().includes(filter.toLowerCase()));

  const selectedSubaccount = subaccounts?.find(
    ({ subaccount_id }) => subaccount_id === subaccountId
  );

  const onSelectSubaccount = (subaccount: UserSubaccount) => {
    const { subaccount_id, access_level } = subaccount;
    dispatch(setSubaccount(subaccount_id, access_level));
    setIsOpen(false);
    history.push('/landing');
  };

  if (!subaccounts || !subaccounts.length || !selectedSubaccount) {
    return null;
  }

  return (
    <div className={styles.AccountPopover} data-id="desktop-navigation-account-popover">
      <Popover
        as="div"
        id="desktop-navigation-account-popover-contents"
        trigger={
          <Button
            variant="text"
            color={isOpen ? 'blue' : 'gray'}
            useMatchboxVariant={true}
            mr="200"
            id="subaccount-popover-trigger"
            onClick={togglePopover}
          >
            {selectedSubaccount.subaccount_name}
            <Button.Icon as={isOpen ? KeyboardArrowUp : KeyboardArrowDown} />
          </Button>
        }
        left
        open={isOpen}
        onClose={togglePopover}
      >
        <CustomActionList data-id="subaccountList">
          <Box p="300" borderBottom="400">
            <Text fontWeight="600" fontSize="300">
              Switch Subaccount
            </Text>
          </Box>
          {filteredSubaccounts.map((item) => {
            return (
              <ActionList.Action
                key={`action-item-${item.subaccount_id}`}
                onClick={() => onSelectSubaccount(item)}
                highlighted={selectedSubaccount?.subaccount_id === item.subaccount_id}
              >
                {item.subaccount_name}
              </ActionList.Action>
            );
          })}
        </CustomActionList>
      </Popover>
    </div>
  );
}

export default SubaccountPopover;
