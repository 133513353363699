const stagingConfig = {
  rudderstack: {
    enabled: true,
    publicKey: '2A59r5cgf9ZJnZBtj6kggLgmQKM'
  },
  sentry: {
    dsn: 'https://b0d90901e16f413fa89be4900da74be4@o113111.ingest.sentry.io/4504046126235648',
    publicKey: 'b0d90901e16f413fa89be4900da74be4'
  }
};

module.exports = stagingConfig;
