import { ChevronLeft } from '@sparkpost/matchbox-icons';
import propTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { ExternalLink, PageLink } from 'src/components/links';
import { Banner, ScreenReaderOnly } from 'src/components/matchbox';
import SparkPost from 'src/components/sparkPost/SparkPost';
import { TranslatableText } from '../text';
// eslint-disable-next-line local/no-css-module-import
import styles from './FullPage.module.scss';

const ITEM_NAME = 'showTaxiBannerEditTemplate';

const TaxiBanner = () => {
  const [showTaxiBanner, setShowTaxiBanner] = useState(false);

  useEffect(() => {
    const itemName = window.localStorage.getItem(ITEM_NAME);
    itemName !== null ? setShowTaxiBanner(JSON.parse(itemName)) : setShowTaxiBanner(true);
  }, []);

  const handleTaxiBannerDismiss = useCallback(() => {
    window.localStorage.setItem(ITEM_NAME, 'false');
    setShowTaxiBanner(false);
  }, []);

  if (showTaxiBanner)
    return (
      <Banner status="promo" size="small" onDismiss={handleTaxiBannerDismiss} data-id="taxiBanner">
        <TranslatableText>
          Build modular and reusable templates by creating a Email Design System in Taxi for Email!{' '}
        </TranslatableText>
        <TranslatableText>
          <ExternalLink
            showIcon={false}
            to="https://taxiforemail.com/demo/"
            className={styles.demoLink}
          >
            Request a Demo
          </ExternalLink>
          .
        </TranslatableText>
      </Banner>
    );
  return null;
};

/**
 * @description uses the entire viewport
 */
const FullPage = ({ breadcrumbRedirectsTo, children, primaryArea, title }: $TODOFIXME) => {
  return (
    <>
      <div className={styles.FullPageNav}>
        <PageLink className={styles.FullPageBreadcrumb} to={breadcrumbRedirectsTo}>
          <ChevronLeft size={28} className={styles.FullPageChevron} />
          <SparkPost.Icon className="" height={28} width={28} />
          <ScreenReaderOnly>Back</ScreenReaderOnly>
        </PageLink>

        <div className={styles.FullPageHeaderArea}>
          <h1 className={styles.FullPageTitle}>{title}</h1>

          <div>{primaryArea}</div>
        </div>
      </div>

      <div className={styles.FullPageContents}>
        <TaxiBanner />
        {children}
      </div>
    </>
  );
};

FullPage.propTypes = {
  breadcrumbRedirectsTo: propTypes.string.isRequired,
  children: propTypes.node,
  primaryArea: propTypes.node,
  title: propTypes.node.isRequired
};

export default FullPage;
