import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { PageLink } from 'src/components/links';
import { Page } from 'src/components/matchbox';

import { useRouteMatch } from 'react-router-dom';
import { AnalyticsReportContextProvider } from 'src/pages/analyticsReport/context/AnalyticsReportContext';
import SetupSelector from './Setup/SetupSelector';
import TypeSelection from './TypeSelection';
import { AlertFormContextProvider } from './useAlertForm';

const backBreadcrumb = { content: 'Alerts', to: '/alerts', as: PageLink };

export default function NewCreatePage() {
  const isDuplicatingAlert = useRouteMatch('/alerts/duplicate');
  const isEditingAlert = useRouteMatch('/alerts/edit/:alertId');

  const [shouldShowSetupForm, setShouldShowSetupForm] = useState(
    isDuplicatingAlert || isEditingAlert
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [shouldShowSetupForm]);

  const goToTypeSelection = useCallback(() => {
    setShouldShowSetupForm(false);
  }, []);

  const goToSetup = useCallback(() => {
    setShouldShowSetupForm(true);
  }, []);

  const pageTitle = useMemo(() => {
    if (isDuplicatingAlert) {
      return 'Duplicate Alert';
    }

    if (isEditingAlert) {
      return 'Edit Alert';
    }

    return 'Create Alert';
  }, [isDuplicatingAlert, isEditingAlert]);

  return (
    <Page title={pageTitle} breadcrumbAction={backBreadcrumb}>
      <AnalyticsReportContextProvider>
        <AlertFormContextProvider>
          {shouldShowSetupForm ? (
            <SetupSelector goToTypeSelection={goToTypeSelection} />
          ) : (
            <TypeSelection goToSetup={goToSetup} />
          )}
        </AlertFormContextProvider>
      </AnalyticsReportContextProvider>
    </Page>
  );
}
