//REACT & REDUX
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';
import { defaultStore } from 'src';
import { callGetFirstStateForCountry } from 'src/selectors/accountBillingForms';
//COMPONENTS
import { SelectWrapper, TextFieldWrapper } from 'src/components';
import { Grid, Stack } from 'src/components/matchbox';
import { TranslatableText } from '../text';
//HELPERS
import _ from 'lodash';
import { getZipLabel } from 'src/helpers/billing';
import { required } from 'src/helpers/validation';

//TYPES
import { CountriesProps } from './Billing.types';

type BillingAddressFormProps = {
  change: (formName: string, state: string, firstState: string | null) => void;
  countries: CountriesProps;
  countryValue: string;
  disabled: boolean;
  firstName: string;
  firstState: string;
  formName: string;
  lastName: string;
};

type BillingAddressFormState = { showName: boolean };

/**
 * This component will register the following redux-form fields
 * billingAddress.firstName
 * billingAddress.lastName
 * billingAddress.country
 * billingAddress.state (if country US | CA)
 * billingAddress.zip
 */
export class BillingAddressForm extends Component<
  BillingAddressFormProps,
  BillingAddressFormState
> {
  state = {
    showName: true
  };

  componentDidMount() {
    const { firstName, lastName } = this.props;

    // Hide name fields if we already have them
    // Their values are still in the redux-form store although the fields are hidden
    if (firstName && lastName) {
      this.setState({ showName: false });
    }
  }

  componentDidUpdate({ countryValue: prevCountry }: { countryValue: string }) {
    const { countryValue, change, formName, firstState } = this.props;

    // Handles billingAddress.state field mutation when country changes
    if (prevCountry !== countryValue) {
      if (countryValue === 'US' || countryValue === 'CA') {
        // Sets first state value
        change(formName, 'billingAddress.state', firstState);
      } else {
        // Removes state value from store
        change(formName, 'billingAddress.state', null);
      }
    }
  }

  render(): JSX.Element {
    const { countries = [], countryValue, disabled } = this.props;

    const stateOrProvince =
      countries.length && (countryValue === 'US' || countryValue === 'CA') ? (
        <Grid.Column xs={6}>
          <Field
            label={countryValue === 'US' ? 'State' : 'Province'}
            name="billingAddress.state"
            placeholder={`Select a ${countryValue === 'US' ? 'State' : 'Province'}`}
            component={SelectWrapper}
            options={_.find(countries, { value: countryValue })?.states}
            validate={required}
            disabled={disabled}
            data-sensitive={true}
          />
        </Grid.Column>
      ) : null;

    const nameFields = this.state.showName ? (
      <Grid>
        <Grid.Column xs={6}>
          <Field
            label="First Name"
            name="billingAddress.firstName"
            component={TextFieldWrapper}
            validate={required}
            disabled={disabled}
            data-sensitive={true}
          />
        </Grid.Column>
        <Grid.Column xs={6}>
          <Field
            label="Last Name"
            name="billingAddress.lastName"
            component={TextFieldWrapper}
            validate={required}
            disabled={disabled}
            data-sensitive={true}
          />
        </Grid.Column>
      </Grid>
    ) : null;

    return (
      <div>
        <Stack>
          <p>Billing Address</p>
          <TranslatableText>{nameFields}</TranslatableText>
          <Field
            label="Country"
            name="billingAddress.country"
            placeholder="Select a country"
            component={SelectWrapper}
            options={countries}
            validate={required}
            disabled={disabled}
            data-sensitive={true}
          />
          <Grid>
            <TranslatableText>{stateOrProvince}</TranslatableText>
            <Grid.Column xs={6}>
              <Field
                label={getZipLabel(countryValue)}
                name="billingAddress.zip"
                component={TextFieldWrapper}
                validate={required}
                disabled={disabled}
                data-sensitive={true}
              />
            </Grid.Column>
          </Grid>
        </Stack>
      </div>
    );
  }
}

const mapStateToProps = (
  state: typeof defaultStore.getState,
  { formName }: { formName: string }
): { countryValue: string; firstName: string; firstState: string; lastName: string } => {
  // Get country value from state
  const selector = formValueSelector(formName);
  const countryValue: string = selector(state, 'billingAddress.country');
  const firstState: string = callGetFirstStateForCountry({ state, countryValue });

  return {
    countryValue,
    firstName: selector(state, 'billingAddress.firstName'),
    lastName: selector(state, 'billingAddress.lastName'),
    firstState: firstState
  };
};
export default connect(mapStateToProps, { change })(BillingAddressForm);
