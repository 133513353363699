import _ from 'lodash';
import React from 'react';
import { Field } from 'redux-form';
import { Box, Stack, Tooltip } from 'src/components/matchbox';
import { CheckboxWrapper } from 'src/components/reduxFormWrappers';
import styled from 'styled-components';

type GrantType = {
  description: string;
  key: string;
  label: string;
};

type GrantCheckBoxesType = {
  disabled: boolean;
  grants: GrantType[];
};

const GrantsGrid = styled.div`
  display: grid;
  grid-gap: ${(props) => (props.theme.space ? props.theme.space['500'] : 0)};
  row-gap: 0;
  /* Auto generates columns based on the width of the child DOM elements */
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

const GrantsCheckboxes = ({ grants, disabled }: GrantCheckBoxesType): JSX.Element => {
  const grantFields = _.map(grants, (grant) => (
    <Box display="inline-block" marginRight="-4px" key={grant.key}>
      <Tooltip dark id="" content={grant.description}>
        <Field
          name={`grants[${grant.key}]`}
          label={grant.label}
          component={CheckboxWrapper}
          type="checkbox"
          disabled={disabled}
          data-track={true}
        />
      </Tooltip>
    </Box>
  ));

  const grantFieldChunks = _.chunk(grantFields, Math.ceil(_.size(grants) / 3));

  const grantCols = _.map(grantFieldChunks, (grantFields, index) => (
    <div key={index}>
      <Stack space="100">{grantFields}</Stack>
    </div>
  ));

  return <GrantsGrid>{grantCols}</GrantsGrid>;
};

export default GrantsCheckboxes;
