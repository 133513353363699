/* eslint-disable local/restrict-translatable-text */
/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';
import { RadioGroup, SubaccountTypeaheadWrapper, TextFieldWrapper } from 'src/components';
import { Box, Stack, Tag, Tooltip } from 'src/components/matchbox';
import { TranslatableText } from 'src/components/text';
import { isAccountUiOptionSet } from 'src/helpers/conditions/account';
import { required } from 'src/helpers/validation';

/**
 * Component produces the follow redux form fields
 * If newWebhook
 * - assignTo 'master' | 'all' | 'subaccount'
 * - subaccount if assignTo === 'subaccount'
 *
 * If !newWebhook
 * - subaccount TextField | typeahead (disabled)
 */

const TAG_CHAR_LIMIT = 20;
const EXCEPTION_SUBACCOUNTS_LIMIT = 10;
export class SubaccountSection extends Component {
  state = {
    excludedSubaccounts: []
  };

  componentDidMount() {
    const { subaccounts, exceptionSubaccounts } = this.props;

    if (exceptionSubaccounts) {
      const excludedSubaccounts = subaccounts.filter((subaccount) =>
        exceptionSubaccounts.includes(String(subaccount.id))
      );

      this.setState({ excludedSubaccounts });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { assignTo, formName, change } = this.props;

    // Clear subaccount value if switching away from subaccount
    if (assignTo !== 'subaccount' && prevProps.assignTo === 'subaccount') {
      change(formName, 'subaccount', null);
    }

    if (prevState.excludedSubaccounts.length !== this.state.excludedSubaccounts.length) {
      this.state.excludedSubaccounts.forEach((excludedSubaccount) => {
        change(formName, `exception_subaccounts.${excludedSubaccount.id}`, true);
      });
    }
  }

  handleAddExcludedSubaccount = (subaccount) => {
    if (subaccount) {
      const { formName, change } = this.props;

      const subaccountAlreadyAdded = this.state.excludedSubaccounts.find(
        (excludedSubaccount) => excludedSubaccount.id === subaccount.id
      );

      if (subaccountAlreadyAdded) return;

      change(formName, `exception_subaccounts.${subaccount.id}`, true);

      return this.setState((prevState) => ({
        excludedSubaccounts: [...prevState.excludedSubaccounts, subaccount]
      }));
    }
  };

  handleRemoveExcludedSubaccount = (subaccountId) => {
    const { formName, change } = this.props;

    change(formName, `exception_subaccounts.${subaccountId}`, false);

    this.setState((prevState) => ({
      excludedSubaccounts: prevState.excludedSubaccounts.filter(
        (excludedSubaccount) => excludedSubaccount.id !== subaccountId
      )
    }));
  };

  renderCreate() {
    const { assignTo, disabled, isExcludedSubaccountsEnabled } = this.props;

    const createOptions = [
      { label: 'Events from Primary and all Subaccounts', value: 'all', disabled },
      { label: 'Events from Primary Account only', value: 'master', disabled },
      { label: 'Events from a Single Subaccount', value: 'subaccount', disabled }
    ];

    const typeahead =
      assignTo === 'subaccount' ? (
        <Box ml="500">
          <Field
            name="subaccount"
            component={SubaccountTypeaheadWrapper}
            validate={required}
            data-track={true}
            helpText="This assignment is permanent."
          />
        </Box>
      ) : null;

    return (
      <Stack space="300">
        <Field
          component={RadioGroup}
          name="assignTo"
          label="Event Source"
          options={createOptions}
          data-track={true}
          display="block"
        />
        {typeahead}
        {isExcludedSubaccountsEnabled && assignTo === 'all' && (
          <Box mt="300">
            <Field
              name="excluded-subaccount-field"
              component={SubaccountTypeaheadWrapper}
              data-track={true}
              placeholder="e.g. samplesubaccount"
              label="Excluded Subaccounts"
              helpText="Exclude up to 10 subaccounts from being a source for your webhook."
              onChange={this.handleAddExcludedSubaccount}
              unpersist
              disabled={this.state.excludedSubaccounts.length >= EXCEPTION_SUBACCOUNTS_LIMIT}
            />
            <Box>
              {this.state.excludedSubaccounts.map(({ id: subaccountId, name }, index) => (
                <Tooltip
                  key={`tag-${subaccountId}-${index}`}
                  id={`tag-${subaccountId}-${index}`}
                  content={`${name} (${subaccountId})`}
                >
                  <Tag
                    onRemove={() => this.handleRemoveExcludedSubaccount(subaccountId)}
                    mr="100"
                    mb="100"
                  >
                    <TranslatableText>
                      {`${name} (${subaccountId})`.length > TAG_CHAR_LIMIT
                        ? `${name.substr(
                            0,
                            20 - 6 - subaccountId.toString().length
                          )}... (${subaccountId})`
                        : `${name} (${subaccountId})`}
                    </TranslatableText>
                  </Tag>
                </Tooltip>
              ))}
            </Box>
          </Box>
        )}
      </Stack>
    );
  }

  renderEdit() {
    const { subaccount, isExcludedSubaccountsEnabled, assignTo } = this.props;
    let component = SubaccountTypeaheadWrapper;

    // On 'primary only' or 'primary and all' webhooks
    if (typeof subaccount === 'string') {
      component = TextFieldWrapper;
    }

    return (
      <>
        <Field
          component={component}
          name="subaccount"
          label="Event Source"
          helpText="This assignment is permanent."
          disabled
          data-track={true}
        />
        {isExcludedSubaccountsEnabled && assignTo === 'all' && (
          <Box mt="600">
            <Field
              name="excluded-subaccount-field"
              component={SubaccountTypeaheadWrapper}
              data-track={true}
              placeholder="e.g. samplesubaccount"
              label="Excluded Subaccounts"
              helpText="Exclude up to 10 subaccounts from being a source for your webhook."
              onChange={this.handleAddExcludedSubaccount}
              unpersist
              disabled={this.state.excludedSubaccounts.length >= 10}
            />
            <Box>
              {this.state.excludedSubaccounts.map(({ id: subaccountId, name }, index) => (
                <Tooltip
                  key={`tag-${subaccountId}-${index}`}
                  id={`tag-${subaccountId}-${index}`}
                  content={`${name} (${subaccountId})`}
                >
                  <Tag
                    onRemove={() => this.handleRemoveExcludedSubaccount(subaccountId)}
                    mr="100"
                    mb="100"
                  >
                    <TranslatableText>
                      {`${name} (${subaccountId})`.length > TAG_CHAR_LIMIT
                        ? `${name.substr(
                            0,
                            20 - 6 - subaccountId.toString().length
                          )}... (${subaccountId})`
                        : `${name} (${subaccountId})`}
                    </TranslatableText>
                  </Tag>
                </Tooltip>
              ))}
            </Box>
          </Box>
        )}
      </>
    );
  }

  render() {
    return this.props.newWebhook ? this.renderCreate() : this.renderEdit();
  }
}

SubaccountSection.propTypes = {
  newWebhook: PropTypes.bool,
  assignTo: PropTypes.oneOf(['master', 'all', 'subaccount']),
  change: PropTypes.func
};

const mapStateToProps = (state, props) => {
  const selector = formValueSelector(props.formName);
  return {
    assignTo: selector(state, 'assignTo'),
    subaccount: selector(state, 'subaccount'),
    isExcludedSubaccountsEnabled: isAccountUiOptionSet('excluded_subaccounts_enabled')(state)
  };
};

export default connect(mapStateToProps, { change })(SubaccountSection);
