import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import Divider from 'src/components/divider';
import { Box, Checkbox, Label, Popover, ScreenReaderOnly } from 'src/components/matchbox';
import { FIELD_MAX_WIDTH } from 'src/constants';
import { AlignedButtonIcon, AlignedTextButton, Chevron, StatusPopoverContent } from './styles';

const reducer = (state, action) => {
  switch (action.type) {
    case 'UNCHECK_ALL':
      return {
        ...state,
        checkboxes: state.checkboxes.map((checkbox) => ({
          ...checkbox,
          isChecked: false
        }))
      };

    case 'TOGGLE_ALL':
      const selectAllCheckboxState = state.checkboxes[0]?.isChecked; //Should be the first to even get this option
      const newCheckboxes = state.checkboxes.map((checkbox) => ({
        ...checkbox,
        isChecked: !selectAllCheckboxState
      }));
      return {
        ...state,
        checkboxes: newCheckboxes
      };

    case 'TOGGLE': {
      const selectAllCheckbox = state.checkboxes.filter(({ name }) => name === 'selectAll');
      const mainCheckboxes = state.checkboxes.filter(({ name }) => name !== 'selectAll');
      const targetCheckboxIndex = mainCheckboxes.findIndex(({ name }) => name === action.name);
      mainCheckboxes[targetCheckboxIndex].isChecked = !Boolean(
        mainCheckboxes[targetCheckboxIndex].isChecked
      );

      if (Boolean(selectAllCheckbox.length)) {
        const allChecked = mainCheckboxes.every(({ isChecked }) => isChecked);
        selectAllCheckbox[0].isChecked = allChecked;
      }
      return {
        ...state,
        checkboxes: [...selectAllCheckbox, ...mainCheckboxes]
      };
    }
    default:
      throw new Error('This is not a valid action for this reducer');
  }
};

/**
 * @name useMultiCheckbox
 * @description Attaches selectAll and click behavior for the checkboxes.
 * @param {Object} options
 * @param {Array<Object>} options.checkboxes Array of checkboxes. Format is array of objects as { label: 'Checkbox Label', name: 'checkboxName' }
 * @param {Boolean} options.allowSelectAll Attaches a checkbox at the top for selecting all checkboxes.
 * @param {Boolean} options.allowEmpty Turning off allowEmpty forces if all checkboxes are empty, all values are returned instead of an empty array
 * @param {function} options.onChange Change handler invoked when `values` are updated
 */
export function useMultiCheckbox({
  checkboxes,
  allowSelectAll = true,
  allowEmpty = true,
  onChange
}) {
  // TODO: Allow for a more calculated initial state for checkboxes instead of having empty as the default.
  const [state, dispatch] = useReducer(reducer, {
    checkboxes: [
      ...(allowSelectAll ? [{ name: 'selectAll', label: 'Select All', isChecked: false }] : []),
      ...checkboxes.map((checkbox) => ({ ...checkbox, isChecked: false }))
    ]
  });

  const handleChange = useCallback(
    (e) => {
      if (e.target.name === 'selectAll') {
        return dispatch({ type: 'TOGGLE_ALL' });
      }

      return dispatch({ type: 'TOGGLE', name: e.target.name });
    },
    [dispatch]
  );

  const mappedCheckboxes = useMemo(
    () => state.checkboxes.map((checkbox) => ({ ...checkbox, onChange: handleChange })),
    [state.checkboxes, handleChange]
  );

  const mainCheckboxes = useMemo(
    () => state.checkboxes.filter(({ name }) => name !== 'selectAll'),
    [state.checkboxes]
  );

  const checkedCheckboxes = useMemo(
    () => mainCheckboxes.filter(({ isChecked }) => isChecked),
    [mainCheckboxes]
  );

  const targetCheckboxes = useMemo(() => {
    const checkboxes =
      !allowEmpty && checkedCheckboxes.length === 0 ? mainCheckboxes : checkedCheckboxes;

    return checkboxes.map(({ name }) => name);
  }, [checkedCheckboxes, mainCheckboxes, allowEmpty]);
  const resetCheckboxes = useCallback(() => dispatch({ type: 'UNCHECK_ALL' }), [dispatch]);

  // When checkbox values update, invoke the passed-in change handler
  // This is particularly useful when a MultiCheckbox state change needs to dispatch
  // an action to a state reducer.
  useEffect(() => {
    if (onChange) return onChange(targetCheckboxes);

    // To avoid unnecessary re-renders, only wait for targetCheckboxes to change
    // eslint-disable-next-line
  }, [targetCheckboxes]);

  return {
    checkboxes: mappedCheckboxes,
    values: targetCheckboxes,
    resetCheckboxes
  };
}

function MultiCheckboxDropdown({
  checkboxes,
  disabled,
  label = 'Options',
  id = 'multi-select-dropdown',
  allowEmpty = true,
  checkboxComponent,
  hideLabel
}) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const CheckboxComponent = checkboxComponent ? checkboxComponent : Checkbox;

  const checkedCheckboxes = checkboxes.filter((checkbox) => checkbox.isChecked);
  const hasCheckedCheckboxes = Boolean(checkedCheckboxes?.length > 0);
  const allCheckboxesChecked = Boolean(checkedCheckboxes?.length === checkboxes.length);

  const checkboxLabels = checkedCheckboxes
    .filter(({ name }) => name !== 'selectAll')
    .map(({ label }) => label)
    .join(', ');
  const selectAllCheckbox = checkboxes.find(({ name }) => name === 'selectAll');

  return (
    <Box maxWidth={FIELD_MAX_WIDTH}>
      {/* This label is intentionally hidden from screen readers - the button's content serves the purpose of labeling for those users */}
      {!hideLabel && (
        <div aria-hidden="true">
          <Label label={label} />
        </div>
      )}

      <Popover
        id={id}
        left
        as="div"
        width="100%"
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        trigger={
          <AlignedTextButton
            outline
            fullWidth
            variant="monochrome"
            aria-expanded={isPopoverOpen}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            disabled={disabled}
          >
            <StatusPopoverContent aria-hidden="true" data-id="multi-checkbox-button-content">
              {/* Render the checked filters that visually replace the button's content */}
              {!hasCheckedCheckboxes && allowEmpty && 'None'}
              {(allCheckboxesChecked || (!allowEmpty && !hasCheckedCheckboxes)) && 'All'}
              {hasCheckedCheckboxes && !allCheckboxesChecked && checkboxLabels}
            </StatusPopoverContent>

            <AlignedButtonIcon as={Chevron} size={25} />

            <ScreenReaderOnly>{label}</ScreenReaderOnly>
          </AlignedTextButton>
        }
      >
        {selectAllCheckbox && (
          <>
            <Box padding="300">
              <CheckboxComponent
                label="Select All"
                id="select-all"
                name="selectAll"
                onChange={selectAllCheckbox.onChange}
                checked={selectAllCheckbox.isChecked}
              />
            </Box>
            <Divider />
          </>
        )}

        <Box padding="300">
          <Checkbox.Group label="Filter Options" labelHidden>
            {checkboxes
              .filter((checkbox) => checkbox.name !== 'selectAll') //Will remove this regardless
              .map((checkbox, index) => (
                <CheckboxComponent
                  key={`${checkbox.name}-${index}`}
                  label={checkbox.label}
                  id={checkbox.name}
                  name={checkbox.name}
                  onChange={checkbox.onChange}
                  disabled={checkbox.disabled}
                  checked={checkbox.isChecked}
                />
              ))}
          </Checkbox.Group>
        </Box>
      </Popover>
    </Box>
  );
}

export default MultiCheckboxDropdown;
