import React from 'react';
import styled from 'styled-components';

type AbbreviationProps = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  title: string;
};

const Abbreviation = styled.abbr.attrs<AbbreviationProps>(({ title, className, style }) => ({
  title,
  style,
  className
}))`
  border-bottom: 1px dashed currentColor;
  text-decoration: none;
`;

export default Abbreviation;
