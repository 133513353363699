import React from 'react';
import { Snippet } from 'react-instantsearch/dom';
import { ExternalLink } from 'src/components/links';
import { Box, Stack, Text } from 'src/components/matchbox';

const AlgoliaResults = ({ hit }) => (
  <Stack space="100">
    <Text
      as={ExternalLink}
      to={`https://support.sparkpost.com${hit.slug}`}
      fontWeight="normal"
      fontSize="200"
      color="gray.700"
    >
      {hit.title}
    </Text>
    <Box mb="500" fontSize="200">
      <Snippet tagName="b" attribute="excerpt" hit={hit} />
    </Box>
  </Stack>
);

export default AlgoliaResults;
