import React from 'react';

// COMPONENTS
import { Box, QueryCard } from 'src/components/matchbox';

// HELPERS
import { toFilterTypeLabel } from 'src/pages/analyticsReport/helpers';

// TYPES
import { UseAnalyticsReportContext } from 'src/typescript';

type ActiveComparisonsProps = {
  comparisons: UseAnalyticsReportContext['state']['comparisons'];
  handleFilterRemove?: (index: number) => void;
};

export default function ActiveComparisons({
  comparisons,
  handleFilterRemove
}: ActiveComparisonsProps): JSX.Element {
  const remove = (index: number): void => handleFilterRemove && handleFilterRemove(index);

  return (
    <Box data-id="active-comparison-filters" position="relative">
      <QueryCard.Group>
        {comparisons.map((compareFilter, index) => (
          <QueryCard
            key={`comparison-${compareFilter.value}`}
            id={`active-comparison-${index}`}
            onClose={() => remove(index)}
          >
            <QueryCard.Title>{toFilterTypeLabel(compareFilter.type)}</QueryCard.Title>
            <QueryCard.Subtitle>{compareFilter.value}</QueryCard.Subtitle>
          </QueryCard>
        ))}
      </QueryCard.Group>
    </Box>
  );
}
