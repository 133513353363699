import React, { useState } from 'react';
import { Banner, Box, Column, Columns, Stack, Text } from 'src/components/matchbox';
import { ExternalLink } from 'src/components/links';
import { Rocket } from '@sparkpost/matchbox-icons';
import { LINKS } from 'src/constants';

export const DeliverabilityBanner = () => {
  const [bannerOpen, setBannerOpen] = useState(true);

  if (!bannerOpen) {
    return null;
  }

  return (
    <Box>
      <Banner
        data-id="deliverability-metrics-banner"
        status="muted"
        size="small"
        onDismiss={() => setBannerOpen(false)}
      >
        {/* TODO: Replace with Inline once fixed in matchbox */}
        <Columns>
          <Column width="content">
            <Text color="brand.orange">
              <Rocket />
            </Text>
          </Column>

          <Column>
            <Box>
              <span>Gain access to </span>
              <ExternalLink to={LINKS.DELIVERABILITY_ANALYTICS_DEMO}>
                deliverability metrics
              </ExternalLink>
            </Box>
          </Column>
        </Columns>
      </Banner>
    </Box>
  );
};

/**
 * Tell the user they need to remove the campaign subject to add non-deliverability metrics (cause they're disabled)
 */
export function MetricsSubjectCampaignsWarning(props) {
  const { filter, compare } = props;

  return (
    <>
      {/* alert role used to inform screen reader users of the presence of the error since it renders before interactive content in the DOM */}
      {/* negative tabIndex used to allow programmatic focus handling */}
      <div role="alert" tabIndex="-1" data-id={props['data-id']}>
        <Banner size="small" status="danger">
          <Stack space="200">
            {filter && !compare && (
              <Text>Remove the Campaign (Subject) filter to add additional metrics.</Text>
            )}
            {compare && !filter && (
              <Text>Remove the Campaign (Subject) comparison to add additional metrics.</Text>
            )}
            {filter && compare && (
              <Text>
                Remove the Campaign (Subject) filter and comparison to add additional metrics.
              </Text>
            )}
          </Stack>
        </Banner>
      </div>
    </>
  );
}
