import React from 'react';
import { useSelector } from 'react-redux';
import { LINKS } from 'src/constants';
import { PageLink, SubduedLink, ExternalLink } from 'src/components/links';
import { Box, Stack } from 'src/components/matchbox';
import { Settings } from '@sparkpost/matchbox-icons';
import { hasGrants } from 'src/helpers/conditions';

const SubaccountSubTitle = function ({ id }) {
  const subaccountIdNumber = parseInt(id);
  const canManageSubaccounts = useSelector((state) =>
    hasGrants('subaccount/manage', 'api_keys/manage', 'sending_domains/manage')(state)
  );

  const isNotPrimaryAccount = subaccountIdNumber !== 0;
  return (
    <Stack>
      <Box>
        {isNotPrimaryAccount && canManageSubaccounts && (
          <PageLink to={`/account/subaccounts/${id}`} fontSize="200">
            <span>Subaccount Settings&nbsp;</span>
            <Settings />
          </PageLink>
        )}
      </Box>
      <SubduedLink as={ExternalLink} to={LINKS.SUBACCOUNTS} fontSize="200">
        Learn more about Subaccounts
      </SubduedLink>
    </Stack>
  );
};

export default SubaccountSubTitle;
