/* eslint-disable react/display-name */
import React, { cloneElement, forwardRef, HTMLAttributes } from 'react';
import { Banner as MatchboxBanner, PolymorphicButton } from '@sparkpost/matchbox';
import { Box, Inline } from 'src/components/matchbox';

/**
 * Matchbox Banner component
 * @see https://design.sparkpost.com/components/banner/
 */
const Banner = forwardRef((props, ref) => {
  return <MatchboxBanner data-id={props.status + '-banner'} {...props} />;
}) as typeof MatchboxBanner & { Actions: PolymorphicButton };

type RenderChildrenProps<HTMLElements> = HTMLElements | Array<HTMLElements> | null | undefined;

const Actions: typeof MatchboxBanner.Action = forwardRef((props, ref) => {
  const { children } = props;

  const renderChildren = (): RenderChildrenProps<
    React.DetailedReactHTMLElement<HTMLAttributes<HTMLElement>, HTMLElement>
  > => {
    const childrenArr = React.Children.map(children, (child) => child);

    if (childrenArr?.length === 1) {
      return cloneElement(
        childrenArr[0] as React.DetailedReactHTMLElement<
          HTMLAttributes<HTMLElement> & { variant: string },
          HTMLElement
        >,
        {
          variant: 'monochrome-secondary'
        }
      );
    }

    return React.Children.map(children, (child, index) => {
      if (index === 0) {
        return cloneElement(
          child as React.DetailedReactHTMLElement<
            HTMLAttributes<HTMLElement> & { variant: string },
            HTMLElement
          >,
          {
            variant: 'monochrome'
          }
        );
      }

      return cloneElement(
        child as React.DetailedReactHTMLElement<
          HTMLAttributes<HTMLElement> & { variant: string },
          HTMLElement
        >,
        {
          variant: 'monochrome-secondary'
        }
      );
    });
  };

  return (
    <Box marginTop="500">
      <Inline>{renderChildren()}</Inline>
    </Box>
  );
});

const Action: typeof MatchboxBanner.Action = forwardRef((props, ref) => {
  return <MatchboxBanner.Action {...props} ref={ref} />;
});

const Media: typeof MatchboxBanner.Media = forwardRef((props, ref) => {
  return <MatchboxBanner.Media {...props} ref={ref} />;
});

Banner.displayName = 'Banner';
Actions.displayName = 'Banner.Actions';
Action.displayName = 'Banner.Action';
Media.displayName = 'Banner.Media';

Banner.Actions = Actions;
Banner.Action = Action;
Banner.Media = Media;

export default Banner;
