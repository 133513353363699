import React, { useCallback, useState } from 'react';
import { tokens } from '@sparkpost/design-tokens';
import { Send } from '@sparkpost/matchbox-icons';
import { ExternalLink, SubduedLink } from 'src/components/links';
import {
  Box,
  Button,
  Inline,
  Layout,
  Panel,
  Stack,
  Tag,
  Text,
  Toggle
} from 'src/components/matchbox';
import { Bold, Heading, SubduedText, TranslatableText } from 'src/components/text';
import { useModal, useSparkPostMutation } from 'src/hooks';
import { weekDayDictionary } from './constants';
import DeleteAlertModal from './DeleteAlertModal';
import SendAlertTestModal from './SendAlertTestModal';
import { updateAlert } from 'src/helpers/api/alerts';

const MODAL_TYPES = Object.freeze({
  deleteAlert: 'deleteAlert',
  sendAlertTest: 'sendAlertTest'
});

const handleNotifications = (alert) => {
  return alert.targets.reduce(
    (acc, curr) => {
      if (curr.target_type === 'email') {
        return { ...acc, email: [...acc.email, curr.target] };
      }
      if (curr.target_type === 'slack') {
        return { ...acc, slack: [...acc.slack, curr.target] };
      }
      if (curr.target_type === 'webhook') {
        return { ...acc, webhook: [...acc.webhook, curr.target] };
      }
    },
    { email: [], slack: [], webhook: [] }
  );
};

export default function AlertOverView({ alert }) {
  const { openModal, closeModal, isModalOpen } = useModal();

  const { email, slack, webhook } = handleNotifications(alert);
  const { alert_configuration: alertConfiguration } = alert;
  const { notification_schedule: notificationSchedule } = alertConfiguration;
  const [status, setStatus] = useState(alert?.status || 'disabled');
  const [selectedModalType, setSelectedModalType] = useState(MODAL_TYPES.sendAlertTest);

  const inverseNotificationStatus = (status) => {
    if (status === 'active') {
      return 'disabled';
    }
    return 'active';
  };
  const handleErrorUpdateStatus = useCallback(() => {
    setStatus(inverseNotificationStatus(status));
  }, [status]);
  const updateAlertStatusRequest = useSparkPostMutation(
    (newStatus) => updateAlert(alert.id, { status: newStatus }),
    { onError: handleErrorUpdateStatus }
  );
  const handleChangeNotificationStatus = useCallback(() => {
    const newStatus = inverseNotificationStatus(status);
    setStatus(newStatus);
    updateAlertStatusRequest.mutate(newStatus);
  }, [status, updateAlertStatusRequest]);
  const openAlertModal = useCallback(
    (modalType) => {
      setSelectedModalType(modalType);
      openModal();
    },
    [openModal]
  );
  const alertNotificationToggleDescription =
    status === 'active'
      ? 'Alert notifications will be sent to the specified channels.'
      : 'Alert notifications will not be sent.';

  return (
    <Box>
      <Layout>
        <Layout.Section annotated>
          <Stack>
            <Heading as="h4">Notification Settings</Heading>
            <SubduedText>An alert requires at least 1 notification channel.</SubduedText>
            <Text>
              To learn how to create a unique URL for Slack integration, reference Slack’s
            </Text>
            <SubduedLink
              as={ExternalLink}
              to="https://www.sparkpost.com/blog/creating-and-consuming-sparkpost-webhooks/"
            >
              Webhook Article
            </SubduedLink>
            <SubduedLink as={ExternalLink} to="https://developers.sparkpost.com/api/webhooks/">
              Webhook Payload Sample
            </SubduedLink>
          </Stack>
        </Layout.Section>
        <Layout.Section>
          <Panel>
            <Panel.Section>
              <Stack space="100">
                <Box display="flex" justifyContent="space-between">
                  <Inline space="800">
                    <Bold>Enable Notification</Bold>
                    <Toggle
                      paddingTop="200"
                      data-id="alert-notification-toggle-input"
                      label="Enable Notification"
                      id="alert-notification-toggle"
                      checked={status === 'active'}
                      onChange={handleChangeNotificationStatus}
                    />
                  </Inline>
                  <Panel.Action onClick={() => openAlertModal(MODAL_TYPES.sendAlertTest)}>
                    Send a Test <Button.Icon as={Send} />
                  </Panel.Action>
                </Box>

                <TranslatableText>{alertNotificationToggleDescription}</TranslatableText>
              </Stack>
            </Panel.Section>
            <Panel.SubHeader>Notification Channels</Panel.SubHeader>
            <Panel.Section>
              <Stack space="400">
                <Box>
                  <Bold>Email</Bold>
                  {email.map((individualTarget) => {
                    return (
                      <Box
                        key={`alert-overview-email-${individualTarget.target}`}
                        mb={tokens.spacing_200}
                      >
                        <Tag>{individualTarget}</Tag>
                      </Box>
                    );
                  })}
                </Box>
                <Box>
                  <Bold>Slack</Bold>
                  {slack.length > 0 ? (
                    slack.map((individualTarget) => (
                      <Box mb={tokens.spacing_200} key={`alert-overview-slack-${individualTarget}`}>
                        <Tag>{individualTarget}</Tag>
                      </Box>
                    ))
                  ) : (
                    <Text>None</Text>
                  )}
                </Box>
                <Box>
                  <Bold>Webhook</Bold>
                  {webhook.length > 0 ? (
                    webhook.map((individualTarget) => (
                      <Box
                        mb={tokens.spacing_200}
                        key={`alert-overview-webhook-${individualTarget}`}
                      >
                        <Tag>{individualTarget}</Tag>
                      </Box>
                    ))
                  ) : (
                    <Text>None</Text>
                  )}
                </Box>
              </Stack>
            </Panel.Section>
            <Panel.SubHeader>Notification Time Range</Panel.SubHeader>
            <Box pl={tokens.spacing_450} pr={tokens.spacing_450} pt={tokens.spacing_300}>
              The alert is triggered if the conditions are met during the configured time range.
            </Box>
            <Panel.Section>
              <Stack space="500">
                <Box>
                  <Box>
                    <Bold>Days</Bold>
                  </Box>

                  {notificationSchedule?.days.map((day) => (
                    <Tag key={`alert-overview-day-${day}`} mr={tokens.spacing_200}>
                      <TranslatableText>
                        {typeof day === 'string' ? day : weekDayDictionary[day]}
                      </TranslatableText>
                    </Tag>
                  ))}
                </Box>
                <Box>
                  <Bold>Timeframe</Bold>
                  <Box mb={tokens.spacing_200}>
                    <Text>
                      {notificationSchedule?.timeframe.start} to{' '}
                      {notificationSchedule?.timeframe.end},{' '}
                      {notificationSchedule?.timeframe.time_zone}
                    </Text>
                  </Box>
                </Box>
              </Stack>
            </Panel.Section>
          </Panel>
        </Layout.Section>
      </Layout>
      <Layout>
        <Layout.Section annotated>
          <Stack space="300">
            <Heading as="h4">Delete Alert</Heading>
          </Stack>
        </Layout.Section>
        <Layout.Section>
          <Panel borderTop="4px solid #D9363E">
            <Box padding={tokens.spacing_450}>
              <Text color={tokens.color_gray_900}>
                Deleting an alert is permanent and cannot be undone.
              </Text>
            </Box>
          </Panel>
          <Panel>
            <Panel.Section>
              <Button
                color="red"
                variant="outline-destructive"
                onClick={() => openAlertModal(MODAL_TYPES.deleteAlert)}
              >
                Delete Alert
              </Button>
            </Panel.Section>
          </Panel>
        </Layout.Section>

        <DeleteAlertModal
          open={selectedModalType === MODAL_TYPES.deleteAlert && isModalOpen}
          onClose={closeModal}
          alert={alert}
        />
        <SendAlertTestModal
          open={selectedModalType === MODAL_TYPES.sendAlertTest && isModalOpen}
          onClose={closeModal}
          email={email}
          slack={slack}
          webhook={webhook}
        />
      </Layout>
    </Box>
  );
}
