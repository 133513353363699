import _ from 'lodash';
import { DEFAULT_FORM_DATA } from '../constants';

/**
 * @description Config returned from `/v1/inline-seeds/config`
 * @typedef InlineSeedConfig
 * @type {object}
 * @property {number} threshold - Number of messages seen for a campaign before seed sending starts.
 * @property {number} duration - Number of minutes after threshold is met over which to spread out seed messages.
 * @property {number} reset - Number of hours after threshold is met until seed sending can happen again.
 * @property {Array<string>} match - Array of campaign ids or prefixes that are eligible. Strings ending with an asterisk are treated as prefixes (MAX OF 5 ITEMS!).
 * @property {Array<string>} exclude - Array of campaign ids or prefixes that are NOT eligible. Strings ending with an asterisk are treated as prefixes (MAX OF 5 ITEMS!).
 */

/**
 * @description Re-formatted form data derived from `/v1/inline-seeds/config`
 * @typedef InlineSeedFormData
 * @type {object}
 * @property {number} threshold - Number of messages seen for a campaign before seed sending starts.
 * @property {number} duration - Number of minutes after threshold is met over which to spread out seed messages.
 * @property {number} reset - Number of hours after threshold is met until seed sending can happen again.
 * @property {string} mutationType - 'create' | 'update' - uses relevant POST or PUT upon submitting the form
 * @property {string} campaignsToSeed - 'include' | 'exclude' - maps to `match` and `exclude` in the API
 * @property {Array} campaignIds - Array of campaign IDs to be passed to `match` or `exclude`
 * @property {string} campaignIdsInput - Raw user entry before adding tags to the multi entry field
 */

/**
 * @description Reformats API data to meet needs of UI interaction in the form
 * @param {Array<InlineSeedConfig>} configs
 * @returns {InlineSeedFormData}
 */
export function toFormFriendlyFormat(configs) {
  if (!configs) {
    return DEFAULT_FORM_DATA;
  }

  const [config] = configs; // Grabbing the first config from the array
  const { match, exclude, threshold, duration, reset } = config;

  let campaignsToSeed = 'include';
  let campaignIds = match;

  if (_.isNil(match) || _.isEmpty(match)) {
    campaignsToSeed = 'exclude';
    campaignIds = exclude;
  }

  return {
    mutationType: 'update',
    campaignsToSeed,
    campaignIds,
    campaignIdsInput: '', // Empty value for initial, freeform entry in to the `TextField`
    threshold,
    duration,
    reset
  };
}

/**
 * @description Reformats `formData` to object format expected by `/inline-seeds/configs` endpoints
 * @param {InlineSeedFormData} formData
 * @returns {InlineSeedConfig}
 */
export function toApiFriendlyFormat(formData) {
  const { threshold, duration, reset, campaignsToSeed, campaignIds } = formData;
  const matchOrExclude = campaignsToSeed === 'exclude' ? 'exclude' : 'match';

  return {
    threshold: Number(threshold),
    duration: Number(duration),
    reset: Number(reset),
    [matchOrExclude]: campaignIds
  };
}
