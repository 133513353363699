import React from 'react';
import useHibanaToggle from 'src/hooks/useHibanaToggle';

type EmailIconProps = {
  className?: string;
};

const OGEmailIcon = ({ className }: EmailIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className={className}>
    <path
      d="M86.8 13.25H13.2a9.18 9.18 0 0 0-9.15 9.2v55.2a9.23 9.23 0 0 0 9.2 9.2H86.8a9.23 9.23 0 0 0 9.2-9.2v-55.2a9.23 9.23 0 0 0-9.2-9.2zm0 64.4H13.2v-46l36.8 23 36.8-23zM50 45.45l-36.8-23h73.6z"
      fill="#fa6423"
    />
  </svg>
);

const HibanaEmailIcon = ({ className }: EmailIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className={className}>
    <path
      d="M86.8 13.25H13.2a9.18 9.18 0 0 0-9.15 9.2v55.2a9.23 9.23 0 0 0 9.2 9.2H86.8a9.23 9.23 0 0 0 9.2-9.2v-55.2a9.23 9.23 0 0 0-9.2-9.2zm0 64.4H13.2v-46l36.8 23 36.8-23zM50 45.45l-36.8-23h73.6z"
      fill="#1271e6"
    />
  </svg>
);

const EmailIcon = (props: EmailIconProps) => useHibanaToggle(OGEmailIcon, HibanaEmailIcon)(props);
export default EmailIcon;
