import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Search } from '@sparkpost/matchbox-icons';
import { useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PER_PAGE } from 'src/constants';
import { Empty } from 'src/components';
import { Pagination } from 'src/components/collection';
import MultiCheckboxDropdown, { useMultiCheckbox } from 'src/components/MultiCheckboxDropdown';
import { ListBox, Panel, Stack, TextField } from 'src/components/matchbox';
import { DEFAULT_SORT_BY, SEEDING_STATUS_CHECKBOXES, SORT_BY_OPTIONS } from './constants';
import { SeedingActivityTable } from './SeedingActivityTable';

const FilterFields = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.space['400']};
  grid-template-columns: 1fr;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-gap: ${(props) => props.theme.space['500']};
    grid-template-columns: 2fr 1fr 250px; /* Static column width to prevent re-sizing when the user updates their selection */
  }
`;

export function SeedingActivityPanel({ data }) {
  const columns = useMemo(
    () => [
      { Header: 'Campaign', accessor: 'campaignId' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Threshold', accessor: 'thresholdPercentage' },
      { Header: 'Time Remaining', accessor: 'timeRemaining' },
      { Header: 'Actions', accessor: 'actions' }
    ],
    []
  );
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: DEFAULT_CURRENT_PAGE - 1, // react-table takes a 0 base pageIndex
        pageSize: DEFAULT_PER_PAGE,
        sortBy: DEFAULT_SORT_BY
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );
  const multiCheckbox = useMultiCheckbox({
    checkboxes: SEEDING_STATUS_CHECKBOXES,
    allowSelectAll: false,
    onChange: (values) => {
      // Clear filters if all are selected - does this interaction make sense when only one status is possible for each row?
      if (values.length === SEEDING_STATUS_CHECKBOXES.length) {
        return tableInstance.setFilter('status', undefined);
      }

      return tableInstance.setFilter('status', values);
    }
  });

  function handleCampaignIdChange(e) {
    const value = e.target.value || undefined;

    tableInstance.setFilter('campaignId', value);
  }

  function handleSortByChange(e) {
    const value = e.currentTarget.value;
    const [id, direction] = value.split('.');
    const desc = direction === 'desc' ? true : false;

    // Kind of funky that `setSortBy` accepts an array - I think `react-table` supports multi-dimensional sorting
    tableInstance.setSortBy([{ id, desc }]);
  }

  return (
    <Stack space="300">
      <Panel>
        <Panel.Section>
          <FilterFields>
            <TextField
              label="Search Campaigns"
              id="search-campaigns"
              placeholder="e.g. WelcomeCampaign"
              prefix={<Search />}
              onChange={handleCampaignIdChange}
            />

            <MultiCheckboxDropdown
              label="Seeding Status"
              id="seeding-status"
              screenReaderDirections="Checkboxes filter the table. When checked, table rows without the selected value are hidden."
              checkboxes={multiCheckbox.checkboxes}
            />

            <ListBox
              label="Sort By"
              id="sort-by"
              onChange={handleSortByChange}
              defaultValue={SORT_BY_OPTIONS[0].value}
            >
              {SORT_BY_OPTIONS.map((option, index) => {
                return (
                  <ListBox.Option key={`${option.value}-${index}`} value={option.value}>
                    {option.label}
                  </ListBox.Option>
                );
              })}
            </ListBox>
          </FilterFields>
        </Panel.Section>

        {tableInstance.rows.length === 0 ? <Empty message="No results found." /> : null}

        <SeedingActivityTable tableInstance={tableInstance} />
      </Panel>

      <Pagination
        data={tableInstance.rows.map(({ original }) => original)}
        currentPage={tableInstance.state.pageIndex + 1}
        perPage={tableInstance.state.pageSize}
        saveCsv={true}
        onPageChange={(page) => tableInstance.gotoPage(page)}
        onPerPageChange={(perPage) => tableInstance.setPageSize(perPage)}
      />
    </Stack>
  );
}
