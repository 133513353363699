import { Location } from 'history';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { defaultStore } from 'src';
import * as analytics from 'src/helpers/analytics';
import findRouteByPath from 'src/helpers/findRouteByPath';
import { selectRoutes } from 'src/selectors/routes';

type RouteProps = {
  public: boolean;
  title: string;
};

type GoogleTagmanagerProps = {
  accountId: number;
  id: number;
  location: Location;
  routes: $TODOFIXME;
  trackPageView: () => void;
  username: string;
};

// This component is responsible for loading the GTM snippet, initialising our analytics
// module, setting the username analytics variable and firing the content-view even on route change.
export class GoogleTagManager extends Component<GoogleTagmanagerProps> {
  state = {
    dataLayerLoaded: false
  };

  componentDidMount() {
    analytics.setup();

    const route: RouteProps | Record<string, unknown> = findRouteByPath(
      this.props.location.pathname,
      undefined,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.props.routes
    );
    // for public routes, track initial page view immediately
    if (route.public) {
      this.trackPageview();
    }

    // for non-public routes, store flag to let us know we need to track initial page view once the username loads
    this.setState({ dataLayerLoaded: true });
  }

  componentDidUpdate(prevProps: GoogleTagmanagerProps) {
    const isNewRoute = prevProps.location.pathname !== this.props.location.pathname;
    const userHasLoaded = !prevProps.username && this.props.username;

    if (userHasLoaded) {
      analytics.setVariable('accountid', this.props.accountId);
      analytics.setVariable('username', this.props.username);
    }

    // Track additional page views whenever the route changes or when username first loads on auth page initial load
    if (isNewRoute) {
      this.trackPageview();
    }
  }

  trackPageview() {
    const { location, routes } = this.props;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const route = findRouteByPath(location.pathname, undefined, routes);

    analytics.trackPageview({
      path: location.pathname + location.search, // duplicates angular 1.x ui-router "$location.url()" which is /path?plus=search
      title: (route as RouteProps).title || location.pathname // duplicate angular 1.x $rootScope.stateData.title
    });
  }

  render() {
    const src = `https://www.googletagmanager.com/gtm.js?id=${this.props.id}`;
    return (
      <Helmet>
        {this.state.dataLayerLoaded && <script src={src} type="text/javascript" async={true} />}
      </Helmet>
    );
  }
}

const mapStateToProps = (state: typeof defaultStore.getState) => ({
  accountId: state.currentUser.customer,
  username: state.currentUser.username,
  routes: selectRoutes(state)
});
const Connected = connect(mapStateToProps)(GoogleTagManager);
export default withRouter(Connected as $TODOFIXME);
